import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { ApiAuctionsService } from "../../../../api/custom/api-auctions.service";
import { Auction, BidAuction } from "../../../../interfaces/custom/auction.interface";

@Component({
  selector: "app-auction-bid",
  templateUrl: "./auction-bid.component.html",
})
export class AuctionBidComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(MAT_DIALOG_DATA) data: { auction: Auction; playerBid: number; phone: string };
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AuctionBidComponent>;
  @OwInject(ApiAuctionsService) apiAuctionsService: ApiAuctionsService;

  form: FormGroup;
  auction: Auction;
  playerBid: number;
  STOCK_VIEW = STOCK_VIEW;

  ngOnInit() {
    this.auction = this.data.auction;
    this.playerBid = this.data.playerBid;
    this.clearForm();
  }

  clearForm() {
    this.form = this.fb.group({
      phone: this.fb.control(this.data.phone || null, [Validators.required, this.checkPhoneNumber]),
    });
  }

  checkPhoneNumber(control: AbstractControl): ValidationErrors | null {
    if (control.value && !control.value.replace(/\s+/g, "").match(/^\d{9}$/)) {
      return {
        phoneNumberError: true,
      };
    }
    return null;
  }

  getErrors(controlName) {
    return controlName.invalid && controlName.dirty && controlName.errors;
  }

  back() {
    this.matDialogRef.close();
  }

  bid() {
    const data: BidAuction = {
      bid: this.playerBid,
      phone: this.form.get("phone").value,
      auction_id: this.auction.id,
    };

    this.apiAuctionsService.postApiAuctionBid(data).subscribe({
      next: resp => {
        this.dialogService.openAlert({
          description: resp.message,
        });
        this.matDialogRef.close(true);
      },
    });
  }
}
