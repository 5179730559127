import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select } from '@ngrx/store';

import { GAME_CONFIG } from '../../../../../../../core/config/custom/_parsed-game.config';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { ApiService } from '../../../../../../../core/providers/api.service';
import { unsubscribeObject } from '../../../../../../../core/utility/unsubscribe-array';
import { PlayerSelectors } from '../../../../../../../store/player';
import { checkPasswordTheSame } from '../../../../../../auth/helpers/validators-form-password-the-same.helper';
import { AbstractPlayerDetailsComponent } from '../../../abstract/core/abstract-player-details.component';
import { PostPasswordRequestInterface } from '../../../api/custom/interfaces/request/post-password-request.interface';
import { PostSettingsRequestInterface } from '../../../api/custom/interfaces/request/post-settings-request.interface';
import { GetPlayerSettingsResponse } from '../../../api/custom/interfaces/response/get-player-settings-response.interface';
import { ApiPlayerService } from '../../../api/custom/services/api-player.service';

@Component({
  selector: 'app-custom-player-details',
  templateUrl: './player-details.component.html',
})
export class PlayerDetailsComponent
  extends AbstractPlayerDetailsComponent
  implements OnInit, OnDestroy
{
  @OwInject(ApiPlayerService) apiPlayerService: ApiPlayerService;
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(FormBuilder) fb: FormBuilder;

  TEMPLATE = {
    PLAYER_DETAILS_INFO: 1,
    PLAYER_DETAILS_EDIT: 2,
  };
  activeTemplate: number;
  playerSettings: GetPlayerSettingsResponse;
  formChangeSettings: FormGroup;
  formChangePassword: FormGroup;
  isCC = GAME_CONFIG.IS_CC;
  playerName;

  ngOnInit() {
    this.changeTemplate(this.TEMPLATE.PLAYER_DETAILS_INFO);
    this.getPlayerSettings();
    this.subscribePlayer();
    this.clearFormPassword();
  }

  changeTemplate(template: number) {
    this.activeTemplate = template;
  }

  getPlayerSettings() {
    this.apiPlayerService.getPlayerSettings().subscribe(resp => {
      this.playerSettings = resp;
      this.clearFormSettings();
    });
  }

  postSettings() {
    const dataRequest: PostSettingsRequestInterface = {
      business_phone: this.formChangeSettings.value.businessPhone,
      private_email: this.formChangeSettings.value.privateEmail,
      facebook_username: this.formChangeSettings.value.facebookUsername,
      facebook_email: this.formChangeSettings.value.facebookEmail,
    };

    this.apiPlayerService.postSettings(dataRequest).subscribe(
      () => {
        this.dialogService.openAlert({
          description: 'Twoje dane zostały zmienione',
          buttonClose: {
            label: 'ok',
          },
        });

        this.getPlayerSettings();
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  postPassword() {
    const dataRequest: PostPasswordRequestInterface = {
      old_password: this.formChangePassword.value.oldPassword,
      new_password: this.formChangePassword.value.password,
    };

    this.apiPlayerService.postPassword(dataRequest).subscribe(
      () => {
        this.dialogService.openAlert({
          description: 'Twoje hasło zostało zmienione',
          buttonClose: {
            label: 'ok',
          },
        });
        this.clearFormPassword();
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  subscribePlayer() {
    this.subs.player = this.store
      .pipe(select(PlayerSelectors.selectPlayer))
      .subscribe(player => {
        this.player = player;
        this.playerName = this.player.title_id.name;
      });
  }

  setProfileImage(file) {
    this.playerService.setProfileImage(file).subscribe(
      () => {},
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  changeImage(event) {
    const eventObj: any = event;
    const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    const files: FileList = target.files;
    const file = files[0];
    if (file) {
      this.setProfileImage(file);
    }
  }

  clearFormSettings() {
    this.formChangeSettings = this.fb.group({
      privateEmail: this.fb.control(this.playerSettings.private_email, []),
      businessPhone: this.fb.control(this.playerSettings.business_phone, []),
    });

    if (!this.isCC) {
      this.formChangeSettings.addControl(
        'facebookUsername',
        this.fb.control(this.playerSettings.facebook_username, [])
      );
      this.formChangeSettings.addControl(
        'facebookEmail',
        this.fb.control(this.playerSettings.facebook_email, [])
      );
    }
  }

  clearFormPassword() {
    this.formChangePassword = this.fb.group(
      {
        oldPassword: this.fb.control(null, [Validators.required]),
        password: this.fb.control(null, [Validators.required]),
        passwordRepeat: this.fb.control(null, [Validators.required]),
      },
      {
        validator: checkPasswordTheSame,
      }
    );
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
