import { RANK_GROUP } from '../../../../../const/custom/rank-group.const';
import { RANK_NAME } from '../../../../../const/custom/rank-name.const';
import { RankFilter } from '../interfaces/rank-filter.interface';

export const FILTERS: RankFilter[] = [
  {
    valueApi: RANK_GROUP.EMPLOYEE,
    label: 'Indywidualny',
    show: true,
  },
  {
    valueApi: RANK_GROUP.MANAGERS,
    label: 'Indywidualny (kierownicy)',
    show: true,
    conditions: [RANK_NAME.GAME],
  },
  {
    valueApi: RANK_GROUP.BRANCH,
    label: 'Oddziały',
    show: true,
  },
  {
    valueApi: RANK_GROUP.REGION,
    label: 'Regiony',
    show: true,
  },
];
