<div class="new-edition-background">
  <button
    (click)="logout()"
    class="c-square small c-primary no-box-shadow logout"
    matTooltip="Wyloguj"
    matTooltipPosition="left"
  >
    <i class="fas fa-power-off"></i>
  </button>
</div>
