import { Component } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { MatDialogRef } from '@angular/material/dialog';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';

@Component({
  selector: 'app-hud-menu-more',
  templateUrl: './hud-menu-more.component.html',
})
export class HudMenuMoreComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<HudMenuMoreComponent>;

  close() {
    this.matDialogRef.close();
  }
}
