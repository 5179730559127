<div class="ow-dialog">

  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (click)="close()"
  ></m-ui-close-button>

  <!-- TITLE -->
  <p class="title" *ngIf="title">
    {{ title }}
  </p>

  <div *ngIf="prizes" class="prizes">
    <table>
      <tbody>
        <tr *ngFor="let currency of prizes.currency_prizes">
          <td class="center">
            <m-ui-currency
              *ngIf="currency.currency_id !== PLAYER_SCORE.ID"
              [item]="{currency_id: currency.currency_id, amount: currency.amount}"
              [stockView]="STOCK_VIEW.CUSTOM"
              [customIconSize]="'small'"
              [customClassStock]="'stock-layout-b'"
              [lack]="false"
            ></m-ui-currency>
            <div *ngIf="currency.currency_id === PLAYER_SCORE.ID">
              {{ currency.amount }} pkt
            </div>
          </td>
        </tr>
        <tr *ngFor="let product of prizes.product_prizes">
          <td class="center">
            <m-ui-product
              [item]="{product_id: product.product_id, amount: product.amount}"
              [stockView]="STOCK_VIEW.CUSTOM"
              [customIconSize]="'small'"
              [customClassStock]="'stock-layout-b'"
              [lack]="false"
            ></m-ui-product>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
