import { Component, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventShop } from '../../../../api/custom/interfaces/response/get-event-shops.interface';
import { EventShopProduct } from '../../../../api/custom/interfaces/response/get-event-shops-products.interface';
import { PlayerService } from '../../../../../../../player/providers/player.service';
import { SynchronizeTimeService } from '../../../../../../../../core/providers/synchronize-time.service';
import * as moment from 'moment';
import { ApiEventShopService } from '../../../../api/custom/services/api-event-shop.service';
import { ShoppingRequest } from '../../../../interfaces/custom/event-shop-requests.interface';
import { DialogService } from '../../../../../../../shared/providers/dialog.service';
import { GADGET_SHOP_ID, GADGET_SHOP_ID_3 } from '../../../../consts/custom/gadget-shop.const';
import { EventEmitterDialogsService } from '../../../../../../../../core/services/core/event-emitter-dialogs.service';
import { EventShopAlertConfirm } from '../event-shop-alert-confirm/event-shop-alert-confirm.component';
import { UserService } from '../../../../../../../user/providers/user.service';
import { UserActions } from '../../../../../../../../store/user';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../../store/state';

@Component({
  selector: 'event-shop-product-details',
  templateUrl: './event-shop-product-details.html',
})
export class EventShopProductDetails extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;
  @OwInject(ApiEventShopService) apiEventShopService: ApiEventShopService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<EventShopProductDetails>;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(UserService) userService: UserService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(MAT_DIALOG_DATA) data: {
    eventShop: EventShop & { currencyId: number },
    product: EventShopProduct,
  };
  hideInputField = false;

  form = new FormGroup({
    amount: new FormControl(null, [
      Validators.required,
      this.availableAmountValidator(),
    ])
  });

  maxLengthAmount = 5;

  isLimitOverrun: boolean;
  isLowLevel: boolean;
  isLowStar: boolean;
  isPhoneNumberDialogVisible = false;
  GADGET_SHOP_ID = GADGET_SHOP_ID;
  GADGET_SHOP_ID_3 = GADGET_SHOP_ID_3;

  availableAmountValidator() {
    return (control) => {
      const availableAmount = this.data.product.available_amount;
      if (availableAmount === null || control.value <= availableAmount) {
        return null;
      } else {
        return {availableAmount: true};
      }
    };
  }

  setEdenredCardInput() {
    const edenred = this.data.product.product_type === 'edenred-card';
    if (edenred) {
      this.form.controls['amount'].patchValue(1);
      this.hideInputField = true;
    }
  }

  ngOnInit() {
    this.checkLimit();
    this.setMaxValidator();
    this.checkLevel();
    this.checkStar();
    this.setEdenredCardInput();
    this.subscribeAmountChange(this.form);
  }

  buyProduct(phoneNumber?: string) {
    const params: ShoppingRequest = {
      amount: this.form.value.amount,
      shopID: this.data.eventShop.event_shop_id,
      productID: this.data.product.shop_product_id,
      phoneNumber
    };
    this.isPhoneNumberDialogVisible = false;
    this.apiEventShopService.buyProduct(params).subscribe((resp) => {
      let desc = 'Produkt został zakupiony.';

      if (resp?.promo_codes?.length > 0) {
        desc = desc + '<br> Wiadomość mailowa z numerami kodów została wysłana na Twoją skrzynkę służbową.';
      }

      if (this.data.product.product_type === 'edenred-card') {
        this.store.dispatch(new UserActions.FetchUser());
      }

      this.matDialogRef.close('reload');
      setTimeout(() => {
        this.dialogService.openAlert({
          title: 'Gratulacje',
          description: desc,
        });
      });
    }, errResp => {
      this.dialogService.openAlertErrorApi({errResp});
    });
  }

  submit() {
    const type = this.data.product.product_type;

    if ((type === 'custom' || type === 'edenred-card')) {
      this.dialogService.open(EventShopAlertConfirm, {}, (phone?: string) => {
        if (phone) {
          this.buyProduct(phone);
        }
      });
    } else {
      this.buyProduct();
    }
  }

  checkLevel() {
    this.isLowLevel = !this.playerService.allowRequiredLevel(this.data.product.required_level);
  }

  checkStar() {
    this.isLowStar = false;

    if (this.data.product.required_star) {
      this.isLowStar = this.data.product.required_star.star_id > this.playerService.player.star.star_id;
    }
  }

  checkLimit() {
    this.isLimitOverrun = this.data.product.limit_per_person_left === 0;
  }

  isActive() {
    const type = this.data.product.product_type;
    const hasCard = this.userService.me['has_edenred_card'];
    const validTime = this.synchronizeTimeService.getActualLocalTime() >= moment(this.data.eventShop.open_start).startOf('days').toDate()
      && this.synchronizeTimeService.getActualLocalTime() <= moment(this.data.eventShop.open_end).endOf('days').toDate();

    if (validTime && type === 'edenred-card') {
      return !hasCard;
    } else if (validTime && type === 'edenred-topup') {
      return !!hasCard;
    } else {
      return validTime;
    }
  }

  setTooltip() {
    const type = this.data.product.product_type;
    const validTime = this.synchronizeTimeService.getActualLocalTime() >= moment(this.data.eventShop.open_start).startOf('days').toDate()
      && this.synchronizeTimeService.getActualLocalTime() <= moment(this.data.eventShop.open_end).endOf('days').toDate();
    const deactivated = !this.isActive();

    if (validTime) {
      if (type === 'edenred-card') {
        return deactivated ? 'Posiadasz już jedną kartę' : null;
      } else if (type === 'edenred-topup') {
        return deactivated ? 'Nie posiadasz karty, aby doładować' : null;
      }
    } else {
      return deactivated ? 'W tej chwili nie możesz wymienić żetonów' : null;
    }
  }

  setMaxValidator() {
    if (this.data.product.limit_per_person_left !== null) {
      this.form.get('amount').setValidators(Validators.max(this.data.product.limit_per_person_left));
    }
  }

  openAlertImage(imagePath: string) {
    this.dialogService.openAlertImage({
      image: imagePath
    });
  }

  isCurrencyEnough(amount) {
    return (this.form.value.amount || 0) * this.data.product.price <= amount;
  }

  subscribeAmountChange(form: FormGroup) {
    form.get('amount').valueChanges.subscribe(value => {
      console.log(value);
      const stringValue = String(value);

      if (stringValue.length > this.maxLengthAmount) {
        const newValue = Number(stringValue.slice(0, this.maxLengthAmount));
        form.get('amount').patchValue(newValue);
      }
    });
  }
}
