export const RANK_CONFIG = {
  DEFAULT: {
    columns: [
      {header: null, value: 'position', default: true, classes: ['text-center']},
      {header: null, delta: true, classes: ['text-center']},
      {header: null, custom: true, template: 'basic', classes: ['full-width']},
      {columnFromApi: 1, classes: ['text-center']},
      {columnFromApi: 2, classes: ['text-center']},
      {columnFromApi: 3, classes: ['text-center']},
      {header: null, prizes: true, classes: ['text-right']}
    ]
  },
  BRANCH: {
    columns: [
      {header: null, value: 'position', default: true, classes: ['text-center']},
      {header: null, delta: true, classes: ['text-center']},
      {header: null, custom: true, template: 'branch-name', classes: ['full-width']},
      {columnFromApi: 1, classes: ['text-center']},
      {columnFromApi: 2, classes: ['text-center']},
      {columnFromApi: 3, classes: ['text-center']},
      {header: null, prizes: true, classes: ['text-right']}
    ]
  },
  REGION: {
    columns: [
      {header: null, value: 'position', default: true, classes: ['text-center']},
      {header: null, delta: true, classes: ['text-center']},
      {header: null, custom: true, template: 'region-name', classes: ['full-width']},
      {columnFromApi: 1, classes: ['text-center']},
      {columnFromApi: 2, classes: ['text-center']},
      {columnFromApi: 3, classes: ['text-center']},
      {header: null, prizes: true, classes: ['text-right']}
    ]
  }
};
