import { Component, OnInit } from '@angular/core';
import { AbstractQaFinishComponent } from '../../../../abstract/core/abstract-qa-finish.component';
import { QA_TYPE } from '../../../../consts/core/qa-type.const';

@Component({
  selector: 'app-custom-qa-finish',
  templateUrl: './qa-finish.component.html',
})
export class QaFinishComponent extends AbstractQaFinishComponent implements OnInit {
  QA_TYPE = QA_TYPE;

  ngOnInit() {
    this.setQa();
  }
}
