<div class="global-dialog ow-dialog c-window-long">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- DIALOG BACK -->
  <m-ui-back-button></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Organizacja pracy</span>
      </div>
    </div>
    <div class="right">
      <div class="source-data-date-tab pagination navigation" *ngIf="qualityTasks && paginationDate">
        <!-- PREV -->
        <button
          class="previous"
          (click)="prev()"
          [disabled]="!paginationDate.allowPrev.month"
        >
          <i class="far fa-chevron-square-left nav-arrow"></i>
        </button>

        <!-- MONTH -->
        <span class="value">
          {{ owDate.month_text_normal }}
        </span>

        <!-- NEXT -->
        <button
          class="next"
          (click)="next()"
          [disabled]="!paginationDate.allowNext.month"
        >
          <i class="far fa-chevron-square-right nav-arrow"></i>
        </button>
      </div>
      <div class="source-data-date-tab" *ngIf="qualityTasks?.source_data_date">
        Dane aktualne na: {{ qualityTasks.source_data_date | date:'dd-MM-y' }}
      </div>
    </div>
  </div>

  <div class="mark-window-left">
    <img [src]="'icon_quality.png' | asset:'ui'">
  </div>

  <div class="frame loading-opacity" *ngIf="qualityTasks">
    <ng-container *ngIf="qualityTasks.tasks.length; else noItem">
      <ow-perfect-scrollbar [scrollGradient]="qualityTasks.tasks.length">
        <p class="description">
          Parametr ORGANIZACJA PRACY używamy jako mnożnik, który może podnieść wartość nagród, które co miesiąc
          otrzymasz za realizację stałych zadań biznesowych oraz tych wybranych do 20 dnia danego miesiąca.
          Parametr DYSPOZYCYJNOŚĆ obliczamy jako dni przepracowane do dni zaplanowanych w grafiku (urlop to zawsze 100% obecności).
          Dbaj o dobrą <b>organizację pracy i 100% dyspozycyjność</b>, by zyskać jeszcze więcej <b>Złotych Talarów i Banknotów</b>!
        </p>

        <div class="static-container">
          <div *ngIf="qualityTasks.tasks.length || availabilityQualityTasks.length" class="loading-opacity">
            <!-- TITLE TABLE -->
            <m-ui-separator class="left" title="Twoje wyniki"></m-ui-separator>

            <!-- TABLE -->
            <table *ngIf="availabilityQualityTasks.length">
              <thead>
              <tr>
                <th>Nazwa zadania</th>
                <th class="score">Twój wynik</th>
                <th>Bonus za wynik 100% - złote talary</th>
                <th>Bonus za wynik 100% - banknoty</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let availabilityQualityTask of availabilityQualityTasks">
                <td>{{ availabilityQualityTask.name }}</td>
                <td class="score">{{ availabilityQualityTask.score }}</td>
                <td *ngFor="let item of availabilityQualityTask.const_prizes">
                  <m-ui-currency
                    [item]="item"
                    [stockView]="STOCK_VIEW.J"
                    [lack]="false"
                ></m-ui-currency>
                </td>
              </tr>
              </tbody>
            </table>

            <!-- TABLE -->
            <table *ngIf="qualityTasks.tasks.length">
              <thead>
              <tr>
                <th>Nazwa zadania</th>
                <th class="score">Ilość przekroczeń</th>
                <th>Mnożnik Złotych Talarów</th>
                <th>Mnożnik Banknotów</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let task of qualityTasks.tasks">
                <td>{{ task.name }}</td>
                <td class="score">{{ task.result_value }}</td>
                <td>{{ task.money_percent | number:'1.2-2' }}%</td>
                <td>{{ task.material_percent | number:'1.2-2' }}%</td>
              </tr>
              </tbody>
            </table>
          </div>

          <!-- TITLE TABLE -->
          <m-ui-separator class="left" title="Skala oceny parametru - Organizacja pracy"></m-ui-separator>

          <!-- TABLE STATIC -->
          <table class="static">
            <thead>
            <tr>
              <th>Parametry organizacji pracy</th>
              <th>Ilość przekroczeń w miesiącu</th>
              <th>Mnożnik Banknotów i Złotych Talarów</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                Codzienna, wzorowa organizacja czasu pracy (brak spóźnień i przekroczeń czasu przerw)
              </td>
              <td>0</td>
              <td>105%</td>
            </tr>
            <tr>
              <td>
                Pojedyncze niedociągnięcia w organizacji czasu pracy
              </td>
              <td>od 1 do 2</td>
              <td>90%</td>
            </tr>
            <tr>
              <td>
                Kilkukrotne niedociągnięcia w organizacji czasu pracy
              </td>
              <td>od 3 do 5</td>
              <td>60%</td>
            </tr>
            <tr>
              <td>
                Niewłaściwa organizacja czasu pracy (wielokrotne spóźnienia lub przekroczenia czasu przerw)
              </td>
              <td>powyżej 5</td>
              <td>0%</td>
            </tr>
            </tbody>
          </table>

          <span class="description">
            Ważne! Żeby brać udział w rankingach i otrzymać nagrody za zrealizowane zadania biznesowe, nie możesz mieć więcej niż 5 przekroczeń w miesiącu!
          </span>
        </div>
      </ow-perfect-scrollbar>
    </ng-container>
    <ng-template #noItem>
      <p class="flex-center no-data"><b>Brak wyników</b></p>
    </ng-template>
  </div>
</div>
