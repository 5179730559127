import { M3Direction, M3GameOptions } from "../models/match3.model";
import { Match3Game } from "./match3.game";
import Sprite = Phaser.GameObjects.Sprite;
import Pointer = Phaser.Input.Pointer;
import { Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { Match3Service } from "../match3.service";

const m3Souds = {
  select: "o-select.mp3",
  swap: "o-swap.wav",
  fail: "o-fail.mp3",
  collect: "o-collect.mp3",
  finish: "l-finish.mp3",
  music: "music.mp3",
};

interface GameGem {
  figureId: number;
  figureSprite: Phaser.GameObjects.Sprite;
  isEmpty: boolean;
}

export class Match3Scene extends Phaser.Scene {
  gameOptions: M3GameOptions;
  myGame: Match3Game;
  match3Service: Match3Service;
  gameConfiguration = {
    figure: [],
    // figure: [
    //   { id: 1, bonus: false, points: 10 },
    //   { id: 2, bonus: false, points: 10 },
    //   { id: 3, bonus: false, points: 10 },
    //   { id: 4, bonus: false, points: 10 },
    //   { id: 5, bonus: false, points: 10 },
    //   { id: 9, bonus: false, points: 10 },
    // ],
  };

  private gemGroup: any;
  private swappingGems: number;
  private removeMap: any[];

  private showMovesSprites: Sprite[] = [];

  private _poolArray: any[];

  removeBoxes: boolean;
  private canPick: boolean;
  private dragging: boolean;
  private selectedGem: any;
  private _gameArray: Array<Array<GameGem>>; // any[][];

  // colorsMap: string[];
  private movedAfterReset = false;
  music: Phaser.Sound.BaseSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;

  constructor() {
    super("PlayGame");
  }

  init() {
    // debugger;
    this.myGame = this.game as Match3Game;
    this.match3Service = this.myGame.match3Service;
    this.gameOptions = this.myGame.gameOptions;
    this.gameConfiguration.figure = this.myGame.match3Service.currentLevelDefs.colors.map(color => ({
      id: parseInt(color.color, 10),
      bonus: color.bonus,
      points: color.points,
    }));
    // this.colorsMap = [
    //   { color: "1", bonus: false, points: 10 },
    //   { color: "2", bonus: false, points: 10 },
    //   { color: "3", bonus: false, points: 10 },
    //   { color: "4", bonus: false, points: 10 },
    //   { color: "5", bonus: false, points: 10 },
    //   { color: "9", bonus: false, points: 10 },
    // ].map(color => color.color);
    //this.myGame.match3Service.currentLevelDefs.colors.map(color => color.color);
    // console.log("game opptiosn", this.gameOptions);
    // console.log("colors map", this.colorsMap);
  }

  // getColorSpriteKey(colorToLoad) {
  //   // return `match3-color-${this.colorsMap[colorToLoad]}`;
  //   return `match3-color-${this.colorsMap[colorToLoad]}`;
  // }

  protected initializeSounds() {
    this.music = this.sound.add("music.mp3");
    this.music.play();
    this.music["loop"] = true;
    this.music["volume"] = 0.4;
    if (!this.match3Service.soundsEnabled) {
      this.music.pause();
    }
  }

  removeAllBoxes() {
    this.removeBoxes = false;
    this.cleanupRemoveMap();

    const boxIndex = this.gameConfiguration.figure.findIndex(x => x.id == 99);
    if (boxIndex) {
      this.gameConfiguration.figure.splice(boxIndex, 1);
    }

    for (let i = 0; i < this.gameOptions.rows; i++) {
      for (let j = 0; j < this.gameOptions.cols; j++) {
        const currentFigure = this.gemAt(i, j);
        if (currentFigure.figureId == 99) {
          this.removeMap[i][j]++;
        }
      }
    }
    this.destroyGems();
  }

  scaleGem(gem: Sprite, factor = 1) {
    if (!gem || !gem.setDisplaySize) {
      console.log("no sprite", gem);
      return;
    }
    gem?.setDisplaySize(this.gameOptions.gemSize.width * factor, this.gameOptions.gemSize.height * factor);
  }

  playSound(sound: string, volume = 0.5) {
    if (!this.match3Service.soundsEnabled) {
      return;
    }
    this.match3Service.playSound(sound);
    // this.sound.play(m3Souds[sound], {
    //   volume,
    // });
  }

  preload() {
    //

    const preloadText = this.add.text(this.cameras.main.centerX, this.cameras.main.centerY, ``, {
      fontSize: "50px",
      fontFamily: "MyriadPro",
      align: "center",
      fontStyle: "bold",
      color: "#3d3d3d",
    });
    preloadText.setOrigin(0.5);
    this.load.on("progress", progress => {
      const progressRound = Math.round(100 * progress);
      preloadText.setText(progressRound + "%");
    });

    this.load.on("complete", progress => {
      preloadText.destroy();
    });

    this.load.audio(`music.mp3`, this.myGame.gameService.assetsService.getAsset(`minigames/match-three/sfx/music.mp3`).path);

    // this.colorsMap.forEach(colorToLoad => {
    //   console.log("color to load", colorToLoad);
    //   this.load.image(
    //     `match3-color-${colorToLoad}`,
    //     this.myGame.gameService.assetsService.getAsset(`minigames/match3/products/${colorToLoad}.png`).path
    //   );
    // });
    this.gameConfiguration.figure.forEach(figure => {
      this.load.image(
        `match3-color-${figure.id}`,
        this.myGame.gameService.assetsService.getAsset(`minigames/match-three/products/${figure.id}.png`).path
      );
    });
  }

  resizeCamera() {
    this.cameras.main.setBounds(-10, -10, this.game.canvas.width, this.game.canvas.height);
  }

  create() {
    this.resizeCamera();
    this.canPick = true;
    this.match3Service.blockRedrawButton = false;
    this.dragging = false;
    this.drawField();
    this.selectedGem = null;
    this.input.on("pointerdown", this.gemSelect, this);
    this.input.on("pointermove", this.startSwipe, this);
    this.input.on("pointerup", this.stopSwipe, this);
    this.initializeSounds();
  }

  restartBoard() {
    this.playSound("swap");
    this._gameArray.map(r => r.forEach(s => s.figureSprite.destroy()));

    this.canPick = true;
    this.match3Service.blockRedrawButton = false;
    this.dragging = false;
    this.drawField();
    this.selectedGem = null;
    this.showMovesSprites = [];
    //
    // if (this.movedAfterReset) {
    //   this._gameArray.forEach((r, ii) => r.forEach((s, i) => {
    //     if (!s.gemSprite.destroyed) {
    //       this.add.tween({
    //         targets: [s.gemSprite],
    //         alpha: 0,
    //         x: s.gemSprite.x - 60,
    //         duration: 200,
    //         delay: 40 * (i + ii),
    //         ease: Phaser.Math.Easing.Quartic,
    //       });
    //     }
    //
    //   }));
    // }
    //
    // setTimeout(() => {
    //   this._gameArray.map(r => r.forEach(s => s.gemSprite.destroy()));
    //
    //   this.canPick = true;
    //   this.dragging = false;
    //   this.drawField();
    //   this.selectedGem = null;
    //   this.showMovesSprites = [];
    //
    //   this.isAnyMove();
    // }, this.movedAfterReset ? 600 : 0);
    // // console.log(sprites);
    // this.movedAfterReset = false;
  }

  // TODO - move to the service
  genGemPosition(col, row) {
    return {
      x: this.gameOptions.gemSize.width * col + this.gameOptions.gemSize.width / 2 + 5 * col,
      y: this.gameOptions.gemSize.height * row + this.gameOptions.gemSize.height / 2 + 5 * row,
    };
  }

  drawField() {
    this._gameArray = [];
    this._poolArray = [].constructor(this.gameOptions.cols).fill([]);
    this.gemGroup = this.add.group();
    for (let row = 0; row < this.gameOptions.rows; row++) {
      this._gameArray[row] = [];
      for (let col = 0; col < this.gameOptions.cols; col++) {
        // get gem icon
        const gemPosition = this.genGemPosition(col, row);
        const gem = this.add.sprite(gemPosition.x, gemPosition.y, "gems-atlas");
        this.scaleGem(gem);
        this.gemGroup.add(gem);
        do {
          const randomFigure = this.drawColor();
          // console.log(randomFigure);
          // gem.setTexture(this.getColorSpriteKey(randomColor));
          gem.setTexture(`match3-color-${randomFigure.id}`);
          this.scaleGem(gem);
          this._gameArray[row][col] = {
            figureId: randomFigure.id,
            figureSprite: gem,
            isEmpty: false,
          };
        } while (this.isMatch(row, col));
      }
    }
    // debugger;
    // console.log(this);
    this.isAnyMove();
  }

  isMatch(row, col) {
    return this.isHorizontalMatch(row, col) || this.isVerticalMatch(row, col);
  }

  isHorizontalMatch(row: number, col: number): boolean {
    const gems = [
      this.gemAt(row, col).figureId,
      this.gemAt(row, col - 1)?.figureId,
      this.gemAt(row, col - 2)?.figureId,
      this.gemAt(row, col - 3)?.figureId,
    ];
    // console.log(gems[0], "row: " + row, "col: " + col);
    // console.log("row: " + row, "col: " + (col - 1));
    // console.log("row: " + row, "col: " + (col - 2));
    // return (
    //   this.gemAt(row, col).gemColor === this.gemAt(row, col - 1).gemColor &&
    //   this.gemAt(row, col).gemColor === this.gemAt(row, col - 2).gemColor
    // );

    switch (gems[0].toString()) {
      case "99": {
        // console.log('KEEEEK');
        return gems[0] === gems[1] && gems[0] === gems[2] && gems[0] === gems[3];
      }
      default: {
        // console.log(gems[0], gems[1], gems[2]);
        return gems[0] === gems[1] && gems[0] === gems[2];
      }
    }
    //
    // return gems[0] === gems[1] && gems[0] === gems[2];
  }

  isVerticalMatch(row: number, col: number) {
    const gems = [
      this.gemAt(row, col).figureId,
      this.gemAt(row - 1, col)?.figureId,
      this.gemAt(row - 2, col)?.figureId,
      this.gemAt(row - 3, col)?.figureId,
    ];
    // console.log(gems[0], "row: " + row, "col: " + col);
    // console.log("row: " + (row - 1), "col: " + col);
    // console.log("row: " + (row - 2), "col: " + col);
    // console.log(this.gemAt(row, col));
    switch (gems[0].toString()) {
      case "99": {
        // console.log('KEEEEK');
        return gems[0] === gems[1] && gems[0] === gems[2] && gems[0] === gems[3];
      }
      default: {
        // console.log(gems[0], gems[1], gems[2]);
        return gems[0] === gems[1] && gems[0] === gems[2];
      }
    }
    // return gems[0] === gems[1] && gems[0] === gems[2];
  }

  gemAt(row, col): GameGem | null {
    if (row < 0 || row >= this.gameOptions.rows || col < 0 || col >= this.gameOptions.cols) {
      return null;
    }
    return this._gameArray[row][col];
  }

  // resize the gem after clicking on it
  gemSelect(pointer: Pointer) {
    if (this.canPick) {
      this._gameArray.forEach(gemRow => gemRow.forEach(gem => this.scaleGem(gem.figureSprite)));

      this.dragging = true;
      const col = Math.floor(pointer.worldX / (this.gameOptions.gemSize.width + 5));
      const row = Math.floor(pointer.worldY / (this.gameOptions.gemSize.height + 5));
      const pickedGem = this.gemAt(row, col);
      if (pickedGem !== null) {
        // @ts-ignore
        // console.log(col, row, pickedGem, this.selectedGem);
        if (this.selectedGem === null) {
          this.playSound("select");
          // pickedGem.gemSprite.setScale(1.2);
          this.scaleGem(pickedGem.figureSprite, 1.2);
          pickedGem.figureSprite.setDepth(1);
          this.selectedGem = pickedGem;
        } else {
          // console.log(this.areTheSame(pickedGem, this.selectedGem));
          if (this.areTheSame(pickedGem, this.selectedGem)) {
            this.scaleGem(this.selectedGem.figureSprite);
            // this.selectedGem.gemSprite.setScale(1);
            this.selectedGem = null;
          } else {
            if (this.areNext(pickedGem, this.selectedGem)) {
              // this.selectedGem.gemSprite.setScale(1);
              this.match3Service.blockRedrawButton = true;
              this.scaleGem(this.selectedGem.figureSprite);
              this.swapGems(this.selectedGem, pickedGem, true);
            } else {
              // this.selectedGem.gemSprite.setScale(1);
              // pickedGem.gemSprite.setScale(1.2);

              this.scaleGem(this.selectedGem.figureSprite, 1.2);
              this.scaleGem(pickedGem.figureSprite, 1.2);

              this.selectedGem = pickedGem;
            }
          }
        }
      }
    }
  }

  startSwipe(pointer) {
    if (this.dragging && this.selectedGem != null) {
      const deltaX = pointer.downX - pointer.x;
      const deltaY = pointer.downY - pointer.y;
      let deltaRow = 0;
      let deltaCol = 0;
      if (deltaX > this.gameOptions.gemSize.width / 2 && Math.abs(deltaY) < this.gameOptions.gemSize.height / 4) {
        deltaCol = -1;
      }
      if (deltaX < -this.gameOptions.gemSize.width / 2 && Math.abs(deltaY) < this.gameOptions.gemSize.height / 4) {
        deltaCol = 1;
      }
      if (deltaY > this.gameOptions.gemSize.width / 2 && Math.abs(deltaX) < this.gameOptions.gemSize.height / 4) {
        deltaRow = -1;
      }
      if (deltaY < -this.gameOptions.gemSize.width / 2 && Math.abs(deltaX) < this.gameOptions.gemSize.height / 4) {
        deltaRow = 1;
      }
      if (deltaRow + deltaCol !== 0) {
        const pickedGem = this.gemAt(this.getGemRow(this.selectedGem) + deltaRow, this.getGemCol(this.selectedGem) + deltaCol);
        if (pickedGem !== null) {
          // this.selectedGem.gemSprite.setScale(1);
          this.match3Service.blockRedrawButton = true;
          this.scaleGem(this.selectedGem.figureSprite);

          this.swapGems(this.selectedGem, pickedGem, true);
          // this.dragging = false; not necessary anymore
        }
      }
    }
  }

  stopSwipe() {
    this.dragging = false;
  }

  areTheSame(figure1: GameGem, figure2: GameGem) {
    // console.log('ROW', this.getGemRow(gem1), this.getGemRow(gem2));
    // console.log('COL', this.getGemCol(gem1), this.getGemCol(gem2));
    return this.getGemRow(figure1) === this.getGemRow(figure2) && this.getGemCol(figure1) === this.getGemCol(figure2);
  }

  getGemRow(figure: GameGem) {
    return Math.floor((figure.figureSprite.y + 5) / (this.gameOptions.gemSize.height + 5));
  }

  getGemCol(figure: GameGem) {
    return Math.floor((figure.figureSprite.x + 5) / (this.gameOptions.gemSize.width + 5));
  }

  areNext(figure1: GameGem, figure2: GameGem) {
    return Math.abs(this.getGemRow(figure1) - this.getGemRow(figure2)) + Math.abs(this.getGemCol(figure1) - this.getGemCol(figure2)) === 1;
  }

  swapGems(gem1: GameGem, gem2: GameGem, swapBack) {
    this.playSound("swap");
    this.swappingGems = 2;
    this.canPick = false;
    this.dragging = false;
    const fromColor = gem1.figureId;
    const fromSprite = gem1.figureSprite;
    const toColor = gem2.figureId;
    const toSprite = gem2.figureSprite;
    const gem1Row = this.getGemRow(gem1);
    const gem1Col = this.getGemCol(gem1);
    const gem2Row = this.getGemRow(gem2);
    const gem2Col = this.getGemCol(gem2);
    this._gameArray[gem1Row][gem1Col].figureId = toColor;
    this._gameArray[gem1Row][gem1Col].figureSprite = toSprite;
    this._gameArray[gem2Row][gem2Col].figureId = fromColor;
    this._gameArray[gem2Row][gem2Col].figureSprite = fromSprite;
    this.tweenGem(gem1, gem2, swapBack);
    this.tweenGem(gem2, gem1, swapBack);
    this._gameArray.forEach(gemRow => gemRow.forEach(gem => this.scaleGem(gem.figureSprite)));
  }

  tweenGem(gem1, gem2, swapBack) {
    const row = this.getGemRow(gem1);
    const col = this.getGemCol(gem1);
    const gemPos = this.genGemPosition(col, row);
    this.tweens.add({
      targets: this._gameArray[row][col].figureSprite,
      x: gemPos.x,
      y: gemPos.y,
      duration: this.gameOptions.swapSpeed,
      callbackScope: this,
      ease: Phaser.Math.Easing.Quartic.InOut,
      onComplete: () => {
        this.swappingGems--;
        if (this.swappingGems === 0) {
          if (!this.matchInBoard() && swapBack) {
            this.swapGems(gem1, gem2, false);
          } else {
            if (this.matchInBoard()) {
              this.match3Service.decreaseMovesAmount();
              this.handleMatches();
            } else {
              this.canPick = true;
              this.selectedGem = null;
              this.match3Service.blockRedrawButton = false;
            }
          }
        }
      },
    });
  }

  matchInBoard() {
    for (let i = 0; i < this.gameOptions.rows; i++) {
      for (let j = 0; j < this.gameOptions.cols; j++) {
        if (this.isMatch(i, j)) {
          return true;
        }
      }
    }
    return false;
  }

  handleMatches() {
    this.cleanupRemoveMap();
    this.markMatches(M3Direction.HORIZONTAL);
    this.markMatches(M3Direction.VERTICAL);
    this.destroyGems();
  }

  cleanupRemoveMap() {
    this.removeMap = [];
    for (let i = 0; i < this.gameOptions.rows; i++) {
      this.removeMap[i] = [];
      for (let j = 0; j < this.gameOptions.cols; j++) {
        this.removeMap[i].push(0);
      }
    }
  }

  markMatches(direction: M3Direction) {
    if (direction === M3Direction.HORIZONTAL) {
      for (let i = 0; i < this.gameOptions.rows; i++) {
        let matchedFiguresAmount = 1; // the number of elements that have exactly the same value going forward
        let previouslyCheckedFigure: GameGem = null; // the figure that was checked in the previous loop cycle
        let startStreakRowNumber = 0; // number of the row where the streak starts
        let currentFigure: GameGem = null; // figure that is currently checked

        // loop through the columns
        for (let j = 0; j < this.gameOptions.cols; j++) {
          currentFigure = this.gemAt(i, j);

          // if previous figure and current has exactly same value increment amount of matched figures
          if (currentFigure.figureId === previouslyCheckedFigure?.figureId) {
            matchedFiguresAmount++;
            // continue;
          }

          if (currentFigure.figureId !== previouslyCheckedFigure?.figureId || j === this.gameOptions.cols - 1) {
            if (
              (previouslyCheckedFigure?.figureId !== 99 && matchedFiguresAmount >= 3) ||
              (previouslyCheckedFigure?.figureId == 99 && matchedFiguresAmount >= 4)
            ) {
              this.playSound("collect");
              this.match3Service.calculateMovePoints(
                previouslyCheckedFigure.figureId,
                matchedFiguresAmount,
                previouslyCheckedFigure?.figureId == 99
              );
              this.movedAfterReset = true;
              for (let k = 0; k < matchedFiguresAmount; k++) {
                this.removeMap[i][startStreakRowNumber + k]++;
              }
            }
            startStreakRowNumber = j;
            matchedFiguresAmount = 1;
            previouslyCheckedFigure = currentFigure;
          }
        }
      }
    } else {
      // iterate through the row
      for (let j = 0; j < this.gameOptions.cols; j++) {
        let matchedFiguresAmount = 1; // the number of elements that have exactly the same value going forward
        let previouslyCheckedFigure: GameGem = null; // the figure that was checked in the previous loop cycle
        let startStreakColumnNumber = 0; // number of the column where the streak starts
        let currentFigure: GameGem = null; // figure that is currently checked

        // loop through the row
        for (let i = 0; i < this.gameOptions.rows; i++) {
          currentFigure = this.gemAt(i, j);

          // if previous figure and current has exactly same value increment amount of matched figures
          if (currentFigure.figureId === previouslyCheckedFigure?.figureId) {
            matchedFiguresAmount++;
            // continue;
          }

          // if no then check the figures:
          if (currentFigure.figureId !== previouslyCheckedFigure?.figureId || i === this.gameOptions.rows - 1) {
            // for a streak
            if (
              (previouslyCheckedFigure?.figureId !== 99 && matchedFiguresAmount >= 3) ||
              (previouslyCheckedFigure?.figureId == 99 && matchedFiguresAmount >= 4)
            ) {
              this.playSound("collect");
              this.match3Service.calculateMovePoints(
                previouslyCheckedFigure.figureId,
                matchedFiguresAmount,
                previouslyCheckedFigure?.figureId == 99
              );
              this.movedAfterReset = true;
              for (let k = 0; k < matchedFiguresAmount; k++) {
                this.removeMap[startStreakColumnNumber + k][j]++;
              }
            }

            // and then reset the values
            startStreakColumnNumber = i;
            matchedFiguresAmount = 1;
            previouslyCheckedFigure = currentFigure;
          }
        }
      }
    }
  }

  destroyGems() {
    let destroyed = 0;
    for (let i = 0; i < this.gameOptions.rows; i++) {
      for (let j = 0; j < this.gameOptions.cols; j++) {
        if (this.removeMap[i][j] > 0) {
          destroyed++;
          this.tweens.add({
            targets: this._gameArray[i][j].figureSprite,
            alpha: 0.1,
            duration: this.gameOptions.destroySpeed,
            callbackScope: this,
            onComplete: () => {
              destroyed--;
              this._gameArray[i][j].figureSprite.visible = false;
              this._poolArray[j].push(this._gameArray[i][j].figureSprite);
              if (destroyed === 0) {
                this.makeGemsFall();
                this.replenishField();
              }
            },
          });
          this._gameArray[i][j].isEmpty = true;
        }
      }
    }
  }

  makeGemsFall() {
    for (let i = this.gameOptions.rows - 2; i >= 0; i--) {
      for (let j = 0; j < this.gameOptions.cols; j++) {
        if (!this._gameArray[i][j].isEmpty) {
          const fallTiles = this.holesBelow(i, j);
          if (fallTiles > 0) {
            this.tweens.add({
              alpha: 1,
              ease: Phaser.Math.Easing.Quartic.Out,
              targets: this._gameArray[i][j].figureSprite,
              y: this._gameArray[i][j].figureSprite.y + fallTiles * this.gameOptions.gemSize.height + 5 * fallTiles,
              duration: this.gameOptions.fallSpeed * fallTiles,
            });
            this._gameArray[i + fallTiles][j] = {
              figureSprite: this._gameArray[i][j].figureSprite,
              figureId: this._gameArray[i][j].figureId,
              isEmpty: false,
            };
            this._gameArray[i][j].isEmpty = true;
          }
        }
      }
    }
  }

  holesBelow(row, col) {
    let result = 0;
    for (let i = row + 1; i < this.gameOptions.rows; i++) {
      if (this._gameArray[i][col].isEmpty) {
        result++;
      }
    }
    return result;
  }

  replenishField() {
    let replenished = 0;
    for (let j = 0; j < this.gameOptions.cols; j++) {
      const emptySpots = this.holesInCol(j);
      if (emptySpots > 0) {
        for (let i = 0; i < emptySpots; i++) {
          replenished++;
          const randomFigure = this.drawColor();
          this._gameArray[i][j].figureId = randomFigure.id;
          this._gameArray[i][j].figureSprite = this._poolArray[j].pop();
          this._gameArray[i][j].figureSprite.setTexture(`match3-color-${randomFigure.id}`);
          this.scaleGem(this._gameArray[i][j].figureSprite);
          this._gameArray[i][j].figureSprite.visible = true;
          this._gameArray[i][j].figureSprite.x = (this.gameOptions.gemSize.width + 5) * j + this.gameOptions.gemSize.width / 2;
          this._gameArray[i][j].figureSprite.y =
            this.gameOptions.gemSize.height / 2 - (emptySpots - i) * this.gameOptions.gemSize.height + 5 * i;
          this._gameArray[i][j].figureSprite.alpha = 1;
          this._gameArray[i][j].isEmpty = false;
          this.tweens.add({
            targets: this._gameArray[i][j].figureSprite,
            y: this.gameOptions.gemSize.height * i + this.gameOptions.gemSize.height / 2 + 5 * i,
            duration: this.gameOptions.fallSpeed * emptySpots,
            callbackScope: this,
            alpha: 1,
            ease: Phaser.Math.Easing.Quartic.Out,
            onComplete: function () {
              replenished--;
              if (replenished === 0) {
                if (this.matchInBoard()) {
                  this.time.addEvent({
                    delay: 250,
                    callback: this.handleMatches(),
                  });
                } else {
                  this.canPick = true;
                  this.selectedGem = null;
                  this.match3Service.blockRedrawButton = false;

                  if (this.removeBoxes) {
                    this.removeBoxes = false;
                    this.removeAllBoxes();
                  }
                }
              }
            },
          });
        }
      }
    }
    // this.isAnyMove();
  }

  holesInCol(col) {
    let result = 0;
    for (let i = 0; i < this.gameOptions.rows; i++) {
      if (this._gameArray[i][col].isEmpty) {
        result++;
      }
    }
    return result;
  }

  private drawColor() {
    // console.log("getColors", this.gameOptions.gemColors, Phaser.Math.Between(1, this.gameOptions.gemColors));
    // return Phaser.Math.Between(1, this.colorsMap.length - 1); // Phaser.Math.Between(1, this.gameOptions.gemColors);
    const randomNumber = Math.floor(Math.random() * this.gameConfiguration.figure.length);
    return this.gameConfiguration.figure[randomNumber];
  }

  /**
   * Check if there is a move available
   */
  isAnyMove() {
    let moveAvailable = false;
    const mappedArray = this._gameArray.map(row =>
      row.map(gem => {
        gem?.figureSprite.setTint(0xffffff);
        return gem.figureId;
      })
    );

    let currentType = -1;
    let lastType = -1;
    let sameTypesCount = 1;
    let currentRow = 0;

    // going horizontally
    for (let row = 0; row < this.gameOptions.rows; row++) {
      for (let col = 0; col < this.gameOptions.cols; col++) {
        currentType = mappedArray[row][col];

        // if new row reset variables
        if (currentRow !== row) {
          sameTypesCount = 1;
          lastType = -1;
        }

        // set new current row value
        if (col === 0) {
          currentRow = row;
        }

        // if types are the same increase same types counter
        if (currentType === lastType) {
          sameTypesCount++;

          // if minimal two same types counted, need to look for one more
          if (sameTypesCount === 2) {
            // console.log('found two at ', row, col);

            const positionsToCheck = this.findPossibleMovesForStreak(row, col, M3Direction.HORIZONTAL);
            positionsToCheck.forEach(position => {
              const gem = this.gemAt(position.y, position.x);
              if (gem.figureId === currentType) {
                moveAvailable = true;
                const gemSprite = gem?.figureSprite as Sprite;

                if (this.gameOptions.showMoves && gemSprite) {
                  gemSprite.setTint(0xff0000);
                }
              }
            });
            // console.log(positionsToCheck);
          }

          // if (sameTypesCount > 2) {
          //   console.log('found three at ', row, col);
          //   return true;
          //   return true;
          // }
        } else {
          sameTypesCount = 1;
        }
        lastType = currentType;
      }
    }
    // if (!moveAvailable && this.gameOptions.showMoves) {
    //   alert('brak ruchu');
    // }
    if (!moveAvailable) {
      this.restartBoard();
      //
      // console.log('No move found');
      // setTimeout(() => {
      //   this.restartBoard();
      // }, this.movedAfterReset ? 1000 : 0);
    }
    return moveAvailable;
  }

  // find grid positions to check if move is available
  private findPossibleMovesForStreak(row: number, col: number, direction: M3Direction) {
    let streakStart;
    let streakEnd;
    const positions: { x: number; y: number }[] = [];
    switch (direction) {
      case M3Direction.HORIZONTAL:
        streakStart = col - 1;
        streakEnd = col;

        positions.push({ y: row + 1, x: streakStart - 1 });
        positions.push({ y: row - 1, x: streakStart - 1 });

        positions.push({ y: row + 1, x: streakEnd + 1 });
        positions.push({ y: row - 1, x: streakEnd + 1 });

        positions.push({ y: row, x: streakEnd + 2 });
        positions.push({ y: row, x: streakEnd - 2 });

        break;
      case M3Direction.VERTICAL:
        streakStart = row - 1;
        streakEnd = row;

        positions.push({ y: streakStart - 1, x: col - 1 });
        positions.push({ y: streakStart + 1, x: col - 1 });

        positions.push({ y: streakStart + 1, x: col + 1 });
        positions.push({ y: streakEnd - 1, x: col + 1 });

        positions.push({ y: streakStart + 2, x: col });
        positions.push({ y: streakEnd - 2, x: col });
        break;
    }
    return positions.filter(
      position => position.x > -1 && position.y > -1 && position.x < this.gameOptions.cols && position.y < this.gameOptions.rows
    );
  }

  toggleSounds(isOn: boolean) {
    if (isOn) {
      this.music.resume();
    } else {
      this.music.pause();
    }
  }
}
