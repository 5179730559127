import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from "../../../../../shared/shared.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { AuctionCardComponent } from "../../base/custom/components/auction-card/auction-card.component";
import { AuctionInfoComponent } from "../../base/custom/components/auction-info/auction-info.component";
import { AuctionBidComponent } from "../../base/custom/dialogs/auction-bid/auction-bid.component";
import { AuctionDetailsComponent } from "../../base/custom/dialogs/auction-details/auction-details.component";
import { AuctionsComponent } from "../../base/custom/dialogs/auctions/auctions.component";
import { OwPerfectScrollbarModule } from '@oskarwegner/ow-perfect-scrollbar';

@NgModule({
  declarations: [AuctionsComponent, AuctionCardComponent, AuctionInfoComponent, AuctionBidComponent, AuctionDetailsComponent],
  imports: [CommonModule, SharedUiMobileModule, SharedModule, FormsModule, ReactiveFormsModule, OwPerfectScrollbarModule],
})
export class AuctionsModule {}
