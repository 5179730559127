<div class="preview-container" *ngIf="!playerService.isActiveMe">
  <button
    class="c-square small c-primary"
    (click)="changeSceneService.changePlayerToMe()"
  >
    <i class="fas fa-sign-out-alt"></i>
  </button>

  <div class="player-name">
    {{ player.first_name }} {{ player.last_name }}
  </div>
  <div class="player-branch">
    {{ player.title_id.name }} | {{ player.branch_name }}
  </div>
</div>
