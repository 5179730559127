import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { Pane } from "tweakpane";

import { GameService } from "../../../../game/services/game.service";
import { Match3Game } from "../../game/match3.game";
import { Match3Scene } from "../../game/match3.scene";
import { Match3Service } from "../../match3.service";
import { M3GameDefinitions, M3GameOptions } from "../../models/match3.model";
import ScaleModes = Phaser.Scale.ScaleModes;
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../core/decorators/ow-inject.decorator";
import { STOCK_VIEW } from "../../../../game/game-ui/shared-ui/mobile/consts/stock-view.const";
import { DialogService } from "../../../../shared/providers/dialog.service";
import { Match3Config } from "../../../config/match3.config";

@Component({
  selector: "app-match3-minigame",
  templateUrl: "./match3-minigame.component.html",
  providers: [Match3Service],
})
export class Match3MinigameComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<Match3MinigameComponent>;
  @OwInject(Match3Service) match3Service: Match3Service;
  @OwInject(GameService) gameService: GameService;
  @OwInject(ChangeDetectorRef) cd: ChangeDetectorRef;
  @OwInject(ActivatedRoute) activatedRoute: ActivatedRoute;
  @OwInject(ElementRef<HTMLElement>) host: ElementRef<HTMLElement>;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data: {
    game: number;
    payment: number;
    unfinishedStatus: any;
  };

  nextLevelClicked = false; // TODO - refactor the game statuses
  STOCK_VIEW = STOCK_VIEW;
  tooltipText = ["Dowolna pamiątka", "Bęben", "Kapelusz", "Waza", "Maska", "Popiersie", "Słoń", "Lew", "Ślad"];
  isLoading = false;

  private game: Match3Game;
  private debugPane = null;
  private gameOptions: M3GameOptions;
  private paramsSub: Subscription;

  ngOnInit(): void {
    this.match3Service.resetGame();
    this.gameOptions = {
      // next three properties will be set at game creation step
      cols: null,
      rows: null,
      gemColors: null,

      gemSize: {
        width: 50,
        height: 50,
      },
      swapSpeed: 550,
      fallSpeed: 200,
      destroySpeed: 600,
      showMoves: false,
    };

    this.match3Service
      .getGameDefinitions(this.data.game)
      .pipe(take(1))
      .subscribe(d => {
        this.match3Service.setCurrentGame(this.data);

        // progress can be null; change the flag so it will trigger later screens. It need to be rewritten when the game will be status-based
        if (this.data?.unfinishedStatus?.["progress"]) {
          this.nextLevelClicked = true;
        }

        this.cd.detectChanges();
        // this.startGame();
      });

    // this.addDebugPane();
  }

  moveToTheNextLevel() {
    this.nextLevelClicked = true;
  }

  addDebugPane() {
    // TODO - it doesn't work
    this.debugPane = new Pane({
      expanded: false,
      document: window.document,
    });
  }

  toggleDebug() {
    // @ts-ignore
    this.debugPane.expanded = !this.debugPane.expanded;
  }

  toggleFullScreen(scene: Match3Scene) {
    if (scene.scale.isFullscreen) {
      scene.scale.stopFullscreen();
    } else {
      scene.scale.startFullscreen();
    }
  }

  restartScene() {
    // this.game.canvas.width = Math.min(589, this.gameOptions.cols * (this.gameOptions.gemSize.width + 5)) + 10;
    // this.game.canvas.height = Math.min(241, this.gameOptions.rows * (this.gameOptions.gemSize.height + 5)) + 10;
    // this.game.scene.scenes[0].scene.restart();
    this.restartGame();
  }

  restartGame() {
    this.game?.destroy(true);
    this.match3Service.levelChange = false;
    this.match3Service.gameStarted = false;
    this.cd.detectChanges();
    this.createGame(this.match3Service.gameDefs);
    this.resumeGame();
  }

  private createGame(gameDefs: M3GameDefinitions) {
    const isMobile: boolean = this.gameService.deviceDetector.isMobile();
    const sizeFactor = isMobile ? 1 : 2;

    const currentLevel = this.match3Service.currentLevel;
    this.gameOptions.cols = gameDefs.levels[currentLevel - 1].cols;
    this.gameOptions.rows = gameDefs.levels[currentLevel - 1].rows;
    this.gameOptions.gemColors = gameDefs.levels[currentLevel - 1].colors.length;
    this.game = this.match3Service.createGame({
      parent: "match3-target",
      scene: Match3Scene,
      gameService: this.gameService,
      match3GameService: this.match3Service,
      width: Math.min(2 * 589, this.gameOptions.cols * (this.gameOptions.gemSize.width + 5)) + 10,
      height: Math.min(2 * 271, this.gameOptions.rows * (this.gameOptions.gemSize.height + 5)) + 10,
      gameOptions: this.gameOptions,
      gameDefinitions: gameDefs,
      loader: {
        // @ts-ignore
        imageLoadType: "HTMLImageElement",
        crossOrigin: "anonymous"
      },
      images: {
        default: 'assets/phaser/default.png',
        missing: 'assets/phaser/missing.png',
        white: 'assets/phaser/white.png'
      },
      scale: {
        mode: ScaleModes.NONE,
        fullscreenTarget: "body",
        width: Math.min(2 * 589, this.gameOptions.cols * (this.gameOptions.gemSize.width + 5)) + 10,
        height: Math.min(2 * 271, this.gameOptions.rows * (this.gameOptions.gemSize.height + 5)) + 10,
      },
      transparent: true,
    });
  }

  startGame() {
    // this.match3Service.gameStarted = true;
    // this.createGame(this.match3Service.gameDefs);
    // return;
    this.isLoading = true;

    this.match3Service.finishUnfinishedGames().subscribe(
      () => {
        this.match3Service.startGame(this.data.game).subscribe(
          currentGame => {
            this.match3Service.gameStarted = true;
            this.nextLevelClicked = false;
            this.cd.detectChanges();
            this.createGame(this.match3Service.gameDefs);
          },
          err => {},
          () => {
            this.isLoading = false;
          }
        );
      },
      err => {
        this.isLoading = false;
      }
    );
  }

  resumeGame() {
    this.match3Service.gameStarted = true;
  }

  nextLevel() {
    this.match3Service.nextLevel();
    this.nextLevelClicked = false;
    this.restartGame();
  }

  private setDebug() {
    // fetch defs
    // this.match3Service.getGameDefinitions().subscribe();

    this.debugPane
      .addInput(this.gameOptions, "cols", {
        min: 3,
        max: 20,
        step: 1,
      })
      .on("change", ev => {
        this.restartScene();
      });

    this.debugPane
      .addInput(this.gameOptions, "rows", {
        min: 3,
        max: 15,
        step: 1,
      })
      .on("change", ev => {
        this.restartScene();
      });

    this.debugPane
      .addInput(this.gameOptions.gemSize, "width", {
        min: 10,
        max: 100,
        step: 1,
      })
      .on("change", ev => {
        this.restartScene();
      });

    this.debugPane
      .addInput(this.gameOptions.gemSize, "height", {
        min: 10,
        max: 100,
        step: 1,
      })
      .on("change", ev => {
        this.restartScene();
      });

    this.debugPane
      .addInput(this.gameOptions, "gemColors", {
        min: 3,
        max: 8,
        step: 1,
      })
      .on("change", ev => {
        this.restartScene();
      });

    // this.debugPane.addInput(this.gameOptions, 'showMoves').on('change', (ev) => {
    //   this.restartScene();
    // });

    const btn = this.debugPane.addButton({
      title: "Restart",
      label: "Plansza", // optional
    });

    btn.on("click", () => {
      this.restartScene();
    });

    this.debugPane.addSeparator();

    const btnFS = this.debugPane.addButton({
      title: "Wl/wyl",
      label: "Pelny ekran", // optional
    });

    btnFS.on("click", () => {
      this.toggleFullScreen(this.game.scene.scenes[0] as Match3Scene);
    });

    const btnHide = this.debugPane.addButton({
      title: "Ukryj",
      label: "Debug", // optional
    });

    btnHide.on("click", () => {
      this.toggleDebug();
    });
  }

  ngOnDestroy(): void {
    try {
      this.game.destroy(true);
    } catch (e) {
      // it may return an error when the phaser wasn't initialized
    }

    this.paramsSub?.unsubscribe();
    this.match3Service.resetGame();
  }

  exitMinigame(forceMute?: boolean) {
    this.dialogService.closeAll();
  }
}
