import { Component, OnInit } from '@angular/core';
import { ApiPlayerService } from '../../../api/custom/services/api-player.service';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../../shared/providers/dialog.service';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { Branch } from '../../../../../interfaces/branch.interface';
import { NavigationUi } from '../../../../shared-ui/interfaces/navigation-ui.interface';
import { BranchPreviewComponent } from '../branch-preview/branch-preview.component';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
})
export class BranchesComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiPlayerService) apiPlayerService: ApiPlayerService;
  @OwInject(DialogService) dialogService: DialogService;

  branches: Branch[];

  navigation: NavigationUi = {
    currentPage: 1,
    maxPage: null,
  };

  ngOnInit() {
    this.getBranches();
  }

  getBranches(page: number = 1) {
    this.apiPlayerService.branches({page: page, limit: 12})
      .subscribe((resp: any) => {
        this.navigation.maxPage = resp.headers.get('x-pages-count');
        this.branches = resp.body;
        this.navigation.currentPage = page;

      });
  }

  pageChange(page: number) {
    this.getBranches(page);
  }

  openBranch(branch: Branch) {
    this.dialogService.open(BranchPreviewComponent, {
      data: {
        branch,
      }
    });
  }
}
