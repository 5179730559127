import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { checkPasswordTheSame } from '../../../../helpers/validators-form-password-the-same.helper';
import { TokenObject } from '../../../../interfaces/token-object';
import { ApiOptions } from '../../../../../../core/interfaces/api-options';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { AuthService } from '../../../../providers/auth.service';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { ApiService } from '../../../../../../core/providers/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../store/state';
import { GuiService } from '../../../../../game/services/gui.service';
import { UtilityActions } from '../../../../../../store/utility';
import { APP_ROUTING_ABSOLUTE_PATH } from '../../../../../../consts/routing-app-absolute.const';

@Component({
  selector: 'app-custom-register-confirm',
  templateUrl: './registration-confirm.component.html',
})
export class RegistrationConfirmComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(ActivatedRoute) activatedRoute: ActivatedRoute;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(Router) router: Router;
  @OwInject(GuiService) guiService: GuiService;
  form: FormGroup;
  token: string;

  ngOnInit() {
    this.subscribeRoutingParams();
  }

  subscribeRoutingParams() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.hasOwnProperty('token')) {
        this.token = params['token'];
        this.clearForm();
      }
    });
  }

  clearForm() {
    this.form = this.fb.group({
      token: this.fb.control(this.token, [Validators.required]),
      oldPassword: this.fb.control(null, [Validators.required]),
      password: this.fb.control(null, [Validators.required]),
      passwordRepeat: this.fb.control(null, [Validators.required]),
    }, {
      validator: checkPasswordTheSame,
    });
  }

  registrationConfirm() {
    const data: RegistrationConfirmRequestData = {
      token: this.form.value.token,
      password: this.form.value.password,
      old_password: this.form.value.oldPassword,
    };

    this.registrationConfirmRequest(data)
      .subscribe((resp: TokenObject) => {
        this.dispatchToken(resp);
        this.routeToPlayerChoose();
      });
  }

  registrationConfirmRequest(data: RegistrationConfirmRequestData) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true
    };

    return this.apiService.post('registration/confirm', options);
  }

  dispatchToken(tokenObject: TokenObject) {
    this.store.dispatch(new UtilityActions.SetToken(tokenObject));
  }

  routeToPlayerChoose() {
    this.guiService.isSplashShow.next(true);

    setTimeout(() => {
      this.router.navigate([APP_ROUTING_ABSOLUTE_PATH.PLAYER_CHOOSE]);
    });
  }
}

interface RegistrationConfirmRequestData {
  token: string;
  password: string;
  old_password: string;
}
