import { Component, OnInit } from "@angular/core";

import { AbstractMessageDetailsHiddenRewardComponent } from "../../../../abstract/core/abstract-message-details-hidden-reward.component";

@Component({
  selector: "app-message-details-hidden-reward",
  templateUrl: "./message-details-hidden-reward.component.html",
})
export class MessageDetailsHiddenRewardComponent extends AbstractMessageDetailsHiddenRewardComponent implements OnInit {
  icon = "generic_finding";
  path = "ilustration";

  ngOnInit() {
    this.getProducts();
    this.setMessageProperty();
    this.afterInit();
  }

  setMessageProperty() {
    if (this.message.parameters.message) {
      /**
       * ICON
       */
      const icon = this.message.parameters.message.icon;
      if (icon) {
        this.icon = icon;
        this.path = this.message?.teaser.toLowerCase().includes('punkt') ? 'ilustration' : "ui";
      }
      /**
       * TITLE
       */
      const title = this.message.parameters.message.title;
      if (title) {
        this.message.title = title;
      }
      /**
       * DESCRIPTION
       */
      const description = this.message.parameters.message.description;
      if (description) {
        this.message.description = description;
      }
    }
  }
}
