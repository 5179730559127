<div class="global-dialog ow-dialog c-window-long">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks" *ngIf="!data.eventShopId">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Sklepy</span>
      </div>
    </div>
  </div>

  <loading></loading>

  <!-- TITLE -->
  <div
    *ngIf="eventShops"
    class="title"
  >
    <div class="title-separator"></div>
    <span>Wybierz sklep</span>
    <div class="title-separator"></div>
  </div>

  <ow-perfect-scrollbar [scrollGradient]="eventShops?.length">
    <div class="event-shops-container">
      <ng-container *ngFor="let eventShop of eventShops; let i = index">
        <ng-container *ngIf="!isCC || (isCC && (eventShop.event_shop_id !== TAO_SHOP_ID || (eventShop.event_shop_id === TAO_SHOP_ID && playerService.player.gui_unlocks['cc_market_playable'])))">
          <div
            *ngIf="eventShop.event_shop_id !== 100 && eventShop['is_active']"
            class="event-shop-container"
            [class]="eventShop['color']"
            [class.deactivated]="!isShopEventDeactivate(eventShop)"
            [class.cursor-none]="eventShop.event_shop_id === TAO_SHOP_ID && !isShopEventDeactivate(eventShop)"
            (click)="openShopDetails(eventShop)"
          >
            <div class="shop-title">
              {{ eventShop.title }}
            </div>

            <div
              *ngIf="({currency_id: eventShop.currencyId, amount: null} | singleCurrency) as currency"
              class="shop-details"
            >
              <div class="shop-currency">
                <img
                  class="icon"
                  [src]="currency.iconUrlBig"
                >
                <ng-container *ngIf="currency.type === 'player'">
                  {{ (playerService.player.currency_balances | currencyGameBalance:currency.key).balance | NumberWithSpaces }}
                </ng-container>
                <ng-container *ngIf="currency.type === 'user'">
                  {{ (userService.me.currency_balances | currencyGameBalance:currency.key).balance | NumberWithSpaces }}
                </ng-container>
              </div>

              <div class="shop-info">
                <div class="item">
                  <img [src]="'cointime.png' | asset:'ui'" class="icon"/>
                  <span [matTooltip]=" eventShop.event_shop_id !== 1
                ? 'Czas na zbieranie żetonów'
                : 'Czas na zbieranie złotych talarów'">
                {{ eventShop.currency_collection_start | date: 'dd.MM.yy'}} -
                    {{ eventShop.currency_collection_end | date: 'dd.MM.yy'}}
              </span>
                </div>

                <div class="item">
                  <img
                    *ngIf="!eventShop.is_repeatable"
                    [src]="'exclamation.png' | asset:'ui'"
                    class="icon not-repeatable"
                    [matTooltip]="eventShop.event_shop_id !== 1
                  ? 'Ten kolor żetonów wykorzystasz tylko w podanym terminie'
                  : 'Złote talary wykorzystasz tylko w podanym terminie'"
                    matTooltipPosition="above"
                    matTooltipClass="delayed"
                  />
                  <img [src]="'shop.png' | asset:'ui'" class="icon"/>
                  <span [matTooltip]="eventShop.event_shop_id !== 1
                ? 'Czas na wymianę żetonów'
                : 'Czas na wymianę złotych talarów'"
                  >
                {{ eventShop.open_start | date: 'dd.MM.yy'}} -
                    {{ eventShop.open_end | date: 'dd.MM.yy'}}
              </span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ow-perfect-scrollbar>
</div>
