<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="closeOrCloseAll()"
  ></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button
    [preventDefault]="true"
    (clicked)="close()"
    *ngIf="messageOpenDetailsData?.['fromList'] === true && this.showBackButton"
  ></m-ui-back-button>

  <!-- EXPERIENCE -->
  <div
    class="experience"
    *ngIf="(message?.exp_points || this.message?.parameters.exp_points) as expPoints"
    [matTooltip]="'Punkty doświadczenia'"
    [matTooltipShowDelay]="400"
    [matTooltipPosition]="'above'"
    [matTooltipClass]="'exp-tooltip'"
  >
    <div class="inner-wrapper">
      <img [src]="'exp.png' | asset : 'ui'" />
      <p>+{{expPoints}}</p>
    </div>
  </div>

  <div class="animal-container" *ngIf="message">

    <h2 class="animal-title" [innerHTML]="message.title | safe:'html'"></h2>

    <!-- REWARD IMAGE -->
    <div class="animal-image">
      <img [src]="(icon + '.png' | asset: path)"/>
    </div>

    <!-- TEASER -->
    <div class="teaser" [innerText]="message.sender_name"></div>

    <!-- DESCRIPTION -->
    <div class="inner-html-description" [innerHTML]="message.description | safe:'html'"></div>

    <!-- PRIZES -->
    <ng-container *ngIf="message.parameters.currencies?.length || message.parameters.prizes?.length">

      <div class="parameters">
        <!-- CURRENCIES -->
        <m-ui-currency
          *ngFor="let currency of message.parameters.currencies"
          [item]="currency"
          [stockView]="STOCK_VIEW.CUSTOM"
          [customIconSize]="'big'"
          [customClassStock]="'stock-san-a'"
          [lack]="false"
        ></m-ui-currency>

        <!-- PRODUCTS -->
        <m-ui-product
          *ngFor="let product of message.parameters.prizes"
          [item]="product"
          [other]="{value: '+ '}"
          [stockView]="STOCK_VIEW.CUSTOM"
          [customIconSize]="'big'"
          [customClassStock]="'stock-san-a'"
          [lack]="false"
        ></m-ui-product>
      </div>
    </ng-container>
  </div>
</div>
