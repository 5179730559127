import { Component, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { generateEachPages } from "../../../../../../../shared/helpers/generate-pages.helper";
import { AbstractWarehouseComponent } from "../../../../abstract/core/abstract-warehouse.component";
import { forkJoin } from "rxjs";
import {StorageBase} from '../../../../interfaces/core/storage.interface';
import {StorageWithCategories} from '../../../../interfaces/core/storage-with-categories.interface';
import {tap} from 'rxjs/operators';

const FALLBACK_MOCKUPS = {
  6: {
    title: "",
    description: "W tej chwili żadne z dzikich zwierząt nie wymaga interwencji i opieki ze strony specjalistów pracujących w naszej wiosce.",
    icon: "no_animal.png",
    path: "ui",
    enabled: true,
  },
};

@Component({
  selector: "app-san-warehouse",
  templateUrl: "./warehouse.component.html",
})
export class WarehouseComponent extends AbstractWarehouseComponent implements OnInit, OnDestroy {
  fallbackMockups = FALLBACK_MOCKUPS;
  loading = false;
  loaded = false;

  @OwInject(MAT_DIALOG_DATA) data: {
    storageId: number;
  };

  colors = ["yellow", "blue-light", "red", "purple", "orange"];

  ngOnInit() {
    this.loading = true;
    this.checkStorageId();
    this.subscribePlayer();
  }

  checkStorageId() {
    if (this.data.storageId) {
      this.storages = [
        {
          storage_id: this.data.storageId,
          categories: [],
          capacity: 0,
          name: "",
        },
      ];
      this.getAllCategories({ only_with_ever_had_products: true });
    } else {
      this.getStorages({ only_with_ever_had_products: true });
    }
  }

  getAllCategories(params: { [key: string]: string | boolean | number } = {}) {
    const categoriesRequest = [];

    this.storages.forEach((storage) => {
      categoriesRequest.push(this.getCategories(storage, params));
    });

    forkJoin(
      categoriesRequest
    ).subscribe((categories: any) => {
      const category = categories.flat()[0];
      if (category != null) {
        this.changeCategory(category);
        this.subscribeNewProductsInStorage();
      } else {
        this.markWarehouseAsLoaded();
      }
    });
  }

  getProductsByCategory(category_id) {
    this.productPlayerService.productsPlayer({category: category_id})
      .subscribe((resp) => {
      this.parseProducts(resp);
      this.markWarehouseAsLoaded();
    });
  }

  markWarehouseAsLoaded() {
    this.loading = false;
    this.loaded = true;
  }

  dispatchClearNewProducts() {
    super.dispatchClearNewProducts();

    this.storages.forEach(s => {
      if (s.categories.length > 0) {
        s.categories.forEach((c, idx) => {
          c["color"] = this.colors[idx];
        });
      }
    });
  }

  parseProducts(products) {
    this.productsSlider.pages = [];
    this.productsSlider.items = this.productPlayerService.getProducts(products);
    this.productsSlider = generateEachPages(this.productsSlider);
    this.calculateItemsCapacity();
  }

  getCategories(storage: StorageWithCategories, params: { [key: string]: string | boolean | number } = {}) {
    return this.productPlayerService.productsCategories({storage_id: storage.storage_id, params})
      .pipe(
        tap((resp) => {
          storage.categories = resp;
        })
      );
  }

  getStorages(params: { [key: string]: string | boolean | number } = {}) {
    this.storages = [];

    this.apiStorageService.storage()
      .subscribe((resp: StorageBase[]) => {
        this.storages = <StorageWithCategories[]>resp
          .filter((storage) => {
            if (this.data.storageId) {
              return storage.storage_id === this.data.storageId;
            } else {
              return true;
            }
          })
          .map((storage) => {
            return {...storage, categories: []};
          });

        this.getAllCategories(params);
        this.changeActiveStorage(this.storages[0]);
      });
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
