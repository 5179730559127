<div class="global-dialog ow-dialog">
  <div class="decoration decoration-1"></div>
  <div class="decoration decoration-5 z-1"></div>
  <div class="decoration decoration-4"></div>

  <m-ui-close-button (clicked)="close()"></m-ui-close-button>

  <div class="minigame-container">
    <ng-container [ngTemplateOutlet]="currentTemplate"></ng-container>

    <div class="game-container">
      <app-minigame-catch-bananas
        [parameters]="catchBananasParams"
      ></app-minigame-catch-bananas>
    </div>
  </div>
</div>

<ng-template #catchBananasGuiResult>
  <div class="background-container results-container">
    <div class="ow-dialog c-window-a c-secondary">
      <div class="logo result"></div>

      <div class="title">Koniec czasu</div>

      <table class="results">
        <!-- BANANAS -->
        <tr>
          <td>
            <div class="icon point banana"></div>
          </td>
          <td class="point-per-one">
            {{ summary.score1 | NumberWithSpaces }} x {{ summary.multiplier1 }}
          </td>
          <td>=</td>
          <td>
            {{ summary.points1 | NumberWithSpaces }} pkt
          </td>
        </tr>
        <!-- ROTTEN BANANAS -->
        <tr>
          <td>
            <div class="icon point banana-rotten"></div>
          </td>
          <td class="point-per-one">
            {{ summary.score2 | NumberWithSpaces }} x {{ summary.multiplier2 }}
          </td>
          <td>=</td>
          <td>
            {{ summary.points2 | NumberWithSpaces }} pkt
          </td>
        </tr>
        <!-- CHEST -->
        <tr *ngIf="currentGame.payment === CBGameModes.PREMIUM">
          <td>
            <div class="icon point box"></div>
          </td>
          <td class="point-per-one">
            {{ summary.chests_received }} x {{ summary.chest_multiplier }}
          </td>
          <td>=</td>
          <td>
            {{ summary.chest_points }} pkt
          </td>
        </tr>
      </table>

      <div class="separator"></div>

      <div class="summary">
        {{ (summary.points1 + summary.points2 + summary.chest_points) | NumberWithSpaces }} pkt
        =
        <m-ui-currency
          [item]="{currency_id: 10, amount: summary.total_food}"
          [lack]="false"
          [stockView]="STOCK_VIEW.CUSTOM"
          [customIconSize]="'big'"
          [customClassStock]="'stock-minigame-catch-banana'"
        ></m-ui-currency>
      </div>

      <button
        class="c-base c-primary"
        (click)="close()"
      >
        Ok
      </button>
    </div>
  </div>
</ng-template>

<ng-template #catchBananasGuiPause>
  <div class="background-container pause-container">
    <div class="ow-dialog c-window-a c-secondary">
      <div class="logo pause"></div>

      <div class="title">Pauza aktywna</div>

      <button
        class="c-base c-primary"
        (click)="resumeGame()"
      >
        Kontynuuj grę
      </button>
    </div>
  </div>
</ng-template>

<ng-template #catchBananasGuiLoad>
  <div class="background-container load-container">
    <div class="ow-dialog c-window-a c-secondary">
      <loading></loading>
      <div class="logo load loading-opacity"></div>

      <div class="title loading-opacity">Ładowanie gry: {{ gameState.progress }}%</div>

      <button
        [disabled]="!gameState.isLoaded"
        class="c-base c-primary loading-opacity"
        (click)="data.unfinishedStatus ? continueUnfinished() : start()"
      >
        {{ gameState.isLoaded ? (data.unfinishedStatus ? 'Kontynuuj' : 'Graj') : 'Proszę czekać...' }}
      </button>
    </div>
  </div>
</ng-template>
