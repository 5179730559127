import { GAME_CONFIG } from "../../../../../core/config/custom/_parsed-game.config";
import {
  BOARD_TILE_ALPHA,
  BOARD_TILE_WITH_BUILDING_ALPHA,
  GAME_EVENTS,
  PLAYER_BUILDING_STATUS_BUILT,
  PLAYER_BUILDING_STATUS_UPGRADED,
  ProductionStatus,
  TileTooltipType,
} from "../../../constants";
import { BUILDING_NOTIFICATION } from "../../../constants/custom/building-notifications.const";
import { GameFeaturesMap } from "../../../constants/game-features";
import { ISO_OBJECT_DEPTH_VALUE_FACTOR } from "../../../constants/iso.constants";
import {
  extractBuildingImageParametersFromAssetPath,
  isBuildingSpecialIncrease,
  isBuildingWithProbability,
  isSpecial,
} from "../../../game-gui/helpers/buildings.helper";
import {isAnimalTracking, isAntyminer} from '../../../game-gui/helpers/custom-buildings.helper';
import { PlayerBuilding } from "../../interfaces/player-building.config";
import { getBuildingAnchor, getTileHoverAnchor, getTileHoverTextureName } from "../../utils/board.helper";
import {
  isAssetLoadedToPhaserCache,
  isNonInteractiveBuilding,
  isPointerOnCanvas,
  isPointerupOnCanvas,
  isTileBuyable,
} from "../../utils/game.helper";
import { BoardTileCore } from "../core/BoardTileCore.class";
import { BuildingLevelLayerCore } from "../core/BuildingLevelLayerCore";

export class BoardTile extends BoardTileCore {
  handleBuildingStatus() {
    if (!this.gameService.playerService.isActiveMe) {
      return;
    }

    if (this.hasMission()) {
      this.createTooltip(TileTooltipType.MISSION);
    }
    if (this.hasBuilding && this.playerBuildingData.auto_production_amount) {
      this.createTooltip(TileTooltipType.COLLECT);
    } else if (
      this.hasBuilding &&
      this.isBuildInProgress()
    ) {
      this.createTooltip(TileTooltipType.UPGRADE);
    } else if (this.tileData.production) {
      switch (this.tileData.production.status) {
        case ProductionStatus.InProgress:
          this.createTooltip(TileTooltipType.PRODUCTION);
          break;
        case ProductionStatus.Finished:
          this.createTooltip(TileTooltipType.PRODUCTION_FINISHED);
          break;
      }
    }

    if (
      this.hasBuilding &&
      this.playerBuildingData.status === PLAYER_BUILDING_STATUS_BUILT &&
      this.playerBuildingData["hide_level_information_on_front"] === false &&
      GAME_CONFIG.BUILDING_LEVELS
    ) {
      this.buildingLevelLayer = new BuildingLevelLayerCore(this.myScene, this);
    }
  }

  createActiveTile() {
    const emptyTileIcon = this.tileData.empty_icon;
    const tileTextureFrame = emptyTileIcon ? emptyTileIcon : getTileHoverTextureName(this.tileData.tile_type);
    this.baseSprite = this.scene.add.image(0, 0, "map-atlas", tileTextureFrame);
    this.add(this.baseSprite);
    const tileAnchor = getTileHoverAnchor(this.tileData.tile_type);
    if (this.hasBuilding) {
      this.baseSprite.setScale(this.tileData.tile_type.width, this.tileData.tile_type.height);
    }

    this.baseSprite.setOrigin(tileAnchor.x, tileAnchor.y);
    this.baseSprite.alpha = BOARD_TILE_ALPHA;
    this.handleInput();
  }

  handlePlayerBuilding() {
    if (!this.myScene.sceneConfig.isOrhto) {
      this.processRoads();
    }

    this.baseSprite = this.scene.add.sprite(0, 0, "");
    this.baseSprite.setOrigin(0.5, 1);
    this.add(this.baseSprite);

    if (
      this.playerBuildingData.hasOwnProperty("player_building_disaster") &&
      this.playerBuildingData["player_building_disaster"] !== null
    ) {
      if (this.playerBuildingData.level) {
        this.playerBuildingData.icon = this.playerBuildingData.icon + "_destroyed";
      }

      this.playerBuildingData.name = "Zepsute auto";
    }

    let buildingImage = this.gameService.buildingsService.getBuildingImage(this.playerBuildingData, true);
    const buildingImageVersion = this.handleBuildingVersions(buildingImage);
    buildingImage = buildingImageVersion ? buildingImageVersion : buildingImage;

    const buildingImageAsset = this.gameService.buildingsService.getBuildingImageAssetObject(this.playerBuildingData);

    const buildingExtensionsImage = this.gameService.buildingsService.getBuildingExtensions(this.playerBuildingData);
    if (buildingExtensionsImage) {
      console.log("buildingExtensionsImage", buildingExtensionsImage);
      const buildingImageParameters = extractBuildingImageParametersFromAssetPath(buildingExtensionsImage.path);

      const sprite = this.scene.add.sprite(-buildingImageParameters.x, -buildingImageParameters.y, null);
      if (buildingExtensionsImage.path.includes("infrastructure_warehouse")) {
        sprite.x = buildingImageParameters.x;
        sprite.y = -buildingImageParameters.y;
      }
      this.add(sprite);
      sprite.setOrigin(0);
      // sprite.setBlendMode(Phaser.BlendModes.COLOR_BURN);
      sprite.alpha = 0;

      // this.scene.add.tween({
      //   targets: sprite,
      //   duration: 600,
      //   alpha: 1,
      //   ease: 'Power2',
      //   yoyo: true,
      //   hold: 500,
      //   repeat: -1
      // });

      switch (buildingImageParameters.animation) {
        case "glow":
          setInterval(() => {
            let targetAlpha = 0;
            if (sprite.alpha === 0) {
              targetAlpha = 1;
            }

            this.scene.add.tween({
              targets: sprite,
              duration: 200,
              alpha: targetAlpha,
              ease: "Power2",
            });
          }, 1000);
          break;

        case "up":
          const rideElevator = (y: number, delay: number) => {
            this.scene.add.tween({
              targets: sprite,
              y: -y,
              duration: 5000 * (y / 180),
              ease: "Expo.easeInOut",
              onComplete: () => {
                setTimeout(() => {
                  rideElevator((Math.random() * 4 * (180 - 139)) / 5 + 139, Math.random() * 2);
                }, delay);
              },
            });
          };

          rideElevator(180, 1000);
          break;
      }

      this.gameService.loadGameImages(this.myScene, [buildingExtensionsImage.path]).subscribe(() => {
        setTimeout(() => {
          sprite.setTexture(buildingExtensionsImage.path);
          sprite.alpha = BOARD_TILE_WITH_BUILDING_ALPHA;
          // (this.scene as MainScene).customPipeline.setFloat2('u_resolution', this.baseSprite.width, this.baseSprite.height);
          // this.baseSprite.setPipeline(PIPELINE_NAME);
        }, 300);
      });
    }

    const constructionImage = this.handleConstructionImage(this.playerBuildingData);
    buildingImage = constructionImage ? constructionImage : buildingImage;

    if (buildingImage) {
      this.setTileImage(buildingImage);
      let buildingAnchor = getBuildingAnchor(this.playerBuildingData.icon, this.playerBuildingData.level);
      if (!buildingAnchor) {
        buildingAnchor = getTileHoverAnchor(this.tileData.tile_type);
      }
      this.baseSprite.setOrigin(buildingAnchor.x, buildingAnchor.y);
    }
    this.handleInput();
    this.handleBuildingStatus();

    if (
      this.gameService.playerService.isActiveMe &&
      this.gameService.playerService.player["gui_notifications"][BUILDING_NOTIFICATION[this.playerBuildingData.group]]
    ) {
      this.createTooltip(TileTooltipType.ALERT);
    }
  }

  handleConstructionImage(buildingData: PlayerBuilding) {
    let constructionImage;

    if (this.isBuildInProgress() && buildingData.level === 1) {
      const upgradingFromBuilding = buildingData.upgrading_from_building;
      if (upgradingFromBuilding) {
        constructionImage = this.gameService.buildingsService.getBuildingImage(upgradingFromBuilding, true);
      } else if (this.myScene.sceneConfig.mapDirectoryName === "garage") {
        constructionImage = "groundzero_garage";
      } else {
        constructionImage = "construction";
      }
    }
    return constructionImage;
  }

  setTileImage(buildingImage: string) {
    return new Promise((resolve, reject) => {
      if (isAssetLoadedToPhaserCache(buildingImage, this.game.textures.getTextureKeys())) {
        this.baseSprite.setTexture(buildingImage);
        resolve(buildingImage);
      } else {
        this.visible = false;
        this.gameService.loadGameImages(this.myScene, [buildingImage]).subscribe(() => {
          this.baseSprite.setTexture(buildingImage); // Timeout fix to wait till Phaser prepare texture from image.
          this.visible = true;
          resolve(buildingImage);
          this.calculateDepth();
        });
      }
    });
  }

  handleInput() {
    if (!this.gameService.playerService.isActiveMe || !GameFeaturesMap.boardTileInput) {
      return;
    }

    this.enableInteractivity();
    const sprite =
      (this.hasBuilding && this.playerBuildingData.pixel_perfect) || (!this.hasBuilding && this.tileData["empty_icon_pixel_perfect"])
        ? this.baseSprite
        : this;
    sprite.on("pointerover", pointer => {
      const isMobile: boolean = this.gameService.deviceDetector.isMobile();
      if (!isPointerOnCanvas(pointer, this.myScene)) {
        return;
      }

      // fix for the bug that was firing pointerover event after clicked on a tile covered by a dialog
      if (pointer.isDown && !isPointerupOnCanvas(pointer, this.myScene)) {
        return;
      }

      if (this.hasBuilding || isTileBuyable(this.tileData.state)) {
        if (pointer.event.altKey && this.game.debug) {
          this.showInterior();
        } else {
          if (!this.myScene.board.movingTile) {
            this.setTint();
            if (!this.tileMenu || !(this.tileMenu && this.tileMenu.visible)) {
              if (!this.tooltip) {
                !isMobile && this.myScene.board.tileHover.show(this);
                if (this.buildingLevelLayer && this.myScene.board.isVisibleBuildingsLevelLayer) {
                  this.buildingLevelLayer.hide();
                }
              }
            }
          }
        }
      } else {
        if (this.myScene.board.movingTile) {
          this.myScene.board.moveBuildingPreviewIfPossible(this);
        } else if (!this.hoverTween || (this.hoverTween && !this.hoverTween.isPlaying())) {
          this.baseSprite.alpha = BOARD_TILE_ALPHA + 0.2;
          this.hoverTween = this.scene.add.tween({
            targets: this.baseSprite,
            y: -10,
            duration: 300,
            ease: "Power2",
            yoyo: true,
          });
        }
      }
      if ((!this.tileMenu || !this.tileMenu.visible) && this.buildingLevelLayer && this.myScene.board.isVisibleBuildingsLevelLayer) {
        this.buildingLevelLayer.hide();
      }
    });

    sprite.on("pointerout", () => {
      if (this.hasBuilding || isTileBuyable(this.tileData.state)) {
        this.baseSprite.clearTint();
        this.myScene.board.tileHover.hide();
        if (this.interiorView) {
          this.showInterior();
        }
      } else {
        this.baseSprite.alpha = BOARD_TILE_ALPHA;
      }
      if (this.requirementsTooltip) {
        this.requirementsTooltip.destroy();
      }
      if ((!this.tileMenu || !this.tileMenu.visible) && this.buildingLevelLayer && this.myScene.board.isVisibleBuildingsLevelLayer) {
        this.buildingLevelLayer.show();
      }
    });

    sprite.on("pointerup", (pointer: Phaser.Input.Pointer) => {
      // This method will execute and if return anything else than undefined then stop Core flow.
      if (this.beforeClickHandle(pointer) !== undefined) {
        return;
      }

      if (!isPointerupOnCanvas(pointer, this.myScene)) {
        return;
      }

      if (this.hasBuilding && isNonInteractiveBuilding(this.playerBuildingData.group_type)) {
        return;
      }

      console.log(this.tileData);
      console.log(this);

      if (!this.myScene.shouldCancelClickEvent(pointer.position)) {
        if (this.myScene.board.movingTile) {
          // this.handleBuildingMoveConfirm(); moved to BuildingMovePreview menu
        } else if (this.tooltip && this.tooltip.isType(TileTooltipType.COLLECT)) {
          this.autoprodCollect();
        } else if (this.tooltip && this.tooltip.isType(TileTooltipType.PRODUCTION_FINISHED)) {
          this.productionCollect();
        } else if (this.tooltip && this.tooltip.isType(TileTooltipType.UPGRADE)) {
          return;
        } else if (this.tooltip && this.tooltip.isType(TileTooltipType.MISSION)) {
          this.gameService.emitGameEvent({
            name: GAME_EVENTS.OPEN_PLAYER_MISSION,
            value: this.tileData.player_building.player_mission,
          });
        } else {
          if (this.playerBuildingData) {
            if (this.isBuildInProgress() && this.playerBuildingData.level === 1) {
              return true;
            }

            if (
              this.playerBuildingData["player_building_disaster"] !== null &&
              this.playerBuildingData["player_building_disaster"].repaired_at == null
            ) {
              if (this.isBuildInProgress()) {
                // return if building in progress and is special type
                return true;
              }
              // this.gameService.emitGameEvent({
              //   name: GAME_EVENTS.OPEN_CONSERVATION,
              //   value: this.tileData.player_tile_id,
              //   data: this.tileData,
              // });
            } else if (isBuildingSpecialIncrease(this.playerBuildingData.group_type)) {
              if (this.isBuildInProgress()) {
                // return if building in progress and is special type
                return true;
              }
              this.gameService.emitGameEvent({
                name: GAME_EVENTS.BUILDING_UPGRADE_SPECIAL_INCREASE,
                value: this.tileData.player_tile_id,
              });
            } else if (isBuildingWithProbability(this.playerBuildingData.group_type)) {
              if (this.isBuildInProgress()) {
                // return if building in progress and is special type
                return true;
              }
              this.gameService.emitGameEvent({
                name: GAME_EVENTS.BUILDING_UPGRADE_WITH_PROBABILITY,
                value: this.tileData.player_tile_id,
              });
            } else if (isSpecial(this.playerBuildingData.group_type)) {
              if (this.isBuildInProgress()) {
                // return if building in progress and is special type
                return true;
              }
              this.gameService.emitGameEvent({
                name: GAME_EVENTS.BUILDING_SPECIAL_UPGRADE,
                value: this.tileData.player_tile_id,
              });
            } else if (isAnimalTracking(this.playerBuildingData.group_type)) {
              if (this.playerBuildingData.level % 2 == 0) {
                this.gameService.emitGameEvent({
                  name: GAME_EVENTS.TRACKING_CONTINUE,
                  value: this.tileData.player_tile_id,
                });
              } else {
                this.gameService.emitGameEvent({
                  name: GAME_EVENTS.TRACKING_BEGIN,
                  value: this.tileData.player_tile_id,
                });
              }
            } else if (isAntyminer(this.playerBuildingData.group_type)) {
              if (this.playerBuildingData.level % 2 == 0) {
                this.gameService.emitGameEvent({
                  name: GAME_EVENTS.ANTYMINER_CONTINUE,
                  value: this.tileData.player_tile_id
                })
              } else {
                this.gameService.emitGameEvent({
                  name: GAME_EVENTS.ANTYMINER_BEGIN,
                  value: this.tileData.player_tile_id
                })
              }
            } else if (this.playerBuildingData.group_type === 'info-problem') {
              this.gameService.emitGameEvent(({
                name: GAME_EVENTS.BUILDING_INFO_PROBLEM,
                value: this.playerBuildingData?.['info_problem']
              }))
            } else {
              this.myScene.board.tileHover.hide();
              if (this.tooltip) {
                this.tooltip.hide();
              }

              this.gameService.emitGameEvent({
                name: GAME_EVENTS.OPEN_TILE_MENU,
                value: this,
              });
            }
          } else {
            if (this.tileData.state) {
              this.gameService.emitGameEvent({
                name: GAME_EVENTS.TILE_CLICK,
                value: this.tileData.tile_id,
              });
            }
          }
        }
      }
    });
  }

  calculateDepth() {
    const zPos = this.tileData.z_pos;
    if (zPos) {
      this.setDepth(zPos);
    } else {
      if (this.baseSprite) {
        let correction = 0;
        switch (this.tileData.tile_id) {
          case 157:
          case 19:
          case 20:
            correction = 400;
            break;
        }

        this.setDepth(this.depthIndex + (this.y + correction) * ISO_OBJECT_DEPTH_VALUE_FACTOR);
      }
    }
  }
}
