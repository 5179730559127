import { Component } from "@angular/core";
import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BusinessCalendarDetailsResponse } from "../../../../interfaces/custom/business-calendar.interface";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";

@Component({
  selector: "app-business-calendar-details",
  templateUrl: "business-calendar-details.component.html",
  styleUrls: ["business-calendar-details.component.scss"],
})
export class BusinessCalendarDetailsComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<BusinessCalendarDetailsComponent>;
  @OwInject(MAT_DIALOG_DATA) data: BusinessCalendarDetailsResponse;

  close(confirm?: boolean) {
    this.matDialogRef.close(confirm);
  }

  protected readonly STOCK_VIEW = STOCK_VIEW;
}
