import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { ApiEventShopService } from "../../../../event-shop/api/custom/services/api-event-shop.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { EventShop } from "../../../../event-shop/api/custom/interfaces/response/get-event-shops.interface";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";
import { SynchronizeTimeService } from "../../../../../../../core/providers/synchronize-time.service";
import { ApiEventBusinessService } from "../../../api/custom/services/api-event-business.service";
import { GetEventBusinessResponseInterface } from "../../../api/custom/interfaces/response/get-event-business-response.interface";
import { EventShopDetails } from "../../../../event-shop/basic/custom/dialogs/event-shop-details/event-shop-details";
import { ClRankListComponent } from "../cl/cl-rank-list/cl-rank-list.component";
import { forkJoin } from "rxjs";
import { PlayerService } from "../../../../../../player/providers/player.service";
import * as R from "ramda";
import { NotificationsService } from "../../../../../services/custom/notifications.service";
import { PLAYER_TITLES } from "../../../../../../../core/consts/core/player-titles";
import { Branch } from "../../../../../interfaces/branch.interface";
import { ClearNotificationService } from "../../../../san-business/custom/utils/clear-notification.service";

@Component({
  selector: "app-event-business-list",
  templateUrl: "./event-business-list.component.html",
})
export class EventBusinessListComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;
  @OwInject(ApiEventShopService) apiEventShopService: ApiEventShopService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data: {
    isOpenedFromDashboard?: boolean;
    isOpenedFromBusinessPreview?: boolean;
    branch?: Branch;
    managerPlayerId?: number;
  };
  @OwInject(ApiEventBusinessService) apiEventBusinessService: ApiEventBusinessService;
  @OwInject(NotificationsService) notificationsService: NotificationsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ClearNotificationService) clearNotificationService: ClearNotificationService;

  businessEvents: Array<
    GetEventBusinessResponseInterface & {
      notification?: boolean;
      is_repeatable?: boolean;
      is_active?: boolean;
    }
  > = [];
  eventShops: EventShop[] = [];
  ranks = [];
  branchName?: string;
  branchId?: number;

  isShopLocked?: boolean = false;
  shopLockedMessage?: string = "wróć do Centrum danych zgodnego z planszą na której jesteś zalogowany";

  ngOnInit() {
    this.getData();
    this.branchName = this.data?.branch?.name;
    this.branchId = this.data?.branch?.branch_id;
    this.isShopLocked = this.playerService.player.id !== this.data.managerPlayerId;
    if (!this.data.managerPlayerId) {
      this.isShopLocked = !this.playerService.isActiveMe || !this.playerService.player.title_id.playable
    }
    if (this.playerService.player.title_id.id >= PLAYER_TITLES.DOR.id) {
      this.shopLockedMessage = "Brak możliwości podglądu";
    }
  }

  getData() {
    forkJoin([
      this.data?.isOpenedFromBusinessPreview && !this.data?.managerPlayerId
        ? this.apiEventBusinessService.getManagerEventBusiness(this.data?.branch.branch_id)
        : this.apiEventBusinessService.getEventBusiness(this.data?.managerPlayerId),
      this.data.isOpenedFromBusinessPreview && !this.data.managerPlayerId
        ? this.apiEventShopService.getManagerEventShops(this.data?.branch.branch_id)
        : this.apiEventShopService.getEventShops(this.data?.managerPlayerId),
    ]).subscribe(([eventBusiness, eventsShops]) => {
      this.eventShops = eventsShops.map(eventShop => {
        return {
          ...eventShop,
          currencyId: this.setCurrencyId(eventShop),
        };
      });

      this.businessEvents = eventBusiness.map(businessEvent => {
        if (businessEvent.event_shop) {
          const eventShop = this.eventShops.find(_eventsShop => _eventsShop.event_shop_id === businessEvent.event_shop.event_shop_id);
          if (eventShop) {
            return {
              ...businessEvent,
              is_repeatable: eventShop.is_repeatable,
              is_active: eventShop["is_active"],
            };
          }
        } else {
          return businessEvent;
        }
      });

      this.mapNotification();
    });
  }

  setCurrencyId(eventShop: EventShop) {
    return eventShop.events[0].prizes[0].currency_id;
  }

  openRankDetails(eventBusiness: GetEventBusinessResponseInterface) {
    this.apiEventBusinessService.getEventBusinessRanks(eventBusiness.event_id).subscribe(ranks => {
      if (ranks.length === 0) {
        this.dialogService.openAlert({
          title: "Uwaga!",
          description: "Rankingi dostępne wkrótce.",
        });
      } else {
        this.dialogService.open(ClRankListComponent, {
          data: {
            event_shop_id: eventBusiness.event_shop.event_shop_id,
            ranks: ranks,
            event_business_id: eventBusiness.event_id,
          },
        });
      }
    });
  }

  openShop(eventShopId: number) {
    const eventShop = this.eventShops.find(_shop => _shop.event_shop_id === eventShopId);
    if (!eventShop) {
      this.dialogService.openAlert({
        title: "Uwaga!",
        description: "Sklep tymczasowo niedostępny",
      });
    } else {
      this.dialogService.open(EventShopDetails, {
        data: {
          eventShop,
        },
      });
    }
  }

  isShopEventDeactivate(businessEvent: GetEventBusinessResponseInterface) {
    const currentStart = moment(businessEvent.start_at).startOf("day");
    const currentEnd = moment(businessEvent.end_at).endOf("day");
    const currentDate = moment(this.synchronizeTimeService.getActualLocalTime());
    return !currentDate.isBetween(currentStart, currentEnd);
  }

  mapNotification() {
    if (this.playerService.player["gui_notifications"]?.["business_event"] > 0) {
      this.businessEvents.forEach(event => {
        event.notification = R.includes(
          event.event_id,
          this.playerService.player["gui_notifications_list"]["business_event"].map(notification => notification.business_event_id)
        );
      });
    }
  }

  clearNotification() {
    this.clearNotificationService.clearNotification(["business_event"], this.data?.managerPlayerId);
  }

  ngOnDestroy() {
    this.clearNotification();
  }
}
