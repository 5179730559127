import { Component } from "@angular/core";
import { LEVEL_FILTER_KEY } from "../../../../../const/custom/level-filter-key.const";
import { RANK_LEAGUE_TYPES } from "../../../../../const/custom/rank-league-types.const";
import { OwInject } from "../../../../../../../../../core/decorators/ow-inject.decorator";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RankLeague } from "../../../../../interfaces/custom/rank-league.interface";
import { OwDate } from "../../../../../../../../../core/utility/ow-date.class";
import { AbstractInjectBaseComponent } from "../../../../../../../../../core/abstracts/abstract-inject-base.component";
import { MyIdObject, RankLevel } from "../../../../../../../interfaces/custom/rank.interface";
import { Branch } from "../../../../../../../interfaces/branch.interface";

@Component({
  selector: "app-rank-league-details",
  templateUrl: "./rank-league-details.component.html",
})
export class RankLeagueDetailsComponent extends AbstractInjectBaseComponent {
  @OwInject(MAT_DIALOG_DATA) public data: {
    rank: RankLeague;
    group: string;
    owDate: OwDate;
    myIdObject: MyIdObject;
    pagination: string;
    branchName?: string;
    branch?: Branch;
  };

  levels: RankLevel[] = [
    { label: "Kraj", data: "", index: 1, show: true, filterKey: LEVEL_FILTER_KEY.COUNTRY },
    { label: "Region", data: "region", index: 2, show: true, filterKey: LEVEL_FILTER_KEY.REGION },
    { label: "Oddział", data: "branch", index: 3, show: true, filterKey: LEVEL_FILTER_KEY.BRANCH },
  ];

  RANK_LEAGUE_TYPES = RANK_LEAGUE_TYPES;
}
