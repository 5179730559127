import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as moment from "moment";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { GAME_CONFIG } from "../../../../../../../../core/config/custom/_parsed-game.config";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { SynchronizeTimeService } from "../../../../../../../../core/providers/synchronize-time.service";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { UserService } from "../../../../../../../user/providers/user.service";
import { EventShop } from "../../../../api/custom/interfaces/response/get-event-shops.interface";
import { ApiEventShopService } from "../../../../api/custom/services/api-event-shop.service";
import { TAO_SHOP_ID } from "../../../../consts/custom/gadget-shop.const";
import { ShopColorsEnum } from "../../../../consts/custom/shop-colors.enum";
import { EventsShopService } from "../../../../services/custom/events-shop.service";
import { EventShopDetails } from "../event-shop-details/event-shop-details";

@Component({
  selector: "event-shop-list",
  templateUrl: "./event-shop-list.html",
})
export class EventShopList extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiEventShopService) apiEventShopService: ApiEventShopService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(UserService) userService: UserService;
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;
  @OwInject(EventsShopService) eventsShopService: EventsShopService;
  @OwInject(Router) router: Router;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<EventShopList>;
  @OwInject(MAT_DIALOG_DATA) data: {
    eventShopId?: number;
  };

  TAO_SHOP_ID = TAO_SHOP_ID;
  colors = ShopColorsEnum;
  eventShops: Array<EventShop & { currencyId: number }>;
  isCC = GAME_CONFIG.IS_CC;

  ngOnInit() {
    this.eventsShopService.clearEventShopNotification();
    this.getEventShops();
  }

  getEventShops() {
    this.apiEventShopService.getEventShops().subscribe(
      resp => {
        this.eventShops = resp.map(eventShop => {
          return {
            ...eventShop,
            currencyId: this.setCurrencyId(eventShop),
          };
        });
        this.openSelectedShop();
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  openSelectedShop() {
    if (this.data.eventShopId) {
      const eventShop = this.eventShops.find(shop => shop.event_shop_id === this.data.eventShopId);

      if (eventShop) {
        this.openShopDetails(eventShop);
      } else {
        this.dialogService.openAlert({
          description: `Brak sklepu eventowego o id: ${this.data.eventShopId}`,
        });
      }

      setTimeout(() => {
        this.close();
      });
    }
  }

  openShopDetails(eventShop: EventShop) {
    if (eventShop.event_shop_id === TAO_SHOP_ID) {
      if (!this.isShopEventDeactivate(eventShop)) {
        return;
      }
      this.router.navigate(["/auth/shop"]);
      this.dialogService.closeAll();
    } else {
      this.dialogService.open(EventShopDetails, {
        data: {
          eventShop,
          hideBackButton: !!this.data.eventShopId,
        },
      });
    }
  }

  setCurrencyId(eventShop: EventShop) {
    return eventShop.events[0].prizes[0].currency_id;
  }

  isShopEventDeactivate(eventShop: EventShop) {
    const currencyStart = moment(eventShop.currency_collection_start).toDate();
    const openStart = moment(eventShop.open_start).toDate();
    const openEnd = moment(eventShop.open_end).toDate();

    const currentDate = moment(this.synchronizeTimeService.getActualLocalTime()).startOf("day").toDate();
    const startDate = currencyStart < openStart ? currencyStart : openStart;

    return currentDate >= startDate && currentDate <= openEnd;
  }

  close() {
    this.matDialogRef.close();
  }
}
