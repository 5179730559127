import { Component, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import * as moment from "moment/moment";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { CurrencyService } from "../../../../../../../../core/providers/currency.service";
import { SynchronizeTimeService } from "../../../../../../../../core/providers/synchronize-time.service";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { selectPlayer } from "../../../../../../../../store/player/selectors";
import { AppState } from "../../../../../../../../store/state";
import { UserSelectors } from "../../../../../../../../store/user";
import { Player } from "../../../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { User } from "../../../../../../../user/interfaces/user";
import { UserService } from "../../../../../../../user/providers/user.service";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { ApiAuctionsService } from "../../../../api/custom/api-auctions.service";
import { STATUS } from "../../../../consts/custom/auction.const";
import { EVENT_DIALOGS_NAMES_AUCTIONS_CUSTOM } from "../../../../consts/custom/event-dialogs/event-names.const";
import { Auction } from "../../../../interfaces/custom/auction.interface";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { CurrencyBalanceDetails } from "../../../../../../../../core/interfaces/currency";

@Component({
  selector: "app-auction-details",
  templateUrl: "./auction-details.component.html",
})
export class AuctionDetailsComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(MAT_DIALOG_DATA) data: { auctionId: number; currencyId: number };
  @OwInject(ApiAuctionsService) apiAuctionsService: ApiAuctionsService;
  @OwInject(UserService) userService: UserService;
  @OwInject(CurrencyService) currencyService: CurrencyService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AuctionDetailsComponent>;
  @OwInject(DialogService) dialogService: DialogService;

  STOCK_VIEW = STOCK_VIEW;
  isLoading = false;
  auction: Auction;
  playerBid: number;
  STATUS = STATUS;
  user: User;
  player: Player;

  didPlayerBid: boolean;
  timerOutAuctionFinished: boolean;

  subs = {
    user: null,
    player: null,
  };
  currencyAmount: {'pln': CurrencyBalanceDetails | null, 'cash': CurrencyBalanceDetails | null} = {
    pln: null,
    cash: null
  }

  // get talarsBalance() {
  //   const talars = this.userService.me.currency_balances.find(c => c.currency_id === 3);
  //   return talars.balance;
  // }

  ngOnInit() {
    this.subscribeUser();
    this.subscribePlayer();
    this.getAuctionDetails();
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(selectPlayer)).subscribe(player => {
      this.player = player;
    });
  }

  subscribeUser() {
    this.isLoading = true;
    this.subs.user = this.store.pipe(select(UserSelectors.selectUser)).subscribe(user => {
      this.user = user;
      this.currencyAmount.pln = this.user.currency_balances.find(x => x.key === 'pln');
      this.currencyAmount.cash = this.user.currency_balances.find(x => x.key === 'cash');
    });
  }

  getAuctionDetails(isAuctionClosed?: boolean) {
    this.apiAuctionsService.getApiAuctionDetails(this.data.auctionId).subscribe({
      next: resp => {
        this.auction = resp;

        if (isAuctionClosed) {
          this.auction.status = {
            name: "FINISHED",
            value: 2,
          };
          this.timerOutAuctionFinished = true;
        } else {
          this.setTimer();

          this.playerBid =
            !this.auction.highest_bid || this.auction.highest_bid.amount < this.auction.min_bid
              ? this.auction.min_bid
              : this.auction.highest_bid.amount + 1;
          this.isLoading = false;
        }
      },
      error: () => (this.isLoading = false),
    });
  }

  openAuctionBid() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_AUCTIONS_CUSTOM.BID,
      config: {
        data: {
          auction: this.auction,
          playerBid: this.playerBid,
          phone: this.player["delivery_phone"],
        },
      },
      callback: event => {
        if (event) {
          this.clearAuctionInterval();
          this.didPlayerBid = event;
          this.getAuctionDetails();
        }
      },
    });
  }

  findBalanceByCurrencyId(currency_id: number) {
    return Object.values(this.currencyAmount).find(x => x?.currency_id === currency_id) ?? {};
  }

  setTimer() {
    if (this.auction.ends_at && this.auction.status.value === STATUS.ACTIVE) {
      const actualDate = this.synchronizeTimeService.getActualLocalTime();
      const endedDate = moment(this.auction.ends_at);
      const timer = moment(endedDate).diff(moment(actualDate), "seconds");

      if (timer <= 0) {
        this.auction.timer = null;
        this.auction.isTimeIntSet = false;
        this.clearAuctionInterval();
        return;
      }

      const days = moment(endedDate).diff(moment(actualDate), "days");
      const hours = moment(endedDate).diff(moment(actualDate), "hours");
      const minutes = moment(endedDate).diff(moment(actualDate), "minutes");
      const seconds = moment(endedDate).diff(moment(actualDate), "seconds");

      if (days > 0) {
        this.auction.timer = days + (days === 1 ? " dzień" : " dni");
      } else if (hours > 0) {
        this.auction.timer = hours + " godz.";
        this.auction.timerSoonEnd = true;
      } else if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
        this.auction.timer = null;
        this.auction.isTimeIntSet = false;
      } else if (days <= 0 && hours <= 0 && (minutes > 0 || (minutes <= 0 && seconds > 0))) {
        this.auction.isTimeIntSet = true;
        if (!this.auction.intervalTimer) {
          this.auction.intervalTimer = setInterval(() => {
            const testDate = this.synchronizeTimeService.getActualLocalTime();
            this.auction.timer = moment(endedDate).diff(moment(testDate), "seconds");

            if (this.auction.timer <= 0) {
              this.clearAuctionInterval();
              this.getAuctionDetails(true);
            }
          }, 1000);
        }
        this.auction.timerSoonEnd = true;
      }
    } else {
      this.auction.timer = null;
    }
    this.isLoading = false;
  }

  clearAuctionInterval() {
    if (this.auction.intervalTimer) {
      clearInterval(this.auction.intervalTimer);
    }
  }

  chooseCurrency(currencyId: number) {
    this.dialogService.openConfirm(
      {
        title: `UWAGA!`,
        description: `Czy na pewno chcesz wybrać tę formę płatności?`,
        buttonSuccess: {
          label: "Tak",
        },
        buttonClose: {
          label: "Nie",
        },
      },
      (confirm: any) => {
        if (confirm) {
          this.apiAuctionsService.postApiAuctionChooseCurrency({ bid_id: this.auction.my_bid?.id, currency_id: currencyId }).subscribe({
            next: () => {
              this.didPlayerBid = true;
              this.getAuctionDetails();
            },
          });
        }
      }
    );
  }

  back() {
    if (this.didPlayerBid || this.timerOutAuctionFinished) {
      this.matDialogRef.close(true);
    } else {
      this.matDialogRef.close();
    }
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
    this.clearAuctionInterval();
  }
}
