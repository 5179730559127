<div
  class="global-dialog ow-dialog c-window-long loading-opacity"
  [class.location-50]="qa.location === 50 || (qa.location >= 101 && qa.location <= 112)"
>
  <loading></loading>

  <!-- TABS -->
  <div class="tabs">
    <div
      class="tab active"
      [class]="qa?.type == QA_TYPE.QUIZ ? 'yellow' : 'blue-light'"
    >
      <div class="bookmarks">
        <div
          class="bookmark"
          [class]="qa?.type == QA_TYPE.QUIZ ? 'yellow' : 'blue-light'"
        >
          <span>{{ qa?.type == QA_TYPE.QUIZ ? 'Quiz' : 'Ankieta' }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="frame">
    <!-- QA NAME -->
    <div class="title quiz" [innerHTML]="qa.name"></div>

    <!-- CONTENT AT FINISH -->
    <p [innerHTML]="qa.content_at_finish" class="inner-html-description" owEllipsis></p>

    <!-- SCORE, TIME AND PRIZES -->
    <app-custom-qa-summary
      [qa]="this.qa"
      [separatorTitle]="separatorTitle"
    ></app-custom-qa-summary>
  </div>

  <!-- BACK TO LIST BUTTON-->
  <div class="bottom">
    <div class="bar-button base">
      <button *ngIf="qa.location !== 50 && !(qa.location >= 101 && qa.location <= 112)" class="c-base c-primary"
              (click)="backToList()">
        {{ 'qa.qa-finish.back-to-list' | myTranslate }}
      </button>

      <button *ngIf="qa.location === 50 || (qa.location >= 101 && qa.location <= 112)" class="c-base c-primary"
              (click)="dialogService.closeAll()">
        Wróć do mostka
      </button>
    </div>
  </div>
</div>
