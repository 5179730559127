<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button [preventDefault]="true" (clicked)="close()"></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button *ngIf="data['boardTile']"></m-ui-back-button>

  <ng-container *ngIf="buildingDetails && currentBuildingFullDetails">
    <div class="bookmarks">
      <div class="overlap active green">
        <div class="bookmark green">
          <span (click)="goToPlace()">{{ buildingDetails.name }}</span>
        </div>
      </div>
    </div>

    <div class="top-info" *ngIf="upgradeBuildingDetails?.exp_points > 0">
      <div class="experience" [matTooltip]="'Punkty doświadczenia'" [matTooltipShowDelay]="400" [matTooltipPosition]="'above'">
        <div class="inner-wrapper">
          <p>+{{ upgradeBuildingDetails.exp_points }}</p>
        </div>
        <img [src]="'exp.png' | asset: 'ui'" />
      </div>
    </div>

    <div class="top loading-opacity">
      <div class="icon-container">
        <!-- BUILDING ICON -->
        <img [src]="buildingDetails | buildingIcon: false" />
      </div>

      <div class="right-column">
        <div
          class="description"
          [innerHTML]="
            buildingDetails.upgrade_building_id ? buildingDetails.additional_details : currentBuildingFullDetails.group_description
          "></div>

        <div *ngIf="upgradeBuildingDetails" class="elements">
          <app-unlocked-parameters-upgrade
            *ngIf="notOutcomeParametersDiffParameters?.length"
            [parameters]="notOutcomeParametersDiffParameters"
          ></app-unlocked-parameters-upgrade>

          <app-building-return
            *ngIf="upgradeBuildingDetails.build_currency_prizes.length || upgradeBuildingDetails.build_product_prizes.length"
            [returnCurrencies]="upgradeBuildingDetails.build_currency_prizes"
            [returnProducts]="upgradeBuildingDetails.build_product_prizes"></app-building-return>

          <app-unlocked-automatic-production
            *ngIf="automaticCurrency || automaticProduct"
            [groupType]="upgradeBuildingDetails.group_type"
            [automaticCurrency]="automaticCurrency"
            [automaticProduct]="automaticProduct"></app-unlocked-automatic-production>

          <app-unlocked-storage-value *ngIf="storageValue" [storageValue]="storageValue"></app-unlocked-storage-value>
        </div>
      </div>
    </div>

    <div *ngIf="upgradeBuildingDetails" class="bottom loading-opacity">
      <m-ui-separator title="Wymagania"></m-ui-separator>

      <!-- REQUIREMENTS -->
      <app-building-requirements
        *ngIf="outcomeParametersRequirements"
        [building]="upgradeBuildingDetails"
        [products]="upgradeBuildingDetails.build_product_prices"
        [currencies]="upgradeBuildingDetails.build_currency_prices"
        [population]="upgradeBuildingDetails.required_population"
        [requiredBuilding]="upgradeBuildingDetails.required_building"
        [parametersOutcome]="outcomeParametersRequirements"
        [playerLevel]="upgradeBuildingDetails.required_level > 1 ? upgradeBuildingDetails.required_level : null"
        (requirementsStatus)="setRequirementsStatus($event)"></app-building-requirements>
    </div>

    <div class="buttons-container">
      <div class="buttons" *ngIf="upgradeBuildingDetails && requirementsStatus">
        <!-- BUILD -->
        <div [ngbTooltip]="requirementsStatus['textErrors'].length && tooltipRequirementsTpl" placement="bottom" container="body">
          <button (click)="upgrade()" class="c-base c-secondary" [disabled]="!requirementsStatus.valid">
            {{
              this.boardTile.open_player_island_id && buildingDetails
                ? "Rozbuduj"
                : upgradeBuildingDetails.upgrade_button_name ?? "Rozbuduj"
            }}&nbsp;
            <!-- TIME IN SECONDS -->
            <span class="text-normal">
              <app-core-building-time [building]="upgradeBuildingDetails"></app-core-building-time>
            </span>
          </button>
        </div>

        <!-- FAST ACTION -->
        <div [ngbTooltip]="requirementsStatus['textErrors'].length && tooltipRequirementsTpl" placement="bottom" container="body">
          <button
            *ngIf="upgradeBuildingDetails.fast_build_currency_prices.length || upgradeBuildingDetails.fast_build_product_prices.length"
            [matTooltip]="'Akcja błyskawiczna'"
            class="c-base c-primary"
            (click)="openFastSpecialUpgradeConfirm()"
            [disabled]="!requirementsStatus.valid">
            Błyskawiczna akcja
          </button>
        </div>

        <!-- TOOLTIP REQUIREMENTS TPL -->
        <ng-template #tooltipRequirementsTpl>
          <div *ngFor="let textError of requirementsStatus['textErrors']">
            {{ textError }}
          </div>
        </ng-template>
      </div>

      <!-- GO TO CITY SCENE -->
      <div class="buttons loading-opacity" *ngIf="this.boardTile.open_player_island_id && buildingDetails">
        <button class="c-base c-primary" (click)="goToCityScene()">
          {{
            buildingDetails?.group === "monastery"
              ? "Umiejętności"
              : upgradeBuildingDetails?.upgrade_button_name || buildingDetails.upgrade_button_name
          }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
