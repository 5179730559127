import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/providers/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiSparkleService {

  constructor(
    public apiService: ApiService,
  ) {
  }

  getSparkle() {
    return this.apiService.get('sparkles');
  }
}
