<!-- LOADING API -->
<loading *ngIf="loading"></loading>

<div class="confirmation-delivery-container ow-dialog">
  <!-- TEMPLATE DELIVERY -->
  <ow-perfect-scrollbar *ngIf="userService.me && confirmation">
    <div [class.loading-opacity]="loading">
      <p class="title">
        Potwierdzenie odbioru nagród
      </p>

      <table class="table-user">
        <tr>
          <td>
            <b>Nr potwierdzenia:</b>
            {{ confirmation.number }}
          </td>
          <td>
            <b>Data nadania:</b>
            {{ confirmation.sent_date | date:'dd-MM-yyyy' }}
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <b>Uczestnik:</b>
            {{ userService.me.first_name }} {{ userService.me.last_name }}
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <b>Adres:</b>
            {{ confirmation.address }}
          </td>
        </tr>
      </table>

      <p
        *ngIf="isCC"
        class="description bold"
      >
        Wysłaliśmy do Ciebie zamówienie z San Tao Market. <br/>
        Jeżeli odebrałeś już przesyłkę, prosimy potwierdź otrzymanie nagród(-y).
      </p>

      <p
        *ngIf="!isCC"
        class="description"
      >
        <span><b>SPRAWDŹ NA PASKU PONIŻEJ, JAKĄ NAGRODĘ JUŻ WYSŁALIŚMY DO CIEBIE!</b></span>
        <br/><br/>
        <b>Może jest to Twoje zamówienie z San Tao Market lub ze Sklepu gadżetowego?</b><br/>
        Jeśli tak i odebrałeś już przesyłkę, to prosimy o potwierdzenie otrzymania nagród(-ody).
        <br/><br/>
        <b>Może to karta przedpłacona?</b><br/>
        Jeśli tak, to czekamy na Twoje potwierdzenie, że odebrałeś już przesyłkę.<br/>
        <b>UWAGA!</b> Karta nie jest jeszcze zasilona! Doładowanie karty odpowiednią kwotą nastąpi do 10 dni od momentu
        Twojego
        potwierdzenia jej odbioru.<br/>
        Przypominamy, że historię i status karty sprawdzisz w górnym menu, po kliknięciu w ikonę "Karty przedpłacone".<br/>
        <ng-container *ngIf="confirmation.card_number">
          <br/>
          <span>NUMER KARTY, KTÓREJ ODBIÓR POTWIERDZASZ: {{ confirmation.card_number }}</span>
          <br/>
        </ng-container>
        <br/>
        <b>A może jest to doładowanie do karty przedpłaconej?</b><br/>
        Jeśli tak to wiedz, że doładowaliśmy Twoją kartę. Koniecznie sprawdź czy środki znajdują
        się
        już na Twojej karcie i prosimy o potwierdzenie ich otrzymania.<br/>
        Pamiętaj, że historię i statusy doładowań sprawdzisz w górnym menu, po kliknięciu w ikonę "Karty przedpłacone".
      </p>

      <table class="table-prize">
        <thead>
        <tr>
          <th>Nagroda:</th>
          <th>Ilość:</th>
          <th>Wartość:</th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let prize of confirmation.prizes">
          <td>{{ prize.name }}</td>
          <td>{{ prize.amount }}</td>
          <td>{{ prize.amount * prize.price | currency:'PLN':'symbol':'1.2-2' }}</td>
        </tr>
        </tbody>
      </table>

      <div class="bottom-button">
        <table>
          <tr>
            <td>
              Potwierdzenie otrzymania przesyłki <br/>
              nie rzutuje na możliwość złożenia reklamacji
            </td>
            <td>
              Wybierz jeśli jeszcze nie odebrałaś(-eś) przesyłki. <br/>
              Uwaga! Niniejsze potwierdzenie odbioru nagrody po raz kolejny wyświetli się po upływie 24h.
            </td>
          </tr>
          <tr>
            <td>
              <button class="c-base c-secondary" (click)="toggleConfirmed()">
                Potwierdzam odbiór
              </button>
            </td>
            <td>
              <button class="c-base c-primary" (click)="delayConfirmationAlert()">
                Nie otrzymałam(-em) przesyłki
              </button>
            </td>
          </tr>
        </table>
      </div>

      <p class="helpdesk">
        Jeśli masz problem z nagrodą lub przesyłką lub nie wiesz jak wypełnić formularz<br/>
        skontaktuj się z Centrum Informacji San Tao:
        {{ 'global.helpdesk-email' | myTranslate }} (godz. 9-17)
      </p>

      <!-- TEMPLATE CONFIRMATION -->
      <div class="confirmation-template" *ngIf="isConfirmed">
        <div class="term-info">
          Kwota dodatkowej nagrody pieniężnej została już potrącona celem zapłaty zryczałtowanego podatku dochodowego od
          osób fizycznych do właściwego Urzędu Skarbowego. W przypadku zaistnienia konieczności złożenia reklamacji,
          jeśli nagroda, której dotyczy reklamacja, posiada kartę gwarancyjną, Uczestnik powinien zgłosić się do
          najbliższego serwisu. Jeśli nagroda nie posiada karty gwarancyjnej, Uczestnik proszony jest o mailowe zgłoszenie
          reklamacji: {{ 'global.helpdesk-email' | myTranslate }} (godz. 9-17).
        </div>

        <p class="text-error" style="margin-bottom: 10px">{{ error }} &nbsp;</p>

        <input
          class="input-date"
          type="date"
          [ngModel]="deliveryTime"
          (ngModelChange)="deliveryTimeChange($event)"
          readonly
        >
        <br/>

        <button class="c-base c-secondary" [disabled]="!deliveryTime || error" (click)="shopConfirmationsConfirm()">
          Ok
        </button>

        <button class="c-base c-primary" (click)="toggleConfirmed()">
          Anuluj
        </button>
      </div>
    </div>
  </ow-perfect-scrollbar>
</div>
