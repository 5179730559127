import { Component, OnInit } from '@angular/core';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { ApiEdenredCardService } from '../../../api/custom/services/api-edenred-card.service';
import { DialogService } from '../../../../../../shared/providers/dialog.service';

@Component({
  selector: 'app-edenred-card-history',
  templateUrl: './edenred-card-history.component.html',
})
export class EdenredCardHistoryComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiEdenredCardService) apiEdenredCardService: ApiEdenredCardService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data: { card };

  cardTopUps: CardTopUps[];

  ngOnInit() {
    if (this.data.card) {
      this.getEdenredCardHistory();
    }
  }

  getEdenredCardHistory() {
    this.apiEdenredCardService.getEdenredCardHistory(this.data.card['card_id'])
      .subscribe((resp) => {
        this.cardTopUps = resp;
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }
}

interface CardTopUps {
  amount: number;
  created_at: string;
  description: string;
  payout_request_at: string;
  status: number;
  status_text: string;
  top_up_at: string;
  topup_id: number;
}
