<div class="global-dialog ow-dialog">
    <m-ui-close-button (clicked)="exitMinigame(true)"></m-ui-close-button>

    <div class="minigame-container">
        <!-- PHASER -->
        <div id="match3-target" [ngClass]="{'started': match3Service.gameStarted}" [class.game-started]="match3Service.gameStarted"></div>

        <!-- DIALOG -->
        <div class="levels-navigation" *ngIf="match3Service.currentLevelDefs">
            <!-- STARTING DIALOG -->
            <div class="m3-dialog"
                 *ngIf="!match3Service.gameStarted && !match3Service.levelChange && match3Service.currentLevel===1">
                <div class="m3-dialog-content with-logo">
                    <div class="logo"></div>
                    <div class="level">Poziom {{match3Service.currentLevel}}</div>
                    <div class="missions-label"><span>Misje:</span></div>
                    <div class="missions-list d-flex">
                        <div class="mission-list-item" [matTooltip]="tooltipText[0]" [matTooltipDisabled]="!!mission.color"
                             *ngFor="let mission of match3Service.currentLevelDefs.missions">
                            <img class="mission-product-img" [src]="(mission.color || 'all')+'.png' | asset: 'minigames/match-three/products'">
                            <span class="mission-text">x{{mission.target}}</span>
                        </div>
                    </div>
                    <ng-container *ngIf="match3Service.currentBonusColor">
                        <div class="missions-label"></div>
                        <div class="missions-list">
                            <div class="mission-list-item bonus-mission">
                                <p>Premiowane</p>
                                <img [src]="match3Service.currentBonusColor.color+'.png' | asset: 'minigames/match-three/products'">
                            </div>
                        </div>
                    </ng-container>
                    <button class="c-base c-primary start-game" [disabled]="isLoading" (click)="startGame()">Start</button>
                </div>
            </div>

            <div class="m3-dialog level-result" *ngIf="!match3Service.gameStarted && match3Service.levelChange && !nextLevelClicked">
                <div class="m3-dialog-content with-logo">
                    <div class="logo"></div>
                    <div class="dialog-title">
                        <h2 *ngIf="match3Service.isGameOver">Koniec rozgrywki</h2>
                    </div>
                    <div class="finished-level-details">
                        <table>
                            <tbody>
                                <tr class="result-ingredient" *ngIf="match3Service.currentLevel !== 1">
                                    <td class="description">za poprzednie poziomy: </td>
                                    <td class="value">{{match3Service.prevTotalPoints}} pkt</td>
                                </tr>
                                <tr class="result-ingredient">
                                    <td class="description">za ostatni poziom: </td>
                                    <td class="value">{{match3Service.currentLevelPoints}} pkt</td>
                                </tr>
                                <tr class="result-ingredient">
                                    <td class="description">za ukończenie poziomu:</td>
                                    <td class="value">{{match3Service.currentLevelBonus}} pkt</td>
                                </tr>
                                <tr class="result-ingredient" *ngIf="match3Service.currentLevelDefs['boxLevel']">
                                    <td class="description">za skrzynie:</td>
                                    <td class="value">{{match3Service.currentBoxPoints}} pkt</td>
                                </tr>
                                <tr class="separator">
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr class="result-ingredient">
                                    <td class="description">Razem:</td>
                                    <td class="value">{{match3Service.totalPoints}} pkt</td>
                                </tr>
                                <tr class="result-ingredient" *ngIf="match3Service.isGameOver && match3Service.showBoxes">
                                    <td class="description">Twój bonus:</td>
                                    <td class="value">{{ match3Service.boxAmount * 25 }} %</td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="game-result" *ngIf="match3Service.isGameOver">
                            <p>Twój wynik:</p>

<!--                            <h2>{{match3Service.finalResult?.total_points}} pkt = {{match3Service.finalResult?.total_food}}</h2>-->
                            <div class="result" *ngIf="match3Service.finalResult">
                                {{ (match3Service.finalResult.total_score | NumberWithSpaces) }} pkt
                                =
                                <m-ui-currency
                                  [item]="{currency_id: 10, amount: match3Service.finalResult.total_food}"
                                  [lack]="false"
                                  [stockView]="STOCK_VIEW.CUSTOM"
                                  [customIconSize]="'big'"
                                  [customClassStock]="'stock-minigame-m3-result'"
                                ></m-ui-currency>
                            </div>

                        </div>
<!--                        <div class="result-ingredient">-->
<!--                            <td class="description">za poprzednie poziomy: </td>-->
<!--                            <td class="value">{{match3Service.prevTotalPoints}} pkt</td>-->
<!--                        </div>-->
<!--                        <div class="result-ingredient">-->
<!--                            <p class="description">za ostatni poziom: </p>-->
<!--                            <p class="value">{{match3Service.currentLevelPoints || 10}} pkt</p>-->
<!--                        </div>-->
<!--                        <div class="result-ingredient">-->
<!--                            <p class="description">za ukończenie poziomu:</p>-->
<!--                            <p class="value">{{match3Service.currentLevelBonus || 10}} pkt</p>-->
<!--                        </div>-->
<!--                        <div class="separator"></div>-->
<!--                        <div class="result-ingredient">-->
<!--                            <p class="description">razem:</p>-->
<!--                            <p class="value">{{match3Service.totalPoints  || 10}} pkt</p>-->
<!--                        </div>-->
                        <button *ngIf="!match3Service.isGameOver" class="c-base c-primary start-game" (click)="moveToTheNextLevel()">Dalej</button>
                        <button *ngIf="match3Service.isGameOver" class="c-base c-primary start-game" (click)="exitMinigame()">Zakończ</button>
<!--                        <button *ngIf="match3Service.isGameOver" class="base secondary text-uppercase end-game-btn"-->
<!--                                (click)="exitMinigame()">ZAKOńCZ-->
<!--                        </button>-->
                    </div>
                </div>
            </div>

            <div class="m3-dialog next-level-info"
                 *ngIf="!match3Service.gameStarted && match3Service.levelChange && match3Service.nextLevelDefs && !match3Service.isGameOver && nextLevelClicked">
                <div class="m3-dialog-content with-logo">
                    <div class="logo"></div>
                    <div class="level">Poziom {{match3Service.gameDefs.levels[match3Service.currentLevel].level}}</div>
                    <div class="missions-label"><span>Misje:</span></div>
                    <div class="missions-list d-flex">
                        <div class="mission-list-item"
                             *ngFor="let mission of match3Service.nextLevelDefs.missions">
                            <img class="mission-product-img"
                                 [matTooltip]="tooltipText[mission.color] || tooltipText[0]"
                                 [src]="(mission.color || 'all')+'.png' | asset: 'minigames/match-three/products'">
                            <span class="mission-text">x{{mission.target}}</span>
                        </div>
                    </div>
                    <ng-container *ngIf="match3Service.nextLevelBonusColor">
                        <div class="missions-label"></div>
                        <div class="missions-list">
                            <div class="mission-list-item bonus-mission">
                                <p>Premiowane</p>
                                <img [src]="match3Service.nextLevelBonusColor?.color+'.png' | asset: 'minigames/match-three/products'"
                                     [matTooltip]="tooltipText[match3Service.nextLevelBonusColor?.color] || tooltipText[0]">
                            </div>
                        </div>
                    </ng-container>
                    <button class="c-base c-primary start-game" (click)="nextLevel()">Start</button>
                </div>
<!--                <div class="m3-dialog-content">-->
<!--                    <div class="level">Kolejny poziom</div>-->
<!--                    <div class="level-circle">{{match3Service.gameDefs.levels[match3Service.currentLevel].level}}</div>-->
<!--                    <div class="missions-label"><span>Misje:</span></div>-->
<!--                    <div class="missions-list d-flex">-->
<!--                        <div class="mission-list-item" [matTooltip]="'Dowolny produkt'" [matTooltipDisabled]="!!mission.color"-->
<!--                             *ngFor="let mission of match3Service.nextLevelDefs.missions">-->
<!--                            <img [src]="(mission.color || 'all')+'.png' | asset: 'minigames/match3/products'">-->
<!--                            <span>x{{mission.target}}</span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="mission-separator"></div>-->
<!--                    <div class="missions-list" *ngIf="match3Service.nextLevelBonusColor">-->
<!--                        <div class="bonus-mission mission-list-item">-->
<!--                            <span>Premiowane</span>-->
<!--                            <img [src]="match3Service.nextLevelBonusColor.color+'.png' | asset: 'minigames/match3/products'">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <button class="base secondary text-uppercase start-game" (click)="nextLevel()">DALEJ</button>-->
<!--                </div>-->
            </div>

        </div>

        <!-- OVERLAY FOR THE GAME   -->
        <div class="game-ui" *ngIf="match3Service.gameStarted">
            <div class="score-and-moves">
                <div class="moves">
                    <p>
                        <i class="fas fa-arrows"></i>
                        {{this.match3Service.movesAvailable}}
                    </p>

                </div>
                <div class="score">
                    <p>
                        {{this.match3Service.totalPoints + match3Service.currentLevelPoints}} pkt
                    </p>
                </div>
            </div>

            <div class="missions">
                <div class="level">
                    <p class="level-value">{{match3Service.currentLevel}}</p>
                </div>

                <label>Misje</label>
                <div class="products-container">
                    <ng-container *ngFor="let mission of match3Service.currentLevelDefs.missions"
                                  [ngTemplateOutlet]="product"
                                  [ngTemplateOutletContext]="{mission: mission}"></ng-container>
                </div>

                <ng-container *ngIf="match3Service.currentBonusColor">
                    <label>Premia</label>
                    <div class="products-container margin-less">
                        <ng-container [ngTemplateOutlet]="product"
                                      [ngTemplateOutletContext]="{mission: match3Service.currentBonusColor}"></ng-container>
                    </div>
                </ng-container>
            </div>

            <div class="box-container" *ngIf="match3Service.showBoxes">
                <div class="box" *ngFor="let box of [].constructor(3); let i = index">
                    <img [src]="(i + 1 > match3Service.boxAmount ? 'box_empty.png' : 'box.png') | asset: 'minigames/match-three/ui'" />
                    <p>{{((i + 1) * 25)}}%</p>
                </div>
            </div>

<!--            <div class="side-button exit">-->
<!--                <button (click)="exitMinigame()" class="secondary normal square"><i class="fas fa-times"></i></button>-->
<!--            </div>-->
            <div class="side-button">
                <button class="square" [disabled]="match3Service.blockRedrawButton" [matTooltipShowDelay]="400" matTooltip="Przetasuj pamiątki" (click)="match3Service.restartBoard(true)">
                    <i class="fas fa-sync"></i>
                </button>
                <button class="square" [matTooltipShowDelay]="400" matTooltip="Wycisz muzykę" (click)="match3Service.mute()">
                    <i class="fas {{match3Service.soundsEnabled ? 'fa-volume' : 'fa-volume-mute'}}"></i>
                </button>
            </div>
        </div>
    </div>

</div>

<ng-template #product let-mission="mission">
    <div class="game-ui-product"
         [matTooltipShowDelay]="400"
         [matTooltip]="tooltipText[mission?.color] || tooltipText[0]">
        <img [src]="(mission?.color || 'all')+'.png' | asset: 'minigames/match-three/products'">
        <span *ngIf="mission?.target">x{{mission.target - match3Service.currentMissionProgress.get((mission?.color || 'all')) | noNegative}}</span>
    </div>
</ng-template>
