import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractCurrencyExchangeComponent } from '../../../../abstract/core/abstract-currency-exchange.component';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';

@Component({
  selector: 'app-custom-currency-exchange',
  templateUrl: './currency-exchange.component.html',

})
export class CurrencyExchangeComponent extends AbstractCurrencyExchangeComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.clearForm();
    this.subscribePrimary();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
