<div class="ow-dialog">
  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="close()"
  ></m-ui-close-button>

  <!-- TITLE -->
  <div class="description">
    Aby zawalczyć o 100% nagrody, do końca drugiego dnia kontraktu potwierdź zapoznanie się z jego zasadami.<br/>
    Przy braku odklikania check box, możesz zdobyć już tylko część nagrody. <br/><br/>

    Nagrody będą naliczane po przeliczeniu danych za drugi dzień trwania kontraktu.
  </div>
  <div class="title">
    NAGRODY DO ZDOBYCIA
  </div>
  <div class="prizes">
      <div class="item label">Jeśli potwierdzisz zapoznanie się z zasadami kontraktu:</div>
      <div class="item" *ngFor="let currencyItem of data?.max_prizes?.currency">
        <m-ui-currency [stockView]="STOCK_VIEW.J" [item]="currencyItem" [lack]="false"></m-ui-currency>
      </div>
      <div class="item" *ngFor="let productItem of data?.max_prizes?.product">
        <m-ui-product [stockView]="STOCK_VIEW.J" [item]="productItem" [lack]="false"></m-ui-product>
      </div>
      <div class="item final-prizes label">Jeśli nie potwierdzisz poznania zasad kontraktu:</div>
      <div class="item final-prizes" *ngFor="let currencyItem of data?.min_prizes?.currency">
        <m-ui-currency [stockView]="STOCK_VIEW.J" [item]="currencyItem" [lack]="false"></m-ui-currency>
      </div>
      <div class="item final-prizes" *ngFor="let productItem of data?.min_prizes?.product">
        <m-ui-product [stockView]="STOCK_VIEW.J" [item]="productItem" [lack]="false"></m-ui-product>
      </div>
  </div>

</div>
