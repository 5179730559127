<div class="ow-dialog global-dialog c-window-short edenred-list-container">
  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <div class="title">
    Twoja karta przedpłacona
  </div>

  <loading></loading>

  <div class="info"><span>Sprawdź info <a href="https://santao.santanderconsumer.pl/api/files/download/389a9087-90f8-4ad4-9be4-c39d51bc1cee/Twoja%20karta%20z%20logo%20SanTao.pdf" target="_blank">TUTAJ</a> </span></div>

  <div class="frame loading-opacity">
    <ow-perfect-scrollbar
      *ngIf="edenredCards"
      [scrollGradient]="edenredCards"
    >
      <div class="table-wrapper">
        <table>
          <thead>
          <tr>
            <th class="card-number">Numer seryjny na rewersie karty</th>
            <th class="card-status">Data przyznania</th>
            <th class="card-status">Status karty</th>
            <th class="card-status">Data odebrania</th>
            <th class="card-history">Historia doładowań</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let card of edenredCards">
            <!-- NUMBER -->
            <td class="card-number">
              {{ card.number }}
            </td>

            <!-- DATE -->
            <td class="card-status">
              {{ card.bought_at | date: 'dd.MM.yyyy' }}
            </td>

            <!-- DATE -->
            <td class="card-status">
              {{ card.status_text }}
            </td>

            <!-- DATE -->
            <td class="card-status">
              {{ card.received_at | date: 'dd.MM.yyyy' }}
            </td>

            <!-- HISTORY -->
            <td class="card-history">
              <button
                class="c-square small"
                (click)="openEdenredCardHistory(card)"
              >
                <i class="fas fa-history"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ow-perfect-scrollbar>
  </div>
</div>
