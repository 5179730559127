export const RANKS_NAME = {
  BUSINESS: 1,
  LEAGUE_MONTH: 2,
  GAME: 3,
  LEAGUE_QUARTER: 4,
  LEAGUE_HALF: 5,
  LEAGUE_YEAR: 6,
};

export const RANK_GROUP = {
  EMPLOYEE: 'employee',
  BRANCH: 'branch',
  REGION: 'region',
  MANAGERS: 'managers',
};

export const RANK_GAME_GROUP = {
  PLAYERS: 'players',
  BRANCHES: 'branches',
  REGIONS: 'regions',
  MANAGERS: 'managers',
};

export const RANK_GAME_TYPE = {
  LEVEL: 1,
  SPARKLE: 999,
  POPULATION: 8,
  EXPLORED_ISLAND: 9,
  TREASURY: 10,
  PRESTIGE: 12,
  CHEST_FROM_WATER: 20,
  TOTAL: 99,
  TOTAL_MONTH: 98,
  RANK_COFFEE: 102,
  RANK_DRINKS: 103,
  RANK_DESSERT: 104,
  RANK_MUSEUM: 101,
  RANK_BAR_PREVIOUS_DAY: 100,
  BAR_TOTAL: 110,
};

export const LEVEL_FILTER_KEY = {
  COUNTRY: 'country',
  REGION: 'region',
  BRANCH: 'branch',
};

export const PAGINATION_TYPES = {
  MONTH: 'month',
  QUARTER: 'quarter',
  HALF: 'half',
  YEAR: 'year'
};

export const RANK_LEAGUE_TYPES = {
  RANK: 'rank',
  RESULTS: 'results',
};

export const RANK_VALUE_TYPE = {
  PERCENTAGE: 1,
  VALUE: 2,
};
