import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import * as R from "ramda";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { Primary } from "../../../../../../../../core/interfaces/primary";
import { ParametersService } from "../../../../../../../../core/providers/parameters.service";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { BuildingConfig } from "../../../../../../game-engine/interfaces/building-config";
import { AbstractRequirementsComponent } from "../../../../../shared-ui/abstract/abstract-requirements.component";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";

interface BuildingRequirementsConfig {
  requireAtLeastOneParameterFor: number[]; // require at least one income element for provided parameters, expects parameter_id
}

@Component({
  selector: "app-building-requirements",
  templateUrl: "./building-requirements.component.html",
})
export class BuildingRequirementsComponent extends AbstractRequirementsComponent implements OnInit, OnDestroy {
  @OwInject(ParametersService) parametersService: ParametersService;
  @Input() building: BuildingConfig;
  @Input() parametersOutcome: any[];
  @Input() hideTxtRequired?: boolean;
  @Input() config: BuildingRequirementsConfig = {
    requireAtLeastOneParameterFor: [],
  };
  parametersOutcomeFormatted: any[];
  STOCK_VIEW = STOCK_VIEW;
  primary: Primary;
  textErrors: string[] = [];

  ngOnInit() {
    this.subscribePlayer();
  }

  buildWaitingList() {
    super.buildWaitingList();

    this.waitingList["parameters-outcome"] = { progress: true };
    this.checkParametersOutcome();
  }

  createTextsList() {
    super.createTextsList();
    this.createTextsError();
  }

  createTextsError() {
    this.textErrors = [];

    Object.keys(this.waitingList)
      .filter(key => {
        return this.waitingList[key].value && !this.waitingList[key].value.valid;
      })
      .forEach(key => {
        if (this.waitingList[key].value.textError) {
          this.textErrors.push(this.waitingList[key].value.textError);
        }
      });
  }

  emitStatus() {
    const valid =
      Object.keys(this.waitingList).filter(key => {
        return this.waitingList[key].value.valid === false;
      }).length === 0;

    this.requirementsStatus.emit({
      valid: valid,
      requirementsList: this.waitingList,
      textErrors: this.textErrors,
    });
  }

  checkParametersOutcome() {
    let valid = true;
    if (this.parametersOutcome) {
      this.parametersOutcomeFormatted = R.clone(this.parametersOutcome);

      this.parametersOutcomeFormatted.forEach(parameter => {
        const parameterBalance = this.parametersService.getParameterBalanceBy({
          key: "parameter_id",
          value: parameter.parameter_id,
        });

        // sometimes we just need to check if there's at least one of required item
        // for instance we need only one doctor to take care of all animals
        // so, when we find at least one doctor it means that requirements are fulfilled
        const foundParameter = this.config.requireAtLeastOneParameterFor.find(x => x === parameter.parameter_id);
        if (foundParameter) {
          parameter.have = (parameterBalance?.["income"] || 0) >= parameter.outcome; // require at least one income element
          parameter.amount = 1;
        } else {
          parameter.have = (parameterBalance?.["balance"] || 0) >= parameter.outcome;
          parameter.amount = parameter.outcome;
        }
      });

      this.parametersOutcomeFormatted = this.parametersOutcomeFormatted.filter(parameter => parameter.amount > 0);

      valid = this.parametersOutcomeFormatted.every(parameter => parameter.have);
    }

    const event = {
      action: "parameters-outcome",
      value: {
        valid,
      },
    };

    this.checkCompleted(event);
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
