<div
  class="ow-dialog"
  [ngStyle]="{
    maxWidth: data.style?.maxWidth,
    maxHeight: data.style?.maxHeight
  }"
>
  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="close()"
    *ngIf="!data.buttonClose"
  ></m-ui-close-button>

  <!-- TITLE -->
  <p class="title" *ngIf="data.title">
    {{ data.title }}
  </p>

  <ow-perfect-scrollbar *ngIf="data.isScrollbarOn; else descriptionTpl"
                     [ngStyle]="{height: data.style?.fixedPerfectScrollbarHeight}">
    <ng-container [ngTemplateOutlet]="descriptionTpl"></ng-container>
  </ow-perfect-scrollbar>

  <button
    *ngIf="data.buttonClose"
    class="c-base c-secondary"
    (click)="matDialogRef.close(false)"
    [id]="data.buttonClose.id"
  >
    {{ data.buttonClose.label }}
  </button>
</div>

<!-- DESCRIPTION TPL -->
<ng-template #descriptionTpl>
  <p
    class="description"
    [style.text-align]="data.style?.textAlign"
    *ngIf="data.description"
    [innerHTML]="data.description | safe:'html'"
  ></p>
</ng-template>


