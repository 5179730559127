<div class="upgrade-container">
  <div class="upgrade-label" *ngIf="valuesToBeShown.showDifferenceComponent">
    <m-ui-product
      *ngIf="upgrade.product_prizes[0]"
      [item]="upgrade.product_prizes[0]"
      [other]="{
        value: 'x'
      }"
      [stockView]="STOCK_VIEW.CUSTOM"
      [customIconSize]="'big'"
      [customClassStock]="'stock-san-a'"
      [lack]="false"
      [class.quantity-diff]="parameters && parameters.quantity_diff"
      [hideValue]="true"
      [class.new]="valuesToBeShown.isNew"
    ></m-ui-product>

    <m-ui-currency
      *ngIf="upgrade.currency_prizes[0]"
      [item]="upgrade.currency_prizes[0]"
      [other]="{
        value: 'x'
      }"
      [stockView]="STOCK_VIEW.CUSTOM"
      [customIconSize]="'big'"
      [customClassStock]="'stock-san-a'"
      [lack]="false"
      [class.quantity-diff]="parameters && parameters.quantity_diff"
      [hideValue]="true"
      [class.new]="valuesToBeShown.isNew"
    ></m-ui-currency>

    <span *ngIf="valuesToBeShown.showCurrentValue">
      {{ valuesToBeShown.currentValue }}
    </span>

    <div
      class="upgrade-value-container"
      [class.has-normal]="normal"
    >
      <div
        *ngIf="valuesToBeShown.showCurrentValue"
        class="upgrade-arrow"
      ></div>

      <span
        class="upgrade"
        [class.quantity-diff]="parameters && parameters.quantity_diff"
      >
        {{ valuesToBeShown.upgradedValue }}
      </span>
    </div>
  </div>
</div>
