import * as R from 'ramda';
import { LeagueBonusTaskObject } from '../../interfaces/custom/league-bonus-task-object.interface';

export class BusinessLeagueBonusTask {
  bonusTaskObject: LeagueBonusTaskObject[] = [
    {name: '', bonus_type_id: 0},
    {name: 'Obligatoryjne', bonus_type_id: 1},
    {name: 'Dodatkowe', bonus_type_id: 2},
  ];

  getBonusTask(id): LeagueBonusTaskObject {
    id = +id;

    const bonusTaskObjectDefault: LeagueBonusTaskObject = {name: 'undefined', bonus_type_id: null};
    const bonusTaskObjectResult = this.bonusTaskObject.find((item) => item.bonus_type_id === id);

    return R.clone(bonusTaskObjectResult || bonusTaskObjectDefault);
  }
}
