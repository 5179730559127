<div
  *ngIf="parameters.length"
  class="building-upgrade-diff-parameter"
>
  <div class="sub-title" *ngIf="!hideSubTitle">Parametry:</div>

  <app-building-upgrade-diff-parameter
    *ngFor="let parameter of parameters"
    [normal]="parameter.normal"
    [parameters]="parameter.parameters"
    [upgrade]="parameter.upgrade"
  ></app-building-upgrade-diff-parameter>
</div>
