<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button [preventDefault]="true" (clicked)="closeOrCloseAll()"></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button [preventDefault]="true" (clicked)="close()" *ngIf="messageOpenDetailsData.fromList"></m-ui-back-button>

  <!-- IF MESSAGE EXIST -->
  <div class="container loading-opacity">
    <ng-container *ngIf="message">
      <!-- LVL UP IMAGE -->
      <div class="level-up">
        <div class="level">
          {{ message.parameters.level }}
        </div>
      </div>

      <!-- TITLE -->
      <div class="title" [innerHTML]="'Awansujesz na kolejny poziom!'"></div>

      <!-- UNLOCKED BUILDING -->
      <ng-container *ngIf="unlockedBuildings.items.length > 0">
        <m-ui-separator [title]="'Nowości'"></m-ui-separator>

        <div class="unlock">
          <!-- SLIDER -->
          <swiper-container #swiperRef1 swiperElement [config]="config1" *ngIf="initSwiper1" init="false" class="swiper">
            <swiper-slide class="page" *ngFor="let page of unlockedBuildings?.pages">
              <app-building-thumbnail *ngFor="let building of page" [building]="building"></app-building-thumbnail>
            </swiper-slide>
          </swiper-container>

          <ng-container *ngIf="swiper1">
            <div class="arrows-slider">
              <div
                class="arrow arrow-blue-left prev"
                (click)="prevSlide('swiper1', 'currentSlideIndex1')"
                [class.hide]="swiper1?.isBeginning"></div>
              <div class="arrow-blue-right next" (click)="nextSlide('swiper1', 'currentSlideIndex1')" [class.hide]="swiper1.isEnd"></div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- MESSAGE TRASH -->
  <button class="c-square small trash" *ngIf="!message?.deleted" matTooltip="Przenieś do archiwum">
    <i class="fas fa-archive trash-icon" (click)="moveToTrash()"></i>
  </button>
</div>
