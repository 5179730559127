import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SharedModule } from "../../../../../shared/shared.module";
import { BuildingsModule } from "../../../buildings/module/core/buildings.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import {AntyminerContinueComponent} from '../../base/custom/dialogs/antyminer-continue/antyminer-continue.component';
import {AntyminerStartComponent} from '../../base/custom/dialogs/antyminer-start/antyminer-start.component';
import {AntyminerBeginComponent} from '../../base/custom/dialogs/antyminer-begin/antyminer-begin.component';
import {AntyminerBoardComponent} from '../../base/custom/dialogs/antyminer-board/antyminer-board.component';
import {
  AntyminerStartRequirementsComponent
} from '../../base/custom/components/antyminer-start-requirements/antyminer-start-requirements.component';

@NgModule({
  declarations: [
    AntyminerContinueComponent,
    AntyminerStartComponent,
    AntyminerBeginComponent,
    AntyminerBoardComponent,
    AntyminerStartRequirementsComponent
  ],
  imports: [CommonModule, SharedModule, SharedUiMobileModule, BuildingsModule],
})
export class AntyminerModule {}
