import { EventEmitter } from "@angular/core";

import { CB_ANNIE_MOVE_TIME, CB_ANNIE_STANDING_ANIMATION_TIME, CB_ATLAS_KEY, CB_SPRITE_POSITIONS } from "../../catch-bananas.constans";
import { generateAnimationFramesList } from "../../catch-bananas.helpers";
import { CatchBananasPlayScene } from "../../scenes/CatchBananasPlay.scene";
import { AbstractArcadeSprite } from "./abstract-arcade-sprite";

export class AnnieSprite extends AbstractArcadeSprite {
  position: number;
  collectSoundEmitter = new EventEmitter<string>();

  constructor(scene: CatchBananasPlayScene, x: number, y: number) {
    super(scene, x, y, CB_ATLAS_KEY, "ania-01.png");
    this.position = this.scene.sceneConfig.annie.position;
    this.handlePlayerInput();
    this.collectSoundEmitter.subscribe(key => {
      switch (key) {
        case "collect":
          return this.scene.collectSound.play();
        case "error":
          return this.scene.errorSound.play();
        case "clock":
          return this.scene.clockSound.play();
        case "collect-wood":
          return this.scene.collectWoodSound.play();
      }
    });

    // remove gravity for annie - fixes issue when the annie is collapsing through the platform
    if (this.body["setAllowGravity"] !== null) {
      (this.body as Phaser.Physics.Arcade.Body).setAllowGravity(false);
    }
  }

  handlePlayerInput() {
    this.scene.input.keyboard.on("keydown", event => {
      if (this.scene.keyLeft.isDown && this.scene.isPlaying && this.position > 1) {
        this.moveLeft();
      }
      if (this.scene.keyRight.isDown && this.scene.isPlaying && this.position < 4) {
        this.moveRight();
      }
    });
  }

  moveLeft() {
    this.position -= 1;
    this.setFlipX(true);
    this.scene.anims.play("annie-move", this);

    this.scene.add.tween({
      targets: this,
      repeat: 0,
      duration: CB_ANNIE_MOVE_TIME,
      y: CB_SPRITE_POSITIONS.y.annie, // this.y,
      x: CB_SPRITE_POSITIONS.x[this.position],
      ease: Phaser.Math.Easing.Quadratic.Out,
      onComplete: () => {
        this.scene.anims.play("annie-standing", this);
      },
    });
  }

  moveRight() {
    this.position += 1;
    this.setFlipX(false);
    this.scene.anims.play("annie-move", this);

    this.scene.add.tween({
      targets: this,
      repeat: 0,
      duration: CB_ANNIE_MOVE_TIME,
      y: CB_SPRITE_POSITIONS.y.annie,
      x: CB_SPRITE_POSITIONS.x[this.position],
      ease: Phaser.Math.Easing.Quadratic.Out,
      onComplete: () => {
        this.scene.anims.play("annie-standing", this);
      },
    });
  }

  createAnimations() {
    this.scene.anims.create({
      key: "annie-standing",
      frames: generateAnimationFramesList(CB_ATLAS_KEY, "ania", 10),
      delay: 0,
      frameRate: 8,
      duration: CB_ANNIE_STANDING_ANIMATION_TIME,
      repeat: -1,
    });

    this.scene.anims.create({
      key: "annie-move",
      frames: generateAnimationFramesList(CB_ATLAS_KEY, "ania_jump", 1),
      delay: 0,
      frameRate: 8,
      duration: CB_ANNIE_MOVE_TIME,
      repeat: 0,
    });

    this.scene.anims.create({
      key: "ania_triumph",
      frames: generateAnimationFramesList(CB_ATLAS_KEY, "ania_triumph", 4),
      delay: 0,
      frameRate: 30,
      duration: CB_ANNIE_STANDING_ANIMATION_TIME,
      repeat: 0,
    });
  }

  handleCompleteAnimation() {
    this.on("animationcomplete", e => {
      this.scene.anims.play("annie-standing", this);
    });
  }

  spriteConfiguration() {
    this.setDepth(3).setOrigin(0.5, 1);
    this.scene.anims.play("annie-standing", this);
    this.body.setSize(this.width, 30);
    this.body.setOffset(0, 0);
    this.scene.physics.add.collider(this, this.scene.catchBananasState.platformAnnie);
  }
}
