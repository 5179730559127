import { Component, OnInit } from "@angular/core";
import { tap } from "rxjs/operators";

import { AbstractQaListComponent } from "../../../../abstract/core/abstract-qa-list.component";
import { QA_TYPE } from "../../../../consts/core/qa-type.const";

@Component({
  selector: "app-custom-qa-list",
  templateUrl: "./qa-list.component.html",
})
export class QaListComponent extends AbstractQaListComponent implements OnInit {
  activeTab: TabTop;
  qaLabel = "";
  tabs: TabTop[] = [];
  isTabRankShow: boolean;
  searchValue: string;
  hideView = false;

  TAB = {
    QA: "qa",
    RANK: "rank",
  };

  ngOnInit() {
    this.baseInit();
  }

  setTabs() {
    this.tabs = [
      { label: this.qaLabel, name: this.TAB.QA, show: true, color: "yellow" },
      { label: "Ranking", name: this.TAB.RANK, show: this.isTabRankShow, color: "green" },
    ];
  }

  setQaLabel() {
    this.qaLabel = this.activeQa.type === QA_TYPE.QUIZ ? "Quiz" : "Ankieta";
    this.isTabRankShow = this.activeQa.type === QA_TYPE.QUIZ && this.activeQa["rank_edition_id"];
  }

  changeTabByName(name: string) {
    let tab = this.tabs.find(tab => tab.name === name);

    if (!tab || (tab && !tab.show)) {
      tab = this.tabs.find(tab => tab.show === true);
    }

    this.changeTab(tab);
  }

  changeTab(tab: TabTop) {
    this.activeTab = tab;
  }

  changeActiveQa(qa) {
    super.changeActiveQa(qa);
    this.setQaLabel();
    this.setTabs();
    this.changeTabByName(this.TAB.QA);
  }

  afterQaList() {
    super.afterQaList();

    if (this.activeQa && (this.activeQa.location === 50 || (this.activeQa.location >= 101 && this.activeQa.location <= 112))) {
      if (!this.isExpiredQa && !this.activeQa.last_attempt) {
        this.startQa();
      }

      if (!this.isExpiredQa && this.activeQa.last_attempt && !this.activeQa.last_attempt.finished_at) {
        this.openQaDetails();
      }

      if (this.isExpiredQa || (this.activeQa.last_attempt && this.activeQa.last_attempt.finished_at)) {
        this.close();

        this.dialogService.openAlert({
          description: this.activeQa.description,
        });
      }
    }
  }
  startQa() {
    this.hideView = true;
    this.apiQaService
      .startQa({
        qa_id: this.activeQa.qa_id,
        type: this.activeQa.type,
      })
      .subscribe(resp => {
        this.activeQa.last_attempt = resp;
        this.openQaDetails();
        this.close();
      });
  }
}

interface TabTop {
  label;
  name: string;
  show: boolean;
  className?: string;
  color: string;
}
