<div class="global-dialog ow-dialog c-window-long">

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Zadania</span>
      </div>
    </div>

    <div class="source-data-date-tab" *ngIf="tasksSourceDataDate">
      Dane aktualne na: {{ tasksSourceDataDate | date:'dd-MM-y' }}
    </div>
  </div>

  <div class="content loading-opacity">
    <ow-perfect-scrollbar [scrollGradient]="cardsTasks" *ngIf="cardsTasks?.length > 0; else noItemTpl">
      <ng-container *ngFor="let card of cardsTasks">
        <div class="container-card">
          <span class="animal-icon"></span>
          <div
            class="card"
            (click)="openCardTaskDetails(card)"
            [ngClass]="'card-template-' + card.card_template_id"
          >
            <!-- NAME -->
            <div class="card-name">{{ card.name }}</div>

            <!-- PROGRESSBAR -->
            <div class="progressbar-container">
              <div class="progressbar">
                <div class="fill" [style.width]="card.performance_percent + '%'" [ngClass]="card.color_id"></div>
              </div>
              <div class="box">
                <div class="progressbar-value">
                  {{ card.performance_percent }}%
                </div>
              </div>
            </div>

            <!-- PRIZES -->
            <div class="card-prizes">
              <ng-container *ngIf="card.prizes?.length > 0">
                <ng-container *ngFor="let prize of card.prizes">
                  <m-ui-currency
                    *ngIf="prize['type'] === 'currency' && prize['currency_id'] != 7"
                    [item]="prize"
                    [lack]="false"
                    [stockView]="STOCK_VIEW.H"
                  ></m-ui-currency>

                  <m-ui-product
                    *ngIf="prize['type'] === 'product' && (prize['product_id'] === 203 ? (owDate.year <= 2020 && owDate.month <= 9) : true)"
                    [item]="prize"
                    [lack]="false"
                    [stockView]="STOCK_VIEW.H"
                    [lockOpenStorage]="true"
                  ></m-ui-product>
                </ng-container>
              </ng-container>
            </div>

            <!-- CARD TAB -->
            <div class="tab" [ngClass]="card.color_id"></div>
          </div>
        </div>
      </ng-container>
    </ow-perfect-scrollbar>

    <ng-template #noItemTpl>
      <div class="no-item">
        Brak danych
      </div>
    </ng-template>
  </div>

  <!-- LOADING -->
  <loading></loading>

  <!-- CLOSE -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button></m-ui-back-button>

  <!-- CLEARED -->
  <div class="cleared" *ngIf="isSomeCardCleared"></div>

  <div class="navigation" *ngIf="owPaginationDate">
    <!-- PREV -->
    <button
      class="previous"
      [disabled]="!owPaginationDate.allowPrev.month"
      (click)="prev()"
    >
      <i class="far fa-chevron-square-left nav-arrow"></i>
    </button>

    <!-- MONTH -->
    <span class="value">
      <ng-container *ngIf="owDate">
        {{ owDate.month_text_normal }}
      </ng-container>
    </span>

    <!-- NEXT -->
    <div
      [matTooltip]="'Dane dostępne wkrótce.'"
      [matTooltipPosition]="'above'"
      [matTooltipDisabled]="owPaginationDate.allowNext.month"
    >
      <button
        class="next"
        [disabled]="!owPaginationDate.allowNext.month"
        (click)="next()"
      >
        <i class="far fa-chevron-square-right nav-arrow"></i>
      </button>
    </div>
  </div>
  <div *ngIf="branchName" class="branch-name">{{ branchName }}</div>
</div>
