export const CB_GAME_WIDTH = 1000;
export const CB_GAME_HEIGHT = 570;
export const CB_ATLAS_KEY = "cb-atlas";
export const CB_UI_KEY = "cb-ui";
export const CB_PLAY_SCENE_KEY = "CatchBananasPlayScene";
export const CB_PAUSE_USED_LS_KEY = "cb-pause-used";
export const LS_CONFIG_KEY = "cb-config";

// DURATION TIMES
export const CB_ANNIE_MOVE_TIME = 200;
export const CB_ANNIE_STANDING_ANIMATION_TIME = 6000;
export const CB_BANANA_END_ANIMATION = 2000;

export const CB_EDITABLE = {
  CB_GRAVITY: 250,
  CB_TIME_DIFFICULITY: 6,
  CB_BANANA_VELOCITY: 0,
  CB_BANANA_VELOCITY_ACCELERATION: 60,
  CB_MONKEY_BANANA_THROW_INTERVAL: 2000,
  CB_MONKERY_JUMP_INTERVAL: 4000,
  CB_ROTTEN_BANANA_CHANCE: 0.25,
  CB_CLOCK_CHANCE: 0.05,
  CB_CLOCK_POINTS_TIME: 5,
  CB_NORMAL_GAME_TIME: 90,
  CB_PREMIUM_GAME_TIME: 120,
};

// POSITIONS
export const CB_SPRITE_POSITIONS = {
  x: {
    0: 0,
    1: 169,
    2: 398,
    3: 610,
    4: 830,
  },
  y: {
    annie: 500,
    palms: 478,
    monkeys: 178,
  },
};
