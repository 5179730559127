import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import { filter, take } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { selectGameBoardTile } from "../../../../../../../../store/game/selectors";
import { AppState } from "../../../../../../../../store/state";
import { PlayerBuilding } from "../../../../../../game-engine/interfaces/player-building.config";
import { BuildingsService } from "../../../../../../services/buildings.service";
import { BuildingData } from "../../../../../buildings/interfaces/core/dialogs/building-data.interface";
import { EVENT_DIALOGS_NAMES_TRACKING_CUSTOM } from "../../../../consts/custom/event-dialogs/event-names.const";
import { PlayerBuildingDetailsExtra } from "../tracking-begin/tracking-begin.component";
import { BuildingDataTracking } from "../tracking-board/tracking-board.component";

@Component({
  selector: "app-tracking-continue",
  templateUrl: "./tracking-continue.component.html",
})
export class TrackingContinueComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(MAT_DIALOG_DATA) data: BuildingData;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<TrackingContinueComponent>;

  playerBuilding: PlayerBuilding;
  buildingDetails: PlayerBuildingDetailsExtra;

  subs = {
    board: null,
  };

  ngOnInit() {
    this.subscribeBoardTile();
  }

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameBoardTile, { playerTileId: this.data.playerTileId }),
        filter(state => !!state),
        take(1)
      )
      .subscribe(tile => {
        this.playerBuilding = tile.player_building;
        this.getBuildDetails();
      });
  }

  getBuildDetails() {
    this.buildingsService.getPlayerBuildingDetails(this.playerBuilding.player_building_id).subscribe({
      next: buildingDetails => {
        this.buildingDetails = buildingDetails;
      },
    });
  }

  trackingContinue() {
    const buildingBuildData: BuildingDataTracking = {
      playerTileId: this.data.playerTileId,
      playerBuildingId: this.playerBuilding.player_building_id,
      playerBuildingLevel: this.playerBuilding.level,
      boardDetails: {
        board: {
          x: this.buildingDetails.tracking_definition.board.x,
          y: this.buildingDetails.tracking_definition.board.y,
        },
        buildingDetails: this.buildingDetails,
        background: this.buildingDetails.tracking_definition.background,
      }
    };

    setTimeout(() => {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_TRACKING_CUSTOM.TRACKING_BOARD,
        config: {
          data: buildingBuildData,
        },
      });

      this.matDialogRef.close();
    });
  }
}
