import { Component, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Qa } from '../../../../interfaces/core/qa.interface';
import { EVENT_DIALOGS_NAMES_QA } from '../../../../consts/core/event-dialogs/event-names.const';
import { EventEmitterDialogsService } from '../../../../../../../../core/services/core/event-emitter-dialogs.service';
import { GetQaListRequest } from '../../../../api/core/interfaces/get-qa-list-request.interface';
import { ApiQaService } from '../../../../api/core/services/api-qa.service';
import { QA_TYPE } from '../../../../consts/core/qa-type.const';
import * as moment from 'moment';
import * as R from 'ramda';

@Component({
  selector: 'app-custom-qa-list-view',
  templateUrl: './qa-list-view.component.html',
})
export class QaListViewComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(ApiQaService) apiQaService: ApiQaService;
  @OwInject(MAT_DIALOG_DATA) data: {
    location?: number,
  };

  items: Qa[];
  QA_TYPE = QA_TYPE;
  activeTab;
  tabs = [
    { label: 'Quizy', color: 'yellow', type: 'quiz', visible: true },
    { label: 'Ankiety', color: 'blue-light', type: 'survey', visible: true },
  ];

  ngOnInit(): void {
    this.setTab();
  }

  setTab() {
    let tab;

    if (!tab) {
      tab = this.tabs.find(t => t.visible);
    }

    this.changeTab(tab);
  }

  changeTab(tab) {
    this.activeTab = tab;
    if (this.activeTab) {
      this.getAllQa(this.activeTab.type);
    }
  }

  getAllQa(type) {
    const getQaListRequest: GetQaListRequest = {
      location: this.data.location,
      type: type,
    };

    this.apiQaService.getQaList(getQaListRequest)
      .subscribe((resp) => {
        this.items = resp;
        this.afterQaList();
      });
  }

  afterQaList() {
    this.items.forEach((i) => {
      this.setCorrectlyFormatDates(i);
    });
  }

  openQaList(qa) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA.QA_LIST,
      config: {
        data: {
          location: 1,
          qa: qa,
          index: this.items.indexOf(qa.id),
          isOpenByQaListView: true,
          isShowBackButton: true,
        }
      }
    });
  }

  setCorrectlyFormatDates(item) {
    const canAnswerFromMoment = (() => moment(item.can_answer_from))();
    const canAnswerToMoment = (() => moment(item.can_answer_to))();

    if (this.isTheSameStart(canAnswerFromMoment) && this.isTheSameStart(canAnswerToMoment)) {
      const format = 'YYYY-MM-DD';
      item.canAnswerFrom = canAnswerFromMoment.format(format);
      item.canAnswerTo = canAnswerToMoment.subtract(1, 'days').format(format);
    } else {
      const format = 'YYYY-MM-DD HH:mm';
      item.canAnswerFrom = canAnswerFromMoment.format(format);
      item.canAnswerTo = canAnswerToMoment.format(format);
    }
  }

  isTheSameStart(date): boolean {
    return R.clone(date).unix() === R.clone(date).startOf('day').unix();
  }
}
