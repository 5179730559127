import { Component, OnDestroy, OnInit } from "@angular/core";

import { translate } from "../../../../../../../../core/helpers/translate.helper";
import { BoardTileState } from "../../../../../../../../store/game/interfaces/board-tile.state";
import { PlayerBuilding } from "../../../../../../game-engine/interfaces/player-building.config";
import { AbstractBuildingProductionComponent } from "../../../../abstract/core/abstract-building-production.component";
import {OwInject} from '../../../../../../../../core/decorators/ow-inject.decorator';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BuildingData} from '../../../../interfaces/core/dialogs/building-data.interface';

@Component({
  selector: "app-building-production",
  templateUrl: "./building-production.component.html",
})
export class BuildingProductionComponent extends AbstractBuildingProductionComponent implements OnInit, OnDestroy {
  @OwInject(MAT_DIALOG_DATA) data: BuildingData & { isCharityDodo?: boolean };
  cyclesRequirements = { valid: true };
  isHellPad: boolean;

  ngOnInit() {
    this.baseInit();
  }

  setBuilding(tile: BoardTileState & { inner_island_player_production_building: PlayerBuilding }) {
    this.building = tile.inner_island_player_production_building || tile.player_building;
  }

  cancelPlayerProductionConfirm() {
    this.dialogService.openConfirm(
      {
        title: translate("building-production.alert-cancel.title"),
        description: this.data?.isCharityDodo ? DESC_CHARITY : translate("building-production.alert-cancel.description"),
        buttonSuccess: {
          label: this.data?.isCharityDodo ? CANCEL_CHARITY : translate("building-production.alert-cancel.button-success"),
        },
        buttonClose: {
          label: translate("building-production.alert-cancel.button-close"),
        },
      },
      confirm => {
        if (confirm) {
          this.cancelPlayerProduction();
        }
      }
    );
  }

  ngOnDestroy() {
    this.baseDestroy();
  }
}

const DESC_CHARITY =
  "Zamierzasz przerwać wdrażanie programu charytatywnego!<br/>Otrzymasz zwrot zainwestowanych środków, ale program nie zostanie zrealizowany, a Ty nie otrzymasz certyfikatu" +
  "<br/>poświadczającego udzielenie pomocy. Co więcej, za tę realizację nie zostaną przesłane środki na rzecz akcji charytatywnych w realnym świecie.";
const CANCEL_CHARITY = "Anuluj wdrażanie programu";
