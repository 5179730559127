import { CB_UI_KEY } from "../../catch-bananas.constans";
import { CatchBananasPlayScene } from "../../scenes/CatchBananasPlay.scene";
import { AbstractSprite } from "./abstract-sprite";

export class BoxGuiSprite extends AbstractSprite {
  id: number;
  throwTime: number;
  icon: string;

  constructor(scene: CatchBananasPlayScene, x: number, y: number, init?: Partial<BoxGuiSprite>) {
    super(scene, x, y, CB_UI_KEY, init.icon);
    Object.assign(this, init);
  }
}
