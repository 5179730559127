import { Component, OnInit } from '@angular/core';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { ApiService } from '../../../../../../core/providers/api.service';
import { ApiOptions } from '../../../../../../core/interfaces/api-options';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { AUTH_ROUTING_ABSOLUTE_PATH } from '../../../../consts/core/routing-auth-absolute.const';

@Component({
  selector: 'app-custom-registration',
  templateUrl: './registration.component.html',
})
export class RegistrationComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(Router) router: Router;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiService) apiService: ApiService;
  AUTH_ROUTING_ABSOLUTE_PATH = AUTH_ROUTING_ABSOLUTE_PATH;
  form: FormGroup;
  formFinal: FormGroup;
  TEMPLATE = {
    REGISTRATION_VERIFY_FORM: 1,
    REGISTRATION_VERIFY_WRONG_DATA: 2,
    REGISTRATION_WRONG_DATA_SUCCESS: 3,
    REGISTRATION_VERIFY_SUCCESS: 4,
    REGISTRATION_SUCCESS: 5,
  };
  activeTemplate: number;

  ngOnInit() {
    this.clearForm();
    this.changeTemplate(this.TEMPLATE.REGISTRATION_VERIFY_FORM);
  }

  changeTemplate(template: number) {
    this.activeTemplate = template;
  }

  clearForm() {
    this.form = this.fb.group({
      firstName: this.fb.control(null, [Validators.required]),
      lastName: this.fb.control(null, [Validators.required]),
      email: this.fb.control(null, [Validators.required, Validators.email]),
      psn: this.fb.control(null, [Validators.required]),
      term1: this.fb.control(null, [Validators.requiredTrue]),
    });

    this.formFinal = this.fb.group({
      term: this.fb.control(null, [Validators.requiredTrue]),
    });
  }

  registration() {
    const data: RegistrationRequestData = {
      first_name: this.form.value.firstName,
      last_name: this.form.value.lastName,
      email: this.form.value.email,
      psn: this.form.value.psn,
    };

    this.registrationRequest(data)
      .subscribe(() => {
        this.changeTemplate(this.TEMPLATE.REGISTRATION_SUCCESS);
      });
  }

  registrationVerify() {
    const data: RegistrationRequestData = {
      first_name: this.form.value.firstName,
      last_name: this.form.value.lastName,
      email: this.form.value.email,
      psn: this.form.value.psn,
    };

    this.registrationVerifyRequest(data)
      .subscribe(() => {
        this.changeTemplate(this.TEMPLATE.REGISTRATION_VERIFY_SUCCESS);
      }, (errResp) => {
        errResp.defaultHandler.unsubscribe();

        if (errResp.status === 404) {
          this.changeTemplate(this.TEMPLATE.REGISTRATION_VERIFY_WRONG_DATA);
        } else {
          this.dialogService.openAlertErrorApi({errResp});
        }
      });
  }

  wrongData() {
    const data: RegistrationRequestData = {
      first_name: this.form.value.firstName,
      last_name: this.form.value.lastName,
      email: this.form.value.email,
      psn: this.form.value.psn,
    };

    this.wrongDataRequest(data)
      .subscribe(() => {
        this.changeTemplate(this.TEMPLATE.REGISTRATION_WRONG_DATA_SUCCESS);
      });
  }

  registrationRequest(data: RegistrationRequestData) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true
    };

    return this.apiService.post('registration', options);
  }

  registrationVerifyRequest(data: RegistrationRequestData) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true
    };

    return this.apiService.post('registration/verify', options);
  }

  wrongDataRequest(data: RegistrationRequestData) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true
    };

    return this.apiService.post('registration/wrong-data', options);
  }
}

interface RegistrationRequestData {
  email: string;
  first_name: string;
  last_name: string;
  psn: string;
}
