import { ChangeDetectionStrategy, Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

import { GlobalEvent } from "../../../../../core/interfaces/shared.interfaces";
import { AndroidService } from "../../../../../core/providers/android.service";
import { GlobalService } from "../../../../../core/providers/global.service";
import { ResetBoard, SetCurrentScene } from "../../../../../store/game/actions";
import { AppState } from "../../../../../store/state";
import { GAME_EVENTS } from "../../../constants";
import { GameFeaturesMap } from "../../../constants/game-features";
import { GameService } from "../../../services/game.service";
import { MyGame } from "../../classes/core/MyGame";
import { customGameConfig } from "../../config/custom-game.config";
import { MyGameConfig } from "../../interfaces/my-game-config";
import { CustomBootScene } from "../../scenes-main/custom/CustomBootScene";
import { CustomWorldBootScene } from "../../scenes-world/custom/CustomWorld.boot.scene";
import { prepareGameConfig } from "../../utils/game-config.utils";

@Component({
  selector: "app-game-engine-p3",
  templateUrl: "./game-engine-p3.component.html",

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameEngineP3Component implements OnInit, OnDestroy {
  public game: MyGame;
  private globalEvents: Subscription;
  private gameConfig: any;

  public constructor(
    private store: Store<AppState>,
    private gameService: GameService,
    private androidService: AndroidService,
    private ngZone: NgZone,
    public globalService: GlobalService
  ) {}

  ngOnInit() {
    const debugMode = !!localStorage.getItem("debug-mode");

    const baseGameConfig: MyGameConfig = {
      parent: "game",
      type: GameFeaturesMap.forceCanvas ? Phaser.CANVAS : Phaser.AUTO,
      scene: [CustomBootScene, CustomWorldBootScene],
      gameService: this.gameService,
      render: {
        roundPixels: true,
      },
      fps: {
        target: GameFeaturesMap.forceLowerFramerate ? 30 : 60,
        forceSetTimeOut: GameFeaturesMap.forceLowerFramerate,
      },
      loader: {
        // @ts-ignore
        imageLoadType: "HTMLImageElement",
        crossOrigin: "anonymous"
      },
      scale: {
        mode: Phaser.Scale.NONE,
        width: window.innerWidth * window.devicePixelRatio,
        height: window.innerHeight * window.devicePixelRatio,
        zoom: 1 / window.devicePixelRatio,
        fullscreenTarget: document.querySelector("body"),
      },
      images: {
        default: 'assets/phaser/default.png',
        missing: 'assets/phaser/missing.png',
        white: 'assets/phaser/white.png'
      },
      banner: false,
    };

    this.gameConfig = prepareGameConfig(baseGameConfig, customGameConfig);
    this.ngZone.runOutsideAngular(() => {
      this.game = this.gameService.initGame(this.gameConfig, debugMode);
    });
    this.globalEvents = this.gameService.globalService.globalEvents.subscribe(this.handleGlobalEvent.bind(this));
  }

  handleGlobalEvent(event: GlobalEvent) {
    switch (event.name) {
      case GAME_EVENTS.START_SCENE:
        this.gameService.guiService.isSplashShow.next(true);
        this.gameService.game.currentScene.destroyScene();
        this.gameService.store.dispatch(new ResetBoard());
        this.gameService.store.dispatch(new SetCurrentScene(null));

        if (!this.gameConfig.disablePrepareGameData) {
          this.gameService.prepareGameData(this.gameService.playerService.player.id, event.data ? event.data.playerIslandId : null);
        }
        setTimeout(() => {
          if (!event.data) {
            event.data = {
              islandGroupType: "main",
            };
          }
          this.gameService.game.currentScene.scene.start(event.value, event.data);
        }, 500);
        break;
    }
  }

  ngOnDestroy(): void {
    this.globalEvents.unsubscribe();
    this.gameService.game.currentScene.scene.stop();
    this.gameService.game.currentScene.destroyScene();
  }
}
