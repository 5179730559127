import * as R from "ramda";

import { SceneConfigMap } from "../interfaces/scene-config.interface";
import { BASIC_SCENE_BASE_CONFIG } from "./basic-scene.base-config";
import {
  CAMP_SCENE_CONFIG_KEY,
  CLIMBING1_SCENE_CONFIG_KEY,
  CLIMBING2_SCENE_CONFIG_KEY,
  CLIMBING3_SCENE_CONFIG_KEY,
  CLIMBING4_SCENE_CONFIG_KEY,
  CLIMBING5_SCENE_CONFIG_KEY,
  CLIMBING6_SCENE_CONFIG_KEY,
  SKILL_TREE_CONFIG_KEY,
  TEAM_SCENE_CONFIG_KEY,
  WORLD_SCENE_CONFIG_KEY,
} from "../../constants/custom/game.const";

export const BASIC_SCENE_CUSTOM_CONFIG: SceneConfigMap = {
  default: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    isOrhto: false,
    yAxisSign: 1,
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    tiledJsonPrefix: null,
    backgroundImages: [
      {
        filename: "map.jpg",
        x: 0,
        y: 0,
        z: -1,
        key: "map.jpg",
        displayWidth: 4000,
        displayHeight: 4000,
      },
    ],
    mapDirectoryName: "world",
    tiledMapFileName: null,
    islandGroupIdForBoard: "B",
    boardStartOffset: {
      x: 0,
      y: 0,
    },
    tileMenuAtlasDirectory: "tile-menu",
    cameraBounds: {
      x: 0,
      y: 0,
      width: 4000,
      height: 4000,
    },
    cameraStartPosition: {
      x: 1600,
      y: 1600,
    },
    other: {
      sceneZoom: {
        min: 1,
        max: 0.48,
      },
      isWorldScene: true,
      sceneSize: {
        width: 4000,
        height: 4000,
      },
    },
  },
  [CLIMBING1_SCENE_CONFIG_KEY]: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    tiledJsonPrefix: null,
    backgroundImages: [
      {
        filename: "climbing_1.png",
        x: 0,
        y: 0,
        key: "climbing_1",
        displayWidth: 1920,
        displayHeight: 1080,
        origin: {
          x: 0,
          y: 0,
        },
      },
    ],
    mapDirectoryName: "climbing",
    tiledMapFileName: null,
    islandGroupIdForBoard: "C",
    boardStartOffset: {
      x: 0,
      y: 0,
    },
    yAxisSign: 1,
    isOrhto: true,
    tileMenuAtlasDirectory: "tile-menu",
    cameraBounds: {
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
    },
    other: {
      sceneZoom: {
        min: 1.2,
        max: 0.48,
      },
      sceneSize: {
        width: 1920,
        height: 1080,
      },
      startZoom: 0.5,
      isClimbing: true,
      climbingVersion: 1
    },
    tileTooltipConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
      maxZoomScaleValue: 1.5,
    },
    tileMenuConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
      maxZoomScaleValue: 1.5,
    },
    tileHoverConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
      maxZoomScaleValue: 1.5,
    },
  },
  [CLIMBING2_SCENE_CONFIG_KEY]: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    tiledJsonPrefix: null,
    backgroundImages: [
      {
        filename: "climbing_1.png",
        x: 0,
        y: 0,
        key: "climbing_1",
        displayWidth: 1920,
        displayHeight: 1080,
        origin: {
          x: 0,
          y: 0,
        },
      },
    ],
    mapDirectoryName: "climbing",
    tiledMapFileName: null,
    islandGroupIdForBoard: "G",
    boardStartOffset: {
      x: 0,
      y: 0,
    },
    yAxisSign: 1,
    isOrhto: true,
    tileMenuAtlasDirectory: "tile-menu",
    cameraBounds: {
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
    },
    other: {
      sceneZoom: {
        min: 1.2,
        max: 0.48,
      },
      sceneSize: {
        width: 1920,
        height: 1080,
      },
      startZoom: 0.5,
      isClimbing: true,
      climbingVersion: 1
    },
    tileTooltipConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
      maxZoomScaleValue: 1.5,
    },
    tileMenuConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
      maxZoomScaleValue: 1.5,
    },
    tileHoverConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
      maxZoomScaleValue: 1.5,
    },
  },
  [CLIMBING3_SCENE_CONFIG_KEY]: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    tiledJsonPrefix: null,
    backgroundImages: [
      {
        filename: "climbing_1.png",
        x: 0,
        y: 0,
        key: "climbing_1",
        displayWidth: 1920,
        displayHeight: 1080,
        origin: {
          x: 0,
          y: 0,
        },
      },
    ],
    mapDirectoryName: "climbing",
    tiledMapFileName: null,
    islandGroupIdForBoard: "H",
    boardStartOffset: {
      x: 0,
      y: 0,
    },
    yAxisSign: 1,
    isOrhto: true,
    tileMenuAtlasDirectory: "tile-menu",
    cameraBounds: {
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
    },
    other: {
      sceneZoom: {
        min: 1.2,
        max: 0.48,
      },
      sceneSize: {
        width: 1920,
        height: 1080,
      },
      startZoom: 0.5,
      isClimbing: true,
      climbingVersion: 1
    },
    tileTooltipConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
      maxZoomScaleValue: 1.5,
    },
    tileMenuConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
      maxZoomScaleValue: 1.5,
    },
    tileHoverConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
      maxZoomScaleValue: 1.5,
    },
  },
  [CLIMBING4_SCENE_CONFIG_KEY]: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    tiledJsonPrefix: null,
    backgroundImages: [
      {
        filename: "climbing_2.png",
        x: 0,
        y: 0,
        key: "climbing_2",
        displayWidth: 1920,
        displayHeight: 1080,
        origin: {
          x: 0,
          y: 0,
        },
      },
    ],
    mapDirectoryName: "climbing",
    tiledMapFileName: null,
    islandGroupIdForBoard: "I",
    boardStartOffset: {
      x: 0,
      y: 0,
    },
    yAxisSign: 1,
    isOrhto: true,
    tileMenuAtlasDirectory: "tile-menu",
    cameraBounds: {
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
    },
    other: {
      sceneZoom: {
        min: 1.2,
        max: 0.48,
      },
      sceneSize: {
        width: 1920,
        height: 1080,
      },
      startZoom: 0.5,
      isClimbing: true,
      climbingVersion: 1
    },
    tileTooltipConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
      maxZoomScaleValue: 1.5,
    },
    tileMenuConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
      maxZoomScaleValue: 1.5,
    },
    tileHoverConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
      maxZoomScaleValue: 1.5,
    },
  },
  [CLIMBING5_SCENE_CONFIG_KEY]: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    tiledJsonPrefix: null,
    backgroundImages: [
      {
        filename: "climbing_2.png",
        x: 0,
        y: 0,
        key: "climbing_2",
        displayWidth: 1920,
        displayHeight: 1080,
        origin: {
          x: 0,
          y: 0,
        },
      },
    ],
    mapDirectoryName: "climbing",
    tiledMapFileName: null,
    islandGroupIdForBoard: "J",
    boardStartOffset: {
      x: 0,
      y: 0,
    },
    yAxisSign: 1,
    isOrhto: true,
    tileMenuAtlasDirectory: "tile-menu",
    cameraBounds: {
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
    },
    other: {
      sceneZoom: {
        min: 1.2,
        max: 0.48,
      },
      sceneSize: {
        width: 1920,
        height: 1080,
      },
      startZoom: 0.5,
      isClimbing: true,
      climbingVersion: 1
    },
    tileTooltipConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
      maxZoomScaleValue: 1.5,
    },
    tileMenuConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
      maxZoomScaleValue: 1.5,
    },
    tileHoverConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
      maxZoomScaleValue: 1.5,
    },
  },
  [CLIMBING6_SCENE_CONFIG_KEY]: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    tiledJsonPrefix: null,
    backgroundImages: [
      {
        filename: "climbing_2.png",
        x: 0,
        y: 0,
        key: "climbing_2",
        displayWidth: 1920,
        displayHeight: 1080,
        origin: {
          x: 0,
          y: 0,
        },
      },
    ],
    mapDirectoryName: "climbing",
    tiledMapFileName: null,
    islandGroupIdForBoard: "K",
    boardStartOffset: {
      x: 0,
      y: 0,
    },
    yAxisSign: 1,
    isOrhto: true,
    tileMenuAtlasDirectory: "tile-menu",
    cameraBounds: {
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
    },
    other: {
      sceneZoom: {
        min: 1.2,
        max: 0.48,
      },
      sceneSize: {
        width: 1920,
        height: 1080,
      },
      startZoom: 0.5,
      isClimbing: true,
      climbingVersion: 1
    },
    tileTooltipConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
      maxZoomScaleValue: 1.5,
    },
    tileMenuConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
      maxZoomScaleValue: 1.5,
    },
    tileHoverConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
      maxZoomScaleValue: 1.5,
    },
  },
  [CAMP_SCENE_CONFIG_KEY]: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    tiledJsonPrefix: null,
    backgroundImages: [
      {
        filename: "camp-0-0.jpg",
        x: 0,
        y: 0,
        key: "camp-0-0",
        displayWidth: 4096,
        displayHeight: 3200,
        origin: {
          x: 0,
          y: 0,
        },
      },
      {
        filename: "camp-1-0.jpg",
        x: 4096,
        y: 0,
        key: "camp-1-0",
        displayWidth: 1454,
        displayHeight: 3200,
        origin: {
          x: 0,
          y: 0,
        },
      }
    ],
    mapDirectoryName: "camp",
    tiledMapFileName: null,
    islandGroupIdForBoard: "A",
    boardStartOffset: {
      x: 0,
      y: 0,
    },
    yAxisSign: 1,
    tileMenuAtlasDirectory: "tile-menu",
    cameraBounds: {
      x: 0,
      y: 0,
      width: 5550,
      height: 3200,
    },
    cameraStartPosition: {
      x: 2300,
      y: 1600,
    },
    other: {
      sceneZoom: {
        min: 1.3,
        max: 0.45,
      },
      sceneSize: {
        width: 5550,
        height: 3200,
      },
      startZoom: 0.45,
      isCamp: true
    },
    tileTooltipConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
      maxZoomScaleValue: 1.5,
    },
    tileMenuConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
      maxZoomScaleValue: 1.5,
    },
    tileHoverConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
      maxZoomScaleValue: 1.5,
    },
  },
  [WORLD_SCENE_CONFIG_KEY]: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    isOrhto: false,
    yAxisSign: 1,
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    tiledJsonPrefix: null,
    backgroundImages: [
      {
        filename: "map.jpg",
        x: 0,
        y: 0,
        z: -1,
        key: "map.jpg",
        displayWidth: 4000,
        displayHeight: 4000,
      },
    ],
    mapDirectoryName: "world",
    tiledMapFileName: null,
    islandGroupIdForBoard: "B",
    boardStartOffset: {
      x: 0,
      y: 0,
    },
    tileMenuAtlasDirectory: "tile-menu",
    cameraBounds: {
      x: 0,
      y: 0,
      width: 4000,
      height: 4000,
    },
    cameraStartPosition: {
      x: 2000,
      y: 2000,
    },
    other: {
      sceneZoom: {
        min: 1.1,
        max: 0.45,
      },
      isWorldScene: true,
      sceneSize: {
        width: 4000,
        height: 4000,
      },
    },
  },
  [TEAM_SCENE_CONFIG_KEY]: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    isOrhto: true,
    tiledJsonPrefix: null,
    backgroundImages: [
      {
        filename: "team.png",
        x: 0,
        y: 0,
        key: "team",
        displayWidth: 1920,
        displayHeight: 1080,
        origin: {
          x: 0,
          y: 0,
        },
      },
      {
        filename: "start.png",
        key: "start",
        displayWidth: 1100,
        displayHeight: 600,
        x: 960, // half of FHD res
        y: 540,
        origin: {
          x: 0.5,
          y: 0.5,
        }
      }
    ],
    mapDirectoryName: "team",
    tiledMapFileName: null,
    islandGroupIdForBoard: "F",
    boardStartOffset: {
      x: 280, // half of FHD res
      y: 160,
    },
    yAxisSign: 1,
    tileMenuAtlasDirectory: "tile-menu",
    cameraBounds: {
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
    },
    other: {
      sceneZoom: {
        min: 1.2,
        max: 0.48,
      },
      sceneSize: {
        width: 1920,
        height: 1080,
      },
      startZoom: 0.5,
      isClimbing: true,
      climbingVersion: 1
    },
    tileTooltipConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
      maxZoomScaleValue: 1.5,
    },
    tileMenuConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
      maxZoomScaleValue: 1.5,
    },
    tileHoverConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
      maxZoomScaleValue: 1.5,
    },
  },
  [SKILL_TREE_CONFIG_KEY]: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    isOrhto: true,
    tiledJsonPrefix: null,
    backgroundImages: [
      {
        filename: "team.png",
        x: 0,
        y: 0,
        key: "team",
        displayWidth: 1920,
        displayHeight: 1080,
        origin: {
          x: 0,
          y: 0,
        },
      },
      {
        filename: "skills.png",
        key: "skills.png",
        displayWidth: 1100,
        displayHeight: 600,
        x: 960, // half of FHD res
        y: 540,
        origin: {
          x: 0.5,
          y: 0.5,
        }
      }
    ],
    mapDirectoryName: "team",
    tiledMapFileName: null,
    islandGroupIdForBoard: "E",
    boardStartOffset: {
      x: 280, // half of FHD res
      y: 160,
    },
    yAxisSign: 1,
    tileMenuAtlasDirectory: "tile-menu",
    cameraBounds: {
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
    },
    other: {
      sceneZoom: {
        min: 1.2,
        max: 0.48,
      },
      sceneSize: {
        width: 1920,
        height: 1080,
      },
      startZoom: 0.5,
      isClimbing: true,
      climbingVersion: 1
    },
    tileTooltipConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
      maxZoomScaleValue: 1.5,
    },
    tileMenuConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
      maxZoomScaleValue: 1.5,
    },
    tileHoverConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
      maxZoomScaleValue: 1.5,
    },
  },
};
