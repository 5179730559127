import { Component, OnInit } from "@angular/core";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { AssetsService } from "../../../../../../../../core/providers/assets.service";
import { AbstractMessageDetailsLevelUpComponent } from "../../../../abstract/core/abstract-message-details-level-up.component";
import { swiperInjectionStyles } from "../../../../../../../../../styles/swiper/swiper-injection-styles";

@Component({
  selector: "app-message-details-level-up",
  templateUrl: "./message-details-level-up.component.html",
})
export class MessageDetailsLevelUpComponent extends AbstractMessageDetailsLevelUpComponent implements OnInit {
  @OwInject(AssetsService) assetsService: AssetsService;

  sliderActiveIndex: number;
  unlockedBuildings = {
    config: {
      itemPerPage: 6,
    },
    pages: [],
    items: [],
  };

  ngOnInit() {
    this.getUnlocks();
    this.sliderUnlockedBuildingsActiveIndex = 0;
    this.sliderUnlockedProductsActiveIndex = 0;
    this.sliderActiveIndex = 0;
    this.afterInit();
  }
}
