import { ParameterDefinition } from "../../../../interfaces/parameters";

export interface ParameterInOut extends ParameterDefinition {
  income?: number;
  outcome?: number;
}

export function upgradeDifferenceProduction(arrayProductionNormal = [], arrayProductionUpgrade = []) {
  const arrayNormal = removeDuplicateMax(arrayProductionNormal);
  const arrayUpgrade = removeDuplicateMax(arrayProductionUpgrade);

  return generateUpgradePairProduction(arrayNormal, arrayUpgrade);
}

export function removeDuplicateMax(array) {
  return array
    .sort((a, b) => {
      return convertItem(a).amount > convertItem(b).amount ? -1 : 1;
    })
    .filter((thing, index, self) => {
      return index === self.findIndex(t => convertItem(t).id === convertItem(thing).id && checkTheSameType(t, thing));
    })
    .map(item => item);
}

function generateUpgradePairProduction(arrayNormal, arrayUpgrade) {
  const pair = [];

  arrayNormal.forEach(itemA => {
    const pairObject = { normal: itemA, upgrade: null, parameters: null };
    const findObject = arrayUpgrade.find(itemB => convertItem(itemB).id === convertItem(itemA).id && checkTheSameType(itemA, itemB));

    if (findObject) {
      const quantity_diff = convertItem(findObject).amount - convertItem(itemA).amount;

      pairObject.upgrade = findObject;
      pairObject.parameters = <any>{ quantity_diff };

      const index = arrayUpgrade.indexOf(findObject);
      if (index > -1) {
        arrayUpgrade.splice(index, 1);
      }
    }

    pair.push(pairObject);
  });

  arrayUpgrade.forEach(itemB => {
    const pairObject = { normal: null, upgrade: itemB, parameters: { new: true } };
    pair.push(pairObject);
  });

  return pair;
}

function convertItem(item) {
  if (item.product_prizes.length > 0) {
    return {
      id: item.product_prizes[0].product_id,
      amount: item.product_prizes[0].amount,
    };
  }

  if (item.currency_prizes.length > 0) {
    return {
      id: item.currency_prizes[0].currency_id,
      amount: item.currency_prizes[0].amount,
    };
  }
}

function checkTheSameType(a, b) {
  if (a.product_prizes.length > 0 && b.product_prizes.length > 0) {
    return true;
  }

  if (a.currency_prizes.length > 0 && b.currency_prizes.length > 0) {
    return true;
  }

  return false;
}

// core override - SN
export function upgradeDifferenceParameters(arrayParametersNormal = [], arrayParametersUpgrade = [], isIncome?: boolean) {
  return generateUpgradePairParameters(arrayParametersNormal, arrayParametersUpgrade, isIncome);
}

function generateUpgradePairParameters(arrayNormal, arrayUpgrade, isIncome?: boolean) {
  const pair = [];

  arrayNormal.forEach((itemA: ParameterInOut) => {
    const pairObject = { normal: itemA, upgrade: null, parameters: null };
    const findObject = arrayUpgrade.find((itemB: ParameterInOut) => itemB.parameter_id === itemA.parameter_id);

    if (findObject) {
      const income_diff = findObject.income - itemA.income;
      const outcome_diff = findObject.outcome - itemA.outcome;

      pairObject.upgrade = findObject;
      pairObject.parameters = <any>{ income_diff, outcome_diff };

      const index = arrayUpgrade.indexOf(findObject);
      if (index > -1) {
        arrayUpgrade.splice(index, 1);
      }
    }

    pair.push(pairObject);
  });

  arrayUpgrade.forEach(itemB => {
    const pairObject = { normal: null, upgrade: itemB, parameters: { new: true } };
    if (isIncome && itemB.income !== 0 && itemB.income !== null) {
      pair.push(pairObject);
    } else if (!isIncome) {
      pair.push(pairObject);
    }
  });

  return pair;
}
// core override end
