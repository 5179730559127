<div class="global-dialog ow-dialog c-window-long quality-task-container">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Blok jakości</span>
      </div>
    </div>

    <div class="source-data-date-tab" *ngIf="qualityTasks?.source_data_date">
      Dane aktualne na: {{ qualityTasks.source_data_date | date:'dd-MM-y' }}
    </div>
  </div>

  <ng-container *ngIf="activeTabLeft && qualityTasks">
    <!-- FILTERS -->
    <div class="filters">
      <ng-container *ngFor="let tab of tabsLeft">
        <div class="ow-radio-container" *ngIf="tab.visible">
          <label class="choose-wrapper">
            <span class="checkbox-wrapper">
              <input
                type="radio"
                name="ranksFilter"
                (change)="changeFilter({tab: tab, clearOwDate: true})"
                [checked]="activeTabLeft.data == tab.data"
              >
              <span class="checkmark"></span>
            </span>
            <span class="text-wrapper">{{ tab.label }}</span>
          </label>
        </div>
      </ng-container>
    </div>

    <!-- NAVIGATE CONTAINER -->
    <div class="navigate-container loading-opacity">
      <!-- NAVIGATION MONTH -->
      <div class="pagination" *ngIf="activeTabLeft.pagination === PAGINATION_TYPES.MONTH">
        <!-- PREV -->
        <button
          (click)="prev()"
          [disabled]="!owPaginationDate.allowPrev.month"
        >
          <i class="far fa-chevron-square-left nav-arrow"></i>
        </button>

        <!-- MONTH -->
        <div class="value" *ngIf="qualityTasks">
          <div class="month">{{ owDate.month_text_normal }}</div>
        </div>

        <!-- NEXT -->
        <div
          [matTooltip]="'Dane dostępne wkrótce.'"
          [matTooltipPosition]="'above'"
          [matTooltipDisabled]="owPaginationDate.allowNext.month"
        >
          <button
            (click)="next()"
            [disabled]="!owPaginationDate.allowNext.month"
          >
            <i class="far fa-chevron-square-right nav-arrow"></i>
          </button>
        </div>
      </div>

      <!-- NAVIGATION QUARTER -->
      <div class="pagination" *ngIf="qualityTasks && activeTabLeft.pagination === PAGINATION_TYPES.QUARTER">
        <!-- PREV -->
        <button
          (click)="prev()"
          [disabled]="!owPaginationDate.allowPrev.quarter"
        >
          <i class="far fa-chevron-square-left nav-arrow"></i>
        </button>

        <!-- CURRENT -->
        <div class="value quarter" *ngIf="qualityTasks">
          {{ owPaginationDate.current.quarter_text_normal }}
        </div>

        <!-- NEXT -->
        <button
          (click)="next()"
          [disabled]="!owPaginationDate.allowNext.quarter"
        >
          <i class="far fa-chevron-square-right nav-arrow"></i>
        </button>
      </div>
    </div>

    <div class="frame loading-opacity" *ngIf="qualityTasks">
      <ng-container *ngIf="qualityTasks.tasks.length; else noItemsTpl">
        <table>
          <thead>
          <tr>
            <th>Nazwa zadania</th>
            <th></th>
            <th *ngIf="activeTabLeft.data === 'monthly'">Poziom oczekiwany</th>
            <th *ngIf="activeTabLeft.data === 'quarterly'">Poziom min/max</th>
            <th>Wykonanie</th>
            <th *ngIf="activeTabLeft.data === 'monthly'">Mnożnik złotych talarów</th>
            <th *ngIf="activeTabLeft.data === 'monthly'">Mnożnik banknotów</th>
            <th>Wpływ na wynik ligowy</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let task of qualityTasks.tasks">
            <td>{{ task.name }}</td>
            <td></td>
            <td>{{ task.target_value }}</td>
            <td>{{ activeTabLeft.data === 'monthly' ? task.result_value : task.performance_value }}</td>
            <td *ngIf="activeTabLeft.data === 'monthly'">{{ task.money_percent | number:'1.2-2' }}%</td>
            <td *ngIf="activeTabLeft.data === 'monthly'">{{ task.material_percent | number:'1.2-2' }}%</td>
            <td>{{ task.league_percent | number:'1.2-2' }}%</td>
            <td>
              <button (click)="openQualityTaskInfo(task.task_scopes)">
                <i class="fas fa-info"></i>
              </button>
            </td>
          </tr>
          <tr class="row-sums" *ngIf="activeTabLeft.data === 'monthly'">
            <td></td>
            <td colspan="3">Razem wskaźnik jakości (mnożnik)</td>
            <td>{{ qualityTasks.money_percent | number:'1.2-2' }}%</td>
            <td>{{ qualityTasks.material_percent | number:'1.2-2' }}%</td>
            <td></td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-template #noItemsTpl>
        <p class="flex-center no-data"><b>Brak danych</b></p>
      </ng-template>
    </div>
  </ng-container>
  <div *ngIf="branchName" class="branch-name">{{ branchName }}</div>
</div>
