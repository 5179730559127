import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractLoginComponent } from '../../../../abstract/abstract-login.component';
import { getToken } from '../../../../../../core/utility/token';
import { unsubscribeObject } from '../../../../../../core/utility/unsubscribe-array';
import { Credentials } from '../../../../interfaces/credentials';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-custom-login',
  templateUrl: './login.component.html',
})
export class LoginComponent extends AbstractLoginComponent implements OnInit, OnDestroy {
  form: FormGroup = this.fb.group({
    san_log: this.fb.control(null, [Validators.required]),
    san_pass: this.fb.control(null, [Validators.required]),
    remember_me: this.fb.control(null),
  });

  ngOnInit() {
    if (getToken().token) {
      this.router.navigate(['/auth/player-choose']);
    } else {
      this.subscribeAuthSuccess();
      this.subscribeUserSuccess();
    }
  }

  prepareData(): Credentials {
    return {
      username: this.form.value.san_log,
      password: this.form.value.san_pass,
      remember_me: this.form.value.remember_me,
    };
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }

  login() {
    super.login();
    window['isAuthSuccessed'] = true;
  }
}
