import { BUILDING_GROUP_TYPE_META_PRODUCTION } from "../../constants";
import {
  BUILDING_GROUP_TYPE_ANIMAL_TRACKING, BUILDING_GROUP_TYPE_ANTYMINER
} from '../../constants/game-custom.constants';
import { TileMenuButton } from "../../game-engine/classes/tile-menu/custom/TileMenuButton";
import { PlayerBuilding } from "../../game-engine/interfaces/player-building.config";

export function checkActiveFnByGroupName(additionalDataKey: string, buildingData: PlayerBuilding) {
  return buildingData.group === additionalDataKey;
}

export function checkHireButtonActive(tileMenuBtn: TileMenuButton) {
  return (
    tileMenuBtn.building.group_type === BUILDING_GROUP_TYPE_META_PRODUCTION &&
    tileMenuBtn.tileMenu.targetTile.tileData.open_player_island_id
  );
}

export function isAnimalTracking(buildingGroup: string) {
  return buildingGroup === BUILDING_GROUP_TYPE_ANIMAL_TRACKING;
}

export function isAntyminer(buildingGroup: string) {
  return buildingGroup === BUILDING_GROUP_TYPE_ANTYMINER;
}
