import { Component, Input, OnInit } from '@angular/core';
import { STOCK_VIEW } from '../../../../../shared-ui/mobile/consts/stock-view.const';

@Component({
  selector: 'app-building-upgrade-diff-storage-value',
  templateUrl: './building-upgrade-diff-storage-value.component.html',
})
export class BuildingUpgradeDiffStorageValueComponent implements OnInit {
  @Input() storageValue;
  @Input() upgradeStorageValue;
  STOCK_VIEW = STOCK_VIEW;
  diffStorageValue;

  ngOnInit() {
    this.setDiffStorageValue();
  }

  setDiffStorageValue() {
    this.diffStorageValue = {
      normal: this.storageValue || 0,
      upgrade: this.upgradeStorageValue || 0,
      parameters: {},
    };

    this.diffStorageValue.parameters = {
      diff: this.diffStorageValue.upgrade - this.diffStorageValue.normal,
    };
  }
}
