import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';

import { PlayerService } from '../../../player/providers/player.service';
import { DialogService } from '../../providers/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class ShopGuard implements CanActivate, CanLoad {
  constructor(
    private playerService: PlayerService,
    private dialogService: DialogService
  ) {}

  check() {
    if (this.playerService.player.gui_unlocks['market']) {
      return true;
    } else {
      this.dialogService.openAlert({
        description: 'San Tao Market (dostępne wkrótce)',
      });
      return false;
    }
  }

  canActivate(): boolean {
    return this.check();
  }

  canLoad(): boolean {
    return this.check();
  }
}
