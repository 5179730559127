import { Component, Input, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { DialogService } from '../../../../../../shared/providers/dialog.service';
import { ApiBusinessService } from '../../../../san-business/api/services/api-business.service';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../../../../../core/providers/global.service';
import { PlayerService } from '../../../../../../player/providers/player.service';
import { OwDate } from '../../../../../../../core/utility/ow-date.class';
import { ChooseTaskCCComponent } from '../../dialogs/choose-task-cc/choose-task-cc.component';
import { Task } from '../../../../san-business/interfaces/custom/task.interface';
import { DashboardCCComponent } from '../../dialogs/dashboard-cc/dashboard-cc.component';

@Component({
  selector: 'app-card-button-choose-cc',
  templateUrl: './card-button-choose-cc.component.html',
})
export class CardButtonChooseCCComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<CardButtonChooseCCComponent>;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(GlobalService) globalService: GlobalService;
  @Input() currentIndex: number;
  @Input() task?: Task;
  @Input() allTasks?: Task[];
  @Input() owDate: OwDate;
  @Input() position?: number;

  allowChange: boolean;
  actualOwDate = new OwDate();

  ngOnInit() {
    this.allowChange =
      this.owDate.year === this.actualOwDate.year &&
      this.owDate.month === this.actualOwDate.month &&
      this.playerService.checkIsActiveMe();
  }

  openChooseTask() {
    let chooseTasks: Task[];

    chooseTasks = this.allTasks.filter((task) => {
      return task.is_choosable && !task.position && task.showChoosable;
    });

    this.dialogService.open(ChooseTaskCCComponent, {
      data: {
        tasks: chooseTasks,
        allTasks: this.allTasks,
        currentIndex: this.currentIndex,
        owDate: this.owDate,
        position: this.position,
      }
    });
  }

  changeTask() {
    if (this.owDate.day <= this.task.choose_task_max_day) {
      if (this.owDate.day <= this.task.last_day_task_full_reward) {
        this.changeTaskApi();
      } else {
        this.dialogService.openConfirm(
          {
            title: 'Uwaga',
            description: 'Zmieniając zadanie dostaniesz tylko połowę nagrody.',
          }, (confirm) => {
            if (confirm) {
              this.changeTaskApi();
            }
          }
        );
      }
    } else {
      this.dialogService.openAlert({
        title: 'Ups...',
        description: `Po ${this.task.choose_task_max_day} dniu miesiąca nie możesz już zmienić zadania.`
      });
    }
  }

  changeTaskApi() {
    this.apiBusinessService.chooseTask({
      id: this.task.id,
      position: this.position,
    })
      .subscribe(() => {
        this.dialogService.closeAll();

        setTimeout(() => {
          this.dialogService.open(DashboardCCComponent);
        });
      });
  }

  close() {
    this.matDialogRef.close();
  }
}
