<div class="ow-dialog c-window-short media-container">
  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button *ngIf="data.isShowBackButton"></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>{{ data.title || 'Biblioteka' }}</span>
      </div>
    </div>
  </div>

  <loading></loading>

  <div class="main-container loading-opacity">
    <div>
      <div class="type-select" *ngIf="filters.length >= 2">
        <ng-select
          [items]="filters"
          bindLabel="name"
          bindValue="id"
          [clearable]="false"
          [searchable]="false"
          [(ngModel)]="currentFilterId"
          (change)="changeFilter()"
        ></ng-select>
      </div>

      <div class="media-list">
        <div
          class="media-row"
          *ngFor="let item of items"
        >
          <div class="left">
            <div class="icon" [style.background-image]="'url(' + item.thumb + ')'">
            </div>
          </div>
          <div class="right">
            <div class="heading">
              <div class="title">
                <a href="{{ item.url }}" target="_blank">
                  {{ item.name }}
                </a>
              </div>
              <div class="description">
                {{ item.description }}
              </div>
            </div>
            <div class="terms">
              {{ item.category.name }}<br />
              {{ item.created_at | date:'y-MM-dd' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="maxPages > 1"
      class="pagination"
    >
      <!-- PREV -->
      <button
        (click)="previousPage()"
        [disabled]="currentPage==1"
      >
        <i class="far fa-chevron-square-left nav-arrow"></i>
      </button>

      <!-- VALUE -->
      <div class="value">
        {{ currentPage }} z {{ maxPages }}
      </div>

      <!-- NEXT -->
      <button
        (click)="nextPage()"
        [disabled]="currentPage >= maxPages"
      >
        <i class="far fa-chevron-square-right nav-arrow"></i>
      </button>
    </div>
  </div>
</div>
