import { Component, Input } from '@angular/core';
import { STOCK_VIEW } from '../../../../../shared-ui/mobile/consts/stock-view.const';

@Component({
  selector: 'app-unlocked-storage-value',
  templateUrl: './unlocked-storage-value.component.html',
})
export class UnlockedStorageValueComponent {
  @Input() storageValue;
  STOCK_VIEW = STOCK_VIEW;
}
