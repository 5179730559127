export function removeDuplicateMax(productions) {
  return productions
    .sort((a, b) => {
      return a.product_prizes[0].amount > b.product_prizes[0].amount ? -1 : 0;
    })
    .filter((thing, index, self) => {
      return index === self.findIndex(t => t.product_prizes[0].product_id === thing.product_prizes[0].product_id);
    })
    .map(item => item);
}
