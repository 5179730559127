import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";

import { RRConfigEditorComponent } from "./rrconfig-editor/rrconfig-editor.component";

@NgModule({
  declarations: [RRConfigEditorComponent],
  exports: [RRConfigEditorComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatSliderModule,
    MatSelectModule,
    DragDropModule,
    MatCardModule,
    MatInputModule,
  ],
})
export class RRConfigEditorModule {}
