import { Component } from "@angular/core";
import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApiBusinessCalendarService } from "../../../../api/services/api-business-calendar.service";

@Component({
  selector: "app-business-calendar-close",
  templateUrl: "./business-calendar-close.component.html",
  styleUrls: ["./business-calendar-close.component.scss"],
})
export class BusinessCalendarCloseComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<BusinessCalendarCloseComponent>;
  @OwInject(ApiBusinessCalendarService) apiBusinessCalendarService: ApiBusinessCalendarService;
  @OwInject(MAT_DIALOG_DATA) data;

  close(confirm?: boolean) {
    this.matDialogRef.close(confirm);
  }

  closeCalendar() {
    this.apiBusinessCalendarService.businessCalendarEventsSeenAll(this.data.managerPlayerId).subscribe();
    this.data.ref.close();
    this.matDialogRef.close();
  }
}
