import { Pipe, PipeTransform } from '@angular/core';
import { LeagueGroupObject } from '../../interfaces/custom/league-group-object.interface';
import { BusinessLeagueGroup } from '../helpers/business-league-group.helper';

@Pipe({
  name: 'businessLeagueGroup'
})
export class BusinessLeagueGroupPipe implements PipeTransform {
  transform(id): LeagueGroupObject {
    return new BusinessLeagueGroup().getLeagueGroup(id);
  }
}
