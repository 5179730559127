import { cbConfig } from "./catch-bananas.config";
import { LS_CONFIG_KEY } from "./catch-bananas.constans";

export function provideSceneConfig() {
  const savedLocalConfig = JSON.parse(localStorage.getItem(LS_CONFIG_KEY));
  return savedLocalConfig ? savedLocalConfig : cbConfig;
}

export function generateAnimationFramesList(key: string, baseFrameName: string, framesCount: number) {
  return new Array(framesCount).fill(1).map((el, i) => ({ key, frame: `${baseFrameName}-${(i + 1).toString().padStart(2, "0")}.png` }));
}
