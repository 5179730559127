import { MinigameCatchBananasGuiComponent } from "../catch-bananas/components/minigame-catch-bananas-gui/minigame-catch-bananas-gui.component";
import { GameModes } from "../enums/game-modes.enum";
import { GameConfigInterface } from "../interfaces/game-config.interface";

export const CatchBananaConfig: GameConfigInterface = {
  visible: true,
  game: 3,
  order: 4,
  payment: GameModes.PREMIUM,
  key: "catchbananas",
  icon: "logo.png",
  iconPath: "ui/catch_bananas",
  component: MinigameCatchBananasGuiComponent,
  rules: `
         <div class="text-left"><div class="text-center text-bold">Zasady gry Złap Banana</div>
           <ol>
             <li>Użyj kostki do gry, aby rozpocząć grę Złap Banana.</li>
             <li>W trakcie rozgrywki złap jak najwięcej dobrych bananów. Każdy złapany banan to 3 punkty.</li>
             <li>Unikaj zgniłych bananów, za każdego zgniłka, który trafi do koszyka Ani otrzymasz -5 punktów.</li>
             <li>Czas rozgrywki to 120 sekund. Zbieraj zegarki, aby wydłużyć czas rozgrywki. Każdy złapany zegarek to 5 dodatkowych sekund.</li>
             <li>Łap skrzynie, aby otrzymać premię w postaci 50 punktów za każdą skrzynię. W trakcie rozgrywki dostępne są tylko 3 skrzynie.</li>
             <li>Steruj Anią za pomocą strzałek (prawo/lewo).</li>
             <li>Używaj spacji, aby włączyć pauzę. Grę możesz wstrzymać tylko raz w trakcie rozgrywki.</li>
             <li>W nagrodę za grę otrzymasz żetony z salonu gier, w ilości zależnej od liczby zdobytych punktów. Żetony możesz wymienić na nagrody w sklepie „Mistrza gier”.</li>
           </ol>
         </div>
      `,
  costs: {
    products: [{ product_id: 24, amount: 1 }], // [{ product_id: 601, amount: 1 }],
    canPlay: null,
  },
  stats: null,
  ranks: null,
  disabledCC: true,
};
