import { Component, Input } from '@angular/core';
import { SchemaFilterTitleDetails } from '../../../../../interfaces/custom/rank-visibility-schema.interface';
import { AbstractInjectBaseComponent } from '../../../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../../../core/decorators/ow-inject.decorator';
import { PlayerService } from '../../../../../../../../player/providers/player.service';
import { GAME_CONFIG } from '../../../../../../../../../core/config/custom/_parsed-game.config';

@Component({
  selector: 'rank-buttons',
  templateUrl: './rank-buttons.component.html',
})
export class RankButtonsComponent extends AbstractInjectBaseComponent {
  @OwInject(PlayerService) playerService: PlayerService;

  @Input() perfectScrollbarRef;
  @Input() schema: SchemaFilterTitleDetails;
  @Input() inputValue: string;

  isCC = GAME_CONFIG.IS_CC;
}
