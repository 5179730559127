import { CUSTOM_MISSION_STAR_SUB_GROUPS } from "../consts/custom/mission-star-sub-groups.const";


export function filterStarMissionByTypeAndSubGroupingHelper(groupMissions) {
  const missionsWithStars = missionWithStars(groupMissions);

  missionsWithStars.forEach(m => {
    if (m.icon.includes("ania")) {
      m.sub_group_id = CUSTOM_MISSION_STAR_SUB_GROUPS.ANIA;
      // m.endMissionTxt = "Wszystkie zadania od Ani zostały zrealizowane!";
    } else if (m.icon.includes("janek")) {
      m.sub_group_id = CUSTOM_MISSION_STAR_SUB_GROUPS.JANEK;
      // m.endMissionTxt = "Wszystkie zadania od Janka zostały zrealizowane!";
    } else if (m.icon.includes("profesor")) {
      m.sub_group_id = CUSTOM_MISSION_STAR_SUB_GROUPS.PROFESSOR;
      // m.endMissionTxt = "Wszystkie zadania od Profesora zostały zrealizowane!";
    }
  });

  return missionsWithStars;
}

export function missionWithStars(groupMissions) {
  return groupMissions.filter(m => m.reward_product_id >= 9978 && m.reward_product_id <= 9998);
}

export function missionWithoutStars(groupMissions) {
  return groupMissions.filter(m => m.reward_product_id >= 9957 && m.reward_product_id <= 9977);
}

export function checkMissionWithoutStars(groupMission) {
  return groupMission.reward_product_id >= 9957 && groupMission.reward_product_id <= 9977;
}
