import { Component, OnChanges, OnInit } from "@angular/core";

import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { AssetsService } from "../../../../../../../core/providers/assets.service";
import { PlayerChooseComponent } from '../../../../../../auth/base/custom/components/player-choose/player-choose.component';
import { UserService } from "../../../../../../user/providers/user.service";
import { AbstractHudPlayerComponent } from "../../../abstract/core/abstract-hud-player.component";

@Component({
  selector: "app-san-hud-player",
  templateUrl: "./hud-player.component.html",
})
export class HudPlayerComponent extends AbstractHudPlayerComponent implements OnInit, OnChanges {
  @OwInject(UserService) userService: UserService;
  @OwInject(AssetsService) assetsService: AssetsService;

  valuePerOnePercent = 5.28;
  hasMissionStarToCollect: boolean;
  button = {
    type: "button",
    place: "menu-top",
    order: 1,
    name: "player_choose",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "player_choose",
    tooltip: "Zmień gracza",
    icon: "change.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  };
  showBtn: boolean;

  ngOnInit() {
    this.setHasMissionStarToCollect();
    this.calcPlayerLevelProgress();

    this.showBtn = this.userService.me.enabled_players.length > 1;
  }

  openPlayerChoose() {
    this.dialogService.open(PlayerChooseComponent, {
      data: {
        isOpenFromHudMenu: true,
      },
      disableClose: true,
    });
  }

  calcPlayerLevelProgress() {
    let strokeDashOffset = 0;
    const levelPercent = this.player.level_percent;
    if (Number.isInteger(levelPercent)) {
      strokeDashOffset = 528 - levelPercent * this.valuePerOnePercent;
    }
    this.strokeDashOffset = strokeDashOffset;
  }

  setHasMissionStarToCollect() {
    this.hasMissionStarToCollect = this.player.missions_to_collect.some(
      mission => mission.mission_type === 1 && mission.mission_slot_id === 1
    );
  }

  ngOnChanges() {
    this.setHasMissionStarToCollect();
    this.calcPlayerLevelProgress();
  }
}
