import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatSliderModule } from "@angular/material/slider";
import { OwPerfectScrollbarModule } from "@oskarwegner/ow-perfect-scrollbar";

import { OwSliderComponent } from "../../../../../shared/components/ow-slider/ow-slider.component";
import { SharedModule } from "../../../../../shared/shared.module";
import { CardButtonChooseCCComponent } from "../../../san-business-cc/custom/components/card-button-choose-cc/card-button-choose-cc.component";
import { SanBusinessCCComponent } from "../../../san-business-cc/custom/components/san-business-cc/san-business-cc.component";
import { CardTaskDetailsCCComponent } from "../../../san-business-cc/custom/dialogs/card-task-details-cc/card-task-details-cc.component";
import { ChooseTaskCCComponent } from "../../../san-business-cc/custom/dialogs/choose-task-cc/choose-task-cc.component";
import { DashboardCCComponent } from "../../../san-business-cc/custom/dialogs/dashboard-cc/dashboard-cc.component";
import { QualityTasksCCComponent } from "../../../san-business-cc/custom/dialogs/quality-tasks-cc/quality-tasks-cc.component";
import { SanRanksModule } from "../../../san-ranks/module/custom/san-ranks/san-ranks.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { SanBusinessComponent } from "../../custom/components/san-business/san-business.component";
import { BasicActionsComponent } from "../../custom/dialogs/basic-actions/basic-actions.component";
import { BusinessCalendarComponent } from "../../custom/dialogs/business-calendar/business-calendar.component";
import { BusinessCalendarCloseComponent } from "../../custom/dialogs/business-calendar/close/business-calendar-close.component";
import { BusinessCalendarDetailsComponent } from "../../custom/dialogs/business-calendar/details/business-calendar-details.component";
import { BusinessChallengesComponent } from "../../custom/dialogs/business-challenges/business-challenges.component";
import { ConfirmAlertComponent } from "../../custom/dialogs/business-challenges/contract-sale/confirm-alert/confirm-alert.component";
import { ContractClockPrizesComponent } from "../../custom/dialogs/business-challenges/contract-sale/contract-clock-prizes/contract-clock-prizes.component";
import { ContractMaxPrizesComponent } from "../../custom/dialogs/business-challenges/contract-sale/contract-max-prizes/contract-max-prizes.component";
import { ContractSaleComponent } from "../../custom/dialogs/business-challenges/contract-sale/contract-sale.component";
import { PrizesAlertComponent } from "../../custom/dialogs/business-challenges/contract-sale/prizes-alert/prizes-alert.component";
import { MicroLeagueComponent } from "../../custom/dialogs/business-challenges/micro-league-tabs/micro-league/micro-league.component";
import { MicroLeagueDetailsComponent } from "../../custom/dialogs/business-challenges/micro-league-tabs/micro-league/micro-league-details/micro-league-details.component";
import { MicroLeagueAchievementDetailsComponent } from "../../custom/dialogs/business-challenges/micro-league-tabs/micro-league-achievements/micro-league-achievement-details/micro-league-achievement-details.component";
import { MicroLeagueAchievementsComponent } from "../../custom/dialogs/business-challenges/micro-league-tabs/micro-league-achievements/micro-league-achievements.component";
import { MicroLeagueMaxPrizesComponent } from "../../custom/dialogs/business-challenges/micro-league-tabs/micro-league-max-prizes/micro-league-max-prizes.component";
import { MicroLeagueTabsComponent } from "../../custom/dialogs/business-challenges/micro-league-tabs/micro-league-tabs.component";
import { BusinessPreviewBranchesComponent } from "../../custom/dialogs/business-preview-dashboard/business-preview-branches/business-preview-branches.component";
import { BusinessPreviewDashboardComponent } from "../../custom/dialogs/business-preview-dashboard/business-preview-dashboard.component";
import { CardTaskDetailsComponent } from "../../custom/dialogs/card-task-details/card-task-details.component";
import { CardTasksComponent } from "../../custom/dialogs/card-tasks/card-tasks.component";
import { DashboardComponent } from "../../custom/dialogs/dashboard/dashboard.component";
import { LeagueComponent } from "../../custom/dialogs/league/league.component";
import { LeagueAchievementDetailsComponent } from "../../custom/dialogs/league-achievements/league-achievement-details/league-achievement-details.component";
import { LeagueAchievementsComponent } from "../../custom/dialogs/league-achievements/league-achievements.component";
import { QualityTaskInfoComponent } from "../../custom/dialogs/quality-tasks/quality-task-info/quality-task-info.component";
import { QualityTasksComponent } from "../../custom/dialogs/quality-tasks/quality-tasks.component";
import { BusinessLeagueBonusTaskPipe } from "../../custom/pipes/business-league-bonus-task.pipe";
import { BusinessLeagueColorPipe } from "../../custom/pipes/business-league-color.pipe";
import { BusinessLeagueGroupPipe } from "../../custom/pipes/business-league-group.pipe";
import { DictionaryService } from "../../providers/custom/dictionary.service";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SharedUiMobileModule,
    MatSliderModule,
    FormsModule,
    OwSliderComponent,
    SanRanksModule,
    OwPerfectScrollbarModule,
  ],
  declarations: [
    SanBusinessComponent,
    DashboardComponent,
    BusinessPreviewDashboardComponent,
    BusinessPreviewBranchesComponent,
    BusinessCalendarComponent,
    BusinessCalendarDetailsComponent,
    BusinessCalendarCloseComponent,
    CardTasksComponent,
    CardTaskDetailsComponent,
    BasicActionsComponent,
    LeagueComponent,
    BusinessLeagueColorPipe,
    QualityTasksComponent,
    QualityTaskInfoComponent,
    BusinessLeagueGroupPipe,
    BusinessLeagueBonusTaskPipe,
    SanBusinessCCComponent,
    DashboardCCComponent,
    QualityTasksCCComponent,
    CardButtonChooseCCComponent,
    ChooseTaskCCComponent,
    CardTaskDetailsCCComponent,
    LeagueAchievementsComponent,
    LeagueAchievementDetailsComponent,
    ContractSaleComponent,
    MicroLeagueComponent,
    BusinessChallengesComponent,
    MicroLeagueAchievementDetailsComponent,
    ContractMaxPrizesComponent,
    ContractClockPrizesComponent,
    PrizesAlertComponent,
    ConfirmAlertComponent,
    MicroLeagueAchievementsComponent,
    MicroLeagueMaxPrizesComponent,
    MicroLeagueDetailsComponent,
    MicroLeagueTabsComponent,
  ],
  exports: [SanBusinessComponent, SanBusinessCCComponent],
  providers: [DictionaryService, BusinessLeagueColorPipe],
})
export class SanBusinessModule {}
