import { GameService } from "../../../game/services/game.service";
import { Match3Service } from "../match3.service";
import { M3GameOptions, Match3GameConfig } from "../models/match3.model";

export class Match3Game extends Phaser.Game {
  gameOptions: M3GameOptions;
  gameService: GameService;
  match3Service: Match3Service;

  constructor(config: Match3GameConfig) {
    super(config);
    this.gameOptions = config.gameOptions;
    this.gameService = config.gameService;
    this.match3Service = config.match3GameService;

    window.focus();
    // this.resize();
    // window.addEventListener('resize', this.resize.bind(this), false);
  }

  resize() {
    const canvas = document.querySelector("canvas");
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const windowRatio = windowWidth / windowHeight;
    const gameRatio = this.gameOptions.gemSize.width / this.gameOptions.gemSize.height;
    if (windowRatio < gameRatio) {
      canvas.style.width = windowWidth + "px";
      canvas.style.height = windowWidth / gameRatio + "px";
    } else {
      canvas.style.width = windowHeight * gameRatio + "px";
      canvas.style.height = windowHeight + "px";
    }
  }
}
