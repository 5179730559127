import { generateSegmentGameObjectConfig } from "./river-ride.helpers";
import { RiverRideGameplayConfig, RRRiverSegmentGameObjectDef } from "./river-ride.interfaces";

export const RR_ATLAS_KEY = "rr-atlas";
export const RR_PLAY_SCENE_KEY = "RiverRidePlayScene";

export enum GameObjectIds {
  PIRANHA = "piranha",
  WHIRLPOOL = "whirlpool",
  STONE_BIG = "stone_big",
  STONE_SMALL = "stone_small",
  LOG = "log",
  CROCK = "crock",
  CROCK2 = "crock2",
  CLOCK = "clock",
  CHEST = "chest",
  ARAPAIMA = "arapaima",
}

export const RR_GAME_OBJECT_DEFS: RRRiverSegmentGameObjectDef[] = [
  {
    key: RR_ATLAS_KEY,
    timeValue: 0,
    pointsValue: 1,
    objectId: GameObjectIds.PIRANHA,
    animationFrames: 14,
    movable: true,
    size: 25,
    destroyOnCollision: true,
  },
  {
    key: RR_ATLAS_KEY,
    timeValue: 10,
    pointsValue: 0,
    objectId: GameObjectIds.CLOCK,
    animationFrames: 8,
    size: 30,
    destroyOnCollision: true,
  },
  {
    key: RR_ATLAS_KEY,
    timeValue: -10,
    pointsValue: 0,
    objectId: GameObjectIds.CROCK,
    animationFrames: 12,
    movable: true,
    size: 30,
  },
  {
    key: RR_ATLAS_KEY,
    timeValue: -10,
    pointsValue: 0,
    objectId: GameObjectIds.CROCK2,
    animationFrames: 12,
    movable: true,
    size: 30,
  },
  {
    key: RR_ATLAS_KEY,
    timeValue: -10,
    pointsValue: 0,
    objectId: GameObjectIds.WHIRLPOOL,
    animationFrames: 8,
    size: 30,
  },
  {
    key: RR_ATLAS_KEY,
    timeValue: -10,
    pointsValue: 0,
    objectId: GameObjectIds.LOG,
    size: 40,
  },
  {
    key: RR_ATLAS_KEY,
    timeValue: -10,
    pointsValue: 0,
    objectId: GameObjectIds.STONE_SMALL,
    size: 20,
  },
  {
    key: RR_ATLAS_KEY,
    timeValue: -10,
    pointsValue: 0,
    objectId: GameObjectIds.STONE_BIG,
    size: 30,
  },
  {
    key: RR_ATLAS_KEY,
    timeValue: -10,
    pointsValue: 0,
    objectId: GameObjectIds.LOG,
    size: 30,
  },
  {
    key: RR_ATLAS_KEY,
    timeValue: -10,
    pointsValue: 0,
    animationFrames: 24,
    objectId: GameObjectIds.ARAPAIMA,
    size: 30,
    isPremium: true,
  },
  {
    key: RR_ATLAS_KEY,
    timeValue: 0,
    pointsValue: 10,
    objectId: GameObjectIds.CHEST,
    size: 30,
    isPremium: true,
    animationFrames: 16,
    destroyOnCollision: true,
  },
];

export let riverRideGameplayConfig: RiverRideGameplayConfig = {
  minSpeed: 1,
  maxSpeed: 5,
  riverSegments: [
    // EASY
    {
      id: 1,
      imagePath: "minigames/riverride/map_easy_1.png",
      maskPath: "minigames/riverride/map_easy_1_m.png",
      difficulty: 1,
      objects: [
        generateSegmentGameObjectConfig(410, 2300, GameObjectIds.PIRANHA, RR_GAME_OBJECT_DEFS, 2, 720),
        generateSegmentGameObjectConfig(694, 1600, GameObjectIds.CLOCK, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(650, 1265, GameObjectIds.LOG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(360, 650, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(685, 150, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
      ],
    },
    {
      id: 2,
      imagePath: "minigames/riverride/map_easy_2.png",
      maskPath: "minigames/riverride/map_easy_2_m.png",
      difficulty: 1,
      objects: [
        generateSegmentGameObjectConfig(230, 2250, GameObjectIds.PIRANHA, RR_GAME_OBJECT_DEFS, 2, 470),
        generateSegmentGameObjectConfig(720, 1560, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(550, 1085, GameObjectIds.CLOCK, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(690, 50, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
      ],
    },
    {
      id: 3,
      imagePath: "minigames/riverride/map_easy_3.png",
      maskPath: "minigames/riverride/map_easy_3_m.png",
      difficulty: 1,
      objects: [
        generateSegmentGameObjectConfig(637, 2245, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(210, 1640, GameObjectIds.PIRANHA, RR_GAME_OBJECT_DEFS, 2, 480),
        generateSegmentGameObjectConfig(325, 1112, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(200, 526, GameObjectIds.CLOCK, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(270, 140, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
      ],
    },
    {
      id: 4,
      imagePath: "minigames/riverride/map_easy_4.png",
      maskPath: "minigames/riverride/map_easy_4_m.png",
      difficulty: 1,
      objects: [
        generateSegmentGameObjectConfig(1150, 2850, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(450, 1950, GameObjectIds.CLOCK, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(380, 1830, GameObjectIds.STONE_SMALL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(600, 1840, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(450, 1490, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(270, 1370, GameObjectIds.LOG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(415, 1045, GameObjectIds.PIRANHA, RR_GAME_OBJECT_DEFS, 2, 680),
        generateSegmentGameObjectConfig(610, 900, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(670, 670, GameObjectIds.LOG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(613, 430, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
      ],
    },
    {
      id: 5,
      imagePath: "minigames/riverride/map_easy_5.png",
      maskPath: "minigames/riverride/map_easy_5_m.png",
      difficulty: 1,
      objects: [
        generateSegmentGameObjectConfig(490, 2610, GameObjectIds.CLOCK, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(230, 2180, GameObjectIds.PIRANHA, RR_GAME_OBJECT_DEFS, 2, 450),
        generateSegmentGameObjectConfig(330, 2050, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(270, 1460, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(610, 740, GameObjectIds.LOG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(620, 240, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(225, 160, GameObjectIds.STONE_SMALL, RR_GAME_OBJECT_DEFS),
      ],
    },

    // MEDIUM
    {
      id: 6,
      imagePath: "minigames/riverride/map_medium_1.png",
      maskPath: "minigames/riverride/map_medium_1_m.png",
      difficulty: 2,
      objects: [
        generateSegmentGameObjectConfig(260, 2400, GameObjectIds.PIRANHA, RR_GAME_OBJECT_DEFS, 2, 520),
        generateSegmentGameObjectConfig(215, 1870, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 3, 480),
        generateSegmentGameObjectConfig(585, 1665, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(250, 790, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 530),
        generateSegmentGameObjectConfig(235, 450, GameObjectIds.CLOCK, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(215, 51, GameObjectIds.LOG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(630, 40, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
      ],
    },
    {
      id: 7,
      imagePath: "minigames/riverride/map_medium_2.png",
      maskPath: "minigames/riverride/map_medium_2_m.png",
      difficulty: 2,
      objects: [
        generateSegmentGameObjectConfig(220, 2200, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(210, 1930, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 760),
        generateSegmentGameObjectConfig(215, 1290, GameObjectIds.PIRANHA, RR_GAME_OBJECT_DEFS, 2, 450),
        generateSegmentGameObjectConfig(690, 780, GameObjectIds.CLOCK, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(400, 610, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(300, 230, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 710),
      ],
    },
    {
      id: 8,
      imagePath: "minigames/riverride/map_medium_3.png",
      maskPath: "minigames/riverride/map_medium_3_m.png",
      difficulty: 2,
      objects: [
        generateSegmentGameObjectConfig(320, 2290, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 710),
        generateSegmentGameObjectConfig(320, 2290, GameObjectIds.LOG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(400, 1335, GameObjectIds.PIRANHA, RR_GAME_OBJECT_DEFS, 2, 730),
        generateSegmentGameObjectConfig(580, 1190, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(460, 760, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(700, 750, GameObjectIds.STONE_SMALL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(720, 250, GameObjectIds.CLOCK, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(440, 100, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 3, 780),
      ],
    },
    {
      id: 9,
      imagePath: "minigames/riverride/map_medium_4.png",
      maskPath: "minigames/riverride/map_medium_4_m.png",
      difficulty: 2,
      objects: [
        generateSegmentGameObjectConfig(220, 2360, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 755),
        generateSegmentGameObjectConfig(225, 2000, GameObjectIds.STONE_SMALL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(740, 1800, GameObjectIds.STONE_SMALL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(250, 1215, GameObjectIds.LOG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(740, 1230, GameObjectIds.CLOCK, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(205, 870, GameObjectIds.PIRANHA, RR_GAME_OBJECT_DEFS, 3, 440),
        generateSegmentGameObjectConfig(220, 480, GameObjectIds.STONE_SMALL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(240, 320, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 795),
      ],
    },
    {
      id: 10,
      imagePath: "minigames/riverride/map_medium_5.png",
      maskPath: "minigames/riverride/map_medium_5_m.png",
      difficulty: 2,
      objects: [
        generateSegmentGameObjectConfig(230, 2180, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 770),
        generateSegmentGameObjectConfig(270, 1650, GameObjectIds.LOG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(640, 1350, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(210, 1050, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(735, 1030, GameObjectIds.CLOCK, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(230, 608, GameObjectIds.PIRANHA, RR_GAME_OBJECT_DEFS, 2, 465),
        generateSegmentGameObjectConfig(225, 490, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 780),
      ],
    },

    // HARD
    {
      id: 11,
      imagePath: "minigames/riverride/map_hard_1.png",
      maskPath: "minigames/riverride/map_hard_1_m.png",
      difficulty: 3,
      objects: [
        generateSegmentGameObjectConfig(225, 2625, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 1, 780),
        generateSegmentGameObjectConfig(450, 2110, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(468, 1250, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 468, 1595),
        generateSegmentGameObjectConfig(340, 1090, GameObjectIds.LOG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(590, 1050, GameObjectIds.STONE_SMALL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(800, 851, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(225, 550, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 1, 780),
        generateSegmentGameObjectConfig(220, 440, GameObjectIds.PIRANHA, RR_GAME_OBJECT_DEFS, 1, 544),
        generateSegmentGameObjectConfig(380, 310, GameObjectIds.LOG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(340, 235, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 750),
        generateSegmentGameObjectConfig(225, 35, GameObjectIds.STONE_SMALL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(680, 50, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
      ],
    },
    {
      id: 12,
      imagePath: "minigames/riverride/map_hard_2.png",
      maskPath: "minigames/riverride/map_hard_2_m.png",
      difficulty: 3,
      objects: [
        generateSegmentGameObjectConfig(220, 2700, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 745),
        generateSegmentGameObjectConfig(600, 2070, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 600, 2550),
        generateSegmentGameObjectConfig(1150, 2850, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(270, 1710, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(370, 1540, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 1, 730),
        generateSegmentGameObjectConfig(715, 1340, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(360, 1180, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(675, 1150, GameObjectIds.STONE_SMALL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(770, 2000, GameObjectIds.STONE_SMALL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(295, 990, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(390, 780, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(680, 820, GameObjectIds.STONE_SMALL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(430, 510, GameObjectIds.PIRANHA, RR_GAME_OBJECT_DEFS, 1, 760),
        generateSegmentGameObjectConfig(220, 390, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 1, 590),
        generateSegmentGameObjectConfig(355, 160, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(500, 40, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
      ],
    },

    {
      id: 13,
      imagePath: "minigames/riverride/map_hard_3.png",
      maskPath: "minigames/riverride/map_hard_3_m.png",
      difficulty: 3,
      objects: [
        generateSegmentGameObjectConfig(220, 2485, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 1, 525),
        generateSegmentGameObjectConfig(710, 2480, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(275, 2300, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(435, 2215, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 750),
        generateSegmentGameObjectConfig(345, 2140, GameObjectIds.PIRANHA, RR_GAME_OBJECT_DEFS, 1, 572),
        generateSegmentGameObjectConfig(335, 2050, GameObjectIds.STONE_SMALL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(630, 1980, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(240, 1750, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(235, 1600, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 1, 575),
        generateSegmentGameObjectConfig(320, 1430, GameObjectIds.LOG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(550, 1420, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(250, 820, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 760),
        generateSegmentGameObjectConfig(260, 660, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(530, 275, GameObjectIds.STONE_SMALL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(290, 135, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
      ],
    },
    {
      id: 14,
      imagePath: "minigames/riverride/map_hard_4.png",
      maskPath: "minigames/riverride/map_hard_4_m.png",
      difficulty: 3,
      objects: [
        generateSegmentGameObjectConfig(450, 2700, GameObjectIds.LOG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(215, 2325, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 705),
        generateSegmentGameObjectConfig(200, 2200, GameObjectIds.PIRANHA, RR_GAME_OBJECT_DEFS, 1, 525),
        generateSegmentGameObjectConfig(230, 2070, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 545),
        generateSegmentGameObjectConfig(250, 1940, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 1, 760),
        generateSegmentGameObjectConfig(255, 1810, GameObjectIds.LOG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(660, 1070, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 660, 1590),
        generateSegmentGameObjectConfig(640, 900, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(290, 650, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(480, 400, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(325, 310, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(200, 70, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 1, 430),
        generateSegmentGameObjectConfig(485, 60, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 1, 805),
      ],
    },
    {
      id: 15,
      imagePath: "minigames/riverride/map_hard_5.png",
      maskPath: "minigames/riverride/map_hard_5_m.png",
      difficulty: 3,
      objects: [
        generateSegmentGameObjectConfig(410, 2700, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(570, 2050, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 1, 570, 2520),
        generateSegmentGameObjectConfig(300, 1745, GameObjectIds.STONE_SMALL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(620, 1770, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(210, 1400, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 2, 790),
        generateSegmentGameObjectConfig(300, 1280, GameObjectIds.PIRANHA, RR_GAME_OBJECT_DEFS, 1, 700),
        generateSegmentGameObjectConfig(655, 815, GameObjectIds.STONE_SMALL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(260, 575, GameObjectIds.WHIRLPOOL, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(540, 405, GameObjectIds.LOG, RR_GAME_OBJECT_DEFS),
        generateSegmentGameObjectConfig(230, 330, GameObjectIds.CROCK, RR_GAME_OBJECT_DEFS, 1, 795),
        generateSegmentGameObjectConfig(480, 50, GameObjectIds.STONE_BIG, RR_GAME_OBJECT_DEFS),
      ],
    },
  ],
};
riverRideGameplayConfig = {
  minSpeed: 1,
  maxSpeed: 5,
  riverSegments: [
    {
      id: 1,
      imagePath: "minigames/riverride/map_easy_1.png",
      maskPath: "minigames/riverride/map_easy_1_m.png",
      difficulty: 1,
      objects: [
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 410,
          y: 2300,
          targetX: 720,
          tweenDuration: 2,
        },
        {
          key: "rr-atlas",
          timeValue: 10,
          pointsValue: 0,
          objectId: "clock",
          animationFrames: 8,
          size: 30,
          destroyOnCollision: true,
          x: 741.0010843756039,
          y: 1589.9997976161758,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 567.9978543206136,
          y: 661.988383168476,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 490.0029993367767,
          y: 863.0043107754609,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 486.99543177937085,
          y: 73.99846188293395,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 715.009574805864,
          y: 1430.972293654432,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 10,
          pointsValue: 0,
          objectId: "clock",
          animationFrames: 8,
          size: 30,
          destroyOnCollision: true,
          x: 544.0056295244117,
          y: 759.9587136998107,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "chest",
          size: 30,
          x: 254,
          y: 1085,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 582,
          y: 1359,
          targetX: 751,
          tweenDuration: 2.5,
          targetY: 1365,
        },
      ],
    },
    {
      id: 2,
      imagePath: "minigames/riverride/map_easy_2.png",
      maskPath: "minigames/riverride/map_easy_2_m.png",
      difficulty: 1,
      objects: [
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 230,
          y: 2250,
          targetX: 470,
          tweenDuration: 2,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 445.99367832094754,
          y: 1953.0079536843014,
        },
        {
          key: "rr-atlas",
          timeValue: 10,
          pointsValue: 0,
          objectId: "clock",
          animationFrames: 8,
          size: 30,
          destroyOnCollision: true,
          x: 700.0034607732039,
          y: 1218.0026917048654,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 410.9935629618408,
          y: 451.0081155913606,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 406.002445613064,
          y: 1748.9787294600496,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 10,
          pointsValue: 0,
          objectId: "clock",
          animationFrames: 8,
          size: 30,
          destroyOnCollision: true,
          x: 421.0027916903845,
          y: 1847.980733059912,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 723.0097593804348,
          y: 1117.9659590407264,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "chest",
          size: 30,
          x: 395,
          y: 1661,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 314,
          y: 318,
          targetX: 467,
          tweenDuration: 3,
          targetY: 324,
        },
      ],
    },
    {
      id: 3,
      imagePath: "minigames/riverride/map_easy_3.png",
      maskPath: "minigames/riverride/map_easy_3_m.png",
      difficulty: 1,
      objects: [
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 637,
          y: 2245,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 210,
          y: 1640,
          targetX: 480,
          tweenDuration: 2,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 339.000323005499,
          y: 1025.9982594991097,
        },
        {
          key: "rr-atlas",
          timeValue: 10,
          pointsValue: 0,
          objectId: "clock",
          animationFrames: 8,
          size: 30,
          destroyOnCollision: true,
          x: 253.00122280653204,
          y: 739.0043107754611,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 259.9997692817864,
          y: 333.00390600781225,
        },
        {
          key: "rr-atlas",
          timeValue: 10,
          pointsValue: 0,
          objectId: "clock",
          animationFrames: 8,
          size: 30,
          destroyOnCollision: true,
          x: 297.0016150274951,
          y: 2389.9910343965767,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 300,
          y: 2800,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 340,
          y: 2502,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "chest",
          size: 30,
          x: 634,
          y: 2166,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 665,
          y: 789,
          targetX: 399,
          tweenDuration: 3,
          targetY: 903,
        },
      ],
    },
    {
      id: 4,
      imagePath: "minigames/riverride/map_easy_4.png",
      maskPath: "minigames/riverride/map_easy_4_m.png",
      difficulty: 1,
      objects: [
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 1150,
          y: 2850,
        },
        {
          key: "rr-atlas",
          timeValue: 10,
          pointsValue: 0,
          objectId: "clock",
          animationFrames: 8,
          size: 30,
          destroyOnCollision: true,
          x: 462,
          y: 1976,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 355,
          y: 1828,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 631,
          y: 1838,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 450,
          y: 1490,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 331,
          y: 1415,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 415,
          y: 1045,
          targetX: 645,
          tweenDuration: 2,
          targetY: 1043,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 610,
          y: 900,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 670,
          y: 670,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 613,
          y: 430,
        },
        {
          key: "rr-atlas",
          timeValue: 10,
          pointsValue: 0,
          objectId: "clock",
          animationFrames: 8,
          size: 30,
          destroyOnCollision: true,
          x: 669,
          y: 784,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "chest",
          size: 30,
          x: 435,
          y: 1322,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 404,
          y: 1753,
          targetX: 578,
          tweenDuration: 3,
          targetY: 1756,
        },
      ],
    },
    {
      id: 5,
      imagePath: "minigames/riverride/map_easy_5.png",
      maskPath: "minigames/riverride/map_easy_5_m.png",
      difficulty: 1,
      objects: [
        {
          key: "rr-atlas",
          timeValue: 10,
          pointsValue: 0,
          objectId: "clock",
          animationFrames: 8,
          size: 30,
          destroyOnCollision: true,
          x: 336,
          y: 1458,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 230,
          y: 2180,
          targetX: 450,
          tweenDuration: 2,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 330,
          y: 2050,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 314,
          y: 1574,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 327,
          y: 815,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 436,
          y: 345,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 497,
          y: 123,
        },
        {
          key: "rr-atlas",
          timeValue: 10,
          pointsValue: 0,
          objectId: "clock",
          animationFrames: 8,
          size: 30,
          destroyOnCollision: true,
          x: 279,
          y: 1694,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "chest",
          size: 30,
          x: 293,
          y: 1962,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 234,
          y: 749,
          targetX: 411,
          tweenDuration: 3,
          targetY: 749,
        },
      ],
    },
    {
      id: 6,
      imagePath: "minigames/riverride/map_medium_1.png",
      maskPath: "minigames/riverride/map_medium_1_m.png",
      difficulty: 2,
      objects: [
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 260,
          y: 2400,
          targetX: 502,
          tweenDuration: 2,
          targetY: 2400,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 429,
          y: 1952,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 527.1684090000242,
          y: 1034,
          targetX: 479,
          tweenDuration: 2,
          targetY: 1445,
        },
        {
          key: "rr-atlas",
          timeValue: 10,
          pointsValue: 0,
          objectId: "clock",
          animationFrames: 8,
          size: 30,
          destroyOnCollision: true,
          x: 542,
          y: 143,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 429,
          y: 229,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 638,
          y: 96,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 272,
          y: 2278,
          targetX: 586,
          tweenDuration: 3,
          targetY: 2283,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 528,
          y: 2111,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 553,
          y: 1566,
          targetX: 371,
          tweenDuration: 3,
          targetY: 1566,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          animationFrames: 24,
          objectId: "arapaima",
          size: 30,
          isPremium: true,
          x: 552,
          y: 2614,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          animationFrames: 24,
          objectId: "arapaima",
          size: 30,
          isPremium: true,
          x: 477,
          y: 706,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "chest",
          size: 30,
          isPremium: true,
          animationFrames: 16,
          destroyOnCollision: true,
          x: 596,
          y: 1225,
          targetX: null,
          tweenDuration: null,
        },
      ],
    },
    {
      id: 7,
      imagePath: "minigames/riverride/map_medium_2.png",
      maskPath: "minigames/riverride/map_medium_2_m.png",
      difficulty: 2,
      objects: [
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 704,
          y: 2272,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 440,
          y: 2346,
          targetX: 425,
          tweenDuration: 2,
          targetY: 2021,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 215,
          y: 1290,
          targetX: 450,
          tweenDuration: 2,
        },
        {
          key: "rr-atlas",
          timeValue: 10,
          pointsValue: 0,
          objectId: "clock",
          animationFrames: 8,
          size: 30,
          destroyOnCollision: true,
          x: 737,
          y: 785,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 714,
          y: 653,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 476,
          y: 445,
          targetX: 645,
          tweenDuration: 2,
          targetY: 103,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 521,
          y: 2602,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 715,
          y: 899,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 510,
          y: 1909,
          targetX: 343,
          tweenDuration: 3,
          targetY: 1906,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          animationFrames: 24,
          objectId: "arapaima",
          size: 30,
          isPremium: true,
          x: 262,
          y: 1507,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          animationFrames: 24,
          objectId: "arapaima",
          size: 30,
          isPremium: true,
          x: 262,
          y: 448,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "chest",
          size: 30,
          isPremium: true,
          animationFrames: 16,
          destroyOnCollision: true,
          x: 252,
          y: 613,
          targetX: null,
          tweenDuration: null,
        },
      ],
    },
    {
      id: 8,
      imagePath: "minigames/riverride/map_medium_3.png",
      maskPath: "minigames/riverride/map_medium_3_m.png",
      difficulty: 2,
      objects: [
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 501,
          y: 2177,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 400,
          y: 1335,
          targetX: 730,
          tweenDuration: 2,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 580,
          y: 1190,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 493,
          y: 777,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 745,
          y: 774,
        },
        {
          key: "rr-atlas",
          timeValue: 10,
          pointsValue: 0,
          objectId: "clock",
          animationFrames: 8,
          size: 30,
          destroyOnCollision: true,
          x: 720,
          y: 250,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 365,
          y: 2284,
          targetX: 654,
          tweenDuration: 2,
          targetY: 2286,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 595,
          y: 147,
          targetX: 759,
          tweenDuration: 1,
          targetY: 145,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 414,
          y: 2225,
          targetX: 612,
          tweenDuration: 2.5,
          targetY: 2222,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          animationFrames: 24,
          objectId: "arapaima",
          size: 30,
          isPremium: true,
          x: 384,
          y: 2021,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          animationFrames: 24,
          objectId: "arapaima",
          size: 30,
          isPremium: true,
          x: 583,
          y: 1545,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "chest",
          size: 30,
          isPremium: true,
          animationFrames: 16,
          destroyOnCollision: true,
          x: 583,
          y: 1467,
          targetX: null,
          tweenDuration: null,
        },
      ],
    },
    {
      id: 9,
      imagePath: "minigames/riverride/map_medium_4.png",
      maskPath: "minigames/riverride/map_medium_4_m.png",
      difficulty: 2,
      objects: [
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 450,
          y: 2408,
          targetX: 364,
          tweenDuration: 2,
          targetY: 2145,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 629,
          y: 2062,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 740,
          y: 1800,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 643,
          y: 1093,
        },
        {
          key: "rr-atlas",
          timeValue: 10,
          pointsValue: 0,
          objectId: "clock",
          animationFrames: 8,
          size: 30,
          destroyOnCollision: true,
          x: 740,
          y: 1230,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 221,
          y: 869,
          targetX: 411,
          tweenDuration: 3,
          targetY: 870,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 305,
          y: 512,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 683,
          y: 2226,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 620,
          y: 1630,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 328,
          y: 742,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 264,
          y: 2346,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 265,
          y: 309,
          targetX: 687,
          tweenDuration: 3,
          targetY: 321,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 472,
          y: 2506,
          targetX: 587,
          tweenDuration: 1.5,
          targetY: 2372,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          animationFrames: 24,
          objectId: "arapaima",
          size: 30,
          isPremium: true,
          x: 507,
          y: 1437,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          animationFrames: 24,
          objectId: "arapaima",
          size: 30,
          isPremium: true,
          x: 735,
          y: 144,
          targetX: null,
          tweenDuration: null,
          collided: true,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "chest",
          size: 30,
          isPremium: true,
          animationFrames: 16,
          destroyOnCollision: true,
          x: 466,
          y: 1353,
          targetX: null,
          tweenDuration: null,
        },
      ],
    },
    {
      id: 10,
      imagePath: "minigames/riverride/map_medium_5.png",
      maskPath: "minigames/riverride/map_medium_5_m.png",
      difficulty: 2,
      objects: [
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 326,
          y: 1725,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 640,
          y: 1350,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 718,
          y: 922,
        },
        {
          key: "rr-atlas",
          timeValue: 10,
          pointsValue: 0,
          objectId: "clock",
          animationFrames: 8,
          size: 30,
          destroyOnCollision: true,
          x: 735,
          y: 1030,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 251,
          y: 608,
          targetX: 421,
          tweenDuration: 2,
          targetY: 612,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 287,
          y: 512,
          targetX: 267,
          tweenDuration: 2,
          targetY: 99,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 308,
          y: 2224,
          targetX: 676,
          tweenDuration: 1.5,
          targetY: 2184,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 473,
          y: 2048,
          targetX: 567,
          tweenDuration: 2,
          targetY: 2094,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          animationFrames: 24,
          objectId: "arapaima",
          size: 30,
          isPremium: true,
          x: 717,
          y: 1905,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          animationFrames: 24,
          objectId: "arapaima",
          size: 30,
          isPremium: true,
          x: 369,
          y: 705,
          targetX: null,
          tweenDuration: null,
          collided: true,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "chest",
          size: 30,
          isPremium: true,
          animationFrames: 16,
          destroyOnCollision: true,
          x: 326,
          y: 1658,
          targetX: null,
          tweenDuration: null,
        },
      ],
    },
    {
      id: 11,
      imagePath: "minigames/riverride/map_hard_1.png",
      maskPath: "minigames/riverride/map_hard_1_m.png",
      difficulty: 3,
      objects: [
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 479,
          y: 2543,
          targetX: 501,
          tweenDuration: 1,
          targetY: 2295,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 313,
          y: 2029,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 382,
          y: 1170,
          targetX: 465,
          targetY: 1428,
          tweenDuration: 1,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 707,
          y: 1023,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 605,
          y: 1130,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 313,
          y: 933,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 714,
          y: 785,
          targetX: 760,
          tweenDuration: 1,
          targetY: 437,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 311,
          y: 434,
          targetX: 487,
          tweenDuration: 1,
          targetY: 434,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 416,
          y: 353,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 225,
          y: 35,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 633,
          y: 83,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 707,
          y: 1991,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 250,
          y: 2151,
          targetX: 643,
          tweenDuration: 1.5,
          targetY: 2163,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 425,
          y: 216,
          targetX: 685,
          tweenDuration: 1,
          targetY: 215,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 244,
          y: 1964,
          targetX: 334,
          tweenDuration: 1.5,
          targetY: 1924,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          animationFrames: 24,
          objectId: "arapaima",
          size: 30,
          x: 289,
          y: 1865,
          targetX: null,
          tweenDuration: null,
        },
      ],
    },
    {
      id: 12,
      imagePath: "minigames/riverride/map_hard_2.png",
      maskPath: "minigames/riverride/map_hard_2_m.png",
      difficulty: 3,
      objects: [
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 600,
          y: 2070,
          targetX: 600,
          targetY: 2550,
          tweenDuration: 2,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 1150,
          y: 2850,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 326,
          y: 1821,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 705,
          y: 1396,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 360,
          y: 1180,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 713,
          y: 1174,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 625,
          y: 2005,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 295,
          y: 990,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 390,
          y: 780,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 742,
          y: 903,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 535,
          y: 510,
          targetX: 668,
          tweenDuration: 1,
          targetY: 511,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 462,
          y: 256,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 379,
          y: 208,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 658,
          y: 1807,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 521,
          y: 375,
          targetX: 308,
          tweenDuration: 1.5,
          targetY: 375,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 517.910877987629,
          y: 1520.39314650004,
          targetX: 644,
          tweenDuration: 1,
          targetY: 1506,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 299,
          y: 1712,
          targetX: 681,
          tweenDuration: 2,
          targetY: 1717,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 587,
          y: 1935,
          targetX: 722,
          tweenDuration: 1.5,
          targetY: 1866,
          collided: true,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          animationFrames: 24,
          objectId: "arapaima",
          size: 30,
          x: 312,
          y: 44,
          targetX: null,
          tweenDuration: null,
        },
      ],
    },
    {
      id: 13,
      imagePath: "minigames/riverride/map_hard_3.png",
      maskPath: "minigames/riverride/map_hard_3_m.png",
      difficulty: 3,
      objects: [
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 710,
          y: 2480,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 275,
          y: 2300,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 345,
          y: 2140,
          targetX: 572,
          tweenDuration: 1,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 335,
          y: 2050,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 630,
          y: 1980,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 263,
          y: 1598,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 442,
          y: 1661,
          targetX: 467,
          tweenDuration: 1,
          targetY: 1350,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 368,
          y: 1474,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 529,
          y: 1543,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 502,
          y: 961,
          targetX: 439,
          tweenDuration: 2,
          targetY: 681,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 366,
          y: 784,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 558,
          y: 382,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 251,
          y: 355,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 366,
          y: 2210,
          targetX: 662,
          tweenDuration: 1.5,
          targetY: 2206,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 479,
          y: 2549,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 411,
          y: 1108,
          targetX: 599,
          tweenDuration: 1.5,
          targetY: 1097,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          animationFrames: 24,
          objectId: "arapaima",
          size: 30,
          x: 641,
          y: 251,
          targetX: null,
          tweenDuration: null,
        },
      ],
    },
    {
      id: 14,
      imagePath: "minigames/riverride/map_hard_4.png",
      maskPath: "minigames/riverride/map_hard_4_m.png",
      difficulty: 3,
      objects: [
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 477,
          y: 2661,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 200,
          y: 2200,
          targetX: 504,
          tweenDuration: 1,
          targetY: 2200,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 373,
          y: 2089,
          targetX: 290,
          tweenDuration: 1.5,
          targetY: 1780,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 506,
          y: 2048,
          targetX: 689,
          tweenDuration: 1,
          targetY: 1813,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 457,
          y: 1932,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 660,
          y: 1070,
          targetX: 670,
          targetY: 1466,
          tweenDuration: 2,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 551,
          y: 1080,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 276,
          y: 795,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 465,
          y: 654,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 477,
          y: 485,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 372,
          y: 345,
          targetX: 256,
          tweenDuration: 1,
          targetY: 63,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 495,
          y: 344,
          targetX: 623,
          tweenDuration: 1,
          targetY: 141,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 410.4906890588188,
          y: 2584.595343220656,
          targetX: 262,
          tweenDuration: 1,
          targetY: 2584,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 256,
          y: 2294,
          targetX: 564,
          tweenDuration: 1,
          targetY: 2300,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 561,
          y: 2699,
          targetX: 742,
          tweenDuration: 1,
          targetY: 2712,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 493,
          y: 1573,
          targetX: 326,
          tweenDuration: 1.5,
          targetY: 1596,
          collided: true,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          animationFrames: 24,
          objectId: "arapaima",
          size: 30,
          x: 643,
          y: 795,
          targetX: null,
          tweenDuration: null,
        },
      ],
    },
    {
      id: 15,
      imagePath: "minigames/riverride/map_hard_5.png",
      maskPath: "minigames/riverride/map_hard_5_m.png",
      difficulty: 3,
      objects: [
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 661,
          y: 2410,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 545,
          y: 2145,
          targetX: 531,
          targetY: 2563,
          tweenDuration: 1,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 319,
          y: 2016,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 638,
          y: 1992,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 473,
          y: 1280,
          targetX: 650,
          tweenDuration: 1,
          targetY: 1285,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 737,
          y: 767,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 758,
          y: 305,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 256,
          y: 569,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 485,
          y: 525,
          targetX: 500,
          tweenDuration: 1,
          targetY: 172,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 275,
          y: 104,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 734,
          y: 1357,
          targetX: 393,
          tweenDuration: 1,
          targetY: 1356,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 524,
          y: 1159,
          targetX: 704,
          tweenDuration: 1,
          targetY: 1119,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 272,
          y: 683,
          targetX: 442,
          tweenDuration: 1.5,
          targetY: 622,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          animationFrames: 24,
          objectId: "arapaima",
          size: 30,
          x: 277,
          y: 1836,
          targetX: null,
          tweenDuration: null,
          collided: true,
        },
      ],
    },
    {
      id: 16,
      imagePath: "minigames/riverride/map_hard_1.png",
      maskPath: "minigames/riverride/map_hard_1_m.png",
      difficulty: 4,
      objects: [
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 492,
          y: 2505,
          targetX: 540,
          tweenDuration: 1,
          targetY: 2165,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 332,
          y: 2504,
          targetX: 235,
          tweenDuration: 1.5,
          targetY: 2160,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 332,
          y: 2020,
          targetX: 244,
          tweenDuration: 1.5,
          targetY: 1715,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 686,
          y: 2237,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 660,
          y: 2027,
          targetX: 742,
          tweenDuration: 1.5,
          targetY: 1748,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 725,
          y: 2526,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_small",
          size: 20,
          x: 400,
          y: 2513,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 364,
          y: 2168,
          targetX: null,
          tweenDuration: null,
          collided: true,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 504,
          y: 1440,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 417,
          y: 1337,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 491,
          y: 2703,
          targetX: 713,
          tweenDuration: 1.5,
          targetY: 2627,
          collided: true,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 348,
          y: 1251,
          targetX: 526,
          tweenDuration: 1,
          targetY: 1318,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 632,
          y: 1216,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 374,
          y: 1187,
          targetX: null,
          tweenDuration: null,
          collided: true,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 420.2861449982156,
          y: 289.9518066690457,
          targetX: 695,
          tweenDuration: 1,
          targetY: 212,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 277,
          y: 838,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 352,
          y: 952,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 238,
          y: 1084,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 742,
          y: 917,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 646,
          y: 790,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 615,
          y: 1072,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 602,
          y: 649,
          targetX: 754,
          tweenDuration: 1,
          targetY: 717,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 299,
          y: 602,
          targetX: 551,
          tweenDuration: 3,
          targetY: 532,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 331,
          y: 2631,
          targetX: 471,
          tweenDuration: 1.5,
          targetY: 2608,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 363,
          y: 1526,
          targetX: 527,
          tweenDuration: 1.5,
          targetY: 1528,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "chest",
          size: 30,
          isPremium: true,
          animationFrames: 16,
          destroyOnCollision: true,
          x: 394,
          y: 2097,
          targetX: null,
          tweenDuration: null,
        },
      ],
    },
    {
      id: 17,
      imagePath: "minigames/riverride/map_hard_2.png",
      maskPath: "minigames/riverride/map_hard_2_m.png",
      difficulty: 4,
      objects: [
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 529,
          y: 2613,
          targetX: 619,
          tweenDuration: 1,
          targetY: 2243,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 540,
          y: 2178,
          targetX: 662,
          tweenDuration: 1,
          targetY: 1764,
          collided: true,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 398,
          y: 2087,
          targetX: 245,
          tweenDuration: 1.5,
          targetY: 1788,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 685,
          y: 2390,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 665,
          y: 1624,
          targetX: null,
          tweenDuration: 0,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 479,
          y: 2123,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 465,
          y: 289,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 282,
          y: 1324,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 673,
          y: 1401,
          targetX: null,
          tweenDuration: 0,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 398,
          y: 1191,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 345,
          y: 1453,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 332,
          y: 1026,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 240,
          y: 1641,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 702,
          y: 1158,
          targetX: null,
          tweenDuration: 1.5,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 545,
          y: 1499,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 279,
          y: 933,
          targetX: 426,
          tweenDuration: 0.5,
          targetY: 869,
          collided: true,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 765,
          y: 1041,
          targetX: 708,
          tweenDuration: 1,
          targetY: 698,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 358,
          y: 1753,
          targetX: 585,
          tweenDuration: 1.5,
          targetY: 1647,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 532,
          y: 397,
          targetX: 260,
          tweenDuration: 1,
          targetY: 316,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 669,
          y: 564,
          targetX: null,
          tweenDuration: 0,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 476,
          y: 52,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 312,
          y: 205,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 397,
          y: 1827,
          targetX: 555,
          tweenDuration: 3,
          targetY: 1789,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 247,
          y: 1251,
          targetX: 374,
          tweenDuration: 1,
          targetY: 1255,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "chest",
          size: 30,
          isPremium: true,
          animationFrames: 16,
          destroyOnCollision: true,
          x: 339,
          y: 1113,
          targetX: null,
          tweenDuration: null,
        },
      ],
    },
    {
      id: 18,
      imagePath: "minigames/riverride/map_hard_3.png",
      maskPath: "minigames/riverride/map_hard_3_m.png",
      difficulty: 4,
      objects: [
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 717,
          y: 2318,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 249,
          y: 2215,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 475,
          y: 2606,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 494,
          y: 2287,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 323,
          y: 2152,
          targetX: 243,
          tweenDuration: 1,
          targetY: 1798,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 411,
          y: 2181,
          targetX: 673,
          tweenDuration: 1,
          targetY: 2255,
          collided: true,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 660,
          y: 2098,
          targetX: 640,
          tweenDuration: 1,
          targetY: 1836,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 386,
          y: 1655,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 281,
          y: 1521,
          targetX: null,
          tweenDuration: null,
          collided: true,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 541,
          y: 1587,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 512,
          y: 1755,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 350,
          y: 1293,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 491,
          y: 1510,
          targetX: 367,
          tweenDuration: 1,
          targetY: 1445,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 468,
          y: 637,
          targetX: 631,
          tweenDuration: 1.5,
          targetY: 312,
          collided: true,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 253.61809061467653,
          y: 445.94307519157906,
          targetX: 360,
          tweenDuration: 1,
          targetY: 736,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 459,
          y: 955,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 492,
          y: 779,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 623,
          y: 1223,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 302,
          y: 2494,
          targetX: 501,
          tweenDuration: 1.5,
          targetY: 2477,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 488,
          y: 1038,
          targetX: 616,
          tweenDuration: 1.5,
          targetY: 1140,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "chest",
          size: 30,
          isPremium: true,
          animationFrames: 16,
          destroyOnCollision: true,
          x: 470,
          y: 1372,
          targetX: null,
          tweenDuration: null,
        },
      ],
    },
    {
      id: 19,
      imagePath: "minigames/riverride/map_hard_4.png",
      maskPath: "minigames/riverride/map_hard_4_m.png",
      difficulty: 4,
      objects: [
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 509,
          y: 2546,
          targetX: 509,
          tweenDuration: 1,
          targetY: 2037,
          collided: true,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 240,
          y: 2566,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 679,
          y: 2414,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 389,
          y: 2268,
          targetX: 286,
          tweenDuration: 1,
          targetY: 1944,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 232,
          y: 2275,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 394,
          y: 1996,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 422,
          y: 2024,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 374,
          y: 1693,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 443,
          y: 2264,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 285,
          y: 1809,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 473,
          y: 1888,
          targetX: 677,
          tweenDuration: 1.5,
          targetY: 1977,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 433,
          y: 1563,
          targetX: 717,
          tweenDuration: 1,
          targetY: 1406,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 516,
          y: 973,
          targetX: 670,
          tweenDuration: 1.5,
          targetY: 1296,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 338,
          y: 906,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 572,
          y: 848,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 270,
          y: 727,
          targetX: null,
          tweenDuration: null,
          collided: true,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 646,
          y: 978,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 435,
          y: 1060,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 262,
          y: 411,
          targetX: 456,
          tweenDuration: 1,
          targetY: 517,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 288,
          y: -78,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 162,
          y: -59,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 285,
          y: 168,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 244,
          y: 360,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 576,
          y: 277,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 393,
          y: 255,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 333,
          y: 2589,
          targetX: 501,
          tweenDuration: 1,
          targetY: 2643,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 439,
          y: 688,
          targetX: 278,
          tweenDuration: 1,
          targetY: 609,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "chest",
          size: 30,
          isPremium: true,
          animationFrames: 16,
          destroyOnCollision: true,
          x: 593,
          y: 906,
          targetX: null,
          tweenDuration: null,
        },
      ],
    },
    {
      id: 20,
      imagePath: "minigames/riverride/map_hard_5.png",
      maskPath: "minigames/riverride/map_hard_5_m.png",
      difficulty: 5,
      objects: [
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 412,
          y: 2540,
          targetX: 696,
          tweenDuration: 1,
          targetY: 2402,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 721,
          y: 2491,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 441,
          y: 2625,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 408,
          y: 2171,
          targetX: 580,
          tweenDuration: 1.5,
          targetY: 2316,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 431,
          y: 1237,
          targetX: 671,
          tweenDuration: 1,
          targetY: 1113,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 631,
          y: 1971,
          targetX: 765,
          tweenDuration: 1.5,
          targetY: 1608,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 351,
          y: 1982,
          targetX: 226,
          tweenDuration: 1.5,
          targetY: 1610,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock2",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 400.31227616217933,
          y: 884.0395900767062,
          targetX: 637,
          tweenDuration: 1,
          targetY: 999,
          collided: true,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 714.1978499752004,
          y: 792.4736321111025,
          targetX: 744,
          tweenDuration: 1,
          targetY: 369,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "crock",
          animationFrames: 12,
          movable: true,
          size: 30,
          x: 502,
          y: 182,
          targetX: 431,
          tweenDuration: 1.5,
          targetY: 685,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 550,
          y: 2147,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 334,
          y: 2223,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 582,
          y: 1097,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 327,
          y: 810,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 243,
          y: 1924,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 315,
          y: 1547,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 604,
          y: 1716,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 767,
          y: 235,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "log",
          size: 40,
          x: 612,
          y: 397,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 495,
          y: 94,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "stone_big",
          size: 30,
          x: 403,
          y: 1327,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 257,
          y: 283,
          targetX: null,
          tweenDuration: null,
        },
        {
          key: "rr-atlas",
          timeValue: -10,
          pointsValue: 0,
          objectId: "whirlpool",
          animationFrames: 8,
          size: 30,
          x: 255,
          y: 563,
          targetX: null,
          tweenDuration: null,
          collided: true,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 372,
          y: 2094,
          targetX: 595,
          tweenDuration: 1,
          targetY: 2095,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 1,
          objectId: "piranha",
          animationFrames: 14,
          movable: true,
          size: 25,
          destroyOnCollision: true,
          x: 541,
          y: 1370,
          targetX: 705,
          tweenDuration: 1,
          targetY: 1296,
        },
        {
          key: "rr-atlas",
          timeValue: 0,
          pointsValue: 10,
          objectId: "chest",
          size: 30,
          isPremium: true,
          animationFrames: 16,
          destroyOnCollision: true,
          x: 564,
          y: 1559,
          targetX: null,
          tweenDuration: null,
        },
      ],
    },
  ],
} as any;
