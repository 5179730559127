import { Component, Input, OnInit } from "@angular/core";
import { GetMediaListRequest } from "../../../../../hud/api/custom/interfaces/request/get-media-list-request.interface";
import { MediaComponent } from '../../../../../hud/custom/dialogs/media/media.component';
import { MediaCategory } from "../../../../../hud/api/custom/interfaces/response/get-media-category-response.interface";

@Component({
  selector: "wf-media",
  templateUrl: "./wf-media.component.html",
})
export class WfMediaComponent extends MediaComponent implements OnInit {
  @Input() sourceId: number;
  @Input() isLibrary: boolean;

  currentFilter: MediaCategory;

  ngOnInit(): void {
    this.getMediaList();
    this.getMediaCategories();
  }

  getMediaList() {
    let data: GetMediaListRequest = {
      category_id: this.currentFilterId,
      source_id: this.sourceId,
      page: this.currentPage,
      limit: 5,
    };

    this.apiMediaService.getMediaList(data).subscribe(({ headers, body }) => {
      this.maxPages = headers.get("X-Pages-Count");
      this.items = body;
    });
  }

  getMediaCategories() {
    this.apiMediaService.getMediaCategories({ source_id: this.sourceId }).subscribe(resp => {
      this.filters = [...this.filters, ...resp];
      this.currentFilter = this.filters[0];
    });
  }

  changeFilter() {
    this.currentFilterId = this.currentFilter.id;
    this.prepare();
    this.getMediaList();
  }
}
