import { Directive, Injectable, Input, OnInit } from "@angular/core";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { RankGame } from "../../interfaces/custom/rank-game.interface";
import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import * as R from "ramda";
import { GetGameRankResultsResponse } from "../../api/interfaces/response/get-game-rank-results.response.interface";
import { RANK_CONFIG } from "../../basic/custom/dialogs/rank-details/rank-game-details/consts/rank.config";
import { ApiGameRanksService } from "../../api/service/api-game-ranks.service";
import { OwDate } from "../../../../../../core/utility/ow-date.class";
import { LEVELS } from "../../basic/custom/dialogs/rank-details/rank-game-details/consts/levels.const";
import { Level } from "../../basic/custom/dialogs/rank-details/rank-game-details/interfaces/level.interface";
import { LEVEL_FILTER_KEY } from "../../const/custom/level-filter-key.const";
import { GetGameRankResultsRequest } from "../../api/interfaces/request/get-game-rank-results.request.interface";
import { RANK_GROUP } from "../../const/custom/rank-group.const";
import { ApiSparkleService } from "../../api/service/api-sparkle.service";
import { SchemaFilterTitleDetails } from "../../interfaces/custom/rank-visibility-schema.interface";
import { getSchemaByGroupAndTitleId, showTabBySchema } from "../../basic/custom/helpers/rank-schema.helper";
import { PlayerService } from "../../../../../player/providers/player.service";
import { UserService } from "../../../../../user/providers/user.service";
import { TITLE_IDS } from "../../basic/custom/dialogs/rank-details/rank-game-details/consts/title-ids.const";
import { GAME_CONFIG } from "../../../../../../core/config/custom/_parsed-game.config";
import { LEVELS_CC } from "../../basic/custom/dialogs/rank-details/rank-game-details/consts/levels-cc.const";
import { TITLE_IDS_CC } from "../../basic/custom/dialogs/rank-details/rank-game-details/consts/title-ids-cc.const";
import { MyIdObject, RankLevel } from "../../../../interfaces/custom/rank.interface";

@Directive()
@Injectable()
export abstract class AbstractRankDetailsGameComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractRankDetailsGameComponent>;
  @OwInject(ApiGameRanksService) apiGameRanksService: ApiGameRanksService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiSparkleService) apiSparkleService: ApiSparkleService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(UserService) userService: UserService;
  @Input() @OwInject(MAT_DIALOG_DATA) data: {
    rankId: number;
    group?: string;
    innerRank?: boolean;
    hideFilters?: boolean;
    titleIds?: number[];
    isWfRank?: boolean;
  };

  @Input() searchValue: string;

  RANK_CONFIG;
  STOCK_VIEW = STOCK_VIEW;
  levels = GAME_CONFIG.IS_CC ? LEVELS_CC : LEVELS;
  titleIds = GAME_CONFIG.IS_CC ? TITLE_IDS_CC : TITLE_IDS;

  owDate: OwDate;
  myPositionIndex: number;
  activeLevel: Level;
  rank: RankGame;
  rankResults: GetGameRankResultsResponse;

  sparkle: Sparkle;
  schema: SchemaFilterTitleDetails;
  myIdObject: MyIdObject;

  ngOnInit() {
    this.myIdObject = {
      player_id: this.playerService.player.id,
      branch_id: this.playerService.player.branch_id,
      region_id: this.playerService.player.region_id,
      partner_id: this.userService.me.partner_id,
      title_id: this.playerService.player.title_id.id,
    };
    this.setConfig();
    this.setSchema();
    this.getGameRank();
  }

  setConfig(config = RANK_CONFIG.DEFAULT) {
    this.RANK_CONFIG = R.clone(config);
  }

  setSchema() {
    this.schema = getSchemaByGroupAndTitleId(this.data.group, this.myIdObject);
    this.activeLevel = showTabBySchema(<RankLevel[]>this.levels, <RankLevel>this.activeLevel, this.schema).activeLevel;
  }

  getGameRank() {
    this.apiGameRanksService
      .getGameRank({
        rank_edition_id: this.data.rankId,
      })
      .subscribe(
        resp => {
          this.rank = resp;
          this.owDate = new OwDate({
            year: this.rank.year,
            month: this.rank.month,
          });
          this.setFirstAllowLevels();
          this.afterRankRequest();
        },
        errResp => {
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
  }

  afterRankRequest() {
    if (this.rank.parameters && this.rank.parameters.is_sparkle) {
      this.apiSparkleService.getSparkle().subscribe(
        (resp: Sparkle) => {
          this.sparkle = resp;
        },
        errResp => {
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
    }
  }

  setWfTooltips() {
    if (!this.rankResults && !this.data.isWfRank) {
      return;
    }

    this.rankResults?.results.forEach(item => {
      item["wfRankToolip"] = `
        Za tropienia finałowe: ${item.value1 - item.value2 - item.value3} \n
        Za Punkty Doświadczenia: ${item.value3} \n 
        Za niewydane Klisze: ${item.value2}
      `;
    });
  }

  getGameRanksResults() {
    this.rankResults = null;

    const requestData = this.getRequestData();

    let apiRequest;
    switch (this.data.group) {
      case RANK_GROUP.BRANCH:
        this.setConfig(RANK_CONFIG.BRANCH);
        apiRequest = this.apiGameRanksService.getGameRanksResultsBranches(requestData);
        break;

      case RANK_GROUP.BRANCH_MANAGERS:
        this.setConfig(RANK_CONFIG.DEFAULT);
        apiRequest = this.apiGameRanksService.getGameRanksResults(requestData);
        break;

      case RANK_GROUP.REGION:
        this.setConfig(RANK_CONFIG.REGION);
        apiRequest = this.apiGameRanksService.getGameRanksResultsRegions(requestData);
        break;

      default:
        apiRequest = this.apiGameRanksService.getGameRanksResults(requestData);
    }

    apiRequest.subscribe(
      resp => {
        this.rankResults = resp;
        this.afterGetGameRankRequest();
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  afterGetGameRankRequest() {
    this.setMyPositionIndex();
    this.replacePlaceholders();
    this.setWfTooltips();
  }

  replacePlaceholders() {
    const regex = new RegExp(`%([a-zA-Z0-9_]+)`, "g");

    Object.keys(this.rank.parameters || {}).forEach(key => {
      this.rankResults.results.forEach(item => {
        let value = this.rank.parameters[key];

        if (typeof value === "string") {
          let result = this.getRegexValue(regex, value);

          while (result) {
            value = value.replace(`%${result}`, item[result]);
            result = this.getRegexValue(regex, value);
          }

          item[key] = value;
        }
      });

      switch (key) {
        case "column_prizes_label":
          this.RANK_CONFIG.columns.forEach(column => {
            if (column.prizes) {
              column.header = this.rank.parameters[key];
            }
          });
          break;
      }
    });
  }

  getRegexValue(regex: RegExp, string: string) {
    const results = regex.exec(string);
    return results && results[1];
  }

  setFirstAllowLevels() {
    const tmpLevel = this.levels.find(level => level.show) || this.levels[0];
    this.changeLevel(tmpLevel);
  }

  changeLevel(level: Level) {
    this.activeLevel = level;
    this.getGameRanksResults();
  }

  getRequestData(): GetGameRankResultsRequest {
    const dataRequest: GetGameRankResultsRequest = {
      rank_edition_id: this.rank.rank_edition_id,
    };

    switch (this.activeLevel.filterKey) {
      case LEVEL_FILTER_KEY.BRANCH:
        dataRequest.branch = true;
        break;

      case LEVEL_FILTER_KEY.REGION:
        dataRequest.region = true;
        break;
    }

    const titleIds = this.data.titleIds || this.titleIds[this.data.group];
    if (titleIds) {
      dataRequest.title_ids = titleIds;
    }

    return dataRequest;
  }

  setMyPositionIndex() {
    this.myPositionIndex = this.rankResults.results.findIndex(item => {
      return R.equals(this.rankResults.my_position, item);
    });
  }

  changeSearchValue(event) {
    this.searchValue = event.target.value;
  }

  close() {
    this.matDialogRef.close();
  }
}

interface Sparkle {
  sparkle_sum: number;
  cash_sum: number;
}
