import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../../../../shared-ui/mobile/consts/stock-view.const";
import { PLAYER_SCORE } from "../contract-sale.component";

@Component({
  selector: "app-prizes-alert",
  templateUrl: "./prizes-alert.component.html",
})
export class PrizesAlertComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data: {
    prizes;
    title;
  };
  STOCK_VIEW = STOCK_VIEW;
  PLAYER_SCORE = PLAYER_SCORE;
  prizes;
  title;

  ngOnInit() {
    if (this.data.prizes) {
      this.prizes = this.data.prizes;
      this.title = this.data.title;
    }
  }

  close() {
    this.dialogService.closeActive();
  }
}
