import { Component, OnInit } from "@angular/core";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Media } from "../../../api/custom/interfaces/response/get-media-list-response.interface";
import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { MediaCategory } from "../../../api/custom/interfaces/response/get-media-category-response.interface";
import { ApiMediaService } from "../../../api/custom/services/api-media.service";

@Component({
  selector: "app-custom-media",
  templateUrl: "./media.component.html",
})
export class MediaComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiMediaService) apiMediaService: ApiMediaService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data: {
    sourceId: number;
    isShowBackButton: boolean;
    title: string;
    icon: string;
  };
  DEFAULT_SOURCE_ID = 1;
  sourceId: number;
  filters: MediaCategory[] = [
    {
      id: 0,
      name: "Wszystkie kategorie",
    },
  ];
  currentFilterId = 0;
  currentPage = 1;
  maxPages: number;
  items: Media[];

  ngOnInit() {
    this.setSourceId();
    this.getMediaList();
    this.getMediaCategories();
  }

  setSourceId() {
    this.sourceId = this.data.sourceId || this.DEFAULT_SOURCE_ID;
  }

  getMediaList() {
    this.apiMediaService
      .getMediaList({
        page: this.currentPage,
        limit: 5,
        category_id: this.currentFilterId,
        source_id: this.sourceId,
      })
      .subscribe(({ headers, body }) => {
        this.maxPages = headers.get("X-Pages-Count");
        this.items = body;
      });
  }

  getMediaCategories() {
    this.apiMediaService.getMediaCategories({ source_id: this.sourceId }).subscribe(resp => {
      this.filters = [...this.filters, ...resp];
    });
  }

  nextPage() {
    if (this.currentPage < this.maxPages) {
      this.currentPage++;
      this.getMediaList();
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.getMediaList();
    }
  }

  changeFilter() {
    this.prepare();
    this.getMediaList();
  }

  prepare() {
    this.currentPage = 1;
  }
}
