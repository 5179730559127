<div
  *ngIf="storageValue"
  class="building-productions"
>
  <div class="sub-title">Pojemność <br> magazynu:</div>

  <m-ui-stock-other-custom
    [item]="{amount: storageValue}"
    [iconType]="'warehouse-capacity'"
    [stockView]="STOCK_VIEW.CUSTOM"
    [customIconSize]="'big'"
    [customClassStock]="'stock-san-a'"
    [lack]="false"
  ></m-ui-stock-other-custom>
</div>
