import { Component, OnInit } from "@angular/core";
import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { ApiBusinessLeagueService } from "../../../api/services/api-business-league.service";
import { LeagueAchievement } from "../../../interfaces/custom/league-achievement.interface";
import { STOCK_VIEW } from "../../../../shared-ui/mobile/consts/stock-view.const";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { LeagueAchievementDetailsComponent } from "./league-achievement-details/league-achievement-details.component";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationsService } from "../../../../../services/custom/notifications.service";
import { ClearNotificationService } from "../../utils/clear-notification.service";

@Component({
  selector: "app-league-achievements",
  templateUrl: "./league-achievements.component.html",
})
export class LeagueAchievementsComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiBusinessLeagueService) apiBusinessLeagueService: ApiBusinessLeagueService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(NotificationsService) notificationsService: NotificationsService;
  @OwInject(ClearNotificationService) clearNotificationService: ClearNotificationService;
  @OwInject(MAT_DIALOG_DATA) data: { isOpenFromQuarter?: boolean; playerId?: number; branchName?: string };

  achievements: LeagueAchievement[];
  sourceDate;
  STOCK_VIEW = STOCK_VIEW;
  branchName?: string;

  ngOnInit(): void {
    this.getAchievementList(this.data?.playerId);
    this.branchName = this.data?.branchName;
  }

  clearNotification() {
    this.clearNotificationService.clearNotification(["processing_achievement"], this.data?.playerId, true);
  }

  getAchievementList(playerId?: number) {
    this.apiBusinessLeagueService.achievements(playerId).subscribe(resp => {
      this.sourceDate = resp.source_data_date;
      this.achievements = resp.achievements;
      this.achievements.forEach(a => {
        a.flipped = false;
        a.sumPrizes = this.sumPrizes(a);
        a.polygons = this.getThresholds(a);
        a.isProgressGreen = a.completions.some(c => c.branch_id == this.playerService.player.branch_id);
        a.hasNotification = this.hasNotification(a);
      });
    });
  }

  hasNotification(item: LeagueAchievement) {
    return this.findNotificationAchievementByIdOrNull(item.achievement_id);
  }

  findNotificationAchievementByIdOrNull(value: number | null) {
    const processingAchievements = this.playerService.player["gui_notifications_list"]?.["processing_achievement"];

    let exist = false;
    if (processingAchievements && processingAchievements.length) {
      exist = processingAchievements.find(processingAchievement => processingAchievement.achievement_id === value);
    }

    return exist;
  }

  getThresholds(a) {
    return Array.from(Array(a.threshold?.limit).keys());
  }

  sumPrizes(achievement) {
    let sum = 0;

    if (achievement.is_achievable) {
      achievement.completions?.forEach(c => {
        if (c.is_player_completed) {
          c.prizes.currency?.forEach(p => {
            sum += p?.amount;
          });
        }
      });
    }

    return {
      currency_id: 9,
      amount: sum,
    };
  }

  openAchievementDetails(achievementId) {
    this.dialogService.open(LeagueAchievementDetailsComponent, {
      data: {
        achievementId: achievementId,
      },
    });
  }

  ngOnDestroy() {
    this.clearNotification();
  }
}
