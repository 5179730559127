<div [class.padding]="isMissionWithoutStars" class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button
  *ngIf="!data?.isOpenBySocket"
  ></m-ui-back-button>

  <img
    *ngIf="!isMissionWithoutStars && (activeGroupMission || icon)"
    [src]="(activeGroupMission?.icon || icon) + '.png' | asset: 'missions/big'"
    class="annie-image"
  >

  <div *ngIf="!isMissionWithoutStars && groupMissions" class="stars loading-opacity">
    <ng-container *ngFor="let groupMission of groupMissions">
      <div
        [class.full]="groupMission.is_reward_collected || allStarsCollected"
        [class.half]="activeGroupMission?.mission_group_id === groupMission.mission_group_id"
        [matTooltip]="!groupMission.is_reward_collected && activeGroupMission?.mission_group_id !== groupMission.mission_group_id
          ? 'Realizuj zadania, aby móc rozpocząć zdobywanie tego statusu'
          : ''"
        [ngClass]="{
                'camp': groupMission.sub_group_id === SUB_GROUPS.ANIA,
                'trail': groupMission.sub_group_id === SUB_GROUPS.JANEK,
                'bulb': groupMission.sub_group_id === SUB_GROUPS.PROFESSOR
              }"
        class="star"
        matTooltipPosition="above"
      ></div>
    </ng-container>
  </div>

  <ng-container *ngIf="activeGroupMission">
    <div class="title loading-opacity">
      <span>{{ activeGroupMission.name }}</span>
      <ng-container *ngIf="!isMissionWithoutStars">
        Nowe opcje rozwoju po awansie na kolejny status
      </ng-container>
    </div>

    <div
      [innerHtml]="activeGroupMission.description"
      class="description loading-opacity"
    ></div>

    <div *ngIf="activeGroupMissionDetails" class="progress loading-opacity">
      <div class="tabs">
        <div
          *ngFor="let mission of activeGroupMissionDetails.missions;
            let last = last"
          [class.active]="mission.title === activeMission.title"
          [class.complete]="mission.is_collected"
          class="tab green"
        >
          <div
            (click)="changeMission(mission)"
            class="tab-container"

          >
            <span>{{ mission.title }}</span>
          </div>
          <div *ngIf="!last" class="tab-separator">
            <div class="separator-arrow arrow-down"></div>
            <div class="separator-arrow arrow-up"></div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="activeMissionDetails">
      <div class="missions loading-opacity">
        <div
          [innerHtml]="activeMissionDetails.description"
          class="missions-info"
        ></div>
        <div class="missions-separateor"></div>
        <div class="missions-stages">
          <div
            *ngFor="let condition of activeMissionDetails.conditions"
            [class.text-bold]="condition.completed_at"
            class="stage"
          >
            <div [class.stage-box-active]="condition.completed_at"
                 class="stage-box">
              <div class="box"></div>
              <span>{{ condition.name }}</span>
            </div>
            <ng-container [ngSwitch]="condition.target_type">
              <!-- INTEGER -->
              <ng-container *ngSwitchCase="MISSION_CONDITION_VALUE_TYPE.INTEGER">
                <span class="objective">{{ condition.value }} / {{ condition.target }}</span>
              </ng-container>

              <!-- PERCENTAGE -->
              <ng-container *ngSwitchCase="MISSION_CONDITION_VALUE_TYPE.PERCENTAGE">
                <span class="objective">{{ condition.value | percent:'1.2-2'}}
                  / {{ condition.target | percent:'1.2-2' }}
                </span>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>

      <m-ui-separator [title]="'Za realizację misji otrzymasz:'"></m-ui-separator>

      <div class="bottom">
        <div class="mission-prizes">
          <div
            *ngIf="activeMissionDetails.points_prize"
            [matTooltipShowDelay]="400"
            [matTooltip]="'Punkty doświadczenia'"
            class="exp"
          >
            <img [src]="'exp.png' | asset: 'parameters/big'" alt="Punkty doświadczenia">
            <span class="value">{{ activeMissionDetails.points_prize }}</span>
          </div>

          <ng-container *ngIf="activeMissionDetails.currency_prizes.length">
            <m-ui-currency
              *ngFor="let currencyPrize of activeMissionDetails.currency_prizes"
              [customClassStock]="'stock-san-a'"
              [customIconSize]="'big'"
              [item]="currencyPrize"
              [lack]="false"
              [stockView]="STOCK_VIEW.CUSTOM"
            ></m-ui-currency>
          </ng-container>

          <ng-container *ngIf="activeMissionDetails.product_prizes.length">
            <m-ui-product
              *ngFor="let productPrize of activeMissionDetails.product_prizes"
              [customClassStock]="'stock-san-a'"
              [customIconSize]="'big'"
              [item]="productPrize"
              [lack]="false"
              [stockView]="STOCK_VIEW.CUSTOM"
            ></m-ui-product>
          </ng-container>
        </div>

        <div class="buttons">
          <!-- SHOW ON MAP -->
          <button
            (click)="showOnMap(activeMissionDetails['center_map'])"
            *ngIf="activeMissionDetails['center_map_on_tile_id']"
            class="c-base c-secondary"
          >
            {{ 'missions.show-on-map' | myTranslate }}
          </button>

          <button
            (click)="completeStage()"
            [disabled]="!areConditionsComplete"
            class="c-base c-primary loading-opacity"
          >
            Zalicz etap
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="allStarsCollected">
    <div class="success">
      <p>GRATULACJE!</p>

      Ten zestaw wyzwań został pomyślnie ukończony! <br> Jesteś coraz bliżej odkrycia tajemnicy San Tao i tego, by zażegnać problemy okolicznych mieszkańców!
      <br>
    </div>
  </ng-container>

  <ng-container *ngIf="noMissionsForActiveGroup">
    <div class="success">
      <p>Idziesz jak burza!</p>

      Poczekaj na kolejne wyzwania i nie zwalniaj tempa!
    </div>
  </ng-container>
</div>
