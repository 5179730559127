import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AbstractInjectBaseComponent } from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../../../shared/providers/dialog.service';

@Component({
  selector: 'event-shop-alert-confirm',
  templateUrl: './event-shop-alert-confirm.component.html',
})
export class EventShopAlertConfirm extends AbstractInjectBaseComponent implements OnInit {

  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<EventShopAlertConfirm>;
  @OwInject(DialogService) dialogService: DialogService;

  form: FormGroup;

  ngOnInit() {
    this.form = this.fb.group({
      phoneNumber: [
        null,
        [Validators.required,
          Validators.minLength(9),
          Validators.maxLength(9)
        ]
      ]
    });
  }

  submit() {
    this.matDialogRef.close(this.form.value.phoneNumber);
  }
}
