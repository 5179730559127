<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- DIALOG BACK -->
  <m-ui-back-button *ngIf="!temporaryParentTask"></m-ui-back-button>

  <!-- BACK REVERT CHILD -->
  <m-ui-back-button
    *ngIf="temporaryParentTask"
    [preventDefault]="true"
    (clicked)="revertChildTask()"
  ></m-ui-back-button>

  <!-- NAVIGATION CENTER -->
  <m-ui-navigation-center
    *ngIf="!data.isChooseableView && !temporaryParentTask"
    (nextClicked)="next()"
    (prevClicked)="prev()"
    [hideNext]="!pagination.allowNext"
    [hidePrev]="!pagination.allowPrev"
  ></m-ui-navigation-center>

  <ng-container *ngIf="currentTask; else noTaskTpl">
    <div class="dialog-decoration">
      <span class="right"></span>
      <span class="bottom"></span>
    </div>

    <div class="mark-window-left-container">
      <!-- QUALITY -->
      <div class="mark-window-left" *ngIf="currentTask?.is_quality_multiplier">
        <img [src]="'icon_quality.png' | asset:'ui'">
      </div>

      <!-- CUSTOM BY CLASS NAME -->
      <div
        class="mark-window-left custom"
        [ngClass]="currentTask?.className"
      >
        <div class="image"></div>
      </div>
    </div>

    <!-- TITLE -->
    <div class="title">
      {{ currentTask.name }}
    </div>

    <!-- DESCRIPTION -->
    <div
      class="description"
      [innerText]="currentTask.description"
      owEllipsis
    ></div>

    <div class="content loading-opacity">
      <ow-perfect-scrollbar scrollGradient>

        <!-- BRANCH NAME -->
        <div class="branch-name">
          {{ currentTask.branch_name }}
          <ng-container *ngIf="currentTask.nextTask">
            <i (click)="openParentId()" class="fas fa-arrow-circle-right"></i>
          </ng-container>
        </div>

        <!-- PRIZES -->
        <div class="prizes">
          <div class="prize-container" *ngFor="let item of currentTask.scopes | keyvalue">
            <span class="percent">
              {{ currentTask['scope' + item.key] }}%
            </span>
            <div class="frame-white">
              <div class="prize">
                <ng-container *ngFor="let prize of currentTask.scopes[item.key].prizes">
                  <m-ui-currency
                    *ngIf="prize['type'] === 'currency'"
                    [stockView]="STOCK_VIEW.CUSTOM"
                    [item]="prize"
                    [lack]="false"
                    [customClassStock]="'stock-card-details'"
                  ></m-ui-currency>

                  <m-ui-product
                    *ngIf="prize['type'] === 'product'"
                    [stockView]="STOCK_VIEW.CUSTOM"
                    [item]="prize"
                    [lack]="false"
                    [customClassStock]="'stock-card-details'"
                  ></m-ui-product>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <!-- PROGRESS BARS -->
        <!-- PROGRESSBAR PERFORMANCE -->
        <div class="progressbar-container" *ngIf="currentTask.performance_percent != null">
          <div class="box">
            <div class="progressbar-title">
              {{ currentTask.is_task_type_branch ? 'Stopień realizacji zadania w oddziale' : 'Stopień realizacji zadania' }}
            </div>
            <div class="progressbar-value">
              {{ currentTask.performance_percent }}<ng-container *ngIf="currentTask.card_template_id !== CARD_TEMPLATE.HIDE_PERCENTAGE">{{ currentTask.suffix_performance_formatted }}</ng-container>
            </div>
          </div>
          <div class="progress-container-after">
            <div class="progressbar">
              <div
                class="fill fill-color-1"
                [class.ten]="currentTask.performance_percent >= 100 && (!currentTask.is_task_type_branch ? currentTask.min_valid : true)"
                [style.width]="currentTask.performance_percent / 1.163 + '%'"
              ></div>
            </div>
          </div>
        </div>

        <!-- PROGRESSBAR SHARE -->
        <div class="progressbar-container" *ngIf="currentTask.share_percent != null">
          <div class="box">
            <div class="progressbar-title">
              Twój udział w nagrodzie
            </div>
            <div class="progressbar-value">
              {{ currentTask.share_percent }}%
            </div>
          </div>
          <div class="progress-container-after">
            <div class="progressbar">
              <div
                class="fill fill-color-2"
                [style.width]="currentTask.share_percent / 1.163 + '%'"
              ></div>
            </div>
          </div>
        </div>

        <!-- PROGRESSBAR FORECAST -->
        <div class="progressbar-container" *ngIf="currentTask.forecast != null">
          <div class="box">
            <div class="progressbar-title">
              Prognoza Twojego wyniku
            </div>
            <div class="progressbar-value">
              {{ currentTask.forecast }}{{ currentTask.suffix_performance_formatted }}
            </div>
          </div>
          <div class="progress-container-after">
            <div class="progressbar">
              <div
                class="fill fill-color-3"
                [style.width]="currentTask.forecast / 1.163 + '%'"
              ></div>
            </div>
          </div>
        </div>

        <!-- INFO -->
        <div class="info" *ngIf="currentTask.is_task_type_branch">
          Otrzymasz część nagrody dla Oddziału wydzieloną proporcjonalnie do Twojego udziału w wykonaniu zadania.<br/>
          W wyliczeniach nie uwzględnia się produkcji kierownika Oddziału ani kasjerów
        </div>

        <!-- TABLES -->
        <div class="table">
          <table>
            <tbody>
            <tr>
              <td class="title left">WYKONANIE ZADANIA:</td>
            </tr>

            <!-- ADDITIONAL DESCRIPTON -->
            <tr *ngIf="currentTask.additional_description">
              <td>
                {{ currentTask.additional_description }}
              </td>
            </tr>

            <!-- TARGET -->
            <tr
              *ngIf="currentTask.target_name !== indicatorName && currentTask.is_visible_target_formatted !== false"
              class="border-bottom"
            >
              <td>
                {{ currentTask.target_name }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.target }}
              </td>
            </tr>

            <!-- NUMERATOR -->
            <tr *ngIf="currentTask.is_visible_numerator_formatted !== false" class="border-bottom">
              <td>
                {{ currentTask.numerator_name }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.is_task_type_branch ? currentTask.sum_branch : currentTask.numerator }}
              </td>
            </tr>

            <!-- DENOMINATOR -->
            <tr
              *ngIf="currentTask.denominator_name !== indicatorName && currentTask.is_visible_denominator_formatted !== false"
              class="border-bottom"
            >
              <td>
                {{ currentTask.denominator_name }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.denominator }}
              </td>
            </tr>

            <!-- SUMMARY -->
            <tr class="summary">
              <td>
                {{ currentTask.is_task_type_branch ? 'Wynik oddziału:' : 'Twój wynik:' }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.performance_percent_formatted }}
              </td>
            </tr>
            </tbody>
          </table>

          <!-- CHILD TASKS -->
          <div *ngIf="currentTask['child_tasks'] && currentTask['child_tasks'].length">
            <table>
              <tbody>
              <tr>
                <td class="title left">ZADANIA POŁĄCZONE:</td>
              </tr>

              <ng-container *ngFor="let task of currentTask['child_tasks']">
                <tr class="border-bottom">
                  <!-- NAME -->
                  <td>
                    {{ task.name }}
                  </td>

                  <!-- CHANGE TASK TO CHILD TASK -->
                  <td
                    class="text-right no-wrap pointer text-bold"
                    (click)="changeChildTask(task)"
                  >
                    więcej <i class="far fa-long-arrow-alt-right"></i>
                  </td>
                </tr>

                <!-- PERFORMANCE -->
                <tr>
                  <td>
                    {{ task.performance_percent }}%
                  </td>
                  <td></td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>

          <table *ngIf="currentTask.is_task_type_branch">
            <tbody>
            <tr>
              <td class="title">UDZIAŁ W NAGRODZIE</td>
            </tr>
            <tr class="border-bottom">
              <td>
                Twoja sprzedaż:
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.numerator }}
              </td>
            </tr>
            <tr>
              <td>
                Sprzedaż wszystkich doradców w oddziale:
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.sum_branch_without_leader }}
              </td>
            </tr>
            </tbody>
          </table>

          <!-- TABLE ONLY SHOW MIN -->
          <table *ngIf="currentTask.is_showing_mins">
            <tbody>
            <tr>
              <td class="title left">Próg minimalny:</td>
            </tr>
            <!-- MIN 1 -->
            <tr *ngIf="currentTask.min1_name" class="border-bottom">
              <td>
                {{ currentTask.min1_name }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.min1_value_formatted }}
              </td>
            </tr>

            <!-- MIN 2 -->
            <tr *ngIf="currentTask.min2_name" class="border-bottom">
              <td>
                {{ currentTask.min2_name }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.min2_value_formatted }}
              </td>
            </tr>

            <!-- MIN 3 -->
            <tr *ngIf="currentTask.min3_name" class="border-bottom">
              <td>
                {{ currentTask.min3_name }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.min3_value_formatted }}
              </td>
            </tr>

            <!-- MAX 1 -->
            <tr *ngIf="currentTask.max1_name" class="border-bottom">
              <td>
                {{ currentTask.max1_name }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.max1_value_formatted }}
              </td>
            </tr>

            <!-- MIN VALID -->
            <tr>
              <td>
                {{ currentTask.min2_name ? 'Minima spełnione' : 'Minimum spełnione:' }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.min_valid ? 'Tak' : 'Nie' }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </ow-perfect-scrollbar>
    </div>
  </ng-container>

  <!-- CLEARED -->
  <div class="cleared" *ngIf="currentTask?.is_cleared"></div>

  <!-- WF STICKER -->
  <div class="wf-sticker" *ngIf="currentTask?.card_template_id === CARD_TEMPLATE.WF"></div>

  <ng-template #noTaskTpl>
    <div class="sanjungle-logo"></div>
  </ng-template>

  <app-card-button-choose-cc
    *ngIf="(currentTask?.position || data.position) && (currentTask?.is_choosable || data.isChooseableView)"
    [task]="data.isChooseableView ? currentTask : null"
    [allTasks]="data.tasks"
    [currentIndex]="currentIndex"
    [owDate]="data.owDate"
    [position]="currentTask?.position || data.position"
  ></app-card-button-choose-cc>

  <div class="description-2" *ngIf="!data.isChooseableView && !currentTask?.position">
    Jeżeli chcesz wybrać to zadanie to przejdź do zadania już wybranego i kliknij w przycisk <b>Zobacz inne</b>. Po
    kliknięciu otworzy się lista możliwych do wyboru zadań. Wejdź w zadanie, które chcesz wybrać i kliknij <b>Wybierz
    zadanie</b>.
  </div>
</div>
