<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="close()"
  ></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button
    *ngIf="data['boardTile']"
  ></m-ui-back-button>

  <ng-container *ngIf="buildingDetails">
    <div class="bookmarks">
      <div class="overlap active green">
        <div class="bookmark green">
          <span>{{ buildingDetails.name }}</span>
        </div>
      </div>
    </div>

    <div class="top loading-opacity">
      <div class="icon-container">
        <!-- BUILDING ICON -->
        <img [src]="buildingDetails | buildingIcon:false">
      </div>

      <div class="right-column">
        <div
          class="description"
          [innerHTML]="buildingDetails.upgrade_building_id ? buildingDetails.group_description : buildingDetails.additional_details | safe:'html'"
        ></div>

        <div class="elements not-show">
          <div class="min-title">Otrzymasz:</div>

          <ng-container *ngIf="upgradeBuildingDetails; else maxBuildingLvlTpl">
            <app-unlocked-parameters-upgrade
              *ngIf="notOutcomeParametersDiffParameters"
              [parameters]="notOutcomeParametersDiffParameters"
              [hideSubTitle]="true"
            ></app-unlocked-parameters-upgrade>

            <app-building-return
              *ngIf="upgradeBuildingDetails.build_currency_prizes.length || upgradeBuildingDetails.build_product_prizes.length"
              [returnCurrencies]="upgradeBuildingDetails.build_currency_prizes"
              [returnProducts]="upgradeBuildingDetails.build_product_prizes"
              [hideSubTitle]="true"
            ></app-building-return>
          </ng-container>

          <ng-template #maxBuildingLvlTpl>
            <div class="parameters-container">
              <div class="sub-title">Parametry:</div>

              <m-ui-parameter
                *ngFor="let parameter of buildingDetails.parameters"
                [item]="parameter"
                [other]="{value: '', amount: parameter.income}"
                [stockView]="STOCK_VIEW.CUSTOM"
                [customIconSize]="'big'"
                [customClassStock]="'stock-san-a'"
                [lack]="false"
              ></m-ui-parameter>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <div
      *ngIf="upgradeBuildingDetails"
      class="bottom loading-opacity"
    >
      <m-ui-separator title="Wymagania"></m-ui-separator>

      <!-- REQUIREMENTS -->
      <app-building-requirements
        *ngIf="upgradeBuildingDetails && outcomeParametersRequirements"
        [building]="upgradeBuildingDetails"
        [products]="upgradeBuildingDetails.build_product_prices"
        [currencies]="upgradeBuildingDetails['build_prices']"
        [population]="upgradeBuildingDetails.required_population"
        [requiredBuilding]="upgradeBuildingDetails.required_building"
        [parametersOutcome]="outcomeParametersRequirements"
        (requirementsStatus)="setRequirementsStatus($event)"
      ></app-building-requirements>

      <div
        class="buttons"
        *ngIf="upgradeBuildingDetails && requirementsStatus"
      >
        <!-- BUILD -->
        <div
          [ngbTooltip]="requirementsStatus['textErrors'].length && tooltipRequirementsTpl"
          placement="bottom"
          container="body"
        >
          <button
            (click)="upgrade()"
            class="c-base c-secondary"
            [disabled]="!requirementsStatus.valid"
          >
            {{ upgradeBuildingDetails.upgrade_button_name || 'Rozbuduj' }}&nbsp;
            <!-- TIME IN SECONDS -->
            <span class="text-normal">
              <app-core-building-time [building]="upgradeBuildingDetails"></app-core-building-time>
            </span>
          </button>
        </div>

        <!-- FAST ACTION -->
        <div
          [ngbTooltip]="requirementsStatus['textErrors'].length && tooltipRequirementsTpl"
          placement="bottom"
          container="body"
        >
          <button
            *ngIf="upgradeBuildingDetails.fast_build_currency_prices.length || upgradeBuildingDetails.fast_build_product_prices.length"
            [matTooltip]="'Akcja błyskawiczna'"
            class="c-base c-primary"
            (click)="openFastSpecialUpgradeConfirm()"
            [disabled]="!requirementsStatus.valid"
          >
            Błyskawiczna akcja
          </button>
        </div>

        <!-- TOOLTIP REQUIREMENTS TPL -->
        <ng-template #tooltipRequirementsTpl>
          <div *ngFor="let textError of requirementsStatus['textErrors']">
            {{ textError }}
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>
</div>
