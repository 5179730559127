<div class="global-dialog ow-dialog c-window-long warehouse-container">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- CATEGORIES -->
  <div class="tabs" *ngIf="storages.length > 0">
    <ng-container *ngFor="let storage of storages">
      <div
        class="tab"
        *ngFor="let category of storage.categories; let idx = index"
        [class.active]="category == activeCategory"
        [style.z-index]="-idx"
        [class]="category.color"
      >
        <div class="bookmarks">
          <div
            class="bookmark"
            [class]="category.color"
            (click)="changeCategory(category)"
          >
         <span [class.new-products]="category.hasNewProducts">
          {{ category.name }}
        </span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="frame loading-opacity">
    <ng-container *ngIf="loaded && !loading">
      <ow-perfect-scrollbar *ngIf="productsSlider.items.length; else fallbackScreen"
                         [scrollGradient]="productsSlider.items.length" >
        <div class="products-container">
          <div class="products-list">
            <div
              *ngFor="let item of productsSlider.items"
              [class.active]="item.product_id"
              (click)="openTradeDialog(item)"
              class="product"
            >
              <ng-container *ngIf="item.product_id">
                <!-- PRODUCT ICON -->
                <div class="icon">
                  <img class="product-icon" [src]="item.iconUrlBig"/>
                </div>
                <!-- PRODUCT NAME -->
                <div class="name">
                  {{ item.name }}
                </div>

                <!-- PRODUCT BALANCE -->
                <div class="balance">
                  {{ item.balance | NumberWithSpaces }}
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ow-perfect-scrollbar>
    </ng-container>
  </div>
</div>


<ng-template #fallbackScreen>
  <ng-container *ngIf="fallbackMockups[this.data.storageId] as fb">
    <div class="fallback-screen" *ngIf="fb?.enabled">
      <img [src]="fb.icon | asset: fb.path" />
      <h3 *ngIf="fb?.title">{{fb?.title}}</h3>
      <p *ngIf="fb?.description">{{fb?.description}}</p>
    </div>
  </ng-container>
</ng-template>
