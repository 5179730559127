import { GAME_EVENTS } from "../../../constants";
import {getAdditionalBoardData, isProduction} from '../../../game-gui/helpers/buildings.helper';
import {
  checkActiveFnByGroupName,
  checkHireButtonActive,
} from "../../../game-gui/helpers/custom-buildings.helper";
import { ADDITIONAL_BOARD_DATA } from "../../config/additional-board-data.config";
import { CUSTOM_ADDITIONAL_BOARD_DATA } from "../../config/custom-additional-board-data.config";
import { TileMenuButtonConfig } from "../../interfaces/tile-menu-config.interface";

export const CUSTOM_BUTTONS_CONFIG: {
  [buttonName: string]: TileMenuButtonConfig
} = {
  employment: {
    x: 0,
    y: -135,
    name: "tile-menu.employment",
    frame: "employment.png",
    hoverFrame: "employment_h.png",
    activeFrame: "employment_a.png",
    eventName: GAME_EVENTS.OPEN_FUNCTIONAL,
    activeCheckFn: function () {
      return checkHireButtonActive(this);
    },
  },
  autoProduction: {
    x: 0,
    y: -135,
    name: "tile-menu.auto-production",
    frame: "auto.png",
    hoverFrame: "auto_h.png",
    activeFrame: "auto_a.png",
    eventName: GAME_EVENTS.BUILDING_AUTO_PRODUCTION,
    activeCheckFn: function () {
      return false;
    },
  },
  // production: {
  //   x: 0,
  //   y: -135,
  //   name: "tile-menu.production",
  //   nameFn: function () {
  //     return this.building.group === CUSTOM_ADDITIONAL_BOARD_DATA.HELL_PAD_TRANSPARENT ||
  //     this.building.group === CUSTOM_ADDITIONAL_BOARD_DATA.HELL_PAD
  //       ? "tile-menu.order-prod"
  //       : "tile-menu.production";
  //   },
  //   frame: "production.png",
  //   hoverFrame: "production_h.png",
  //   activeFrame: "production_a.png",
  //   eventName: GAME_EVENTS.BUILDING_PRODUCTION,
  //   activeCheckFn: function () {
  //     const isActive = isProduction(this.building.group_type) && !this.tileMenu.targetTile.tileData.open_player_island_id;
  //     this.hasProduction = this.isActive;
  //     return isActive;
  //   },
  // },
  storage: {
    x: 0,
    y: -135,
    name: "tile-menu.storage",
    frame: "warehouse.png",
    hoverFrame: "warehouse_h.png",
    activeFrame: "warehouse_a.png",
    eventName: GAME_EVENTS.OPEN_FUNCTIONAL,
    activeCheckFn: function () {
      const storageId = getAdditionalBoardData(ADDITIONAL_BOARD_DATA.OPEN_STORAGE_ID, this.building);
      return storageId && storageId !== 6;
    },
  },
  storageAnimal: {
    x: 0,
    y: -135,
    name: "tile-menu.team",
    frame: "garage.png",
    hoverFrame: "garage_h.png",
    activeFrame: "garage_a.png",
    eventName: GAME_EVENTS.OPEN_TEAM,
    activeCheckFn: function () {
      return this.building.group === 'rescue'
    },
  },
  games: {
    x: 0,
    y: -135,
    name: "tile-menu.games",
    frame: "games.png",
    hoverFrame: "games_h.png",
    activeFrame: "games_a.png",
    eventName: GAME_EVENTS.OPEN_FUNCTIONAL,
    activeCheckFn: function () {
      return checkActiveFnByGroupName(ADDITIONAL_BOARD_DATA.OPEN_MINIGAMES, this.building);
    },
  },
  production: {
    x: 0,
    y: -135,
    name: "tile-menu.production",
    frame: "production.png",
    hoverFrame: "production_h.png",
    activeFrame: "production_a.png",
    eventName: GAME_EVENTS.BUILDING_PRODUCTION,
    activeCheckFn: function () {
      const isActive = isProduction(this.building.group_type);
      this.hasProduction = this.isActive;
      return isActive && !this.building.group.includes('dodo_foundation');
    },
  },
  productionCharityDodo: {
    x: 0,
    y: -135,
    name: "tile-menu.dodo",
    frame: "star.png",
    hoverFrame: "star_h.png",
    activeFrame: "star_a.png",
    eventName: GAME_EVENTS.BUILDING_CHARITY_DODO,
    activeCheckFn: function () {
      const isActive = isProduction(this.building.group_type);
      this.hasProduction = this.isActive;
      return isActive && this.building.group.includes('dodo_foundation');
    },
  },
};
