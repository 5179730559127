export const swiperInjectionStyles = `
          swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
          .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
            bottom: 65px;
            position: absolute;
            transform: translate(-50%, -50%);
          }
          
          .swiper-pagination-bullet {
            background-color: #ebe5d5;
            border-radius: calc(50px * var(--multiply));
            transition: .3s;
            transform: scale(0);
            transform-origin: 50% 50%;
            box-sizing: border-box;
            opacity: 1;
            height: 10px;
            margin: 0 calc(1px * var(--multiply));
            width: 10px;
            border: 1.5px solid #000000;
      
            &.swiper-pagination-bullet-active-prev-prev {
              transform: scale(0.4);
            }
      
            &.swiper-pagination-bullet-active-prev {
              transform: scale(0.7);
            }
      
            &.swiper-pagination-bullet-active {
              &.swiper-pagination-bullet-active-main {
                transform: scale(1);
                background-color: #6699CC;
              }
            }
      
            &.swiper-pagination-bullet-active-next {
              transform: scale(0.7);
            }
      
            &.swiper-pagination-bullet-active-next-next {
              transform: scale(0.4);
            }
          }
      `;

export const swiperWfInjectionStyles = `
          swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
          .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
            bottom: 65px;
            position: absolute;
            transform: translate(-50%, -50%);
          }
          
          .swiper-pagination-bullet {
            background-color: #ebe5d5;
            border-radius: calc(50px * var(--multiply));
            transition: .3s;
            transform: scale(0);
            transform-origin: 50% 50%;
            box-sizing: border-box;
            opacity: 1;
            height: 10px;
            margin: 0 calc(1px * var(--multiply));
            width: 10px;
            border: 1.5px solid #000000;
      
            &.swiper-pagination-bullet-active-prev-prev {
              transform: scale(0.4);
            }
      
            &.swiper-pagination-bullet-active-prev {
              transform: scale(0.7);
            }
      
            &.swiper-pagination-bullet-active {
              &.swiper-pagination-bullet-active-main {
                transform: scale(1);
                background-color: #6699CC;
              }
            }
      
            &.swiper-pagination-bullet-active-next {
              transform: scale(0.7);
            }
      
            &.swiper-pagination-bullet-active-next-next {
              transform: scale(0.4);
            }
          }
          
          ::slotted(swiper-slide) {
            max-width: 44px;
            min-width: 44px;
            height: 100%;
          }
          
          ::slotted(swiper-slide:last-child) {
            margin-right: 0 !important;
          }
      `;
