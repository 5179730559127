<div
  *ngIf="parameterBalances && player.title_id.playable"
  class="parameters-container"
>
  <ng-container *ngFor="let parameter of filteredParameters">
    <div
      class="parameter-block"
      container="body"
      [ngbTooltip]="tooltipTpl"
      placement="bottom-right"
      tooltipClass="hud hud-parameters delayed"
    >
      <!-- PARAMETER -->
      <div
        class="icon-parameter"
        [style.background-image]="'var(--asset--parameters-hud-' + parameter.key + '-png)'"
      ></div>

      <div class="number">
        {{ parameter.income || 0 | NumberWithSpaces }}<ng-container *ngIf="!NO_OUTCOME_LIST.includes(parameter.parameter_id)">/{{ parameter.outcome || 0 | NumberWithSpaces }}</ng-container>
      </div>
    </div>

    <ng-template #tooltipTpl>
      <div [innerHTML]="parameter.tooltip"></div>
    </ng-template>
  </ng-container>
</div>
