import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { SharedUiMobileModule } from "../../game/game-ui/shared-ui/mobile/shared-ui-mobile.module";
import { SharedModule } from "../../shared/shared.module";
import { Match3MinigameComponent } from "./components/match3-minigame/match3-minigame.component";
import { NoNegativePipe } from "./no-negative.pipe";

@NgModule({
  declarations: [Match3MinigameComponent, NoNegativePipe],
  imports: [CommonModule, SharedModule, RouterModule, SharedUiMobileModule],
  exports: [Match3MinigameComponent],
})
export class Match3MinigameModule {}
