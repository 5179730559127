<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Rankingi</span>
      </div>
    </div>
  </div>

  <!-- NAVIGATE CONTAINER -->
  <div class="navigate-container">
    <!-- NAVIGATION MONTH -->
    <div class="navigate-date" *ngIf="dates.length">
      <!-- PREV -->
      <button
        class="prev"
        (click)="changeDate(currentIndex - 1)"
        [disabled]="currentIndex === 0"
      >
        <i class="fas fa-angle-left"></i>
      </button>

      <!-- CURRENT -->
      <div class="current">
        {{ currentDate.date_start | date:'dd-MM-y' }} - {{ currentDate.date_end | date:'dd-MM-y' }}
      </div>

      <!-- NEXT -->
      <button
        class="next"
        (click)="changeDate(currentIndex + 1)"
        [disabled]="currentIndex === this.dates.length - 1"
      >
        <i class="fas fa-angle-right"></i>
      </button>
    </div>
  </div>

  <!-- FRAME CONTENT -->
  <div class="frame loading-opacity">
    <div class="content">
      <div class="options">
        <!-- FILTERS -->
        <div class="filters">
          <ng-container *ngFor="let filter of filters">
            <div class="ow-radio-container" *ngIf="filter.show">
              <label class="choose-wrapper">
                  <span class="checkbox-wrapper">
                    <input
                      type="radio"
                      name="ranksFilter"
                      (change)="changeFilter(filter)"
                      [checked]="activeFilter.type == filter.type"
                    >
                    <span class="checkmark"></span>
                  </span>
                <span class="text-wrapper">
                    {{ filter.label }}
                  </span>
              </label>
            </div>
          </ng-container>
        </div>
      </div>

      <ow-perfect-scrollbar scrollGradient>
        <!-- GAME RANKS -->
        <table class="game">
          <tbody>
          <ng-container *ngFor="let rank of data.ranks">
            <tr
              *ngIf="rank['type'] === activeFilter.type && (rank.date_start === currentDate.date_start && rank.date_end === currentDate.date_end)">
              <td (click)="openClRankDetails(rank)">
                {{ rank.name }}
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </ow-perfect-scrollbar>
    </div>
  </div>
</div>
