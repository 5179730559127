import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";

import { SharedUiMobileModule } from "../game/game-ui/shared-ui/mobile/shared-ui-mobile.module";
import { SharedModule } from "../shared/shared.module";
import { MinigameCatchBananasComponent } from "./catch-bananas/components/minigame-catch-bananas/minigame-catch-bananas.component";
import { MinigameCatchBananasGuiComponent } from "./catch-bananas/components/minigame-catch-bananas-gui/minigame-catch-bananas-gui.component";
import { MinigamesComponent } from "./components/minigames/minigames.component";
import { MinigameRankDetailsComponent } from "./dialogs/minigame-rank-details/minigame-rank-details.component";
import { Match3MinigameModule } from "./match3/match3-minigame.module";
import { SortByOrderPipe } from "./pipes/sort";
import { MinigameRiverRideComponent } from "./riverride/components/minigame-river-ride/minigame-river-ride.component";
import { MinigameRiverRideGuiComponent } from "./riverride/components/minigame-river-ride-gui/minigame-river-ride-gui.component";
import { RRConfigEditorModule } from "./riverride/rrconfig-editor/rrconfig-editor.module";
import { Match3Service } from "./match3/match3.service";

@NgModule({
  declarations: [
    MinigameRiverRideComponent,
    MinigamesComponent,
    MinigameRiverRideGuiComponent,
    MinigameCatchBananasGuiComponent,
    MinigameCatchBananasComponent,
    MinigameRankDetailsComponent,
    SortByOrderPipe,
  ],
  imports: [
    CommonModule,
    RRConfigEditorModule,
    SharedUiMobileModule,

    SharedModule,
    FormsModule,
    BrowserModule,
    Match3MinigameModule,
  ],
  exports: [MinigamesComponent],
  providers: [Match3Service]
})
export class MinigamesModule {}
