<div class="ow-range-slider">
  <input type="range"
         [disabled]="disabled"
         [min]="min"
         [max]="max"
         [step]="step"
         [value]="value"
         (change)="emitChange($event)"
         (input)="emitInput($event)" />
</div>
