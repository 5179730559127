import { Component, OnInit } from "@angular/core";

import { Currency } from "../../../../../../../../core/interfaces/currency";
import { ProductDetailsBalance } from "../../../../../../../player/interfaces/product.interface";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { AbstractMessageDetailsHiddenRewardComponent } from "../../../../abstract/core/abstract-message-details-hidden-reward.component";
import { MessageDetails } from "../../../../interfaces/core/message-details.interface";
import {OwInject} from '../../../../../../../../core/decorators/ow-inject.decorator';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MessageOpenDetailsData} from '../../../../interfaces/core/message-open-details-data.interface';
import {GlobalEvent} from '../../../../../../../../core/interfaces/shared.interfaces';

@Component({
  selector: "app-message-details-info-problem",
  templateUrl: "./message-details-info-problem.component.html",
})
export class MessageDetailsInfoProblemComponent extends AbstractMessageDetailsHiddenRewardComponent implements OnInit {
  @OwInject(MAT_DIALOG_DATA) messageOpenDetailsData: any;
  STOCK_VIEW = STOCK_VIEW;
  icon = "test_01.png";
  path = "trivia/big";
  message: MessageDetailsAnimalReward;
  showBackButton = false;
  preventCloseAllOnDialogExit = true;

  ngOnInit() {
    console.log(this.messageOpenDetailsData);
    if (this.messageOpenDetailsData?.name === "BUILDING_INFO_PROBLEM") {
      this.message = {
        category_name: '',
        created_at: '',
        deletable: false,
        deleted: false,
        description: this.messageOpenDetailsData.value.description,
        generic_type: null,
        gallery: null,
        mobile_gallery: null,
        player_message_id: 0,
        qa_info: undefined,
        read: false,
        seen: false,
        sender_icon: '',
        sender_name: this.messageOpenDetailsData.value.sender_name,
        teaser: '',
        tip: '',
        title: this.messageOpenDetailsData.value.title,
        type: 0,
        exp_points: this.messageOpenDetailsData.value?.['exp'],
        parameters: {
          prizes: [],
          currencies: [],
          message: {
            title: this.messageOpenDetailsData.value.title,
            description: this.messageOpenDetailsData.value.description,
          },
          animal: {
            icon: this.messageOpenDetailsData.value.image,
            name: this.messageOpenDetailsData.value.title,
          }
        },
      }
      this.icon = this.message.parameters.animal?.icon ?? '';
    } else {
      this.showBackButton = true;
      if (this.message.parameters.prizes) {
        this.getProducts();
      }
      this.setMessageProperty();
      this.afterInit();
    }
  }

  setMessageProperty() {
    if (this.message.parameters) {
      /**
       * ICON
       */
      const icon = this.message.parameters.animal?.icon;
      if (icon) {
        this.icon = icon;
      }
      // /**
      //  * EXPERIENCE
      //  */
      // const experience = this.message.parameters?.["exp_points"];
      // if (experience) {
      //   this.message.exp_points = experience;
      // }
    }
  }

  async closeOrCloseAll() {
    // opened from the building
    if (!this.showBackButton) {
      this.matDialogRef.close(this.closeEvent);
      return;
    }

    // opened from the message
    if (this.messageOpenDetailsData.fromList) {
      this.dialogService.closeAll(true);
    } else {
      await this.close();
    }
  }
}

export interface MessageDetailsAnimalReward extends MessageDetails {
  parameters: {
    prizes: ProductDetailsBalance[];
    currencies: Currency[];
    message: {
      title?: string;
      description?: string;
      icon?: string;
    };
    animal?: {
      icon?: string;
      name?: string;
    };
  };
  exp_points?: number,
}
