<!-- REQUIRED TEXT -->
<div class="texts-container" *ngIf="!hideTxtRequired">
  <ng-container *ngIf="textsList.length">
    <span
      *ngFor="let item of textsList;
      let last = last"
      [class.text-error]="!item.valid"
    >
      {{ item.text }}{{ !last ? ', ' : ''}}
    </span>
  </ng-container>
  &nbsp;
</div>

<div class="flex-center">
  <!-- PARAMETERS OUTCOME -->
  <div
    *ngIf="parametersOutcomeFormatted"
    class="parameters-employee-costs"
  >
    <m-ui-parameter
      *ngFor="let parameter of parametersOutcomeFormatted"
      [item]="parameter"
      [stockView]="STOCK_VIEW.CUSTOM"
      [customClassStock]="'stock-san-a'"
      [lack]="true"
    ></m-ui-parameter>
  </div>

  <!-- CURRENCIES OR PRODUCTS -->
  <ng-container *ngIf="currencies || products">
    <m-ui-costs
      [currencies]="currencies"
      [products]="products"
      (eventEmitter)="checkCompleted($event)"
      [stockView]="STOCK_VIEW.CUSTOM"
      [customClassStock]="'stock-san-a'"
    ></m-ui-costs>
  </ng-container>
</div>
