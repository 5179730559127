<div class="global-dialog c-window-long">
  <loading [classNames]="['white', 'stroke']" [size]="'big'"></loading>
  <ng-container *ngIf="!isLoading && groupMissions.length">
    <m-ui-close-button *ngIf="!isLoading"> </m-ui-close-button>
    <m-ui-navigation-center
      *ngIf="groupMissions?.length>2 && swiper"
      (prevClicked)="prevSlide()"
      (nextClicked)="nextSlide()"
      [hideNext]="swiper?.isEnd"
      [hidePrev]="swiper?.isBeginning"
    >
    </m-ui-navigation-center>
    <swiper-container *ngIf="initSwiper" #swiperRef swiperElement [config]="swiperConfig" init="false">
      <swiper-slide *ngFor="let mission of groupMissions; let idx = index">
        <div class="slide">
          <div class="mission" (click)="openMissionDetails(mission)" >
            <div class="foto" [style.background-image]="'url(assets-gfx/missions/basic/'+mission.icon + '.png)' "></div>
            <div class="title">{{mission.name}}</div>
  <!--          <div *ngIf="mission.completed_missions" class="badge">Sukces!</div>-->
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
  </ng-container>
</div>
