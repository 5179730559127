import {Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from "moment";
import * as R from "ramda";
import { PlayerService } from "src/app/modules/player/providers/player.service";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { SynchronizeTimeService } from "../../../../../../../../core/providers/synchronize-time.service";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { ApiMediaService } from "../../../../../hud/api/custom/services/api-media.service";
import { ApiAuctionsService } from "../../../../api/custom/api-auctions.service";
import { STATUS, TAB_ID } from "../../../../consts/custom/auction.const";
import { EVENT_DIALOGS_NAMES_AUCTIONS_CUSTOM } from "../../../../consts/custom/event-dialogs/event-names.const";
import { AuctionObj, MediaObj, TabAuction } from "../../../../interfaces/custom/auction.interface";

@Component({
  selector: "app-auctions",
  templateUrl: "./auctions.component.html",
})
export class AuctionsComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(ApiAuctionsService) apiAuctionsService: ApiAuctionsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ApiMediaService) apiMediaService: ApiMediaService;
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;

  tabs: TabAuction[] = [
    { id: STATUS.ACTIVE, label: "Aukcje dostępne", status: "active", color: "green", disabledTooltip: null },
    { id: STATUS.FINISHED, label: "Aukcje zakończone", status: "ended", color: "yellow", disabledTooltip: null },
    { id: 3, label: "Moje aukcje", status: "player", color: "blue-light", disabledTooltip: null },
    { id: 4, label: "Info", status: "info", color: "red", disabledTooltip: null },
  ];

  activeTab: TabAuction;
  TAB_ID = TAB_ID;
  isLoading = false;

  auctions: AuctionObj = {
    items: [],
    activeFilter: null,
    objectFilters: [
      { label: "Wszystkie", id: 1 },
      { label: "W trakcie", id: 2 },
      { label: "Wygrane", id: 3 },
      { label: "Niewygrane", id: 4 },
    ],
  };

  media: MediaObj = {
    sourceId: 5,
    currentFilterId: 0,
    currentPage: 1,
    maxPages: 1,
    items: [],
  };

  ngOnInit() {
    this.changeTab(this.tabs[0]);
  }

  changeTab(tab: TabAuction) {
    this.isLoading = true;
    this.activeTab = tab;
    this.clearData();

    switch (this.activeTab.id) {
      case 3:
        this.auctions.activeFilter = this.auctions.objectFilters[0];
        this.getAuctions(this.auctions.activeFilter);
        break;

      case 4:
        this.getMediaList();
        break;

      default:
        this.getAuctions();
        break;
    }
  }

  clearData() {
    this.media.items = [];
    this.media.currentPage = 1;
    this.media.maxPages = 1;
    this.auctions.items = [];
    this.auctions.activeFilter = null;
  }

  //**************
  // AUCTIONS
  //**************
  getAuctions(event?) {
    this.isLoading = true;
    this.auctions.items = [];

    this.apiAuctionsService.getApiAuctions().subscribe({
      next: resp => {
        const myResp = this.setTimer(R.clone(resp));

        if (myResp) {
          if (!event) {
            this.auctions.items =
              this.activeTab.id == TAB_ID.AVAILABLE || this.activeTab.id == TAB_ID.ENDED
                ? myResp.filter(a => a.status.value == this.activeTab.id)
                : this.activeTab.id == TAB_ID.PLAYER
                ? myResp.filter(a => a.my_bid)
                : myResp;
          } else {
            this.getFilteredAuctions(myResp, event);
          }

          this.isLoading = false;
        }
      },
      error: () => (this.isLoading = false),
    });
  }

  getFilteredAuctions(resp, event) {
    switch (event.id) {
      case 1:
        this.auctions.items = resp.filter(a => a.my_bid);
        this.auctions.items.sort((a, b) => a.status.value - b.status.value);
        break;

      case 2:
        this.auctions.items = resp.filter(a => a.my_bid && a.status.value == STATUS.ACTIVE);
        break;

      case 3:
        this.auctions.items = resp.filter(a => a.my_bid && a.status.value == STATUS.FINISHED && a.my_bid.has_won);
        break;

      case 4:
        this.auctions.items = resp.filter(a => a.my_bid && a.status.value == STATUS.FINISHED && !a.my_bid.has_won);
        break;
    }
  }

  setTimer(arr) {
    arr.forEach(auction => {
      if (auction.ends_at) {
        const actualDate = this.synchronizeTimeService.getActualLocalTime();
        const endedDate = moment(auction.ends_at);

        const timeDiff = moment(endedDate).diff(moment(actualDate), "seconds");

        if (timeDiff <= 0) {
          auction.timer = null;
          return;
        }

        const days = moment(endedDate).diff(moment(actualDate), "days");
        const hours = moment(endedDate).diff(moment(actualDate), "hours");
        const minutes = moment(endedDate).diff(moment(actualDate), "minutes");
        const seconds = moment(endedDate).diff(moment(actualDate), "seconds");

        if (days > 0) {
          auction.timer = days + (days === 1 ? " dzień" : " dni");
        } else if (hours > 0) {
          auction.timer = hours + " godz.";
          auction.timerSoonEnd = true;
        } else if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
          auction.timer = null;
        } else {
          auction.isTimeIntSet = true;
          auction.timerSoonEnd = true;

          auction.intervalTimer = setInterval(() => {
            const testDate = this.synchronizeTimeService.getActualLocalTime();
            auction.timer = moment(endedDate).diff(moment(testDate), "seconds");

            if (auction.timer <= 0) {
              this.clearAuctionInterval(auction);
              auction.status = {
                name: "FINISHED",
                value: 2,
              };
              auction.timer = null;
              auction.isTimeIntSet = false;
              auction.timerSoonEnd = false;
              this.filterByDate();
            }
          }, 1000);
        }
      } else {
        auction.timer = null;
      }
    });

    return arr;
  }

  filterByDate() {
    const actualDate = this.synchronizeTimeService.getActualLocalTime();
    const arr = [];

    for (const item of this.auctions.items) {
      const endedDate = moment(item.ends_at);

      this.clearAuctionInterval(item);

      if (this.activeTab.id === STATUS.ACTIVE && moment(endedDate).diff(moment(actualDate), "seconds") > 0) {
        arr.push(item);
      } else if (this.activeTab.id !== STATUS.ACTIVE) {
        arr.push(item);
      }
    }

    this.auctions.items = JSON.parse(JSON.stringify(arr));
    this.setTimer(this.auctions.items);
  }

  openAuctionDetails(auction) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_AUCTIONS_CUSTOM.DETAILS,
      config: {
        data: {
          auctionId: auction.auction_id,
          currencyId: auction.currency_id,
        },
      },
      callback: event => {
        if (event) {
          this.getAuctions();
        }
      },
    });
  }

  //**************
  // MEDIA
  //**************
  getMediaList() {
    this.isLoading = true;

    this.apiMediaService
      .getMediaList({
        page: this.media.currentPage,
        limit: 5,
        category_id: this.media.currentFilterId,
        source_id: this.media.sourceId,
      })
      .subscribe(({ headers, body }) => {
        this.media.maxPages = headers.get("X-Pages-Count");
        this.media.items = body;
        this.isLoading = false;
      });
  }

  nextPage() {
    if (this.media.currentPage < this.media.maxPages) {
      this.media.currentPage++;
      this.getMediaList();
    }
  }

  previousPage() {
    if (this.media.currentPage > 1) {
      this.media.currentPage--;
      this.getMediaList();
    }
  }

  changeFilter() {
    this.prepare();
    this.getMediaList();
  }

  prepare() {
    this.media.currentPage = 1;
  }

  clearAuctionInterval(auction) {
    if (auction.intervalTimer) {
      clearInterval(auction.intervalTimer);
    }
  }

  ngOnDestroy() {
    for (let item of this.auctions.items) {
      if (item.intervalTimer) {
        clearInterval(item.intervalTimer);
      }
    }
  }
}
