<div class="main-container loading-opacity" [class.media]="!isLibrary">
  <div>
    <div class="type-select" *ngIf="filters">
      <ng-select
        [items]="filters"
        [bindLabel]="'name'"
        [(ngModel)]="currentFilter"
        (change)="changeFilter()"
        [clearable]="false"
        [searchable]="false"
      ></ng-select>
    </div>

    <div class="media-list">
      <perfect-scrollbar [scrollGradient]="items" *ngIf="items?.length; else noItemTpl">
        <div
          class="media-row"
          *ngFor="let item of items"
        >
          <div class="left">
            <div class="icon" [style.background-image]="'url(' + item.thumb + ')'">
            </div>
          </div>
          <div class="right">
            <div class="heading">
              <div class="title">
                <a href="{{ item.url }}" target="_blank">
                  {{ item.name }}
                </a>
              </div>
              <div class="description">
                {{ item.description }}
              </div>
            </div>
            <div class="terms">
              {{ item.category.name }}
              <ng-container *ngIf="isLibrary"><br/></ng-container>
              <ng-container *ngIf="!isLibrary"> | </ng-container>
              {{ item.created_at | date:'y-MM-dd' }}
            </div>
          </div>
        </div>
      </perfect-scrollbar>

      <ng-template #noItemTpl>
        <div class="media-row">
          <div class="no-item">
            Brak danych
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <div
    *ngIf="isLibrary && maxPages > 1"
    class="pagination"
  >
    <!-- PREV -->
    <button
      (click)="previousPage()"
      [disabled]="currentPage === 1"
    >
      <i class="far fa-chevron-square-left nav-arrow"></i>
    </button>

    <!-- VALUE -->
    <div class="value">
      {{ currentPage }} z {{ maxPages }}
    </div>

    <!-- NEXT -->
    <button
      (click)="nextPage()"
      [disabled]="currentPage >= maxPages"
    >
      <i class="far fa-chevron-square-right nav-arrow"></i>
    </button>
  </div>
</div>
