import {Component} from '@angular/core';
import {AbstractInjectBaseComponent} from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import {OwInject} from '../../../../../../../../core/decorators/ow-inject.decorator';
import {DialogService} from '../../../../../../../shared/providers/dialog.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ProductDetails} from '../../../../../../../player/interfaces/product.interface';
import {CurrencyBalanceDetails} from '../../../../../../../../core/interfaces/currency';

@Component({
  selector: "wf-piece-details",
  templateUrl: "./wf-piece-details.component.html"
})
export class WfPieceDetailsComponent extends AbstractInjectBaseComponent {
  @OwInject(MAT_DIALOG_DATA) data: { product: ProductDetails | Product | null, currency: CurrencyBalanceDetails }
  @OwInject(DialogService) dialogService: DialogService;

  closeActive() {
    this.dialogService.closeActive();
  }
}
