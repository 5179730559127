import { EventDialog } from "../../../../../../../core/interfaces/event-dialog.interface";
import { EVENT_DIALOGS_NAMES_MISSION } from "../../core/event-dialogs/event-names";
import { EVENT_DIALOGS_NAMES_MISSION_CUSTOM } from "./event-names.const";
import {CustomDialogEvent} from '../../../../../../../core/classes/custom-dialog-event.class';
import {MissionDetailsComponent} from '../../../base/custom/dialogs/mission-details/mission-details.component';
import {MissionTransferComponent} from '../../../base/custom/dialogs/mission-transfer/mission-transfer.component';
import {StarMissionsDetailsComponent} from '../../../base/custom/dialogs/star-missions-details/star-missions-details.component';
import {StarMissionsComponent} from '../../../base/custom/dialogs/star-missions/star-missions.component';
import * as R from "ramda";

export const EVENT_DIALOGS_MISSION_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_MISSION.MISSION_TRANSFER]: MissionTransferComponent,
  [EVENT_DIALOGS_NAMES_MISSION_CUSTOM.MISSION_STAR_DETAILS]: StarMissionsDetailsComponent,
  [EVENT_DIALOGS_NAMES_MISSION.MISSIONS]: StarMissionsComponent,
  [EVENT_DIALOGS_NAMES_MISSION.MISSION_DETAILS]: new CustomDialogEvent(({ data }) => {
    return R.path(["mission", "slot", "type"], data) === 1 ? StarMissionsDetailsComponent : MissionDetailsComponent;
  }),
};
