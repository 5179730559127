<ng-container *ngIf="!isDialog">
  <div
    class="main-buttons-container"
    *ngIf="playerService.isActiveMe && playerService.player"
  >
    <ng-container *ngFor="let menuButton of menuButtons; let i = index">
      <ng-container
        *ngIf="(otherButtonsIsActive && (((isCC && playerService.player.title_id.playable) || (!isCC && !isDor)) && menuButton.order <= 5)) || !otherButtonsIsActive"
        [ngTemplateOutlet]="buttonTpl"
        [ngTemplateOutletContext]="{button: menuButton}"
      ></ng-container>
    </ng-container>

    <!--MORE-->
    <ng-container
      *ngIf="otherButtonsIsActive"
      [ngTemplateOutlet]="moreButtonTpl"
    ></ng-container>
  </div>

  <!-- MORE BUTTON TPL -->
  <ng-template #moreButtonTpl>
    <div class="buttons-horizontal">
      <button
        class="main-button"
        matTooltip="Więcej"
        [matTooltipShowDelay]="300"
        [matTooltipPosition]="'below'"
        (click)="openHudMenuMore()"
      >
        <img alt="Więcej" [src]="'more.png' | asset : 'ui/menu'" />
      </button>
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="isDialog">
  <div
    class="main-buttons-container"
    *ngIf="playerService.player"
  >
    <ng-container *ngFor="let menuButton of menuButtons; let i = index">
      <ng-container
        *ngIf="menuButton.order > 5"
        [ngTemplateOutlet]="buttonTpl"
        [ngTemplateOutletContext]="{button: menuButton}"
      ></ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template
  #buttonTpl
  let-button="button"
>
  <div
    class="buttons-horizontal"
    [class.dropdown]="button.type === 'group'"
  >
    <button
      class="main-button"
      *ngIf="button.isVisible"
      [class.active]="button.isVisible"
      [class.anim]="button.group_name"
      [matTooltip]="(!isCC && button.gui_unlocks_blocked_tooltip) ? 'Dostępne wkrótce' : (isCC && button.tooltip_CC ? button.tooltip_CC : button.tooltip)"
      [matTooltipPosition]="'below'"
      [matTooltipShowDelay]="300"
      [matTooltipDisabled]="isDialog && (isCC || (!isCC && playerService.player.gui_unlocks[button.gui_unlocks_button]))"
      (click)="handleMenuButtonEvent(button, $event)"
    >
<!--      <ow-object-svg [svgObject]="assetsService.assetsData['/ui/' + button.icon]"></ow-object-svg>-->
      <img alt="{{button.name}}" [src]="button.icon + '.png' | asset : 'ui/menu'" />
      <ng-container *ngIf="button.customNotification as notification">
        <ng-template
          *ngIf="notification.type === 'async' && (notification.observable | async)"
          [ngTemplateOutlet]="notificationTpl"
        ></ng-template>
      </ng-container>

      <div
        *ngIf="isDialog"
        class="button-name"
      >
        {{ isCC && button.tooltip_CC ? button.tooltip_CC : button.tooltip }}
      </div>
    </button>

    <div class="dropdown-btn" *ngIf="button.group_buttons?.length">
      <ng-container *ngFor="let menuButton of button.group_buttons">
        <ng-container
          [ngTemplateOutlet]="buttonTpl"
          [ngTemplateOutletContext]="{button: menuButton}"
        ></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #notificationTpl>
  <div class="app-notification"></div>
</ng-template>
