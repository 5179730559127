import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import * as R from "ramda";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { GAME_CONFIG } from "../../../../../../../core/config/custom/_parsed-game.config";
import { CURRENCIES } from "../../../../../../../core/consts/core/currencies";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { AppState } from "../../../../../../../store/state";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { ChangeSceneService } from "../../../../../../shared/services/custom/change-scene.service";
import { PreviewPlayerService } from "../../../../../game-gui/providers/preview-player.service";
import { Branch } from "../../../../../interfaces/branch.interface";
import { GameService } from "../../../../../services/game.service";
import { GuiService } from "../../../../../services/gui.service";
import { ApiPlayerService } from "../../../api/custom/services/api-player.service";

@Component({
  selector: "app-branch-preview",
  templateUrl: "./branch-preview.component.html",
})
export class BranchPreviewComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiPlayerService) apiPlayerService: ApiPlayerService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PreviewPlayerService) previewPlayerService: PreviewPlayerService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(GameService) gameService: GameService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(GuiService) guiService: GuiService;
  @OwInject(ChangeSceneService) changeSceneService: ChangeSceneService;
  @OwInject(MAT_DIALOG_DATA) data: {
    branch: Branch;
  };

  branchesPlayers;
  CURRENCIES = CURRENCIES;
  ALLOW_CHANGE_PLAYER = GAME_CONFIG.ALLOW_CHANGE_PLAYER;
  isCC = GAME_CONFIG.IS_CC;

  ngOnInit() {
    this.getBranchesPlayers();
  }

  getBranchesPlayers() {
    const branchId = this.data.branch && this.data.branch.branch_id;

    this.apiPlayerService.getBranchesPlayers(branchId).subscribe(resp => {
      this.branchesPlayers = resp;
      this.setProducts();
    });
  }

  setProducts() {
    const solvedAsiaProblems = 1005;
    const technologiesDiscovered = 1004;

    this.branchesPlayers.forEach(player => {
      const products = R.clone(player.product_balances);
      products.forEach(product => {
        switch (product.product_id) {
          case solvedAsiaProblems:
            player.solvedAsiaProblems = product.balance;
            break;
          case technologiesDiscovered:
            player.technologiesDiscovered = product.balance;
            break;
          default:
            break;
        }
      });
    });
  }
}
