import { Component, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Actions, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { take } from 'rxjs/operators';

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { ActionTypes } from "../../../../../../../../store/game/actions";
import { BoardTileState } from "../../../../../../../../store/game/interfaces/board-tile.state";
import { selectPlayer } from "../../../../../../../../store/player/selectors";
import { AppState } from "../../../../../../../../store/state";
import { Player } from "../../../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { ProductPlayerService } from "../../../../../../../player/providers/product-player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import {
  BUILDING_GROUP_TYPE_ANIMAL_TRACKING, BUILDING_GROUP_TYPE_ANIMAL_TRACKING_FINISHED, BUILDING_GROUP_TYPE_INFO_PROBLEM
} from '../../../../../../constants/game-custom.constants';
import { BoardTile } from "../../../../../../game-engine/classes/custom/BoardTile.class";
import { BoardService } from "../../../../../../services/board.service";
import { BuildingsService } from "../../../../../../services/buildings.service";
import { EVENT_DIALOGS_NAMES_TRACKING_CUSTOM } from "../../../../consts/custom/event-dialogs/event-names.const";
import {BuildingDetailsConfig} from '../../../../../../game-engine/interfaces/building-details-config';

enum SoughtElementsEnum {
  objects = "objects",
  poacher = "poacher",
}

enum Finds {
  chain = "chain",
  trash = "trash",
  scroll = "knowledge",
  rock_pass = "rock_pass",
  chest = "chest",
  chemicals = "chemicals",
  poacher = "poacher",
  snow_leopard = "snow_leopard",
  rhino = "rhino",
}

enum FootprintDirection {
  top = 1,
  right,
  bottom,
  left,
}

interface Cell {
  clickable: boolean;
  background: string;
  showBushes: string;
  footprint: {
    tooltip: string;
    class: string;
  }[];
  creature: {
    variant: string;
    name: string;
    text: string;
  };
  playerBuildingId: number;
  upgradeBuildingId: number;
  playerTileId: number;
  xPos: number;
  yPos: number;
  uniqueId: string; // param for trackBy to compare objects(performance reasons)
}

export interface BuildingDataTracking {
  playerTileId: number;
  playerBuildingId: number;
  playerBuildingLevel: number;
  boardDetails: {
    board: {
      x: number;
      y: number;
    };
    buildingDetails: BuildingDetailsConfig,
    background: string;
  };
  isSpecialMode?: boolean;
  tile?: BoardTileState;
}

@Component({
  selector: "app-tracking-board",
  templateUrl: "./tracking-board.component.html",
})
export class TrackingBoardComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(BoardService) boardService: BoardService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(BuildingsService) buildingService: BuildingsService;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  @OwInject(MAT_DIALOG_DATA) data: BuildingDataTracking;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(Actions) storeActions: Actions;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<TrackingBoardComponent>;

  boardConfig = {
    boardName: "",
    playerBuildingId: null,
    currentLevel: null,
    hudMenuTooltipText: {
      steps: "Kondycja Janka.",
      trackerMove: "Po ilu ruchach odnajdziesz wszystkie obiekty ukryte na planszy.",
      trackersPower: "Siła do walki z kłusownikami.",
      poachersPower: "Moc kłusowników, którzy mogą polować na obszarze, w którym tropisz.",
      soughtPoacher: "Ilość baz kłusowników do wytropienia.",
      soughtAnimal: "Liczba obiektów do odnalezienia.",
      photoFilm: "Ilość klisz.",
      victoryPoints: "Punkty Zwycięstwa."
    },
    blocked: false,
    seeking: SoughtElementsEnum.objects,
  };
  trackingDefinition;

  boardTiles: Array<Cell[]> = [];
  hashMap: { [key: number]: any } = {};
  playerParentTileId: number;
  playerBuildingParentId: number;
  loaded = false;

  subs = {
    board: null,
    storeActions: null,
    player: null,
  };
  player: Player;

  boardResp: BoardTileState[] = [];

  parameters = {
    steps: null,
    max_turns: null,
    tracker_force: null,
    poachers: null,
    creaturesFound: 0,
    creaturesOnTheMap: 0,
    victory_points: 0,
    photo_film: 0,
  };

  soughtCreatureEnum = SoughtElementsEnum;
  buildingsDetails = null;

  ngOnInit() {
    this.buildingsDetails = this.data.boardDetails.buildingDetails;
    this.subscribeTileUpdate();
    this.subscribePlayer();
  }

  subscribeTileUpdate() {
    // subscribe to actions to get the latest update from websocket
    this.subs.storeActions = this.storeActions
      .pipe(ofType(ActionTypes.BOARD_TILE_UPDATE))
      .subscribe((res: { type: string; payload: BoardTile }) => {
          if (this.hashMap[res.payload["player_tile_id"]]) {
            // socket refers to child building
            this.updateSingleBoardTile(res.payload);

            this.getBuildingDetails(this.boardConfig.playerBuildingId);
          }

          // check if socket refers to main building
          if (
            res.payload["player_tile_id"] === this.playerParentTileId &&
            (res.payload["player_building"]?.group_type === BUILDING_GROUP_TYPE_ANIMAL_TRACKING ||
              res.payload["player_building"]?.group_type === BUILDING_GROUP_TYPE_ANIMAL_TRACKING_FINISHED || BUILDING_GROUP_TYPE_INFO_PROBLEM) &&
            res.payload["player_building"]?.level !== this.data.playerBuildingLevel &&
            res.payload["player_building"]?.level % 2 === 1
          ) {
            // this.matDialogRef.close();
            this.boardConfig.blocked = true;
          }

      });
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(selectPlayer)).subscribe(player => {
      this.player = player;
      this.setParameters(); // refresh hud on the left of the board

      if (!this.boardResp.length) {
        // when tile is not available in the boardState(which is obtained on app start)
        if (this.data.tile) {
          this.setBoardTileData(this.data.tile);
        } else {
          this.subscribeBoardTile();
        }
      }
    });
  }

  subscribeBoardTile() {
    this.store
      .pipe(
        select(state => state.application.game.boardState.board),
        take(1)
      )
      .subscribe(tiles => {
        const tile = tiles.find(x => x.player_tile_id === this.data.playerTileId) || this.data.tile;
        this.setBoardTileData(tile);
      });
  }

  setBoardTileData(tile: BoardTileState) {
    this.playerParentTileId = tile.player_tile_id;
    this.playerBuildingParentId = this.data.playerBuildingId;

    this.boardConfig.playerBuildingId = this.data.playerBuildingId || tile.player_building.player_building_id;
    this.boardConfig.currentLevel = tile.player_building.level;
    this.trackingDefinition = this.data.boardDetails;
    this.getBuildingDetails(this.boardConfig.playerBuildingId);
    this.getTile(tile.open_player_island_id);
  }

  // sometimes you're not looking for animals, but the poachers instead
  setCreaturesToBeFoundAmount() {
    // check if we have all data needed
    this.boardConfig.seeking = SoughtElementsEnum.objects;
    this.parameters.creaturesOnTheMap = this.buildingsDetails.tracking_definition.animals_count;
  }

  setParameters() {
    if (this.buildingsDetails) {
      this.parameters.max_turns = this.buildingsDetails["tracking_progress"]?.max_turns - this.buildingsDetails["tracking_progress"]?.moves;
      this.parameters.poachers = this.buildingsDetails["tracking_definition"]?.poachers[0];
    }

    this.parameters.steps = this.player.product_balances.find(p => p.product_id === 1001)?.balance || 0;
  }

  getTile(islandId: number) {
    this.boardService.getBoard(this.playerService.player.id, islandId).subscribe({
      next: resp => {
        this.boardResp = resp;
        this.generateBoard();
      },
    });
  }

  getBuildingDetails(player_building_id) {
    this.buildingService.getPlayerBuildingDetails(player_building_id).subscribe({
      next: buildingDetails => {
        this.buildingsDetails = buildingDetails;
        this.setCreaturesToBeFoundAmount()

        this.setParameters();
      },
    });
  }

  updateSingleBoardTile(tileUpdate) {
    const existingTile = this.boardTiles[tileUpdate.y_pos - 1]?.find(x => x.playerTileId === tileUpdate.player_tile_id);
    if (existingTile != null) {
      const newTileData = this.generateTileData(tileUpdate, existingTile.xPos, existingTile.yPos);

      this.boardTiles[tileUpdate.y_pos - 1][existingTile.xPos - 1] = newTileData;
      this.hashMap[newTileData?.playerTileId] = tileUpdate;

      const existingElIndex = this.boardResp.findIndex(x => x.player_tile_id === tileUpdate.player_tile_id);
      if (existingElIndex !== -1) {
        this.boardResp[existingElIndex] = tileUpdate;
      }

      this.markTileAsAvailable([{ x: existingTile.xPos - 1, y: tileUpdate.y_pos - 1 }]);
    }
  }

  // region logic
  discoverTheField(cell: Cell) {
    // no data
    if (!cell || !cell.playerBuildingId || this.boardConfig.blocked) {
      return;
    }

    // prevent outside clicking in premium mode
    if (this.data.isSpecialMode && !cell.clickable) {
      return;
    }

    // discover bush
    if (cell.showBushes) {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_TRACKING_CUSTOM.TRACKING_START,
        config: {
          data: {
            playerBuildingId: cell.playerBuildingId,
            upgradeBuildingId: cell.upgradeBuildingId,
          },
          disableClose: true,
        },
      });

      return;
    }
  }

  identifyObj(index, item) {
    return item.uniqueId;
  }
  // endregion

  // region board generation logic
  generateBoard() {
    /* reset tiles */
    this.boardTiles = [];

    /* dialog background */
    switch (this.trackingDefinition.background) {
      default:
      case "mountain": {
        this.boardConfig.boardName = "mountain";
        break;
      }
      case "savannah": {
        this.boardConfig.boardName = "savannah";
        break;
      }
      case "swamp": {
        this.boardConfig.boardName = "swamp";
        break;
      }
      case "forest_1": {
        this.boardConfig.boardName = "forest_1";
        break
      }
      case "forest_2": {
        this.boardConfig.boardName = "forest_2";
        break
      }
    }

    /* generate board tiles struct */
    const setAsAvailable = [];

    // rows
    for (let i = 1; i <= this.trackingDefinition.board.y; i++) {
      this.boardTiles.push([]);
      const currentRow = this.boardTiles[i - 1];

      // cells
      for (let j = 1; j <= this.trackingDefinition.board.x; j++) {
        const dbTileData = this.boardResp.find(e => e.x_pos === j && e.y_pos === i);
        const tileData = this.generateTileData(dbTileData, j, i);

        if (!tileData.showBushes) {
          setAsAvailable.push({ x: j - 1, y: i - 1 });
        }

        currentRow.push(tileData);
        this.hashMap[dbTileData.player_tile_id] = currentRow[j - 1];
      }
    }

    this.markTileAsAvailable(setAsAvailable);

    this.loaded = true;
    this.setCreaturesToBeFoundAmount();
  }

  markTileAsAvailable(elToSetAsAvailable: { x: number; y: number }[]) {
    for (let item of elToSetAsAvailable) {
      // top
      if (this.boardTiles[item.y + 1]?.[item.x]) {
        this.boardTiles[item.y + 1][item.x].clickable = true;
        this.boardTiles[item.y + 1][item.x].uniqueId = self.crypto.randomUUID();
      }

      // bottom
      if (this.boardTiles[item.y - 1]?.[item.x]) {
        this.boardTiles[item.y - 1][item.x].clickable = true;
        this.boardTiles[item.y - 1][item.x].uniqueId = self.crypto.randomUUID();
      }

      // left
      if (this.boardTiles[item.y][item.x - 1]) {
        this.boardTiles[item.y][item.x - 1].clickable = true;
        this.boardTiles[item.y][item.x - 1].uniqueId = self.crypto.randomUUID();
      }

      // right
      if (this.boardTiles[item.y][item.x + 1]) {
        this.boardTiles[item.y][item.x + 1].clickable = true;
        this.boardTiles[item.y][item.x + 1].uniqueId = self.crypto.randomUUID();
      }
    }
  }

  // used for frontend tile generation
  generateTileData(fieldData, x: number, y: number): Cell {
    const cell: Cell = {
      background: this.determineTileBackgroundName(x, y, this.trackingDefinition.board),
      showBushes: fieldData?.player_building?.group.includes("empty"),
      footprint: [],
      creature: this.determineCreature(fieldData?.player_building?.icon, fieldData?.player_building?.level),
      playerBuildingId: fieldData?.player_building?.player_building_id,
      upgradeBuildingId: fieldData?.player_building?.upgrade_building_id,
      playerTileId: fieldData?.player_tile_id,
      xPos: x,
      yPos: y,
      uniqueId: self.crypto.randomUUID(),
      clickable: false,
    };

    // handle footprints classes(rotation, direction, type)
    if (fieldData.tracking_footprints?.length) {
      for (const [index, footprint] of fieldData.tracking_footprints.entries()) {
        const footprintDetails = {
          direction: this.determineFootprintDirection(footprint.direction),
          distance: this.determineFootprintDistance(footprint.distance),
          icon: this.determineFootprintIcon(footprint.icon),
        };
        const tooltipText = `${footprintDetails.distance.text} ślad ${footprintDetails.icon.text} prowadzący ${footprintDetails.direction.text}`;

        let footprintClasses = `${footprintDetails.direction.class} ${footprintDetails.icon.class} ${footprintDetails.distance.class}`;

        // sometimes you need to put other animal footprints underneath the discovered animal(case where you have tile with animal
        // and footprint or double footprints)
        // footprintClasses += cell.creature?.name ? ` absolute-${index}` : " single";

        footprintClasses += ` absolute`; // always move to the corner

        cell.footprint.push({
          tooltip: tooltipText,
          class: footprintClasses,
        });
      }
    }

    /* decrease amount of creatures to be found if we're seeking poacher and we found poacher */
    // if (cell.creature?.name && cell.creature?.name === Creatures.poacher && this.boardConfig.seeking === SoughtCreaturesEnum.poacher) {
    //   this.parameters.creaturesToBeFoundAmount--;
    // }

    if (
      cell.creature?.name &&
      ![Finds.trash, Finds.chain, Finds.scroll].includes(cell.creature?.name as Finds) &&
      this.boardConfig.seeking === SoughtElementsEnum.objects
    ) {
      this.parameters.creaturesFound++;
    }

    /* other*/
    // if (cell.creature?.name === Finds.outdoor) {
    //   cell.clickable = true;
    // }

    return cell;
  }

  determineTileBackgroundName(x: number, y: number, boardSize: { x: number; y: number }) {
    // first of all - remember that field are reversed(count from left bottom, exactly as it is in cartesian cords)
    // show nothing(y = 0; x = max)
    if (x === boardSize.x && y === 1) {
      return "grid-d";
    }

    // all cells in the last row
    if (x !== boardSize.x && y === 1) {
      return "grid-c";
    }

    // last element in a row
    if (x === boardSize.x && y !== 1) {
      return "grid-b";
    }

    // rest
    return "grid-a";
  }

  determineFootprintDirection(direction: FootprintDirection) {
    switch (direction) {
      case FootprintDirection.top: {
        return {
          class: "direction-top",
          text: "do góry",
        };
      }
      case FootprintDirection.right: {
        return {
          class: "direction-right",
          text: "w prawo",
        };
      }
      case FootprintDirection.bottom: {
        return {
          class: "direction-bottom",
          text: "w dół",
        };
      }
      case FootprintDirection.left: {
        return {
          class: "direction-left",
          text: "w lewo",
        };
      }
      default: {
        return {
          class: "",
          text: "",
        };
      }
    }
  }

  determineFootprintDistance(distance: number) {
    switch (distance) {
      case 1: {
        return {
          class: "opacity-100",
          text: "Wyraźny",
        };
      }
      case 2: {
        return {
          class: "opacity-80",
          text: "Lekko niewyraźny",
        };
      }
      case 3: {
        return {
          class: "opacity-60",
          text: "Niewyraźny",
        };
      }
      case 4: {
        return {
          class: "opacity-40",
          text: "Zamazany",
        };
      }
      case 5: {
        return {
          class: "opacity-20",
          text: "Mocno zamazany",
        };
      }
      case 6: {
        return {
          class: "opacity-10",
          text: "Ledwo widoczny",
        };
      }
      default: {
        return {
          class: "",
          text: "",
        };
      }
    }
  }

  determineFootprintIcon(iconName: string) {
    switch (iconName) {
      case "chain": {
        return {
          class: "icon-chain",
          text: "łańcucha",
        };
      }
      case "track_chemicals": {
        return {
          class: "icon-chemicals",
          text: "chemikalii"
        }
      }
      case "track_poachers": {
        return {
          class: "icon-poacher",
          text: "kłusownika"
        }
      }
      case "track_rhino": {
        return {
          class: "icon-rhino",
          text: "nosorożca"
        }
      }
      case "track_snow_leopard": {
        return {
          class: "icon-snow_leopard",
          text: "śnieżnej pantery",
        }
      }
      default: {
        return null;
      }
    }
  }

  determineCreature(iconName: string, level?: number) {
    const animal = iconName?.substring(iconName?.indexOf("_") + 1)?.split("_") || null;
    if (!animal) {
      return {
        name: "",
        text: "",
        variant: "",
      };
    }

    switch (iconName.toLowerCase().replace('map_', '')) {
      case Finds.chain: {
        return {
          name: Finds.chain,
          text: "Łancuch asekuracyjny",
          variant: animal[1] ? `_${animal[1]}` : "",
        };
      }
      case Finds.rock_pass: {
        return {
          name: Finds.rock_pass,
          text: "Przejście górskie",
          variant: `-${level}`,
        };
      }
      case Finds.chest: {
        return {
          name: Finds.chest,
          text: "Skrzynia",
          variant: animal[1] ? `_${animal[1]}` : "",
        };
      }
      case Finds.trash: {
        return {
          name: Finds.trash,
          text: "Przejście górskie",
          variant: `-${level}`,
        };
      }
      case Finds.scroll: {
        return {
          name: Finds.scroll,
          text: "Zwój wiedzy",
          variant: `-${level}`,
        };
      }
      case Finds.chemicals: {
        return {
          name: Finds.chemicals,
          text: "Beczki z odpadami",
          variant: `-${level}`
        }
      }
      case Finds.snow_leopard: {
        return {
          name: Finds.snow_leopard,
          text: "Śnieżna pantera",
          variant: `-${level}`
        };
      }
      case Finds.rhino: {
        return {
          name: Finds.rhino,
          text: "Nosorożec",
          variant: `-${level}`
        };
      }
      case Finds.poacher: {
        return {
          name: Finds.poacher,
          text: "Kłusownicy",
          variant: `-${level}`
        };
      }
      default: {
        return null;
      }
    }
  }
  // endregion

  closeTrackingBoard() {
    if (this.data.isSpecialMode) {
      this.matDialogRef.close(true);
      return;
    }

    this.dialogService.closeAll();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
