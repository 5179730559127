<div class="container-bg"></div>

<div class="auth-container">
  <div class="inner">
    <div class="logo-wrapper"></div>

    <div class="auth-form-wrapper">
      <loading></loading>
      <app-auth-info></app-auth-info>

      <div class="loading-opacity">
        <p class="form-title">Resetowanie hasła</p>

        <form [formGroup]="form">
          <!-- PASSWORD FORM -->
          <div class="control"
               [class.error]="form.get('password').invalid && form.get('password').dirty && form.get('password').errors">
            <div class="default-field">
              <input class="control-input no-ow-input-directive" formControlName="password" type="password"
                     placeholder="hasło" id="password">
              <label for="password">Nowe hasło</label>
            </div>
          </div>

          <!-- PASSWORD REPEAT FORM -->
          <div class="control"
               [class.error]="form.get('passwordRepeat').invalid && form.get('passwordRepeat').dirty && form.get('passwordRepeat').errors">
            <div class="default-field">
              <input class="control-input no-ow-input-directive" formControlName="passwordRepeat" type="password"
                     placeholder="hasło" id="passwordRepeat">
              <label class="color-2" for="passwordRepeat">Powtórz nowe hasło</label>
            </div>
          </div>

          <p
            class="description invalid-form invalid-password error"
            *ngIf="form.get('password').value && form.get('passwordRepeat').value && form.hasError('notSame')"
          >
            Podane hasła nie są identyczne.
          </p>
        </form>

        <div class="buttons">
          <button
            class="c-base c-primary full-width"
            (click)="onFormSubmit()"
            [disabled]="form.invalid"
          >
            Zmień hasło
          </button>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- APP HELP DESK INFO -->
<app-helpdesk-info></app-helpdesk-info>

