<div
  class="global-dialog ow-dialog c-window-short"
  *ngIf="({currency_id: data.eventShop.currencyId, amount: null} | singleCurrency) as currency"
>
  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>{{ data.eventShop.title }}</span>
      </div>
    </div>
  </div>

  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button></m-ui-back-button>

  <loading></loading>

  <div
    *ngIf="data.product.limit_per_person > 0"
    [class.text-error]="isLimitOverrun"
    class="limit-container"
    matTooltip="Limit"
    matTooltipPosition="left"
  >
    {{ (data.product.limit_per_person - data.product.limit_per_person_left) + '/' + data.product.limit_per_person }}
  </div>

  <div class="currency-balance-container">
    <img
      [src]="({currency_id: data.eventShop.currencyId, amount: null} | singleCurrency).iconUrl"
      class="icon-currency"
    />

    <ng-container *ngIf="currency.type === 'player'">
      {{ (playerService.player.currency_balances | currencyGameBalance:currency.key).balance | NumberWithSpaces }}
    </ng-container>
    <ng-container *ngIf="currency.type === 'user'">
      {{ (userService.me.currency_balances | currencyGameBalance:currency.key).balance | NumberWithSpaces }}
    </ng-container>
  </div>


  <div
    class="frame loading-opacity"
    [class.gadget]="data.eventShop.event_shop_id === GADGET_SHOP_ID || data.eventShop.event_shop_id === GADGET_SHOP_ID_3"
  >
    <div class="start">
      <ng-container *ngIf="(data.product.icon + '.png' | asset:'products_eventmarket/big') as imagePath">
        <img
          class="icon-product"
          [class.allow-image-zoom]="data.product.product_type === 'custom'"
          (click)="openAlertImage(imagePath)"
          [src]="imagePath"
        />
      </ng-container>

      <div class="product-name">
        {{ data.product.name }}
      </div>
      <div class="product-price-container">
        {{ data.product.price }}
        <img
          [src]="({currency_id: data.eventShop.currencyId, amount: null} | singleCurrency).iconUrl"
          class="icon-currency"
        />
      </div>

      <div class="description" [innerHTML]="data.product.description"></div>
    </div>

    <div
      class="to-low-lvl-info text-error"
      *ngIf="isLowLevel"
    >
      wymagany poziom {{ data.product.required_level }}
    </div>

    <div
      class="to-low-lvl-info text-error"
      *ngIf="isLowStar"
    >
      wymagana {{ data.product.required_star.star_name }}
    </div>

    <form
      [formGroup]="form"
      class="end"
      [class.deactivated]="!isActive()"
      [matTooltip]="setTooltip()"
      (submit)="submit()">
      <div class="control">
        <div class="default-field" *ngIf="!hideInputField">
          <input
            placeholder="ilość"
            class="control-input no-ow-input-directive"
            formControlName="amount"
            type="number"
            min="1"
          />
        </div>
      </div>

      <div class="summary">
        {{ data.product.price }}
        <img
          [src]="({currency_id: data.eventShop.currencyId, amount: null} | singleCurrency).iconUrl"
          class="icon-currency"
        />
        x {{ form.get('amount').value || 0 }} szt. =&nbsp;
        <span
          [class.text-error]="!isCurrencyEnough(((currency.type === 'player' ? playerService.player.currency_balances : userService.me.currency_balances) | currencyGameBalance:currency.key).balance)">
          {{ (form.get('amount').value || 0) * data.product.price }}
        </span>

        <img
          [src]="({currency_id: data.eventShop.currencyId, amount: null} | singleCurrency).iconUrl"
          class="icon-currency"
        />
      </div>

      <button
        class="c-base c-secondary"
        type="submit"
        [disabled]="form.invalid || isLowLevel || isLowStar || isLimitOverrun"
      >
        Kup
      </button>

      <div
        class="available-amount"
        [class.text-error]="data.product.available_amount !== null"
        [class.opacity-0]="data.product.available_amount === null && data.eventShop.event_shop_id === GADGET_SHOP_ID_3"
      >
        Pozostało:
        <ng-container *ngIf="data.product.available_amount !== null; else infinityTpl">
          {{ data.product.available_amount }}
        </ng-container>
        <ng-template #infinityTpl>
          <i class="fas fa-infinity"></i>
        </ng-template>
      </div>
    </form>
  </div>
</div>
