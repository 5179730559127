<loading></loading>
<ng-container *ngIf="rank && rankDetails">
  <div class="navigate-info">
    <ng-container *ngIf="owDate">
      {{ owDate.month_text_normal }} | Dane aktualne na: {{ rankDetails.source_data_date | date:'dd-MM-y' }}
    </ng-container>
  </div>

  <div class="content loading-opacity">
    <!-- TOP -->
    <div class="top">
      <!-- LEFT -->
      <div class="left">
        <!-- DESCRIPTION -->
        <p
          class="description"
          [innerText]="rankDetails.description"
          owEllipsis
        ></p>
      </div>

      <!-- RIGHT -->
      <div class="right">
        <!-- FORM -->
        <div class="default-field">
          <input
            type="text"
            id="search"
            placeholder="Szukaj..."
            #inputSearch
            (keyup)="$event"
          />
          <label for="search">Szukaj...</label>
        </div>
      </div>
    </div>

    <!-- TOP 2 -->
    <div class="top v2">
      <!-- LEFT -->
      <div class="left">
        <ng-container *ngIf="!currentButtonMyBranch.isActive && setFiltersOnce">
          <ng-container *ngFor="let filter of objectFilters">
            <div class="role-select" *ngIf="filter.show">
              <ng-select
                [items]="filter.items"
                [bindLabel]="filter.displayKey"
                [(ngModel)]="this[filter.ngModel]"
                [clearable]="false"
                [searchable]="false"
                (change)="getRank()"
              ></ng-select>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="middle" *ngIf="!currentButtonMyBranch.isActive">
        <div
          class="result"
          *ngIf="(group == RANK_GROUP.BRANCH || group == RANK_GROUP.REGION) && rankDetails.league_result"
          [ngbTooltip]="rankDetails.league_result_name"
          placement="top"
        >
          {{ group == RANK_GROUP.BRANCH ? 'Wynik grupy: ' : 'Wynik kraju: ' }}{{ rankDetails.league_result }}
        </div>
      </div>

      <!-- RIGHT -->
      <div class="right">
        <!-- MY POSITION -->
        <div
          class="my-position-link"
          showMyPositionRank
          *ngIf="(!branch || (branch && branch.manager_player_id) || branch && !branch.manager_player_id && rankDetails.rank_group !== RANK_GROUP.EMPLOYEE) && !currentButtonMyBranch.isActive && rankDetails.my_position !== null && playerService.isActiveMe"
          [perfectScrollbarRef]="perfectScrollbar"
          [myPosition]="rankDetails.my_position"
        >
          <a *ngIf="!this?.branch?.manager_player_id || (this?.branch?.manager_player_id && rankDetails.rank_group !== RANK_GROUP.REGION)">{{branch && isDor ? "Pokaż pozycję oddziału" : "Pokaż moją pozycję"}}</a>
        </div>

        <!-- MY BRANCH -->
        <div
          class="my-position-link"
          *ngIf="(rankDetails.rank_group == RANK_GROUP.EMPLOYEE || rankDetails.rank_group == RANK_GROUP.BRANCH)"
          (click)="showMyBranch()"
        >
          <a>
            {{ currentButtonMyBranch.isActive ? 'Wróć' : (isDor ? 'Pokaż oddział' :'Pokaż mój oddział') }}
          </a>
        </div>
      </div>
    </div>

    <!-- FRAME -->
    <div class="frame">
      <table *ngIf="currentButtonMyBranch.isActive" class="header">
        <tbody>
        <tr>
          <td class="td-position"></td>
          <td class="td-name"></td>
          <td class="td-value"></td>
          <td class="td-min-valid"></td>
          <td class="td-min-valid">
            kraj
          </td>
          <td class="td-min-valid">
            region
          </td>
          <td class="td-min-valid">
            grupa ligowa
          </td>
        </tr>
        </tbody>
      </table>
      <!-- FIRST THREE POSITIONS -->
      <table class="first-three-places">
        <tbody>
        <ng-container
          *ngFor="let item of rankDetails.items.slice(0,3) | filterRankDetails:currentButtonMyBranch.filterKey:currentButtonMyBranch.id:currentButtonMyBranch.isActive | filterObject:inputSearch.value; let i = index"
        >
          <ng-container
            [ngTemplateOutlet]="templateTr"
            [ngTemplateOutletContext]="{item: item, i: i, inputSearch: inputSearch}"
          ></ng-container>
        </ng-container>
        </tbody>
      </table>

      <!-- OTHER POSITIONS -->
      <ow-perfect-scrollbar #perfectScrollbar scrollGradient>
        <table class="custom" [class.my-branch]="currentButtonMyBranch.isActive">
          <tbody>
          <ng-container
            *ngFor="let item of rankDetails.items.slice(3, rankDetails.items.length) | filterRankDetails:currentButtonMyBranch.filterKey:currentButtonMyBranch.id:currentButtonMyBranch.isActive | filterObject:inputSearch.value; let i = index"
          >
            <ng-container
              [ngTemplateOutlet]="templateTr"
              [ngTemplateOutletContext]="{item: item, i: i + 3, inputSearch: inputSearch}"
            ></ng-container>
          </ng-container>
          </tbody>
        </table>
      </ow-perfect-scrollbar>
    </div>
  </div>
</ng-container>

<!-- TEMPLATE -->
<ng-template #templateTr let-item="item" let-i="i" let-inputSearch="inputSearch">
  <tr
    [class.my-position]="(i == rankDetails.my_position) && !(inputSearch && inputSearch.value) && playerService.isActiveMe"
    [attr.data-id]="i"
    [class.player-inactive]="item.is_inactive"
  >
    <!-- POSITION -->
    <td class="td-position">
      {{ currentButtonMyBranch.isActive ? item.position_in_branch : item.pos }}
    </td>

    <!-- NAME -->
    <td class="td-name">
      <div
        [matTooltip]="'Rekord pisany kursywą dotyczy ' + tooltipUser + ' niezarejestrowanego w San Tao wg stanu na ostatni dzień rankingu.'"
        [matTooltipShowDelay]="400"
        [matTooltipPosition]="'above'"
        [matTooltipDisabled]="!item.is_inactive"
      >
        {{ item.player }}
      </div>
    </td>

    <!-- VALUE PERCENT -->
    <td class="td-value">
      <img
        *ngIf="rankDetails.is_quality && item.min_valid && item.prizes && item.prizes.length > 0"
        [src]="'q2.png' | asset:'ui'"
      />
      <span [matTooltipDisabled]="!currentButtonMyBranch.isActive"
            [matTooltip]="rankDetails?.task_type === 23 ? 'różnica wyniku do miejsca wyżej na poziomie kraju' : 'wynik w kraju'">{{ item.value }}</span>
    </td>
    <!-- MIN VALID -->
    <td class="td-min-valid">
      <span
        placement="left"
        [ngbTooltip]="item.mins.tooltip_text"
        [disableTooltip]="!item.mins.tooltip_text"
        tooltipClass="pre-line"
        container="body"
      >
          {{ item.min_valid ? 'Warunki spełnione' : 'Nieklasyfikowany' }}
      </span>
    </td>

    <ng-container *ngIf="!currentButtonMyBranch.isActive">
      <td class="td-prize-array">
        <ng-container *ngFor="let currency of item.prizes?.currency">
          <div class="prize-box">
            <m-ui-currency
              [item]="currency"
              [lack]="false"
              [stockView]="STOCK_VIEW.G"
              [hideValue]="true"
            ></m-ui-currency>
            {{ currency['currency_id'] == 13 ? (currency['amount'] + 'zł') : currency['amount'] }}
          </div>
        </ng-container>

        <ng-container *ngFor="let product of item.prizes['product']">
          <div class="prize-box">
            <m-ui-product
              [item]="product"
              [lack]="false"
              [stockView]="STOCK_VIEW.G"
              [hideValue]="true"
            ></m-ui-product>
            {{ product?.amount }}
          </div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container *ngIf="currentButtonMyBranch.isActive">
      <td class="td-min-valid">
        {{ item.pos || '-' }}
      </td>
      <td class="td-min-valid">
        {{ item.position_in_region || '-' }}
      </td>
      <td class="td-min-valid">
        {{ item.position_in_league_group || '-' }}
      </td>
    </ng-container>
  </tr>
</ng-template>
