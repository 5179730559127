import { Component, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { select } from "@ngrx/store";
import * as moment from "moment";
import * as R from "ramda";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { unsubscribeArray } from "../../../../../../core/utility/unsubscribe-array";
import { UserSelectors } from "../../../../../../store/user";
import { User } from "../../../../../user/interfaces/user";
import { AbstractPlayerChooseComponent } from "../../../../abstract/abstract-player-choose.component";

@Component({
  selector: "app-san-player-choose",
  templateUrl: "./player-choose.component.html",
})
export class PlayerChooseComponent extends AbstractPlayerChooseComponent implements OnInit, OnDestroy {
  @OwInject(MAT_DIALOG_DATA) data: { isOpenFromHudMenu?: boolean };
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<PlayerChooseComponent>;

  subscriptionUser: Subscription;
  displayKeyPlayer = "displayKey";

  ngOnInit() {
    this.subscriptionUser = this.store.pipe(select(UserSelectors.selectUser), take(1)).subscribe(user => {
      this.enabledPlayers = R.clone(user.enabled_players);
      this.setDisplayKeyPlayer();
      const player =
        user.selected_player_id && this.data.isOpenFromHudMenu
          ? this.enabledPlayers.find(p => p.id == user.selected_player_id)
          : this.enabledPlayers[0];
      this.changePlayer(player);
      this.selectedPlayerId = user.selected_player_id;

      if (!this.data.isOpenFromHudMenu) {
        this.showFirstLoginDialog(user);
      }
    });
  }

  setDisplayKeyPlayer() {
    this.enabledPlayers.forEach(player => {
      player["displayKey"] = player.title_id.name + " - " + (player.branch_name || "niezdefiniowana");
    });
  }

  showFirstLoginDialog(user: User) {
    setTimeout(() => {
      let lastLoginDate = "Brak";

      if (user.previous_login_at) {
        lastLoginDate = moment(user.previous_login_at).format("YYYY-MM-DD HH:mm");
      }

      this.dialogService.openAlert(
        {
          title: "Witaj ponownie!",
          description: `Ostatnie logowanie: ${lastLoginDate}`,
          buttonClose: {
            label: "Ok",
            id: "last-login-alert-button",
          },
        },
        () => {
          this.checkPlayerLength();
        }
      );
    });
  }

  checkPlayerLength() {
    if (this.enabledPlayers.length === 1) {
      if (this.selectedPlayerId !== null) {
        this.redirectGame();
      } else {
        this.setDefaultPlayer();
      }
    }
  }

  redirectGame() {
    if (this.data.isOpenFromHudMenu) {
      this.matDialogRef.close();
      window.location.reload();
    } else {
      super.redirectGame();
    }
  }

  ngOnDestroy() {
    unsubscribeArray([this.subscriptionUser]);
  }
}
