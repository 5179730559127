<div class="global-dialog ow-dialog c-window-long">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button></m-ui-back-button>

  <div *ngIf="data?.branchName" class="branch-name">{{ data?.branchName }}</div>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Ranking - {{ data.rank.name }}</span>
      </div>
    </div>
  </div>
  <div class="dialog-decoration">
    <span class="right"></span>
    <span class="bottom"></span>
  </div>

  <!-- RANK BUSINESS BASE -->
  <app-rank-business-base
    *ngIf="!isCC"
    [rank]="data.rank"
    [owDate]="data.owDate"
    [group]="data.group"
    [myIdObject]="data.myIdObject"
    [filters]="data.filters"
    [branch]="data?.branch"
    [branchName]="data?.branchName"
  ></app-rank-business-base>

  <!-- RANK BUSINESS BASE CC -->
  <app-rank-business-base-cc
    *ngIf="isCC"
    [rank]="data.rank"
    [owDate]="data.owDate"
    [levels]="levels"
    [group]="data.group"
    [myIdObject]="data.myIdObject"
    [filterKey]="data.filters"
  ></app-rank-business-base-cc>

  <div
    *ngIf="data.rank.is_cleared"
    class="cleared"
  ></div>
</div>
