<div
  *ngIf="production.length"
  class="building-upgrade-diff-production"
>
  <div class="sub-title">Produkcja <br> na cykl:</div>

  <app-building-upgrade-diff-production
    *ngFor="let production of production"
    [normal]="production.normal"
    [parameters]="production.parameters"
    [upgrade]="production.upgrade"
  ></app-building-upgrade-diff-production>
</div>
