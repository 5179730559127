<div
  *ngIf="diffStorageValue"
  class="other-container-diff"
>
  <div class="building-upgrade-diff-parameter">
    <div class="sub-title">Pojemność <br> magazynu:</div>

    <div class="upgrade-container">
      <div class="upgrade-label">
        <m-ui-stock-other-custom
          [item]="{}"
          [iconType]="'warehouse-capacity'"
          [stockView]="STOCK_VIEW.CUSTOM"
          [customIconSize]="'big'"
          [customClassStock]="'stock-san-a'"
          [lack]="false"
          [hideValue]="true"
        ></m-ui-stock-other-custom>

        <span>
          {{ diffStorageValue.normal }}
        </span>

        <div class="upgrade-value-container has-normal" *ngIf="diffStorageValue.normal !== diffStorageValue.upgrade">
          <div class="upgrade-arrow"></div>

          <span
            class="upgrade"
            [class.quantity-diff]="diffStorageValue.parameters.diff >= 0"
            [class.quantity-diff-minus]="diffStorageValue.parameters.diff < 0"
          >
            {{ diffStorageValue.upgrade }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
