<div class="global-dialog ow-dialog c-window-short dialog-decoration">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Akcje podstawowe</span>
      </div>
    </div>
  </div>

  <!-- BACK BAR -->
  <ng-container *ngIf="showBaseActions">
    <div class="back-bar">
      <button
        class="c-square normal back"
        (click)="toggleBaseActions()"
      >
        <i class="fas fa-angle-left"></i>
      </button>
    </div>
    <ng-container [ngTemplateOutlet]="baseActionTpl"></ng-container>
  </ng-container>

  <!-- TOP RIGHT -->
  <ng-container *ngIf="!showBaseActions">
    <div class="top-right">
      <button
        class="c-square small c-primary list"
        (click)="toggleBaseActions(data?.branch?.branch_id)"
      >
        <i class="fas fa-gift"></i>
      </button>
    </div>
    <ng-container [ngTemplateOutlet]="dailyBasicActionsTpl"></ng-container>
  </ng-container>
  <div *ngIf="branchName" class="branch-name">{{ branchName }}</div>
</div>

<!-- BASE ACTIONS TEMPLATE -->
<ng-template #baseActionTpl>
  <div class="frame current loading-opacity">
    <div class="title-2">Sprawdź, za co możesz dostać nagrody</div>
    <ow-perfect-scrollbar [scrollGradient]="basicActionCurrent?.length"
                       *ngIf="basicActionCurrent && basicActionCurrent.length">
      <table>
        <thead>
        <tr>
          <th>Akcja podstawowa</th>
          <th class="no-wrap">Nagroda za akcję</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of basicActionCurrent">
          <td class="full-width">
            {{ item.reason }}
          </td>
          <td class="no-wrap">
            <div class="prizes">
              <div class="flex-center prize" *ngFor="let prize of item.prizes">
                <m-ui-currency
                  [item]="prize"
                  [lack]="false"
                  [stockView]="STOCK_VIEW.G"
                  [other]="{afterValue: prize.currency_id == WF_CURRENCY ? 'zł' : ''}"
                ></m-ui-currency>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </ow-perfect-scrollbar>
  </div>
</ng-template>

<!-- DAILY BASIC ACTIONS TEMPLATE -->
<ng-template #dailyBasicActionsTpl>
  <!-- DESCRIPTION -->
  <div
    class="description"
    [innerHTML]="desc"
    owEllipsis
  ></div>

  <ng-container *ngIf="basicActionDetails && basicActionDetails.items.length && basicActionDetails.items[0].date">
    <div class="frame loading-opacity">
      <div class="navigate-container">
        <div class="title-nav" [innerHTML]="'Naliczenia akcji podstawowych za dzień: '"></div>
        <div class="navigate-date">
          <!-- PREV DAY-->
          <button
            class="prev"
            *ngIf="basicActionDetails.date_prev"
            (click)="getBasicActionDetails(basicActionDetails.date_prev)"
          >
            <i class="far fa-chevron-square-left nav-arrow"></i>
          </button>

          <!-- CURRENT DAY -->
          <div class="current">{{ basicActionDetails.items[0].date }}</div>

          <!-- NEXT DAY -->
          <button
            class="next"
            *ngIf="basicActionDetails.date_next"
            (click)="getBasicActionDetails(basicActionDetails.date_next)"
          >
            <i class="far fa-chevron-square-right nav-arrow"></i>
          </button>
        </div>
      </div>

      <ow-perfect-scrollbar
        [scrollGradient]="basicActionDetails.items.length"
        *ngIf="basicActionDetails.items.length"
        class="loading-opacity"
      >
        <table>
          <thead>
          <tr>
            <th class="center">Akcja podstawowa</th>
            <th class="no-wrap">Nagroda za akcję</th>
            <th class="no-wrap">Ilość</th>
            <th class="no-wrap">Otrzymujesz</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of basicActionDetails.items">
            <td class="width-70">
              {{ item.basic_action.reason }}
            </td>
            <td class="no-wrap width-12">
              <div class="prizes">
                <div class="flex-center prize" *ngFor="let prize of item.prizes">
                  <m-ui-currency
                    [item]="{currency_id: prize.currency_id, amount: prize.prize_base_amount}"
                    [lack]="false"
                    [stockView]="STOCK_VIEW.G"
                    [other]="{afterValue: prize.currency_id == WF_CURRENCY ? 'zł' : ''}"
                  ></m-ui-currency>
                </div>
              </div>
            </td>
            <td class="no-wrap border-color width-6">{{ item.prizes.length ? item.prizes[0].quantity : '-' }}</td>
            <td class="no-wrap width-6">
              <div class="prizes">
                <div class="flex-center prize" *ngFor="let prize of item.prizes">
                  <m-ui-currency
                    [item]="prize"
                    [lack]="false"
                    [stockView]="STOCK_VIEW.G"
                    [other]="{afterValue: prize.currency_id == WF_CURRENCY ? 'zł' : ''}"
                  ></m-ui-currency>
                </div>
              </div>
            </td>
          </tr>
          <tr class="summary">
            <td colspan="2" class="line-h-10">
              Suma naliczeń za dzień: {{ basicActionDetails.items[0].date }}
            </td>
            <td class="border-color"></td>
            <td class="no-wrap">
              <div class="prizes">
                <div class="flex-center prize" *ngFor="let prize of basicActionDetails.sums">
                  <m-ui-currency
                    [item]="prize"
                    [lack]="false"
                    [stockView]="STOCK_VIEW.G"
                    [other]="{afterValue: prize.currency_id == WF_CURRENCY ? 'zł' : ''}"
                  ></m-ui-currency>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </ow-perfect-scrollbar>
    </div>
  </ng-container>
  <ng-container *ngIf="!(basicActionDetails && basicActionDetails.items.length && basicActionDetails.items[0].date) && data.branchName && !data?.branch?.manager_player_id">
    <div class="no-data">Brak danych - Kierownik niezarejestrowany</div>
  </ng-container>
</ng-template>
