import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterObject'
})

export class FilterObjectPipe implements PipeTransform {
  /**
   * status check verify all params in fields array
   */
  fieldsStatus: boolean;

  /**
   * @param {array} items
   * @param {string} searchString
   * @param {array<string>} [fields]  if param not exist search all fields
   * @returns {array}
   */
  transform(items: Array<any>, searchString: string, fields?: Array<string>): any {
    if (!(items instanceof Array)) {
      return [];
    }

    if (typeof searchString !== 'string') {
      return items;
    } else if (searchString.length == 0) {
      return items;
    }

    searchString = searchString.toLowerCase();

    this.fieldsStatus = this.verifyFieldsStatus(fields);

    return items.filter(item => {
      if (this.fieldsStatus) {
        let tmpResultsFields = fields.filter((field: string) => {
          if (item[field]) {
            let jsonString = JSON.stringify(item[field]).toLowerCase();
            return jsonString.includes(searchString);
          }
        });

        return tmpResultsFields.length > 0;
      } else {
        let jsonString = JSON.stringify(item).toLowerCase();
        return jsonString.includes(searchString);
      }
    });
  }

  verifyFieldsStatus(fields: Array<string>): boolean {
    if (!(fields instanceof Array)) {
      return false;
    }

    if (fields.length == 0) {
      return false;
    }

    let tmpFields = fields.filter((field: string) => {
      return typeof field === 'string';
    });

    return tmpFields.length == fields.length;
  }
}
