import * as R from "ramda";

import { translate } from "../../../../../../core/helpers/translate.helper";
import { AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME, BOARD_TILE_BUYABLE, TILE_HOVER_DEPTH } from "../../../../constants";
import { isDailyProduction } from "../../../../game-gui/helpers/buildings.helper";
import { isNonInteractiveBuilding } from "../../../utils/game.helper";
import { BoardTile } from "../../custom/BoardTile.class";
import { TileHoverCore } from "../core/TileHoverCore";

export class TileHover extends TileHoverCore {
  processAutoProductionData() {
    this.autoProductionCombined = {
      currency: this.building.automatic_currency
        ? this.gameService.currencyService.getCurrencyDefinition(this.building.automatic_currency)
        : null,
      product: this.building.automatic_product
        ? this.gameService.productionService.productsService.getProduct(this.building.automatic_product)
        : null,
      amount: null,
    };
    if (this.autoProductionCombined.currency || this.autoProductionCombined.product) {
      const dailyProduct = this.building["daily_product_productions"].find(
        product => product.product_id === this.building.automatic_product.product_id
      );
      this.autoProductionCombined.amount =
        dailyProduct?.amount || R.path(["additional_board_data", AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME], this.building) || 0;
    }
  }

  async setAutoproductionParams() {
    const autoprodCombinedValue = this.autoProductionCombined.currency || this.autoProductionCombined.product;
    if (autoprodCombinedValue) {
      const unitValue = isDailyProduction(this.building.group_type) ? "/24h" : "/h";
      this.autoprodTextObject.setText(`${this.autoProductionCombined.amount.toString()}${unitValue}`);
      await this.gameService.safeSetSpriteTexture(this.autoprodIcon, autoprodCombinedValue.iconUrl);
      this.autoprodIcon.setDisplaySize(50, 50);
      this.mainTextObject.y += 15;
      this.additionalTextObject.y += 15;
      this.autoprodContainer.x = -(this.autoprodIcon.width / 2 + this.autoprodTextObject.width) / 2;
      this.autoprodContainer.setAlpha(1);
      this.background.setFrame("schadow_2.png");
    }
  }

  show(target: BoardTile) {
    if (target.tileData.state === BOARD_TILE_BUYABLE) {
      this.showOnBuyable(target);
      return;
    }
    this.targetTile = target;
    this.myScene = target.myScene;
    this.gameService = this.myScene.gameService;
    this.offset = this.calculateOffset(target);
    const backgroundOffset = this.calculateBackgroundOffset();
    this.x = target.x + this.offset.x + backgroundOffset.x;
    this.y = target.y + this.offset.y + backgroundOffset.y;

    switch (target.playerBuildingData.icon) {
      // case "quarry":
      // case "sawmill":
      // case "cave":
      // case "village_craftsmans":
      // case "village_3":
      // case "swamp_1":
      // case "fish_seaweed":
      // case "river_spilled":
      // case "swamp_2":
      // case "swamp_3":
      // case "questionmark_jungle_1":
      // case "nuts_coffee":
      // case "poachers_1":
      // case "poachers_2":
      // case "village_2":
      // case "village_4":
      // case "broken_bridge":
      // case "jungle_1":
      // case "savanna_4":
      // case "jungle_2":
      // case "jungle_3":
      // case "questionmark_nuts_coffee":
      // case "unknown_swamp_3":
      // case "questionmark_village_craftsmans":
      //   break;
      case "firefighter":
      case "tracker":
      case "biologist":
      case "chemist":
      case "volunteer":
      case "farmer":
      case "architect":
      case "recycling_specialist":
      case "zoologist":
      case "hunter":
      case "mudslide_2":
      case "mudslide_3":
      case "mudslide_8":
      case "mudslide_10": {
        break;
      }

      default:
        this.y = target.y - target.baseSprite.height / 2;
        break;
    }

    this.building = target.playerBuildingData;
    this.depth = target.depth + 100 + TILE_HOVER_DEPTH;
    this.hideAutoprodObjects();
    this.processAutoProductionData();
    this.processLimitedResourceData();

    this.setValues();
    this.additionalTextObject.setText(translate("tile-menu.level-text", [this.building.level]));
    this.visible = true;
    this.belowTextObject.alpha = 0;
    this.autoproductionContainer.alpha = 1;

    if (this.building.hasOwnProperty("player_building_disaster") && this.building["player_building_disaster"] !== null) {
      this.building.hide_level_information_on_front = true;
    }

    this.additionalTextObject.alpha = this.building && this.building.hide_level_information_on_front ? 0 : 1;
    this.additionalTextObject.visible = !isNonInteractiveBuilding(this.targetTile.playerBuildingData.group_type);
  }
}
