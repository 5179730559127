import { Component, OnInit } from "@angular/core";

import { AbstractMessageDetailsChestFailedComponent } from "../../../../abstract/core/abstract-message-details-chest-failed.component";

@Component({
  selector: "app-message-details-chest-failed",
  templateUrl: "./message-details-chest-failed.component.html",
})
export class MessageDetailsChestFailedComponent extends AbstractMessageDetailsChestFailedComponent implements OnInit {
  ngOnInit() {
    this.afterInit();
  }
}
