<div class="global-dialog ow-dialog c-window-c">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button></m-ui-back-button>

  <ng-container *ngIf="condition && productOrCurrency">
    <div class="frame loading-opacity">
      <!-- ITEM ICON -->
      <div class="product-in-glow-box">
        <div
          *ngIf="productOrCurrency.iconUrlBig"
          class="product-icon"
          [style.background-image]="'url(' + productOrCurrency.iconUrlBig + ')'"
          [openStorage]="{product_id: condition.product?.product_id}"
        ></div>
      </div>

      <!-- ITEM NAME -->
      <p class="product-name">
        {{ productOrCurrency.name }}
      </p>

      <!-- ITEM BALANCE -->
      <p class="product-balance">
        {{ productOrCurrency.balance | NumberWithSpaces }}
      </p>

      <!-- FORM -->
      <form [formGroup]="form">
        <div class="default-field">
          <input
            type="text"
            [maxlength]="12"
            formControlName="amount"
            placeholder="amount"
            id="amount"
            (keydown)="numberOnly($event)"
          >
          <label for="amount">Wprowadź ilość</label>
        </div>
      </form>

      <!-- SELL ALL -->
      <button
        class="only-border no-hover-blur"
        (click)="setMax()"
      >
        Przekaż wszystko
      </button>
      <!-- SUMMARY -->
      <p class="summary">
        PRZEKAZUJESZ: {{ form.value.amount }}
      </p>

      <!-- ERROR -->
      <div class="error" *ngIf="form.value.amount > productOrCurrency.balance">
        Brak wystarczającej ilości
      </div>

      <!-- BUTTON -->
      <button
        class="c-base c-primary"
        [disabled]="form.invalid || form.value.amount > productOrCurrency.balance"
        (click)="missionHandOver()"
      >
        Przekaż
      </button>
    </div>
  </ng-container>
</div>
