<div class="global-dialog">
  <loading [classNames]="['white', 'stroke']" [size]="'big'"></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <div class="buildings-list-container loading-opacity">
    <div [hidden]="!buildings">
      <!-- NAVIGATION CENTER -->
      <m-ui-navigation-center
        *ngIf="swiper"
        [hidePrev]="swiper?.isBeginning"
        [hideNext]="swiper?.isEnd"
        (prevClicked)="prevSlide()"
        (nextClicked)="nextSlide()"
      ></m-ui-navigation-center>

      <!-- SLIDER -->
      <swiper-container *ngIf="initSwiper" #swiperRef swiperElement [config]="config" init="false">
        <swiper-slide *ngFor="let building of buildings; let idx = index">
          <div (click)="building && openBuildingDetails(building.building_id, idx)">
            <ng-container *ngIf="player.level >= building.required_level; else requiredLevelTpl">
              <!-- GROUP BUILDING -->
              <m-ui-group-count [building]="building" [class.active]="idx === currentSlideIndex"></m-ui-group-count>
            </ng-container>
            <ng-template #requiredLevelTpl>
              <!-- BUILDING LOCK -->
              <div class="building-lock" [class.active]="idx === currentSlideIndex">
                <i class="fas fa-lock"></i>
                <span>
                  {{ "building-list.from-level" | myTranslate : [building.required_level] }}
                </span>
              </div>
            </ng-template>
            <div class="ow-dialog window-c primary" [class.active]="idx === currentSlideIndex">
              <!-- ICON -->
              <div class="icon-container">
                <img [src]="building | buildingIcon : false"/>
              </div>

              <!-- NAME -->
              <p class="name">{{ building.name }}</p>

              <!-- RESOURCE -->
              <div class="bar-price" *ngIf="building.build_currency_prices.length > 0">
                <m-ui-costs [stockView]="STOCK_VIEW.D" [currencies]="building.build_currency_prices"></m-ui-costs>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
  </div>
</div>
