import { Component, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../../../shared/providers/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../../../../../../user/providers/user.service';
import { PlayerService } from '../../../../../../../player/providers/player.service';
import { EventShopRank } from '../../../../../event-shop/api/custom/interfaces/response/get-event-shops-ranks.interface';
import { ApiEventShopService } from '../../../../../event-shop/api/custom/services/api-event-shop.service';
import { EventShopRankItem } from '../../../../../event-shop/api/custom/interfaces/response/get-event-shops-rank-details.interface';
import { ApiEventBusinessService } from '../../../../api/custom/services/api-event-business.service';

@Component({
  selector: 'app-cl-rank-details',
  templateUrl: './cl-rank-details.component.html',
})
export class ClRankDetailsComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiEventShopService) apiEventShopService: ApiEventShopService;
  @OwInject(ApiEventBusinessService) apiEventBusinessService: ApiEventBusinessService;
  @OwInject(UserService) userService: UserService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MAT_DIALOG_DATA) data: {
    event_shop_id: number,
    rank: EventShopRank,
    event_business_id?: number
  };

  rankDetails: EventShopRankItem[];
  isShowOnlyMe: boolean;
  isShowOnlyPrize: boolean;

  ngOnInit() {
    this.getRankDetails();
  }

  getRankDetails() {
    if (this.data.event_business_id) {
      this.apiEventBusinessService.getEventBusinessRankDetails(
        this.data.event_business_id,
        this.data.rank.rank_id
      ).subscribe(resp => {
        resp = resp.map((item, idx) => {
          item['position'] = idx + 1;
          return item;
        });
        this.rankDetails = resp;
      }, (errResp) => {
        this.rankDetails = null;
        this.dialogService.openAlertErrorApi({errResp});
      });
    } else {
      this.apiEventShopService.eventShopsRankDetails({
        rank_id: this.data.rank.rank_id,
        event_shop_id: this.data.event_shop_id,
      })
        .subscribe((resp) => {
          resp = resp.map((item, idx) => {
            item['position'] = idx + 1;
            return item;
          });

          this.rankDetails = resp;
        }, (errResp) => {
          this.rankDetails = null;
          this.dialogService.openAlertErrorApi({errResp});
        });
    }
  }

  toggleShowOnlyMe() {
    this.isShowOnlyPrize = false;
    this.isShowOnlyMe = !this.isShowOnlyMe;
  }

  toggleShowOnlyPrize() {
    this.isShowOnlyMe = false;
    this.isShowOnlyPrize = !this.isShowOnlyPrize;
  }

  trackBy(item) {
    return item['position'];
  }
}
