import { Component, OnDestroy, OnInit } from "@angular/core";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { ParametersService } from "../../../../../../../../core/providers/parameters.service";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { AbstractBuildingUpgradeComponent } from "../../../../abstract/core/abstract-building-upgrade.component";
import { BUILDING_TYPES } from "../../../../consts/core/buidling-types.const";
import { upgradeDifferenceParameters, upgradeDifferenceProduction } from "../../../../helpers/core/upgrade.helpers";
import { getAutomaticProductHelper } from "../../../../helpers/custom/get-automatic-product.helper";
import { getStorageValueHelper } from "../../../../helpers/custom/get-storage-value.helper";
import { getNotOutcomeParameters, getOutcomeParameters } from "../../../../helpers/custom/outcome-parameters.helper";

@Component({
  selector: "app-building-upgrade",
  templateUrl: "./building-upgrade.component.html",
})
export class BuildingUpgradeComponent extends AbstractBuildingUpgradeComponent implements OnInit, OnDestroy {
  @OwInject(ParametersService) parametersService: ParametersService;
  STOCK_VIEW = STOCK_VIEW;

  notOutcomeParameters: any[];
  outcomeParameters: any[];

  notOutcomeParametersUpgrade;
  outcomeParametersUpgrade;

  notOutcomeParametersDiffParameters;

  outcomeParametersDiffParameters;
  outcomeParametersRequirements;

  storageValue;
  upgradeStorageValue;

  automaticCurrency;
  automaticProduct;

  upgradeAutomaticCurrency;
  upgradeAutomaticProduct;

  diffProductions;

  hideSubTitle: boolean;

  ngOnInit() {
    this.subscribeBoardTile();
  }

  getUpgradeDifferenceParameters() {
    this.setParameters();

    // compare parameters difference(what's the cost of current building compared to the next level)
    this.notOutcomeParametersDiffParameters = upgradeDifferenceParameters(
      this.notOutcomeParameters,
      this.notOutcomeParametersUpgrade,
      true
    );

    this.outcomeParametersDiffParameters = upgradeDifferenceParameters(this.outcomeParameters, this.outcomeParametersUpgrade);

    // we're filtering stuff that didn't change
    this.outcomeParametersDiffParameters = this.outcomeParametersDiffParameters.filter(x => x.upgrade !== null);
    this.notOutcomeParametersDiffParameters = this.notOutcomeParametersDiffParameters.filter(x => x.upgrade !== null && x.parameters.income_diff !== 0);

    this.outcomeParametersRequirements = this.outcomeParametersDiffParameters.map(parameter => {
      return {
        ...parameter.upgrade,
        outcome: parameter.parameters.outcome_diff === 0 ? 0 : parameter.parameters.outcome_diff || parameter.upgrade.outcome,
      };
    });

    this.afterGetDataRequests();
  }

  setParameters() {
    // current building requirements
    this.notOutcomeParameters = getNotOutcomeParameters(this.buildingDetails.parameters, true);
    this.outcomeParameters = getOutcomeParameters(this.buildingDetails.parameters);

    // requirements of building after lvl up
    this.notOutcomeParametersUpgrade = getNotOutcomeParameters(this.upgradeBuildingDetails.parameters, true);
    this.outcomeParametersUpgrade = getOutcomeParameters(this.upgradeBuildingDetails.parameters);
  }

  setAutoProduction() {
    const automaticProduction = getAutomaticProductHelper(this.buildingDetails);
    const upgradeAutomaticProduction = getAutomaticProductHelper(this.upgradeBuildingDetails);

    this.automaticCurrency = automaticProduction.automaticCurrency;
    this.automaticProduct = automaticProduction.automaticProduct;

    this.upgradeAutomaticCurrency = upgradeAutomaticProduction.automaticCurrency;
    this.upgradeAutomaticProduct = upgradeAutomaticProduction.automaticProduct;
  }

  afterGetDataRequests() {
    this.hideSubTitle = this.buildingDetails.group_type !== "production" && this.buildingDetails.group_type !== "auto-production";
    this.setStorageSize();
    this.setAutoProduction();
  }

  setStorageSize() {
    this.storageValue = getStorageValueHelper(this.buildingDetails);
    this.upgradeStorageValue = getStorageValueHelper(this.upgradeBuildingDetails);
  }

  getBuildingProductions() {
    switch (this.upgradeBuildingDetails.group_type) {
      case BUILDING_TYPES.PRODUCTION:
      case BUILDING_TYPES.CROP:
        if (this.buildingDetails.group_type !== BUILDING_TYPES.PRODUCTION && this.buildingDetails.group_type !== BUILDING_TYPES.CROP) {
          // ACTUAL BUILDING != PROD/CROP -> GET PRODUCTION ONLY FOR UPGRADE BUILDING
          this.productionService.getBuildingProduction(this.upgradeBuildingDetails.building_id).subscribe(productionUpgrade => {
            this.setDiffProductionSlider([], productionUpgrade);
          });
        } else {
          // COMPARE DIFF PRODUCTION BETWEEN BUILDINGS
          Promise.all([
            this.productionService
              .getBuildingProduction(this.buildingDetails.building_id)
              .pipe(catchError(() => of([])))
              .toPromise(),
            this.productionService
              .getBuildingProduction(this.upgradeBuildingDetails.building_id)
              .pipe(catchError(() => of([])))
              .toPromise(),
          ]).then(([productionNormal, productionUpgrade]: [any[], any[]]) => {
            this.setDiffProductionSlider(productionNormal, productionUpgrade);
          });
        }
        break;
    }
  }

  setDiffProductionSlider(productionNormal, productionUpgrade) {
    this.diffProductions = upgradeDifferenceProduction(productionNormal, productionUpgrade);
    this.afterGetDataRequests();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
