import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { map, startWith, take, tap } from "rxjs/operators";
import { Swiper } from "swiper/types";
import { SwiperOptions } from "swiper/types/swiper-options";

import { swiperInjectionStyles } from "../../../../../../../../../styles/swiper/swiper-injection-styles";
import { CUSTOM_MISSION_STAR_SUB_GROUPS } from "../../../../../../../../consts/custom/mission-star-sub-groups.const";
import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import {
  filterStarMissionByTypeAndSubGroupingHelper,
  missionWithoutStars,
} from "../../../../../../../../helpers/filterStarMissionByType.helper";
import { AppState } from "../../../../../../../../store/state";
import { UtilityActions } from "../../../../../../../../store/utility";
import { ProductPlayerService } from "../../../../../../../player/providers/product-player.service";
import { ApiMissionService } from "../../../../api/core/services/api-mission.service";
import { ApiMissionGroupService } from "../../../../api/core/services/api-mission-group.service";
import { EVENT_DIALOGS_NAMES_MISSION_CUSTOM } from "../../../../consts/custom/event-dialogs/event-names.const";
import { GroupMissionInterface, Mission } from "../../../../interfaces/core";
import { mockedMissionsGropus } from "./mocked-data";

@Component({
  selector: "app-star-missions",
  templateUrl: "./star-missions.component.html",
})
export class StarMissionsComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiMissionGroupService)
  apiMissionGroupService: ApiMissionGroupService;
  @OwInject(ApiMissionService) apiMissionService: ApiMissionService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef;
  @OwInject(MAT_DIALOG_DATA) public data: {
    mission?: Mission;
    afterCloseOpenList?: boolean;
    isMissionContract?: boolean;
  };

  swiper?: Swiper = null;
  initSwiper = false;
  @ViewChild("swiperRef", { static: false })
  swiperRef: ElementRef;
  swiperConfig: SwiperOptions = {
    centeredSlides: true,
    spaceBetween: 30,
    initialSlide: 0,
    slidesPerView: 3,
    injectStyles: [swiperInjectionStyles],
  };
  groupMissions: GroupMissionInterface[] = [];
  isLoading: boolean;
  test = { tick: 0, shouldBeRefreshed: false };
  missionFromSocket: GroupMissionInterface;
  filteredMissions: GroupMissionInterface[];

  ngOnInit() {
    this.dispatchShowedMissions();
    this.fetchAndComputeData();
  }

  dispatchShowedMissions() {
    setTimeout(() => {
      this.store.dispatch(new UtilityActions.SetHasMissionsToCollect(false));
    },1000);
  }

  fetchAndComputeData() {
    this.apiMissionGroupService
      .getMissionGroups()
      .pipe(
        // startWith(mockedMissionsGropus), // MOCKED DATA!!!!!
        take(1),
        tap(() => (this.isLoading = true)),
        tap(missions => console.log(missions)),
        map(missions => this.getActiveMissions(missions)),
        map(missions => this.setShowTrueWhenProuductBalanceHigherThanZero(missions)),
        map(missions => this.filterMissionByShowProperty(missions)), // UNLOCK WHEN PLAYER PRODUCTS DEFINITIONS READY
        map(missions => this.launchMissionDetailsFromSocket(missions)),
        tap(() => this.releaseLoadingWhenMissionIsNotFromSocket())
      )
      .subscribe(missions => {
        this.groupMissions = missions;
        this.setSwiper();
      });
  }

  setSwiper() {
    this.adjustSwiperPosition();
    this.initSwiper = true;
    this.changeDetectorRef.detectChanges();
    if (this.swiperRef?.nativeElement) {
      this.swiper = this.swiperRef.nativeElement.swiper;
      this.swiper.on("slideChange", swiper => {
        this.changeDetectorRef.detectChanges();
      });
    }
  }
  adjustSwiperPosition() {
    if (this.groupMissions.length >= 3) {
      this.swiperConfig.initialSlide = 1;
    } else {
      this.swiperConfig.initialSlide = 0;
    }
  }

  nextSlide() {
    this?.swiper?.slideNext();
  }

  prevSlide() {
    this?.swiper?.slidePrev();
  }

  getActiveMissions(groupMissions: GroupMissionInterface[]) {
    const missionsWithStars = filterStarMissionByTypeAndSubGroupingHelper(groupMissions);
    let missionsWithoutStars = missionWithoutStars(groupMissions);
    missionsWithoutStars = missionsWithoutStars.filter(m => !m.is_reward_collected);
    const activesMissionsWithStars = [];

    Object.entries(CUSTOM_MISSION_STAR_SUB_GROUPS).forEach(key => {
      const oneGroup = missionsWithStars.filter(g => g.sub_group_id === key[1]);
      const checkIfAllStarsCollected = oneGroup.every(g => g.is_reward_collected);
      const activeMission = oneGroup.find(groupMission => !groupMission.is_reward_collected);
      const setMission = activeMission || (checkIfAllStarsCollected ? oneGroup[oneGroup.length - 1] : undefined);
      activesMissionsWithStars.push(setMission);
    });

    const allActive = [...activesMissionsWithStars, ...missionsWithoutStars];
    const filteredActive: GroupMissionInterface[] = allActive.filter(a => a !== undefined);
    return filteredActive;
  }

  launchMissionDetailsFromSocket(missions: GroupMissionInterface[]) {
    if (this.data?.mission) {
      this.apiMissionService.getMissionDetails(this.data.mission.player_mission_id).subscribe(missionToOpen => {
        this.missionFromSocket = missionToOpen;
        if (this.missionFromSocket) {
          const findMissionGroupToOpen = missions.find(m => m.mission_group_id === this.missionFromSocket.mission_group_id);
          if (findMissionGroupToOpen) {
            this.openMissionDetails(findMissionGroupToOpen, true);
            this.dispatchShowedMissions();
          }
        }
      });
    }
    return missions;
  }

  releaseLoadingWhenMissionIsNotFromSocket() {
    if (!this.data?.mission) {
      this.isLoading = false;
    }
  }

  setShowTrueWhenProuductBalanceHigherThanZero(missions: GroupMissionInterface[]) {
    missions.forEach(a => {
      if (a.reward_product_id) {
        const product = this.productPlayerService.getProduct({ product_id: a.reward_product_id });
        a.show = product.balance > 0;
      } else {
        a.show = true;
      }
    });
    return missions;
  }

  filterMissionByShowProperty(missions: GroupMissionInterface[]) {
    return missions.filter(a => a.show);
  }

  openMissionDetails(mission: GroupMissionInterface, isOpenBySocket?: boolean) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MISSION_CUSTOM.MISSION_STAR_DETAILS,
      config: {
        data: {
          mission,
          isOpenBySocket,
        },
        disableClose: true,
      },
      callback: data => {
        if (data && data.playerMissionsReload) {
          this.fetchAndComputeData();
        }
      },
    });
  }
}
