import { AfterViewInit, Component, OnChanges, OnInit } from '@angular/core';
import { QA_TYPE } from '../../../../consts/core/qa-type.const';
import { AbstractQaSummaryComponent } from '../../../../abstract/core/abstract-qa-summary.component';

@Component({
  selector: 'app-custom-qa-summary',
  templateUrl: './qa-summary.component.html',
})
export class QaSummaryComponent extends AbstractQaSummaryComponent implements OnInit, OnChanges, AfterViewInit {
  QA_TYPE = QA_TYPE;
  HIDE_PRODUCT_ID_1 = 8307;
  HIDE_PRODUCT_ID_2 = 8308;
  IS_QA_PRIZES_DIVIDE = true;

  ngOnInit(): void {
    this.afterQa();
  }

  ngAfterViewInit() {
    this.checkAnyElScoreAndPrizes();
  }

  ngOnChanges() {
    this.afterQa();
    this.checkAnyElScoreAndPrizes();
  }
}
