export const CAMP_SCENE_CONFIG_KEY = "camp";
export const WORLD_SCENE_CONFIG_KEY = "world";
export const CLIMBING1_SCENE_CONFIG_KEY = "climbing_1";
export const CLIMBING2_SCENE_CONFIG_KEY = "climbing_2";
export const CLIMBING3_SCENE_CONFIG_KEY = "climbing_3";
export const CLIMBING4_SCENE_CONFIG_KEY = "climbing_4";
export const CLIMBING5_SCENE_CONFIG_KEY = "climbing_5";
export const CLIMBING6_SCENE_CONFIG_KEY = "climbing_6";

export const TEAM_SCENE_CONFIG_KEY = "team";

export const SKILL_TREE_CONFIG_KEY = "skill_tree";
