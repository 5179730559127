<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <ng-container *ngIf="buildingDetails">
    <div class="bookmarks">
      <div class="overlap active green">
        <div class="bookmark green">
          <span>{{ buildingDetails.name }}</span>
        </div>
      </div>

      <div class="levels-container">
        <div class="lvl-box">
          <span>Poziom</span>
          <div class="level">
            {{ buildingDetails.level }}
          </div>
        </div>
      </div>
    </div>

    <div class="top-info">
      <!-- GROUP BUILDING -->
      <div
        class="group-count"
        [matTooltip]="'Maksymalna ilość budynków'"
        [matTooltipShowDelay]="400"
        [matTooltipPosition]="'above'"
      >
        <m-ui-group-count [building]="buildingDetails" #groupCount></m-ui-group-count>
      </div>
    </div>

    <div class="top loading-opacity">
      <!-- BUILDING ICON -->
      <div class="icon-container">
        <img [src]="buildingDetails | buildingIcon:false">
      </div>

      <div class="right-column">
        <div
          class="description"
          [innerHTML]="buildingDetails.group_description"
        ></div>

        <div class="elements">
          <app-unlocked-parameters
            *ngIf="notOutcomeParameters?.length"
            [parameters]="notOutcomeParameters"
            [hideExtraValue]="true"
          ></app-unlocked-parameters>

          <app-unlocked-productions
            *ngIf="production"
            [production]="production"
            [hideExtraValue]="true"
          ></app-unlocked-productions>

          <app-unlocked-automatic-production
            *ngIf="automaticCurrency || automaticProduct"
            [groupType]="buildingDetails.group_type"
            [automaticCurrency]="automaticCurrency"
            [automaticProduct]="automaticProduct"
          ></app-unlocked-automatic-production>

          <app-unlocked-storage-value
            *ngIf="storageValue"
            [storageValue]="storageValue"
          ></app-unlocked-storage-value>
        </div>
      </div>
    </div>
  </ng-container>
</div>
