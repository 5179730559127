import * as R from "ramda";

import { AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME } from "../../../../constants";
import { isDailyProduction } from "../../../../game-gui/helpers/buildings.helper";
import { TileMenuCore } from "../core/TileMenuCore";
import { TILE_MENU_OFFSET_BY_ICON } from "../custom-tile-menu.config";

export class TileMenu extends TileMenuCore {
  async processAutoProduction() {
    this.autoProductionCombined = {
      currency: this.building.automatic_currency
        ? this.targetTile.gameService.productionService.currencyService.getCurrencyDefinition(this.building.automatic_currency)
        : null,
      product: this.building.automatic_product
        ? this.targetTile.gameService.productionService.productsService.getProduct(this.building.automatic_product)
        : null,
      amount: null,
    };
    if (this.autoProductionCombined.currency || this.autoProductionCombined.product) {
      this.hasAutoproduction = true;

      const dailyProduct = this.building["daily_product_productions"].find(
        product => product.product_id === this.building.automatic_product.product_id
      );
      this.autoProductionCombined.amount =
        dailyProduct?.amount || R.path(["additional_board_data", AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME], this.building) || 0;
    }

    const param = this.autoProductionCombined.currency || this.autoProductionCombined.product;
    if (param) {
      const unitValue = isDailyProduction(this.building.group_type) ? "/24h" : "/h";
      const autoprodContainer = this.myScene.add.container(0, 0);
      const autoprodIcon = this.myScene.add.image(0, -40, param.iconUrl);
      await this.targetTile.gameService.safeSetSpriteTexture(autoprodIcon, param.iconUrl);
      autoprodIcon.setOrigin(0.5);
      autoprodIcon.setDisplaySize(50, 50);
      autoprodContainer.add(autoprodIcon);
      const autoprodText = this.myScene.add.text(
        25,
        -40,
        `${this.autoProductionCombined.amount.toString()}${unitValue}`,
        this.config.levelTextStyles
      );
      autoprodText.setOrigin(0, 0.5);
      this.addShadow(autoprodText);
      this.add(autoprodContainer);
      autoprodContainer.add(autoprodText);
      this.mainTextObject.y += 15;
      this.additionalTextObject.y += 15;
      autoprodContainer.x = -(autoprodIcon.width / 2 + autoprodText.width) / 2;
    }
  }

  afterGenerate() {
    const config = TILE_MENU_OFFSET_BY_ICON[this.targetTile.playerBuildingData.icon];
    if (config) {
      if (config.x) {
        this.x -= config.x;
      }
      if (config.y) {
        this.y -= config.y;
      }
    }
  }
}
