import { Component, OnInit } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { OwDate } from "../../../../../../../core/utility/ow-date.class";
import { OwPagination, OwPaginationDate } from "../../../../../../../core/utility/ow-pagination.class";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { GetBusinessTaskRequest } from "../../../../san-business/api/interfaces/request/get-business-task-request.interface";
import { ApiBusinessService } from "../../../../san-business/api/services/api-business.service";
import {
  AvailabilityQualityTasks,
  QualityTasks,
} from "../../../../san-business/interfaces/custom/quality-tasks.interface";
import { STOCK_VIEW } from "../../../../shared-ui/mobile/consts/stock-view.const";

@Component({
  selector: "app-quality-tasks-cc",
  templateUrl: "./quality-tasks-cc.component.html",
})
export class QualityTasksCCComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;

  owDate: OwDate = new OwDate();
  paginationDate: OwPagination;
  qualityTasks: QualityTasks;
  availabilityQualityTasks: AvailabilityQualityTasks[];

  ngOnInit() {
    this.getData();
  }

  getData() {
    const { month, year } = this.owDate;

    const requestData: GetBusinessTaskRequest = {
      month,
      year,
    };

    this.getTasks(requestData);
  }

  getTasks(requestData: GetBusinessTaskRequest) {
    this.apiBusinessService.tasks(requestData).subscribe(
      data => {
        this.setDateAndPagination(data);
        this.qualityTasks = data.quality_tasks;
        this.availabilityQualityTasks = this.sortConstPrizesDescending(data.availability_quality_tasks)
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  sortConstPrizesDescending(objects: AvailabilityQualityTasks[]): AvailabilityQualityTasks[] {
    for (const obj of objects) {
      obj.const_prizes.sort((a, b) => b.currency_id - a.currency_id);
    }
    return objects;
  }

  setDateAndPagination({ year, month }) {
    this.owDate = new OwDate({ year, month });

    this.paginationDate = new OwPaginationDate({
      current: this.owDate,
    });
  }

  next() {
    this.owDate.add({ month: 1 });
    this.getData();
  }

  prev() {
    this.owDate.subtract({ month: 1 });
    this.getData();
  }

  protected readonly STOCK_VIEW = STOCK_VIEW;
}
