import { Component, Input, OnInit, QueryList, ViewChildren } from "@angular/core";
import { NgSelectComponent } from "@ng-select/ng-select";
import * as moment from "moment";
import { forkJoin } from "rxjs";

import { AbstractInjectBaseComponent } from "../../../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../../../core/decorators/ow-inject.decorator";
import { OwDate } from "../../../../../../../../../../core/utility/ow-date.class";
import { PlayerService } from "../../../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../../../shared/providers/dialog.service";
import { MyIdObject, RankLeagueResponse, RankLevel } from "../../../../../../../../interfaces/custom/rank.interface";
import { BusinessLeagueColor } from "../../../../../../../san-business/custom/helpers/business-league-color.helper";
import { STOCK_VIEW } from "../../../../../../../shared-ui/mobile/consts/stock-view.const";
import { ApiBusinessLeagueRankService } from "../../../../../../api/service/api-business-league-rank.service";
import { PAGINATION_TYPES } from "../../../../../../const/custom/pagination-types.const";
import { RANK_GROUP } from "../../../../../../const/custom/rank-group.const";
import { RankLeague } from "../../../../../../interfaces/custom/rank-league.interface";
import { SchemaFilterTitleDetails } from "../../../../../../interfaces/custom/rank-visibility-schema.interface";
import { getSchemaByGroupAndTitleId, showTabBySchema } from "../../../../helpers/rank-schema.helper";
import { Branch } from "../../../../../../../../interfaces/branch.interface";
import { PLAYER_TITLES } from "../../../../../../../../../../core/consts/core/player-titles";

@Component({
  selector: "app-rank-league-main",
  templateUrl: "./rank-league-main.component.html",
})
export class RankLeagueMainComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiBusinessLeagueRankService) apiBusinessLeagueRankService: ApiBusinessLeagueRankService;
  @Input() rank: RankLeague;
  @Input() owDate: OwDate;
  @Input() levels: RankLevel[];
  @Input() group: string;
  @Input() myIdObject: MyIdObject;
  @Input() pagination: string;
  @Input() branchName?: string;
  @Input() branch?: Branch;
  @ViewChildren(NgSelectComponent) viewChildrenSelect: QueryList<NgSelectComponent>;

  sites = [
    { id: 0, value: "Wszystkie Sieci" },
    { id: 1, value: "Sieć własna" },
    { id: 2, value: "Sieć partnerska" },
  ];
  groups = [{ league_group_id: 0, league_group_name: "Wszystkie Grupy" }];
  regions = [{ region_id: 0, name: "Wszystkie Regiony" }];
  activeSiteId: number = null;
  activeRegionId: number = null;
  activeGroupId: number = null;
  isDor: boolean;

  STOCK_VIEW = STOCK_VIEW;
  RANK_GROUP = RANK_GROUP;

  activeLevel: RankLevel;
  rankDetails: RankLeagueResponse;
  schema: SchemaFilterTitleDetails;
  tooltip: string;

  PAGINATION_TYPES = PAGINATION_TYPES;

  isLoading: boolean;

  ngOnInit() {
    this.activeSiteId = this.sites[0].id;
    this.activeRegionId = this.regions[0].region_id;
    this.activeGroupId = this.groups[0].league_group_id;
    this.isDor = this.playerService.player.title_id.id >= PLAYER_TITLES.DOR.id;

    this.setSchema();
    this.getRank();
    this.getFilters();
  }

  getFilters() {
    const date = moment(this.owDate.momentDate).endOf("month").format();

    forkJoin([this.apiBusinessLeagueRankService.groupList(), this.apiBusinessLeagueRankService.regionList(date)]).subscribe(
      ([groups, regions]) => {
        this.groups = [...this.groups, ...groups];
        this.regions = [...this.regions, ...regions];
        this.reRenderSelects();
      }
    );
  }

  reRenderSelects() {
    setTimeout(() => {
      this.viewChildrenSelect.forEach(selectComponent => {
        selectComponent.ngOnInit();
      });
    });
  }

  setSchema() {
    this.schema = getSchemaByGroupAndTitleId(this.group, this.myIdObject);
    this.activeLevel = showTabBySchema(this.levels, this.activeLevel, this.schema).activeLevel;
  }

  changeLevel(level: RankLevel) {
    this.activeLevel = level;
    this.getRank();
  }

  getRank() {
    this.isLoading = true;

    const requestParams = {
      year: +this.rank.year,
      group: this.group,
      type: this.rank.type,
      level: this.activeLevel.filterKey,
      group_id: this.activeSiteId || undefined,
      region_id: this.activeRegionId || undefined,
      league_group_id: this.activeGroupId || undefined,
      branch: this.branch,
    };

    let apiRequest;

    switch (this.pagination) {
      case PAGINATION_TYPES.MONTH:
        apiRequest = this.apiBusinessLeagueRankService.leagueRanksMonthDetails({
          month: +this.rank.month,
          ...requestParams,
        });
        break;

      case PAGINATION_TYPES.QUARTER:
        apiRequest = this.apiBusinessLeagueRankService.leagueRanksQuarterDetails({
          quarter: +this.rank.quarter,
          ...requestParams,
        });
        break;

      case PAGINATION_TYPES.HALF:
        apiRequest = this.apiBusinessLeagueRankService.leagueRanksHalfDetails({
          half: +this.rank.half,
          ...requestParams,
        });
        break;

      case PAGINATION_TYPES.YEAR:
        apiRequest = this.apiBusinessLeagueRankService.leagueRanksYearDetails({
          ...requestParams,
        });
        break;
    }

    if (apiRequest) {
      apiRequest.subscribe(
        (resp: RankLeagueResponse) => {
          this.rankDetails = resp;
          this.setMyPosition(this.rankDetails.my_position);
          this.setLeagueRowsName();
          this.isLoading = false;
        },
        errResp => {
          this.rankDetails = null;
          this.isLoading = false;
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
    }
  }

  setLeagueRowsName() {
    this.rankDetails.items.forEach(item => {
      item.colorObject = new BusinessLeagueColor().getLeagueObject(item.color);
    });
  }

  setMyPosition(position: number) {
    this.schema.currentMyPositionId = position;
  }
}
