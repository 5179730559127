import { Component, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Actions, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { take } from 'rxjs/operators';

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { ActionTypes } from "../../../../../../../../store/game/actions";
import { BoardTileState } from "../../../../../../../../store/game/interfaces/board-tile.state";
import { selectPlayer } from "../../../../../../../../store/player/selectors";
import { AppState } from "../../../../../../../../store/state";
import { Player } from "../../../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { ProductPlayerService } from "../../../../../../../player/providers/product-player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import {
  BUILDING_GROUP_TYPE_ANTYMINER,
  BUILDING_GROUP_TYPE_ANTYMINER_FINISHED, BUILDING_GROUP_TYPE_INFO_PROBLEM
} from '../../../../../../constants/game-custom.constants';
import { BoardTile } from "../../../../../../game-engine/classes/custom/BoardTile.class";
import { BoardService } from "../../../../../../services/board.service";
import { BuildingsService } from "../../../../../../services/buildings.service";
import { EVENT_DIALOGS_NAMES_ANTYMINER_CUSTOM } from '../../../../consts/custom/event-dialogs/event-names.const';
import {
  AntyminerCell,
  BuildingDataAntyminer,
  PlayerAntyminerBuildingDetailsExtra
} from '../../../../interfaces/antyminer.interface';
import {AntyminerFinds} from '../../../../enums/antyminer.enum';
import {AntyminerService} from '../../../../services/custom/antyminer.service';

@Component({
  selector: "app-antyminer-board",
  templateUrl: "./antyminer-board.component.html",
})
export class AntyminerBoardComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(BoardService) boardService: BoardService;
  @OwInject(AntyminerService) antyminerService: AntyminerService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(BuildingsService) buildingService: BuildingsService;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  @OwInject(MAT_DIALOG_DATA) data: BuildingDataAntyminer;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(Actions) storeActions: Actions;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AntyminerBoardComponent>;

  boardConfig = {
    boardName: "",
    playerBuildingId: null,
    currentLevel: null,
    hudMenuTooltipText: {
      steps: "Kondycja Janka.",
      trackerMove: "Po ilu ruchach odnajdziesz wszystkie obiekty.",
      soughtItems: "Ilość obiektów do odnalezienia na planszy.",
      hint: [
        "Z tym polem nie sąsiadują żadne obiekty",
        "Z tym polem sąsiaduje jeden obiekt",
        "Z tym polem sąsiadują dwa obiekty",
        "Z tym polem sąsiadują trzy obiekty",
        "Z tym polem sąsiadują cztery obiekty",
        "Z tym polem sąsiaduje pięć obiektów",
        "Z tym polem sąsiaduje sześć obiektów",
        "Z tym polem sąsiaduje siedem obiektów",
        "Z tym polem sąsiaduje osiem obiektów"
      ]
    },
    blocked: false
  };
  antyminerDefinition;

  boardTiles: Array<AntyminerCell[]> = [];
  hashMap: { [key: number]: any } = {};
  playerParentTileId: number;
  playerBuildingParentId: number;
  loaded = false;

  subs = {
    board: null,
    storeActions: null,
    player: null,
  };
  player: Player;

  boardResp: BoardTileState[] = [];

  parameters = {
    steps: null,
    max_turns: null,
    itemsFound: 0,
    itemsOnTheMap: 0,
  };
  buildingsDetails: PlayerAntyminerBuildingDetailsExtra = null;

  ngOnInit() {
    this.buildingsDetails = this.data.boardDetails.buildingDetails;
    this.subscribeTileUpdate();
    this.subscribePlayer();
  }

  subscribeTileUpdate() {
    // subscribe to actions to get the latest update from websocket
    this.subs.storeActions = this.storeActions
      .pipe(ofType(ActionTypes.BOARD_TILE_UPDATE))
      .subscribe((res: { type: string; payload: BoardTile }) => {
          if (this.hashMap[res.payload["player_tile_id"]]) {
            // socket refers to child building
            this.updateSingleBoardTile(res.payload);

            this.getBuildingDetails(this.boardConfig.playerBuildingId);
          }

          // check if socket refers to main building
          if (
            res.payload["player_tile_id"] === this.playerParentTileId &&
            (res.payload["player_building"]?.group_type === BUILDING_GROUP_TYPE_ANTYMINER ||
              res.payload["player_building"]?.group_type === BUILDING_GROUP_TYPE_ANTYMINER_FINISHED || BUILDING_GROUP_TYPE_INFO_PROBLEM) &&
            res.payload["player_building"]?.level !== this.data.playerBuildingLevel &&
            res.payload["player_building"]?.level % 2 === 1
          ) {
            this.boardConfig.blocked = true;
          }
      });
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(selectPlayer)).subscribe(player => {
      this.player = player;
      this.setParameters(); // refresh hud on the left of the board

      if (!this.boardResp.length) {
        // when tile is not available in the boardState(which is obtained on app start)
        if (this.data.tile) {
          this.setBoardTileData(this.data.tile);
        } else {
          this.subscribeBoardTile();
        }
      }
    });
  }

  subscribeBoardTile() {
    this.store
      .pipe(
        select(state => state.application.game.boardState.board),
        take(1)
      )
      .subscribe(tiles => {
        const tile = tiles.find(x => x.player_tile_id === this.data.playerTileId) || this.data.tile;
        this.setBoardTileData(tile);
      });
  }

  setBoardTileData(tile: BoardTileState) {
    this.playerParentTileId = tile.player_tile_id;
    this.playerBuildingParentId = this.data.playerBuildingId;

    this.boardConfig.playerBuildingId = this.data.playerBuildingId || tile.player_building.player_building_id;
    this.boardConfig.currentLevel = tile.player_building.level;
    this.antyminerDefinition = this.data.boardDetails;
    this.getBuildingDetails(this.boardConfig.playerBuildingId);
    this.getTile(tile.open_player_island_id);
  }

  setItemsToBeFoundAmount() {
    // check if we have all data needed
    this.parameters.itemsOnTheMap = this.buildingsDetails.antiminer_definition.prizes_count;
  }

  setParameters() {
    if (this.buildingsDetails) {
      this.parameters.max_turns = this.buildingsDetails.antiminer_progress.max_turns - this.buildingsDetails.antiminer_progress.moves;
    }

    this.parameters.steps = this.player.product_balances.find(p => p.product_id === 1001)?.balance || 0;
  }

  getTile(islandId: number) {
    this.boardService.getBoard(this.playerService.player.id, islandId).subscribe({
      next: resp => {
        this.boardResp = resp;
        this.generateBoard();
      },
    });
  }

  getBuildingDetails(player_building_id) {
    this.buildingService.getPlayerBuildingDetails(player_building_id).subscribe({
      next: buildingDetails => {
        this.buildingsDetails = buildingDetails;
        this.setItemsToBeFoundAmount()

        this.setParameters();
      },
    });
  }

  updateSingleBoardTile(tileUpdate) {
    const existingTile = this.boardTiles[tileUpdate.y_pos - 1]?.find(x => x.playerTileId === tileUpdate.player_tile_id);
    if (existingTile != null) {
      const newTileData = this.generateTileData(tileUpdate, existingTile.xPos, existingTile.yPos);

      this.boardTiles[tileUpdate.y_pos - 1][existingTile.xPos - 1] = newTileData;
      this.hashMap[newTileData?.playerTileId] = tileUpdate;

      const existingElIndex = this.boardResp.findIndex(x => x.player_tile_id === tileUpdate.player_tile_id);
      if (existingElIndex !== -1) {
        this.boardResp[existingElIndex] = tileUpdate;
      }

      this.markTileAsAvailable([{ x: existingTile.xPos - 1, y: tileUpdate.y_pos - 1 }]);
    }
  }

  // region logic
  discoverTheField(cell: AntyminerCell) {
    // no data
    if (!cell || !cell.playerBuildingId || this.boardConfig.blocked) {
      return;
    }

    // hide dialog when marked
    if (cell.marked) {
      return;
    }

    // discover bush
    if (cell.showBushes) {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_ANTYMINER_CUSTOM.ANTYMINER_START,
        config: {
          data: {
            playerBuildingId: cell.playerBuildingId,
            upgradeBuildingId: cell.upgradeBuildingId,
          },
          disableClose: true,
        },
      });

      return;
    }
  }

  // mark region with X letter
  markTheField(cell: AntyminerCell, event): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.boardConfig.blocked) {
      return;
    }

    this.boardConfig.blocked = true;
    if (cell.marked) {
      this.antyminerService.unmarkCell(cell.playerTileId).subscribe(res => {
        cell.marked = false;
        this.boardConfig.blocked = false;
      })
    } else {
      this.antyminerService.markCell(cell.playerTileId).subscribe(res => {
        cell.marked = true;
        this.boardConfig.blocked = false;
      })
    }
  }

  identifyObj(index, item) {
    return item.uniqueId;
  }
  // endregion

  // region board generation logic
  generateBoard() {
    /* reset tiles */
    this.boardTiles = [];

    /* dialog background */
    switch (this.antyminerDefinition.background) {
      default:
      case "field": {
        this.boardConfig.boardName = "field";
        break;
      }
      case "river": {
        this.boardConfig.boardName = "river";
        break;
      }
      case "forest": {
        this.boardConfig.boardName = "forest";
        break;
      }
    }

    /* generate board tiles struct */
    const setAsAvailable = [];

    // rows
    for (let i = 1; i <= this.antyminerDefinition.board.y; i++) {
      this.boardTiles.push([]);
      const currentRow = this.boardTiles[i - 1];

      // cells
      for (let j = 1; j <= this.antyminerDefinition.board.x; j++) {
        const dbTileData = this.boardResp.find(e => e.x_pos === j && e.y_pos === i);
        const tileData = this.generateTileData(dbTileData, j, i);

        if (!tileData.showBushes) {
          setAsAvailable.push({ x: j - 1, y: i - 1 });
        }

        currentRow.push(tileData);
        this.hashMap[dbTileData.player_tile_id] = currentRow[j - 1];
      }
    }

    this.markTileAsAvailable(setAsAvailable);

    this.loaded = true;
    this.setItemsToBeFoundAmount();
  }

  markTileAsAvailable(elToSetAsAvailable: { x: number; y: number }[]) {
    for (let item of elToSetAsAvailable) {
      // top
      if (this.boardTiles[item.y + 1]?.[item.x]) {
        this.boardTiles[item.y + 1][item.x].clickable = true;
        this.boardTiles[item.y + 1][item.x].uniqueId = self.crypto.randomUUID();
      }

      // bottom
      if (this.boardTiles[item.y - 1]?.[item.x]) {
        this.boardTiles[item.y - 1][item.x].clickable = true;
        this.boardTiles[item.y - 1][item.x].uniqueId = self.crypto.randomUUID();
      }

      // left
      if (this.boardTiles[item.y][item.x - 1]) {
        this.boardTiles[item.y][item.x - 1].clickable = true;
        this.boardTiles[item.y][item.x - 1].uniqueId = self.crypto.randomUUID();
      }

      // right
      if (this.boardTiles[item.y][item.x + 1]) {
        this.boardTiles[item.y][item.x + 1].clickable = true;
        this.boardTiles[item.y][item.x + 1].uniqueId = self.crypto.randomUUID();
      }
    }
  }

  // used for frontend tile generation
  generateTileData(fieldData, x: number, y: number): AntyminerCell {
    const cell: AntyminerCell = {
      background: this.determineTileBackgroundName(x, y, this.antyminerDefinition.board),
      showBushes: fieldData?.player_building?.group.includes("empty"),
      hint: fieldData.antiminer_hints?.[0]?.hint_level,
      item: this.determineItem(fieldData?.player_building?.icon, fieldData?.player_building?.level),
      playerBuildingId: fieldData?.player_building?.player_building_id,
      upgradeBuildingId: fieldData?.player_building?.upgrade_building_id,
      playerTileId: fieldData?.player_tile_id,
      xPos: x,
      yPos: y,
      uniqueId: self.crypto.randomUUID(),
      marked: fieldData?.is_marked,
      clickable: false,
    };

    /* decrease amount of creatures to be found if we're seeking poacher and we found poacher */
    if (cell.item?.name) {
      this.parameters.itemsFound++;
    }

    return cell;
  }

  determineTileBackgroundName(x: number, y: number, boardSize: { x: number; y: number }) {
    // first of all - remember that field are reversed(count from left bottom, exactly as it is in cartesian cords)
    // show nothing(y = 0; x = max)
    if (x === boardSize.x && y === 1) {
      return "grid-d";
    }

    // all cells in the last row
    if (x !== boardSize.x && y === 1) {
      return "grid-c";
    }

    // last element in a row
    if (x === boardSize.x && y !== 1) {
      return "grid-b";
    }

    // rest
    return "grid-a";
  }

  determineItem(iconName: string, level?: number) {
    const item = iconName?.substring(iconName?.indexOf("_") + 1)?.split("_") || null;
    if (!item) {
      return {
        name: "",
        text: "",
        variant: "",
      };
    }

    switch (iconName.toLowerCase().replace('map_', '')) {
      case AntyminerFinds.chemicals: {
        return {
          name: AntyminerFinds.chemicals,
          text: "Chemikalia",
          variant: ``,
        };
      }
      case AntyminerFinds.garbage: {
        return {
          name: AntyminerFinds.garbage,
          text: "Śmieci",
          variant: ``,
        };
      }
      case AntyminerFinds.chest: {
        return {
          name: AntyminerFinds.chest,
          text: "Skrzynia",
          variant: ``,
        };
      }
      case AntyminerFinds.natural: {
        return {
          name: AntyminerFinds.natural,
          text: "Nawóz naturalny",
          variant: ``,
        };
      }
      case AntyminerFinds.wood: {
        return {
          name: AntyminerFinds.wood,
          text: "Drewno",
          variant: ``,
        };
      }
      case AntyminerFinds.recycled: {
        return {
          name: AntyminerFinds.recycled,
          text: "Materiały z recyklingu",
          variant: ``,
        };
      }
      case AntyminerFinds.building: {
        return {
          name: AntyminerFinds.building,
          text: "Materiały budowlane",
          variant: ` `,
        };
      }
      default: {
        return null;
      }
    }
  }
  // endregion

  closeTrackingBoard() {
    this.dialogService.closeAll();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
