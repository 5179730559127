import { Component } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../../core/decorators/ow-inject.decorator";
import { STOCK_VIEW } from "../../../../../../shared-ui/mobile/consts/stock-view.const";
import { MicroLeagueMaxPrizesComponent } from "../../micro-league-tabs/micro-league-max-prizes/micro-league-max-prizes.component";
import { ContractItem } from "../contract-sale.component";

@Component({
  selector: "app-contract-clock-prizes",
  templateUrl: "./contract-clock-prizes.component.html",
  styleUrls: ["./contract-clock-prizes.component.scss"],
})
export class ContractClockPrizesComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<MicroLeagueMaxPrizesComponent>;
  @OwInject(MAT_DIALOG_DATA) data: { timePrizes: ContractItem["time_prizes"] };

  close(confirm?: boolean) {
    this.matDialogRef.close(confirm);
  }

  protected readonly STOCK_VIEW = STOCK_VIEW;
}
