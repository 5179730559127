import { Pipe, PipeTransform } from '@angular/core';
import { LeagueBonusTaskObject } from '../../interfaces/custom/league-bonus-task-object.interface';
import { BusinessLeagueBonusTask } from '../helpers/business-league-bonus-task.helper';

@Pipe({
  name: 'businessLeagueBonusTask'
})
export class BusinessLeagueBonusTaskPipe implements PipeTransform {
  transform(id): LeagueBonusTaskObject {
    return new BusinessLeagueBonusTask().getBonusTask(id);
  }
}
