import { Component, OnInit } from "@angular/core";

import { EVENT_DIALOGS_NAMES_QA } from "../../../../../qa/consts/core/event-dialogs/event-names.const";
import { AbstractMessageDetailsComicsComponent } from "../../../../abstract/core/abstract-message-details-comics.component";

@Component({
  selector: "app-message-details-comics",
  templateUrl: "./message-details-comics.component.html",
})
export class MessageDetailsComicsComponent extends AbstractMessageDetailsComicsComponent implements OnInit {
  ngOnInit() {
    this.afterInit();
  }

  openQaList() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA.QA_LIST,
      config: {
        data: {
          eachQaId: this.message.qa_info.qa_id,
          isShowBackButton: true,
        },
      },
    });
  }
}
