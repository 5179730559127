import { Injectable, reflectComponentType } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../core/abstracts/abstract-inject-base.component";
import { EVENT_DIALOGS_NAMES_CORE } from "../../../core/consts/core/event-dialogs/event-names.const";
import { OwInject } from "../../../core/decorators/ow-inject.decorator";
import { Alert, AlertConfirm, AlertImage } from "../../../core/interfaces/alert";
import { EventEmitterDialogsService } from "../../../core/services/core/event-emitter-dialogs.service";
import { transformErrorApi } from "../../../core/utility/transform-error-api";
import { ApiService } from "../../../core/providers/api.service";
import { take } from "rxjs/operators";
import { AnalyticsService } from "./analytics.service";

@Injectable()
export class DialogService extends AbstractInjectBaseComponent {
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(MatDialog) dialog: MatDialog;
  @OwInject(ApiService) apiService: ApiService;
  // @TODO:gutyo [change this property from static to normal] (problem: DialogService is not singleton)
  static openDialogs: any[] = [];

  public open(component: any, config: MatDialogConfig = { data: {} }, onCloseCallback?) {
    const dialogRef = this.dialog.open(component, config);
    DialogService.openDialogs.push(dialogRef);
    this.reportDialogOpen(reflectComponentType(component).selector, config.data);

    this.prepareBackground();


    dialogRef.afterOpened().subscribe(res => {
      this.reportDialogOpen(reflectComponentType(component).selector, config.data);
    })

    dialogRef.afterClosed().subscribe(data => {
      if (onCloseCallback) {
        onCloseCallback(data);
      }

      this.removeDialog(dialogRef);
    });

    return dialogRef;
  }

  // core override - dirty workaround which prevents closing info-problem-building on star mission close
  // make variable 'preventCloseAllOnDialogExit = true' to make it closeAll-proof
  public closeAll(force = false) {
    DialogService.openDialogs.forEach(dialogRef => {
      if (!dialogRef.componentInstance['preventCloseAllOnDialogExit'] || force) {
        dialogRef.close();
        dialogRef = null;
      }
    });
  }
  // core override end

  public openAlertErrorApi(data: { errResp: any } & Alert, callback?) {
    this.reportDialogOpen(EVENT_DIALOGS_NAMES_CORE.ALERT, data);
    if (data.errResp.status !== 500 && data.errResp.status !== 502 && data.errResp.status !== 0) {
      const transformedError = transformErrorApi(data.errResp);

      this.openAlert(
        {
          description: transformedError,
          ...data,
        },
        callback
      );
    }
  }

  public openAlert(data: Alert, callback?) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_CORE.ALERT,
      config: {
        data,
      },
      callback,
    });
  }

  public openConfirm(data: AlertConfirm, callback?) {
    this.reportDialogOpen(EVENT_DIALOGS_NAMES_CORE.ALERT_CONFIRM, data);
    const defaultAlertConfirm: AlertConfirm = {
      style: {
        maxWidth: "calc(400px * var(--multiply))",
        minWidth: "calc(400px * var(--multiply))",
      },
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_CORE.ALERT_CONFIRM,
      config: {
        data: { ...defaultAlertConfirm, ...data },
      },
      callback,
    });
  }

  public openAlertImage(data: AlertImage, callback?) {
    this.reportDialogOpen(EVENT_DIALOGS_NAMES_CORE.ALERT_IMAGE, data);
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_CORE.ALERT_IMAGE,
      config: {
        data,
      },
      callback,
    });
  }

  private removeDialog(dialogRef) {
    // REMOVE NULL/EMPTY ELEMENT START
    const idx = DialogService.openDialogs.indexOf(dialogRef);
    if (idx != -1) {
      delete DialogService.openDialogs[idx];
    }
    DialogService.openDialogs = DialogService.openDialogs.filter(dialog => {
      return dialog !== (undefined || "" || null);
    });
    // REMOVE NULL/EMPTY ELEMENT END

    this.prepareBackground({ close: true });
  }

  public closeActive() {
    return DialogService.openDialogs.pop().close();
  }

  private prepareBackground({ close }: { close?: boolean } = {}): void {
    let overlayClass = ".cdk-overlay-container .cdk-overlay-backdrop.cdk-overlay-dark-backdrop";

    if (close) {
      overlayClass += ".cdk-overlay-backdrop-showing";
    }

    const backdropArray = Array.from(document.querySelectorAll(overlayClass));
    backdropArray.forEach((node, index) => {
      node.classList.add("hide-background");

      if (index + 1 === backdropArray.length) {
        node.classList.remove("hide-background");
      }
    });

    this.hideDialog();
  }

  // report information about clicked dialog(statistics & analytics purpose)
  reportDialogOpen(componentSelector: string, data: { [key: string]: any } = {}) {
    try {
      // prevent 403 loop
      if (!window['storePlayer']) {
        return;
      }

      // allow only primitives
      const filteredData = {};
      for (const key of Object.keys(data)) {
        if (["string", "number", "boolean"].includes(typeof data[key])) {
          filteredData[key] = data[key];
        }
      }

      this.apiService
          .post(`analytics/event`, {
            body: {
              action: "OPEN",
              category: "DIALOG",
              label: componentSelector,
              value: JSON.stringify(filteredData)
            }
          })
          .subscribe(res => {
          });
    } catch (e) {
      // console.error(e)
    }
  }

  private hideDialog(): void {
    const dialogClass = ".cdk-overlay-container .cdk-global-overlay-wrapper .global-dialog";
    const dialogs = Array.from(document.querySelectorAll(dialogClass));

    dialogs.forEach((node, index) => {
      node.classList.add("hide-dialog");

      if (index + 1 === dialogs.length) {
        node.classList.remove("hide-dialog");
      }
    });
  }
}
