import { Component, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiBusinessLeagueService } from '../../../../api/services/api-business-league.service';
import { LeagueAchievementDetails } from '../../../../interfaces/custom/league-achievement.interface';

@Component({
  selector: 'app-league-achievement-details',
  templateUrl: './league-achievement-details.component.html',
})
export class LeagueAchievementDetailsComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiBusinessLeagueService) apiBusinessLeagueService: ApiBusinessLeagueService;
  @OwInject(MAT_DIALOG_DATA) data: {
    achievementId: number
  };

  achievement: LeagueAchievementDetails;

  ngOnInit(): void {
    if (this.data?.achievementId) {
      this.getAchievementDetails();
    }
  }

  getAchievementDetails() {
    this.apiBusinessLeagueService.achievementDetails(this.data.achievementId)
      .subscribe((resp) => {
        this.achievement = resp;
      });
  }
}
