import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../../core/providers/api.service";
import { ApiOptions } from "../../../../../../core/interfaces/api-options";
import { PlayerService } from "../../../../../player/providers/player.service";
import { Branch } from "../../../../interfaces/branch.interface";

@Injectable({
  providedIn: "root",
})
export class ApiBusinessRankService {
  constructor(
    private apiService: ApiService,
    private playerService: PlayerService
  ) {}

  businessRanks({
    year,
    month,
    contest_type,
    type = "business",
    branch,
  }: {
    year: number;
    month: number;
    contest_type?: number;
    type?: string;
    branch?: Branch;
  }) {
    const options: ApiOptions = {
      params: {
        year,
        month,
        type,
        contest_type,
      },
    };

    if (branch && !branch.manager_player_id) {
      return this.apiService.get(`branch/${branch.branch_id}/ranks`, options);
    }

    let player_id = branch?.manager_player_id;

    player_id = player_id || this.playerService.getActivePlayerId();
    return this.apiService.get(`player/${player_id}/ranks`, options);
  }

  patchRank(id, { is_favourite }: { is_favourite?: boolean }, player_id?: number) {
    const options: ApiOptions = {
      body: {
        is_favourite: is_favourite ? 1 : 0,
      },
    };

    player_id = player_id || this.playerService.getActivePlayerId();
    return this.apiService.patch(`player/${player_id}/ranks/${id}`, options);
  }

  rank({
    id,
    group_id,
    region_id,
    league_group_id,
    branch,
  }: {
    id: number;
    group_id?: number;
    region_id?: number;
    league_group_id?: number;
    branch?: Branch;
  }) {
    const options: ApiOptions = {
      params: {
        group_id,
        region_id,
        league_group_id,
      },
    };

    if (branch && !branch.manager_player_id) {
      return this.apiService.get(`branch/${branch.branch_id}/ranks/rank/${id}`, options);
    }

    let player_id = branch?.manager_player_id;
    player_id = player_id || this.playerService.getActivePlayerId();
    return this.apiService.get(`player/${player_id}/ranks/rank/${id}`, options);
  }

  rankCc({ id, level, group, player_id }: { id: number; level: string; group?: string; player_id?: number }) {
    const options: ApiOptions = {
      params: {
        level,
        group,
      },
    };

    player_id = player_id || this.playerService.getActivePlayerId();
    return this.apiService.get(`player/${player_id}/ranks/${id}`, options);
  }
}
