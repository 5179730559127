import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterRankDetails'
})

@Injectable()
export class FilterRankDetailsPipe implements PipeTransform {
  transform(items: any[], field: string, value: any, isActive?: boolean): any[] {
    if (!items) {
      return [];
    }
    if (!value || !isActive) {
      return items;
    }
    return items.filter(it => it[field] === value);
  }
}
