<div class="global-dialog ow-dialog c-window-short building-production-container">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks" *ngIf="building">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>{{ building.name }}</span>
      </div>
    </div>
  </div>

  <!-- PRODUCTIONS -->
  <div *ngIf="cyclesRequirements" class="h-static relative loading-opacity">
    <ow-perfect-scrollbar
      *ngIf="cyclesRequirements"
      [scrollGradient]="productions"
      class="loading-opacity"
    >
      <div class="row" *ngFor="let production of productions.items">
        <!-- BUILDING ROW PRODUCTION -->
        <app-building-row-production
          [production]="production"
          (requirementsStatus)="setRequirementsStatus($event, production)"
        ></app-building-row-production>

        <!-- START PRODUCTION -->
        <ng-container *ngIf="production.requirementsStatus">
          <div class="buttons-wrapper">
            <ng-container
              *ngIf="!(actualProduction && (actualProduction.production_recipe_id == production.production_recipe_id)); else cancelProductionTpl">
              <!-- NORMAL PRODUCTION -->
              <button
                class="c-square small c-secondary no-box-shadow production-play"
                [ngClass]="{'charity-dodo': data?.isCharityDodo}"
                [matTooltip]="(data?.isCharityDodo ? 'Finansuj program: ' : 'Rozpocznij produkcję: ') + ((production.time_in_days * 86400 || production.time_in_minutes * 60) | owDuration:2)"
                (click)="startProduction({recipeId: production.production_recipe_id})"
                [disabled]="!production.requirementsStatus.valid"
              >
                <i class="fas fa-play"></i>
              </button>
            </ng-container>
            <!-- CANCEL PRODUCTION -->
            <ng-template #cancelProductionTpl>
              <button
                class="c-square small c-primary no-box-shadow production-stop"
                [matTooltip]="data?.isCharityDodo ? 'Wstrzymaj program' : ('building-production.tooltip-cancel' | myTranslate)"
                (click)="cancelPlayerProductionConfirm()"
              >
                <i class="fas fa-stop"></i>
              </button>
            </ng-template>

            <!-- FAST ACTION -->
            <button
              class="c-square small c-primary no-box-shadow production-boost"
              (click)="openFastProductionConfirm(production)"
              [matTooltip]="'Akcja błyskawiczna'"
              *ngIf="!data?.isCharityDodo"
              [disabled]="!(production.fast_production_currency_prices.length > 0 && production.requirementsStatus.valid)"
            >
              <i class="fas fa-bolt"></i>
            </button>
          </div>
        </ng-container>
      </div>
    </ow-perfect-scrollbar>
  </div>

  <!-- CURRENT PRODUCTION PROGRESS -->
  <div class="prod-progress">
    <div class="current-production-text">
      <div class="heading">
        {{ actualProduction ? (data?.isCharityDodo ? 'Pozostały czas wdrożenia' : 'Aktualna produkcja') : (data?.isCharityDodo ? 'Żaden z programów nie jest wdrażany' : 'Brak aktualnej produkcji') }}
      </div>
    </div>

    <div class="current-production-progress">
      <div class="progressbar">
        <div
          class="fill fill-color-4"
          *ngIf="actualProduction"
          [style.width]="diffTime.percentage + '%'"
        ></div>
      </div>
      <div class="time">
        {{ actualProduction ? (diffTime.actualSeconds | owDuration) : '0m 0s' }}
      </div>
    </div>
  </div>
</div>
