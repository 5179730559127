import * as moment from "moment";

import { CB_ATLAS_KEY, CB_EDITABLE, CB_SPRITE_POSITIONS, CB_UI_KEY } from "../../catch-bananas.constans";
import { generateAnimationFramesList } from "../../catch-bananas.helpers";
import { CbThrowObjectEnum } from "../../enums/cb-throw-object.enum";
import { CatchBananasPlayScene } from "../../scenes/CatchBananasPlay.scene";
import { AbstractSprite } from "./abstract-sprite";
import { BananaSprite } from "./banana.sprite";
import { BananaRottenSprite } from "./banana-rotten.sprite";
import { BoxSprite } from "./box.sprite";
import { BoxGuiSprite } from "./box-gui.sprite";
import { ClockSprite } from "./clock.sprite";

export class MonkeySprite extends AbstractSprite {
  id: number;
  treeId: number;
  shouldJump: boolean;
  shouldThrow: boolean;
  jumpIntervalTime: number;
  throwIntervalTime: number;
  scene: CatchBananasPlayScene;
  CbThrowObjectEnum = CbThrowObjectEnum;
  fallenBox: BoxGuiSprite;

  constructor(scene: CatchBananasPlayScene, x: number, y: number, init?: Partial<MonkeySprite>) {
    super(scene, x, y, CB_ATLAS_KEY, "ape_jump-01.png");
    Object.assign(this, init);
    this.setIntervals();
  }

  setIntervals() {
    //bananas
    this.scene.time.addEvent({
      delay: this.throwIntervalTime,
      loop: true,
      callbackScope: this,
      callback: () => {
        if (this.scene.isPlaying && this.scene.catchBananasState.timeLeft > 2) {
          this.throw();
        }
      },
    });
    //jump
    this.scene.time.addEvent({
      delay: this.jumpIntervalTime,
      loop: true,
      callbackScope: this,
      callback: () => {
        if (this.scene.isPlaying && this.scene.catchBananasState.timeLeft > 2) {
          this.jump();
        }
      },
    });
  }

  throw() {
    if (this.shouldJump) {
      return;
    }
    this.anims.play("throw");
  }

  jump() {
    const previousTree = this.scene.catchBananasState.trees.find(tree => tree.id === this.treeId);
    const emptyTree = this.findEmptyTree();
    if (this.scene.isPlaying && this.scene.catchBananasState.timeLeft > 2) {
      previousTree.id > emptyTree.id ? this.setFlipX(true) : this.setFlipX(false);
      previousTree.isOccupied = false;
      emptyTree.isOccupied = true;
      this.treeId = emptyTree.id;
      this.scene.anims.play("monkey-jump", this);

      this.scene.add.tween({
        targets: this,
        repeat: 0,
        duration: 800,
        y: this.y,
        x: CB_SPRITE_POSITIONS.x[emptyTree.id],
        ease: Phaser.Math.Easing.Quadratic.InOut,
        onComplete: () => {
          this.setFlipX(false);
        },
      });
    }
  }

  createAnimations() {
    this.scene.anims.create({
      key: "monkey-jump",
      frames: generateAnimationFramesList(CB_ATLAS_KEY, "ape_jump", 8),
      delay: 0,
      frameRate: 8,
      repeat: 0,
    });
    this.scene.anims.create({
      key: "throw",
      frames: generateAnimationFramesList(CB_ATLAS_KEY, "ape_throw", 9),
      delay: 0,
      frameRate: 9,
      repeat: 0,
    });
  }

  handldeStartAnimation() {
    this.on("animationstart", event => {
      if (event.key === "throw") {
        this.shouldThrow = true;
      }
      if (event.key === "monkey-jump") {
        this.shouldJump = true;
      }
    });
  }

  handleUpdateAnimation() {
    this.on("animationupdate", event => {
      if (this.anims.currentFrame.index === 6 && event.key === "throw") {
        const throwType = this.checkThrowObjectType();
        const spriteConfig = {
          scene: this.scene,
          x: this.x,
          y: this.y - (this.height - 20),
        };
        switch (throwType.icon) {
          case this.CbThrowObjectEnum[0]:
            const now = moment();
            const then = this.scene.lastThrowTimeStamp;
            const diff = moment.duration(now.diff(then)).asSeconds();
            if (diff < 0.2) {
              return;
            }
            this.scene.lastThrowTimeStamp = moment();
            return new BananaSprite(spriteConfig);
          case this.CbThrowObjectEnum[1]:
            return new BananaRottenSprite(spriteConfig);
          case this.CbThrowObjectEnum[2]:
            return new ClockSprite(spriteConfig);
          case this.CbThrowObjectEnum[3]:
            return new BoxSprite(spriteConfig);
        }
      }
    });
  }

  handleCompleteAnimation() {
    this.on("animationcomplete", event => {
      if (event.key === "throw") {
        if (this.shouldJump) {
          this.jump();
        }
        this.shouldThrow = false;
      }

      if (event.key === "monkey-jump") {
        if (this.shouldThrow) {
          this.throw();
        }
        this.shouldJump = false;
      }
    });
  }

  private findEmptyTree() {
    return this.scene.catchBananasState.trees.find(tree => !tree.isOccupied);
  }

  private checkThrowObjectType() {
    const rottenChance = Math.random() < CB_EDITABLE.CB_ROTTEN_BANANA_CHANCE;
    const clockChance = Math.random() < CB_EDITABLE.CB_CLOCK_CHANCE;
    let result = {
      key: CB_ATLAS_KEY,
      icon: CbThrowObjectEnum[0],
    };
    if (this.fallenBox) {
      result = {
        key: CB_ATLAS_KEY,
        icon: "box.png",
      };
    } else {
      if (rottenChance && clockChance) {
        result.icon = CbThrowObjectEnum[1];
      } else if (clockChance) {
        result = {
          key: CB_UI_KEY,
          icon: CbThrowObjectEnum[2],
        };
      } else if (rottenChance) {
        result.icon = CbThrowObjectEnum[1];
      }
    }
    return result;
  }

  spriteConfiguration() {
    this.setDepth(1).setOrigin(0.5, 1);
  }
}
