import { Component, Input } from '@angular/core';
import { BUILDING_GROUP_TYPE_DAILY_PRODUCTION } from '../../../../../../constants';
import { STOCK_VIEW } from '../../../../../shared-ui/mobile/consts/stock-view.const';

@Component({
  selector: 'app-unlocked-automatic-production',
  templateUrl: './unlocked-automatic-production.component.html',
})
export class UnlockedAutomaticProductionComponent {
  @Input() automaticCurrency;
  @Input() automaticProduct;
  @Input() groupType;

  BUILDING_GROUP_TYPE_DAILY_PRODUCTION = BUILDING_GROUP_TYPE_DAILY_PRODUCTION;
  STOCK_VIEW = STOCK_VIEW;
}
