<div
  class="global-dialog ow-dialog c-window-long"
  *ngIf="data.rankId"
>
  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <m-ui-back-button></m-ui-back-button>

  <loading></loading>
</div>

<div
  class="global-dialog ow-dialog c-window-long"
  *ngIf="!data.rankId"
>
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <m-ui-back-button></m-ui-back-button>

  <ng-container *ngIf="activeTab">
    <!-- NAVIGATE CONTAINER -->
    <div class="navigate-container" *ngIf="!hideNavigation && activeTab.pagination != PAGINATION_TYPES.YEAR">
      <!-- NAVIGATION MONTH -->
      <div class="navigate-date" *ngIf="activeTab.pagination === PAGINATION_TYPES.MONTH">
        <!-- PREV -->
        <button class="prev" (click)="prev()" [disabled]="!owPaginationDate.allowPrev.month">
          <i class="fas fa-angle-left"></i>
        </button>

        <!-- CURRENT -->
        <div
          [matTooltip]="rankListBusiness && 'Dane aktualne na: ' + (rankListBusiness.rank_source_data_date | date:'dd-MM-y')"
          [matTooltipDisabled]="rankListBusiness && !rankListBusiness.rank_source_data_date"
          matTooltipPosition="above"
          class="current"
        >
          {{ owPaginationDate.current.month_text_normal }}
        </div>

        <!-- NEXT -->
        <div
          [matTooltip]="'Dane dostępne wkrótce.'"
          [matTooltipPosition]="'above'"
          [matTooltipDisabled]="owPaginationDate.allowNext.month"
        >
          <button
            class="next"
            (click)="next()"
            [disabled]="!owPaginationDate.allowNext.month"
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </div>
      </div>

      <!-- NAVIGATION QUARTER -->
      <div class="navigate-date" *ngIf="activeTab.pagination === PAGINATION_TYPES.QUARTER">
        <!-- PREV -->
        <button class="prev" (click)="prev()" [disabled]="!owPaginationDate.allowPrev.quarter">
          <i class="fas fa-angle-left"></i>
        </button>

        <!-- CURRENT -->
        <div class="current">{{ owPaginationDate.current.quarter_text_normal }}</div>

        <!-- NEXT -->
        <button class="next" (click)="next()" [disabled]="!owPaginationDate.allowNext.quarter">
          <i class="fas fa-angle-right"></i>
        </button>
      </div>

      <!-- NAVIGATION HALF -->
      <div class="navigate-date" *ngIf="activeTab.pagination === PAGINATION_TYPES.HALF">
        <!-- PREV -->
        <button class="prev" (click)="prev()" [disabled]="!owPaginationDate.allowPrev.half">
          <i class="fas fa-angle-left"></i>
        </button>

        <!-- CURRENT -->
        <div class="current">{{ owPaginationDate.current.half_text_normal }}</div>

        <!-- NEXT -->
        <button class="next" (click)="next()" [disabled]="!owPaginationDate.allowNext.half">
          <i class="fas fa-angle-right"></i>
        </button>
      </div>
    </div>

    <!-- TABS -->
    <div class="tabs">
      <ng-container *ngFor="let tab of tabs; let i = index">
        <div
          *ngIf="tab.visible"
          class="tab"
          [class.active]="tab == activeTab"
          [class]="tab.color"
          [style.z-index]="-i"
        >
          <div class="bookmarks">
            <div
              class="bookmark"
              [class]="tab.color"
              (click)="!tab.disabledTooltip && changeTab({tab: tab})"
              [ngbTooltip]="tab.disabledTooltip"
              placement="bottom"
            >
              <span>{{tab.label}}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- FRAME CONTENT -->
    <div class="frame loading-opacity">
      <div class="content">
        <ng-container *ngIf="(rankListGame || rankListBusiness || rankListLeague)">
          <div class="options" *ngIf="!isHideRanks && activeFilter">
            <!-- FILTERS -->
            <div class="filters">
              <ng-container *ngFor="let filter of filters">
                <div class="ow-radio-container" *ngIf="filter.show">
                  <label class="choose-wrapper">
                  <span class="checkbox-wrapper">
                    <input
                      type="radio"
                      name="ranksFilter"
                      (change)="changeFilter(filter)"
                      [checked]="activeFilter.valueApi == filter.valueApi"
                    >
                    <span class="checkmark"></span>
                  </span>
                    <span class="text-wrapper">
                    {{ filter.displayLabel }}
                  </span>
                  </label>
                </div>
              </ng-container>
            </div>

            <!-- DOR REPORT -->
            <div class="report-container" *ngIf="selectReports && activeTab.pagination !== PAGINATION_TYPES.HALF">
              <div class="role-select">
                <ng-select
                  [items]="selectReports"
                  bindLabel="label"
                  bindValue="dropdownId"
                  [clearable]="false"
                  [searchable]="false"
                  [(ngModel)]="currentReportId"
                ></ng-select>
              </div>

              <button
                (click)="downloadCsv(currentReportId)"
                [disabled]="!currentReportId"
              >
                <i class="far fa-file-download"></i>
              </button>
            </div>
          </div>

          <ng-container [ngTemplateOutlet]="ranksTpl"></ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div *ngIf="branchName" class="branch-name">{{ branchName }}</div>
</div>

<ng-template #ranksTpl>
  <ow-perfect-scrollbar scrollGradient>
    <!-- BUSINESS TASKS RANKS -->
    <ng-container *ngIf="activeTab.index === RANK_NAME.BUSINESS">
      <ng-container *ngIf="!isHideRanks; else hideRanksTpl">
        <table *ngIf="true">
          <tbody>
          <tr *ngFor="let rank of rankListBusiness.rankings[activeFilter?.valueApi]" [class]="{'insurance-competition': rank.card_template_id == CARD_TEMPLATE.INSURANCE_COMPETITION}">
            <td (click)="openRankBusinessDetails(rank)">
              {{ rank.name }}
              <div class="insurance-competition-border"></div>
            </td>
            <td class="favourite-cell">

              <!-- INSURANCE COMPETITION -->
              <div class="insurance-competition-icon">
                <img [src]="'ui_rice.png' | asset: 'ui'" />
              </div>

              <!-- FAVOURITE -->
              <div
                class="favourite"
                [class.is-favourite]="rank.is_favourite"
                (click)="favouriteRank($event, rank)"
              >
                <ng-container *ngIf="rank.loading; else favouriteBlockTpl">
                  <i class="fas fa-spinner-third fa-spin"></i>
                </ng-container>
                <ng-template #favouriteBlockTpl>
                  <i class="fas fa-star"></i>
                  <i class="far fa-star"
                     [ngbTooltip]="rank.is_favourite ? 'Odepnij z ulubionych' : 'Zaznacz jako ulubiony'"
                     placement="left"
                  ></i>
                </ng-template>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </ng-container>
    </ng-container>

    <!-- BUSINESS LEAGUE RANKS -->
    <ng-container *ngIf="activeTab.data === 'leagues'">
      <ng-container *ngIf="!isHideRanks; else hideRanksTpl">
        <ng-container *ngIf="activeTab.pagination !== PAGINATION_TYPES.HALF; else halfRanksTpl">
          <table *ngIf="rankListLeague?.rankings" class="league">
            <tbody>
            <tr *ngFor="let rank of rankListLeague.rankings[activeFilter?.valueApi]">
              <td (click)="openRankLeagueDetails(rank)">
                {{ rank.name }}
              </td>
            </tr>
            </tbody>
          </table>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #hideRanksTpl>
      <p class="hide-rank-info">Dla wybranego miesiąca brak danych.</p>
    </ng-template>

    <ng-template #halfRanksTpl>
      <div class="half-league-rank">
        <div class="desc">
          Sprawdź
          <ng-container *ngIf="activeFilter?.valueApi == RANK_GROUP.BRANCH">
            <a [href]="RANK_HALF_PDF.H1.BRANCH" target="_blank">TUTAJ</a>
          </ng-container>
          <ng-container *ngIf="activeFilter?.valueApi == RANK_GROUP.REGION">
            <a [href]="RANK_HALF_PDF.H1.REGION" target="_blank">TUTAJ</a>
          </ng-container>
          wyniki ligowe za <span>{{ owDate.half == 1 ? 'pierwsze' : 'drugie' }} półrocze</span>!
        </div>

        <div class="logo-league"></div>
      </div>
    </ng-template>

    <!-- GAME RANKS -->
    <table *ngIf="rankListGame" class="game">
      <tbody>
      <tr *ngFor="let rank of rankListGame">
        <td
          (click)="openRankGameDetails(rank)"
          [ngbTooltip]="rank.parameters && (rank.parameters.tooltipInList || rank.parameters.tooltipInListWithoutBlock)"
          container="body"
          placement="bottom-left"
          tooltipClass="rank pre-line"
        >
          {{ rank.name }}

          <div class="sparkle-ribbon" *ngIf="rank.parameters?.is_sparkle">
            <img [src]="'icon-iskierki.png' | asset:'ui'"/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </ow-perfect-scrollbar>
  <div
    class="cleared"
    *ngIf="isCleared"
  ></div>
</ng-template>
