import { Injectable } from "@angular/core";
import { filter } from "rxjs/operators";

import { GlobalService } from "../../../../../../core/providers/global.service";
import { PlayerService } from "../../../../../player/providers/player.service";
import { GAME_EVENTS } from "../../../../constants";
import { BASIC_BOOT_SCENE } from "../../../../game-engine/scenes-basic/basic.constants";
import {
  CAMP_SCENE_CONFIG_KEY,
  CLIMBING1_SCENE_CONFIG_KEY,
  CLIMBING2_SCENE_CONFIG_KEY,
  CLIMBING3_SCENE_CONFIG_KEY,
  CLIMBING4_SCENE_CONFIG_KEY,
  CLIMBING5_SCENE_CONFIG_KEY,
  CLIMBING6_SCENE_CONFIG_KEY,
  WORLD_SCENE_CONFIG_KEY,
  TEAM_SCENE_CONFIG_KEY, SKILL_TREE_CONFIG_KEY,
} from '../../../../constants/custom/game.const';

@Injectable({
  providedIn: "root",
})
export class ChangeMapService {
  campPlayerIslandId: number;
  climbing1PlayerIslandId: number;
  climbing2PlayerIslandId: number;
  climbing3PlayerIslandId: number;
  climbing4PlayerIslandId: number;
  climbing5PlayerIslandId: number;
  climbing6PlayerIslandId: number;
  worldPlayerIslandId: number;
  teamPlayerIslandId: number;
  skillTreePlayerIslandId: number;

  subs = {
    playerRegions: null,
  };

  constructor(private playerService: PlayerService, private globalService: GlobalService) {
    this.subscribePlayerRegions();
  }

  subscribePlayerRegions() {
    this.subs.playerRegions = this.playerService.playerRegions
      .pipe(
        filter(playerRegions => playerRegions != null))
      .subscribe(playerRegions => {

        const islands = playerRegions.regions.reduce((_islands, region) => {
          _islands.push(...region.islands);
          return _islands;
        }, []);

        // WORLD
        const world = islands.find(_island => _island.config_name === WORLD_SCENE_CONFIG_KEY);

        if (world) {
          this.worldPlayerIslandId = world.player_island_id;
        }

        // climbing
        const climbing1 = islands.find(_island => _island.config_name === CLIMBING1_SCENE_CONFIG_KEY);
        if (climbing1) {
          this.climbing1PlayerIslandId = climbing1.player_island_id;
        }

        const climbing2 = islands.find(_island => _island.config_name === CLIMBING2_SCENE_CONFIG_KEY);
        if (climbing2) {
          this.climbing2PlayerIslandId = climbing2.player_island_id;
        }

        const climbing3 = islands.find(_island => _island.config_name === CLIMBING3_SCENE_CONFIG_KEY);
        if (climbing3) {
          this.climbing3PlayerIslandId = climbing3.player_island_id;
        }

        const climbing4 = islands.find(_island => _island.config_name === CLIMBING4_SCENE_CONFIG_KEY);
        if (climbing4) {
          this.climbing4PlayerIslandId = climbing4.player_island_id;
        }

        const climbing5 = islands.find(_island => _island.config_name === CLIMBING5_SCENE_CONFIG_KEY);
        if (climbing5) {
          this.climbing5PlayerIslandId = climbing5.player_island_id;
        }

        const climbing6 = islands.find(_island => _island.config_name === CLIMBING6_SCENE_CONFIG_KEY);
        if (climbing6) {
          this.climbing6PlayerIslandId = climbing6.player_island_id;
        }

        // GARAGE
        const camp = islands.find(_island => _island.config_name === CAMP_SCENE_CONFIG_KEY);
        if (camp) {
          this.campPlayerIslandId = camp.player_island_id;
        }

        const team = islands.find(_island => _island.config_name === TEAM_SCENE_CONFIG_KEY);
        if (team) {
          this.teamPlayerIslandId = team.player_island_id;
        }

        const skillTree = islands.find(_island => _island.config_name === SKILL_TREE_CONFIG_KEY);
        if (skillTree) {
          this.skillTreePlayerIslandId = skillTree.player_island_id;
        }
      });
  }

  goToWorld(forceNewPlayerIsland?: number) {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: BASIC_BOOT_SCENE,
      data: {
        sceneConfigKey: WORLD_SCENE_CONFIG_KEY,
        playerIslandId: forceNewPlayerIsland ?? this.worldPlayerIslandId,
      },
    });
  }

  goToCamp() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: BASIC_BOOT_SCENE,
      data: {
        sceneConfigKey: CAMP_SCENE_CONFIG_KEY,
        playerIslandId: this.campPlayerIslandId,
      },
    });
  }

  goToTeam() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: BASIC_BOOT_SCENE,
      data: {
        sceneConfigKey: TEAM_SCENE_CONFIG_KEY,
        playerIslandId: this.teamPlayerIslandId,
      },
    });
  }

  goToSkillTree() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: BASIC_BOOT_SCENE,
      data: {
        sceneConfigKey: SKILL_TREE_CONFIG_KEY,
        playerIslandId: this.skillTreePlayerIslandId,
      },
    });
  }

  // default map; for non-playable players
  goToDefaultMap() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: BASIC_BOOT_SCENE,
    });
  }

  goToClimbingMap(climbing: string) {
    let configName: string = null;
    let playerIslandId: number = null;

    switch (climbing) {
      case 'climbing_1':
      default: {
        configName = CLIMBING1_SCENE_CONFIG_KEY;
        playerIslandId = this.climbing1PlayerIslandId;
        break;
      }

      case 'climbing_2': {
        configName = CLIMBING2_SCENE_CONFIG_KEY;
        playerIslandId = this.climbing2PlayerIslandId;
        break;
      }

      case 'climbing_3': {
        configName = CLIMBING3_SCENE_CONFIG_KEY;
        playerIslandId = this.climbing3PlayerIslandId;
        break;
      }

      case 'climbing_4': {
        configName = CLIMBING4_SCENE_CONFIG_KEY;
        playerIslandId = this.climbing4PlayerIslandId;
        break;
      }

      case 'climbing_5': {
        configName = CLIMBING5_SCENE_CONFIG_KEY;
        playerIslandId = this.climbing5PlayerIslandId;
        break;
      }

      case 'climbing_6': {
        configName = CLIMBING6_SCENE_CONFIG_KEY;
        playerIslandId = this.climbing6PlayerIslandId;
        break;
      }
    }

    if (configName == null || playerIslandId == null) {
      return;
    }

    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: BASIC_BOOT_SCENE,
      data: {
        sceneConfigKey: configName,
        playerIslandId: playerIslandId,
      },
    });
  }
}
