import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "noNegative",
})
export class NoNegativePipe implements PipeTransform {
  transform(value: number): number {
    return Math.max(0, value);
  }
}
