import { Injectable } from "@angular/core";
import {ApiService} from '../../../../../../core/providers/api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class AntyminerService {
  constructor(private apiService: ApiService) {
  }

  markCell(playerTileId: number): Observable<void> {
    return this.apiService.patch('/tracking/tracking/mark-tile/' + playerTileId);
  }

  unmarkCell(playerTileId: number): Observable<void> {
    return this.apiService.patch('/tracking/tracking/unmark-tile/' + playerTileId);
  }
}
