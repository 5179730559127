<div class="global-dialog ow-dialog {{boardConfig.boardName}}">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button (clicked)="closeTrackingBoard()" [preventDefault]="true"></m-ui-close-button>

  <div class="top-hud">
    <ng-container>
      <div class="board-pill"
           matTooltip="{{boardConfig.hudMenuTooltipText.steps}}"
           matTooltipPosition="right"
           [matTooltipShowDelay]="300">
        <img [src]="'fitness.png' | asset : 'products/small'" />
        <p>{{ loaded ? parameters.steps : '&nbsp;'}}</p>
      </div>
      <div class="board-pill"
           matTooltip="{{boardConfig.hudMenuTooltipText.trackerMove}}"
           matTooltipPosition="right"
           [matTooltipShowDelay]="300">
        <img [src]="'orientation.png' | asset : 'parameters/small'" />
        <p>{{ loaded ? parameters.max_turns : '&nbsp;' }}</p>
      </div>
      <div class="board-pill"
           matTooltip="{{boardConfig.hudMenuTooltipText.soughtItems}}"
           matTooltipPosition="right"
           [matTooltipShowDelay]="300">
        <img [src]="'search.png' | asset : 'ui'" />
        <p>{{ loaded ? parameters.itemsOnTheMap - parameters.itemsFound : '&nbsp;'}}</p>
      </div>
    </ng-container>
  </div>

  <div class="frame is-loading">
    <div class="board-wrapper">
      <div *ngFor="let row of boardTiles; let y = index"
           class="row">
        <div (click)="discoverTheField(cell)"
             (contextmenu)="markTheField(cell, $event)"
             *ngFor="let cell of row; let x = index; trackBy: identifyObj"
             [ngClass]="{
               'bush': cell.showBushes,
               'marked': cell.marked,
               'clickable': !data.isSpecialMode && !boardConfig.blocked || cell.clickable && data.isSpecialMode && !boardConfig.blocked,
               'blocked': boardConfig.blocked
             }"
             [ngStyle]="{
              'z-index': y+x
             }"
             class="cell {{cell.background}} {{boardConfig.boardName}}">
          <span class="cross"></span>
          <div *ngIf="cell.hint != null"
               class="hint v-{{cell.hint ?? 'unknown'}}"
               [title]="boardConfig.hudMenuTooltipText.hint[cell.hint ?? 0]"></div>
          <div *ngIf="cell.item?.name"
               title="{{cell.item?.text}}"
               class="items {{cell.item.name}}{{cell.item.variant || ''}}"></div>
        </div>
      </div>
    </div>
  </div>
</div>
