import { CB_EDITABLE } from "./catch-bananas.constans";

export const cbConfig = {
  background: {
    x: 0,
    y: 0,
    origin: {
      x: 0,
      y: 0,
    },
  },
  annie: { position: 1 },
  banana: {
    speed: CB_EDITABLE.CB_BANANA_VELOCITY,
  },
  monkeys: [
    {
      id: 1,
      jumpIntervalTime: CB_EDITABLE.CB_MONKERY_JUMP_INTERVAL,
      throwIntervalTime: CB_EDITABLE.CB_MONKEY_BANANA_THROW_INTERVAL + 500,
    },
    {
      id: 2,
      jumpIntervalTime: CB_EDITABLE.CB_MONKERY_JUMP_INTERVAL + 1130,
      throwIntervalTime: CB_EDITABLE.CB_MONKEY_BANANA_THROW_INTERVAL + 1125,
    },
    {
      id: 3,
      jumpIntervalTime: CB_EDITABLE.CB_MONKERY_JUMP_INTERVAL + 2480,
      throwIntervalTime: CB_EDITABLE.CB_MONKEY_BANANA_THROW_INTERVAL + 2500,
    },
  ],
  score: 0,
  time: 0,
  trees: [
    {
      id: 1,
      icon: "palm_a.png",
    },
    {
      id: 2,
      icon: "palm_b.png",
    },
    {
      id: 3,
      icon: "palm_c.png",
    },
    {
      id: 4,
      icon: "palm_d.png",
    },
  ],
  boxes: [
    {
      id: 1,
      throwTime: 0,
      icon: "box_d.png",
    },
    {
      id: 2,
      throwTime: 0,
      icon: "box_d.png",
    },
    {
      id: 3,
      throwTime: 0,
      icon: "box_d.png",
    },
  ],
};
