import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import * as R from "ramda";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { PLAYER_TITLES } from "src/app/core/consts/core/player-titles";

import { AbstractInjectBaseComponent } from "../../../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../../../core/decorators/ow-inject.decorator";
import { OwDate } from "../../../../../../../../../../core/utility/ow-date.class";
import { PlayerService } from "../../../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../../../../../shared-ui/mobile/consts/stock-view.const";
import { GetBusinessRankResponse } from "../../../../../../api/interfaces/response/get-business-rank.response.interface";
import { ApiBusinessLeagueRankService } from "../../../../../../api/service/api-business-league-rank.service";
import { ApiBusinessRankService } from "../../../../../../api/service/api-business-rank.service";
import { RANK_GROUP } from "../../../../../../const/custom/rank-group.const";
import { MyIdObject, RanksRankBusiness } from "../../../../../../interfaces/custom/rank-business.interface";
import { RankTask } from "../../../../../../interfaces/custom/rank-task.interface";
import { Branch } from "../../../../../../../../interfaces/branch.interface";
import { CARD_TEMPLATE } from "../../../../../../../san-business/consts/custom/card-template.const";

@Component({
  selector: "app-rank-business-base",
  templateUrl: "./rank-business-base.component.html",
})
export class RankBusinessBaseComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiBusinessRankService) apiBusinessRankService: ApiBusinessRankService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiBusinessLeagueRankService) apiBusinessLeagueRankService: ApiBusinessLeagueRankService;
  @OwInject(PlayerService) playerService: PlayerService;
  @Input() rank: RankTask;
  @Input() owDate: OwDate;
  @Input() group: string;
  @Input() myIdObject: MyIdObject;
  @Input() filters: { group_id: number; league_group_id: number; region_id: number };
  @Input() branch: Branch;
  @Input() branchName?: string;

  objectFilters: ObjectFilter[];

  activeSite;
  activeRegion;
  activeGroup;

  setFiltersOnce: boolean;

  RANK_GROUP = RANK_GROUP;
  STOCK_VIEW = STOCK_VIEW;
  PLAYER_TITLES = PLAYER_TITLES;

  loading: boolean;
  isDor: boolean;
  rankDetails: RanksRankBusiness;
  tooltip: string;
  otherPrizeKey: string;
  tooltipUser: string;

  currentButtonMyBranch = {
    id: null,
    filterKey: "branch_id",
    isActive: false,
  };

  ngOnInit() {
    this.isDor = this.playerService.player.title_id.id >= PLAYER_TITLES.DOR.id;
    this.setTooltipUser();
    this.setObjectFilter();
    this.getRank();
  }

  setTooltipUser() {
    if (this.group) {
      switch (this.group) {
        case "employee":
          this.tooltipUser = "gracza";
          break;
        case "branch":
          this.tooltipUser = "kierownika";
          break;

        case "region":
          this.tooltipUser = "DR";
          break;

        default:
          this.tooltipUser = "gracza";
          break;
      }
    }
  }

  getRank() {
    this.otherPrizeKey = null;
    const requestData = {
      id: this.rank.id,
      group_id: this.activeSite?.id || undefined,
      region_id: this.activeRegion?.region_id || undefined,
      league_group_id: this.activeGroup?.league_group_id || undefined,
      branch: this?.branch,
    };

    this.apiBusinessRankService.rank(requestData).subscribe(
      (resp: GetBusinessRankResponse) => {
        if (!this.setFiltersOnce) {
          this.objectFilters.forEach(filter => {
            if (!filter.show) {
              filter.show = resp.available_filters.some(f => f === filter.name);
            }
          });
          this.checkAvailableFilters();
        }
        this.rankDetails = R.clone(resp);
        this.owDate = new OwDate({
          year: resp.year,
          month: resp.month,
        });

        this.rankDetails.items = this.rankDetails.items.map(rank => {
          this.tooltipFormatted(rank);
          this.setAllPrizesOrder(rank);
          return rank;
        });

        this.currentButtonMyBranch.id = this.myIdObject.branch_id;
      },
      errResp => {
        this.rankDetails = null;
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  setObjectFilter() {
    this.objectFilters = [
      {
        name: "group_id",
        displayKey: "value",
        ngModel: "activeSite",
        show: false,
        items: [
          { id: 0, value: "Cała sieć" },
          { id: 1, value: "Sieć własna" },
          { id: 2, value: "Sieć partnerska" },
        ],
      },
      {
        name: "region_id",
        displayKey: "name",
        ngModel: "activeRegion",
        show: false,
        items: [{ region_id: 0, name: "Wszystkie Regiony" }],
        api: "regionList",
      },
      {
        name: "league_group_id",
        displayKey: "league_group_name",
        ngModel: "activeGroup",
        show: false,
        items: [{ league_group_id: 0, league_group_name: "Wszystkie Grupy" }],
        api: "groupList",
      },
    ];
  }

  checkAvailableFilters() {
    const req = [];
    const filterRegion = this.objectFilters.some(f => f.name === "region_id" && f.show);
    const filterLeague = this.objectFilters.some(f => f.name === "league_group_id" && f.show);
    let date;

    if (filterLeague || filterRegion) {
      this.objectFilters.forEach(filter => {
        if (filter.name !== "group_id" && filter.show) {
          if (filter.name === "region_id") {
            date = moment(this.owDate.momentDate).endOf("month").format();
          }

          req.push(
            this.apiBusinessLeagueRankService[filter.api](date).pipe(
              map(resp => {
                return {
                  filter,
                  resp,
                };
              })
            )
          );
        }
      });

      forkJoin(req).subscribe((resp: any[]) => {
        resp.forEach(item => {
          item.filter.items = [...item.filter.items, ...R.clone(item.resp)];
        });

        this.setDefaultFilters();
      });
    } else {
      this.setDefaultFilters();
    }
  }

  setDefaultFilters() {
    this.objectFilters.forEach(f => {
      switch (f.name) {
        case "group_id":
          this.activeSite = f.items.find((s: Sites) => s.id == (this.filters ? this.filters.group_id || 0 : 0));
          break;
        case "region_id":
          this.activeRegion = f.items.find(
            (r: Region) => r.region_id == (this.filters ? this.filters.region_id || 0 : this.myIdObject.region_id)
          );
          break;
        case "league_group_id":
          this.activeGroup = f.items.find(
            (g: League) => g.league_group_id == (this.filters ? this.filters.league_group_id || 0 : this.myIdObject.league_group_id)
          );
          break;
      }
    });

    this.setFiltersOnce = true;
    this.getRank();
  }

  showMyBranch() {
    if (!this.currentButtonMyBranch.isActive) {
      this.clearFilters();
      this.getRank();
    }

    setTimeout(() => {
      this.currentButtonMyBranch.isActive = !this.currentButtonMyBranch.isActive;
    }, 1000);
  }

  clearFilters() {
    this.objectFilters.forEach(f => {
      switch (f.name) {
        case "group_id":
          this.activeSite = f.items[0];
          break;
        case "region_id":
          this.activeRegion = f.items[0];
          break;
        case "league_group_id":
          this.activeGroup = f.items[0];
          break;
      }
    });
  }

  setAllPrizesOrder(rank) {
    if (rank.prizes?.currency) {
      rank.prizes.currency = this.setOrderWithCurrency(rank.prizes.currency);
    }

    if (rank.prizes?.product) {
      rank.prizes.product = this.setOrderWithProduct(rank.prizes.product);
    }
  }

  tooltipFormatted(rank) {
    rank.mins.tooltip_text = rank.mins?.tooltip_text?.replace(/<br[\/]*>/g, "\n");
  }

  setOrderWithCurrency(currencies) {
    // TODO check why false-positive tslint error
    // @ts-ignore
    const prizesSorted = R.sortWith([R.descend(R.prop("currency_id"))]);

    return prizesSorted(currencies);
  }

  setOrderWithProduct(products) {
    // TODO check why false-positive tslint error
    // @ts-ignore
    const prizesSorted = R.sortWith([R.descend(R.prop("product_id"))]);

    return prizesSorted(products);
  }

  protected readonly CARD_TEMPLATE = CARD_TEMPLATE;
}

interface ObjectFilter {
  name: string;
  displayKey: string;
  ngModel: string;
  show: boolean;
  items: any[];
  api?: string;
}

interface Sites {
  id?: number;
  value?: string;
}

interface Region {
  region_id?: number;
  name?: string;
}

interface League {
  league_group_id?: number;
  league_group_name?: string;
}
