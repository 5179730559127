<div class="global-dialog ow-dialog c-window-short history-transaction-container">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!--  DIALOG BACK-->
  <m-ui-back-button></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Historia</span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="items">
    <div class="frame loading-opacity">
      <table>
        <tbody>
        <tr *ngFor="let item of items">
          <td class="date">{{ item.transaction_at | date:'dd-MM-y'}}</td>
          <td class="name">{{ item.reason }}</td>
          <td class="currency">
            <div class="flex-center">
              {{ item.value > 0 ? '+' : '' }}{{ item.value | NumberWithSpaces }}

              <m-ui-currency
                [item]="data.currency"
                [lack]="false"
                [stockView]="STOCK_VIEW.A"
                [hideValue]="true"
              ></m-ui-currency>
            </div>
          </td>
          <td class="more">
            <button
              *ngIf="item.import_data_created_at"
              class="x-small"
              (click)="openBasicActions(item.import_data_created_at)"
            >
              <i class="fas fa-chevron-square-right"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <!-- NAVIGATION -->
    <div class="pagination" *ngIf="navigation.maxPage > 1">

      <!-- PREV -->
      <button
        (click)="pageChange(navigation.currentPage-1)"
        [disabled]="navigation.currentPage == 1"
      >
        <i class="far fa-chevron-square-left nav-arrow"></i>
      </button>

      <!-- MONTH -->
      <div class="value">
        <div class="number-current">{{ navigation.currentPage }}</div>
        <div class="separator">z</div>
        <div class="number-all">{{ navigation.maxPage }}</div>
      </div>

      <!-- NEXT -->
      <button
        (click)="pageChange(navigation.currentPage+1)"
        [disabled]="navigation.currentPage == navigation.maxPage"
      >
        <i class="far fa-chevron-square-right nav-arrow"></i>
      </button>
    </div>
  </ng-container>
</div>
