import { Component, Input } from "@angular/core";

import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";

@Component({
  selector: "app-unlocked-parameters",
  templateUrl: "./unlocked-parameters.component.html",
})
export class UnlockedParametersComponent {
  @Input() parameters: any[] = [];
  @Input() hideSubTitle: boolean;
  @Input() hideExtraValue: boolean;
  @Input() groupTypeBuilding = '';

  STOCK_VIEW = STOCK_VIEW;
}
