import { Types } from "phaser";

import { GameService } from "../../game/services/game.service";
import { GameObjectIds } from "./river-ride.config";
import { RiverRideService } from "./services/river-ride.service";

export interface RiverRideGameConfig extends Types.Core.GameConfig {
  gameService: GameService;
  riverRideService: RiverRideService;
  editMode: boolean;
  gameParams: RRGameParams;
  // sceneConfig: RiverRideGameplayConfig;
}

export interface RRGameObjectConfig {
  x: number;
  y: number;
  key: string;
  frame?: string;
}

// tslint:disable-next-line:no-empty-interface
export type RRBoatConfig = RRGameObjectConfig;

export interface RRRiverSegmentGameObjectDef {
  animationFrames?: string[] | number;
  timeValue: number;
  pointsValue: number;
  objectId?: GameObjectIds;
  key: string;
  movable?: boolean;
  size: number;
  destroyOnCollision?: boolean;
  isPremium?: boolean;
  collided?: boolean;
}

export interface RRRiverSegmentObjectConfig extends RRGameObjectConfig, RRRiverSegmentGameObjectDef {
  x: number;
  y: number;
  tweenDuration?: number;
  targetX?: number;
  targetY?: number;
}

export interface RRRiverSegment {
  id: number;
  difficulty: number;
  objects: RRRiverSegmentObjectConfig[];
  imagePath: string;
  maskPath: string;
}

export interface RiverRideGameplayConfig {
  minSpeed: number;
  maxSpeed: number;
  riverSegments: RRRiverSegment[];
}

export enum RREvents {
  LOAD_PROGRESS,
  LOAD_COMPLETE,
  GAME_START,
  GAME_PAUSE,
  GAME_RESUME,
  PROGRESS,
  GAME_OVER,
}

export interface RREvent {
  name: RREvents;
  value: any;
}

export interface RRGameParams {
  gameMode?: RRGameModes;
  state?: any;
}

export enum RRGameModes {
  NORMAL = 1,
  PREMIUM = 2,
}
