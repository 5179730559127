import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {filter, map, mergeMap, take} from 'rxjs/operators';

import {GAME_CONFIG} from '../../../../core/config/custom/_parsed-game.config';
import {PlayerActions, PlayerSelectors} from '../../../../store/player';
import {AppState} from '../../../../store/state';
import {ChangeMapService} from '../../../game/game-ui/hud/custom/services/change-map.service';
import {GameService} from '../../../game/services/game.service';
import {Player} from '../../../player/interfaces/player';
import {PlayerService} from '../../../player/providers/player.service';
import {DialogService} from '../../providers/dialog.service';
import {WORLD_SCENE_CONFIG_KEY} from '../../../game/constants/custom/game.const';
import {of} from 'rxjs';
import {GAME_EVENTS} from '../../../game/constants';
import {BASIC_BOOT_SCENE} from '../../../game/game-engine/scenes-basic/basic.constants';

@Injectable({
  providedIn: 'root',
})
export class ChangeSceneService {
  ALLOW_CHANGE_PLAYER = GAME_CONFIG.ALLOW_CHANGE_PLAYER;

  constructor(
    private store: Store<AppState>,
    private gameService: GameService,
    private dialogService: DialogService,
    private playerService: PlayerService,
    private changeMapService: ChangeMapService
  ) {
  }

  changePlayerToMe() {
    this.changePlayer(this.playerService.firstTimeMePlayerSnapshot);
  }

  changePlayer(player: Player) {
    if (!this.ALLOW_CHANGE_PLAYER) {
      return;
    }

    if (player.id === this.playerService.getActivePlayerId()) {
      this.dialogService.openAlert({
        description: 'Właśnie oglądasz tego gracza.',
      });
      return;
    }

    this.subscribePlayer({player});
    this.store.dispatch(new PlayerActions.FetchPlayer({playerId: player.id}));
  }

  private subscribePlayer({player}: { player: Player }) {
    this.store
      .pipe(
        select(PlayerSelectors.selectPlayer),
        map(player => {
          return player.id;
        }),
        filter(playerId => playerId === player.id),
        take(1)
      )
      .subscribe(playerId => {
        this.gameService.guiService.isSplashShow.next(true);
        this.dialogService.closeAll();

        // TODO - refactor this code; racing
        // inside the changeMapService you may find region subscription which is looking for current islands
        // because of the application flow it is always late, sometimes its fine(you're downloading data which is ok for the moment)
        // but sometimes you're receiving 403 with forbidden. Before refactor definitely you should simplify the flow of getting board, regions and player
        // then eventually you may take this piece of code, see #28339
        if (!this.playerService.player.title_id.playable) {
          // in this scenario we dont care about the regions and board - it can be outdated
          this.changeMapService.goToDefaultMap();
        } else {
          // get the regions from the player you're switching to
          this.gameService.boardService.getWorld(playerId)
            .subscribe((regions) => {
              let worldIsland = regions.find(x => x.islands.find(island => island.config_name === WORLD_SCENE_CONFIG_KEY));

              if (!worldIsland) {
                this.gameService.guiService.isSplashShow.next(false);
                return;
              }

              this.changeMapService.goToWorld(worldIsland.player_island_id);
            });
        }
      });
  }
}
