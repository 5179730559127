<div class="main-container loading-opacity">
  <div *ngIf="!isLoading && items?.length">
    <div class="media-list">
      <div
        class="media-row"
        *ngFor="let item of items"
      >
        <div class="left">
          <div class="icon" [style.background-image]="'url(' + item.thumb + ')'">
          </div>
        </div>
        <div class="right">
          <div class="heading">
            <div class="title">
              <a href="{{ item.url }}" target="_blank">
                {{ item.name }}
              </a>
            </div>
            <div class="description">
              {{ item.description }}
            </div>
          </div>
          <div class="terms">
            {{ item.category.name }}<br />
            {{ item.created_at | date:'y-MM-dd' }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="maxPages > 1"
    class="pagination"
  >
    <!-- PREV -->
    <button
      (click)="previous()"
      [disabled]="currentPage === 1"
    >
      <i class="far fa-chevron-square-left nav-arrow"></i>
    </button>

    <!-- VALUE -->
    <div class="value">
      {{ currentPage }} z {{ maxPages }}
    </div>

    <!-- NEXT -->
    <button
      (click)="next()"
      [disabled]="currentPage >= maxPages"
    >
      <i class="far fa-chevron-square-right nav-arrow"></i>
    </button>
  </div>
</div>
