<div class="options loading-opacity">
  <!-- FILTERS -->
  <div class="filters" *ngIf="activeFilter">
    <ng-container *ngFor="let filter of filters">
      <div class="ow-radio-container" *ngIf="filter.show">
        <label class="choose-wrapper">
          <span class="checkbox-wrapper">
            <input
              type="radio"
              name="ranksFilterWf"
              (change)="changeFilter(filter)"
              [checked]="activeFilter.valueApi === filter.valueApi"
            >
            <span class="checkmark"></span>
          </span>
          <span class="text-wrapper">
            {{ filter.label }}
          </span>
        </label>
      </div>
    </ng-container>
  </div>
</div>

<perfect-scrollbar [scrollGradient]="rankListGame">
  <table>
    <tbody>
    <!-- RANKS GAME -->
    <ng-container *ngFor="let rank of rankListGame">
      <tr *ngIf="!isCC || (isCC && rank.parameters && rank.parameters.show_only === activeFilter.valueApi)">
        <td
          (click)="openRankGameDetails(rank)"
          [ngbTooltip]="rank.parameters && (rank.parameters.tooltipInList || rank.parameters.tooltipInListWithoutBlock)"
          container="body"
          placement="bottom-left"
          tooltipClass="rank pre-line"
        >
          {{ rank.name }}
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</perfect-scrollbar>
