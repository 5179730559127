import { Component, Input } from "@angular/core";

@Component({
  selector: "app-unlocked-parameters-upgrade",
  templateUrl: "./unlocked-parameters-upgrade.component.html",
})
export class UnlockedParametersUpgradeComponent {
  @Input() parameters: any[] = [];
  @Input() hideSubTitle: boolean;
}
