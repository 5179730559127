import { Component } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { STOCK_VIEW } from "src/app/modules/game/game-ui/shared-ui/mobile/consts/stock-view.const";

import { AbstractInjectBaseComponent } from "../../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../../core/decorators/ow-inject.decorator";
import { PlayerMicroLeagueDetails } from "../../../../../interfaces/custom/business-challenges.interface";

@Component({
  selector: "app-micro-league-max-prizes",
  templateUrl: "./micro-league-max-prizes.component.html",
  styleUrls: ["./micro-league-max-prizes.component.scss"],
})
export class MicroLeagueMaxPrizesComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<MicroLeagueMaxPrizesComponent>;
  @OwInject(MAT_DIALOG_DATA) data: PlayerMicroLeagueDetails["available_prizes"];

  close(confirm?: boolean) {
    this.matDialogRef.close(confirm);
  }

  protected readonly STOCK_VIEW = STOCK_VIEW;
}
