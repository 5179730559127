import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../../../core/providers/api.service";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { Observable } from "rxjs";
import { PostSettingsRequestInterface } from "../interfaces/request/post-settings-request.interface";
import { ApiOptions } from "../../../../../../../core/interfaces/api-options";
import { PostPasswordRequestInterface } from "../interfaces/request/post-password-request.interface";
import { GetPlayerSettingsResponse } from "../interfaces/response/get-player-settings-response.interface";

@Injectable({
  providedIn: "root",
})
export class ApiPlayerService {
  constructor(
    private apiService: ApiService,
    private playerService: PlayerService
  ) {}

  getPlayerSettings(playerId?: number): Observable<GetPlayerSettingsResponse> {
    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.get(`player/${playerId}/settings`);
  }

  postSettings(data: PostSettingsRequestInterface) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
    };
    return this.apiService.post("settings", options);
  }

  postPassword(data: PostPasswordRequestInterface) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
    };
    return this.apiService.post("user/change-password", options);
  }

  getBranchesPlayers(branchId?: number) {
    branchId = branchId || this.playerService.player.branch_id;
    return this.apiService.get(`branches/${branchId}/players`);
  }

  branches({ page, limit, year, month, player_id }: { page: number; limit?: number; year?: number; month?: number; player_id?: number }) {
    const options: ApiOptions = {
      params: {
        page,
        limit,
        year,
        month,
      },
      responseWithHeaders: true,
    };

    if (player_id) {
      return this.apiService.get(`player/${player_id}/branches`, options);
    }

    return this.apiService.get(`branches`, options);
  }
}
