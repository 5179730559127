<div class="global-dialog ow-dialog c-window-long">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button></m-ui-back-button>

  <div *ngIf="data?.branchName" class="branch-name">{{ data?.branchName }}</div>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Ranking - {{ data.rank.name }}</span>
      </div>
    </div>
  </div>

  <ng-container [ngSwitch]="data.rank.type">
    <!-- RANK LEAGUE RESULTS -->
    <app-rank-league-results
      *ngSwitchCase="RANK_LEAGUE_TYPES.RESULTS"
      [rank]="data.rank"
      [owDate]="data.owDate"
      [levels]="levels"
      [group]="data.group"
      [myIdObject]="data.myIdObject"
      [pagination]="data.pagination"
      [branchName]="data.branchName"
      [branch]="data.branch"
    ></app-rank-league-results>

    <!-- RANK LEAGUE MAIN -->
    <app-rank-league-main
      *ngSwitchCase="RANK_LEAGUE_TYPES.RANK"
      [rank]="data.rank"
      [owDate]="data.owDate"
      [levels]="levels"
      [group]="data.group"
      [myIdObject]="data.myIdObject"
      [pagination]="data.pagination"
      [branchName]="data.branchName"
      [branch]="data.branch"
    ></app-rank-league-main>

    <!-- RANK LEAGUE TYPE -->
    <app-rank-league-type
      *ngSwitchDefault
      [rank]="data.rank"
      [owDate]="data.owDate"
      [levels]="levels"
      [group]="data.group"
      [myIdObject]="data.myIdObject"
      [pagination]="data.pagination"
    ></app-rank-league-type>

    <div
      *ngIf="data.rank.is_cleared"
      class="cleared"
    ></div>
  </ng-container>
</div>
