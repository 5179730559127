import { Component, HostBinding } from "@angular/core";
import { of, timer } from 'rxjs';
import { debounce } from "rxjs/operators";

import { GameService } from "../../../game/services/game.service";
import { GuiService } from "../../../game/services/gui.service";

@Component({
  selector: "app-splash-screen",
  templateUrl: "./splash-screen.component.html",
})
export class SplashScreenComponent {
  @HostBinding("class.active") isShow: boolean = true;

  constructor(public guiService: GuiService, public gameService: GameService) {
    this.guiService.isSplashShow
      .pipe(
        debounce(value => {
          return value ? of(false) : timer(250);
        })
      )
      .subscribe(value => {
        this.isShow = value;
      });
  }
}
