import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../core/providers/api.service';
import { ApiOptions } from '../../../../../../../core/interfaces/api-options';
import { GetMediaListRequest } from '../interfaces/request/get-media-list-request.interface';
import { Observable } from 'rxjs';
import { GetMediaListResponse } from '../interfaces/response/get-media-list-response.interface';
import { GetMediaCategoryResponse } from '../interfaces/response/get-media-category-response.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiMediaService {

  constructor(
    private apiService: ApiService,
  ) {
  }

  getMediaList({
                 source_id,
                 category_id,
                 limit,
                 page
               }: GetMediaListRequest): Observable<{ headers: any, body: GetMediaListResponse }> {
    const options: ApiOptions = {
      params: {
        category_id,
        page,
        limit
      },
      responseWithHeaders: true
    };

    return this.apiService.request('GET', `/media/${source_id}`, options);
  }

  getMediaCategories({source_id}): Observable<GetMediaCategoryResponse> {
    return this.apiService.request('GET', `/media/${source_id}/categories`);
  }

}
