import { Component, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import { ApiEventShopService } from '../../../../api/custom/services/api-event-shop.service';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../../../shared/providers/dialog.service';
import { EventShop } from '../../../../api/custom/interfaces/response/get-event-shops.interface';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrencyService } from '../../../../../../../../core/providers/currency.service';
import { EventShopProduct } from '../../../../api/custom/interfaces/response/get-event-shops-products.interface';
import { PlayerService } from '../../../../../../../player/providers/player.service';
import {
  EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY
} from '../../../../../transaction-history/consts/core/event-dialogs/event-names.const';
import { EventEmitterDialogsService } from '../../../../../../../../core/services/core/event-emitter-dialogs.service';
import { GADGET_SHOP_ID, GADGET_SHOP_ID_2, GADGET_SHOP_ID_3 } from '../../../../consts/custom/gadget-shop.const';
import { UserService } from '../../../../../../../user/providers/user.service';
import { EventShopRank } from '../../../../api/custom/interfaces/response/get-event-shops-ranks.interface';
import { ShopColorsEnum } from '../../../../consts/custom/shop-colors.enum';
import { EventShopProductDetails } from '../event-shop-product-details/event-shop-product-details';
import {ClRankListComponent} from '../../../../../hud/custom/dialogs/cl/cl-rank-list/cl-rank-list.component';

@Component({
  selector: 'event-shop-details',
  templateUrl: './event-shop-details.html',
})
export class EventShopDetails extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiEventShopService) apiEventShopService: ApiEventShopService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data: { eventShop: EventShop & { currencyId: number }, color?: number; hideBackButton?: boolean };
  @OwInject(CurrencyService) currencyService: CurrencyService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(UserService) userService: UserService;

  colors = ShopColorsEnum;
  products: EventShopProduct[];
  ranks: EventShopRank[] = [];

  GADGET_SHOP_ID = GADGET_SHOP_ID;
  GADGET_SHOP_ID_2 = GADGET_SHOP_ID_2;
  GADGET_SHOP_ID_3 = GADGET_SHOP_ID_3;

  get hasEdenredCard() {
    return this.userService.me['has_edenred_card'];
  }

  ngOnInit() {
    this.getRanks();
    this.getEventShopsProducts();
  }

  getEventShopsProducts() {
    this.apiEventShopService.getEventShopsProducts({event_shop_id: this.data.eventShop.event_shop_id})
      .subscribe((resp) => {
        this.products = resp;
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }

  openHistoryTransaction(currency) {
    if (currency.show_currency_history) {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY.HISTORY,
        config: {
          data: {
            currency,
            showBackBar: true,
          }
        }
      });
    }
  }

  openEventShopProductDetails(product: EventShopProduct) {
    this.dialogService.open(
      EventShopProductDetails,
      {
        data: {
          eventShop: this.data.eventShop,
          product: product
        },
      },
      (value) => {
        if (value) {
          this.ngOnInit();
        }
      }
    );
  }

  getRanks() {
    this.apiEventShopService.eventShopsRanks({
      event_shop_id: this.data.eventShop.event_shop_id
    })
      .subscribe((resp) => {
        this.ranks = resp;
      });
  }

  openRanks() {
    this.dialogService.open(ClRankListComponent, {
      data: {
        event_shop_id: this.data.eventShop.event_shop_id,
        ranks: this.ranks,
      }
    });
  }
}
