<div class="global-dialog ow-dialog c-window-long no-transparent"
     [class.location-50]="qa.location === 50">

  <!-- TABS -->
  <div class="tabs">
    <div
      class="tab active"
      [class]="qa?.type == QA_TYPE.QUIZ ? 'yellow' : 'blue-light'"
    >
      <div class="bookmarks">
        <div
          class="bookmark"
          [class]="qa?.type == QA_TYPE.QUIZ ? 'yellow' : 'blue-light'"
        >
          <span>{{ qa?.type == QA_TYPE.QUIZ ? 'Quiz' : 'Ankieta' }}</span>
        </div>
      </div>
    </div>
  </div>

  <!-- TIMER -->
  <div class="timer" *ngIf="qa?.last_attempt && !qa?.last_attempt.finished_at && qa?.type === QA_TYPE.QUIZ">
    <i class="fas fa-stopwatch"></i> <span>{{ timer | toHour }}</span>
  </div>

  <ng-container *ngIf="(qa.all_questions || qa.last_attempt.questions) as questions">
    <ng-container *ngIf="questions[currentQuestionIndex - 1] as question">
      <div class="qa-container loading-opacity">
        <ow-perfect-scrollbar [scrollGradient]="currentQuestionIndex">
          <!--QUESTION-->
          <div class="question">
            <div class="top elem-flex">
              <span *ngIf="questions.length > 0" class="question-tag">{{ currentQuestionIndex }}</span>
              <p
                [innerHTML]="question.content"
                owEllipsis
              ></p>
            </div>

            <!-- QUESTION IMAGE URL -->
            <button
              (click)="openImage(question.image_url)"
              *ngIf="question.image_url"
              class="image-container qa"
            >
              <img [src]="question.image_url">
            </button>
          </div>
          <!--ANSWERS-->
          <ng-container *ngIf="qa.all_questions || !question.is_open">
            <ng-container *ngFor="let answer of question.answers">
              <div class="elem-flex answer" [class.align]="answer.image_url">
                <!-- ANSWER CHECKBOX WHEN START QA -->
                <ng-container *ngIf="qa.last_attempt && !qa.last_attempt.finished_at">
                  <div
                    class="elem-flex checkbox-button"
                    (click)="submitAnswer(question, answer)"
                    *ngIf="!question.is_open"
                  >
                    <i
                      [ngClass]="answer.qa_answer_id === question.selected_answer?.qa_answer_id ? 'fas fa-check-square' : 'far fa-minus-square'"
                    ></i>
                  </div>
                </ng-container>

                <!-- ANSWER CHECKBOX WHEN SEE THE ANSWERS -->
                <ng-container *ngIf="qa.all_questions || qa.last_attempt.finished_at">
                  <div class="elem-flex checkbox-button cursor">

                    <ng-container *ngIf="qa.type === QA_TYPE.SURVEY">
                      <i
                        *ngIf="question.selected_answer?.qa_answer_id == answer.qa_answer_id"
                        class="fas fa-check-square cursor"
                      ></i>

                      <i
                        *ngIf="question.selected_answer?.qa_answer_id !== answer.qa_answer_id"
                        class="far fa-minus-square cursor"
                      ></i>
                    </ng-container>

                    <ng-container *ngIf="qa.type === QA_TYPE.QUIZ">
                      <i
                        *ngIf="question.selected_answer?.qa_answer_id !== answer.qa_answer_id"
                        class="far fa-minus-square cursor"
                      ></i>

                      <i
                        *ngIf="question.selected_answer?.qa_answer_id === answer.qa_answer_id && answer.is_correct === true"
                        class="fas fa-check-square correct cursor"
                      ></i>

                      <i
                        *ngIf="question.selected_answer?.qa_answer_id === answer.qa_answer_id && answer.is_correct === false"
                        class="fa fa-times-circle in-correct cursor"
                      ></i>

                      <!-- answer result unknown -->
                      <i
                        *ngIf="question.selected_answer?.qa_answer_id === answer.qa_answer_id && answer.is_correct === null"
                        class="far fa-minus-square indeterminated cursor"
                      ></i>
                    </ng-container>
                  </div>
                </ng-container>

                <button
                  class="image-container"
                  *ngIf="answer.image_url" (click)="openImage(answer.image_url)"
                >
                  <img [src]="answer.image_url">
                </button>

                <!-- ANSWER CONTENT -->
                <p
                  [class.correct]="qa.type == QA_TYPE.QUIZ && (qa.all_questions || qa.last_attempt.finished_at) && answer.is_correct"
                  [class.in-correct]="qa.type == QA_TYPE.QUIZ && qa.last_attempt?.finished_at && question.selected_answer?.qa_answer_id == answer.qa_answer_id && answer.is_correct === false"
                  [class.indeterminated]="qa.type == QA_TYPE.QUIZ && qa.last_attempt?.finished_at && question.selected_answer?.qa_answer_id == answer.qa_answer_id && answer.is_correct === null"
                >
                  {{ answer.content }}
                </p>

                <!-- ANSWER IMAGE URL -->
                <button
                  *ngIf="answer.explanation"
                  (click)="openExplanation(answer)"
                  class="checkbox-button"
                >
                  <i class="fas fa-info-square"></i>
                </button>
              </div>
            </ng-container>
          </ng-container>

          <!-- ANSWER TEXTAREA -->
          <div *ngIf="qa.last_attempt && question.is_open" class="md-form">
                <textarea [(ngModel)]="textAnswers[currentQuestionIndex - 1]"
                          [attr.disabled]="qa.last_attempt.finished_at"
                          [placeholder]="'qa.qa-details.form.placeholder' | myTranslate"
                          class="md-textarea form-control"
                          id="md-7"
                >{{ textAnswers[currentQuestionIndex - 1] }}</textarea>
          </div>
        </ow-perfect-scrollbar>
      </div>

      <!-- LOADING -->
      <loading></loading>

      <!-- BUTTON EXIT -->
      <m-ui-close-button></m-ui-close-button>

      <!-- BACK BAR -->
      <m-ui-back-button
        *ngIf="qa?.all_questions || qa?.last_attempt && qa?.last_attempt.finished_at"
      ></m-ui-back-button>

      <div *ngIf="qa.last_attempt || qa.all_questions" class="navigation">
        <button
          (click)="prevQuestion()"
          [class.hide]="currentQuestionIndex - 1 === 0"
          class="c-base c-primary"
        >
          Wstecz
        </button>

        <!-- FINISH QA -->
        <ng-container *ngIf="qa.last_attempt && !qa.last_attempt.finished_at && currentQuestionIndex === questions.length">
          <button
            (click)="checkFinishQa()"
            [disabled]="!(question.selected_answer || question.qa_text_answer || textAnswers[currentQuestionIndex - 1])"
            class="c-base c-primary"
          >
            {{ (qa.type === QA_TYPE.QUIZ ? 'qa.qa-details.end-quiz' : 'qa.qa-details.end-survey') | myTranslate }}
          </button>
        </ng-container>

        <!-- SEE THE SCORE -->
        <ng-container *ngIf="qa.last_attempt?.finished_at && qa.type === QA_TYPE.QUIZ">
          <button
            (click)="seeScore(false)"
            class="c-base c-primary"
          >
            {{ 'qa.qa-details.see-score' | myTranslate }}
          </button>
        </ng-container>

        <button
          (click)="checkNextQuestion()"
          [disabled]="(qa.last_attempt && !qa.last_attempt.finished_at && !(question.selected_answer || question.qa_text_answer || textAnswers[currentQuestionIndex - 1]))"
          [class.hide]="currentQuestionIndex >= questions.length"
          class="c-base c-primary"
        >
          Dalej
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
