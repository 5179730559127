<div class="global-dialog ow-dialog c-window-long">
  <loading></loading>

  <!-- BUTTON EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- TABS -->
  <div class="tabs">
    <ng-container *ngFor="let tab of tabs; let i = index">
      <div
        *ngIf="tab.visible"
        class="tab"
        [class.active]="tab == activeTab"
        [class]="tab.color"
        [style.z-index]="-i"
      >
        <div class="bookmarks">
          <div
            class="bookmark"
            [class]="tab.color"
            (click)="changeTab(tab)"
          >
            <span>{{tab.label}}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="container loading-opacity" *ngIf="items">
    <ow-perfect-scrollbar scrollGradient>
      <table>
        <tbody>
        <tr *ngFor="let item of items" (click)="openQaList(item)">
          <td class="name">{{ item.name }}</td>

          <td class="valid">
            {{ (item.type === QA_TYPE.QUIZ ? 'qa.qa-list.expiry-date-quiz' : 'qa.qa-list.expiry-date-survey') | myTranslate:[item['canAnswerFrom'], item['canAnswerTo']] }}
          </td>
        </tr>
        </tbody>
      </table>
    </ow-perfect-scrollbar>
  </div>
</div>
