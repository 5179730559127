import { Component, OnDestroy, OnInit } from "@angular/core";
import { select } from "@ngrx/store";
import * as R from "ramda";
import { forkJoin } from "rxjs";
import { filter } from "rxjs/operators";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { GlobalService } from "../../../../../../../../core/providers/global.service";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { BoardTileState } from "../../../../../../../../store/game/interfaces/board-tile.state";
import { selectGameBoardTile } from "../../../../../../../../store/game/selectors";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { AbstractBuildingSpecialComponent } from "../../../../abstract/core/abstract-building-special.component";
import { getNotOutcomeParameters, getOutcomeParameters } from "../../../../helpers/custom/outcome-parameters.helper";

@Component({
  selector: "app-custom-building-special-increase",
  templateUrl: "./building-special-increase.component.html",
})
export class BuildingSpecialIncreaseComponent extends AbstractBuildingSpecialComponent implements OnInit, OnDestroy {
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(PlayerService) playerService: PlayerService;
  boardTile: BoardTileState;

  notOutcomeParameters: any[];
  outcomeParameters: any[];

  notOutcomeParametersUpgrade;
  outcomeParametersUpgrade;

  notOutcomeParametersDiffParameters;

  outcomeParametersDiffParameters;
  outcomeParametersRequirements;

  ngOnInit() {
    if (this.data["boardTile"]) {
      this.afterBoard(this.data["boardTile"]);
    } else {
      this.subscribeBoardTile();
    }
  }

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameBoardTile, { playerTileId: this.data.playerTileId }),
        filter(state => !!state)
      )
      .subscribe(tile => {
        this.afterBoard(tile);
      });
  }

  afterBoard(tile: BoardTileState) {
    this.boardTile = tile;
    this.buildingDetails = R.clone(tile.player_building);
    if (this.boardTile.player_building.upgrade_building_id) {
      this.combineBuildDetails();
    } else {
      this.getOnlyBuildingDetails();
    }
  }

  getOnlyBuildingDetails() {
    this.buildingsService.getPlayerBuildingDetails(this.boardTile.player_building.player_building_id).subscribe(buildingDetails => {
      this.buildingDetails = R.mergeDeepRight(this.buildingDetails, buildingDetails) as any;
    });
  }

  setParameters() {
    this.notOutcomeParameters = getNotOutcomeParameters(this.upgradeBuildingDetails.parameters);
    this.outcomeParameters = getOutcomeParameters(this.upgradeBuildingDetails.parameters);
  }

  combineBuildDetails() {
    forkJoin([
      this.buildingsService.getPlayerBuildingDetails(this.boardTile.player_building.player_building_id),
      this.buildingsService.getBuildingDetails(this.boardTile.player_building.upgrade_building_id),
    ]).subscribe(([buildingDetails, upgradeBuildingDetails]) => {
      this.buildingDetails = R.mergeDeepRight(this.buildingDetails, buildingDetails) as any;
      this.upgradeBuildingDetails = upgradeBuildingDetails;

      this.setDifferentGroup();
      this.getUnlockedBuildings();
      this.getUpgradeDifferenceParameters();
    });
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }

  close() {
    this.matDialogRef.close();
  }
}
