import { CB_ATLAS_KEY, CB_BANANA_END_ANIMATION } from "../../catch-bananas.constans";
import { generateAnimationFramesList } from "../../catch-bananas.helpers";
import { CbAcraceSpriteConfig } from "../../interfaces/cb-arcade-sprite-config.interface";
import { AbstractArcadeSprite } from "./abstract-arcade-sprite";

export class BoxSprite extends AbstractArcadeSprite {
  velocity: number;

  constructor(config: CbAcraceSpriteConfig) {
    super(config.scene, config.x, config.y, CB_ATLAS_KEY, "box.png");
    this.velocity = this.scene.catchBananasState.velocity;
    this.scene.add.existing(this);
    this.setDepth(3);
    this.setVelocityY(this.velocity);
  }

  collectObject() {
    this.scene.catchBananasState.annie.collectSoundEmitter.emit("collect-wood");
    this.scene.catchBananasState.boxes[this.scene.catchBananasState.chestsCatched].setFrame("box.png");
    this.scene.catchBananasState.boxes[this.scene.catchBananasState.chestsCatched].icon = "box.png";
    this.scene.catchBananasState.chestsCatched += 1;
    this.deleteFallenBox();
    super.collectObject();
  }

  destroyObject() {
    this.deleteFallenBox();
    super.destroyObject();
    this.anims.play("box_destroy");
    this.on("animationcomplete", () => {
      this.destroy();
    });
  }

  private deleteFallenBox() {
    const box = this.scene.catchBananasState.monkeys.find(monkey => monkey.fallenBox);
    if (box) {
      box.fallenBox = null;
    }
  }

  createAnimations() {
    this.scene.anims.create({
      key: "box_destroy",
      frames: generateAnimationFramesList(CB_ATLAS_KEY, "box_destroy", 5),
      delay: 0,
      frameRate: 10,
      duration: CB_BANANA_END_ANIMATION,
      repeat: 0,
    });
  }
}
