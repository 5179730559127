<div
  class="cash-container"
  *ngIf="player && user"
>
  <ng-container *ngFor="let guiItem of this.guiItems">
    <div
      class="position-relative"
      *ngIf="guiItem.player_island_id === gameService.lastPlayerIslandId || guiItem.player_island_id === null"
    >
      <ng-container
        *ngIf="guiItem.currency_id && (guiItem.type === 'user' ? playerService.isActiveMe : true)"
        [ngTemplateOutletContext]="{guiItem: guiItem}"
        [ngTemplateOutlet]="currencyTpl"
      ></ng-container>
      <ng-container
        *ngIf="guiItem.product_id && (guiItem.type === 'user' ? playerService.isActiveMe : true)"
        [ngTemplateOutletContext]="{guiItem: guiItem}"
        [ngTemplateOutlet]="productTpl"
      ></ng-container>
    </div>

    <!--CURRENCY-->
    <ng-template
      #currencyTpl
      let-guiItem="guiItem">
      <div
        #tooltip="ngbTooltip"
        (click)="openHistoryTransaction(guiItem.currency, tooltip)"
        (mousedown)="tooltip.open()"
        [ngbTooltip]="guiItem.tooltip && tooltipTpl"
        [class.initial-cursor]="!guiItem?.show_currency_history"
        class="cash-block"
        container="body"
        tooltipClass="hud delayed"
        placement="right"
        [class.shop-btn]="guiItem.currency.currency_id === 3 && ((!isCC && playerService.player.gui_unlocks['market']) || (isCC && ((!playerService.player.title_id.playable && playerService.player.gui_unlocks['market']) || (playerService.player.title_id.playable && playerService.player.gui_unlocks['cc_market_playable']))))"
      >
        <div
          [style.background-image]="'url('+(guiItem.icon + '.png' | asset:'currency/hud') +')'"
          class="icon big"
        ></div>
        <div class="number">
          {{ guiItem.resourceBalance | NumberWithSpaces }}
        </div>

        <button
          (click)="$event.stopPropagation(); openShop()"
          *ngIf="guiItem.currency.currency_id === 3 && ((!isCC && playerService.player.gui_unlocks['market']) || (isCC && ((!playerService.player.title_id.playable && playerService.player.gui_unlocks['market']) || (playerService.player.title_id.playable && playerService.player.gui_unlocks['cc_market_playable']))))"
          class="c-square small c-primary currency-icon"
        >
          <i class="fas fa-shopping-cart"></i>
        </button>
      </div>
    </ng-template>

    <!--PRODUCTS-->
    <ng-template
      #productTpl
      let-guiItem="guiItem"
    >
      <div
        #tooltip="ngbTooltip"
        (mousedown)="tooltip.open()"
        [ngbTooltip]="guiItem.tooltip && tooltipTpl"
        [class.initial-cursor]="true"
        class="cash-block"
        container="body"
        tooltipClass="hud delayed"
        placement="right"
      >
        <div
          [style.background-image]="'url('+(guiItem.icon + '.png' | asset:'products/hud') +')'"
          class="icon"
        ></div>
        <div class="number">
          {{ guiItem?.resourceBalance | NumberWithSpaces }}
        </div>
      </div>
    </ng-template>

    <ng-template #tooltipTpl>
      <div
        class="fw-normal"
        [innerHTML]="guiItem.tooltip"
      ></div>
    </ng-template>
  </ng-container>
</div>
