import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../../core/providers/api.service";
import { ApiOptions } from "../../../../../../core/interfaces/api-options";
import { Branch } from "../../../../interfaces/branch.interface";

@Injectable({
  providedIn: "root",
})
export class ApiBusinessLeagueRankService {
  constructor(private apiService: ApiService) {}

  /**
   * MONTH LIST
   */
  leagueRanksMonth({ year, month, branch }: { year: number; month: number; branch?: Branch }) {
    return this.apiService.get(`leagues${branch ? "-manager" : ""}/ranks/month/${year}/${month}`);
  }

  /**
   * QUARTER LIST
   */
  leagueRanksQuarter({ year, quarter, branch }: { year: number; quarter: number; branch?: Branch }) {
    return this.apiService.get(`leagues${branch ? "-manager" : ""}/ranks/quarter/${year}/${quarter}`);
  }

  /**
   * HALF LIST
   */
  leagueRanksHalf({ year, half, branch }: { year: number; half: number; branch?: Branch }) {
    return this.apiService.get(`leagues${branch ? "-manager" : ""}/ranks/halfyear/${year}/${half}`);
  }

  /**
   * YEAR LIST
   */
  leagueRanksYear({ year, branch }: { year: number; branch?: Branch }) {
    return this.apiService.get(`leagues${branch ? "-manager" : ""}/ranks/year/${year}`);
  }

  /**
   * MONTH DETAILS
   */
  leagueRanksMonthDetails({
    year,
    month,
    group,
    type,
    level,
    group_id,
    region_id,
    league_group_id,
    branch,
  }: {
    year: number;
    month: number;
    group: string;
    type: string;
    level?: string;
    group_id?: number;
    region_id?: number;
    league_group_id?: number;
    branch?: Branch;
  }) {
    if (level == "country") {
      level = null;
    }

    const options: ApiOptions = {
      params: {
        level,
        group_id,
        region_id,
        league_group_id,
      },
    };

    if (branch) {
      return this.apiService.get(`branch/${branch.branch_id}/leagues/rank/month/${year}/${month}/${group}/${type}`, options);
    }

    return this.apiService.get(`leagues/rank/month/${year}/${month}/${group}/${type}`, options);
  }

  /**
   * QUARTER DETAILS
   */
  leagueRanksQuarterDetails({
    year,
    quarter,
    group,
    type,
    level,
    group_id,
    region_id,
    league_group_id,
    branch,
  }: {
    year: number;
    quarter: number;
    group: string;
    type: string;
    level?: string;
    group_id?: number;
    region_id?: number;
    league_group_id?: number;
    branch?: Branch;
  }) {
    if (level == "country") {
      level = null;
    }

    const options: ApiOptions = {
      params: {
        level,
        group_id,
        region_id,
        league_group_id,
      },
    };
    if (branch) {
      return this.apiService.get(`branch/${branch?.branch_id}/leagues/rank/quarter/${year}/${quarter}/${group}/${type}`, options);
    }

    return this.apiService.get(`leagues/rank/quarter/${year}/${quarter}/${group}/${type}`, options);
  }

  /**
   * HALF DETAILS
   */
  leagueRanksHalfDetails({
    year,
    half,
    group,
    type,
    level,
    group_id,
    region_id,
    league_group_id,
  }: {
    year: number;
    half: number;
    group: string;
    type: string;
    level?: string;
    group_id?: number;
    region_id?: number;
    league_group_id?: number;
  }) {
    if (level === "country") {
      level = null;
    }

    const options: ApiOptions = {
      params: {
        level,
        group_id,
        region_id,
        league_group_id,
      },
    };

    return this.apiService.get(`leagues/rank/half/${year}/${half}/${group}/${type}`, options);
  }

  /**
   * YEAR DETAILS
   */
  leagueRanksYearDetails({
    year,
    group,
    type,
    level,
    group_id,
    region_id,
    league_group_id,
  }: {
    year: number;
    group: string;
    type: string;
    level?: string;
    group_id?: number;
    region_id?: number;
    league_group_id?: number;
  }) {
    if (level == "country") {
      level = null;
    }

    const options: ApiOptions = {
      params: {
        level,
        group_id,
        region_id,
        league_group_id,
      },
    };

    return this.apiService.get(`leagues/rank/year/${year}/${group}/${type}`, options);
  }

  /**
   * REPORTS
   */
  reportDorDpp({ year, month, quarter, year_half }: { year: number; month?: number; quarter?: number; year_half?: number }) {
    const options: ApiOptions = {
      body: {
        year,
        quarter,
        month,
        year_half,
      },
    };

    return this.apiService.post("report/dor-dpp/leagues/result/type", options);
  }

  reportDorDppFile(id) {
    return this.apiService.get(`report/dor-dpp/leagues/result/type/${id}`);
  }

  regionList(dateTime?) {
    const options: ApiOptions = {
      params: {
        datetime: dateTime,
      },
    };

    return this.apiService.get("region/list", options);
  }

  groupList() {
    return this.apiService.get("league-group/list");
  }
}
