<loading></loading>
<ng-container *ngIf="!isLoading && rank && rankDetails">
  <div class="navigate-info">
    <ng-container *ngIf="owDate">
      {{ owDate.month_text_normal }} | Dane aktualne na: {{ rankDetails.source_data_date | date:'dd-MM-y' }}
    </ng-container>
  </div>

  <div class="content loading-opacity">
    <!-- TOP -->
    <div class="top">
      <!-- LEFT -->
      <div class="left">

        <!-- DESCRIPTION -->
        <p
          class="description"
          [innerText]="rankDetails.description"
          owEllipsis
        ></p>
      </div>

      <!-- RIGHT -->
      <div class="right">
        <!-- FORM -->
        <div class="default-field">
          <input
            type="text"
            id="search"
            placeholder="Szukaj..."
            #inputSearch
            (keyup)="$event"
          />
          <label for="search">Szukaj...</label>
        </div>
      </div>
    </div>

    <!-- FRAME -->
    <div class="frame">
      <div class="box">
        <!-- FILTERS -->
        <div class="filters">
          <ng-container *ngFor="let level of levels">
            <div
              class="ow-radio-container"
              *ngIf="level.show"
              (click)="changeLevel(level)"
            >
              <label class="choose-wrapper">
                <span class="checkbox-wrapper">
                  <input
                    type="radio"
                    name="filter"
                    [checked]="level == activeLevel"
                  >
                  <span class="checkmark"></span>
                </span>
                <span class="text-wrapper">{{ level.label }}</span>
              </label>
            </div>
          </ng-container>
        </div>

        <!-- RANK BUTTONS -->
        <rank-buttons
          [perfectScrollbarRef]="perfectScrollbar"
          [schema]="schema"
          [inputValue]="inputSearch.value"
        ></rank-buttons>
      </div>

      <!-- FIRST THREE POSITIONS -->
      <table class="first-three-places">
        <tbody>
        <ng-container
          *ngFor="let item of rankDetails.items.slice(0,3) | filterRankDetails:schema.currentButtonMyWorkers.filterKey:schema.currentButtonMyWorkers.id:schema.currentButtonMyWorkers.isActive | filterObject:inputSearch.value; let i = index">
          <ng-container [ngTemplateOutlet]="templateTr"
                        [ngTemplateOutletContext]="{item: item, i: i, inputSearch: inputSearch}"></ng-container>
        </ng-container>
        </tbody>
      </table>

      <!-- OTHER POSITIONS -->
      <ow-perfect-scrollbar #perfectScrollbar scrollGradient>
        <table class="custom">
          <tbody>
          <ng-container
            *ngFor="let item of rankDetails.items.slice(3, rankDetails.items.length) | filterRankDetails:schema.currentButtonMyWorkers.filterKey:schema.currentButtonMyWorkers.id:schema.currentButtonMyWorkers.isActive | filterObject:inputSearch.value; let i = index">
            <ng-container [ngTemplateOutlet]="templateTr"
                          [ngTemplateOutletContext]="{item: item, i: i + 3, inputSearch: inputSearch}"></ng-container>
          </ng-container>
          </tbody>
        </table>
      </ow-perfect-scrollbar>
    </div>
  </div>
</ng-container>

<!-- TEMPLATE -->
<ng-template #templateTr let-item="item" let-i="i" let-inputSearch="inputSearch">
  <tr
    [class.my-position]="(i == schema.currentMyPositionId) && !(inputSearch && inputSearch.value) && playerService.isActiveMe"
    [attr.data-id]="i">
    <!-- POSITION -->
    <td class="td-position">
      {{ item.pos }}
    </td>

    <!-- NAME -->
    <td class="td-name">
      {{ item.player }}
    </td>

    <!-- VALUE PERCENT -->
    <td class="td-value">
      <img
        *ngIf="rankDetails.is_quality && item.min_valid && item.prizes && item.prizes.length > 0"
        [src]="'q2.png' | asset:'ui'"
      />
      {{ item.value }}
      <ng-container *ngIf="rankDetails.suffix !== null">
        {{ rankDetails.suffix }}
      </ng-container>
    </td>
    <!-- MIN VALID -->
    <td class="td-min-valid">
      <span
        placement="left"
        [ngbTooltip]="item.mins.tooltip_text"
        [disableTooltip]="!rankDetails.is_tooltip_text"
        tooltipClass="pre-line"
        container="body"
      >
          {{ item.min_valid ? 'Warunki spełnione' : 'Nieklasyfikowany' }}
      </span>
    </td>
    <td class="td-prize-{{i + 1}}" *ngFor="let el of availablePrizesList; let i = index">
      <ng-container *ngIf="item.prizes[i] as currency">
        <m-ui-currency
          *ngIf="!item.prizes[i].disabled"
          [item]="currency"
          [lack]="false"
          [stockView]="STOCK_VIEW.G"
        ></m-ui-currency>
      </ng-container>
    </td>
    <!-- PRIZE 1 -->
<!--    <td class="td-prize-1">-->
<!--      <ng-container *ngIf="item.prizes[0] as currency">-->
<!--        <m-ui-currency-->
<!--          *ngIf="!item.prizes[0].disabled"-->
<!--          [item]="currency"-->
<!--          [lack]="false"-->
<!--          [stockView]="STOCK_VIEW.G"-->
<!--        ></m-ui-currency>-->
<!--      </ng-container>-->
<!--    </td>-->
<!--    &lt;!&ndash; PRIZE 2 &ndash;&gt;-->
<!--    <td class="td-prize-2">-->
<!--      <ng-container *ngIf="item.prizes[1] as currency">-->
<!--        <m-ui-currency-->
<!--          *ngIf="!item.prizes[1].disabled"-->
<!--          [item]="currency"-->
<!--          [lack]="false"-->
<!--          [stockView]="STOCK_VIEW.G"-->
<!--        ></m-ui-currency>-->
<!--      </ng-container>-->
<!--    </td>-->
<!--    &lt;!&ndash; PRIZE 3 &ndash;&gt;-->
<!--    <td class="td-prize-3">-->
<!--      <ng-container *ngIf="item.prizes[2] as currency">-->
<!--        <m-ui-currency-->
<!--          *ngIf="!item.prizes[2].disabled"-->
<!--          [item]="currency"-->
<!--          [lack]="false"-->
<!--          [stockView]="STOCK_VIEW.G"-->
<!--        ></m-ui-currency>-->
<!--      </ng-container>-->
<!--    </td>-->
<!--    &lt;!&ndash; PRIZE 3 &ndash;&gt;-->
<!--    <td class="td-prize-4">-->
<!--      <ng-container *ngIf="item.prizes[3] as currency">-->
<!--        <m-ui-currency-->
<!--          *ngIf="!item.prizes[3].disabled"-->
<!--          [item]="currency"-->
<!--          [lack]="false"-->
<!--          [stockView]="STOCK_VIEW.G"-->
<!--        ></m-ui-currency>-->
<!--      </ng-container>-->
<!--    </td>-->
  </tr>
</ng-template>
