import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../../../core/providers/api.service";
import { Observable } from "rxjs";
import { GetEventBusinessResponseInterface } from "../interfaces/response/get-event-business-response.interface";
import { PlayerService } from "../../../../../../player/providers/player.service";

@Injectable({ providedIn: "root" })
export class ApiEventBusinessService {
  constructor(
    private apiService: ApiService,
    private playerService: PlayerService
  ) {}

  getEventBusiness(player_id?: number): Observable<GetEventBusinessResponseInterface[]> {
    player_id = player_id || this.playerService.getActivePlayerId();
    return this.apiService.request("GET", `player/${player_id}/business-events`);
  }

  getManagerEventBusiness(branch_id: number): Observable<GetEventBusinessResponseInterface[]> {
    return this.apiService.request("GET", `branch/${branch_id}/manager/business-events`);
  }

  getEventBusinessRanks(eventId: number) {
    return this.apiService.request("GET", `business-events/${eventId}/ranks`);
  }

  getEventBusinessRankDetails(eventId: number, rankId: number) {
    return this.apiService.request("GET", `business-events/${eventId}/ranks/${rankId}`);
  }
}
