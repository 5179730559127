export const TILE_MENU_OFFSET_BY_ICON = {
  // quarry: {
  //   x: -250,
  //   y: -50,
  // },
};

export const TILE_TOOLTIP_OFFSET_BY_ICON = {
  // poachers_1: {
  //   x: 30,
  //   y: 500,
  // },
};
