import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../../core/providers/api.service";
import {
  BusinessCalendarDetailsResponse,
  BusinessCalendarItem,
  BusinessCalendarResponse,
} from "../../interfaces/custom/business-calendar.interface";
import { Observable } from "rxjs";
import { PlayerService } from "../../../../../player/providers/player.service";
import { ApiOptions } from "../../../../../../core/interfaces/api-options";

@Injectable({
  providedIn: "root",
})
export class ApiBusinessCalendarService {
  constructor(
    public apiService: ApiService,
    private playerService: PlayerService
  ) {}

  businessCalendar(year: number, month: number, playerId?: number): Observable<BusinessCalendarResponse> {
    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.get(`player/${playerId}/business/calendar/${year}/${month}`);
  }

  businessCalendarEventsSeen(seenEvents: number[], playerId?: number) {
    const options: ApiOptions = {
      body: seenEvents,
    };

    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.patch(`player/${playerId}/business/calendar/events/mark-as-seen`, options);
  }

  businessCalendarEventsSeenAll(playerId?: number) {
    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.patch(`player/${playerId}/business/calendar/events/mark-as-seen/all`);
  }

  businessCalendarDetails(type: BusinessCalendarItem["type"], id: BusinessCalendarItem["id"]): Observable<BusinessCalendarDetailsResponse> {
    return this.apiService.get(`business/calendar/information/${type}/${id}`);
  }
}
