import { Injectable } from "@angular/core";
import { Howl } from "howler";
import { Observable, of, skip } from "rxjs";
import { switchMap, tap } from "rxjs/operators";

import { ApiOptions } from "../../../core/interfaces/api-options";
import { ApiService } from "../../../core/providers/api.service";
import { AssetsService } from "../../../core/providers/assets.service";
import { ApiMinigameService } from "../api/services/api-minigame.service";
import { Match3Game } from "./game/match3.game";
import { Match3Scene } from "./game/match3.scene";
import { Level, M3Color, M3GameDefinitions, Match3GameConfig } from "./models/match3.model";

const m3Souds = {
  select: "o-select.mp3",
  swap: "o-swap.wav",
  fail: "o-fail.mp3",
  collect: "o-collect.mp3",
  finish: "l-finish.mp3",
  music: "music.mp3",
};

@Injectable()
export class Match3Service {
  game: Match3Game;

  gameDefs: M3GameDefinitions;
  currentLevel = 1;
  currentLevelDefs: Level;
  nextLevelDefs: Level;
  movesAvailable = 0;
  totalPoints = 0;
  gameStarted = false;
  levelChange = false;
  currentBoxPoints = 0;
  currentLevelPoints = 0;
  currentLevelBonus = 0;
  currentMissionProgress = new Map<string, number>();
  isGameOver: boolean;
  currentBonusColor: M3Color;
  nextLevelBonusColor: M3Color;
  currentGame: any;
  soundsEnabled = true;
  prevTotalPoints = 0;
  blockRedrawButton = false;
  showBoxes = false;
  boxAmount = 0;
  boxCollected = false;

  levelFinishAudio: HTMLAudioElement;
  sounds: { [key: string]: Howl } = {};
  finalResult = null;

  constructor(private api: ApiService, private apiMinigameService: ApiMinigameService, private assetsService: AssetsService) {
    // console.log(this.assetsService.getAsset(`minigames/match3/sfx/l-finish.mp3`));
    this.levelFinishAudio = new Audio(this.assetsService.getAsset(`minigames/match-three/sfx/l-finish.mp3`).path);
  }

  createGame(gameConfig: Match3GameConfig) {
    this.initSounds();
    this.setCurrentLevel();
    return (this.game = new Match3Game(gameConfig));
  }

  restartBoard(useMove = false) {
    const scene = this.game.scene.scenes[0] as Match3Scene;
    this.decreaseMovesAmount(true);
    if (this.gameStarted) {
      scene.restartBoard();
    }
  }

  setCurrentGame(gameProgress) {
    this.currentGame = gameProgress?.unfinishedStatus;
    const progress = this.currentGame?.["progress"];

    this.setCurrentLevel(progress);
  }

  setCurrentLevel(progress: { level: number; score1: number } = null) {
    // if (progress) {
    //   this.currentLevel = progress.level;
    //
    //   // emulate continue status
    //   this.levelChange = true;
    // }

    this.currentLevelDefs = this.gameDefs.levels[this.currentLevel - 1];
    this.nextLevelDefs = this.gameDefs.levels[this.currentLevel];
    if (this.currentLevel > 1) {
      this.movesAvailable += this.currentLevelDefs.moves;
    } else {
      this.prevTotalPoints = 0;
      this.movesAvailable = this.currentLevelDefs.moves;
    }

    // this.totalPoints = progress?.score1 ?? this.totalPoints;

    this.boxCollected = false;
    this.currentLevelPoints = 0;
    this.currentLevelBonus = 0;
    this.currentBoxPoints = 0;
    this.currentBonusColor = this.findBonusColor();
    this.nextLevelBonusColor = this.findNextLevelBonusColor();
    this.resetMissionsProgress();
  }

  startGame(game: number): Observable<any> {
    return this.api.post(`minigame/${game}/start/1`).pipe(
      tap(currentGame => {
        this.currentGame = currentGame;
      })
    );
  }

  finishUnfinishedGames(): Observable<any> {
    return this.apiMinigameService.getUnfinished(2).pipe(switchMap(resp => (resp ? this.instantFinishGame(resp) : of(true))));
  }

  instantFinishGame(gameResponse) {
    let progress = {
      score1: gameResponse?.progress?.score1 ? this.calculateTheScore(gameResponse.progress.score1, 0, 0, gameResponse.progress.boxAmount) : 0,
      level: gameResponse?.progress?.level ?? 0,
      boxAmount: gameResponse?.progress?.boxAmount ?? 0,
      forcedFinish: true,
    };
    progress = this.addBuildVersionGame(progress);
    const progressData = this.encodeData(progress, gameResponse.token);
    const options: ApiOptions = {
      body: {
        progress: progressData,
      },
    };
    return this.api.post(`minigame/${gameResponse.mini_game_id}/finish`, options);
  }

  finish(gameId: number, progress: string): Observable<any> {
    const options: ApiOptions = {
      body: {
        progress,
      },
    };

    return this.api.post(`minigame/${gameId}/finish`, options);
  }

  progress(gameId: number, progress: string) {
    const options: ApiOptions = {
      body: {
        progress,
      },
    };
    return this.api.post(`minigame/${gameId}/progress`, options);
  }

  findBonusColor(): M3Color {
    return this.currentLevelDefs.colors.find(color => color.bonus);
  }

  findNextLevelBonusColor(): M3Color {
    // console.log(this.nextLevelDefs);
    return this.nextLevelDefs?.colors.find(color => color.bonus);
  }

  resetMissionsProgress() {
    this.currentMissionProgress.clear();
    this.currentLevelDefs.missions.forEach((mission, i) => {
      this.currentMissionProgress.set(mission.color || "all", 0);
    });
  }

  nextLevel() {
    this.currentLevel++;
    // this.setCurrentLevel();
  }

  getGameDefinitions(gameId: number): Observable<Level[]> {
    this.gameDefs = {
      levels: null
    };

    return this.getLevels(gameId).pipe(tap(gameDefs => {
      // add information about box level
      for (const level of gameDefs) {
        const box = level.colors.find(x => x.color == "99");
        if (box) {
          level["boxLevel"] = true;
          this.showBoxes = true;
        }
      }
      this.gameDefs.levels = gameDefs
    } ));
  }

  resetGame() {
    this.currentLevel = 1;
    this.currentLevelPoints = 0;
    this.movesAvailable = 0;
    this.boxCollected = false;
    this.gameDefs = null;
    this.gameStarted = false;
    this.levelChange = false;
    this.currentLevelDefs = null;
  }

  calculateMovePoints(figureId: number, matchedFiguresAmount: number, boxStreak = false) {
    // TODO - refactor communication between phaser and gui
    let status: "PLAYING" | "FINISHED" | "COMPLETED" = "PLAYING"; // finished(for instance - level), completed - the game end
    if (!this.gameStarted) {
      console.log("game already stopped?");
      return;
    }

    if (boxStreak) {
      if (!this.boxCollected) {
        this.boxAmount++;
        this.boxCollected = true;
      }
      (this.game.scene.scenes[0] as Match3Scene).removeBoxes = true;
      this.currentBoxPoints += matchedFiguresAmount * this.getColorPoints(figureId);
    } else {
      this.currentLevelPoints += matchedFiguresAmount * this.getColorPoints(figureId);
    }

    // TODO - remove color with string; add identifier
    const missionForColorPoints = this.currentMissionProgress.get(figureId.toString());
    const missionForAllColorPoints = this.currentMissionProgress.get("all");
    if (missionForColorPoints || missionForColorPoints === 0) {
      this.currentMissionProgress.set(figureId.toString(), missionForColorPoints + matchedFiguresAmount);
    }
    this.currentMissionProgress.set("all", Math.max(0, missionForAllColorPoints + matchedFiguresAmount));
    const missionsCompleted = this.checkMissionsCompleted();

    // case where you finish the level on the last swap; order sensitive with mission completed
    if (missionsCompleted) {
      this.finishLevel(true);
      status = "FINISHED";
    }

    if (!missionsCompleted && this.movesAvailable <= 0) {
      this.finishLevel(false);
      status = "COMPLETED";
    }

    return status;
  }

  initSounds() {
    Object.keys(m3Souds).forEach(sound => {
      this.sounds[sound] = new Howl({
        src: this.assetsService.getAsset(`minigames/match-three/sfx/${m3Souds[sound]}`).path,
      });
    });
  }

  playSound(sound: string) {
    this.sounds[sound]?.play();
  }

  checkMissionsCompleted() {
    const allMissionsCompleted = this.currentLevelDefs.missions.every(mission => {
      this.currentMissionProgress.set(
        mission.color || "all",
        Math.min(mission.target, this.currentMissionProgress.get(mission.color || "all"))
      );
      return mission.target === this.currentMissionProgress.get(mission.color || "all");
    });
    return allMissionsCompleted;
  }

  finishLevel(completed: boolean) {
    if (!this.gameStarted) {
      console.log("game already stopped?");
      return;
    }
    this.isGameOver = !completed || (completed && this.currentLevel === this.gameDefs.levels.length + 1);
    this.levelChange = true;
    this.gameStarted = false;
    let total = 0;
    let totalWithBoxMultiplier = 0;
    if (completed) {
      this.currentLevelBonus = this.currentLevelDefs.completed_points_bonus;
      if (this.soundsEnabled) {
        this.levelFinishAudio.play();
      }
      if (this.currentLevel === this.gameDefs.levels.length + 1) {
        total = this.calculateTheScore(
          this.totalPoints,
          this.currentLevelBonus,
          this.currentLevelPoints,
          this.currentBoxPoints
        );
        totalWithBoxMultiplier = this.calculateTheScore(
          this.totalPoints,
          this.currentLevelBonus,
          this.currentLevelPoints,
          this.currentBoxPoints,
          this.boxAmount
        );
      } else {
        total = this.calculateTheScore(this.totalPoints, this.currentLevelBonus, this.currentLevelPoints, this.currentBoxPoints);
        totalWithBoxMultiplier = total;
      }
    } else {
      total = this.calculateTheScore(
        this.totalPoints,
        this.currentLevelBonus,
        this.currentLevelPoints,
        this.currentBoxPoints
      );
      totalWithBoxMultiplier = this.calculateTheScore(
        this.totalPoints,
        this.currentLevelBonus,
        this.currentLevelPoints,
        this.currentBoxPoints,
        this.boxAmount
      );
    }
    const progress = { score1: this.isGameOver ? totalWithBoxMultiplier : total, level: this.currentLevel };
    if (this.currentLevel > 1) {
      this.prevTotalPoints = this.totalPoints;
    }
    this.totalPoints = total;
    let progressData = this.addBuildVersionGame(progress);
    progressData = this.encodeData(progressData);

    if (this.isGameOver) {
      this.finish(this.currentGame.mini_game_id, progressData).subscribe(res => {
        this.finalResult = res;
      });
    } else {
      this.progress(this.currentGame.mini_game_id, progressData).subscribe();
    }
    this.game?.destroy(true);
  }

  calculateTheScore(totalPoints: number, currentLevelBonus: number, currentLevelPoints: number, currentBoxPoints: number, boxAmount = 0) {
    // 1 + (boxAmount * 2) / 10 gives 0%, 20%, 40%, 60%
    return Math.floor((totalPoints + currentLevelBonus + currentLevelPoints + currentBoxPoints) * (1 + (boxAmount * 2.5) / 10));
  }

  getColorPoints(color) {
    return this.currentLevelDefs.colors.find(colorDef => colorDef.color == color)?.points;
  }

  decreaseMovesAmount(checkLevelFinish = false) {
    this.movesAvailable--;
    if (this.movesAvailable === 0 && checkLevelFinish) {
      this.finishLevel(false);
    }
  }

  getLevels(gameId: number) {
    return this.api.get(`/minigame/${gameId}/levels`);
  }

  encodeData(data, token?) {
    const base = btoa(JSON.stringify(data));
    return encodeURIComponent(`${token || this.currentGame.token}${base}`);
  }

  addBuildVersionGame(progress) {
    return {
      ...progress,
      buildVersionGame: document["GAME_VERSION_TIMESTAMP"],
    };
  }

  // gameOver() {
  //   let progress = {
  //     score1: this.totalPoints
  //   };
  //   progress = this.addBuildVersionGame(progress);
  //   const progressData = this.encodeData(progress);
  //
  //   this.gameStarted = false;
  //   this.finish(this.currentGame.mini_game_id, progressData).subscribe();
  // }

  resetDataForNewGame() {
    this.totalPoints = 0;
    this.currentLevelPoints = 0;
    this.movesAvailable = 0;
    this.prevTotalPoints = 0;
    this.currentLevelDefs = null;
  }

  mute() {
    this.soundsEnabled = !this.soundsEnabled;
    const scene = this.game.scene.scenes[0] as Match3Scene;
    scene.toggleSounds(this.soundsEnabled);
  }
}
