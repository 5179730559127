<div class="ow-dialog">
  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="close()"
  ></m-ui-close-button>

  <!-- TITLE -->
  <p class="title" *ngIf="data?.name">
    {{ data.name }}
  </p>

  <p
    class="description"
    *ngIf="data.description"
    [innerHTML]="data.description | safe:'html'"
  ></p>
  <div *ngIf="data?.product_prizes || data?.currency_prizes" class="prizes">
    <m-ui-currency  *ngFor="let currency of data?.currency_prizes" [stockView]="STOCK_VIEW.J" [item]="{currency_id: currency.id, amount: currency.amount}" [lack]="false"></m-ui-currency>
    <m-ui-product *ngFor="let prize of data?.product_prizes" [stockView]="STOCK_VIEW.J" [item]="{product_id: prize.id, amount: prize.amount}" [lack]="false"></m-ui-product>
  </div>
  <div *ngIf="data?.file?.link" class="file-description"><span>Zapoznaj się z zasadami eventu <a target="_blank" [href]="data?.file?.link">TUTAJ</a></span></div>

</div>
