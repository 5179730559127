import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../../../core/providers/api.service";
import { Observable } from "rxjs";
import { GetEventShopsResponse } from "../interfaces/response/get-event-shops.interface";
import { GetEventShopsProductsResponse } from "../interfaces/response/get-event-shops-products.interface";
import { ShoppingRequest } from "../../../interfaces/custom/event-shop-requests.interface";
import { GetEventShopsRanksResponse } from "../interfaces/response/get-event-shops-ranks.interface";
import { GetEventShopsRankDetailsResponse } from "../interfaces/response/get-event-shops-rank-details.interface";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { GetEventBusinessResponseInterface } from "../../../../hud/api/custom/interfaces/response/get-event-business-response.interface";

@Injectable({
  providedIn: "root",
})
export class ApiEventShopService {
  constructor(
    protected apiService: ApiService,
    private playerService: PlayerService
  ) {}

  getEventShops(player_id?: number): Observable<GetEventShopsResponse> {
    player_id = player_id || this.playerService.getActivePlayerId();
    return this.apiService.get(`player/${player_id}/event-shops`);
  }

  getManagerEventShops(branch_id: number): Observable<GetEventShopsResponse[]> {
    return this.apiService.request("GET", `branch/${branch_id}/manager/event-shops`);
  }

  getEventShopsProducts({ event_shop_id }: { event_shop_id: number }): Observable<GetEventShopsProductsResponse> {
    return this.apiService.get(`event-shops/${event_shop_id}/products`);
  }

  buyProduct(shopping: ShoppingRequest) {
    return this.apiService.post(`event-shops/${shopping.shopID}/products/${shopping.productID}`, {
      body: {
        amount: shopping.amount,
        phone: shopping.phoneNumber,
      },
    });
  }

  eventShopsRanks({ event_shop_id }: { event_shop_id: number }): Observable<GetEventShopsRanksResponse> {
    return this.apiService.get(`event-shops/${event_shop_id}/ranks`);
  }

  eventShopsRankDetails({
    event_shop_id,
    rank_id,
  }: {
    event_shop_id: number;
    rank_id: number;
  }): Observable<GetEventShopsRankDetailsResponse> {
    return this.apiService.get(`event-shops/${event_shop_id}/ranks/${rank_id}`);
  }
}
