import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RanksComponent } from "../../../basic/custom/dialogs/ranks/ranks.component";
import { SharedUiMobileModule } from "../../../../shared-ui/mobile/shared-ui-mobile.module";
import { SharedModule } from "../../../../../../shared/shared.module";
import { RankBusinessBaseComponent } from "../../../basic/custom/dialogs/rank-details/rank-business-details/rank-business-base/rank-business-base.component";
import { RankBusinessDetailsComponent } from "../../../basic/custom/dialogs/rank-details/rank-business-details/rank-business-details.component";
import { FilterRankDetailsPipe } from "../../../basic/custom/pipes/filter-rank-details.pipe";
import { FilterObjectPipe } from "../../../basic/custom/pipes/filter-object.pipe";
import { RankLeagueMainComponent } from "../../../basic/custom/dialogs/rank-details/rank-league-details/rank-league-main/rank-league-main.component";
import { RankLeagueTypeComponent } from "../../../basic/custom/dialogs/rank-details/rank-league-details/rank-league-type/rank-league-type.component";
import { RankLeagueDetailsComponent } from "../../../basic/custom/dialogs/rank-details/rank-league-details/rank-league-details.component";
import { RankLeagueResultsComponent } from "../../../basic/custom/dialogs/rank-details/rank-league-details/rank-league-results/rank-league-results.component";
import { ShowMyPositionRankDirective } from "../../../directives/custom/show-my-position-rank.directive";
import { RankingDetailsGame } from "../../../basic/custom/dialogs/rank-details/ranking-game-details/ranking-details-game.component";
import { RankingGameTableComponent } from "../../../basic/custom/dialogs/rank-details/ranking-game-details/ranking-game-table/ranking-game-table.component";
import { RankButtonsComponent } from "../../../basic/custom/dialogs/rank-details/rank-buttons/rank-buttons.component";
import { RankBusinessBaseCcComponent } from "../../../basic/custom/dialogs/rank-details/rank-business-details/rank-business-base-cc/rank-business-base-cc.component";
import { FormsModule } from "@angular/forms";
import { OwPerfectScrollbarModule } from "@oskarwegner/ow-perfect-scrollbar";

@NgModule({
  declarations: [
    RanksComponent,
    RankBusinessDetailsComponent,
    RankBusinessBaseComponent,
    FilterRankDetailsPipe,
    FilterObjectPipe,
    RankLeagueDetailsComponent,
    RankLeagueMainComponent,
    RankLeagueResultsComponent,
    RankLeagueTypeComponent,
    ShowMyPositionRankDirective,
    RankingDetailsGame,
    RankingGameTableComponent,
    RankButtonsComponent,
    RankBusinessBaseCcComponent,
  ],
  // entryComponents: [
  //   RanksComponent,
  //   RankBusinessDetailsComponent,
  //   RankBusinessBaseComponent,
  //   RankLeagueDetailsComponent,
  //   RankLeagueMainComponent,
  //   RankLeagueResultsComponent,
  //   RankLeagueTypeComponent,
  //   RankingDetailsGame,
  // ],
  exports: [RankingDetailsGame, ShowMyPositionRankDirective, RankingGameTableComponent, FilterObjectPipe],
  imports: [CommonModule, SharedUiMobileModule, SharedModule, FormsModule, OwPerfectScrollbarModule],
})
export class SanRanksModule {}
