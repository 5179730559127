<div class="container">
  <ng-container *ngIf="!isLoading && microleagues.length">
    <div class="title">{{ isClosedActive ? "Zakończone " : "Aktywne " }} mikroligi</div>
    <div class="tables">
      <div class="table-header">
        <table class="custom" [class.league-closed]="isClosedActive">
          <thead>
          <th class="date">Czas trwania</th>
          <th class="name">Mikroliga</th>
          <th class="value1">
            {{ isClosedActive ? "Zajęte " : "Obecne " }} miejsce w rankingu
          </th>
          <th class="prize">
            {{ isClosedActive ? "Twoja nagroda" : "Maksymalna nagroda za 1 miejsce" }}
          </th>
          <th class="info"></th>
          </thead>
        </table>
      </div>
      <div class="table-body">
        <ow-perfect-scrollbar #perfectScrollbar scrollGradient>
          <table class="custom" [class.league-closed]="isClosedActive">
            <tbody>
            <tr *ngFor="let league of microleagues">
              <td class="date">
                <span (click)="openMicroLeagueDetails(league.microleague_id)">{{ league.date_from | date: 'dd.MM.yyyy' }}-{{ league.date_to | date: 'dd.MM.yyyy' }}</span>
              </td>
              <div
                class="accept-rules"
                [class]="{'box-active': league.is_confirmed, 'cursor-default': checkDateIsBeforeNow(league.rules_confirmation_deadline) || league.is_confirmed}"
                (click)="confirmRules(league.microleague_id)"
              >
                <div *ngIf="checkDateIsAfterNow(league.rules_confirmation_deadline) || league.is_confirmed"
                  class="box"
                ></div>
                {{checkDateIsAfterNow(league.rules_confirmation_deadline) || league.is_confirmed ? 'Wiem na czym polega ta mikroliga i co mogę zdobyć.' : league.is_confirmed === false ? 'Brak Twojego potwierdzenia w wyznaczonym terminie, teraz walczysz tylko o część nagrody.' : '' }}
              </div>
              <td class="name"><span (click)="openMicroLeagueDetails(league.microleague_id)">{{ league.name }}</span></td>
              <td class="value1" (click)="openMicroLeagueDetails(league.microleague_id)">{{ league.my_position === 0 ? '-' : league.my_position}}</td>
              <td class="prize" (click)="openMicroLeagueDetails(league.microleague_id)">
                <div class="my-prizes">

                  <ng-container *ngIf="league.is_closed && (league.final_prizes?.currency || league.final_prizes?.product)">

                    <!-- CURRENCY -->
                    <ng-container *ngIf="league.final_prizes?.currency?.length">
                      <m-ui-currency
                        *ngFor="let currency of league.final_prizes.currency"
                        [item]="currency"
                        [lack]="false"
                        [stockView]="STOCK_VIEW.G"
                      ></m-ui-currency>
                    </ng-container>

                    <!-- PRODUCT -->
                    <ng-container *ngIf="league.final_prizes?.product?.length">
                      <m-ui-product
                        *ngFor="let product of league.final_prizes.product"
                        [item]="product"
                        [stockView]="STOCK_VIEW.G"
                        [lack]="false"
                      ></m-ui-product>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="!league.is_closed && (league.prizes?.currency || league.prizes?.product)">

                    <!-- CURRENCY -->
                    <ng-container *ngIf="league.prizes?.currency?.length">
                      <m-ui-currency
                        *ngFor="let currency of league.prizes.currency"
                        [item]="currency"
                        [lack]="false"
                        [stockView]="STOCK_VIEW.G"
                      ></m-ui-currency>
                    </ng-container>

                    <!-- PRODUCT -->
                    <ng-container *ngIf="league.prizes?.product?.length">
                      <m-ui-product
                        *ngFor="let product of league.prizes.product"
                        [item]="product"
                        [stockView]="STOCK_VIEW.G"
                        [lack]="false"
                      ></m-ui-product>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="(!league.is_closed && !league.prizes?.currency && !league.prizes?.product) || (league.is_closed && !league.final_prizes?.currency && !league.final_prizes?.product)">Brak</ng-container>
                </div>
              </td>
              <td class="chest" [class.chest-background]="league.is_chest_received === false && checkDateIsAfterNow(league?.chest_confirmation_deadline)" [class.cursor-default]="!(league.is_chest_received === false && checkDateIsAfterNow(league?.chest_confirmation_deadline))" *ngIf="!isClosedActive"><div *ngIf="league.is_chest_received === false && checkDateIsAfterNow(league?.chest_confirmation_deadline)"
                                                                                                                                                                                  (click)="markChestOpened(league.microleague_id)"
                                                                                                                                                                                  class="chest-button"></div>
              </td>
              <td class="info" *ngIf="isClosedActive">
                <i class="fas fa-info-square" (click)="openInfo(league.description, $event)"></i>
              </td>
            </tr>
            </tbody>
          </table>
        </ow-perfect-scrollbar>
      </div>
    </div>
  </ng-container>

  <div
    [matTooltip]="'Brak zakończonych mikrolig'"
    [matTooltipPosition]="'above'"
    [matTooltipDisabled]="!closedBtn"
    class="btn-tooltip"
  >
    <div
      class="button-league"
      (click)="showMicroLeaguesClosed()"
      *ngIf="!isLoading && !isClosedActive"
      [class.disabled]="closedBtn"
    >
      Zakończone
    </div>
  </div>

  <ng-container *ngIf="!isLoading && microleagues.length === 0">
    <div class="no-item">Brak {{ isClosedActive ? 'zakończonych' : 'aktywnych' }} mikrolig</div>
  </ng-container>
</div>
