import { Component, OnInit } from "@angular/core";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { ParametersService } from "../../../../../../../../core/providers/parameters.service";
import { BuildingProductionData } from "../../../../../../interfaces/production.interfaces";
import { ProductionService } from "../../../../../../services/production.service";
import { AbstractBuildingBuildComponent } from "../../../../abstract/core/abstract-building-build.component";
import { getAutomaticProductHelper } from "../../../../helpers/custom/get-automatic-product.helper";
import { getStorageValueHelper } from "../../../../helpers/custom/get-storage-value.helper";
import { getNotOutcomeParameters, getOutcomeParameters } from "../../../../helpers/custom/outcome-parameters.helper";
import { removeDuplicateMax } from "../../../../helpers/custom/remove-duplicate-max.helper";

@Component({
  selector: "app-building-build",
  templateUrl: "./building-build.component.html",
})
export class BuildingBuildComponent extends AbstractBuildingBuildComponent implements OnInit {
  @OwInject(ProductionService) productionService: ProductionService;
  @OwInject(ParametersService) parametersService: ParametersService;

  production: BuildingProductionData[];
  notOutcomeParameters: any[];
  outcomeParameters: any[];
  storageValue;
  automaticCurrency;
  automaticProduct;
  hideSubTitle: boolean;

  ngOnInit() {
    this.getData();
  }

  getData() {
    super.getData();
    this.getBuildingProduction();
  }

  afterGetDataRequests() {
    this.hideSubTitle = this.buildingDetails.group_type !== "production" && this.buildingDetails.group_type !== "auto-production";
    this.setParameters();
    this.setStorageSize();
    this.setAutoProduction();
  }

  setParameters() {
    this.notOutcomeParameters = getNotOutcomeParameters(this.buildingDetails.parameters);
    this.outcomeParameters = getOutcomeParameters(this.buildingDetails.parameters);
  }

  setStorageSize() {
    this.storageValue = getStorageValueHelper(this.buildingDetails);
  }

  setAutoProduction() {
    const automaticProduction = getAutomaticProductHelper(this.buildingDetails);

    this.automaticCurrency = automaticProduction.automaticCurrency;
    this.automaticProduct = automaticProduction.automaticProduct;
  }

  getBuildingProduction() {
    this.productionService.getBuildingProduction(this.data.buildingId).subscribe(
      (production: any[]) => {
        this.production = removeDuplicateMax(production);
      },
      error => {
        if (error.status === 404) {
          error.defaultHandler.unsubscribe();
        }
      }
    );
  }
}
