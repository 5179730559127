import { Component, Input } from "@angular/core";

import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";

@Component({
  selector: "app-building-return",
  templateUrl: "./building-return.component.html",
})
export class BuildingReturnComponent {
  @Input() returnCurrencies: any[];
  @Input() returnProducts: any[];
  @Input() title: string;
  @Input() hideSubTitle: boolean;
  STOCK_VIEW = STOCK_VIEW;
}
