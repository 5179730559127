import { Scene } from "phaser";

export class TreeSprite extends Phaser.GameObjects.Sprite {
  id: number;
  isOccupied: boolean;
  icon: string;

  constructor(scene: Scene, x: number, y: number, texture: string, frame: string, init?: Partial<TreeSprite>) {
    super(scene, x, y, texture, frame);
    Object.assign(this, init);
    scene.add.existing(this);
    this.setDepth(2).setOrigin(0.5, 1);
  }
}
