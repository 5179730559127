<div
  class="my-position-link"
  showMyPositionRank
  [perfectScrollbarRef]="perfectScrollbarRef"
  [myPosition]="schema.currentMyPositionId"
  *ngIf="schema.currentMyPositionId != null && schema.currentMyPositionId > -1 && !inputValue && playerService.isActiveMe"
>
  <a>Moja pozycja</a>
</div>

<div
  class="my-position-link"
  *ngIf="schema.currentButtonMyWorkers && schema.currentButtonMyWorkers.filterKey && playerService.isActiveMe"
  (click)="schema.currentButtonMyWorkers.isActive = !schema.currentButtonMyWorkers.isActive"
>
  <a>
    {{ schema.currentButtonMyWorkers.filterKey == 'branch_id' ? (schema.currentButtonMyWorkers.isActive ? 'Wróć' : (isCC ? 'Mój team' : 'Mój oddział')) : '' }}
    {{ schema.currentButtonMyWorkers.filterKey == 'partner_id' ? (schema.currentButtonMyWorkers.isActive ? 'Wróć' : 'Moi pracownicy') : '' }}
  </a>
</div>
