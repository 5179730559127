import { RANK_GROUP } from '../../../../../../san-ranks/const/custom/rank-group.const';
import { RankFilter } from '../../../../../../san-ranks/basic/custom/dialogs/ranks/interfaces/rank-filter.interface';

export const FILTERS: RankFilter[] = [
  {
    valueApi: RANK_GROUP.EMPLOYEE,
    label: 'Indywidualny (DORADCY I KASJERZY)',
    show: true,
  },
  {
    valueApi: RANK_GROUP.MANAGERS,
    label: 'Indywidualny (KIEROWNICY)',
    show: true,
  },
  {
    valueApi: RANK_GROUP.BRANCH,
    label: 'Oddziałowy (KIEROWNICY)',
    show: true,
  },
];
