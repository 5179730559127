<div class="ow-dialog global-dialog c-window-short">

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button *ngIf="data.branch"></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>{{ data.branch ? data.branch.name : (isCC ? 'Podgląd graczy' : 'Podgląd oddziału') }}</span>
      </div>
    </div>
  </div>

  <loading></loading>

  <div class="frame loading-opacity">
    <ow-perfect-scrollbar
      *ngIf="branchesPlayers"
      [scrollGradient]="branchesPlayers"
    >
      <div class="table-wrapper">
        <table>
          <tbody>
          <tr
            *ngFor="let player of branchesPlayers"
          >
            <!-- AVATAR -->
            <td class="avatar">
              <div class="icon">
                <i class="fa fa-user-circle default-avatar"></i>
                <div
                  class="custom-avatar"
                  *ngIf="player.profile_image_url"
                  [style.background-image]="'url(' + player.profile_image_url + ')'"
                ></div>
              </div>
            </td>

            <!-- INFO -->
            <td
              class="info"
              [class.allow-change-player]="this.ALLOW_CHANGE_PLAYER"
              (click)="changeSceneService.changePlayer(player)"
              ngbTooltip="Przejdź na gracza"
              [disableTooltip]="!this.ALLOW_CHANGE_PLAYER"
              placement="left"
              tooltipClass="delayed"
              container="body"
            >
              <div class="name"><b>{{ player.full_name }}</b></div>
              <div class="position">{{ player.title_id.name }}</div>
            </td>

            <!-- PLAYER LEVEL -->
            <td
              class="level"
              ngbTooltip="Level gracza"
              placement="left"
              tooltipClass="delayed"
              container="body"
            >
              <div class="level-circle">
                {{ player.level }}
              </div>
            </td>

            <!-- POINTS BALANCE -->
            <td
              class="icon-td points"
              ngbTooltip="Punkty doświadczenia gracza"
              placement="left"
              tooltipClass="delayed"
              container="body"
            >
              <div class="inner">
                <img [src]="'exp.png' | asset: 'ui'">
                {{ player.points_balance }}
              </div>
            </td>

            <!-- MAP AFRICA -->
            <td
              class="icon-td"
              ngbTooltip="Ilość opracowanych technologii"
              placement="left"
              tooltipClass="delayed"
              container="body"
            >
              <div class="inner">
                <img [src]="'icon_technology.png' | asset: 'ui'">
                {{ (player?.technologiesDiscovered || 0) | NumberWithSpaces }}
              </div>
            </td>

            <td
              class="icon-td"
              ngbTooltip="Ilość rozwiązanych problemów na mapie Azji"
              placement="left"
              tooltipClass="delayed"
              container="body"
            >
              <div class="inner">
                <img [src]="'icon_problem_solved.png' | asset: 'ui'">
                {{ (player?.solvedAsiaProblems || 0) | NumberWithSpaces }}
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ow-perfect-scrollbar>
  </div>
</div>
