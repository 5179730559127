import { Component, OnInit, ViewChild } from "@angular/core";
import { forkJoin } from "rxjs";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { AbstractMessagesComponent } from "../../../../abstract/core/abstract-messages.component";
import { MessageFilter } from "../../../../interfaces/core/message-filter.interface";
import { MessageFilterValue } from "../../../../interfaces/core/message-filter-value.interface";
import { MessageService } from "../../../../services/core/message.service";

@Component({
  selector: "app-custom-messages",
  templateUrl: "./messages.component.html",
})
export class MessagesComponent extends AbstractMessagesComponent implements OnInit {
  @OwInject(MessageService) messagesService: MessageService;
  // @ViewChild("slider", { static: true }) slider: SwiperComponent;

  displayKeyCategory = "category_name";
  displayKeySender = "sender_name";
  activeCategory = null;
  activeSenders = null;
  config = {
    slidesPerView: "auto",
    centeredSlides: true,
    freeMode: true,
  };
  limit = 11;

  ngOnInit() {
    this.dispatchClearNewMessagesToRead();
    this.getMessages();

    forkJoin([
      this.apiMessageService.getMessagesFilters({ filter: "categories" }),
      this.apiMessageService.getMessagesFilters({ filter: "sender-groups" }),
    ]).subscribe(([categoryFilterValues, senderFilterValues]) => {
      this.filterCategory.values = [{ id: 0, category_name: "Wszystkie Kategorie" }, ...categoryFilterValues];
      this.filterSender.values = [{ id: 0, sender_name: "Wszyscy Nadawcy" }, ...senderFilterValues];
      this.setDefaultFilters();
    });
  }

  changeCategory(event) {
    this.filterCategory.selectedId = event.id;
    this.getMessages(1);
  }

  changeSenders(event) {
    this.filterSender.selectedId = event.sender_group_id;
    this.getMessages(1);
  }

  slideTo(slide) {
    // this.slider.directiveRef.setIndex(slide);
  }

  setFilterValue(filter: MessageFilter, value: MessageFilterValue) {
    this.setFilterValueCoreLogic(filter, value);
    // if (this.slider && this.filterCategory.values.length) {
      // this.slideTo(this.filterCategory.values.indexOf(value));
    // }
  }

  pageChange(page: number) {
    this.getMessages(page);
  }

  toggleTrash() {
    this.setDefaultFilters();
    this.toggleTrashCoreLogic();
    // if (this.slider && this.filterCategory.values.length) {
    //   this.slideTo(this.filterCategory.values.length);
    // }
  }

  setDefaultCategory() {
    this.activeCategory = this.filterCategory.values[0];
  }

  setDefaultSenders() {
    this.activeSenders = this.filterSender.values[0];
  }

  setDefaultFilters() {
    this.setDefaultCategory();
    this.setDefaultSenders();
  }

  moveToTrash(messageId, event?) {
    if (event) {
      event.stopPropagation();
    }
    this.apiMessageService.deleteMessage({ messageId }).subscribe(
      () => {
        this.checkPageAfterMoveToTrash();
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }
}
