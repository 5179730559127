import { Component, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import * as moment from "moment";
import * as R from "ramda";
import { Subscription } from "rxjs";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { PLAYER_TITLES } from "../../../../../../../core/consts/core/player-titles";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { SynchronizeTimeService } from "../../../../../../../core/providers/synchronize-time.service";
import { EventEmitterDialogsService } from "../../../../../../../core/services/core/event-emitter-dialogs.service";
import { OwDate } from "../../../../../../../core/utility/ow-date.class";
import { OwPaginationDate } from "../../../../../../../core/utility/ow-pagination.class";
import { unsubscribeObject } from "../../../../../../../core/utility/unsubscribe-array";
import { PlayerSelectors } from "../../../../../../../store/player";
import { AppState } from "../../../../../../../store/state";
import { Player } from "../../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { RANKS_NAME } from "../../../../../constants/custom/rank.const";
import { Branch } from "../../../../../interfaces/branch.interface";
import { EventBusinessListComponent } from "../../../../hud/custom/dialogs/event-business-list/event-business-list.component";
import { ApiBusinessLeagueRankService } from "../../../../san-ranks/api/service/api-business-league-rank.service";
import { RanksComponent } from "../../../../san-ranks/basic/custom/dialogs/ranks/ranks.component";
import { STOCK_VIEW } from "../../../../shared-ui/mobile/consts/stock-view.const";
import { BusinessDateService } from "../../../../shared-ui/services/custom/business-date.service";
import { GetBusinessTaskRequest } from "../../../api/interfaces/request/get-business-task-request.interface";
import { GetBusinessLeagueCardResponse } from "../../../api/interfaces/response/get-business-league-card-response.interface";
import { ApiBusinessService } from "../../../api/services/api-business.service";
import { ApiBusinessLeagueService } from "../../../api/services/api-business-league.service";
import { ApiDashboardService } from "../../../api/services/api-dashboard.service";
import { CARD_TEMPLATE } from "../../../consts/custom/card-template.const";
import { Dashboard, Structure } from "../../../interfaces/custom/dashboard.interface";
import { QualityTasks } from "../../../interfaces/custom/quality-tasks.interface";
import { Task } from "../../../interfaces/custom/task.interface";
import { DictionaryService } from "../../../providers/custom/dictionary.service";
import { setOrderWithCurrency, setOrderWithProduct } from "../../helpers/order-prizes.helper";
import { DashboardRoleChangeService } from "../../services/dashboard-role-change.service";
import { BasicActionsComponent } from "../basic-actions/basic-actions.component";
import { BusinessCalendarComponent } from "../business-calendar/business-calendar.component";
import { BusinessChallengesComponent } from "../business-challenges/business-challenges.component";
import { BusinessPreviewBranchesComponent } from "../business-preview-dashboard/business-preview-branches/business-preview-branches.component";
import { CardTasksComponent } from "../card-tasks/card-tasks.component";
import { LeagueComponent } from "../league/league.component";
import { LeagueAchievementsComponent } from "../league-achievements/league-achievements.component";
import { QualityTasksComponent } from "../quality-tasks/quality-tasks.component";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(DictionaryService) dictionaryService: DictionaryService;
  @OwInject(ApiBusinessLeagueService)
  apiBusinessLeagueService: ApiBusinessLeagueService;
  @OwInject(ApiDashboardService) apiDashboardService: ApiDashboardService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(SynchronizeTimeService)
  synchronizeTimeService: SynchronizeTimeService;
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;
  @OwInject(EventEmitterDialogsService)
  eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(ApiBusinessLeagueRankService)
  apiBusinessLeagueRankService: ApiBusinessLeagueRankService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(BusinessDateService) businessDateService: BusinessDateService;
  @OwInject(DashboardRoleChangeService) dashboardRoleChangeService: DashboardRoleChangeService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<DashboardComponent>;
  @OwInject(MAT_DIALOG_DATA) data: { openLeague?: boolean };

  structure: Structure[];
  dashboard: Dashboard[];
  rankPosition: RankPosition;
  STOCK_VIEW = STOCK_VIEW;
  taskSourceDate;
  tasks: Task[];
  qualityTasks: QualityTasks;
  isLoading: boolean;
  leagueGroup;
  leagueCard: GetBusinessLeagueCardResponse;
  player: Player;
  CARD_TEMPLATE = CARD_TEMPLATE;
  WF_CURRENCY = 13;
  businessCalendarAvailable: boolean = false;
  branch?: Branch;
  private subscription: Subscription;

  owPaginationDate: OwPaginationDate;
  _owDate: OwDate;

  isDor: boolean;
  isPlayable: boolean;

  subs = {
    player: null,
  };

  set owDate(owDate: OwDate) {
    this._owDate = owDate;
    this.owPaginationDate = new OwPaginationDate({
      current: this.owDate,
      end: new OwDate(this.businessDateService.currentBusinessDate),
    });
  }

  get owDate(): OwDate {
    return this._owDate;
  }

  ngOnInit() {
    this.playerService.firstTimeMePlayerSnapshot.title_id.id;
    this.businessCalendarAvailable = this.playerService.player.gui_unlocks["business_calendar"];

    const allowedTitleIds = [PLAYER_TITLES.DORADCA.id, PLAYER_TITLES.KASJER.id];

    if (allowedTitleIds.includes(this.playerService.firstTimeMePlayerSnapshot.title_id.id)) {
      this.businessCalendarAvailable = this.businessCalendarAvailable && this.playerService.player.title_id.id < PLAYER_TITLES.KIEROWNIK.id;
    }

    this.subscription = this.dashboardRoleChangeService.branch$.subscribe(branch => {
      this.branch = branch;
    });

    this.clearOwDate();
    this.owDate = new OwDate(this.businessDateService.currentBusinessDate);
    this.subscribePlayer();
    this.getLeagueGroup();
    this.getData();
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(PlayerSelectors.selectPlayer)).subscribe(player => {
      this.player = player;
      this.isDor = this.player.title_id.id >= PLAYER_TITLES.DOR.id;
      this.isPlayable = this.player.title_id.playable;
    });
  }

  getData() {
    this.checkRoleChanges();
    this.setPagination();
    this.clearData();

    this.getRanksPositions();
    this.getDashboard();
    this.getTasks();
    this.getLeague();
    this.checkIsLeagueOpen();
  }

  checkRoleChanges() {
    this.dashboardRoleChangeService.owDate = this.owDate;
    this.dashboardRoleChangeService.checkRoleChanges();
  }

  clearData() {
    this.rankPosition = null;
    this.dashboard = null;
    this.structure = null;
    this.tasks = null;
    this.qualityTasks = null;
    this.taskSourceDate = null;
    this.leagueCard = null;
  }

  setPagination() {
    this.owPaginationDate = new OwPaginationDate({
      current: this.owDate,
      end: new OwDate(this.businessDateService.currentBusinessDate),
    });
  }

  getLeagueGroup() {
    if (this.player) {
      this.leagueGroup = this.player["league_group_id"];
    }
  }

  getLeague() {
    this.apiBusinessLeagueService
      .card(
        {
          year: this.owDate.year,
          month: this.owDate.month,
        },
        undefined,
        this.branch?.branch_id
      )
      .subscribe(
        resp => {
          this.leagueCard = resp;
        },
        errResp => {
          if (errResp.status === 404) {
            errResp.defaultHandler.unsubscribe();
          }
        }
      );
  }

  checkIsLeagueOpen() {
    if (this.data.openLeague) {
      this.openLeague({ dashboardMonth: true, openedFromQuarter: true });

      setTimeout(() => {
        this.matDialogRef.close();
      });

      this.data.openLeague = false;
    }
  }

  getTasks() {
    const { month, year } = this.owDate;
    const requestData: GetBusinessTaskRequest = {
      month,
      year,
    };

    this.apiBusinessService.tasks(requestData, this.branch).subscribe(
      data => {
        this.tasks = data.tasks;
        this.qualityTasks = data.quality_tasks;
        this.taskSourceDate = data.tasks_source_data_date;
        this.isLoading = false;
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  clearOwDate() {
    this.owDate = new OwDate();
  }

  setExpandMenu() {
    this.dashboard.forEach(t => {
      t["isExpanded"] = true;
    });
  }

  next() {
    this.owDate.add({ month: 1 });
    this.getData();
  }

  prev() {
    this.owDate.subtract({ month: 1 });
    this.getData();
  }

  toggleExpand(item) {
    item.isExpanded = !item.isExpanded;
  }

  getRanksPositions() {
    this.apiDashboardService
      .ranksPositions(
        {
          year: this.owDate.year,
          month: this.owDate.month,
        },
        this.branch?.branch_id
      )
      .subscribe(resp => {
        this.rankPosition = resp;
      });
  }

  getDashboard() {
    const actualTime = this.synchronizeTimeService.getActualLocalTime();
    const monthOw = this.owDate.month;
    const monthActual = moment(actualTime).month() + 1;

    let date;

    if (monthActual === monthOw) {
      date = moment(actualTime).format();
    } else {
      date = moment(this.owDate.momentDate).endOf("month").format();
    }

    this.apiDashboardService.dashboard(date, undefined, this.branch?.branch_id).subscribe(resp => {
      this.dashboard = resp.data;
      this.structure = resp.structure;
      this.setExpandMenu();
      this.setAllPrizesOrder();
    });
  }

  openCardTasks(data?: OpenCardTasksData) {
    if (this.player.gui_unlocks["dashboard_card_tasks"]) {
      this.dialogService.open(CardTasksComponent, {
        data: {
          tasks: R.clone(this.tasks),
          owDate: new OwDate(this.owDate),
          taskId: data.taskId,
          taskSourceDate: this.taskSourceDate,
          branch: this.branch,
        },
      });
    }
  }

  openBusinessCalendar() {
    this.dialogService.open(BusinessCalendarComponent, {
      data: {},
      disableClose: true,
    });
  }

  openLeague(data?: OpenLeagueData) {
    if (this.player.gui_unlocks["league"]) {
      this.dialogService.open(LeagueComponent, {
        data: {
          dashboardDate: data?.dashboardMonth ? new OwDate(this.owDate) : null,
          isOpenFromQuarter: data?.openedFromQuarter,
          branch: this?.branch,
        },
      });
    } else {
      this.dialogService.openAlert({
        description: "Liga sprzedaży (dostępne wkrótce)",
      });
    }
  }

  openRanks(values?: GetTopPlaceRank) {
    if (this.player.gui_unlocks["rankings"]) {
      this.dialogService.open(RanksComponent, {
        data: {
          owDate: values.dashboardMonth ? new OwDate(this.owDate) : null,
          tabIndex: RANKS_NAME.BUSINESS,
          rankId: values?.rankId,
          filters: values?.filters,
          branch: this.branch,
        },
      });
    }
  }

  openEvents() {
    if (this.player.gui_unlocks["business_events"]) {
      this.dialogService.open(EventBusinessListComponent, {
        data: {
          isOpenedFromDashboard: true,
          branch: this.branch,
        },
      });
    }
  }

  openBasicActions(taskId?) {
    this.dialogService.open(BasicActionsComponent, {
      data: {
        taskId,
        branch: this.branch,
      },
    });
  }

  openLeagueAchievements() {
    if (this.player.gui_unlocks["league_achievements"]) {
      this.dialogService.open(LeagueAchievementsComponent);
    }
  }

  openQualityTasks(dashboardMonth?: boolean) {
    if (this.player.gui_unlocks["dashboard_quality"]) {
      this.dialogService.open(QualityTasksComponent, {
        data: {
          owDate: dashboardMonth ? new OwDate(this.owDate) : null,
          branch: this.branch,
        },
      });
    }
  }

  openBusinessChallenges() {
    if (this.player.gui_unlocks["business_challenges"]) {
      this.dialogService.open(BusinessChallengesComponent);
    }
  }

  setAllPrizesOrder() {
    this.dashboard.forEach(item => {
      item.task.forEach(task => {
        if (task.prizes?.currency) {
          task.prizes.currency = setOrderWithCurrency(task.prizes.currency);
        }

        if (task.prizes?.product) {
          task.prizes.product = setOrderWithProduct(task.prizes.product);
        }
      });
    });
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
    this.subscription.unsubscribe();
  }

  openBranchesSelector() {
    this.dialogService.open(BusinessPreviewBranchesComponent, { data: { backButton: true, owDate: this.owDate } });
  }
}

interface RankPosition {
  country: {
    filters: {
      group_id: number;
      league_group_id: number;
      region_id: number;
    };
    rank_id: number;
    position: number;
  };
  region: {
    filters: {
      group_id: number;
      league_group_id: number;
      region_id: number;
    };
    rank_id: number;
    position: number;
  };
  league_group: {
    filters: {
      group_id: number;
      league_group_id: number;
      region_id: number;
    };
    rank_id: number;
    position: number;
  };
}

interface OpenLeagueData {
  dashboardMonth?: boolean;
  openedFromQuarter?: boolean;
}

interface GetTopPlaceRank {
  rankId?: number;
  filters?: { group_id: number; league_group_id: number; region_id: number };
  dashboardMonth?: boolean;
}

interface OpenCardTasksData {
  taskId?: number;
  dashboardMonth?: boolean;
}
