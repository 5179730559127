import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractMissionDetailsComponent } from '../../../../abstract/core/abstract-mission-details.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { PlayerService } from '../../../../../../../player/providers/player.service';
import { CenterMap } from '../../../../../../game-engine/interfaces/shared';
import { EVENTS } from '../../../../../../../../core/consts/core/events';
import { filter } from 'rxjs/operators';
import { GAME_EVENTS } from '../../../../../../constants';
import { WORLD_BOOT_SCENE } from '../../../../../../game-engine/scenes-world/world.constants';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';
import { Subscription } from "rxjs";
import { select } from "@ngrx/store";
import { selectPlayer } from "../../../../../../../../store/player/selectors";
import {Subs} from '../../../../../../../../core/interfaces/subs.interface';

@Component({
  selector: 'app-custom-mission-details',
  templateUrl: './mission-details.component.html',
})
export class MissionDetailsComponent extends AbstractMissionDetailsComponent implements OnInit, OnDestroy {
  @OwInject(PlayerService) playerService: PlayerService;
  markImage: string;
  isMissionContract: boolean;
  radioPlayerIslandId: number;
  buttonConfig: {
    tooltip: string;
    disabled: boolean;
  } = {
    tooltip: "",
    disabled: true,
  };

  // @ts-ignore
  subs: Subs = {
    playerRegions: null,
    player: null,
    globalEmitter: null
  };

  ngOnInit() {
    this.getMissionDetails(this.data.mission.player_mission_id);
    this.subscribePlayerRegions();
  }

  afterMissionDetailsRequest() {
    super.afterMissionDetailsRequest();
    this.checkMissionContract();

    this.subs.player = this.store.pipe(select(selectPlayer)).subscribe((playerData) => {
      this.checkIfTheTaskCanBeCompleted(playerData);
    });
  }

  checkIfTheTaskCanBeCompleted(playerData) {
    if (!playerData) {
      return;
    }
    const awardsThatExceedsStorageCapacity = [];
    const products = playerData.product_balances;

    // reset status
    this.buttonConfig.disabled = false;
    this.buttonConfig.tooltip = "";

    for (const missionProductPrize of this.mission.product_prizes) {
      // get the information about current balance(dictionary is not refreshing its data meanwhile running the application)
      const currentProductDetails = products.find(x => x.product_id === missionProductPrize.product_id);

      // check storage_capacity and gray out the button if it will be full after receiving the award
      if (
        missionProductPrize["maximum_amount"] !== null &&
        currentProductDetails["balance"] + missionProductPrize["amount"] > missionProductPrize["maximum_amount"]
      ) {
        awardsThatExceedsStorageCapacity.push(missionProductPrize);
      }
    }

    if (awardsThatExceedsStorageCapacity.length) {
      this.buttonConfig.disabled = true;
      const text = awardsThatExceedsStorageCapacity.reduce((acc, curr) => acc + curr.name + ",", "");
      this.buttonConfig.tooltip = `Nie masz wystarczającej ilości miejsca w magazynie, aby odebrać nagrody.\nZwolnij miejsce i spróbuj ponownie. Produkty, które przekraczają pojemność magazynu:\n${text.slice(0, text.length - 1)}`;
    }
  }

  checkMissionContract() {
    this.isMissionContract = this.mission.slot.type === 2 || this.mission.slot.type === 3;
  }

  showOnMap(centerMap: CenterMap) {
    if (centerMap.player_island_id === this.radioPlayerIslandId) {
      this.globalService.globalEvents.emit({
        name: GAME_EVENTS.START_SCENE,
        value: WORLD_BOOT_SCENE
      });
    } else {
      this.globalService.globalEvents.emit({
        name: EVENTS.GAME.CENTER_TILE,
        value: centerMap,
      });
    }
    this.dialogService.closeAll();
  }

  subscribePlayerRegions() {
    this.subs.playerRegions = this.playerService.playerRegions
      .pipe(
        filter((playerRegions) => playerRegions != null)
      )
      .subscribe((playerRegions) => {
        const islands = playerRegions.regions.reduce((_islands, region) => {
          _islands.push(...region.islands);
          return _islands;
        }, []);

        const radioIsland = islands.find(_island => _island.group_id === 'C25');
        if (radioIsland) {
          this.radioPlayerIslandId = radioIsland.player_island_id;
        }
      });
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
    this.dispatchRemoveMissionToOpen();
    this.clearInterval();
  }
}
