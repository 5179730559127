import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment";

import { AbstractInjectBaseComponent } from "../../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../../../../shared-ui/mobile/consts/stock-view.const";
import { ApiMicroLeagueService } from "../../../../../api/services/api-micro-league.service";
import { PlayerMicroLeague } from "../../../../../interfaces/custom/business-challenges.interface";

@Component({
  selector: "app-micro-league",
  templateUrl: "./micro-league.component.html",
})
export class MicroLeagueComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiMicroLeagueService) apiMicroLeagueService: ApiMicroLeagueService;

  @Input() isClosedActive: boolean;
  @Input() isLoading: boolean;
  @Input() microleagues: PlayerMicroLeague[];
  @Input() closedBtn: boolean;
  @Input() managerPlayerId?: number;

  @Output() closed = new EventEmitter();
  @Output() showLeagueDetails: EventEmitter<number> = new EventEmitter<number>();

  protected readonly STOCK_VIEW = STOCK_VIEW;

  checkDateIsAfterNow(date: string): boolean {
    return moment(date).isSameOrAfter(new Date());
  }

  checkDateIsBeforeNow(date: string): boolean {
    return moment(date).isSameOrBefore(new Date());
  }

  openMicroLeagueDetails(microleagueId) {
    this.showLeagueDetails.emit(microleagueId);
  }

  showMicroLeaguesClosed() {
    this.closed.emit();
  }

  confirmRules(microleagueId) {
    const leagueToUpdate = this.microleagues.find(league => league.microleague_id === microleagueId);

    if (moment(leagueToUpdate.rules_confirmation_deadline).isBefore(moment()) || leagueToUpdate.is_confirmed) {
      return;
    }

    this.apiMicroLeagueService.confirmPlayerMicroLeagueRules(microleagueId, this.managerPlayerId).subscribe(resp => {
      if (leagueToUpdate) {
        leagueToUpdate.is_confirmed = true;
      }
    });
  }

  markChestOpened(microleagueId) {
    const leagueToUpdate = this.microleagues.find(league => league.microleague_id === microleagueId);

    this.apiMicroLeagueService.confirmPlayerMicroLeagueChest(microleagueId, this.managerPlayerId).subscribe(resp => {
      if (leagueToUpdate) {
        leagueToUpdate.is_chest_received = true;
      }
    });
  }

  openInfo(description, event) {
    event.stopPropagation();

    this.dialogService.openAlert({
      description: description,
    });
  }

  protected readonly moment = moment;
}
