<div class="ow-dialog global-dialog">
  <loading></loading>

  <!-- IF MESSAGE EXIST -->
  <ng-container *ngIf="message">

    <!-- DIALOG EXIT -->
    <m-ui-close-button
      *ngIf="message.read || selectedItem >= message.gallery.length-1"
      [preventDefault]="true"
      (clicked)="closeOrCloseAll()"
    ></m-ui-close-button>

    <!-- BACK BAR -->
    <m-ui-back-button
      [preventDefault]="true"
      (clicked)="close()"
      *ngIf="messageOpenDetailsData.fromList"
    ></m-ui-back-button>

    <!-- NAVIGATION CENTER -->
    <m-ui-navigation-center
      [hidePrev]="selectedItem === 0"
      [hideNext]="selectedItem >= message.gallery.length-1"
      (prevClicked)="loadItem(selectedItem-1)"
      (nextClicked)="loadItem(selectedItem+1)"
      [buttonColor]="''"
    ></m-ui-navigation-center>

    <!-- MESSAGE GALLERY -->
    <div class="gallery-page loading-opacity">
      <!-- IMAGE -->
      <img src="{{message.gallery[selectedItem]}}"/>
    </div>

    <!-- BOTTOM ACTUAL / COUNT -->
    <div class="counter" [class.move-right]="message.qa_info">
      {{ selectedItem + 1 }}/{{ message.gallery.length }}
    </div>

    <!--BOTTOM BAR-->
    <div class="bottom-bar" *ngIf="message.qa_info">
      <div
        class="bar-button base"
        [matTooltip]="'messages.message-details-normal.qa.button-open-tooltip-disabled' | myTranslate"
        matTooltipPosition="above"
        matTooltipShowDelay="300"
        [matTooltipDisabled]="message.qa_info.qa_published"
      >

        <!-- QA -->
        <button
          (click)="openQaList()"
          class="c-base c-primary"
          [disabled]="!message.qa_info.qa_published"
        >
          Odpowiedz
        </button>
      </div>
    </div>
  </ng-container>
</div>
