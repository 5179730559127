import { ParametersService } from "../../../../../../core/providers/parameters.service";
import { InjectorVariable } from "../../../../../../core/utility/injector-instance.helper";

export function getOutcomeParameters(parameters: any[]) {
  const parametersService = InjectorVariable.instance.get<ParametersService>(ParametersService);

  return parametersService.getParametersFromValues(parameters).filter(parameter => parameter.outcome !== 0 && parameter.outcome !== null);
}

export function getNotOutcomeParameters(parameters: any[], isUpgrade?: boolean) {
  const parametersService = InjectorVariable.instance.get<ParametersService>(ParametersService);

  return parametersService.getParametersFromValues(parameters).filter(parameter => {
    if (!isUpgrade) {
      return parameter.income !== 0 && parameter.income !== null;
    } else {
      return parameter.income !== null;
    }
  });
}
