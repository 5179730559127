import { Component, OnInit } from "@angular/core";

import { AbstractBuildingUpgradeDiffProductionComponent } from "../../../../abstract/core/abstract-building-upgrade-diff-production.component";

@Component({
  selector: "app-building-upgrade-diff-production",
  templateUrl: "./building-upgrade-diff-production.component.html",
})
export class BuildingUpgradeDiffProductionComponent extends AbstractBuildingUpgradeDiffProductionComponent implements OnInit {
  valuesToBeShown = {
    currentValue: 0,
    upgradedValue: 0,
    isNew: false,
    showCurrentValue: true,
    showDifferenceComponent: false,
  };

  ngOnInit() {
    if (this.upgrade?.product_prizes.length) {
      this.compareValues(this.normal?.product_prizes?.[0].amount, this.upgrade?.product_prizes?.[0].amount);
    } else {
      this.compareValues(this.normal?.currency_prizes?.[0].amount, this.upgrade?.currency_prizes?.[0].amount);
    }
  }

  compareValues(firstVal: number, secondVal: number) {
    // default case - update from lower to higher value
    if (firstVal && secondVal && firstVal !== secondVal) {
      this.valuesToBeShown.currentValue = firstVal;
      this.valuesToBeShown.upgradedValue = secondVal;
      this.valuesToBeShown.showDifferenceComponent = true;
      return;
    }

    // case(fallback) - same as default one but firstValue is null
    if (firstVal === undefined && secondVal && !this.parameters.new) {
      this.valuesToBeShown.currentValue = 0;
      this.valuesToBeShown.upgradedValue = secondVal;
      this.valuesToBeShown.showDifferenceComponent = true;
      return;
    }

    // case - new product received(label "new")
    if (firstVal === undefined && secondVal && this.parameters.new) {
      // this.valuesToBeShown.currentValue = firstVal;
      this.valuesToBeShown.upgradedValue = secondVal;
      this.valuesToBeShown.isNew = true;
      this.valuesToBeShown.showCurrentValue = false;
      this.valuesToBeShown.showDifferenceComponent = true;
      return;
    }
  }
}
