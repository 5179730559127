import * as R from 'ramda';
import { SCHEMA_FILTER_DEFINITIONS, SchemaFilterTitleDetails } from '../../../interfaces/custom/rank-visibility-schema.interface';
import { GAME_CONFIG } from '../../../../../../../core/config/custom/_parsed-game.config';
import { MyIdObject, RankLevel } from '../../../../../interfaces/custom/rank.interface';
import { RankBase } from '../../../../../interfaces/custom/rank-details.interface';

export function showTabBySchema(levels: RankLevel[], activeLevel: RankLevel, schema: SchemaFilterTitleDetails): { activeLevel: RankLevel } {
  levels.forEach((level) => {
    level.show = false;

    schema.tabs.forEach((tab) => {
      if (tab === level.filterKey) {
        level.show = true;
        // ACTIVE FIRST SHOW LEVEL
        if (!activeLevel) {
          activeLevel = level;
        }
      }
    });
  });

  return {
    activeLevel,
  };
}

export function getSchemaByGroupAndTitleId(group: string, myIdObject: MyIdObject): SchemaFilterTitleDetails {
  const idStringCase = GAME_CONFIG.IS_CC ? titleIdToStringCaseCC(myIdObject) : titleIdToStringCase(myIdObject);
  return R.clone(SCHEMA_FILTER_DEFINITIONS[group][idStringCase]);
}

function titleIdToStringCase(myIdObject: MyIdObject): string {
  switch (myIdObject.title_id) {
    case 1:
    case 2:
      return '1-2';
    case 3:
      return '3';
    case 4:
    case 5:
      return '4-5';
    case 6:
      return '6';
    case 18:
    case 19:
    case 20:
    case 21:
      return '18-21';
    default:
      return '1-2';
  }
}

function titleIdToStringCaseCC(myIdObject: MyIdObject): string {
  switch (myIdObject.title_id) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
    case 27:
    case 28:
    case 29:
    case 30:
      return '1-2';

    case 18:
    case 19:
    case 20:
    case 21:
    case 31:
      return '18-21';

    default:
      return '1-2';
  }
}

export function checkSchemaButtons(schema: SchemaFilterTitleDetails, active: RankLevel, myIdObject: MyIdObject, rankDetails: RankBase[]) {
  checkSchemaButtonMyWorkers(schema, active, myIdObject);
  checkSchemaButtonMyPosition(schema, active, myIdObject, rankDetails);
}

function checkSchemaButtonMyWorkers(schema: SchemaFilterTitleDetails, active: RankLevel, myIdObject: MyIdObject) {
  const currentButtonMyWorkers = {
    id: null,
    filterKey: null,
    isActive: false,
  };

  const schemaActiveFilterKey = schema[active.filterKey || 'country'];

  if (schemaActiveFilterKey.buttonMyWorkers) {
    const buttonMyWorkersKey = schemaActiveFilterKey.buttonMyWorkers;

    schema.currentButtonMyWorkers = {
      id: myIdObject[buttonMyWorkersKey],
      filterKey: schemaActiveFilterKey.buttonMyWorkers,
      isActive: false,
    };
  } else {
    schema.currentButtonMyWorkers = currentButtonMyWorkers;
  }
}

function checkSchemaButtonMyPosition(schema: SchemaFilterTitleDetails, active: RankLevel, myIdObject: MyIdObject, rankDetails: RankBase[]) {
  const schemaActiveFilterKey = schema[active.filterKey || 'country'];

  if (schemaActiveFilterKey.buttonMyPosition) {
    for (let i = 0; i < rankDetails.length; i++) {
      if (rankDetails[i][schemaActiveFilterKey.buttonMyPosition] === myIdObject[schemaActiveFilterKey.buttonMyPosition]) {
        schema.currentMyPositionId = i;
        break;
      }
    }
  } else {
    schema.currentMyPositionId = null;
  }
}
