import { Component } from "@angular/core";
import { EventEmitterDialogsService } from "src/app/core/services/core/event-emitter-dialogs.service";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { PLAYER_TITLES } from "../../../../../../../core/consts/core/player-titles";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { AssetsService } from "../../../../../../../core/providers/assets.service";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { DashboardComponent } from "../../dialogs/dashboard/dashboard.component";
import { NotificationsService } from "../../../../../services/custom/notifications.service";
import { ApiPlayerService } from "../../../../hud/api/custom/services/api-player.service";
import { BusinessPreviewBranchesComponent } from "../../dialogs/business-preview-dashboard/business-preview-branches/business-preview-branches.component";
import { BusinessPreviewDashboardComponent } from "../../dialogs/business-preview-dashboard/business-preview-dashboard.component";
import { OwDate } from "../../../../../../../core/utility/ow-date.class";
import { BusinessDateService } from "../../../../shared-ui/services/custom/business-date.service";
import { UserService } from "../../../../../../user/providers/user.service";
import {EVENT_DIALOGS_NAMES_WF_CUSTOM} from '../../../../wf/consts/custom/event-dialogs/event-names.const';

@Component({
  selector: "app-san-business",
  templateUrl: "./san-business.component.html",
})
export class SanBusinessComponent extends AbstractInjectBaseComponent {
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(AssetsService) assetsService: AssetsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(NotificationsService) notificationsService: NotificationsService;
  @OwInject(ApiPlayerService) apiPlayerService: ApiPlayerService;
  @OwInject(BusinessDateService) businessDateService: BusinessDateService;
  @OwInject(UserService) userService: UserService;

  PLAYER_TITLES = PLAYER_TITLES;
  _owDate: OwDate;

  set owDate(owDate: OwDate) {
    this._owDate = owDate;
  }

  get owDate(): OwDate {
    return this._owDate;
  }

  ngOnInit() {
    this.owDate = new OwDate(this.businessDateService.currentBusinessDate);
  }

  openDashboard() {
    const guiUnlocksKey = this.playerService.player.title_id.id >= PLAYER_TITLES.DOR.id ? "business_dashboard_dor" : "business_dashboard";

    if (this.playerService.player.gui_unlocks[guiUnlocksKey]) {
      if (
        this.playerService.player.title_id.id == PLAYER_TITLES.PARTNER.id ||
        (this.playerService.player.title_id.id == PLAYER_TITLES.KIEROWNIK.id && this.userService.me.enabled_players.length > 1)
      ) {
        this.apiPlayerService
          .branches({
            page: 1,
            limit: 100,
            year: this.owDate.year,
            month: this.owDate.month,
            player_id: !this.playerService.isActiveMe ? this.playerService.player.id : undefined,
          })
          .subscribe((resp: any) => {
            if (resp.body.length > 1 && this.playerService.player.title_id.id != PLAYER_TITLES.KIEROWNIK.id) {
              this.dialogService.open(BusinessPreviewBranchesComponent);
            } else {
              this.dialogService.open(BusinessPreviewDashboardComponent, {
                data: {
                  branch: resp.body.find(obj => obj.branch_id === this.playerService.player.branch_id) || resp.body[0],
                  branches: resp.body,
                },
              });
            }
          });
      } else {
        this.dialogService.open(DashboardComponent);
      }
    } else {
      this.dialogService.openAlert({
        description: "Centrum danych (dostępne wkrótce)",
      });
    }

    if (this.playerService.player["gui_notifications"]?.["import_dashboard_prize"] > 0) {
      this.notificationsService.setNotificationRead("import_dashboard_prize").subscribe();
    }
  }

  openWF() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_WF_CUSTOM.MAIN
    });
  }
}
