import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiOptions } from "../../../../../../core/interfaces/api-options";
import { ApiService } from "../../../../../../core/providers/api.service";
import { Auction, BidAuction } from "../../interfaces/custom/auction.interface";

@Injectable({
  providedIn: "root",
})
export class ApiAuctionsService {
  constructor(protected apiService: ApiService) {}

  getApiAuctions(): Observable<Auction[]> {
    return this.apiService.get(`auction/list`);
  }

  getApiAuctionDetails(id): Observable<Auction> {
    return this.apiService.get(`auction/${id}/details`);
  }

  postApiAuctionBid(data: BidAuction) {
    const options: ApiOptions = {
      body: {
        bid: {
          bid: data.bid,
          phone: data.phone,
        },
      },
    };
    return this.apiService.post(`auction/${data.auction_id}/bid`, options);
  }

  postApiAuctionChooseCurrency(data: { bid_id: number; currency_id: number }) {
    return this.apiService.patch(`auction/bid/${data.bid_id}/set-currency/${data.currency_id}`);
  }
}
