export const NOTIFICATIONS_NAMES = {
  PHOTO_BOOTH: "photo_booth",
  SURVEY: "survey",
  QA: "qa",
};

export const BUILDING_NOTIFICATION = {
  [NOTIFICATIONS_NAMES.PHOTO_BOOTH]: "gallery",
  [NOTIFICATIONS_NAMES.SURVEY]: "survey",
  [NOTIFICATIONS_NAMES.QA]: "qa",
};
