<ng-container *ngIf="!isLoading && auctions?.length">
  <div class="content loading-opacity" [ngClass]="'type-' + typeAuction">
    <ow-perfect-scrollbar class="loading-opacity" [scrollGradient]="auctions.length">
      <ng-container *ngFor="let auction of auctions; trackBy: trackItem">
        <div
          class="card"
          *ngIf="isLoadingAuctionsDone"
          (click)="openAuctionDetails(auction.id)"
        >
          <img
            class="ribbon"
            *ngIf="auction.status.value === STATUS.FINISHED && auction.my_bid?.has_won"
            [src]="'ribbon.png' | asset: 'ui'"
          />

          <img
            class="allegro-currency"
            *ngIf="auction.status.value === STATUS.ACTIVE && auction.can_choose_currency"
            [src]="'allegro_currency.png' | asset: 'ui'"
            [matTooltip]="'Po zakończeniu tej aukcji, zwycięzca ma możliwość wyboru waluty, jaką zapłaci.'"
            [matTooltipShowDelay]="400"
            [matTooltipPosition]="'above'"
          />

          <div
            class="allegro-currency-choose v-card"
            *ngIf="auction.status.value === STATUS.FINISHED && auction.can_choose_currency && auction.my_bid
            && auction.my_bid.has_won && auction.my_bid.currency_id === null"
            [matTooltip]="'Wybierz walutę, którą zapłacisz za aukcję.'"
            [matTooltipShowDelay]="400"
            [matTooltipPosition]="'above'"
          >!</div>

          <img [src]="auction.photo" />

          <p class="name bold center" owEllipsis [innerHTML]="auction.title"></p>

          <div class="sv-1 center h-16">
            <ng-container *ngIf="auction.timer && auction.status.value !== STATUS.FINISHED">
              Do końca aukcji pozostało:
              <span [class.alert]="auction.timerSoonEnd">
              <ng-container *ngIf="auction.isTimeIntSet">{{ auction.timer | toHour }}</ng-container>
              <ng-container *ngIf="!auction.isTimeIntSet">{{ auction.timer }}</ng-container>
            </span>
            </ng-container>

            <span class="alert black" *ngIf="auction.status.value === STATUS.FINISHED">Aukcja zakończona</span>
          </div>

          <div class="box" [class.top]="auction.status.value !== STATUS.FINISHED">
            <m-ui-currency
              [item]="{ currency_id: auction.currency_id, amount: (!auction.highest_bid || (auction.highest_bid.amount < auction.min_bid) ? auction.min_bid : auction.highest_bid?.amount) }"
              [stockView]="STOCK_VIEW.CUSTOM"
              [customIconSize]="'big'"
              [customClassStock]="'stock-san-a'"
              [lack]="false"
            ></m-ui-currency>

            <div class="sv-1 center" *ngIf="auction.my_bid" [class.m-top]="auction.status.value === STATUS.FINISHED">
              <ng-container *ngIf="auction.status.value !== STATUS.FINISHED">
                Twoja maksymalna oferta:
                <ng-container *ngIf="findBalanceByCurrencyId(auction.currency_id)?.key === 'pln'">
                  {{ auction.my_bid?.max_bid }} PLN
                </ng-container>
                <ng-container *ngIf="findBalanceByCurrencyId(auction.currency_id)?.key === 'cash'">
                  {{ auction.my_bid?.max_bid }} ZT
                </ng-container>
                <ng-container *ngIf="auction.currency_id === 1">
                  {{ auction.my_bid?.max_bid }} B
                </ng-container>
              </ng-container>
              <ng-container *ngIf="auction.status.value === STATUS.FINISHED">
                <span *ngIf="auction.my_bid.has_won" class="alert green">WYGRANA</span>
                <span *ngIf="!auction.my_bid.has_won">Niewygrana</span>
              </ng-container>
            </div>

            <div class="sv-1 bold center" *ngIf="auction.status.value !== STATUS.FINISHED && auction.my_bid?.is_highest">
              Twoja oferta jest aktualnie najwyższa
            </div>
          </div>

          <div class="sv-1 center">
            <ng-container *ngIf="auction.number_of_bidders">
              Ilość licytujących: {{ auction.number_of_bidders }}
            </ng-container>
            <ng-container *ngIf="!auction.number_of_bidders">
              Nikt nie licytuje
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ow-perfect-scrollbar>
  </div>
</ng-container>
