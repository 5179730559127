import { Directive, Injectable } from "@angular/core";
import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { GlobalService } from "../../../../../../core/providers/global.service";
import { ApiBasicActionsService } from "../../api/services/api-basic-actions.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { BasicActionCurrent, BasicActionDetails } from "../../interfaces/custom/basic-actions.interface";
import { PlayerService } from "../../../../../player/providers/player.service";
import { NotificationsService } from "../../../../services/custom/notifications.service";
import { Branch } from "../../../../interfaces/branch.interface";

@Directive()
@Injectable()
export abstract class AbstractBasicActionsComponent extends AbstractInjectBaseComponent {
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(ApiBasicActionsService) apiBasicActionsService: ApiBasicActionsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(NotificationsService) notificationsService: NotificationsService;
  @OwInject(PlayerService) playerService: PlayerService;
  showBaseActions: boolean;
  basicActionDetails: BasicActionDetails;
  basicActionCurrent: BasicActionCurrent[];
  STOCK_VIEW = STOCK_VIEW;

  toggleBaseActions(branch_id?: Branch["branch_id"]) {
    this.showBaseActions = !this.showBaseActions;
    this.getBasicActionCurrent(branch_id);
  }

  getBasicActionDetails(date) {
    this.apiBasicActionsService.getBasicActionsDetails({ date }).subscribe(
      (resp: BasicActionDetails) => {
        this.basicActionDetails = resp;
        if (this.basicActionDetails.items.length === 0 && this.basicActionDetails.date_prev) {
          this.getBasicActionDetails(this.basicActionDetails.date_prev);
        }
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  getBasicActionCurrent(branch_id?: Branch["branch_id"]) {
    if (this.basicActionCurrent) {
      return;
    }

    this.apiBasicActionsService.getBasicActionsCurrent(branch_id).subscribe(
      (resp: BasicActionCurrent[]) => {
        this.basicActionCurrent = resp;
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }
}
