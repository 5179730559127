<div class="recipe" [class.blur]="isActiveMultiView">
  <div class="production-container arrow">
    <div class="arrow-tip"></div>
  </div>

  <!-- SINGLE PRODUCT PRIZE CONDITION -->
  <ng-container *ngIf="production.product_prizes.length === 1; else multipleProductPrizeTpl">
    <m-ui-product
      [item]="production.product_prizes[0]"
      [stockView]="STOCK_VIEW.CUSTOM"
      [customIconSize]="'big'"
      [customClassStock]="'stock-layout-c'"
      [lack]="false"
    ></m-ui-product>
  </ng-container>

  <!-- MULTIPLE PRODUCT PRIZES CONDITION -->
  <ng-template #multipleProductPrizeTpl>
    <!-- MULTIPLE PRIZES ICON -->
    <m-ui-multi-stock
      [items]="production.product_prizes"
      [stockView]="STOCK_VIEW.B"
      (isActiveMultiView)="isActiveMultiView = $event "
    ></m-ui-multi-stock>
  </ng-template>

  <!-- CURRENCY PRICES -->
  <m-ui-currency
    *ngFor="let currency of production.production_currency_prices"
    [item]="currency"
    [customIconSize]="'big'"
    [customClassStock]="'stock-production'"
    (itemChanged)="checkCompleted($event)"
    [observable]="true"
    class="ingredient"
  >
  </m-ui-currency>

  <!-- PRODUCT PRICES -->
  <m-ui-product
    *ngFor="let product of production.production_product_prices"
    [item]="product"
    [customIconSize]="'big'"
    [customClassStock]="'stock-production'"
    (itemChanged)="checkCompleted($event)"
    [observable]="true"
    class="ingredient"
  >
  </m-ui-product>
</div>
