import { Component, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { GAME_CONFIG } from "../../../../../../../core/config/custom/_parsed-game.config";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { OwDate } from "../../../../../../../core/utility/ow-date.class";
import { OwPaginationDate } from "../../../../../../../core/utility/ow-pagination.class";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { Branch } from "../../../../../interfaces/branch.interface";
import { NotificationsService } from "../../../../../services/custom/notifications.service";
import { ApiPlayerService } from "../../../../hud/api/custom/services/api-player.service";
import { PAGINATION_TYPES } from "../../../../san-ranks/const/custom/pagination-types.const";
import { BusinessDateService } from "../../../../shared-ui/services/custom/business-date.service";
import { GetBusinessTaskQuarterRequest } from "../../../api/interfaces/request/get-business-task-quarter-request.interface";
import { GetBusinessTaskRequest } from "../../../api/interfaces/request/get-business-task-request.interface";
import { ApiBusinessService } from "../../../api/services/api-business.service";
import { QualityQuarterTasks, QualityTasks, QualityTaskScope } from "../../../interfaces/custom/quality-tasks.interface";
import { DashboardRoleChangeService } from "../../services/dashboard-role-change.service";
import { ClearNotificationService } from "../../utils/clear-notification.service";
import { QualityTaskInfoComponent } from "./quality-task-info/quality-task-info.component";

@Component({
  selector: "app-quality-tasks",
  templateUrl: "./quality-tasks.component.html",
})
export class QualityTasksComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ApiPlayerService) apiPlayerService: ApiPlayerService;
  @OwInject(BusinessDateService) businessDateService: BusinessDateService;
  @OwInject(MAT_DIALOG_DATA) data: {
    qualityTasks: QualityTasks;
    owDate: OwDate;
    tabData?: string;
    branchName?: string;
    branch?: Branch;
  };
  @OwInject(NotificationsService) notificationsService: NotificationsService;
  @OwInject(ClearNotificationService) clearNotificationService: ClearNotificationService;
  @OwInject(DashboardRoleChangeService) dashboardRoleChangeService: DashboardRoleChangeService;

  qualityTasks: QualityTasks | QualityQuarterTasks;
  branch?: Branch;
  branchName?: string;
  private branchSubscription: Subscription;
  private branchNameSubscription: Subscription;
  activeTabLeft: TabLeft;
  tabsLeft: TabLeft[] = [
    { label: "Miesiąc", data: "monthly", pagination: PAGINATION_TYPES.MONTH, visible: true },
    { label: "Kwartał", data: "quarterly", pagination: PAGINATION_TYPES.QUARTER, visible: true },
  ];

  PAGINATION_TYPES = PAGINATION_TYPES;
  owPaginationDate: OwPaginationDate;
  _owDate: OwDate;
  get businessDate() {
    return this.businessDateService.currentBusinessDate;
  }

  set owDate(owDate: OwDate) {
    this._owDate = owDate;
    this.owPaginationDate = new OwPaginationDate({ current: this.owDate });
  }

  get owDate(): OwDate {
    return this._owDate;
  }

  ngOnInit() {
    const initSub = { branch: true, name: true };
    this.branchSubscription = this.dashboardRoleChangeService.branch$.subscribe(branch => {
      if (initSub.branch === true) {
        this.branch = branch || this.data?.branch;
        setTimeout(() => {
          initSub.branch = false;
        }, 1000);
      } else {
        this.branch = branch;
      }
    });
    this.branchNameSubscription = this.dashboardRoleChangeService.branchName$.subscribe(branchName => {
      if (initSub.name === true) {
        this.branchName = branchName || this.data?.branchName;
        setTimeout(() => {
          initSub.name = false;
        }, 1000);
      } else {
        this.branchName = branchName;
      }
    });
    this.branch = this.data?.branch;
    this.owDate = this.data?.owDate || new OwDate();
    const activeTab = this.data?.tabData ? this.tabsLeft.find(t => t.data == this.data.tabData) : this.tabsLeft[0];
    this.changeFilter({ tab: activeTab, clearOwDate: false });
    this.branchName = this.data?.branchName;
  }

  changeFilter(data: { tab; clearOwDate?: boolean }) {
    this.activeTabLeft = data.tab;
    this.getData(data.clearOwDate);
  }

  clearData() {
    this.qualityTasks = null;
  }

  getDataWithBranchInfo(clearOwDate?: boolean) {
    this.dashboardRoleChangeService.owDate = this.owDate;
    this.dashboardRoleChangeService.getDataWithBranchInfo(() => {
      this.getData(clearOwDate);
    }, this.branch);
  }

  checkRoleChanges() {
    if (this?.branch?.role_changed || !this?.branch) {
      this.dashboardRoleChangeService.owDate = this.owDate;
      this.dashboardRoleChangeService.checkRoleChanges();
    }
  }

  getData(clearOwDate?: boolean) {
    this.clearData();
    this.checkRoleChanges();

    switch (this.activeTabLeft.pagination) {
      case PAGINATION_TYPES.MONTH:
        if (clearOwDate) {
          this.owDate = new OwDate();
        }
        const { month, year } =
          (this.owDate.year === this.businessDate?.year && this.owDate.month > this.businessDate?.month) ||
          this.owDate.year > this.businessDate?.year
            ? new OwDate(this.businessDate)
            : this.owDate;
        const requestDataMonth: GetBusinessTaskRequest = {
          month,
          year,
        };

        this.getTasks(requestDataMonth, this.branch);
        break;

      case PAGINATION_TYPES.QUARTER:
        const owDateQuarter = clearOwDate ? new OwDate() : this.owDate;
        const requestDataQuarter: GetBusinessTaskQuarterRequest = {
          quarter: owDateQuarter.quarter,
          year: owDateQuarter.year,
        };
        this.setDateAndPagination(owDateQuarter);
        this.getQuarterTasks(requestDataQuarter, this.branch);
        break;
    }
  }

  getTasks(requestData: GetBusinessTaskRequest, branch?: Branch) {
    this.apiBusinessService.tasks(requestData, branch).subscribe(
      data => {
        this.setDateAndPagination(data);
        this.qualityTasks = data.quality_tasks;
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  getQuarterTasks(requestData: GetBusinessTaskQuarterRequest, branch?: Branch) {
    this.apiBusinessService.tasksQualityQuarter(requestData, branch).subscribe(
      data => {
        this.qualityTasks = data;
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  setDateAndPagination(data: { year?; month?; quarter? }) {
    this.owDate = new OwDate(data);

    this.owPaginationDate = new OwPaginationDate({
      current: this.owDate,
      end: this.activeTabLeft.pagination === this.PAGINATION_TYPES.MONTH ? new OwDate(this.businessDate) : null,
    });
  }

  openQualityTaskInfo(taskScopes: QualityTaskScope[]) {
    this.dialogService.open(QualityTaskInfoComponent, {
      data: {
        taskScopes,
        quarter: this.activeTabLeft.data == "quarterly" ? this.owDate.quarter : null,
      },
    });
  }

  next() {
    const pagination = this.activeTabLeft.pagination == PAGINATION_TYPES.MONTH ? { month: 1 } : { quarter: 1 };
    this.owDate.add(pagination);
    if (this?.branch && !this?.branch?.role_changed) {
      this.getDataWithBranchInfo();
    } else {
      this.getData();
    }
  }

  prev() {
    const pagination = this.activeTabLeft.pagination == PAGINATION_TYPES.MONTH ? { month: 1 } : { quarter: 1 };
    this.owDate.subtract(pagination);
    if (this?.branch && !this?.branch?.role_changed) {
      this.getDataWithBranchInfo();
    } else {
      this.getData();
    }
  }

  clearNotification() {
    this.clearNotificationService.clearNotification(["import_calculate_quality"], this.branch?.manager_player_id);
  }

  ngOnDestroy() {
    this.clearNotification();
    this.branchSubscription.unsubscribe();
    this.branchNameSubscription.unsubscribe();
  }

  protected readonly GAME_CONFIG = GAME_CONFIG;
}

interface TabLeft {
  label: string;
  data: string;
  pagination: string;
  visible: boolean;
}
