import { Component, OnInit } from "@angular/core";
import { AbstractBuildingUpgradeDiffParameterComponent } from '../../../../abstract/core/abstract-building-upgrade-diff-parameter.component';

@Component({
  selector: 'app-building-upgrade-diff-parameter',
  templateUrl: './building-upgrade-diff-parameter.component.html',
})
export class BuildingUpgradeDiffParameterComponent extends AbstractBuildingUpgradeDiffParameterComponent implements OnInit {
  determineValue: "income" | "outcome" = "income";

  ngOnInit() {
    if (this.normal?.income !== this.upgrade?.income) {
      this.determineValue = "income";
    } else {
      this.determineValue = "outcome";
    }
  }
}
