import { Component, Input } from '@angular/core';
import { MUiStockOtherComponent } from '../../ui-stock-other/ui-stock-other.component';

@Component({
  selector: 'm-ui-stock-other-custom',
  templateUrl: '../../stock.html',
})
export class MUiStockOtherCustomComponent extends MUiStockOtherComponent {
  @Input() iconType: string;

  setIconUrl() {
    switch (this.iconType) {
      case 'warehouse-capacity':
        this.item.iconUrl = this.assetsService.getAssetPath(`ui/warehouse_capacity.png`);
        this.item.iconUrlBig = this.assetsService.getAssetPath(`ui/warehouse_capacity.png`);
        break;

      default:
        this.item.iconUrl = this.assetsService.getAssetPath(`icons/basic/${this.item.icon}.png`);
        this.item.iconUrlBig = this.assetsService.getAssetPath(`icons/big/${this.item.icon}.png`);
    }
  }
}
