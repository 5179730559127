import { Component, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { OwDate } from "../../../../../../../core/utility/ow-date.class";
import { OwPaginationDate } from "../../../../../../../core/utility/ow-pagination.class";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { Branch } from "../../../../../interfaces/branch.interface";
import { NotificationsService } from "../../../../../services/custom/notifications.service";
import { ApiPlayerService } from "../../../../hud/api/custom/services/api-player.service";
import { STOCK_VIEW } from "../../../../shared-ui/mobile/consts/stock-view.const";
import { BusinessDateService } from "../../../../shared-ui/services/custom/business-date.service";
import { GetBusinessTaskRequest } from "../../../api/interfaces/request/get-business-task-request.interface";
import { ApiBusinessService } from "../../../api/services/api-business.service";
import { CARD_TEMPLATE } from "../../../consts/custom/card-template.const";
import { Task } from "../../../interfaces/custom/task.interface";
import { setOrderWithCurrency, setOrderWithType } from "../../helpers/order-prizes.helper";
import { DashboardRoleChangeService } from "../../services/dashboard-role-change.service";
import { ClearNotificationService } from "../../utils/clear-notification.service";
import { CardTaskDetailsComponent } from "../card-task-details/card-task-details.component";

@Component({
  selector: "app-card-tasks",
  templateUrl: "./card-tasks.component.html",
})
export class CardTasksComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(BusinessDateService) businessDateService: BusinessDateService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<CardTasksComponent>;
  @OwInject(NotificationsService) notificationsService: NotificationsService;
  @OwInject(ClearNotificationService) clearNotificationService: ClearNotificationService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ApiPlayerService) apiPlayerService: ApiPlayerService;
  @OwInject(DashboardRoleChangeService) dashboardRoleChangeService: DashboardRoleChangeService;

  @OwInject(MAT_DIALOG_DATA) data: {
    tasks: Task[];
    taskSourceDate;
    owDate: OwDate;
    taskId: number;
    branch?: Branch;
    branchName?: string;
  };

  tasks: Task[];
  cardsTasks: Task[];
  branch?: Branch;
  branchName?: string;
  private branchSubscription: Subscription;
  private branchNameSubscription: Subscription;

  isLoading: boolean;
  CARD_TEMPLATE = CARD_TEMPLATE;
  STOCK_VIEW = STOCK_VIEW;
  isSomeCardCleared: boolean;
  tasksSourceDataDate;

  _owDate: OwDate;
  owPaginationDate: OwPaginationDate;

  set owDate(owDate: OwDate) {
    this._owDate = owDate;
    this.owPaginationDate = new OwPaginationDate({ current: this.owDate });
  }
  get owDate(): OwDate {
    return this._owDate;
  }

  ngOnInit() {
    this.branch = this.data?.branch;
    const initSub = { branch: true, name: true };
    this.branchSubscription = this.dashboardRoleChangeService.branch$.subscribe(branch => {
      if (initSub.branch === true) {
        this.branch = branch || this.data?.branch;
        setTimeout(() => {
          initSub.branch = false;
        }, 1000);
      } else {
        this.branch = branch;
      }
    });
    this.branchNameSubscription = this.dashboardRoleChangeService.branchName$.subscribe(branchName => {
      if (initSub.name === true) {
        this.branchName = branchName || this.data?.branch?.name;
        setTimeout(() => {
          initSub.name = false;
        }, 1000);
      } else {
        this.branchName = branchName;
      }
    });
    this.branchName = this.data?.branch?.name;

    if (this.data.tasks) {
      this.owDate = this.data.owDate;
      this.setPagination();
      this.tasks = this.data.tasks;
      this.prepareTasks(this.tasks);
      this.tasksSourceDataDate = this.data.taskSourceDate;

      if (this.data.taskId) {
        this.openTaskDetails();
      }
    } else {
      this.owDate = new OwDate();
      this.getTasks();
    }
  }

  ngOnChanges() {
    if (this.tasks) {
      this.prepareTasks(this.tasks);
    }
  }

  next() {
    this.owDate.add({ month: 1 });
    if (this?.branch && !this?.branch?.role_changed) {
      this.getTasksWithBranchInfo();
    } else {
      this.getTasks();
    }
  }

  prev() {
    this.owDate.subtract({ month: 1 });
    if (this?.branch && !this?.branch?.role_changed) {
      this.getTasksWithBranchInfo();
    } else {
      this.getTasks();
    }
  }

  getTasksWithBranchInfo() {
    this.dashboardRoleChangeService.owDate = this.owDate;
    this.dashboardRoleChangeService.getDataWithBranchInfo(() => {
      this.getTasks();
    }, this.branch);
  }

  checkRoleChanges() {
    if (this?.branch?.role_changed || !this?.branch) {
      this.dashboardRoleChangeService.owDate = this.owDate;
      this.dashboardRoleChangeService.checkRoleChanges();
    }
  }

  getTasks() {
    this.setPagination();

    const { month, year } = this.owDate;
    const requestData: GetBusinessTaskRequest = {
      month,
      year,
    };

    this.checkRoleChanges();

    this.apiBusinessService.tasks(requestData, this?.branch).subscribe(
      data => {
        this.tasks = data.tasks;
        this.tasksSourceDataDate = data.tasks_source_data_date;
        this.isLoading = false;
        this.prepareTasks(this.tasks);
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  setPagination() {
    this.owPaginationDate = new OwPaginationDate({
      current: this.owDate,
      end: this.data.owDate ? new OwDate(this.businessDateService.currentBusinessDate) : null,
    });
  }

  openTaskDetails() {
    const task = this.tasks.find(t => t.id == this.data.taskId);
    this.openCardTaskDetails(task);

    setTimeout(() => {
      this.matDialogRef.close();
    });
  }

  prepareTasks(tasks: Task[]) {
    this.cardsTasks = [];

    tasks.forEach((task: Task) => {
      if (!task.parent_id) {
        this.cardsTasks.push(task);
      }

      let childList = null;
      tasks.forEach(child_task => {
        if (child_task.parent_id == task.id) {
          if (childList) {
            childList.nextTask = child_task;
          } else {
            task.nextTask = child_task;
          }
          childList = child_task;
        }
      });

      if (childList) {
        childList.nextTask = task;
        childList.hideOnList = true;
      }

      /**
       * Search 100 percentage scope
       */
      ["1", "2", "3"].forEach(key => {
        if (task["scope" + key] == 100) {
          task.keyPercentage = key;
        }
      });

      this.setClassName(task);
      this.setPrizesOrder(task);
      this.checkSomeTaskCleared();
    });
  }

  checkSomeTaskCleared() {
    this.isSomeCardCleared = this.tasks.some(t => t.is_cleared);
  }

  setClassName(task: Task) {
    switch (task.card_template_id) {
      default:
        task.className = null;
    }
  }

  openCardTaskDetails(task) {
    this.dialogService.open(CardTaskDetailsComponent, {
      data: {
        task,
        cardTasks: this.cardsTasks,
        allTasks: this.tasks,
        owDate: this.owDate,
      },
    });
  }

  setPrizesOrder(task) {
    const scopes = task.scopes;
    Object.keys(scopes).forEach(el => {
      let prizes = scopes[el].prizes;

      prizes = setOrderWithCurrency(prizes);
      prizes = setOrderWithType(prizes);

      scopes[el].prizes = prizes;
    });

    if (task.prizes && task.prizes.length) {
      task.prizes = setOrderWithCurrency(task.prizes);
      task.prizes = setOrderWithType(task.prizes);
    }

    if (task.rewards) {
      if (task.rewards.cards && task.rewards.cards.length) {
        task.rewards.cards = setOrderWithCurrency(task.rewards.cards);
        task.rewards.cards = setOrderWithType(task.rewards.cards);
      }

      if (task.rewards.minimums && task.rewards.minimums.length) {
        task.rewards.minimums = setOrderWithCurrency(task.rewards.minimums);
        task.rewards.minimums = setOrderWithType(task.rewards.minimums);
      }

      if (task.rewards.scopes && task.rewards.scopes.length) {
        task.rewards.scopes = setOrderWithCurrency(task.rewards.scopes);
        task.rewards.scopes = setOrderWithType(task.rewards.scopes);
      }
    }
  }

  clearNotification() {
    this.clearNotificationService.clearNotification(["import_calculate_tasks"], this?.branch?.manager_player_id);
  }

  ngOnDestroy() {
    this.clearNotification();
    this.branchNameSubscription.unsubscribe();
    this.branchSubscription.unsubscribe();
  }
}
