<div class="global-dialog ow-dialog c-window-long">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button
    *ngIf="!data.hideBackButton"
  ></m-ui-back-button>

  <loading></loading>

  <ng-container *ngIf="data.eventShop as eventShop">
    <div class="shop-details-info" *ngIf="eventShop.event_shop_id !== 100">
      <div
        class="item"
        matTooltip="Czas na zbieranie żetonów"
        matTooltipClass="delayed"
      >
        <img
          [src]="'cointime.png' | asset:'ui'"
          class="icon"
        />
        <div class="dates">
          {{ eventShop.currency_collection_start | date: 'dd.MM.yy'}} -
          {{ eventShop.currency_collection_end | date: 'dd.MM.yy'}}
        </div>
      </div>

      <div
        class="item"
        matTooltip="Czas na wymianę żetonów"
        matTooltipClass="delayed"
      >
        <div
          class="icon-container"
          [class.icon-margin]="!eventShop.is_repeatable"
        >
          <img
            [src]="'shop.png' | asset:'ui'"
            class="icon"
          />
          <img
            *ngIf="!eventShop.is_repeatable"
            [src]="'exclamation.png' | asset:'ui'"
            class="icon not-repeatable"
            matTooltip="Ten kolor żetonów wykorzystasz tylko w podanym terminie"
            matTooltipPosition="above"
            matTooltipClass="delayed"
          />
        </div>

        <div class="dates">
          {{ eventShop.open_start | date: 'dd.MM.yy'}} -
          {{ eventShop.open_end | date: 'dd.MM.yy'}}
        </div>
      </div>
    </div>

    <div
      class="shop-details-container"
      [class]="eventShop['color']"
    >
      <div class="shop-title">
        <span>{{ eventShop.title }}</span>

        <ng-container *ngIf="ranks.length">
          <button
            class="ranks"
            (click)="openRanks()"
            matTooltip="Rankingi"
            matTooltipClass="delayed"
          >
            <i class="fas fa-chart-line"></i>
          </button>
        </ng-container>
      </div>

      <div
        *ngIf="({currency_id: eventShop.currencyId, amount: null} | singleCurrency) as currency"
        class="currency"
      >
        <img
          class="icon"
          [src]="currency.iconUrlBig"
        />
        <i
          class="icon-history fas fa-history"
          (click)="openHistoryTransaction(currency)"
          [class.cursor-pointer]="currency.show_currency_history"
          matTooltip="Historia żetonów"
          matTooltipClass="delayed"
        ></i>
        <span>
          <ng-container *ngIf="currency.type === 'player'">
            {{ (playerService.player.currency_balances | currencyGameBalance:currency.key).balance | NumberWithSpaces }}
          </ng-container>
          <ng-container *ngIf="currency.type === 'user'">
            {{ (userService.me.currency_balances | currencyGameBalance:currency.key).balance | NumberWithSpaces }}
          </ng-container>
        </span>
      </div>

      <div class="shop-details">

        <ow-perfect-scrollbar scrollGradient="true">
          <div [innerHTML]="eventShop.description" class="description"></div>

          <div class="separator"></div>

          <div class="events">
            <div *ngFor="let event of eventShop.events" class="event">
              <div *ngFor="let prize of event.prizes" class="prize">
                <span>{{ prize.amount }}</span>

                <img
                  [src]="(prize | singleCurrency).iconUrl"
                  class="icon"
                />
              </div>

              <span class="event-name">{{ event.name }}</span>
            </div>
          </div>
        </ow-perfect-scrollbar>

        <div class="description-details" *ngIf="eventShop.terms_url">
          Więcej szczegółów znajdziesz <a [href]="eventShop.terms_url" target="_blank">TUTAJ</a>
        </div>
      </div>

    </div>


    <div class="main-container loading-opacity">
      <ow-perfect-scrollbar *ngIf="products" scrollGradient="true">
        <div class="products-container">
          <div
            *ngFor="let product of products"
            class="product-container"
            [class.to-low-lvl]="!playerService.allowRequiredLevel(product.required_level)"
            [class.to-low-star]="product.required_star && product.required_star.star_id > playerService.player.star.star_id"
            [class.gadget]="eventShop.event_shop_id === GADGET_SHOP_ID || eventShop.event_shop_id === GADGET_SHOP_ID_3"
            [class.gadget-shop-id-5]="eventShop.event_shop_id === GADGET_SHOP_ID_2"
            [class.has-edenred-card]="product.product_type === 'edenred-card' && playerService.allowRequiredLevel(product.required_level) && hasEdenredCard"
            [class.no-edenred-card]="product.product_type === 'edenred-topup' && playerService.allowRequiredLevel(product.required_level) && !hasEdenredCard"
            [class.gadget-disabled]="product.available_amount === 0"
            (click)="openEventShopProductDetails(product)"
          >

            <div class="product-details">
              <img
                class="icon"
                [src]="product.icon + '.png' | asset:'products_eventmarket/big'"
              >
              <div class="product-title">
                {{ product.name }}
              </div>

              <div
                class="available-amount"
                [class.text-error]="product.available_amount !== null"
                [class.opacity-0]="product.available_amount === null && eventShop.event_shop_id === GADGET_SHOP_ID_3"
              >
                Pozostało:
                <ng-container *ngIf="product.available_amount !== null; else infinityTpl">
                  {{ product.available_amount }}
                </ng-container>
              </div>

              <div class="product-separator"></div>

              <div class="cost">
                {{ product.price | NumberWithSpaces }}

                <img
                  class="icon-currency"
                  [src]="({currency_id: eventShop.currencyId, amount: null} | singleCurrency).iconUrl"
                />
              </div>

              <div class="limit">
                <span *ngIf="product.limit_per_person > 0; else infinityTpl">
                  {{ ((product.limit_per_person - product.limit_per_person_left) + '/' + product.limit_per_person) }}
                </span>
              </div>
            </div>

            <div class="to-low-lvl-info">
              <span>
                wymagany poziom {{ product.required_level }}
              </span>
            </div>

            <div
              class="to-low-star-info"
              *ngIf="product.required_star && product.required_star.star_id > playerService.player.star.star_id"
            >
              <span>
                wymagana {{ product.required_star.star_name }}
              </span>
            </div>

            <div class="has-edenred-card-info">
              <div class="has-edenred-card-info-padding">
                <ng-container *ngIf="product.product_type === 'edenred-card' && hasEdenredCard">
                  posiadasz już jedną kartę przedpłaconą
                </ng-container>
                <ng-container *ngIf="product.product_type === 'edenred-topup' && !hasEdenredCard">
                  nie posiadasz karty<br>przedpłaconej
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ow-perfect-scrollbar>
    </div>
  </ng-container>
</div>

<ng-template #infinityTpl>
  <i class="fas fa-infinity"></i>
</ng-template>
