import { Component } from '@angular/core';
import { AbstractAlertComponent } from '../../../../abstracts/abstract-alert.component';
import { OwInject } from '../../../../decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertImage } from '../../../../interfaces/alert';

@Component({
  selector: 'app-custom-alert-image',
  templateUrl: './alert-image.component.html',
})
export class AlertImageComponent extends AbstractAlertComponent {
  @OwInject(MAT_DIALOG_DATA) data: AlertImage;
}

