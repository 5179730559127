<div class="global-dialog ow-dialog c-window-long" >
  <loading></loading>

  <!-- BUTTON EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- TABS -->
  <div class="tabs">
    <ng-container *ngFor="let tab of tabs; let i = index">
      <div
        *ngIf="tab.show"
        class="tab"
        [class.active]="tab == activeTab"
        [class]="tab.color"
        [style.z-index]="-i"
      >
        <div class="bookmarks">
          <div
            class="bookmark"
            [class]="tab.color"
            (click)="changeTab(tab)"
          >
            <span>{{tab.label}}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="allQa && !hideView">

    <!-- ALL QA LIST-->
    <ng-container *ngIf="activeQa">
      <div class="wrapper loading-opacity" [class.hide]="activeQa.location === 50 ">

        <!-- QA NAME -->
        <div class="title quiz" [innerHTML]="activeQa.name"></div>

        <!-- CONTENT -->
        <div class="content" [ngSwitch]="activeTab.name" [ngClass]="activeTab.name">
          <ng-container *ngSwitchCase="TAB.QA" [ngTemplateOutlet]="qaTpl"></ng-container>
          <ng-container *ngSwitchCase="TAB.RANK" [ngTemplateOutlet]="rankTpl"></ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <!-- NO QA AVAILABLE-->
  <ng-container *ngIf="allQa && allQa.length === 0">
    <div class="title margin-top">{{ 'qa.qa-list.no-qa-available' | myTranslate }}</div>
  </ng-container>
</div>

<!-- TEMPLATE QA -->
<ng-template #qaTpl>
  <!-- FRAME -->
  <div class="frame loading-opacity" >

    <!-- QA DESCRIPTION -->
    <div
      *ngIf="activeQa.description"
      class="description inner-html-description"
      [innerHTML]="activeQa.description"
      owEllipsis
    ></div>

    <!-- SCORE, TIME AND PRIZES -->
    <app-custom-qa-summary
      *ngIf="activeQa.last_attempt && activeQa.last_attempt.finished_at"
      [qa]="activeQa"
    ></app-custom-qa-summary>

    <!-- SURVEY -->
    <ng-container
      *ngIf="activeQa.last_attempt && activeQa.last_attempt.finished_at && activeQa.type === QA_TYPE.SURVEY">
      <p class="finish-survey-info">Wypełniłeś już tę ankietę</p>
    </ng-container>
  </div>

  <!-- BOTTOM BAR -->
  <div class="bottom">

    <!-- SEE THE ANSWERS WHEN QA EXPIRED AND DONE ATTEMPT = 0 -->
    <div class="bar-button base" *ngIf="activeQa.all_questions">
      <button class="c-base c-primary width" (click)="openQaDetails()">
        {{ 'qa.qa-list.check-answers' | myTranslate }}
      </button>
    </div>

    <!-- SEE THE ANSWERS / RESUME QA-->
    <div
      class="bar-button base"
      *ngIf="activeQa.last_attempt as qa"
      [matTooltip]="'Poprawne odpowiedzi zobaczysz po zakończeniu ważności quizu'"
      [matTooltipPosition]="'below'"
      [matTooltipDisabled]="!(qa.finished_at && activeQa.type === QA_TYPE.QUIZ &&  activeQa.hide_answers_until_quiz_finish && !isExpiredQa)"
    >
      <button
        class="c-base c-primary width"
        (click)="openQaDetails()"
        [disabled]="(qa.finished_at && activeQa.type === QA_TYPE.QUIZ && activeQa.hide_answers_until_quiz_finish && !isExpiredQa)"
      >
        <ng-container *ngIf="qa.finished_at">
          Zobacz odpowiedzi
        </ng-container>
        <ng-container *ngIf="qa.started_at && !qa.finished_at">
          Wznów
        </ng-container>
      </button>
    </div>

    <!-- START QA-->
    <div class="bar-button base" *ngIf="!activeQa.last_attempt && !isExpiredQa">
      <button class="c-base c-primary width" (click)="startQa()">
        Rozpocznij
      </button>
    </div>

    <!-- IMPROVE QA-->
    <div
      class="bar-button base width"
      *ngIf="activeQa.type === QA_TYPE.QUIZ && activeQa.done_attempts !== 0 && activeQa.done_attempts < activeQa.max_attempts && activeQa.last_attempt?.finished_at && activeQa.last_attempt?.player_points < activeQa.last_attempt?.max_points"
    >
      <button class="c-base c-primary" (click)="startQa()">
        Popraw
      </button>
    </div>

    <!-- BACK TO LIST -->
    <div class="bar-button base back-list" *ngIf="data.isShowBackButton">
      <button class="c-base c-primary width" (click)="close()">
        Wróć do listy
      </button>
    </div>
  </div>
</ng-template>

<!-- TEMPLATE RANK -->
<ng-template #rankTpl>

  <!-- FRAME -->
  <div class="frame">

    <!-- FORM -->
    <div class="default-field">
      <input
        type="text"
        id="search"
        placeholder="Szukaj..."
        (keyup)="searchValue = $event.target['value']"
      />
      <label for="search">Szukaj...</label>
    </div>

<!--    <app-ranking-details-game-->
<!--      [data]="{-->
<!--        rankId: activeQa['rank_edition_id'],-->
<!--        innerRank: true,-->
<!--        group: 'employee',-->
<!--        hideFilters: true,-->
<!--        titleIds: []-->
<!--      }"-->
<!--      [searchValue]="searchValue"-->
<!--    ></app-ranking-details-game>-->
  </div>
</ng-template>
