import { Component, OnInit } from "@angular/core";
import * as R from "ramda";

import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { GlobalService } from "../../../../../../../core/providers/global.service";
import { ParametersService } from "../../../../../../../core/providers/parameters.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { PlayerParameterBalance } from "../../../../../interfaces/parameters";
import { GameService } from "../../../../../services/game.service";
import { AbstractHudParametersComponent } from "../../../abstract/core/abstract-hud-parameters.component";
import { PlayerParameterBalanceHud } from "../../../interfaces/core/player-parameter-balance-hud.interface";

@Component({
  selector: "app-san-hud-parameters",
  templateUrl: "./hud-parameters.component.html",
})
export class HudParametersComponent extends AbstractHudParametersComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(ParametersService) parametersService: ParametersService;
  @OwInject(GameService) gameService: GameService;

  PLACEHOLDERS_CUSTOM = [
    {
      placeholder: "%current_outcome_value",
    },
    {
      placeholder: "%current_income_value",
    },
  ];

  parameters = [];

  GROUP_ONE = [1, 3, 4, 5, 6, 7];
  GROUP_TWO = [1, 3, 4, 5, 6, 7];
  NO_OUTCOME_LIST = [7]; // don't add outcome to these parameters id
  filteredParameters = [];

  isWorldScene: boolean;

  subs = {
    parameters: null,
    player: null,
    global: null,
    primary: null,
  };

  setHudParameters() {
    this.parameters = this.parametersService.getParametersFromValues(this.player.parameter_balances);
    const group = this.isWorldScene ? this.GROUP_TWO : this.GROUP_ONE;

    this.filteredParameters = this.parameters
      .filter(parameter => {
        return R.includes(parameter.parameter_id, group);
      })
      .filter(parameter => {
        return parameter.is_parameter_active;
      })
      .filter(parameter => {
        return parameter.position >= 0;
      });

    this.filteredParameters = R.sortBy(R.prop("position"), this.filteredParameters);

    this.filteredParameters.forEach(parameter => {
      this.setTooltip(parameter);
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeGlobalService();
  }

  subscribeGlobalService() {
    this.globalService.globalEvents.subscribe(event => {
      if (event.name === "SCENE_CONFIG_CHANGE") {
        const sceneOtherConfig = R.path(["other"], event.value) as any;
        this.isWorldScene = sceneOtherConfig && sceneOtherConfig.isWorldScene;

        this.setHudParameters();
      }
    });
  }

  setTooltip(parameter: PlayerParameterBalanceHud) {
    super.setTooltip(parameter);

    if (parameter.tooltip) {
      Object.keys(this.PLACEHOLDERS_CUSTOM).forEach(key => {
        const placeholderObject: {
          placeholder: string;
          getValue: (parameter?: PlayerParameterBalance) => any;
        } = this.PLACEHOLDERS_CUSTOM[key];

        if (placeholderObject.placeholder === "%current_outcome_value") {
          placeholderObject.getValue = () => `${parameter.outcome}`;
        }

        if (placeholderObject.placeholder === "%current_income_value") {
          placeholderObject.getValue = () => `${parameter.income}`;
        }

        parameter.tooltip = parameter.tooltip.replace(placeholderObject.placeholder, placeholderObject.getValue(parameter));
      });
    }
  }
}
