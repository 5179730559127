export enum ShopColorsEnum {
  'yellow',
  'gray',
  'orange-light',
  'blue-light',
  'red',
  'green',
  'orange',
  'green-light'
}
