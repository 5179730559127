import { Component, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import * as R from "ramda";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { AssetsService } from "../../../../../../../core/providers/assets.service";
import { CurrencyService } from "../../../../../../../core/providers/currency.service";
import { OwDate } from "../../../../../../../core/utility/ow-date.class";
import { OwPaginationDate } from "../../../../../../../core/utility/ow-pagination.class";
import { unsubscribeObject } from "../../../../../../../core/utility/unsubscribe-array";
import { PlayerSelectors } from "../../../../../../../store/player";
import { AppState } from "../../../../../../../store/state";
import { Player } from "../../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { RANKS_NAME } from "../../../../../constants/custom/rank.const";
import { NotificationsService } from "../../../../../services/custom/notifications.service";
import { EventBusinessListComponent } from "../../../../hud/custom/dialogs/event-business-list/event-business-list.component";
import { GetBusinessTaskRequest } from "../../../../san-business/api/interfaces/request/get-business-task-request.interface";
import { ApiBusinessService } from "../../../../san-business/api/services/api-business.service";
import { CARD_TEMPLATE } from "../../../../san-business/consts/custom/card-template.const";
import { BasicActionsComponent } from "../../../../san-business/custom/dialogs/basic-actions/basic-actions.component";
import { BusinessCalendarComponent } from "../../../../san-business/custom/dialogs/business-calendar/business-calendar.component";
import { MicroLeagueTabsComponent } from "../../../../san-business/custom/dialogs/business-challenges/micro-league-tabs/micro-league-tabs.component";
import { setOrderWithCurrency, setOrderWithType } from "../../../../san-business/custom/helpers/order-prizes.helper";
import { Task } from "../../../../san-business/interfaces/custom/task.interface";
import { RanksComponent } from "../../../../san-ranks/basic/custom/dialogs/ranks/ranks.component";
import { STOCK_VIEW } from "../../../../shared-ui/mobile/consts/stock-view.const";
import { CardTaskDetailsCCComponent } from "../card-task-details-cc/card-task-details-cc.component";
import { QualityTasksCCComponent } from "../quality-tasks-cc/quality-tasks-cc.component";

@Component({
  selector: "app-dashboard-cc",
  templateUrl: "./dashboard-cc.component.html",
})
export class DashboardCCComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(AssetsService) assetsService: AssetsService;
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;
  @OwInject(CurrencyService) currencyService: CurrencyService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(NotificationsService) notificationsService: NotificationsService;

  CARD_TEMPLATE = CARD_TEMPLATE;
  STOCK_VIEW = STOCK_VIEW;
  player: Player;
  subs = {
    player: null,
  };

  owPaginationDate: OwPaginationDate;
  _owDate: OwDate;
  tasks: Task[];
  cardsTasks: Task[];
  isSomeCardCleared: boolean;

  hideQualityTasks: boolean;
  isPlayable: boolean;
  tasksSourceDataDate;
  businessCalendarAvailable: boolean = false;

  set owDate(owDate: OwDate) {
    this._owDate = owDate;
    this.owPaginationDate = new OwPaginationDate({ current: this.owDate });
  }

  get owDate(): OwDate {
    return this._owDate;
  }

  ngOnInit() {
    this.clearOwDate();
    this.getData();
    this.subscribePlayer();
    this.businessCalendarAvailable = this.playerService.player.gui_unlocks["business_calendar"];
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(PlayerSelectors.selectPlayer)).subscribe(player => {
      this.player = player;
      this.isPlayable = this.player.title_id.playable;
      this.hideQualityTasks = this.player.title_id["rank_group"] == 2 || this.player.title_id["rank_group"] == 3;
    });
  }

  openBusinessCalendar() {
    this.dialogService.open(BusinessCalendarComponent, {
      data: {},
      disableClose: true,
    });
  }

  getData() {
    const { month, year } = this.owDate;
    this.setDateAndPagination({ month, year });

    const requestData: GetBusinessTaskRequest = {
      month,
      year,
    };

    if (!this.playerService.player.gui_unlocks["dashboard_under_construction"]) {
      this.getTasks(requestData);
    }
  }

  getTasks(requestData: GetBusinessTaskRequest) {
    this.apiBusinessService.tasks(requestData).subscribe(data => {
      this.tasks = data.tasks;
      this.tasksSourceDataDate = data.tasks_source_data_date;
      this.cardsTasks = [];
      this.prepareTasks(this.tasks);
    });
  }

  clearOwDate() {
    this.owDate = new OwDate();
  }

  next() {
    this.owDate.add({ month: 1 });
    this.getData();
  }

  prev() {
    this.owDate.subtract({ month: 1 });
    this.getData();
  }

  setDateAndPagination({ year, month }) {
    this.owDate = new OwDate({ year, month });

    this.owPaginationDate = new OwPaginationDate({
      current: this.owDate,
    });
  }

  openBasicActions() {
    if (this.playerService.player.gui_unlocks["basic_actions"]) {
      this.dialogService.open(BasicActionsComponent);
    }
  }

  openRanks() {
    if (this.playerService.player.gui_unlocks["rankings"]) {
      this.dialogService.open(RanksComponent, {
        data: {
          owDate: R.clone(this.owDate),
          tabIndex: RANKS_NAME.BUSINESS,
        },
      });
    }
  }

  openEvents() {
    if (this.player.gui_unlocks["business_events"]) {
      this.dialogService.open(EventBusinessListComponent, {
        data: {
          isOpenedFromDashboard: true,
        },
      });
    }
  }

  prepareTasks(tasks: Task[], options: { isChildrenTask?: boolean } = {}) {
    tasks.forEach((task: Task) => {
      if (!options.isChildrenTask) {
        task.showChoosable = !task.parent_id;
        if (!task.parent_id) {
          this.cardsTasks.push(task);
        }
      }

      let childList = null;
      tasks.forEach(child_task => {
        if (child_task.parent_id == task.id) {
          if (childList) {
            childList.nextTask = child_task;
          } else {
            task.nextTask = child_task;
          }
          childList = child_task;
        }
      });

      if (childList) {
        childList.nextTask = task;
        childList.hideOnList = true;
      }

      task.keyPercentage = "3";

      this.setComboTask(task);
      this.setClassName(task);
      this.setPrizesOrder(task);
      this.checkSomeTaskCleared();
    });
  }

  setClassName(task: Task) {
    switch (task.card_template_id) {
      default:
        task.className = null;
    }
  }

  openCardTaskDetails(task) {
    this.dialogService.open(CardTaskDetailsCCComponent, {
      data: {
        task,
        tasks: this.cardsTasks,
        allTasks: this.tasks,
        owDate: this.owDate,
      },
    });
  }

  openMicroLeague() {
    if (this.playerService.player.gui_unlocks["micro_league_cc"]) {
      this.dialogService.open(MicroLeagueTabsComponent);
    }
  }

  setPrizesOrder(task) {
    const scopes = task.scopes;
    Object.keys(scopes).forEach(el => {
      let prizes = scopes[el].prizes;

      prizes = setOrderWithCurrency(prizes);
      prizes = setOrderWithType(prizes);

      scopes[el].prizes = prizes;
    });

    if (task.prizes && task.prizes.length) {
      task.prizes = setOrderWithCurrency(task.prizes);
      task.prizes = setOrderWithType(task.prizes);
    }

    if (task.rewards) {
      if (task.rewards.cards && task.rewards.cards.length) {
        task.rewards.cards = setOrderWithCurrency(task.rewards.cards);
        task.rewards.cards = setOrderWithType(task.rewards.cards);
      }

      if (task.rewards.minimums && task.rewards.minimums.length) {
        task.rewards.minimums = setOrderWithCurrency(task.rewards.minimums);
        task.rewards.minimums = setOrderWithType(task.rewards.minimums);
      }

      if (task.rewards.scopes && task.rewards.scopes.length) {
        task.rewards.scopes = setOrderWithCurrency(task.rewards.scopes);
        task.rewards.scopes = setOrderWithType(task.rewards.scopes);
      }
    }
  }

  checkSomeTaskCleared() {
    this.isSomeCardCleared = this.tasks.some(t => t.is_cleared);
  }

  openQualityTasks() {
    if (this.player.gui_unlocks["dashboard_quality"]) {
      this.dialogService.open(QualityTasksCCComponent);
    }
  }

  setComboTask(task: Task) {
    if (task["child_tasks"] && task["child_tasks"].length) {
      this.prepareTasks(task["child_tasks"], { isChildrenTask: true });
    }
  }

  clearNotification() {
    if (this.playerService.player["gui_notifications"]?.["import_calculate_tasks"] > 0) {
      this.notificationsService.setNotificationRead("import_calculate_tasks").subscribe();
    }
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
    this.clearNotification();
  }
}
