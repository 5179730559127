import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { select } from "@ngrx/store";
import * as R from "ramda";
import { map } from "rxjs/operators";

import { GAME_CONFIG } from "../../../../../../../core/config/custom/_parsed-game.config";
import { PLAYER_TITLES } from "../../../../../../../core/consts/core/player-titles";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { PlayerSelectors } from "../../../../../../../store/player";

import { UserService } from "../../../../../../user/providers/user.service";
import { RANKS_NAME } from "../../../../../constants/custom/rank.const";
import { MappedMenuButtonConfig, MenuButtonConfig } from "../../../../../interfaces/custom/menu-buttons.interface";
import { MenuButtonsConfigService } from "../../../../../services/custom/menu-buttons-config.service";
import { EVENT_DIALOGS_NAMES_AUCTIONS_CUSTOM } from "../../../../auctions/consts/custom/event-dialogs/event-names.const";
import { CurrencyExchangeComponent } from "../../../../currency-exchange/basic/custom/dialogs/currency-exchange/currency-exchange.component";
import { EVENT_DIALOGS_NAMES_EVENT_SHOP } from "../../../../event-shop/consts/custom/event-dialogs/event-names.const";
import { EVENT_DIALOGS_NAMES_MISSION } from "../../../../mission/consts/core/event-dialogs/event-names";
import { EVENT_DIALOGS_NAMES_QA_CUSTOM } from "../../../../qa/consts/custom/event-dialogs/event-names.const";
import { DashboardComponent } from "../../../../san-business/custom/dialogs/dashboard/dashboard.component";
import { LeagueAchievementsComponent } from "../../../../san-business/custom/dialogs/league-achievements/league-achievements.component";
import { RanksComponent } from "../../../../san-ranks/basic/custom/dialogs/ranks/ranks.component";
import { AbstractHudMenuComponent } from "../../../abstract/core/abstract-hud-menu.component";
import { BranchPreviewComponent } from "../../dialogs/branch-preview/branch-preview.component";
import { BranchesComponent } from "../../dialogs/branches/branches.component";
import { EdenredCardListComponent } from "../../dialogs/edenred-card-list/edenred-card-list.component";
import { EventBusinessListComponent } from "../../dialogs/event-business-list/event-business-list.component";
import { HudMenuMoreComponent } from "../../dialogs/hud-menu-more/hud-menu-more.component";
import { MediaComponent } from "../../dialogs/media/media.component";
import { ChangeMapService } from "../../services/change-map.service";
import {MinigamesComponent} from '../../../../../../minigames/components/minigames/minigames.component';

@Component({
  selector: "app-custom-hud-menu",
  templateUrl: "./hud-menu.component.html",
})
export class HudMenuComponent extends AbstractHudMenuComponent implements OnInit {
  @OwInject(MenuButtonsConfigService)
  menuButtonsConfigService: MenuButtonsConfigService;
  @OwInject(ChangeMapService) changeMapService: ChangeMapService;
  @OwInject(Router) router: Router;
  @OwInject(UserService) userService: UserService;
  // @OwInject(BusinessDateService) businessDateService: BusinessDateService;
  @Input() isDialog: boolean;

  menuButtons: MappedMenuButtonConfig[] = [];
  snapShotButtons: MappedMenuButtonConfig[] = [];
  otherButtonsIsActive = false;
  groups = [];
  isDor: boolean;
  isCC = GAME_CONFIG.IS_CC;

  subs = {
    scene: null,
    player: null,
    globalEmitter: null,
  };

  ngOnInit() {
    this.subscribePlayer();
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(PlayerSelectors.selectPlayer)).subscribe(player => {
      this.isDor = player.title_id.id >= PLAYER_TITLES.DOR.id && player.title_id.id <= PLAYER_TITLES.PARTNER.id;
      this.afterSubscribePlayer();
    });
  }

  setMenu() {
    let buttons = this.menuButtonsConfigService.getButtonsMenuConfig({
      gui_unlocks: this.playerService.player.gui_unlocks,
      place: this.getMenuPlace(),
      isDialog: this.isDialog,
    });

    buttons = buttons.filter(button => {
      return button.onlyIsActiveMe ? this.playerService.isActiveMe : true;
    });

    if (R.equals(this.snapShotButtons, buttons)) {
      return;
    }

    this.snapShotButtons = buttons;
    this.menuButtons = R.clone(this.snapShotButtons);
    this.setNotification();
    this.otherButtonsIsActive =
      ((this.isCC && this.playerService.player.title_id.playable) || (!this.isCC && !this.isDor)) &&
      this.menuButtons.length > 6 &&
      !this.isDialog;
  }

  getMenuPlace() {
    let menuPlace = "menu-top";

    if (GAME_CONFIG.IS_CC) {
      if (!this.playerService.player.title_id.playable) {
        menuPlace = "menu-cc-manager";
      }
    } else {
      switch (this.playerService.player.title_id.id) {
        case PLAYER_TITLES.PARTNER.id:
          menuPlace = "menu-partner";
          break;

        case PLAYER_TITLES.DOR.id:
        case PLAYER_TITLES.DPP.id:
          menuPlace = "menu-dor";
          break;
      }
    }

    return menuPlace;
  }

  setNotification() {
    this.menuButtons.forEach(button => {
      switch (button.name) {
        case "missions":
          button["customNotification"] = {
            type: "async",
            observable: this.hasMissionsToCollect,
          };
          break;
        case "messages":
          button["customNotification"] = {
            type: "async",
            observable: this.hasNewMessagesToRead,
          };
          break;

        case "warehouse":
          button["customNotification"] = {
            type: "async",
            observable: this.newProductsInStorage.pipe(map(arr => arr.length)),
          };
          break;
      }
    });
  }

  afterSubscribePlayer() {
    this.setMenu();
  }

  handleMenuButtonEvent(button: MenuButtonConfig, event) {
    if ((this.isDialog && this.playerService.player.gui_unlocks[button.gui_unlocks_button]) || !this.isDialog) {
      if (button.type === "group") {
        this.toggleGroupButtons(button);
      } else if (button.type === "button") {
        switch (button.click_event) {
          case "missions":
            this.openMissions();
            break;

          case "messages":
            this.openMessages();
            break;

          case "warehouse":
            this.openWarehouse();
            break;

          case "minigames":
            this.openMinigames();
            break;

          case "league":
            this.openLeague();
            break;

          case "business_events":
            this.openBusinessEvents();
            break;

          case "league_achievements":
            this.openLeagueAchievements();
            break;

          case "rankings":
            this.openRankings();
            break;

          case "branches":
            this.openBranches();
            break;

          case "edenred":
            this.openEdenred();
            break;

          case "event_shops":
            this.openShops();
            break;

          case "library":
            this.openLibrary();
            break;

          case "qa":
            this.openQaList();
            break;

          case "qa-dor":
            this.openQaList();
            break;

          case "photobooth":
            this.openPhotoGallery();
            break;

          case "branch_preview":
            this.openBranchPreview();
            break;

          case "world":
            this.goToWorld();
            break;

          case "camp":
            this.goToCamp();
            break;

          case "team":
            this.goToTeam();
            break;

          case "skill_tree":
            this.goToSkillTree()
            break;

          case "climbing_1":
            this.changeMapService.goToClimbingMap('climbing_1');
            break;

          case "climbing_2":
            this.changeMapService.goToClimbingMap('climbing_2');
            break;

          case "climbing_3":
            this.changeMapService.goToClimbingMap('climbing_3');
            break;

          case "climbing_4":
            this.changeMapService.goToClimbingMap('climbing_4');
            break;

          case "climbing_5":
            this.changeMapService.goToClimbingMap('climbing_5');
            break;

          case "climbing_6":
            this.changeMapService.goToClimbingMap('climbing_6');
            break;

          case "market":
            this.openShopTao();
            break;

          case "event_shop_4":
            this.openEventShop(4);
            break;

          // case "animal_gallery":
          //   this.openAnimalGallery();
          //   break;

          case "event_shop_6":
            this.openEventShop(6);
            break;

          case "auctions":
            this.openAuctions();
            break;

          case "currency_exchange":
            this.openCurrencyExchange();
            break;
        }
      }
    } else {
      event.stopPropagation();
    }
  }

  openAuctions() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_AUCTIONS_CUSTOM.AUCTIONS,
    });
  }

  openMinigames() {
    this.dialogService.open(MinigamesComponent);
  }

  openBusinessEvents() {
    this.dialogService.open(EventBusinessListComponent);
  }

  // openAnimalGallery() {
  //   this.dialogService.open(AnimalListComponent);
  // }

  openLeague() {
    this.dialogService.open(DashboardComponent, {
      data: {
        openLeague: true,
      },
    });
  }

  openLeagueAchievements() {
    this.dialogService.open(LeagueAchievementsComponent, {
      data: {
        isOpenFromQuarter: true,
      },
    });
  }

  openRankings() {
    if (this.playerService.player.gui_unlocks["rankings"]) {
      this.dialogService.open(RanksComponent, {
        data: {
          tabIndex: RANKS_NAME.BUSINESS,
        },
      });
    } else {
      this.dialogService.openAlert({
        description: "Rankingi (dostępne wkrótce)",
      });
    }
  }

  openShops() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_EVENT_SHOP.LIST,
    });
  }

  toggleGroupButtons(button: MappedMenuButtonConfig) {
    button.group_buttons?.forEach(groupBtn => (groupBtn.isVisible = !groupBtn.isVisible));
  }

  openBranches() {
    this.dialogService.open(BranchesComponent);
  }

  openQaList() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA_CUSTOM.QA_LIST_VIEW,
      config: {
        data: {
          location: 1,
        },
      },
    });
  }

  openPhotoGallery() {
    this.router.navigate(["/auth/gallery"]);
  }

  openEdenred() {
    this.dialogService.open(EdenredCardListComponent);
  }

  // goToGarage() {
  //   this.changeMapService.goToGarage();
  // }

  goToWorld() {
    this.changeMapService.goToWorld();
  }

  goToCamp() {
    this.changeMapService.goToCamp();
  }

  goToTeam() {
    this.changeMapService.goToTeam();
  }

  goToSkillTree() {
    this.changeMapService.goToSkillTree();
  }

  openBranchPreview() {
    this.dialogService.open(BranchPreviewComponent);
  }

  openLibrary() {
    this.dialogService.open(MediaComponent);
  }

  openCurrencyExchange() {
    this.dialogService.open(CurrencyExchangeComponent);
  }

  openHudMenuMore() {
    this.dialogService.open(HudMenuMoreComponent);
  }

  openEventShop(eventShopId: number) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_EVENT_SHOP.LIST,
      config: {
        data: {
          eventShopId,
        },
      },
    });
  }

  openShopTao() {
    if (this.playerService.player.gui_unlocks["market"]) {
      this.router.navigate(["/auth/shop"]);
    } else {
      this.dialogService.openAlert({
        description: "San Tao Market (dostępne wkrótce)",
      });
    }
  }

  openMissions() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MISSION.MISSIONS,
      config: {
        disableClose: true,
      },
    });
  }
}
