<div class="global-dialog ow-dialog c-window-long">
  <loading></loading>

  <div class="content" *ngIf="auction">
    <!-- PHONE -->
    <form *ngIf="form" [formGroup]="form">
      <ng-container *ngIf="form.get('phone') as phone">
        <div class="control" [class.error]="getErrors(phone)">
          <div class="label-flex">
            <label class="sv-1">Numer telefonu</label>
          </div>
          <input class="control-input" formControlName="phone" type="text"/>
          <div class="alert alert-danger" *ngIf="getErrors(phone)">
            <span *ngIf="phone.errors.required">Pole wymagane</span>
            <span *ngIf="phone.errors.phoneNumberError">Numer telefonu powinien składać się z 9 cyfr</span>
          </div>
        </div>
      </ng-container>
    </form>

    <div class="sv-1 number">
      Numer telefonu potrzebny będzie do kontaktu <br> z kurierem w przypadku dostarczenia przedmiotu z wygranej aukcji.
    </div>

    <m-ui-separator></m-ui-separator>

    <div class="bottom">
      <div class="item">
        Aktualna cena:
        <m-ui-currency
          [item]="{ currency_id: auction.currency_id, amount: (!auction.highest_bid || (auction.highest_bid.amount < auction.min_bid) ? auction.min_bid : auction.highest_bid?.amount) }"
          [stockView]="STOCK_VIEW.CUSTOM"
          [customIconSize]="'big'"
          [customClassStock]="'stock-san-a'"
          [lack]="false"
        ></m-ui-currency>
      </div>
      <div class="item no-border no-padding">
        Twoja oferta:
        <m-ui-currency
          [item]="{ currency_id: auction.currency_id, amount: playerBid || auction.min_bid }"
          [stockView]="STOCK_VIEW.CUSTOM"
          [customIconSize]="'big'"
          [customClassStock]="'stock-san-a'"
          [lack]="false"
        ></m-ui-currency>
      </div>
    </div>

    <div class="buttons">
      <button class="c-base c-primary" (click)="back()">Anuluj</button>
      <button class="c-base c-secondary" [disabled]="form?.invalid || playerBid < auction.min_bid" (click)="bid()">Licytuj</button>
    </div>

    <div class="sv-1">
      Złożenie oferty oznacza zawarcie wiążącej umowy.  <br> W przypadku wygranej
      zobowiązuje się do zakupu przedmiotu zgodnie z zasadami opisanymi w sekcji „Info”.
    </div>
  </div>

  <div class="auction-id">id aukcji: {{ auction.id }}</div>
</div>
