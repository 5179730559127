<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Wiadomości</span>
      </div>
    </div>
  </div>

  <!-- DIALOG EXIT -->
  <m-ui-close-button
    *ngIf="isImpersonate || !message['must_confirm_read'] || message?.read"
    [preventDefault]="true"
    (clicked)="closeOrCloseAll()"
  ></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button
    [preventDefault]="true"
    (clicked)="close()"
    *ngIf="messageOpenDetailsData.fromList"
  ></m-ui-back-button>

  <div class="frame loading-opacity" *ngIf="message">
    <ng-container *ngIf="!alternativeView">
      <div class="img">
        <img [src]="message.sender_icon + '.png' | asset:'senders/big'"/>
      </div>

      <!-- MESSAGE default -->
      <div class="frame-white" *ngIf="!alternativeView">

        <!-- SENDER AND DATE -->
        <div class="sender-and-date">
          <!-- SENDER -->
          <span class="senderdate">
          {{ message.sender_name }}
        </span>

          <div class="right">
            <!-- DATE -->
            <span class="date">
          {{ message.created_at | date:"y-MM-dd HH:mm" }}
        </span>
            <!-- MESSAGE TRASH -->
            <button
              class="c-square small"
              *ngIf="!message.deleted && message.deletable && (!message['must_confirm_read'] || message.read)"
              matTooltip="Przenieś do archiwum"
            >
              <i
                class="fas fa-archive trash-icon"
                (click)="moveToTrash()"
              ></i>
            </button>
          </div>
        </div>

        <!-- MESSAGE TITLE -->
        <p class="message-title">
          {{ message.title }}
        </p>

        <!-- MESSAGE DESCRIPTION -->
        <ow-perfect-scrollbar scrollGradient [ngClass]="{'short': message.parameters.currencies?.length || message.parameters.products?.length}">
          <div class="description" [innerHTML]="message.description | resourceFromString: 'small' | downloadToken | safe:'html'"></div>
        </ow-perfect-scrollbar>

        <!-- PRIZES -->
        <ng-container *ngIf="message.parameters.currencies?.length || message.parameters.products?.length">
          <div class="parameters">
            <!-- CURRENCIES -->
            <m-ui-currency
              *ngFor="let currency of message.parameters.currencies"
              [item]="currency"
              [stockView]="STOCK_VIEW.CUSTOM"
              [customClassStock]="'stock-san-a'"
              [lack]="false"></m-ui-currency>

            <!-- PRODUCTS -->
            <m-ui-product
              *ngFor="let product of message.parameters.products"
              [item]="product"
              [stockView]="STOCK_VIEW.CUSTOM"
              [customClassStock]="'stock-san-a'"
              [lack]="false"></m-ui-product>
          </div>
        </ng-container>

        <div
          *ngIf="message['must_confirm_read']"
          class="accept-reading"
          (click)="setRead()"
          [class.box-active]="message.read"
        >
          <div
            class="box"
          ></div>
          Potwierdzam, że znam i rozumiem treść powyższej wiadomości.
        </div>
      </div>
    </ng-container>

    <!-- Message alternative -->
    <div class="frame-white alternative" *ngIf="alternativeView">

      <!-- MESSAGE TITLE -->
      <p class="message-title">
        {{ message.title }}
      </p>

      <!-- MESSAGE DESCRIPTION -->
      <div class="description" [innerHTML]="message.description | resourceFromString: 'small' | downloadToken | safe:'html'"></div>

      <!-- PRIZES -->
      <ng-container *ngIf="message.parameters.currencies?.length || message.parameters.products?.length">
        <div class="parameters">
          <!-- CURRENCIES -->
          <m-ui-currency
            *ngFor="let currency of message.parameters.currencies"
            [item]="currency"
            [stockView]="STOCK_VIEW.CUSTOM"
            [customClassStock]="'stock-san-a'"
            [lack]="false"></m-ui-currency>

          <!-- PRODUCTS -->
          <m-ui-product
            *ngFor="let product of message.parameters.products"
            [item]="product"
            [stockView]="STOCK_VIEW.CUSTOM"
            [customClassStock]="'stock-san-a'"
            [lack]="false"></m-ui-product>
        </div>
      </ng-container>
    </div>
  </div>

  <!--BOTTOM BAR-->
  <div class="bottom-bar" *ngIf="message.qa_info">
    <div
      class="bar-button base"
      [matTooltip]="'messages.message-details-normal.qa.button-open-tooltip-disabled' | myTranslate"
      matTooltipPosition="above"
      matTooltipShowDelay="300"
      [matTooltipDisabled]="message.qa_info.qa_published"
    >

      <!-- QA -->
      <button
        (click)="openQaList()"
        class="c-base c-primary"
        [disabled]="!message.qa_info.qa_published"
      >
        Odpowiedz
      </button>
    </div>
  </div>
</div>
