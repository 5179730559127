import { Component, OnDestroy, OnInit } from '@angular/core';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';
import { AbstractWarehouseDetailsComponent } from '../../../../abstract/core/abstract-warehouse-details.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { PlayerService } from '../../../../../../../player/providers/player.service';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-warehouse-details',
  templateUrl: './warehouse-details.component.html',
})
export class WarehouseDetailsComponent extends AbstractWarehouseDetailsComponent implements OnInit, OnDestroy {
  @OwInject(PlayerService) playerService: PlayerService;
  playerStar = this.playerService.player.star;

  ngOnInit() {
    this.subscribeDefaultTradingCurrencyId();
    this.parseProduct();
    this.getProductDetails();
    this.subscribePlayer();
  }

  transaction(action: string, amount: number) {
    const isRequiredStar = this.checkedRequiredStar(action);

    if (isRequiredStar) {
      super.transaction(action, amount);
    }
  }

  checkedRequiredStar(action) {
    const productBuyableStar = this.product['buyable_from_star'];
    const productSellableStar = this.product['sellable_from_star'];

    switch (action) {
      case this.TRANSACTION.BUY:
        if (productBuyableStar && this.playerStar.star_id < productBuyableStar.star_id) {
          this.openRequiredStarAlert({action: 'kupić', starName: productBuyableStar.star_name});
          return false;
        } else {
          return true;
        }
      case this.TRANSACTION.SELL:
        if (productSellableStar && this.playerStar.star_id < productSellableStar.star_id) {
          this.openRequiredStarAlert({action: 'sprzedać', starName: productSellableStar.star_name});
          return false;
        } else {
          return true;
        }
    }
  }

  openRequiredStarAlert({action, starName}) {
    this.dialogService.openAlert({
      description: 'Nie można ' + action + ' produktu, wymagana ' + starName.toLowerCase(),
    });
  }

  createFormBuy() {
    this.transactionBuyForm = this.fb.group({
      amount: [null, [
        Validators.required,
        Validators.min(1),
        Validators.pattern('^[0-9]*$'),
        Validators.max(this.product['storage_capacity_max'] - this.product['storage_capacity_usage']),
      ]],
      haveAmountSum: [null, [Validators.requiredTrue]]
    });

    this.subscribeAmountChange(this.transactionBuyForm);
  }

  createFormSell() {
    this.transactionSellForm = this.fb.group({
      amount: [null, [
        Validators.required,
        Validators.min(1),
        Validators.pattern('^[0-9]*$'),
        Validators.max(this.product['storage_capacity_usage']),
      ]],
      haveAmountSum: [null, [Validators.requiredTrue]]
    });

    this.subscribeAmountChange(this.transactionSellForm);
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
