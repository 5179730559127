import * as R from 'ramda';

export function setOrderWithCurrency(currencies) {
  const prizesSorted = R.sortWith([
    // TODO check why false-positive tslint error
    // @ts-ignore
    R.ascend(R.prop('currency_id')),
  ]);

  return prizesSorted(currencies);
}

export function setOrderWithProduct(products) {
  const prizesSorted = R.sortWith([
    // TODO check why false-positive tslint error
    // @ts-ignore
    R.ascend(R.prop('product_id')),
  ]);

  return prizesSorted(products);
}

export function setOrderWithType(products) {
  const prizesSorted = R.sortWith([
    // TODO check why false-positive tslint error
    // @ts-ignore
    R.ascend(R.prop('type')),
  ]);

  return prizesSorted(products);
}
