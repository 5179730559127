<div class="global-dialog ow-dialog c-window-long">

  <loading></loading>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Eventy biznesowe</span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="businessEvents?.length > 0; else noItemTpl">
    <ow-perfect-scrollbar [scrollGradient]="businessEvents">
      <div class="event-business-list">
        <div class="event-business-card" [class.deactived]="isShopEventDeactivate(eventBusiness)"
             *ngFor="let eventBusiness of businessEvents">
          <div class="card-title">{{eventBusiness.name}}</div>
          <div class="card-description">
            <img class="description-image" [src]="eventBusiness.image_url">
            <div class="description-separator"></div>
            <div class="description-text">{{eventBusiness.description}}</div>
            <div class="description-separator"></div>
            <div class="right">
              <div class="right-info">
                <div
                  class="description-date"
                  matTooltip="Czas trwania eventu"
                  matTooltipPosition="above"
                >
                  <img [src]="'clock_30.png' | asset: 'ui'" class="date-icon">
                  <span>{{eventBusiness.start_at}} - {{eventBusiness.end_at}}</span>
                </div>
                <div
                  *ngIf="!eventBusiness.hide_rank"
                  [matTooltip]="'Ranking'"
                  matTooltipPosition="above"
                >
                  <ng-container
                    *ngIf="eventBusiness.rank_result_file_url; else openRankTmpl"
                  >
                    <a
                      [href]="eventBusiness.rank_result_file_url"
                      target="_blank"
                    >
                      <button class="c-base c-primary">
                        <i class="fas fa-chart-line"></i>
                      </button>
                    </a>
                  </ng-container>

                  <ng-template #openRankTmpl>
                    <button
                      class="c-base c-primary"
                      (click)="openRankDetails(eventBusiness)">
                      <i class="fas fa-chart-line"></i>
                    </button>
                  </ng-template>
                </div>
                <div
                  [matTooltip]="'Zasady'"
                  matTooltipPosition="above"
                >
                  <a
                    [href]="eventBusiness.terms_url"
                    [class.pointer-events-none]="!eventBusiness.terms_url"
                    target="_blank"
                  >
                    <button [disabled]="!eventBusiness.terms_url" class="c-base c-primary">
                      <i class="fas fa-info"></i>
                    </button>
                  </a>
                </div>
              </div>

              <div class="right-info" *ngIf="eventBusiness.event_shop">
                <div class="description-date">
                  <img
                    *ngIf="!eventBusiness.is_repeatable"
                    matTooltip="Ten kolor żetonów wykorzystasz tylko w podanym terminie"
                    matTooltipPosition="above"
                    [src]="'exclamation.png' | asset: 'ui'"
                    class="date-icon date-notification"
                  >
                  <img [src]="'shop.png' | asset: 'ui'" class="date-icon">
                  <span matTooltip="Czas na wymianę żetonów" matTooltipPosition="above">
                    {{eventBusiness.event_shop.open_start}} - {{eventBusiness.event_shop.open_end}}
                  </span>
                </div>
                <div
                  [matTooltip]="eventBusiness.is_active === false ? 'Sklep dostępny wkrótce' : this.isShopLocked || !playerService.isActiveMe ? shopLockedMessage : 'Sklep żetonowy'"
                  matTooltipPosition="above"
                >
                  <button
                    class="c-base c-primary"
                    [disabled]="eventBusiness.is_active === false || this.isShopLocked || !playerService.isActiveMe"
                    (click)="openShop(eventBusiness.event_shop.event_shop_id)"
                  >
                    <i class="fas fa-shopping-cart"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="eventBusiness.notification"
            class="app-notification"
          ></div>
        </div>
      </div>
    </ow-perfect-scrollbar>
  </ng-container>

  <ng-template #noItemTpl>
    <div class="no-item">
      Brak danych
    </div>
  </ng-template>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button *ngIf="data.isOpenedFromDashboard"></m-ui-back-button>
  <div *ngIf="branchName" class="branch-name">{{ branchName }}</div>
</div>
