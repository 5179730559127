import { MenuButtonConfig } from "../../interfaces/custom/menu-buttons.interface";

export const CUSTOM_MENU_BUTTONS_CONFIG: MenuButtonConfig[] = [
  {
    type: "button",
    place: "menu-top",
    order: 1,
    name: "missions",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "missions",
    tooltip: "Misje",
    icon: "mission",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "group",
    place: "menu-top",
    order: 2,
    name: "go-to",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: null,
    tooltip: "Idź do",
    icon: "navigation",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-top",
    order: 2,
    name: "camp",
    gui_notification_type: null,
    gui_unlocks_button: "camp",
    click_event: "camp",
    tooltip: "Górska osada",
    icon: "city",
    show_only_active_me: true,
    group_name: "go-to",
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-top",
    order: 2,
    name: "world",
    gui_notification_type: null,
    gui_unlocks_button: "worldmap",
    click_event: "world",
    tooltip: "Mapa Azji",
    icon: "world",
    show_only_active_me: true,
    group_name: "go-to",
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-top",
    order: 2,
    name: "team",
    gui_notification_type: null,
    gui_unlocks_button: "team",
    click_event: "team",
    tooltip: "Team",
    icon: "team",
    show_only_active_me: true,
    group_name: "go-to",
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-top",
    order: 2,
    name: "skill_tree",
    gui_notification_type: null,
    gui_unlocks_button: "skills",
    click_event: "skill_tree",
    tooltip: "Drzewko umiejętności",
    icon: "technology_tree",
    show_only_active_me: true,
    group_name: "go-to",
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-top",
    order: 3,
    name: "messages",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "messages",
    tooltip: "Wiadomości",
    icon: "messages",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-top",
    order: 4,
    name: "warehouse",
    gui_notification_type: null,
    gui_unlocks_button: "warehouse",
    click_event: "warehouse",
    tooltip: "Magazyn",
    icon: "warehouse",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-top",
    order: 5,
    name: "minigames",
    gui_notification_type: null,
    gui_unlocks_button: "minigames",
    click_event: "minigames",
    tooltip: "Minigry",
    icon: "game",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-top",
    order: 8,
    name: "league",
    gui_notification_type: null,
    gui_unlocks_button: "league",
    // gui_unlocks_blocked_tooltip: true,
    click_event: "league",
    tooltip: "Liga sprzedaży",
    icon: "league",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-top",
    order: 9,
    name: "business_events",
    gui_notification_type: null,
    gui_unlocks_button: "business_events",
    // gui_unlocks_blocked_tooltip: true,
    click_event: "business_events",
    tooltip: "Eventy biznesowe",
    icon: "events",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-top",
    order: 10,
    name: "league_achievements",
    gui_notification_type: null,
    gui_unlocks_button: "league_achievements",
    // gui_unlocks_blocked_tooltip: true,
    click_event: "league_achievements",
    tooltip: "Osiągnięcia Ligowe",
    icon: "awards",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-top",
    order: 11,
    name: "rankings",
    gui_notification_type: null,
    gui_unlocks_button: "rankings",
    // gui_unlocks_blocked_tooltip: true,
    click_event: "rankings",
    tooltip: "Rankingi",
    icon: "rankings",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-top",
    order: 12,
    name: "branch_preview",
    gui_notification_type: null,
    gui_unlocks_button: "branch_preview",
    // gui_unlocks_blocked_tooltip: true,
    click_event: "branch_preview",
    tooltip: "Podgląd oddziału",
    tooltip_CC: "Podgląd graczy",
    icon: "preview",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-top",
    order: 13,
    name: "edenred",
    gui_notification_type: null,
    gui_unlocks_button: "edenred",
    // gui_unlocks_blocked_tooltip: true,
    click_event: "edenred",
    tooltip: "Twoja karta przedpłacona",
    icon: "prepaid",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-top",
    order: 14,
    name: "shop_event",
    gui_notification_type: null,
    gui_unlocks_button: "event_shops",
    // gui_unlocks_blocked_tooltip: true,
    click_event: "event_shops",
    tooltip: "Sklepy",
    icon: "shop",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-top",
    order: 15,
    name: "library",
    gui_notification_type: null,
    gui_unlocks_button: "library",
    // gui_unlocks_blocked_tooltip: true,
    click_event: "library",
    tooltip: "Biblioteka",
    icon: "library",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-top",
    order: 16,
    name: "qa",
    gui_notification_type: null,
    gui_unlocks_button: "qa",
    // gui_unlocks_blocked_tooltip: true,
    click_event: "qa",
    tooltip: "Quizy i Ankiety",
    icon: "qna",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-top",
    order: 17,
    name: "photobooth",
    gui_notification_type: null,
    gui_unlocks_button: "photobooth",
    // gui_unlocks_blocked_tooltip: true,
    click_event: "photobooth",
    tooltip: "Fotowrzutka",
    icon: "photobooth",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-top",
    order: 18,
    name: "auctions",
    gui_notification_type: null,
    gui_unlocks_button: "auctions",
    // gui_unlocks_blocked_tooltip: null,
    click_event: "auctions",
    tooltip: "Aukcje",
    icon: "auctions",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-top",
    order: 19,
    name: "currency_exchange",
    gui_notification_type: null,
    gui_unlocks_button: "currency_exchange",
    // gui_unlocks_blocked_tooltip: null,
    click_event: "currency_exchange",
    tooltip: "Kantor",
    icon: "change",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },

  // DOR
  {
    type: "button",
    place: "menu-dor",
    order: 1,
    name: "messages",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "messages",
    tooltip: "Wiadomości",
    icon: "messages",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-dor",
    order: 2,
    name: "prewiev_dor",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "branches",
    tooltip: "Podgląd graczy",
    icon: "preview",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-dor",
    order: 3,
    name: "library_dor",
    gui_notification_type: null,
    gui_unlocks_button: "library",
    click_event: "library",
    tooltip: "Biblioteka",
    icon: "library",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-dor",
    order: 4,
    name: "shop_dor",
    gui_notification_type: null,
    gui_unlocks_button: "market",
    click_event: "market",
    tooltip: "San Tao Market",
    icon: "shop",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-dor",
    order: 5,
    name: "photobooth",
    gui_notification_type: null,
    gui_unlocks_button: "photobooth",
    click_event: "photobooth",
    tooltip: "Fotowrzutka",
    icon: "photobooth",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-dor",
    order: 6,
    name: "quest_dor",
    gui_notification_type: null,
    gui_unlocks_button: "qa",
    click_event: "qa-dor",
    tooltip: "Quizy i Ankiety",
    icon: "qna",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-dor",
    order: 7,
    name: "prepaid",
    gui_notification_type: null,
    gui_unlocks_button: "edenred",
    click_event: "edenred",
    tooltip: "Karty przedpłacone",
    icon: "prepaid",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-dor",
    order: 8,
    name: "rankings",
    gui_notification_type: null,
    gui_unlocks_button: "rankings",
    click_event: "rankings",
    tooltip: "Rankingi",
    icon: "rankings",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-dor",
    order: 9,
    name: "event_shop_4",
    gui_notification_type: null,
    gui_unlocks_button: "menu_event_shop_4",
    click_event: "event_shop_4",
    tooltip: "Sklep gadżetowy",
    icon: "chip_shop",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-dor",
    order: 10,
    name: "event_shop_6",
    gui_notification_type: null,
    gui_unlocks_button: "menu_event_shop_6",
    click_event: "event_shop_6",
    tooltip: "Sklep ligowy",
    icon: "league",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-dor",
    order: 16,
    name: "auctions",
    gui_notification_type: null,
    gui_unlocks_button: "auctions",
    // gui_unlocks_blocked_tooltip: null,
    click_event: "auctions",
    tooltip: "Aukcje",
    icon: "auctions",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },

  // PARTNER
  {
    type: "button",
    place: "menu-partner",
    order: 1,
    name: "messages",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "messages",
    tooltip: "Wiadomości",
    icon: "messages",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-partner",
    order: 2,
    name: "prewiev_dor",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "branches",
    tooltip: "Podgląd graczy",
    icon: "preview",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-partner",
    order: 3,
    name: "library_dor",
    gui_notification_type: null,
    gui_unlocks_button: "library",
    click_event: "library",
    tooltip: "Biblioteka",
    icon: "library",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-partner",
    order: 5,
    name: "photobooth",
    gui_notification_type: null,
    gui_unlocks_button: "photobooth",
    click_event: "photobooth",
    tooltip: "Fotowrzutka",
    icon: "photobooth",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-partner",
    order: 6,
    name: "rankings",
    gui_notification_type: null,
    gui_unlocks_button: "rankings",
    click_event: "rankings",
    tooltip: "Rankingi",
    icon: "rankings",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-partner",
    order: 16,
    name: "auctions",
    gui_notification_type: null,
    gui_unlocks_button: "auctions",
    // gui_unlocks_blocked_tooltip: null,
    click_event: "auctions",
    tooltip: "Aukcje",
    icon: "auctions",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },

  // CC MANAGER
  {
    type: "button",
    place: "menu-cc-manager",
    order: 1,
    name: "messages",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "messages",
    tooltip: "Wiadomości",
    icon: "messages",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-cc-manager",
    order: 2,
    name: "library_dor",
    gui_notification_type: null,
    gui_unlocks_button: "library",
    click_event: "library",
    tooltip: "Biblioteka",
    icon: "library",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-cc-manager",
    order: 3,
    name: "shop_dor",
    gui_notification_type: null,
    gui_unlocks_button: "market",
    click_event: "market",
    tooltip: "San Tao Market",
    icon: "shop",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-cc-manager",
    order: 4,
    name: "rankings",
    gui_notification_type: null,
    gui_unlocks_button: "rankings",
    click_event: "rankings",
    tooltip: "Rankingi",
    icon: "rankings",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-cc-manager",
    order: 16,
    name: "auctions",
    gui_notification_type: null,
    gui_unlocks_button: "auctions",
    // gui_unlocks_blocked_tooltip: null,
    click_event: "auctions",
    tooltip: "Aukcje",
    icon: "auctions",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
];

