<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Kantor</span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="allSpreads">
    <div class="container loading-opacity">
      <div class="exchange">
        <div class="exchange-from">
          <!-- TOOLTIP EXCHANGE FROM -->
          <div class="tooltip-exchange-from" *ngIf="activeTooltipCurrencyFrom">
            <m-ui-currency
              *ngFor="let currencyId of currenciesIdFrom"
              [item]="{currency_id: currencyId}"
              [stockView]="STOCK_VIEW.CUSTOM"
              [customClassStock]="'stock-layout-exchange'"
              [customIconSize]="'big'"
              [hideValue]="true"
              [observable]="true"
              [lack]="false"
              (click)="changeActiveCurrencyFrom(currencyId, $event)"
              class="ingredient"
            >
            </m-ui-currency>
          </div>
          <ng-container *ngIf="activeCurrencyFromId">
            <m-ui-currency
              [item]="{currency_id: activeCurrencyFromId}"
              [hideValue]="true"
              [stockView]="STOCK_VIEW.CUSTOM"
              [customClassStock]="'stock-layout-exchange'"
              [customIconSize]="'big'"
              [observable]="true"
              [lack]="false"
              (itemChanged)="setMax($event.balance)"
            ></m-ui-currency>
            <span class="spread" *ngIf="spread">
              {{ spread.spread < 100 ? (1 / spread.spread * 100) : 1 }}
            </span>
          </ng-container>
          <i *ngIf="!activeCurrencyFromId" class="fas fa-question"></i>
        </div>

        <img [src]="'arrow.png' | asset:'ui'">

        <div class="exchange-to">
          <!-- TOOLTIP EXCHANGE TO -->
          <div class="tooltip-exchange-to" *ngIf="activeTooltipSpreadTo">
            <m-ui-currency
              *ngFor="let spread of spreadsTo"
              [item]="{currency_id: spread.to.currency_id}"
              [stockView]="STOCK_VIEW.CUSTOM"
              [customClassStock]="'stock-layout-exchange'"
              [customIconSize]="'big'"
              [hideValue]="true"
              [observable]="true"
              [lack]="false"
              (click)="changeSpread(spread, $event)"
              class="ingredient"
            >
            </m-ui-currency>
          </div>
          <ng-container *ngIf="spread">
            <m-ui-currency
              [item]="{currency_id: spread.to.currency_id}"
              [hideValue]="true"
              [stockView]="STOCK_VIEW.CUSTOM"
              [customClassStock]="'stock-layout-exchange'"
              [customIconSize]="'big'"
              [lack]="false"
            ></m-ui-currency>
            <span class="spread">
               {{ spread.spread < 100 ? 1 : (spread.spread / 100) }}
            </span>
          </ng-container>
          <i *ngIf="!spread" class="fas fa-question"></i>
        </div>
      </div>


      <div class="form-container" *ngIf="form">
        <!-- FORM -->
        <form [formGroup]="form">
          <div class="default-field">
            <input
              type="number"
              min="0"
              max="999999999"
              formControlName="amount"
              placeholder="Wpisz ilość"
              [attr.disabled]="!spread ? true : null"
            />
          </div>
        </form>

        <!-- SELL ALL -->
        <div
          class="sell-all pointer"
          (click)="setAmount()"
        >
          Wymień wszystko
        </div>
      </div>

      <div class="flex-center">
        <!-- SUMMARY -->
        <p class="summary" *ngIf="!form.controls['amount'].hasError('error')">
          Otrzymasz: {{ !this.spread ? 0 : spread.spread * this.amount / 100 }}

          <m-ui-currency
            [item]="{currency_id: spread.to.currency_id}"
            [hideValue]="true"
            [stockView]="STOCK_VIEW.CUSTOM"
            [customClassStock]="'stock-layout-z'"
            [customIconSize]="'big'"
            [lack]="false"
          ></m-ui-currency>
        </p>

        <p class="summary" *ngIf="form.controls['amount'].hasError('error')">
          {{ form.controls['amount'].getError('error') }}
        </p>

        <button
          class="c-base c-primary"
          (click)="exchangeConfirm()"
          [disabled]="form.invalid"
        >
          Wymień
        </button>
      </div>
    </div>
  </ng-container>
</div>
