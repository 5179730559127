<div class="global-dialog ow-dialog wf-piece-pairing-container">

  <loading [customLogicShow]="isLoading"></loading>

  <m-ui-close-button [preventDefault]="true" (clicked)="closeActive(false)"></m-ui-close-button>

  <div class="content" [ngClass]="{'blocked': !this.pieceWithDetails}">
    <img (click)="openPieceDetails()" [src]="data.details?.player_building?.icon + '.png' | asset: 'buildings'"/>

    <button class="c-square small c-primary" [disabled]="!isValid" (click)="pairBuilding()">
      Paruj
    </button>
  </div>
</div>
