import { AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME } from "../../../../constants";
import { getBuildingAttribute } from "../../../../game-gui/helpers/buildings.helper";

export function getAutomaticProductHelper({ automatic_currency, automatic_product, attributes, daily_product_productions }) {
  let automaticCurrency, automaticProduct;

  if (automatic_currency) {
    const amount = getBuildingAttribute(AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME, attributes).value || 0;

    automaticCurrency = {
      currency_id: automatic_currency.currency_id,
      amount,
    };
  }

  if (automatic_product) {
    const dailyProduct = daily_product_productions.find(product => product.product_id === automatic_product.product_id);
    const amount = dailyProduct?.["amount"] || getBuildingAttribute(AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME, attributes).value || 0;

    automaticProduct = {
      product_id: automatic_product.product_id,
      amount,
    };
  }

  return {
    automaticCurrency,
    automaticProduct,
  };
}
