export const RANK_GAME_TYPE = {
  LEVEL: 1,
  SPARKLE: 999,
  POPULATION: 8,
  EXPLORED_ISLAND: 9,
  TREASURY: 10,
  PRESTIGE: 12,
  CHEST_FROM_WATER: 20,
  TOURIST_INCOME: 21,
  MUSEUM_WORTH: 22,
  TOTAL: 99,
  TOTAL_MONTH: 98,
  RANK_COFFEE: 102,
  RANK_DRINKS: 103,
  RANK_DESSERT: 104,
  RANK_MUSEUM: 101,
  RANK_BAR_PREVIOUS_DAY: 100,
  BAR_TOTAL: 110,
  TRIOX_MAIN: 200,
  TRIOX_MONTH_1: 201,
  TRIOX_MONTH_2: 202,
  TRIOX_MONTH_3: 203,
  TEST_RACE_1: 220,
  TEST_RACE_2: 221,
  TEST_RACE_3: 222,
  TEST_RACE_4: 223,
  RACE_1: 230,
  RACE_2: 231,
  RACE_3: 232,
  RACE_4: 233,
};
