import { CB_ATLAS_KEY, CB_BANANA_END_ANIMATION, CB_UI_KEY } from "../../catch-bananas.constans";
import { generateAnimationFramesList } from "../../catch-bananas.helpers";
import { CbAcraceSpriteConfig } from "../../interfaces/cb-arcade-sprite-config.interface";
import { CatchBananasPlayScene } from "../../scenes/CatchBananasPlay.scene";
import { AbstractArcadeSprite } from "./abstract-arcade-sprite";

export class ClockSprite extends AbstractArcadeSprite {
  velocity: number;
  scene: CatchBananasPlayScene;
  colliders: Phaser.Physics.Arcade.Collider[] = [];

  constructor(config: CbAcraceSpriteConfig) {
    super(config.scene, config.x, config.y, CB_UI_KEY, "clock.png");
    this.velocity = this.scene.catchBananasState.velocity;
    this.scene.add.existing(this);
    this.setDepth(3);
    this.createAnimations();
    this.setVelocityY(this.velocity);
  }

  destroyObject() {
    this.colliders.forEach(collider => collider.destroy());
    this.scene.physics.world.disable(this);
    this.scene.saveCurrentState();
    this.anims.play("clock_destroy");
    this.on("animationcomplete", () => {
      this.destroy();
    });
  }

  collectObject() {
    this.scene.add.tween({
      targets: this.scene.catchBananasState.timeLeftText,
      yoyo: true,
      duration: 200,
      y: -2,
      ease: Phaser.Math.Easing.Sine.InOut,
      onStart: tween => {
        (tween.targets[0] as Phaser.GameObjects.Text).setColor("#2a9a00");
      },
      onComplete: tween => {
        (tween.targets[0] as Phaser.GameObjects.Text).setColor("#FFF");
      },
    });
    this.scene.catchBananasState.annie.collectSoundEmitter.emit("clock");
    this.scene.catchBananasState.timeLeft += 5;
    this.scene.updateTimeText();
    super.collectObject();
  }

  createAnimations() {
    this.scene.anims.create({
      key: "clock_destroy",
      frames: generateAnimationFramesList(CB_ATLAS_KEY, "clock_destroy", 6),
      delay: 0,
      frameRate: 10,
      duration: CB_BANANA_END_ANIMATION,
      repeat: 0,
    });
  }
}
