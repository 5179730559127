import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../../core/providers/api.service";
import { PlayerService } from "../../../../../player/providers/player.service";
import { ApiOptions } from "../../../../../../core/interfaces/api-options";

@Injectable({
  providedIn: "root",
})
export class ApiDashboardService {
  constructor(
    public apiService: ApiService,
    private playerService: PlayerService
  ) {}

  dashboard(dateTime, playerId?: number, branch_id?: number) {
    const options: ApiOptions = {
      params: {
        datetime: dateTime,
      },
    };

    if (branch_id) {
      return this.apiService.get(`dashboard/branch/${branch_id}`, options);
    }

    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.get(`dashboard/player/${playerId}`, options);
  }

  ranksPositions({ year, month }, branch_id?: number) {
    const options: ApiOptions = {
      params: {
        year: year,
        month: month,
      },
    };

    return this.apiService.get(`${branch_id ? "branch/" + branch_id : ""}/ranks-info/top`, options);
  }
}
