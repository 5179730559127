<div class="ow-dialog">
  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="close()"
  ></m-ui-close-button>

  <!-- TITLE -->
  <div class="title">
    Spiesz się!
  </div>

  <div class="subtitle">
    Wskazana ilość osób{{data?.timePrizes?.[0]?.league_group_id ? ' w Twojej grupie ligowej' : ''}}, która najszybciej zrealizuje kontrakt otrzyma<br /> dodatkową nagrodę.
  </div>

  <div class="description">
    Warunkiem jej przyznania jest terminowe potwierdzenie zapoznania się z zasadami kontraktu.<br/>
    Nagrody wypłacane są po przeliczeniu danych za drugi dzień trwania kontraktu.
  </div>
  <div class="title cta">
    NAGRODY DO ZDOBYCIA
  </div>
  <div class="prizes">
    <div class="item" *ngFor="let prize of data?.timePrizes">
      <span class="count">Ilość osób: {{prize.max_prizes}}</span>
      <div class="rewards">
        <m-ui-currency *ngIf="prize.prize.type === 'currency'" [stockView]="STOCK_VIEW.J" [item]="{amount: prize.prize.amount, currency_id: prize.prize.id}" [lack]="false"></m-ui-currency>
        <m-ui-product *ngIf="prize.prize.type === 'product'" [stockView]="STOCK_VIEW.J" [item]="{amount: prize.prize.amount, product_id: prize.prize.id}" [lack]="false"></m-ui-product>
      </div>
    </div>
  </div>

</div>
