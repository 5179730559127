import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../../../../../shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { SanRanksModule } from "../../../san-ranks/module/custom/san-ranks/san-ranks.module";
import { WfMediaComponent } from "../../base/custom/components/wf-media/wf-media.component";
import { WfRankComponent } from "../../base/custom/components/wf-rank/wf-rank.component";
import { WfMainComponent } from "../../base/custom/dialogs/wf-main/wf-main.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { WfPlayComponent } from "../../base/custom/components/wf-play/wf-play.component";
import { BuildingsModule } from "../../../buildings/module/core/buildings.module";
import {WfMahjongComponent} from '../../base/custom/components/wf-mahjong/wf-mahjong.component';
import {WfPieceDetailsComponent} from '../../base/custom/dialogs/wf-piece-details/wf-piece-details.component';
import {WfPiecePairingComponent} from '../../base/custom/dialogs/wf-piece-pairing/wf-piece-pairing.component';

@NgModule({
  declarations: [
    WfMainComponent,
    WfMediaComponent,
    WfRankComponent,
    WfPlayComponent,
    WfMahjongComponent,
    WfPieceDetailsComponent,
    WfPiecePairingComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    SharedUiMobileModule,
    ReactiveFormsModule,
    FormsModule,
    SanRanksModule,
    NgSelectModule,
    BuildingsModule,
  ]
})
export class WfModule {}
