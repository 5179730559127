import { createSelector } from "@ngrx/store";

import { Player } from "../../modules/player/interfaces/player";
import { AppState } from "../state";
import { PlayerState } from "./state";
import {CurrencyBalanceDetails} from '../../core/interfaces/currency';
import {ProductDetailsBalance} from '../../modules/player/interfaces/product.interface';

export const selectState = (state: AppState) => state.application.player;

const getIsLoading = (state: PlayerState): boolean => state.isLoading;
export const selectIsLoading = createSelector<AppState, object, boolean>(selectState, getIsLoading);

const getPlayer = (state: PlayerState): Player => state.player;
export const selectPlayer = createSelector<AppState, object, Player>(selectState, getPlayer);

const getPlayerHasUnseenCalendarEvents = (state: PlayerState): boolean => {
  return !!(state.player.has_unseen_calendar_events && state.player.gui_unlocks?.business_calendar);
};

export const selectPlayerHasUnseenCalendarEvents = createSelector<AppState, object, Player["has_unseen_calendar_events"]>(
  selectState,
  getPlayerHasUnseenCalendarEvents
);

const getPlayerResources = (state: PlayerState): { products: any[], currencies: any[] } => {
  return { products: state.player.product_balances, currencies: state.player.currency_balances };
}

export const selectPlayerResources = createSelector<AppState, object, { products: ProductDetailsBalance[], currencies: CurrencyBalanceDetails[] }> (selectState, getPlayerResources);
