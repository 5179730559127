export const TAB_ID = {
  AVAILABLE: 1,
  ENDED: 2,
  PLAYER: 3,
  INFO: 4,
};

export const STATUS = {
  ACTIVE: 1,
  FINISHED: 2,
  SOON: 3,
};
