import { MenuButtonConfig } from "../../interfaces/core/menu-buttonts-config.interface";

export const MENU_BUTTONS_CONFIG: MenuButtonConfig[] = [
  {
    type: "group",
    place: "menu-left",
    order: 1,
    name: "go-to",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: null,
    tooltip: "Idź do",
    icon: "location.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: 1, // very important -> check menu-buttons-config.service.ts
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-left",
    order: 1,
    name: "city",
    gui_notification_type: null,
    gui_unlocks_button: "city_map",
    click_event: "city",
    tooltip: "Miasto",
    icon: "city.png",
    show_only_active_me: true,
    group_name: "go-to",
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-left",
    order: 2,
    name: "missions",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "missions",
    tooltip: "Misje",
    icon: "mission.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-left",
    order: 3,
    name: "messages",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "messages",
    tooltip: "Wiadomości",
    icon: "message.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-left",
    order: 4,
    name: "warehouse",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "warehouse",
    tooltip: "Magazyn",
    icon: "warehouse.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-left",
    order: 5,
    name: "minigames",
    gui_notification_type: null,
    gui_unlocks_button: "minigames",
    click_event: "minigames",
    tooltip: "Minigry",
    icon: "game",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
];
