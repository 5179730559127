<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Ranking</span>
      </div>
    </div>
  </div>

  <div class="content loading-opacity">
    <!-- TOP -->
    <div class="top">
      <!-- LEFT -->
      <div class="left">

        <!-- SUB-TITLE -->
        <p
          class="sub-title"
          [innerText]="data.rank.name"
          owEllipsis
        ></p>

        <!-- DESCRIPTION -->
        <p
          class="description"
          [innerText]="data.rank.description"
          owEllipsis
        ></p>
      </div>

      <!-- RIGHT -->
      <div class="right">
        <a (click)="toggleShowOnlyMe()" *ngIf="!isShowOnlyPrize && playerService.notDorOrDorNotActive">
          {{ isShowOnlyMe ? 'Pokaż wszystkie wyniki' : 'Pokaż moje wyniki' }}
        </a>

        <a (click)="toggleShowOnlyPrize()" *ngIf="!isShowOnlyMe">
          {{ isShowOnlyPrize ? 'Pokaż wszystkie wyniki' : 'Tylko Laureaci' }}
        </a>
      </div>
    </div>

    <!-- FRAME -->
    <div class="frame">
      <!-- OTHER POSITIONS -->
      <ow-perfect-scrollbar #perfectScrollbar scrollGradient *ngIf="rankDetails">
        <table class="custom">
          <tbody>
          <ng-container *ngFor="let item of rankDetails; trackBy: trackBy">
            <tr
              *ngIf="isShowOnlyMe ? item.psn === userService.me['external_id'] : (isShowOnlyPrize ? item.prize : true)"
              [class.my-position]="!isShowOnlyMe && item.psn === userService.me['external_id']"
            >
              <td>{{ item['position'] }}</td>
              <td>{{ item.first_name }} {{ item.last_name }}</td>
              <td>{{ item.branch_name }}</td>
              <td>{{ item.prize }}</td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </ow-perfect-scrollbar>
    </div>
  </div>
</div>
