import { CatchBananasPlayScene } from "../../scenes/CatchBananasPlay.scene";

export class AbstractSprite extends Phaser.GameObjects.Sprite {
  scene: CatchBananasPlayScene;

  constructor(scene: CatchBananasPlayScene, x: number, y: number, texture: string, frame: string) {
    super(scene, x, y, texture, frame);
    scene.add.existing(this);

    this.createAnimations();
    this.handldeStartAnimation();
    this.handleUpdateAnimation();
    this.handleCompleteAnimation();
    this.spriteConfiguration();
  }

  createAnimations() {}

  handldeStartAnimation() {}

  handleUpdateAnimation() {}

  handleCompleteAnimation() {}

  spriteConfiguration() {}
}
