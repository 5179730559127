import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { OwPerfectScrollbarModule } from '@oskarwegner/ow-perfect-scrollbar';
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { VirtualKeyboardMobileModule } from "../../../virtual-keyboard/mobile/virtual-keyboard-mobile.module";
import { MissionsAbstractModule } from "../../abstract/core/missions-abstract.module";
import { MissionDetailsComponent } from "../../base/core/dialogs/mission-details/mission-details.component";
import { MissionGroupDetailsComponent } from "../../base/core/dialogs/mission-group-details/mission-group-details.component";
import { MissionGroupListComponent } from "../../base/core/dialogs/mission-group-list/mission-group-list.component";
import { MissionTransferComponent } from "../../base/core/dialogs/mission-transfer/mission-transfer.component";
import { MissionsComponent } from "../../base/core/dialogs/missions/missions.component";
import { CUSTOM_DECLARATIONS } from "../../consts/custom/components.const";
import { CUSTOM_IMPORTS } from "../../consts/custom/imports.const";
import { CUSTOM_PROVIDERS } from "../../consts/custom/providers.const";
import { MissionService } from "../../services/core/mission.service";
import { MissionServiceCustom } from "../../services/custom/mission-custom.service";

@NgModule({
  imports: [
    CommonModule,
    MissionsAbstractModule,
    VirtualKeyboardMobileModule,
    SharedUiMobileModule,
    OwPerfectScrollbarModule,
    ...CUSTOM_IMPORTS,
  ],
  declarations: [
    MissionsComponent,
    MissionDetailsComponent,
    MissionTransferComponent,
    MissionGroupListComponent,
    MissionGroupDetailsComponent,
    ...CUSTOM_DECLARATIONS,
  ],
  providers: [...CUSTOM_PROVIDERS],
})
export class MissionModule {
  constructor(private missionService: MissionServiceCustom) {} // CORE OVERRIDE
}
