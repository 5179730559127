<div class="global-dialog">
  <loading [classNames]="['white', 'stroke']" [size]="'big'"></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button
  *ngIf="!isLoading"
  ></m-ui-close-button>

  <div *ngIf="buildings && buildings.length">

    <!-- NAVIGATION CENTER -->
    <m-ui-navigation-center
      *ngIf="swiper"
      [hidePrev]="swiper?.isBeginning"
      [hideNext]="swiper?.isEnd"
      (prevClicked)="prevSlide()"
      (nextClicked)="nextSlide()"
    ></m-ui-navigation-center>

    <!-- SLIDER -->
    <swiper-container *ngIf="initSwiper" #swiperRef swiperElement [config]="swiperConfig" init="false">
      <swiper-slide *ngFor="let building of buildings; let idx = index">
        <div
          class="buildings-list-container loading-opacity"
          (click)="building && openBuildingDetails(building.building_id)"
        >
          <!-- exclude team buildings -->
          <ng-container *ngIf="building.building_id < 10001 || building.building_id > 10060">
            <ng-container *ngIf="player.level >= building.required_level; else requiredLevelTpl">

              <!-- GROUP BUILDING -->
              <m-ui-group-count [building]="building"></m-ui-group-count>
            </ng-container>

            <!-- BUILDING LOCK -->
            <ng-template #requiredLevelTpl>
              <div class="building-lock text-error">
                <i class="fas fa-lock"></i>
                <span>poziom {{ building.required_level }}</span>
              </div>
            </ng-template>
          </ng-container>

          <div class="ow-dialog c-window-b c-secondary">

            <!-- ICON -->
            <div class="icon-container">
              <img [src]="building | buildingIcon: false"/>
            </div>

            <!-- NAME -->
            <p class="name">{{ building.name }}</p>

            <!-- COSTS -->
            <div class="frame" *ngIf="building.build_currency_prices.length > 0">
              <m-ui-costs
                [stockView]="STOCK_VIEW.CUSTOM"
                [customIconSize]="'small'"
                [customClassStock]="'stock-layout-d'"
                [currencies]="building.build_currency_prices"
              ></m-ui-costs>
            </div>
            <!-- ACTIVE SLIDER -->
            <div class="active-shadow"></div>

          </div>
        </div>
      </swiper-slide>
    </swiper-container>
<!--    <swiper-->
<!--      #sliderBuildings-->
<!--      [config]="swiperConfig"-->
<!--      [(index)]="sliderActiveIndex"-->
<!--    >-->
<!--      <div-->
<!--        class="buildings-list-container loading-opacity"-->
<!--        *ngFor="let building of buildings; let idx = index"-->
<!--        (click)="building && openBuildingDetails(building.building_id)"-->
<!--      >-->
<!--        <ng-container *ngIf="player.level >= building.required_level; else requiredLevelTpl">-->

<!--          &lt;!&ndash; GROUP BUILDING &ndash;&gt;-->
<!--          <m-ui-group-count [building]="building"></m-ui-group-count>-->
<!--        </ng-container>-->

<!--        &lt;!&ndash; BUILDING LOCK &ndash;&gt;-->
<!--        <ng-template #requiredLevelTpl>-->
<!--          <div class="building-lock text-error">-->
<!--            <i class="fas fa-lock"></i>-->
<!--            <span>poziom {{ building.required_level }}</span>-->
<!--          </div>-->
<!--        </ng-template>-->

<!--        <div class="ow-dialog c-window-b c-secondary">-->

<!--          &lt;!&ndash; ICON &ndash;&gt;-->
<!--          <div class="icon-container">-->
<!--            <img [src]="building | buildingIcon: false"/>-->
<!--          </div>-->

<!--          &lt;!&ndash; NAME &ndash;&gt;-->
<!--          <p class="name">{{ building.name }}</p>-->

<!--          &lt;!&ndash; COSTS &ndash;&gt;-->
<!--          <div class="frame" *ngIf="building.build_currency_prices.length > 0">-->
<!--            <m-ui-costs-->
<!--              [stockView]="STOCK_VIEW.CUSTOM"-->
<!--              [customIconSize]="'small'"-->
<!--              [customClassStock]="'stock-layout-d'"-->
<!--              [currencies]="building.build_currency_prices"-->
<!--            ></m-ui-costs>-->
<!--          </div>-->
<!--          &lt;!&ndash; ACTIVE SLIDER &ndash;&gt;-->
<!--          <div class="active-shadow"></div>-->

<!--        </div>-->
<!--      </div>-->
<!--    </swiper>-->
<!--    <m-ui-slider-dots [sliderRef]="sliderBuildings"></m-ui-slider-dots>-->
  </div>
</div>
