<div class="global-dialog ow-dialog c-window-long" *ngIf="player">
  <loading></loading>

  <m-ui-close-button></m-ui-close-button>

  <!-- BUSINESS CALENDAR -->
  <div *ngIf="businessCalendarAvailable" (click)="openBusinessCalendar()" class="business-calendar-button"></div>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Centrum danych</span>
      </div>
    </div>
  </div>

  <!-- TOP MENU -->
  <div class="top-menu">
    <!-- LEAGUE GROUP -->
    <div
      class="option"
      *ngIf="!isDor"
    >
      <div
        class="icon-league group big loading-opacity"
        [matTooltip]="(leagueGroup | businessLeagueGroup)?.name"
        [ngClass]="'icon-' + leagueGroup"
      ></div>
    </div>

    <!-- LEAGUE COLOR -->
    <div class="option">
      <div
        class="icon-league pointer loading-opacity"
        [ngClass]="leagueCard ? ('league-' + leagueCard?.color) : 'logo'"
        [matTooltip]="player.gui_unlocks['league'] ? ('Liga ' + (leagueCard?.color | businessLeagueColor)?.name) : 'Dostępne wkrótce!'"
        [matTooltipDisabled]="player.gui_unlocks['league'] ? !leagueCard : false"
        matTooltipPosition="below"
        (click)="openLeague({dashboardMonth: true})"
      ></div>
      <span>Liga <br> sprzedaży</span>

      <div
        class="app-notification dot-blue"
        *ngIf="player['gui_notifications']?.['import_calculate_leagues'] > 0"
      ></div>
    </div>

    <!-- CARD TASKS -->
    <div class="option">
      <button
        class="c-square small c-secondary"
        matTooltip="Dostępne wkrótce!"
        matTooltipPosition="below"
        [matTooltipDisabled]="player.gui_unlocks['dashboard_card_tasks']"
        (click)="openCardTasks({dashboardMonth: true})"
      >
        <i class="fas fa-sticky-note"></i>
      </button>
      <span>Karty <br> zadań</span>

      <div
        class="app-notification dot-blue"
        *ngIf="player['gui_notifications']?.['import_calculate_tasks'] > 0"
      ></div>
    </div>

    <!-- RANKS -->
    <div class="option">
      <button
        class="c-square small c-secondary"
        matTooltip="Dostępne wkrótce!"
        matTooltipPosition="below"
        [matTooltipDisabled]="player.gui_unlocks['rankings']"
        (click)="openRanks({dashboardMonth: true})"
      >
        <i class="fas fa-chart-line"></i>
      </button>
      <span>Rankingi</span>

      <div
        class="app-notification dot-blue"
        *ngIf="player['gui_notifications']?.['import_calculate_ranks'] > 0"
      ></div>
    </div>

    <!-- TOP RANK PLACES -->
    <div
      class="option"
      [matTooltip]="'Najwyższe miejsce zajęte przez Ciebie w rankingu sprzedażowym'"
      [class.cursor]="rankPosition?.country"
    >
      <div
        class="circle loading-opacity"
        (click)="openRanks({rankId: rankPosition?.country.rank_id, filters: rankPosition.country.filters, dashboardMonth: true})"
        [class.pointer-events]="!rankPosition?.country"
      >
        {{ rankPosition?.country?.position || '-' }}
      </div>
      <span class="no-wrap">W kraju</span>
    </div>
    <div
      *ngIf="!isDor"
      class="option"
      [matTooltip]="'Najwyższe miejsce zajęte przez Ciebie w rankingu sprzedażowym'"
      [class.cursor]="rankPosition?.region"
    >
      <div
        class="circle loading-opacity"
        (click)="openRanks({rankId: rankPosition?.region.rank_id, filters: rankPosition.region.filters, dashboardMonth: true})"
        [class.pointer-events]="!rankPosition?.region"
      >
        {{ rankPosition?.region?.position || '-' }}
      </div>
      <span class="no-wrap">W regionie</span>
    </div>
    <div
      *ngIf="!isDor"
      class="option"
      [matTooltip]="'Najwyższe miejsce zajęte przez Ciebie w rankingu sprzedażowym'"
      [class.cursor]="rankPosition?.league_group"
    >
      <div
        class="circle loading-opacity"
        (click)="openRanks({rankId: rankPosition?.league_group.rank_id, filters: rankPosition.league_group.filters, dashboardMonth: true})"
        [class.pointer-events]="!rankPosition?.league_group"
      >
        {{ rankPosition?.league_group?.position || '-' }}
      </div>
      <span>W grupie <br> ligowej</span>
    </div>

    <!-- EVENTS -->
    <div class="option">
      <button
        class="c-square small c-secondary"
        matTooltip="Dostępne wkrótce!"
        matTooltipPosition="below"
        [matTooltipDisabled]="player.gui_unlocks['business_events']"
        (click)="openEvents()"
      >
        <i class="fas fa-gift"></i>
      </button>
      <span>Eventy</span>

      <div
        class="app-notification dot-red"
        *ngIf="player['gui_notifications']?.['business_event'] > 0"
      ></div>
    </div>

    <!-- BASIC ACTIONS -->
    <div
      *ngIf="!isDor && isPlayable"
      class="option"
    >
      <button class="c-square small c-secondary" (click)="openBasicActions()">
        <i class="fas fa-coins"></i>
      </button>
      <span>Akcje <br> podstawowe</span>

      <div
        class="app-notification dot-blue"
        *ngIf="isPlayable && player['gui_notifications']?.['import_calculate_basic_actions'] > 0"
      ></div>

      <div
        class="app-notification dot-red"
        *ngIf="isPlayable && player['gui_notifications_list']?.['basic_action'] > 0"
      ></div>
    </div>

    <!-- LEAGUE ACHIEVEMENTS -->
    <div
      *ngIf="!isDor && player.gui_unlocks['league_achievements']"
      class="option"
    >
      <button
        matTooltip="Dostępne wkrótce!"
        matTooltipPosition="below"
        [matTooltipDisabled]="player.gui_unlocks['league_achievements']"
        class="c-square small c-secondary"
        (click)="openLeagueAchievements()"
      >
        <i class="fas fa-trophy-alt"></i>
      </button>
      <span>Osiągnięcia <br> Ligowe</span>

      <div
        class="app-notification dot-red"
        *ngIf="player['gui_notifications_list']?.['processing_achievement']"
      ></div>
    </div>

    <!-- BUSINESS CHALLENGES -->
    <div class="option">
      <button
        matTooltip="Dostępne wkrótce!"
        matTooltipPosition="below"
        [matTooltipDisabled]="player.gui_unlocks['business_challenges']"
        class="c-square small c-quaternary"
        (click)="openBusinessChallenges()"
      >
        <i class="far fa-clipboard-check"></i>
      </button>
      <span>Wyzwania <br> biznesowe</span>

      <div
        class="app-notification dot-blue"
        *ngIf="player['gui_notifications']?.['import_calculate_microleague'] > 0
        || player['gui_notifications']?.['import_calculate_microleague_contract'] > 0"
      ></div>
    </div>

    <!-- QUALITY TASKS -->
    <div class="option">
      <div
        class="img pointer"
        [matTooltip]="player.gui_unlocks['dashboard_quality'] ? 'Blok jakości' : 'Blok jakości - dostępne wkrótce!'"
        matTooltipPosition="below"
        (click)="openQualityTasks(true)"
      >
        <img [src]="'icon_quality.png' | asset: 'ui'">
      </div>
      <span class="loading-opacity">
        <ng-container *ngIf="qualityTasks?.league_percent; else noLeaguePercentTpl">
          {{ qualityTasks?.league_percent }}%
        </ng-container>
        <ng-template #noLeaguePercentTpl>
          -
        </ng-template>
      </span>

      <div
        class="app-notification dot-blue"
        *ngIf="player['gui_notifications']?.['import_calculate_quality'] > 0"
      ></div>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="main loading-opacity" *ngIf="!player.gui_unlocks['dashboard_under_construction']">
    <ng-container *ngIf="dashboard?.length > 0; else noItemTpl">
      <ow-perfect-scrollbar
        [scrollGradient]="dashboard"
        [isRefreshScrollTop]="false"
        [class.loading-opacity]="isLoading"
      >
        <div class="table">
          <div class="header">
            <div class="tr">
              <div class="td" *ngFor="let item of structure">
                <div class="header-title">{{ item.label }}</div>
                <div class="header-prizes" *ngIf="!isDor || (isDor && item.key !== 'event')">
                  <!-- CURRENCIES -->
                  <ng-container *ngIf="item.prizes?.currency?.length > 0">
                    <m-ui-currency
                      *ngFor="let currency of item.prizes.currency"
                      [item]="currency"
                      [stockView]="STOCK_VIEW.H"
                      [lack]="false"
                      [hideValue]="true"
                    ></m-ui-currency>
                  </ng-container>
                  <!-- PRODUCTS -->
                  <ng-container *ngIf="item.prizes?.product?.length > 0">
                    <m-ui-product
                      *ngFor="let product of item.prizes?.product"
                      [item]="product"
                      [stockView]="STOCK_VIEW.H"
                      [lack]="false"
                      [hideValue]="true"
                    ></m-ui-product>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <div class="body">
            <div
              class="tr"
              *ngFor="let item of dashboard; let idx = index"
              [class.expanded]="item.isExpanded"
            >
              <!-- I LEAGUE -->
              <div
                class="td"
                [class.pointer]="item.league?.name"
                (click)="openLeague({dashboardMonth: true})"
              >
                <div>{{ item.league.name }}</div>
              </div>

              <!-- II TASKS CARDS-->
              <div class="td cards">
                <ng-container *ngFor="let task of item.task; let idx = index">
                  <div
                    class="card tasks pointer"
                    *ngIf="idx < 2 || (idx > 1 && item.isExpanded)"
                    (click)="openCardTasks({taskId: task.object_id})"
                    [ngClass]="'card-template-' + task.card_template_id"
                  >
                    <span class="animal-icon"></span>
                    <!-- NAME -->
                    <div class="card-name">{{ task.name }}</div>

                    <!-- PROGRESSBAR -->
                    <div class="progressbar-container" *ngIf="item.isExpanded">
                      <div class="progressbar">
                        <div class="fill" [ngClass]="task.color_id"
                             [style.width]="task.performance_percent + '%'"></div>
                      </div>
                      <div class="box">
                        <div class="progressbar-value">
                          {{ task.performance_percent }}%
                        </div>
                      </div>
                    </div>

                    <!-- PRIZES -->
                    <div class="card-prizes">
                      <!-- CURRENCIES -->
                      <ng-container *ngIf="task.prizes?.currency?.length > 0">
                        <m-ui-currency
                          *ngFor="let currency of task.prizes.currency"
                          [item]="currency"
                          [stockView]="STOCK_VIEW.H"
                          [lack]="false"
                        ></m-ui-currency>
                      </ng-container>
                      <!-- PRODUCTS -->
                      <ng-container *ngIf="task.prizes?.product?.length > 0">
                        <m-ui-product
                          *ngFor="let product of task.prizes.product"
                          [item]="product"
                          [stockView]="STOCK_VIEW.H"
                          [lack]="false"
                        ></m-ui-product>
                      </ng-container>
                    </div>

                    <!-- CARD TAB -->
                    <div class="tab" [ngClass]="task.color_id"></div>
                  </div>
                </ng-container>
              </div>

              <!-- III RANKS -->
              <div class="td cards">
                <ng-container *ngFor="let rank of item.rank; let idx = index">
                  <div
                    class="card ranks pointer"
                    *ngIf="idx < 2 || (idx > 1 && item.isExpanded)"
                    (click)="openRanks({rankId: rank.object_id, filters: rank.object_filters, dashboardMonth: true})"
                    [ngClass]="'card-template-' + rank.card_template_id"
                  >
                    <span class="animal-icon"></span>
                    <div class="card-name">{{ rank.name }}</div>
                    <div class="info" *ngIf="item.isExpanded">
                      <ng-container *ngIf="!isDor; else dorTpl">
                        Kraj: {{ rank.position || '-' }},
                        Region: {{ rank.region_position || '-' }},
                        Grupa ligi: {{ rank.league_group_position || '-' }}
                      </ng-container>

                      <ng-template #dorTpl>
                        Miejsce w kraju: {{ rank.position || '-' }}
                      </ng-template>
                    </div>
                    <div class="card-prizes">
                      <!-- CURRENCIES -->
                      <ng-container *ngIf="rank.prizes.currency?.length > 0">
                        <m-ui-currency
                          *ngFor="let currency of rank.prizes.currency"
                          [item]="currency"
                          [stockView]="STOCK_VIEW.H"
                          [lack]="false"
                          [other]="{afterValue: currency.currency_id == WF_CURRENCY ? 'zł' : ''}"
                        ></m-ui-currency>
                      </ng-container>

                      <!-- PRODUCTS -->
                      <ng-container *ngIf="rank.prizes?.product?.length > 0">
                        <m-ui-product
                          *ngFor="let product of rank.prizes.product"
                          [item]="product"
                          [stockView]="STOCK_VIEW.H"
                          [lack]="false"
                        ></m-ui-product>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>

              <!-- IV EVENTS -->
              <div class="td cards">
                <ng-container *ngFor="let event of item.event; let idx = index">
                  <div
                    class="card pointer"
                    (click)="openEvents()"
                    *ngIf="idx < 2 || (idx > 1 && item.isExpanded)"
                  >
                    <div class="card-name">{{ event.name }}</div>
                    <div class="card-prizes" *ngIf="!isDor">
                      <!-- CURRENCIES -->
                      <ng-container *ngIf="event.prizes?.currency?.length > 0">
                        <m-ui-currency
                          *ngFor="let currency of event.prizes.currency"
                          [item]="currency"
                          [stockView]="STOCK_VIEW.H"
                          [lack]="false"
                          [hideValue]="true"
                        ></m-ui-currency>
                      </ng-container>

                      <!-- PRODUCTS -->
                      <ng-container *ngIf="event.prizes?.product?.length > 0">
                        <m-ui-product
                          *ngFor="let product of event.prizes.product"
                          [item]="product"
                          [stockView]="STOCK_VIEW.H"
                          [lack]="false"
                          [hideValue]="true"
                        ></m-ui-product>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>

              <!-- NOT FIRST/LAST CHILD -->
              <div class="separator" [matTooltip]="item.isExpanded ? 'Zwiń' : 'Zobacz pozostałe'">
                <span class="padding">
                  <i class="far"
                     [ngClass]="item.isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                     (click)="toggleExpand(item)"
                  ></i>
                </span>
              </div>

              <!-- V BASIC ACTIONS -->
              <div class="td" *ngIf="!isDor">
                <div
                  class="pointer"
                  (click)="openBasicActions(item.basic_action.object_id)"
                >
                  {{ item.basic_action.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ow-perfect-scrollbar>
    </ng-container>

    <ng-template #noItemTpl>
      <div class="no-item">
        Brak danych
      </div>
    </ng-template>
  </div>

  <div class="main loading-opacity" *ngIf="player.gui_unlocks['dashboard_under_construction']">
    <div class="under-construction">
      <img [src]="'business.png' | asset:'ui'"/>
      Już wkrótce zobaczysz tutaj wszystkie zadania biznesowe. <br> Tymczasem możesz przeglądać karty zadań i rankingi
      – dostępne po kliknięciu w odpowiednią ikonę w górnej sekcji tego okna.
    </div>
  </div>

  <div class="navigation" *ngIf="owPaginationDate">
    <!-- PREV -->
    <button
      class="previous"
      [disabled]="!owPaginationDate.allowPrev.month"
      (click)="prev()"
    >
      <i class="far fa-chevron-square-left nav-arrow"></i>
    </button>

    <!-- MONTH -->
    <span class="value">
      <ng-container *ngIf="owDate">
        {{ owDate.month_text_normal }}
      </ng-container>
    </span>

    <!-- NEXT -->
    <div
      [matTooltip]="'Dane dostępne wkrótce.'"
      [matTooltipPosition]="'above'"
      [matTooltipDisabled]="owPaginationDate.allowNext.month"
    >
      <button
        class="next"
        [disabled]="!owPaginationDate.allowNext.month"
        (click)="next()"
      >
        <i class="far fa-chevron-square-right nav-arrow"></i>
      </button>
    </div>
  </div>
  <div *ngIf="isDor" class="business-buttons">
    <div class="bar-button base">
      <button class="c-base c-secondary width" (click)="openBranchesSelector()">
        Wybierz oddział
      </button>
    </div>
  </div>
</div>
