import { EventDialog } from "../../../../../../../core/interfaces/event-dialog.interface";
import {EVENT_DIALOGS_NAMES_ANTYMINER_CUSTOM} from './event-names.const';
import {AntyminerBeginComponent} from '../../../base/custom/dialogs/antyminer-begin/antyminer-begin.component';
import {AntyminerStartComponent} from '../../../base/custom/dialogs/antyminer-start/antyminer-start.component';
import {AntyminerContinueComponent} from '../../../base/custom/dialogs/antyminer-continue/antyminer-continue.component';
import {AntyminerBoardComponent} from '../../../base/custom/dialogs/antyminer-board/antyminer-board.component';

export const EVENT_DIALOGS_ANTYMINER_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_ANTYMINER_CUSTOM.ANTYMINER_BEGIN]: AntyminerBeginComponent,
  [EVENT_DIALOGS_NAMES_ANTYMINER_CUSTOM.ANTYMINER_START]: AntyminerStartComponent,
  [EVENT_DIALOGS_NAMES_ANTYMINER_CUSTOM.ANTYMINER_CONTINUE]: AntyminerContinueComponent,
  [EVENT_DIALOGS_NAMES_ANTYMINER_CUSTOM.ANTYMINER_BOARD]: AntyminerBoardComponent,
};
