import { EventDialog } from "../../../../../../../core/interfaces/event-dialog.interface";
import {EVENT_DIALOGS_NAMES_BUILDINGS} from '../../core/event-dialogs/event-names.const';
import {EVENT_DIALOGS_NAMES_BUILDINGS_CUSTOM} from './event-names.const';
import {BuildingBuildComponent} from '../../../base/custom/dialogs/building-build/building-build.component';
import {BuildingUpgradeComponent} from '../../../base/custom/dialogs/building-upgrade/building-upgrade.component';
import {BuildingInfoComponent} from '../../../base/custom/dialogs/building-info/building-info.component';
import {BuildingSpecialIncreaseComponent} from '../../../base/custom/dialogs/building-special-increase/building-special-increase.component';
import {BuildingSpecialComponent} from '../../../base/custom/dialogs/building-special/building-special.component';
import {BuildingListComponent} from '../../../base/custom/dialogs/building-list/building-list.component';
import {BuildingProductionComponent} from '../../../base/custom/dialogs/building-production/building-production.component';

export const EVENT_DIALOGS_BUILDINGS_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_BUILD]: BuildingBuildComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_UPGRADE]: BuildingUpgradeComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_INFO]: BuildingInfoComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_SPECIAL_INCREASE]: BuildingSpecialIncreaseComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_SPECIAL_UPGRADE]: BuildingSpecialComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_LIST]: BuildingListComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_PRODUCTION]: BuildingProductionComponent
};
