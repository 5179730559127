import { translate } from "../../../../../../core/helpers/translate.helper";
import { TileMenuButtonConfig } from "../../../interfaces/tile-menu-config.interface";
import { isPointerupOnCanvas } from "../../../utils/game.helper";
import { TileMenuButtonCore } from "../core/TileMenuButtonCore";
import { TileMenu } from "./TileMenu";

export class TileMenuButton extends TileMenuButtonCore {
  nameFn: Function;

  constructor(tileMenu: TileMenu, buttonConfig: TileMenuButtonConfig) {
    super(tileMenu, buttonConfig);
    // @ts-ignore - todo determine why it stopped working
    this.nameFn = buttonConfig.nameFn;
  }

  addPointerEvents() {
    /**
     * Changes the tile menu text, by changing the main text, and hiding the additional text.
     * Changes the current frame to hover state.
     */
    this.on("pointerover", () => {
      if (!this.scene.input.isOver) {
        return true;
      }

      let name = this.name;

      if (this.nameFn) {
        name = this.nameFn();
      }

      this.tileMenu.changeMenuTextAndHideSecondLine(translate(name));
      this.setFrame(this.hoverFrame);
    });

    /**
     * Changes the tile menu text, by changing the main text to initial state, and showing the additional text.
     * Changes the current frame to initial state.
     */
    this.on("pointerout", () => {
      if (!this.scene.input.isOver) {
        return true;
      }
      this.tileMenu.setBasicMenuText();
      this.setFrame(this.basicFrame);
    });

    /**
     * Changes the current frame to active state.
     */
    this.on("pointerdown", pointer => {
      if (!isPointerupOnCanvas(pointer, this.myScene)) {
        return;
      }
      this.setFrame(this.activeFrame);
    });

    /**
     * Emits the event based on the current button configuration.
     * Destroys currently opened tile menu.
     */
    this.on("pointerup", pointer => {
      if (!isPointerupOnCanvas(pointer, this.myScene)) {
        return;
      }
      this.gameService.emitGameEvent({
        name: this.eventName,
        value: this.playerTileId,
        data: this.tileMenu.targetTile.tileData,
      });
      this.tileMenu.targetTile.closeTileMenu();
    });
  }
}
