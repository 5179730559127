<div class="global-dialog ow-dialog c-window-short employee-details-container">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <div class="top-info">
    <div
      class="experience"
      *ngIf="upgradeBuildingDetails?.exp_points > 0"
      [matTooltip]="'Punkty doświadczenia'"
      [matTooltipShowDelay]="400"
      [matTooltipPosition]="'above'"
    >
      <div class="inner-wrapper">
        <p>+{{ upgradeBuildingDetails?.exp_points }}</p>
      </div>
      <img [src]="'exp.png' | asset : 'ui'" />
    </div>
  </div>

  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>W punkt!</span>
      </div>
    </div>
  </div>

  <ng-container  *ngIf="buildingDetails && upgradeBuildingDetails">
    <div class="details">
      <div class="picture">
        <img [src]="buildingDetails | buildingIcon:false">
      </div>

      <div class="information">
        <h3 class="name" [innerHTML]="buildingDetails.name"></h3>

        <div class="tracking-details">
          <div class="materials-box">
            <p class="title" [innerHTML]="'Informacje o planszy'">
            </p>
            <div class="wrapper">
              <div
                class="item"
                *ngIf="boardSize"
                [matTooltip]="'Wielkość terenu'"
                [matTooltipShowDelay]="400"
                [matTooltipPosition]="'above'"
              >
                <img [src]="'tracking_map_size.png' | asset: 'ui'" />
                <span>{{ boardSize }}</span>
              </div>

              <div
                class="item"
                *ngIf="buildingDetails.antiminer_details.step_price"
                [matTooltip]="'Koszt kondycji Janka'"
                [matTooltipShowDelay]="400"
                [matTooltipPosition]="'above'"
              >
                <img [src]="'fitness.png' | asset: 'products/big'" />
                <span>{{ buildingDetails.antiminer_details.step_price }}</span>
              </div>

              <div
                class="item"
                *ngIf="buildingDetails.antiminer_details.start_fields !== null"
                [matTooltip]="'Ilość pól odkrytych na start'"
                [matTooltipShowDelay]="400"
                [matTooltipPosition]="'above'"
              >
                <img [src]="'binocular_field.png' | asset: 'parameters/basic'" />
                <span>{{ buildingDetails.antiminer_details.start_fields }}</span>
              </div>
            </div>
          </div>

          <div class="materials-box" *ngIf="antyminerItems.length">
            <p class="title" [innerHTML]="'Ilość rzeczy, które możesz<br>odnaleźć na planszy'">
            </p>
            <div class="wrapper">
              <div
                class="item"
                *ngFor="let item of antyminerItems"
                [matTooltip]="item.name"
                [matTooltipShowDelay]="400"
                [matTooltipPosition]="'above'"
              >
                <img [src]="item.icon + '.png' | asset: 'buildings'" />
                <span>{{ item.count }}</span>
              </div>
            </div>
          </div>

          <div class="materials-box" *ngIf="buildingDetails.antiminer_details.max_turns as turns">
            <p class="title" [innerHTML]="'Orientacja w terenie'">
            </p>
            <div class="wrapper">
              <div
                class="item"
                [matTooltip]="'Wszystkie obiekty na planszy zostaną odnalezione max. po poniższej liczbie ruchów'"
                [matTooltipShowDelay]="400"
                [matTooltipPosition]="'above'"
              >
                <img [src]="'orientation.png' | asset: 'parameters/basic'" />
                <span>{{ turns }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom loading-opacity">
      <m-ui-separator
        [title]="upgradeBuildingDetails.build_product_prices?.length ||
        upgradeBuildingDetails.build_currency_prices?.length ||
        upgradeBuildingDetails.required_level > 1 ? 'Wymagania do rozpoczęcia akcji' : ''">
      </m-ui-separator>

      <!-- REQUIREMENTS -->
      <!-- TODO - add output for quick determining if there is any requirements to be fulfilled; remove long if -->
      <app-building-requirements
        *ngIf="upgradeBuildingDetails && outcomeParametersRequirements"
        [building]="upgradeBuildingDetails"
        [products]="upgradeBuildingDetails.build_product_prices"
        [currencies]="upgradeBuildingDetails.build_currency_prices"
        [playerLevel]="upgradeBuildingDetails.required_level > 1 ? upgradeBuildingDetails.required_level : null"
        [requiredBuilding]="upgradeBuildingDetails.required_building"
        [parametersOutcome]="outcomeParametersRequirements"
        (requirementsStatus)="setRequirementsStatus($event)"
      ></app-building-requirements>
    </div>

    <div
      class="buttons"
      *ngIf="buildingDetails && requirementsStatus"
    >
      <!-- BUILD -->
      <div
        [ngbTooltip]="requirementsStatus['textErrors'].length && tooltipRequirementsTpl"
        placement="bottom"
        container="body"
      >
        <button
          class="c-base c-primary"
          [disabled]="!requirementsStatus.valid"
          (click)="upgrade()"
        >
        <span class="text-normal">
         Rozpocznij akcję
        </span>
        </button>
      </div>

      <!-- TOOLTIP REQUIREMENTS TPL -->
      <ng-template #tooltipRequirementsTpl>
        <div *ngFor="let textError of requirementsStatus['textErrors']">
          {{ textError }}
        </div>
      </ng-template>
    </div>
  </ng-container>
</div>


