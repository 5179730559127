import { GameModes } from "../enums/game-modes.enum";
import { GameConfigInterface } from "../interfaces/game-config.interface";
import { Match3MinigameComponent } from "../match3/components/match3-minigame/match3-minigame.component";

export const Match3Config: GameConfigInterface = {
  visible: false,
  order: 1,
  game: 2,
  payment: GameModes.NORMAL,
  key: "match3",
  icon: "logo.png",
  iconPath: "ui/match_three",
  component: Match3MinigameComponent,
  rules: `
        <div class="text-left padding-ol"><div class="text-center text-bold">Zasady gry Pamiątki z Afryki</div>
          <ol>
            <li>Użyj kostki do gry, aby rozpocząć grę Pamiątki z Afryki.</li>
            <li>Twoim zadaniem w grze jest połączenie identycznych pamiątek, które musisz przesunąć w taki sposób, aby utworzyły w linii pionowej lub poziomej ciąg minimum trzech tych samych produktów.</li>
            <li>Na każdym poziomie gry na bocznym pasku zobaczysz misję, którą musisz zrealizować, aby przejść na kolejny poziom. Będą to ikony pamiątek oraz ich ilość zbita w wyniku połączeń. Ikona różnych przedmiotów w pudełku oznacza możliwość dowolnych połączeń (pamiętaj, że jedno połączenie to minimum 3 jednakowe przedmioty).</li>
            <li>Rozgrywka nie jest oparta na czasie, liczy się realizacja misji, połączenie jak największej liczby pamiątek oraz uzyskanie dzięki temu jak największej liczby punktów.</li>
            <li>Za każdą z połączonych pamiątek otrzymujesz 10 pkt. (czyli jeśli połączysz trzy te same pamiątki otrzymujesz 30 pkt.).</li>
            <li>Punktowane są także układy jednakowych pamiątek, jakie tworzy połączenie minimum trzech elementów w linii pionowej z minimum trzema elementami w linii poziomej, mającymi wspólny element. Ten wspólny element punktowany jest podwójnie.</li>
            <li>Punkty naliczane są za wszystkie połączone pamiątki, nie tylko te, które są wyznaczone w misjach.</li>
            <li>Zwróć uwagę na pamiątki premiowane, które pojawią się na wybranych poziomach gry. Za połączenie trzech pamiątek premiowanych tworzących linię poziomą lub pionową otrzymasz 60 pkt., czyli 20 pkt. za produkt. Premiowane produkty traktuj jako możliwość zdobycia dodatkowych punktów. Nie jest to warunek konieczny do przejścia na kolejny poziom.</li>
            <li>Za ukończenie każdego poziomu gry otrzymujesz dodatkowo 200 pkt.</li>
            <li>Na każdym poziomie gry zdefiniowana jest określona liczba ruchów (taka sama dla wszystkich graczy). W przypadku niewykorzystania wszystkich ruchów na danym poziomie, przechodzą one na poziom kolejny.</li>
            <li>W przypadku braku możliwości wykonania ruchu lub jeśli chcesz otrzymać nowe rozmieszczenie pamiątek na planszy, możesz skorzystać z przycisku ze strzałkami znajdującego się po prawej stronie planszy. Przycisk ten powoduje przetasowanie pamiątek. Przetasowania możesz dokonać wielokrotnie, jednak za każdym razem zostanie ci odjęty jeden ruch.</li>
            <li>UWAGA! Jeżeli wyjdziesz z gry zamykając kartę przeglądarki lub kartę rozgrywki używając przycisku X, gra zostanie zakończona i naliczone zostaną żetony tylko za ukończone poziomy gry.</li>
          </ol>
<!--          <div class="text-left padding-ol">-->
<!--            Uwaga!-->
<!--            <br>-->
<!--            Ranking aktualizowany jest raz dziennie.-->
<!--          </div>-->
        </div>`,
  costs: {
    products: [{ product_id: 24, amount: 1 }],
    canPlay: null,
  },
  stats: null,
  ranks: null,
};
