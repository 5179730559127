import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { GameService } from '../../../../../services/game.service';
import { AuthService } from '../../../../../../auth/providers/auth.service';
import { AppState } from '../../../../../../../store/state';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { LoginActions } from '../../../../../../../store/auth/login';

@Component({
  selector: 'new-edition',
  templateUrl: './new-edition.component.html',
})
export class NewEditionComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(GameService) gameService: GameService;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(Store) store: Store<AppState>;

  ngOnInit() {
    this.gameService.guiService.isSplashShow.next(false);
  }

  logout() {
    this.authService.logoutApi()
      .subscribe(() => {
        this.gameService.guiService.isSplashShow.next(true);
        this.store.dispatch(new LoginActions.AuthLogout());
      });
  }
}
