import { Game } from "phaser";

import { GameService } from "../../../game/services/game.service";
import { CatchBananasGameConfig } from "../interfaces/catch-bananas.interfaces";
import { CatchBananasService } from "../services/catch-bananas.service";

export class CatchBananasGame extends Game {
  gameConfig: CatchBananasGameConfig;
  gameService: GameService;
  catchBananasService: CatchBananasService;
  editMode: boolean;

  constructor(gameConfig: CatchBananasGameConfig) {
    super(gameConfig);
    this.gameConfig = gameConfig;
    this.gameService = gameConfig.gameService;
    this.catchBananasService = gameConfig.catchBananasService;
    this.editMode = gameConfig.editMode;
  }
}
