<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <ng-container *ngIf="buildingDetails">
    <div class="bookmarks">
      <div class="overlap active green">
        <div class="bookmark green">
          <span>Zepsute auto</span>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="top loading-opacity">
        <div class="icon-container">
          <!-- BUILDING ICON -->
          <img [src]="buildingDetails | buildingIcon:false">
        </div>

        <div class="right-column">
          <p class="description">
            Twoje auto uległo awarii w wyniku przegranego <br> starcia z kłusownikami.
            <br> Napraw je, aby móc dalej tropić zwierzęta.
          </p>
        </div>
      </div>

      <!-- SEPARATOR TITLE -->
      <m-ui-separator
        [title]="'Koszt naprawy'"
        *ngIf="buildingDetails['repair_currency_prices']?.length || buildingDetails['repair_product_prices']?.length"
      ></m-ui-separator>

      <!-- COSTS -->
      <m-ui-costs
        [currencies]="buildingDetails['repair_currency_prices']"
        [products]="buildingDetails['repair_product_prices']"
        [stockView]="STOCK_VIEW.CUSTOM"
        [customClassStock]="'stock-san-a'"
        [observable]="true"
        (eventEmitter)="checkCostsRequirements($event)"
      ></m-ui-costs>

      <button
        (click)="maintenance()"
        class="c-base c-secondary maintenance"
        [disabled]="!costsRequirement?.valid"
      >
        Napraw auto
      </button>
    </div>
  </ng-container>
</div>
