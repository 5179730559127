import { Injectable } from "@angular/core";
import { CanActivate, CanLoad, Router } from "@angular/router";

import { ApiService } from "../../../../core/providers/api.service";
import { PlayerService } from "../../../player/providers/player.service";
import { DialogService } from "../../providers/dialog.service";

@Injectable({
  providedIn: "root",
})
export class GalleryGuard implements CanActivate, CanLoad {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private playerService: PlayerService,
    private dialogService: DialogService
  ) {}

  check() {
    if (this.playerService.player.gui_unlocks["photobooth"]) {
      return true;
    } else {
      this.dialogService.openAlert({
        description: "Fotowrzutka (dostępne wkrótce)",
      });
      return false;
    }
  }

  canActivate(): boolean {
    return this.check();
  }

  canLoad(): boolean {
    return this.check();
  }
}
