<div *ngIf="!data.isOpenFromHudMenu" class="container-bg"></div>

<div
  [class.a-container]="!data.isOpenFromHudMenu"
  [class.close-button]="data.isOpenFromHudMenu"
  class="auth"
>
  <m-ui-close-button
    *ngIf="data?.isOpenFromHudMenu"
  ></m-ui-close-button>

  <div class="inner">
    <div *ngIf="!data.isOpenFromHudMenu" class="logo-wrapper"></div>

    <div class="auth-form-wrapper">
      <loading></loading>

      <div class="loading-opacity">
        <p class="form-title">Wybór gracza</p>

        <!-- SELECT PLAYER -->
        <div class="role-select" *ngIf="enabledPlayers.length && currentPlayer">
          <ng-select
            [items]="enabledPlayers"
            [bindLabel]="displayKeyPlayer"
            [(ngModel)]="currentPlayer"
            [clearable]="false"
            [searchable]="false"
          ></ng-select>
        </div>

        <!-- CHOOSE PLAYER BUTTON -->
        <div class="buttons">
          <button
            class="c-base c-primary full-width"
            (click)="setDefaultPlayer()"
          >
            Wybierz
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- APP HELP DESK INFO -->
<app-helpdesk-info></app-helpdesk-info>
