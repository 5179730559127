import { GameModes } from "../enums/game-modes.enum";
import { GameConfigInterface } from "../interfaces/game-config.interface";
import { MinigameRiverRideGuiComponent } from "../riverride/components/minigame-river-ride-gui/minigame-river-ride-gui.component";

export const RiverRideConfig: GameConfigInterface = {
  visible: false,
  game: 1,
  order: 3,
  payment: GameModes.PREMIUM,
  key: "riverride",
  icon: "logo.png",
  iconPath: "ui/river_ride",
  component: MinigameRiverRideGuiComponent,
  rules: `
        <div class="text-left">
          <div class="text-center text-bold">Zasady gry Dziki Nurt</div>
          <ol>
            <li>Użyj kostki do gry, aby rozpocząć grę Dziki Nurt.</li>
            <li>W trakcie rozgrywki przepłyń jak najwięcej metrów dzikim nurtem Amazonki. Każdy metr to 1 zdobyty punkt.</li>
            <li>Łap ryby, bo każda złapana ryba daje 50 punktów.</li>
            <li>Zbieraj skrzynie, aby otrzymać premię w postaci 500 punktów za każdą skrzynię. W trakcie rozgrywki dostępne są tylko 3 skrzynie.</li>
            <li>Czas rozgrywki to 150 sekund, możesz go wydłużyć zbierając zegarki. Każdy zebrany zegarek to 10 dodatkowych sekund.</li>
            <li>Unikaj przeszkód pływających po wodzie (wiry, kłody, arapaimy, krokodyle) - zderzenie z każdą z nich skróci czas rozgrywki o 10 sekund.</li>
            <li>Zachowaj dystans od brzegów rzeki i wysp - zderzenie z nimi spowolni Twoje canoe.</li>
            <li>Steruj canoe za pomocą strzałek - skręcaj strzałkami prawo-lewo, przyspieszaj strzałką w górę i zwalniaj strzałką w dół.</li>
            <li>W nagrodę za grę otrzymasz żetony z salonu gier, w ilości zależnej od liczby zdobytych punktów. Żetony możesz wymienić na nagrody w sklepie „Mistrza gier”.</li>
          </ol>
        </div>
  `,
  costs: {
    products: [{ product_id: 24, amount: 1 }],
    canPlay: null,
  },
  stats: {
    chests_total_this_month: 0,
    my_chests_this_month: 0,
  },
  ranks: null,
  disabledCC: true,
};
