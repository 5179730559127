import { Injectable } from "@angular/core";

import { AbstractEventDialogsService } from "../../../../../../core/abstracts/abstract-event-dialogs.service";
import { EVENT_DIALOGS_AUCTIONS_CUSTOM } from "../../consts/custom/event-dialogs/event-dialogs.const";

@Injectable({
  providedIn: "root",
})
export class AuctionsEventDialogsService extends AbstractEventDialogsService {
  getEventDialogs() {
    return {
      customEventDialogs: EVENT_DIALOGS_AUCTIONS_CUSTOM,
    };
  }
}
