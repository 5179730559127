<div class="ow-dialog c-window-d c-quaternary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button
  [preventDefault]="true"
  (click)="close()"
  ></m-ui-close-button>

  <div class="details loading-opacity">
    <ng-container *ngIf="!isLoading">
      <h3>Wymagania do wykonania tego ruchu</h3>

      <!-- REQUIREMENTS -->
      <app-antyminer-start-requirements
        *ngIf="upgradeBuildingDetails"
        [building]="upgradeBuildingDetails"
        [products]="upgradeBuildingDetails.build_product_prices"
        [currencies]="upgradeBuildingDetails.build_currency_prices"
        [requiredBuilding]="upgradeBuildingDetails.required_building"
        (requirementsStatus)="setRequirementsStatus($event)"
      ></app-antyminer-start-requirements>

      <button
        class="c-base c-primary"
        (click)="track()"
        [disabled]="!requirementsStatus?.valid || upgradeBuildingDetails.required_building?.is_disaster"
      >
        Odkryj
      </button>
    </ng-container>
  </div>
</div>
