import { Component, OnInit } from "@angular/core";

import { UtilityActions } from "../../../../../../store/utility";
import { AbstractTempTokenExchangeComponent } from "../../../../abstract/abstract-temp-token-exchange.component";
import {ActivatedRoute} from '@angular/router';
import {OwInject} from '../../../../../../core/decorators/ow-inject.decorator';
import {STORAGE_DATA} from '../../../../../../core/consts/core/storage/storage-data.const';

@Component({
  selector: "app-temp-token-exchange",
  template: "",
})
export class TempTokenExchangeComponent extends AbstractTempTokenExchangeComponent implements OnInit {
  @OwInject(ActivatedRoute) route: ActivatedRoute;

  ngOnInit() {
    if (this.route.snapshot.queryParams['isEphemeralSession']) {
      const sessionStorageDef = STORAGE_DATA.storageDefinitions.find(x => x.type === 'session_storage');
      if (sessionStorageDef.supported) {
        sessionStorage.setItem('isEphemeralSession', 'true');
        STORAGE_DATA.initialize();
      }
    }

    this.store.dispatch(new UtilityActions.RemoveToken());
    this.subscribeRoutingParams();
  }
}
