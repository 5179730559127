import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiOptions } from "../../../../../../core/interfaces/api-options";
import { ApiService } from "../../../../../../core/providers/api.service";
import { PlayerService } from "../../../../../player/providers/player.service";
import { Branch } from "../../../../interfaces/branch.interface";
import { GetBusinessTaskQuarterRequest } from "../interfaces/request/get-business-task-quarter-request.interface";
import { GetBusinessTaskRequest } from "../interfaces/request/get-business-task-request.interface";
import { GetBusinessDiscountHistoryResponse } from "../interfaces/response/get-business-discount-history-response.interface";
import { GetBusinessTaskQuarterResponse } from "../interfaces/response/get-business-task-quarter-response.interface";
import { GetBusinessTaskResponse } from "../interfaces/response/get-business-task-response.interface";

@Injectable({
  providedIn: "root",
})
export class ApiBusinessService {
  constructor(
    public apiService: ApiService,
    private playerService: PlayerService
  ) {}

  tasks(data: GetBusinessTaskRequest, branch?: Branch): Observable<GetBusinessTaskResponse> {
    const options: ApiOptions = {
      params: {
        ...data,
      },
    };

    if (branch && !branch.manager_player_id) {
      return this.apiService.get(`branch/${branch.branch_id}/tasks`, options);
    }

    let playerId = branch?.manager_player_id;

    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.get(`player/${playerId}/tasks`, options);
  }

  tasksQualityQuarter(data: GetBusinessTaskQuarterRequest, branch?: Branch): Observable<GetBusinessTaskQuarterResponse> {
    if (branch && !branch.manager_player_id) {
      return this.apiService.get(`branch/${branch.branch_id}/quality/tasks/year/${data.year}/quarter/${data.quarter}`);
    }

    let playerId = branch?.manager_player_id;

    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.get(`player/${playerId}/quality/tasks/year/${data.year}/quarter/${data.quarter}`);
  }

  discountHistory(): Observable<GetBusinessDiscountHistoryResponse> {
    return this.apiService.get(`discount/history`);
  }

  chooseTask({ id, position }: { id: number; position: number }) {
    return this.apiService.post(`player/choose-task/${id}/${position}`);
  }
}
