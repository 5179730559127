<div
  *ngIf="automaticCurrency || automaticProduct"
  class="building-productions"
>
  <div
    class="sub-title"
    [innerHTML]="groupType === BUILDING_GROUP_TYPE_DAILY_PRODUCTION ? 'Autoprodukcja <br> na dzień:' : 'Autoprodukcja <br> na godzinę:'"
  >
  </div>

  <m-ui-currency
    *ngIf="automaticCurrency"
    [item]="automaticCurrency"
    [stockView]="STOCK_VIEW.CUSTOM"
    [customIconSize]="'big'"
    [customClassStock]="'stock-san-a'"
    [lack]="false"
  ></m-ui-currency>

  <m-ui-product
    *ngIf="automaticProduct"
    [item]="automaticProduct"
    [stockView]="STOCK_VIEW.CUSTOM"
    [customIconSize]="'big'"
    [customClassStock]="'stock-san-a'"
    [lack]="false"
  ></m-ui-product>
</div>
