import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SynchronizeTimeService } from '../core/providers/synchronize-time.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { PlayerService } from '../modules/player/providers/player.service';


@Injectable({
  providedIn: 'root'
})
export class NewEditionGuard implements CanActivate {
  engGameDateMoment: Moment = moment('2050-01-31T23:59:59+01:00');

  constructor(
    private router: Router,
    private synchronizeTimeService: SynchronizeTimeService,
    private playerService: PlayerService,
  ) {
  }

  isEndGame() {
    const currentDate: Moment = moment(this.playerService.player.real_time);
    return currentDate.isAfter(this.engGameDateMoment);
  }

  canActivate(): boolean {
    if (this.isEndGame()) {
      this.router.navigate(['/auth/endgame']);
    } else {
      return true;
    }
  }
}
