import { PAGINATION_TYPES } from "../../../../../const/custom/pagination-types.const";
import { RANK_NAME } from "../../../../../const/custom/rank-name.const";
import { TabTop } from "../interfaces/tab-top.interface";

export const TABS_CC: TabTop[] = [
  {
    label: "Rankingi biznesowe",
    data: "business",
    index: RANK_NAME.BUSINESS,
    pagination: PAGINATION_TYPES.MONTH,
    visible: true,
    color: "green",
    other: { cc: true },
    gui_unlocks: "rank_tab_business",
  },
  {
    label: "Świat gry",
    data: "game-ranks",
    index: RANK_NAME.GAME,
    pagination: PAGINATION_TYPES.MONTH,
    visible: true,
    color: "orange",
    other: { cc: true },
    gui_unlocks: "rank_tab_game",
  },
];
