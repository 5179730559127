import { LeagueColorObject } from '../../interfaces/custom/league-color-object.interface';
import * as R from 'ramda';

export class BusinessLeagueColor {
  leaguesObject: LeagueColorObject[] = [
    {name: 'Srebrna', icon: 'silver', colorString: '#9E9E9E', color: 1},
    {name: 'Złota', icon: 'gold', colorString: '#FDA936', color: 2},
    {name: 'Diamentowa', icon: 'diamond', colorString: '#0085BF', color: 3},
    {name: 'Brylantowa', icon: 'brilliance', colorString: '#CE3B43', color: 4},
    {name: 'Perłowa', icon: 'pearl', colorString: '#825731', color: 5},
  ];

  getLeagueObject(color): LeagueColorObject {
    color = +color;

    const colorObjectDefault: LeagueColorObject = {name: 'undefined', icon: null, colorString: null, color: null};
    const colorObjectResult = this.leaguesObject.find((item) => item.color === color);

    return R.clone(colorObjectResult || colorObjectDefault);
  }
}
