<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="closeOrCloseAll()"
  ></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button
    [preventDefault]="true"
    (clicked)="close()"
    *ngIf="messageOpenDetailsData.fromList"
  ></m-ui-back-button>

  <div class="chest-success-container" *ngIf="message">

    <!-- REWARD IMAGE -->
    <div class="message-chest-image">
      <img [src]="'generic_chest_empty.png' | asset:'ilustration'"/>
    </div>

    <!-- TEASER -->
    <div class="teaser" [innerHTML]="message.teaser | safe:'html'"></div>

    <!-- DESCRIPTION -->
    <div class="inner-html-description" [innerHTML]="message.description | safe:'html'"></div>
  </div>

  <!-- MESSAGE TRASH -->
  <button class="c-square small" *ngIf="message && !message.deleted" matTooltip="Przenieś do archiwum">
    <i
      class="fas fa-archive trash-icon"
      (click)="moveToTrash()"
    ></i>
  </button>
</div>
