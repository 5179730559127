import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";

import { GameService } from "../../../../game/services/game.service";
import { GameParams } from "../../../interfaces/game-params";
import { CB_EDITABLE, CB_GAME_HEIGHT, CB_GAME_WIDTH } from "../../catch-bananas.constans";
import { CatchBananasGame } from "../../classes/CatchBananasGame";
import { CatchBananasPlayScene } from "../../scenes/CatchBananasPlay.scene";
import { CatchBananasService } from "../../services/catch-bananas.service";
import ScaleModes = Phaser.Scale.ScaleModes;

@Component({
  selector: "app-minigame-catch-bananas",
  templateUrl: "./minigame-catch-bananas.component.html",
})
export class MinigameCatchBananasComponent implements AfterViewInit, OnDestroy {
  @Input() parameters: GameParams;
  @Output() fromGameEvent = new EventEmitter<any>();
  game: CatchBananasGame;
  editMode = Boolean(location.search.includes("edit-mode"));

  constructor(private gameService: GameService, private catchBananasService: CatchBananasService, private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.gameService.pauseGame();

    this.game = new CatchBananasGame({
      parent: "minigame-catch-bananas",
      scene: CatchBananasPlayScene,
      gameService: this.gameService,
      catchBananasService: this.catchBananasService,
      width: CB_GAME_WIDTH,
      height: CB_GAME_HEIGHT,
      editMode: false,
      gameParams: this.parameters,
      backgroundColor: "#4488AA",
      loader: {
        // @ts-ignore
        imageLoadType: "HTMLImageElement",
        crossOrigin: "anonymous"
      },
      images: {
        default: 'assets/phaser/default.png',
        missing: 'assets/phaser/missing.png',
        white: 'assets/phaser/white.png'
      },
      physics: {
        default: "arcade",
        arcade: {
          gravity: { y: CB_EDITABLE.CB_GRAVITY },
          debug: false,
        },
      },
      scale: {
        mode: ScaleModes.FIT,
      },
    });
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.game.destroy(true);
    this.gameService.resumeGame();
  }
}
