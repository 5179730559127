export const CUSTOM_TILE_MAP_WIDTH = null;
export const CUSTOM_TILE_MAP_HEIGHT = null;
export const CUSTOM_TILE_WIDTH = 200;
export const CUSTOM_TILE_HEIGHT = 116;

export const CUSTOM_BOARD_TILE_SIZE = 1;
export const CUSTOM_BOARD_TILE_WIDTH = CUSTOM_TILE_WIDTH * CUSTOM_BOARD_TILE_SIZE;
export const CUSTOM_BOARD_TILE_HEIGHT = CUSTOM_TILE_HEIGHT * CUSTOM_BOARD_TILE_SIZE;

export const CUSTOM_TILE_TOOLTIP_OFFSET = {
  "4x1": {
    x: 0,
    y: -300,
  },
  "4x2": {
    x: 0,
    y: -150,
  },
  "4x4": {
    x: 0,
    y: -370,
  },
  "5x2": {
    x: 0,
    y: -220,
  },
  "8x4": {
    x: 100,
    y: -700,
  },
};

// buildings anchors(timer + arrow up), value should be -200 y.axis compared to game.constans.ts TILE_MENU_POSITION_OFFSETS_BY_BUILDING_ICON
export const CUSTOM_TOOLTIP_OFFSET_BY_BUILDING_ICON = {
  mudslide_2: {
    x: 0,
    y: -400,
  },
  mudslide_3: {
    x: 350,
    y: -600,
  },
  mudslide_8: {
    x: 0,
    y: -300,
  },
  mudslide_10: {
    x: 0,
    y: -300,
  },
  plantation_herbs_1: {
    x: 0,
    y: -300,
  },
  plantation_herbs_2: {
    x: 0,
    y: -300,
  },
};

export const CUSTOM_TILE_HOVER_TYPES = {
  "1x1": "ground_zero-1.png",
  "2x2": "ground_zero-1.png",
  "3x2": "ground_zero-1.png",
  "1x3": "ground_zero-1.png",
  "3x1": "ground_zero-1.png",
  "4x4": "ground_zero-1.png",
  "8x4": "ground_zero-1.png",
};
export const CUSTOM_TILE_HOVER_TYPE_ANCHORS = {
  "4x4": { x: 0.5, y: 1 },
};
export const CUSTOM_TILE_TYPE_POLYGON = {
  "1x1": `0 0 ${-CUSTOM_BOARD_TILE_WIDTH / 2} ${-CUSTOM_BOARD_TILE_HEIGHT / 2} 0 ${-CUSTOM_BOARD_TILE_HEIGHT} ${
    CUSTOM_BOARD_TILE_WIDTH / 2
  } -${CUSTOM_BOARD_TILE_HEIGHT / 2}`,
  "1x3": `0 0 ${-CUSTOM_BOARD_TILE_WIDTH / 2} ${-CUSTOM_BOARD_TILE_HEIGHT / 2} ${CUSTOM_BOARD_TILE_WIDTH} ${
    -CUSTOM_BOARD_TILE_HEIGHT * 2
  } ${CUSTOM_BOARD_TILE_WIDTH * 1.5} -${CUSTOM_BOARD_TILE_HEIGHT * 1.5}`,
  "2x2": `0 0 ${-CUSTOM_BOARD_TILE_WIDTH} ${-CUSTOM_BOARD_TILE_HEIGHT} 0 ${
    -CUSTOM_BOARD_TILE_HEIGHT * 2
  } ${CUSTOM_BOARD_TILE_WIDTH} -${CUSTOM_BOARD_TILE_HEIGHT}`,
  "4x4": `0 0 ${-CUSTOM_BOARD_TILE_WIDTH * 2} ${-CUSTOM_BOARD_TILE_HEIGHT * 2} 0 ${-CUSTOM_BOARD_TILE_HEIGHT * 4} ${
    CUSTOM_BOARD_TILE_WIDTH * 2
  } -${CUSTOM_BOARD_TILE_HEIGHT * 2}`,
  "4x1": `0 ${-CUSTOM_BOARD_TILE_HEIGHT * 2.5} ${-CUSTOM_BOARD_TILE_WIDTH / 2} ${-CUSTOM_BOARD_TILE_HEIGHT * 2} 0 ${-CUSTOM_BOARD_TILE_HEIGHT * 1.5} ${
    CUSTOM_BOARD_TILE_WIDTH / 2
  } -${CUSTOM_BOARD_TILE_HEIGHT * 2}`,
  "5x2": `0 0 ${-CUSTOM_BOARD_TILE_WIDTH} ${-CUSTOM_BOARD_TILE_HEIGHT} 0 ${
    -CUSTOM_BOARD_TILE_HEIGHT * 2
  } ${CUSTOM_BOARD_TILE_WIDTH} -${CUSTOM_BOARD_TILE_HEIGHT}`,
  "5x3": `0 ${-CUSTOM_BOARD_TILE_HEIGHT * 2.5} ${-CUSTOM_BOARD_TILE_WIDTH / 2} ${-CUSTOM_BOARD_TILE_HEIGHT * 2} 0 ${-CUSTOM_BOARD_TILE_HEIGHT * 1.5} ${
    CUSTOM_BOARD_TILE_WIDTH / 2
  } -${CUSTOM_BOARD_TILE_HEIGHT * 2}`,
  "3x1": `0 0 ${-CUSTOM_BOARD_TILE_WIDTH * 1.5} ${-CUSTOM_BOARD_TILE_HEIGHT * 1.5} ${-CUSTOM_BOARD_TILE_WIDTH} ${
    -CUSTOM_BOARD_TILE_HEIGHT * 2
  } ${CUSTOM_BOARD_TILE_WIDTH / 2} -${CUSTOM_BOARD_TILE_HEIGHT / 2}`,
  "3x2": `0 0 ${-CUSTOM_BOARD_TILE_WIDTH * 1.5} ${-CUSTOM_BOARD_TILE_HEIGHT * 1.5} ${-CUSTOM_BOARD_TILE_WIDTH / 2} ${
    -CUSTOM_BOARD_TILE_HEIGHT * 2.5
  } ${CUSTOM_BOARD_TILE_WIDTH} -${CUSTOM_BOARD_TILE_HEIGHT}`,
  "2x1": `0 0 ${-CUSTOM_BOARD_TILE_WIDTH} ${-CUSTOM_BOARD_TILE_HEIGHT} ${-CUSTOM_BOARD_TILE_WIDTH / 2} ${-CUSTOM_BOARD_TILE_HEIGHT * 1.5} ${
    CUSTOM_BOARD_TILE_WIDTH / 2
  } -${CUSTOM_BOARD_TILE_HEIGHT / 2}`,
  "8x4": `100 100 ${-CUSTOM_BOARD_TILE_WIDTH * 3.5} ${-CUSTOM_BOARD_TILE_HEIGHT * 3.5} 100 ${-CUSTOM_BOARD_TILE_HEIGHT * 7 - 100} ${
    CUSTOM_BOARD_TILE_WIDTH * 3.5 + 200
  } -${CUSTOM_BOARD_TILE_HEIGHT * 3.5 - 100}`,
};
export const CUSTOM_TILE_MENU_POSITION_OFFSETS = {};
export const CUSTOM_BUILDINGS_LEVEL_COLOR = {};
export const BUILDING_GROUP_TYPE_ANIMAL_TRACKING = "animal-tracking";
export const BUILDING_GROUP_TYPE_ANIMAL_TRACKING_FINISHED = "special";
export const BUILDING_GROUP_TYPE_INFO_PROBLEM = "info-problem";

export const BUILDING_GROUP_TYPE_ANTYMINER = "antiminer";
export const BUILDING_GROUP_TYPE_ANTYMINER_FINISHED = "special"; // TODO confirm
