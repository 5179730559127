<div class="container-bg"></div>

<div class="auth-container h-const">
  <div class="inner">
    <div class="logo-wrapper"></div>

    <div class="auth-form-wrapper">
      <loading></loading>
      <app-auth-info></app-auth-info>

      <div class="loading-opacity">
        <p class="form-title">Rejestracja</p>

        <ng-container [ngSwitch]="activeTemplate">
          <ng-container
            *ngSwitchCase="TEMPLATE.REGISTRATION_VERIFY_FORM"
            [ngTemplateOutlet]="registrationVerifyFormTpl"
          ></ng-container>

          <ng-container
            *ngSwitchCase="TEMPLATE.REGISTRATION_VERIFY_WRONG_DATA"
            [ngTemplateOutlet]="registrationVerifyWrongDataTpl"
          ></ng-container>

          <ng-container
            *ngSwitchCase="TEMPLATE.REGISTRATION_WRONG_DATA_SUCCESS"
            [ngTemplateOutlet]="registrationWrongDataSuccessTpl"
          ></ng-container>

          <ng-container
            *ngSwitchCase="TEMPLATE.REGISTRATION_VERIFY_SUCCESS"
            [ngTemplateOutlet]="registrationVerifySuccessTpl"
          ></ng-container>

          <ng-container
            *ngSwitchCase="TEMPLATE.REGISTRATION_SUCCESS"
            [ngTemplateOutlet]="registrationSuccessTpl"
          ></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- REGISTRATION_VERIFY_FORM -->
<ng-template #registrationVerifyFormTpl>
  <form [formGroup]="form" *ngIf="form">
    <!-- FIRST NAME -->
    <div class="control">
      <div class="default-field">
        <input
          class="control-input no-ow-input-directive"
          formControlName="firstName"
          type="text"
          placeholder="imię"
          id="name"
        >
        <label class="color-1" for="name">Imię</label>
      </div>
    </div>

    <!-- LAST NAME -->
    <div class="control">
      <div class="default-field">
        <input
          class="control-input no-ow-input-directive"
          formControlName="lastName"
          type="text"
          placeholder="nazwisko"
          id="surname"
        >
        <label class="color-1" for="surname">Nazwisko</label>
      </div>
    </div>

    <!-- EMAIL -->
    <div class="control">
      <div class="default-field">
        <input
          class="control-input no-ow-input-directive"
          formControlName="email"
          type="email"
          placeholder="e-mail"
          id="email"
        >
        <label class="color-2" for="email">E-mail (służbowy)</label>
      </div>
    </div>

    <!-- PHONE -->
    <div class="control">
      <div class="default-field">
        <input
          class="control-input no-ow-input-directive"
          formControlName="psn"
          type="text"
          placeholder="psn"
          id="psn"
        >
        <label class="color-3" for="psn">PSN</label>
      </div>
    </div>

    <!--AGREEMENT-->
    <div class="control">
      <label class="choose-wrapper">
        <span class="checkbox-wrapper">
          <input type="checkbox" formControlName="term1">
          <span class="checkmark"></span>
        </span>
        <span class="text-wrapper">
          <b>Chcę dołączyć do San Tao. Proszę o przesłanie pakietu informacji.</b>
        </span>
      </label>
    </div>
  </form>

  <div class="buttons">
    <!-- REGISTRATION BUTTON -->
    <button
      class="c-base c-primary full-width"
      (click)="registrationVerify()"
      [disabled]="form.invalid"
    >
      Wyślij
    </button>
  </div>
</ng-template>

<!-- REGISTRATION_VERIFY_WRONG_DATA -->
<ng-template #registrationVerifyWrongDataTpl>
  <div class="auth-default-text error m-top-35">
    Nie rozpoznaliśmy Twoich danych.
  </div>
  <div class="auth-default-text">
    Sprawdź ich poprawność:
  </div>
  <div class="auth-error-data">
    <div class="item">{{ form.value.firstName }}</div>
    <div class="item">{{ form.value.lastName }}</div>
    <div class="item">{{ form.value.email }}</div>
    <div class="item">PSN: {{ form.value.psn }}</div>
  </div>

  <button class="c-base c-primary full-width" (click)="changeTemplate(TEMPLATE.REGISTRATION_VERIFY_FORM)">
    Popraw dane
  </button>

  <div class="auth-contact-wrapper">
    <div class="auth-default-text">
      Jeśli dane są poprawne:
    </div>
    <a class="auth-contact-link" (click)="wrongData()">
      Zgłoś wniosek o weryfikację do Biura Głównego
    </a>
  </div>
</ng-template>

<!-- REGISTRATION_WRONG_DATA_SUCCESS -->
<ng-template #registrationWrongDataSuccessTpl>
  <div class="auth-big-text m-top-45">
    Dziękujemy
  </div>
  <div class="auth-default-text m-top-10 m-bottom-100">
    <b>Dane zostały wysłane do weryfikacji <br> w Biurze Głównym.</b>
    <br>
    Po pomyślnej weryfikacji uprawnień, w ciągu kilku dni roboczych otrzymasz emaila z informacją o dalszych krokach.
  </div>
  <a
    class="c-base c-primary default-form-link"
    [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.LOGIN"
  >
    <p>
      Powrót do logowania
    </p>
  </a>
</ng-template>

<!-- REGISTRATION_VERIFY_SUCCESS -->
<ng-template #registrationVerifySuccessTpl>
  <div class="auth-big-text m-top-45">
    Gratulacje!
  </div>

  <div class="auth-default-text">
    Twoje dane zostały poprawnie zweryfikowane. Zaakceptuj Regulamin aby dołączyć do społeczności San Tao.
  </div>

  <form [formGroup]="formFinal">
    <label class="choose-wrapper m-top-55">
    <span class="checkbox-wrapper">
      <input type="checkbox" name="checkbox" formControlName="term">
      <span class="checkmark"></span>
    </span>
      <span class="text-wrapper">
      Akceptuję postanowienia <a
        href="/files/terms"
        target="_blank"><b>Regulaminu Konkursu</b></a> oraz potwierdzam zapoznanie
      się z <a
        href="/files/gpdr"
        target="_blank"><b>Informacjami Dotyczącymi Ochrony Danych Osobowych.</b></a>
    </span>
    </label>
  </form>

  <div class="buttons">
    <!-- REGISTRATION BUTTON -->
    <button
      class="c-base c-primary full-width"
      (click)="registration()"
      [disabled]="formFinal.invalid"
    >
      Zarejestruj
    </button>
  </div>
</ng-template>

<!-- REGISTRATION_SUCCESS -->
<ng-template #registrationSuccessTpl>
  <div class="auth-medium-text m-bottom-20 m-top-35">
    WITAJ W SPOŁECZNOŚCI SAN TAO!
  </div>
  <div class="auth-default-text">
    <b>Sprawdź skrzynkę email i dokończ rejestrację.</b>
  </div>
  <div class="auth-default-text m-top-15">
    Wysłaliśmy do Ciebie wiadomość z jednorazowym linkiem aktywacyjnym. Użyj go w oknie przeglądarki Chrome i
    zaloguj się do San Tao!
  </div>
  <div class="auth-default-text m-bottom-55 m-top-15">
    Uwaga! W wyjątkowych przypadkach czas oczekiwania na emaila aktywacyjnego może wynieść 60 min.
  </div>
  <a
    class="c-base c-primary default-form-link"
    [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.LOGIN"
  >
    <p>
      Powrót do logowania
    </p>
  </a>
</ng-template>

<!-- APP HELP DESK INFO -->
<app-helpdesk-info></app-helpdesk-info>
