import { Component, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { NotificationsService } from "../../../../../../services/custom/notifications.service";
import { ApiMicroLeagueService } from "../../../../api/services/api-micro-league.service";
import { ConfirmAlertComponent } from "./confirm-alert/confirm-alert.component";
import { ContractClockPrizesComponent } from "./contract-clock-prizes/contract-clock-prizes.component";
import { ContractMaxPrizesComponent } from "./contract-max-prizes/contract-max-prizes.component";
import { PrizesAlertComponent } from "./prizes-alert/prizes-alert.component";

@Component({
  selector: "app-contract-sale",
  templateUrl: "./contract-sale.component.html",
})
export class ContractSaleComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiMicroLeagueService) apiMicroLeagueService: ApiMicroLeagueService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(NotificationsService) notificationsService: NotificationsService;
  @OwInject(MAT_DIALOG_DATA) data: { playerId?: number; branchName?: string };

  contracts: Contract[] = [];
  isLoading = true;
  branchName?: string;

  checkDateIsAfterNow(date: string): boolean {
    return moment(date).isSameOrAfter(new Date());
  }

  checkDateIsBeforeNow(date: string): boolean {
    return moment(date).isSameOrBefore(new Date());
  }

  confirmRules(microleagueId) {
    const contractToUpdate = this.findContractByMicroleagueId(microleagueId);
    const contractGroupToUpdate = this.findContractGroupByMicroleagueId(microleagueId);

    if (moment(contractToUpdate.rules_confirmation_deadline).isBefore(moment()) || contractToUpdate.is_confirmed) {
      return;
    }

    const confirmAndUpdateContract = () => {
      this.apiMicroLeagueService.confirmPlayerMicroLeagueRules(microleagueId, this.data?.playerId).subscribe(resp => {
        if (contractToUpdate) {
          contractToUpdate.is_confirmed = true;
          contractToUpdate.is_chosen = true;
          if (contractGroupToUpdate?.type === "package") {
            contractGroupToUpdate.package_resolved = true;
          }
        }
      });
    };

    if (contractGroupToUpdate.package_id) {
      const dialogRef = this.dialogService.open(ConfirmAlertComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          confirmAndUpdateContract();
        }
      });
    } else {
      confirmAndUpdateContract();
    }
  }

  findContractByMicroleagueId(microleagueId) {
    for (const contractItem of this.contracts) {
      for (const contract of contractItem.contracts) {
        if (contract.definition_id === microleagueId) {
          return contract;
        }
      }
    }
    return null;
  }

  findContractGroupByMicroleagueId(microleagueId) {
    for (const contractItem of this.contracts) {
      if (contractItem.contracts.some(contract => contract.definition_id === microleagueId)) {
        return contractItem;
      }
    }
    return null;
  }

  markChestOpened(microleagueId) {
    const contractToUpdate = this.findContractByMicroleagueId(microleagueId);

    this.apiMicroLeagueService.confirmPlayerMicroLeagueChest(microleagueId, this?.data?.playerId).subscribe(resp => {
      if (contractToUpdate) {
        contractToUpdate.is_chest_received = true;
      }
    });
  }

  ngOnInit() {
    this.getContracts(this.data?.playerId);
    this.branchName = this.data.branchName;
  }

  getContracts(playerId?: number) {
    this.apiMicroLeagueService.getPlayerContract(playerId).subscribe({
      next: resp => {
        this.contracts = resp as Contract[];
        this.determineContractType();
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  openInfo(desc) {
    this.dialogService.openAlert({
      description: desc,
    });
  }

  openContractPackageInfo(resolved) {
    this.dialogService.openAlert({
      description: resolved
        ? "WYBIERAJĄC KONTRAKT, KTÓRY CHCESZ REALIZOWAĆ WALCZYSZ O PEŁNĄ NAGRODĘ"
        : "WYBIERZ KONTRAKT, KTÓRY CHECESZ REALIZOWAĆ ABY WALCZYĆ O PEŁNĄ NAGRODĘ",
    });
  }

  determineContractType() {
    this.contracts.forEach(contract => {
      contract.type = "normal";
      if (contract.package_id) {
        contract.type = "package";
        contract.package_resolved = contract.contracts.some(contract => contract.is_chosen);
      }
      if (contract?.contracts?.[0]?.rules_confirmation_deadline === null) {
        contract.type = "legacy";
      }

      contract.contracts.forEach(contractItem => {
        contractItem.is_time_prize_available = contractItem.time_prizes.some(prize => prize.is_available);
      });
    });
  }

  openPrize(contract: ContractItem) {
    const { min_prizes, max_prizes } = contract;

    if (contract?.rules_confirmation_deadline === null) {
      let title;

      const prizes = {
        currency_prizes: [],
        product_prizes: [],
      };

      if (!contract.max_prizes) {
        title = "Brak nagród do zdobycia";
      } else {
        if (contract.max_prizes?.currency?.length) {
          prizes.currency_prizes.push(...contract.max_prizes.currency);
        }
        if (contract.max_prizes?.product?.length) {
          prizes.product_prizes.push(...contract.max_prizes.product);
        }
        title = "Nagrody do zdobycia";
      }

      this.dialogService.open(PrizesAlertComponent, {
        data: {
          prizes: prizes,
          title: title,
        },
      });

      return;
    }

    this.dialogService.open(ContractMaxPrizesComponent, {
      data: { min_prizes, max_prizes },
    });
  }

  openClockPrize(contract: ContractItem) {
    const timePrizes = contract.time_prizes;
    this.dialogService.open(ContractClockPrizesComponent, {
      data: { timePrizes },
    });
  }

  clearNotification() {
    if (this.playerService.player["gui_notifications"]?.["import_calculate_microleague_contract"] > 0) {
      this.notificationsService.setNotificationRead("import_calculate_microleague_contract").subscribe();
    }
  }

  ngOnDestroy() {
    this.clearNotification();
  }
}

export const PLAYER_SCORE = {
  ID: 16,
};

export interface Contract {
  package_id: number | null;
  contracts: ContractItem[];
  type?: "legacy" | "package" | "normal";
  package_resolved?: boolean;
}

export interface ContractItem {
  min_prizes: {
    currency?: Array<{
      currency_id: number;
      amount: number;
    }>;
    product?: Array<{
      product_id: number;
      amount: number;
    }>;
  };
  max_prizes: {
    currency?: Array<{
      currency_id: number;
      amount: number;
    }>;
    product?: Array<{
      product_id: number;
      amount: number;
    }>;
  };
  time_prizes?: TimePrize[];
  is_time_prize_available?: boolean;
  is_confirmed?: boolean;
  rules_confirmation_deadline?: string;
  chest_confirmation_deadline?: string;
  is_chosen?: boolean;
  is_combo?: boolean;
  is_chest_received?: boolean;
  definition_id: number;
  date_from: string;
  date_to: string;
  name: string;
  description: string;
  is_closed: boolean;
  is_definition_closed: boolean;
  my_position: number;
  values: string[];
  is_done: boolean;
  conditions: {
    name: string;
    values: string[];
    is_done: boolean;
  }[];
}

export type TimePrize = {
  available_amount: number;
  is_available: boolean;
  max_prizes: number;
  prize: {
    type: "currency" | "product";
    id: number;
    amount: number;
  };
  league_group_id: number;
};
