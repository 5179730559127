import { Component } from "@angular/core";
import { AbstractInjectBaseComponent } from "../../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../../core/decorators/ow-inject.decorator";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RankTask } from "../../../../../interfaces/custom/rank-task.interface";
import { OwDate } from "../../../../../../../../../core/utility/ow-date.class";
import { GAME_CONFIG } from "../../../../../../../../../core/config/custom/_parsed-game.config";
import { MyIdObject } from "../../../../../interfaces/custom/rank-business.interface";
import { LEVELS_BUSINESS_CC } from "./consts/levels-cc.const";
import { CARD_TEMPLATE } from "../../../../../../san-business/consts/custom/card-template.const";
import { RankLevel } from "../../../../../../../interfaces/custom/rank.interface";
import { Branch } from "../../../../../../../interfaces/branch.interface";

@Component({
  selector: "app-rank-business-details",
  templateUrl: "./rank-business-details.component.html",
})
export class RankBusinessDetailsComponent extends AbstractInjectBaseComponent {
  @OwInject(MAT_DIALOG_DATA) public data: {
    rank: RankTask;
    group: string;
    owDate: OwDate;
    myIdObject: MyIdObject;
    filters: any;
    branchName?: string;
    branch?: Branch;
  };

  isCC = GAME_CONFIG.IS_CC;
  levels: RankLevel[] = LEVELS_BUSINESS_CC;
  CARD_TEMPLATE = CARD_TEMPLATE;
}
