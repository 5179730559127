import { Component, OnInit } from "@angular/core";

import { CoreAbstractHistoryTransactionBaseComponent } from "../../../../abstract/core/abstract-history-transaction-base.component";
import {BasicActionsComponent} from '../../../../../san-business/custom/dialogs/basic-actions/basic-actions.component';

@Component({
  selector: "app-san-transaction-history",
  templateUrl: "./transaction-history.component.html",
})
export class TransactionHistoryComponent extends CoreAbstractHistoryTransactionBaseComponent implements OnInit {
  limit = 12;

  ngOnInit() {
    this.getCurrencyTransaction();
  }

  pageChange(page: number) {
    this.getCurrencyTransaction(page);
  }

  openBasicActions(date) {
    this.dialogService.open(BasicActionsComponent, {
      data: {
        date,
      },
    });
  }
}
