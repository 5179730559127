<div class="global-dialog ow-dialog c-window-long">

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>{{ (trashView ? 'Archiwum' : 'Wiadomości') }}</span>
      </div>
    </div>
  </div>

  <loading></loading>

  <!-- EXIT BUTTON -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button
    [preventDefault]="true"
    (clicked)="toggleTrash()"
    *ngIf="trashView"
  ></m-ui-back-button>

  <div class="navigate-pages" *ngIf="navigation.maxPage > 1">
    <!-- NEXT -->
    <button
      class="c-square small"
      (click)="pageChange(navigation.currentPage-1)"
      [disabled]="navigation.currentPage == 1"
    >
      <i class="fas fa-angle-left"></i>
    </button>

    <div class="pages">
      {{ navigation.currentPage }} z {{ navigation.maxPage }}
    </div>

    <!-- PREV -->
    <button
      class="c-square small"
      (click)="pageChange(navigation.currentPage+1)"
      [disabled]="navigation.currentPage == navigation.maxPage"
    >
      <i class="fas fa-angle-right"></i>
    </button>
  </div>

  <!-- CONTENT -->
  <div class="frame">

    <!-- TOP -->
    <div class="top">

      <!-- LEFT -->
      <div class="left">

        <!-- FILTER 1 -->
        <div class="role-select" *ngIf="filterCategory.values.length">
          <ng-select
            [items]="filterCategory.values"
            [bindLabel]="displayKeyCategory"
            [(ngModel)]="activeCategory"
            (change)="changeCategory($event)"
            [clearable]="false"
            [searchable]="false"
          ></ng-select>
        </div>

        <!-- FILTER 2 -->
        <div class="role-select" *ngIf="filterSender.values.length">
          <ng-select
            [items]="filterSender.values"
            [bindLabel]="displayKeySender"
            [(ngModel)]="activeSenders"
            (change)="changeSenders($event)"
            [clearable]="false"
            [searchable]="false"
            class="kek"
          ></ng-select>
        </div>
      </div>

      <!-- RIGHT -->
      <div class="right">
        <!-- ARCHIVE -->
        <div class="archive" (click)="toggleTrash()">
          <a class="archive" *ngIf="!trashView">Archiwum</a>
          <i class="fas fa-archive" *ngIf="!trashView"></i>
        </div>
      </div>
    </div>

    <!-- MESSAGES LIST -->
    <ng-container *ngIf="messages">
      <table class="messages loading-opacity">
        <thead>
        <tr>
          <th>Kategoria</th>
          <th>Nadawca</th>
          <th>Temat wiadomości</th>
          <th>Data</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr class="message" *ngFor="let message of messages" (click)="readMessage(message)"
            [class.readed]="message.read">

          <!-- CATEGORY -->
          <td class="td-category">
            {{ message.category_name }}
          </td>

          <!-- IMAGE -->
          <td class="td-sender">
            <img [src]="message.sender_icon + '.png' | asset:'senders/small'"/>
            {{ message.sender_name }}
          </td>

          <!-- TITLE -->
          <td class="td-title">
            {{ message.title }}
          </td>

          <!-- DATE -->
          <td class="td-date">
            {{ message.created_at | date:'yyyy-MM-dd  HH:mm' }}
          </td>

          <!-- TRASH ICON -->
          <td
            class="td-trash"
            *ngIf="!trashView && message.deletable"
            (click)="moveToTrash(message.player_message_id, $event)"
            matTooltip="Przenieś do archiwum"
          >
            <i class="fas fa-archive trash"></i>
          </td>

          <td class="td-trash empty" *ngIf="trashView"></td>
        </tr>
        </tbody>
      </table>


      <!-- NO MESSAGES -->
      <div class="no-item" *ngIf="messages.length == 0">
        {{ 'messages.empty-inbox' | myTranslate }}
      </div>
    </ng-container>
  </div>
</div>
