<div
  *ngIf="diffAutomaticProduction"
  class="other-container-diff"
>
  <div class="building-upgrade-diff-parameter">
    <div
      class="sub-title"
      [innerHTML]="groupType === BUILDING_GROUP_TYPE_DAILY_PRODUCTION ? 'Autoprodukcja <br> na dzień:' : 'Autoprodukcja <br> na godzinę:'"
    >
    </div>

    <div class="upgrade-container">
      <div class="upgrade-label">
        <m-ui-currency
          *ngIf="upgradeAutomaticCurrency"
          [item]="upgradeAutomaticCurrency"
          [stockView]="STOCK_VIEW.CUSTOM"
          [customIconSize]="'big'"
          [customClassStock]="'stock-san-a'"
          [lack]="false"
          [hideValue]="true"
        ></m-ui-currency>

        <m-ui-product
          *ngIf="upgradeAutomaticProduct"
          [item]="upgradeAutomaticProduct"
          [stockView]="STOCK_VIEW.CUSTOM"
          [customIconSize]="'big'"
          [customClassStock]="'stock-san-a'"
          [lack]="false"
          [hideValue]="true"
        ></m-ui-product>

        <span>
          {{ diffAutomaticProduction.normal }}
        </span>

        <div class="upgrade-value-container has-normal">
          <div class="upgrade-arrow"></div>

          <span
            class="upgrade"
            [class.quantity-diff]="diffAutomaticProduction.parameters.diff >= 0"
            [class.quantity-diff-minus]="diffAutomaticProduction.parameters.diff < 0"
          >
            {{ diffAutomaticProduction.upgrade }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
