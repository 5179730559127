<div class="global-dialog ow-dialog c-window-short">

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Podgląd graczy</span>
      </div>
    </div>
  </div>

  <loading></loading>

  <div class="navigate-pages" *ngIf="navigation.maxPage > 1">
    <!-- NEXT -->
    <button
      class="c-square small"
      (click)="pageChange(navigation.currentPage-1)"
      [disabled]="navigation.currentPage == 1"
    >
      <i class="fas fa-angle-left"></i>
    </button>

    <div class="pages">
      {{ navigation.currentPage }} z {{ navigation.maxPage }}
    </div>

    <!-- PREV -->
    <button
      class="c-square small"
      (click)="pageChange(navigation.currentPage+1)"
      [disabled]="navigation.currentPage == navigation.maxPage"
    >
      <i class="fas fa-angle-right"></i>
    </button>
  </div>

  <div class="frame loading-opacity">
    <ow-perfect-scrollbar
      *ngIf="branches"
      [scrollGradient]="branches"
    >
      <div class="branches-list">
        <div
          class="item"
          *ngFor="let item of branches"
          (click)="openBranch(item)"
        >
          <div class="city">{{ item.city }}</div>
          <div class="branch">{{ item.name }}</div>
        </div>
      </div>
    </ow-perfect-scrollbar>
  </div>
</div>
