<div class="global-dialog ow-dialog c-window-short warehouse-details-container">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <ng-container *ngIf="product && transactionBuyForm && transactionSellForm">
    <div
      [class.text-error]="product['storage_capacity_usage'] === product['storage_capacity_max']"
      class="capacity-container"
      matTooltip="Pojemność (aktualna / maksymalna)"
      matTooltipPosition="left"
    >
      {{ product['storage_capacity_usage'] }} / {{ product['storage_capacity_max'] }}
    </div>

    <div class="frame loading-opacity">
      <!-- PRODUCT ICON -->
      <div class="box-icon">
        <img class="product-icon" [src]="product.iconUrlBig"/>
      </div>

      <!-- PRODUCT DETAILS NAME -->
      <div class="title-product">
        {{ product.name }}
      </div>

      <!-- PRODUCT BALANCE -->
      <div class="balance">
        {{ product.balance | NumberWithSpaces }}
      </div>

      <!-- PRODUCT DESCRIPTION -->
      <div
        class="text"
        [innerHTML]="product.description"
        owEllipsis
      ></div>

      <div class="transaction-container">
        <div class="transaction-inner">

          <!-- BUY TRANSACTION -->
          <div class="column" *ngIf="product.buyable">
            <div class="error-capacity">
              &nbsp;
              <ng-container
                *ngIf="!transactionBuyForm.controls['amount'].errors?.required && !transactionBuyForm.controls['amount'].errors?.pattern && product['storage_capacity_usage'] + transactionBuyForm.controls['amount'].value > product['storage_capacity_max']"
              >
                Za mało miejsca w magazynie (<span
                class="text-error">{{ product['storage_capacity_usage'] + transactionBuyForm.controls['amount'].value }}</span>
                / {{  product['storage_capacity_max'] }})
              </ng-container>
            </div>
            <form [formGroup]="transactionBuyForm">
              <div class="control">
                <div class="default-field">
                  <input
                    class="control-input no-ow-input-directive"
                    formControlName="amount"
                    type="number"
                    placeholder="amount"
                    id="amount"
                  >
                  <label for="amount">Wpisz ilość</label>
                </div>
              </div>
            </form>
            <div class="operation">
              <ng-container
                [ngTemplateOutlet]="currencyAmountTpl"
                [ngTemplateOutletContext]="{amount: product.buying_price, lack: false}"
              ></ng-container>
              &nbsp;&nbsp;x
              <m-ui-product
                [item]="product"
                [stockView]="STOCK_VIEW.E"
                [lockOpenStorage]="true"
                [lack]="false"
                [hideValue]="true"
              ></m-ui-product>
              {{ !(transactionBuyForm.controls['amount'].errors?.required || transactionBuyForm.controls['amount'].errors?.pattern) ? transactionBuyForm.controls['amount'].value : 0 }}
              &nbsp;&nbsp;=
              <ng-container
                [ngTemplateOutlet]="currencyAmountTpl"
                [ngTemplateOutletContext]="{amount: product.buying_price * (transactionBuyForm.controls['amount'].errors?.pattern ? 0 : transactionBuyForm.controls['amount'].value), lack: true, transaction: this.TRANSACTION.BUY}"
              ></ng-container>
            </div>

            <button
              class="c-base c-secondary"
              (click)="transaction(TRANSACTION.BUY, transactionBuyForm.controls['amount'].value)"
              [disabled]="transactionBuyForm.invalid"
            >
              Kup
            </button>
          </div>

          <!-- SELL TRANSACTION -->
          <div class="column" *ngIf="product.sellable">
            <div class="error-capacity">
              &nbsp;
              <ng-container
                *ngIf="!transactionSellForm.controls['amount'].errors?.required && !transactionSellForm.controls['amount'].errors?.pattern && transactionSellForm.controls['amount'].value - product['storage_capacity_usage'] > 0"
              >
                Nie posiadasz tak dużej ilości
              </ng-container>
            </div>
            <form [formGroup]="transactionSellForm">
              <div class="control">
                <div class="default-field">
                  <input
                    class="control-input no-ow-input-directive"
                    formControlName="amount"
                    type="number"
                    placeholder="amount2"
                    id="amount2"
                  >
                  <label for="amount2">Wpisz ilość</label>
                </div>
              </div>
            </form>
            <div class="operation">
              <ng-container
                [ngTemplateOutlet]="currencyAmountTpl"
                [ngTemplateOutletContext]="{amount: product.selling_price, lack: false}"
              ></ng-container>
              &nbsp;&nbsp;x
              <m-ui-product
                [item]="{product_id: product.product_id, amount: transactionSellForm.controls['amount'].value || 0}"
                [stockView]="STOCK_VIEW.E"
                [lockOpenStorage]="true"
                [lack]="true"
                [hideValue]="true"
                (itemChanged)="changeAmountSum($event, this.TRANSACTION.SELL)"
              ></m-ui-product>
              {{ !(transactionSellForm.controls['amount'].errors?.required || transactionSellForm.controls['amount'].errors?.pattern) ? transactionSellForm.controls['amount'].value : 0 }}
              &nbsp;&nbsp;=
              <ng-container
                [ngTemplateOutlet]="currencyAmountTpl"
                [ngTemplateOutletContext]="{amount: product.selling_price * (transactionSellForm.controls['amount'].errors?.pattern ? 0 : transactionSellForm.controls['amount'].value), lack: false}"
              ></ng-container>
            </div>

            <button
              class="c-base c-primary"
              (click)="transaction(TRANSACTION.SELL, transactionSellForm.controls['amount'].value)"
              [disabled]="transactionSellForm.invalid"
            >
              Sprzedaj
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!-- CURRENCY AMOUNT TEMPLATE -->
<ng-template #currencyAmountTpl let-amount="amount" let-lack="lack" let-transaction="transaction">
  <ng-container *ngIf="tradingCurrencyId; else noTradingCurrencyTpl">
    <m-ui-currency
      [item]="{currency_id: tradingCurrencyId, amount: amount}"
      [stockView]="STOCK_VIEW.E"
      [hideValue]="true"
      [observable]="true"
      [lack]="lack"
      (itemChanged)="changeAmountSum($event, transaction)"
    ></m-ui-currency>

    {{ amount }}
  </ng-container>
  <ng-template #noTradingCurrencyTpl>
    {{ amount }}$
  </ng-template>
</ng-template>
