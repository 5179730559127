import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiService } from "../../../../core/providers/api.service";
import { ApiGetMinigame } from "../../interfaces/api-minigame.interface";
import { RRStatus } from "../../riverride/interfaces/status.interface";

@Injectable({
  providedIn: "root",
})
export class ApiMinigameService {
  constructor(public apiService: ApiService) {}

  getCurrentUnfinishedGame() {
    return this.apiService.get(`minigame/unfinished`);
  }

  getUnfinished(gameId: number): Observable<RRStatus> {
    return this.apiService.get(`minigame/${gameId}/unfinished`);
  }

  getMiniGames(): Observable<ApiGetMinigame[]> {
    return this.apiService.get("minigame/all");
  }

  getMiniGameInfo(game_id): Observable<ApiGetMinigame> {
    return this.apiService.get(`minigame/${game_id}/info`);
  }

  finishGame(gameId: number, payload) {
    return this.apiService.post(`minigame/${gameId}/finish`, payload);
  }

  encodeData(data, token?) {
    const base = btoa(JSON.stringify(data));
    return encodeURIComponent(`${token}${base}`);
  }
}
