import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { STATUS } from "../../../../consts/custom/auction.const";
import { Auction } from "../../../../interfaces/custom/auction.interface";
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {UserSelectors} from '../../../../../../../../store/user';
import {AppState} from '../../../../../../../../store/state';
import {CurrencyBalanceDetails} from '../../../../../../../../core/interfaces/currency';

@Component({
  selector: "app-auction-card",
  templateUrl: "./auction-card.component.html",
})
export class AuctionCardComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(Store) store: Store<AppState>;
  @Input() auctions: Auction[];
  @Input() typeAuction?: number;
  @Input() isLoading?: boolean;
  @Output() openDetails = new EventEmitter();

  STOCK_VIEW = STOCK_VIEW;
  STATUS = STATUS;
  isLoadingAuctionsDone = false;
  interval = null;

  currencyAmount: {'pln': CurrencyBalanceDetails | null, 'cash': CurrencyBalanceDetails | null} = {
    pln: null,
    cash: null
  }
  subs: { user: Subscription } = {
    user: null
  }

  ngOnInit() {
    const auctionsWithInterval = this.auctions.filter(a => a.isTimeIntSet);

    if (auctionsWithInterval.length) {
      this.interval = setInterval(() => {
        this.isLoadingAuctionsDone = auctionsWithInterval.every(a => a.timer);

        if (this.isLoadingAuctionsDone) {
          clearInterval(this.interval);
        }
      }, 250);
    } else {
      this.isLoadingAuctionsDone = true;
    }

    this.subs.user = this.store.pipe(select(UserSelectors.selectUser)).subscribe(user => {
      this.currencyAmount.pln = user.currency_balances.find(x => x.key === 'pln');
      this.currencyAmount.cash = user.currency_balances.find(x => x.key === 'cash');
    });
  }

  trackItem(idx, item) {
    return item.timer;
  }

  openAuctionDetails(id) {
    this.openDetails.emit({ auction_id: id });
  }

  findBalanceByCurrencyId(currency_id: number) {
    return Object.values(this.currencyAmount).find(x => x?.currency_id === currency_id) ?? {};
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
