// @ts-nocheck
import {Component, OnInit} from '@angular/core';

import {OwInject} from '../../../../../../../../core/decorators/ow-inject.decorator';
import {LoadingService} from '../../../../../../../../core/providers/loading.service';
import {getToken} from '../../../../../../../../core/utility/token';
import {EVENT_DIALOGS_NAMES_QA} from '../../../../../qa/consts/core/event-dialogs/event-names.const';
import {AbstractMessageDetailsNormalComponent} from '../../../../abstract/core/abstract-message-details-normal.component';
import {STOCK_VIEW} from '../../../../../shared-ui/mobile/consts/stock-view.const';

@Component({
  selector: 'app-custom-message-details-normal',
  templateUrl: './message-details-normal.component.html',
})
export class MessageDetailsNormalComponent extends AbstractMessageDetailsNormalComponent implements OnInit {
  @OwInject(LoadingService) loadingService: LoadingService;
  isImpersonate: boolean = getToken().is_impersonate;
  alternativeView: boolean = false;

  ngOnInit() {
    this.afterInit();
    if (this.message.parameters?.currencies?.length || this.message.parameters?.products?.length) {
      this.alternativeView = true;
    }
  }

  openQaList() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA.QA_LIST,
      config: {
        data: {
          eachQaId: this.message.qa_info.qa_id,
          isShowBackButton: true,
        },
      },
    });
  }

  async setRead() {
    if (!this.message.read) {
      this.loadingService.show();
    }

    await super.setRead();
  }

  afterRequestRead() {
    this.message.read = true;
    this.loadingService.hide();
  }

  protected readonly STOCK_VIEW = STOCK_VIEW;
}
