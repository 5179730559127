<div class="container-bg"></div>

<div class="auth-container">
  <div class="inner">
    <div class="logo-wrapper"></div>

    <div class="auth-form-wrapper">
      <loading></loading>
      <app-auth-info></app-auth-info>

      <div class="loading-opacity">
        <!--TITLE-->
        <p class="form-title">
          Resetowanie hasła
        </p>
        <ng-container *ngIf="formSendSuccess; else formTpl">
          <!-- SEND SUCCESS INFO -->
          <p class="auth-default-text" [innerHTML]="'auth.forgot-password.send-success-info' | myTranslate"></p>
        </ng-container>
        <ng-template #formTpl>
          <form [formGroup]="form">
            <!-- EMAIL FORM -->
            <div class="control">
              <div class="default-field">
                <input class="control-input no-ow-input-directive" formControlName="email" type="email"
                       placeholder="email" id="email">
                <label for="email">Email (służbowy)</label>
                <div class="input-icon">
                </div>
              </div>
            </div>
          </form>
        </ng-template>

        <div class="buttons">
          <button
            class="c-base c-primary full-width"
            (click)="onFormSubmit()"
            [disabled]="form.invalid"
            *ngIf="!formSendSuccess"
          >
            Wyślij
          </button>

          <a
            class="c-base c-primary default-form-link"
            [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.LOGIN"
          >
            <p>
              Powrót do logowania
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- APP HELP DESK INFO -->
<app-helpdesk-info></app-helpdesk-info>
