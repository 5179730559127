<div *ngIf="true" class="dashboard" (click)="openDashboard()">
  <img [src]="'business.png' | asset: 'ui'" alt="" class="icon-business" />

  <button class="dashboard-button" (click)="openDashboard()">Centrum danych</button>

  <div class="notification-box">
    <div [matTooltipShowDelay]="400" [matTooltip]="'Pojawiło się coś nowego!'">
      <div
        *ngIf="
          playerService.player['gui_notifications']?.['business_event'] > 0 ||
          (playerService.player.title_id.playable && playerService.player['gui_notifications']?.['basic_action'] > 0) ||
          playerService.player['gui_notifications_list']?.['processing_achievement']
        "
        class="app-notification dot-red"></div>
    </div>

    <div [matTooltipShowDelay]="400" [matTooltip]="'Zaktualizowaliśmy dane biznesowe.'">
      <div
        *ngIf="
          (playerService.player.title_id.playable && playerService.player['gui_notifications']?.['import_calculate_basic_actions'] > 0) ||
          playerService.player['gui_notifications']?.['import_calculate_leagues'] > 0 ||
          playerService.player['gui_notifications']?.['import_calculate_quality'] > 0 ||
          playerService.player['gui_notifications']?.['import_calculate_ranks'] > 0 ||
          playerService.player['gui_notifications']?.['import_calculate_tasks'] > 0 ||
          playerService.player['gui_notifications']?.['import_calculate_microleague'] > 0 ||
          playerService.player['gui_notifications']?.['import_calculate_microleague_contract'] > 0
        "
        class="app-notification dot-blue"></div>
    </div>
  </div>
</div>
<div
  *ngIf="playerService.player.title_id.playable && playerService.player['gui_notifications']?.['import_dashboard_prize'] > 0"
  class="dashboard-prize"></div>

<div
  (click)="openWF()"
  class="wf"
  [matTooltipShowDelay]="400"
  [matTooltip]="'DUETY - rozgrywka finałowa'"
  *ngIf="playerService.player.title_id.playable && playerService.player.gui_unlocks['wf_entry']"
>
  <img [src]="'wf_button.png' | asset: 'ui'" alt="" class="icon-business"/>
</div>
