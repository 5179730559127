<div class="ow-dialog quality-info">
  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="close()"
  ></m-ui-close-button>

  <div class="frame">
    <div class="container" [class.padding-top]="data.quarter">
      <div class="info" *ngIf="data.quarter">
        Wartość w polu „Wpływ na wynik ligowy” jest średnią potrąceń z 3 miesięcy tego kwartału.<br>
        Potrącenie w każdym miesiącu wynika z poziomu wykonania zadania i wyliczone jest według poniższego:
      </div>

      <table class="data-table">
        <thead>
        <tr class="th-main">
          <th colspan="2">Wykonanie</th>
          <th colspan="2" *ngIf="!data.quarter">Mnożnik</th>
          <th colspan="2">Wpływ na wynik ligowy</th>
        </tr>
        <tr>
          <th>od (włącznie)</th>
          <th>do</th>
          <ng-container *ngIf="!data.quarter">
            <th>Złote talary</th>
            <th>Banknoty</th>
          </ng-container>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let scope of data.taskScopes">
          <td>{{ scope.from }}</td>
          <td>{{ scope.to }}</td>
          <td *ngIf="!data.quarter">{{ scope.money_percent | percent:'1.2-2'}}</td>
          <td *ngIf="!data.quarter">{{ scope.material_percent | percent:'1.2-2' }}</td>
          <td>{{ scope['league_percent'] | percent:'1.2-2' }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
