export function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

export const isEqual = (a, b) => {
  if (a === b) {
    return true;
  }
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  }
  if (!a || !b || (typeof a !== "object" && typeof b !== "object")) {
    return a === b;
  }
  if (a === null || a === undefined || b === null || b === undefined) {
    return false;
  }
  if (a.prototype !== b.prototype) {
    return false;
  }
  const keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) {
    return false;
  }
  return keys.every(k => isEqual(a[k], b[k]));
};
export function deepClone(item) {
  if (item === null || typeof item !== 'object') {
    return item;
  }

  let clone;

  if (Array.isArray(item)) {
    clone = [];
    for (let i = 0; i < item.length; i++) {
      clone[i] = deepClone(item[i]);
    }
    return clone;
  }

  if (item instanceof Date) {
    return new Date(item.getTime());
  }

  if (item instanceof Map) {
    clone = new Map();
    item.forEach((value, key) => {
      clone.set(key, deepClone(value));
    });
    return clone;
  }

  if (item instanceof Set) {
    clone = new Set();
    item.forEach((value) => {
      clone.add(deepClone(value));
    });
    return clone;
  }

  if (item instanceof Object) {
    clone = {};
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        clone[key] = deepClone(item[key]);
      }
    }
    return clone;
  }

  throw new Error('Unable to copy item! Its type is not supported.');
}
