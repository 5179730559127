import { Component, OnDestroy, OnInit } from "@angular/core";
import { select } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { ApiService } from "../../../../../../../../core/providers/api.service";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { selectGameBoardTile } from "../../../../../../../../store/game/selectors";
import { ProductionService } from "../../../../../../services/production.service";
import { AbstractBuildingInfoComponent } from "../../../../abstract/core/abstract-building-info.component";

@Component({
  selector: "app-san-building-efficiency",
  templateUrl: "./building-efficiency.component.html",
})
export class BuildingEfficiencyComponent extends AbstractBuildingInfoComponent implements OnInit, OnDestroy {
  @OwInject(ProductionService) productionService: ProductionService;
  @OwInject(ApiService) apiService: ApiService;

  costsRequirement: {
    reason: boolean;
    valid: boolean;
  };

  tile;
  actualCalcUsage;
  isBuildingDisaster: boolean;

  ngOnInit() {
    this.subscribeBoardTile();
    this.checkNotification();
  }

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameBoardTile, { playerTileId: this.data.playerTileId }),
        filter(state => !!state)
      )
      .subscribe(tile => {
        this.tile = tile;
        this.isBuildingDisaster = this.tile.player_building.player_building_disaster !== null;
        this.checkNotification();
        this.getPlayerBuildingDetails(this.tile.player_building.player_building_id);
      });
  }

  checkNotification() {
    if (this.tile.player_building.show_close_disaster_notification) {
      this.postNotificationSeen(this.tile.player_building.player_building_id).subscribe();
    }
  }

  maintenance() {
    this.patchBuildingRepair(this.tile.player_building.player_building_id).subscribe(() => {
      this.matDialogRef.close();
    });
  }

  checkCostsRequirements(event) {
    setTimeout(() => {
      this.costsRequirement = event.value;
    });
  }

  patchBuildingRepair(player_building_id: number): Observable<any> {
    return this.apiService.patch(`player-buildings/${player_building_id}/repair`);
  }

  postNotificationSeen(player_building_id: number): Observable<any> {
    return this.apiService.post(`player-buildings/${player_building_id}/notification-seen`);
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
