import { EventDialog } from "../../../../../../../core/interfaces/event-dialog.interface";
import {EVENT_DIALOGS_NAMES_MESSAGE} from '../../core/event-dialogs/event-names.const';
import {
  MessageDetailsProbabilityCompensationComponent
} from '../../../base/custom/dialogs/message-details-probability-compensation/message-details-probability-compensation.component';
import {EVENT_DIALOGS_NAMES_MESSAGE_CUSTOM} from './event-names.const';
import {MessagesComponent} from '../../../base/custom/dialogs/messages/messages.component';
import {MessageDetailsNormalComponent} from '../../../base/custom/dialogs/message-details-normal/message-details-normal.component';
import {MessageDetailsLevelUpComponent} from '../../../base/custom/dialogs/message-details-level-up/message-details-level-up.component';
import {MessageDetailsComicsComponent} from '../../../base/custom/dialogs/message-details-comics/message-details-comics.component';
import {
  MessageDetailsHiddenRewardComponent
} from '../../../base/custom/dialogs/message-details-hidden-reward/message-details-hidden-reward.component';
import {
  MessageDetailsChestSuccessComponent
} from '../../../base/custom/dialogs/message-details-chest-success/message-details-chest-success.component';
import {
  MessageDetailsChestFailedComponent
} from '../../../base/custom/dialogs/message-details-chest-failed/message-details-chest-failed.component';
import {MessageDetailsInfoProblemComponent} from '../../../base/custom/dialogs/message-details-info-problem/message-details-info-problem.component';

export const EVENT_DIALOGS_MESSAGE_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_MESSAGE.MESSAGES]: MessagesComponent,
  [EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_NORMAL]: MessageDetailsNormalComponent,
  [EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_LEVEL_UP]: MessageDetailsLevelUpComponent,
  [EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_COMICS]: MessageDetailsComicsComponent,
  [EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_HIDDEN_REWARD]: MessageDetailsHiddenRewardComponent,
  [EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_CHEST_SUCCESS]: MessageDetailsChestSuccessComponent,
  [EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_CHEST_FAILED]: MessageDetailsChestFailedComponent,
  [EVENT_DIALOGS_NAMES_MESSAGE_CUSTOM.MESSAGE_DETAILS_ANIMAL]: MessageDetailsInfoProblemComponent,
  [EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_PROBABILITY_COMPENSATION]: MessageDetailsProbabilityCompensationComponent,
};
