<div class="slider-games-container">
  <loading [classNames]="['white', 'stroke']" [size]="'big'"></loading>

  <m-ui-close-button (clicked)="close()"></m-ui-close-button>

  <div class="global-dialog loading-opacity" *ngIf="loaded">
    <!-- NAVIGATION CENTER -->
    <m-ui-navigation-center
      [hidePrev]="sliderActiveIndex <= 0"
      [hideNext]="sliderActiveIndex >= games.length - swiperConfig.slidesPerView"
      (prevClicked)="prevSlide()"
      (nextClicked)="nextSlide()"
    ></m-ui-navigation-center>

    <swiper-container
      #swiperRef
      swiperElement
      init="false"
      class="swiper"
      [config]="swiperConfig"
    >
      <swiper-slider *ngFor="let game of games | sortByOrder">
        <div class="game">
          <div class="ow-dialog c-window-b c-secondary">
            <img
              class="logo"
              [src]="game.icon | asset:game.iconPath"
            >

            <div class="costs-title">
              Koszt gry:
            </div>

            <m-ui-costs
              *ngIf="game.costs as costs"
              [products]="costs.products"
              [stockView]="STOCK_VIEW.CUSTOM"
              [observable]="true"
              [lack]="true"
              (eventEmitter)="checkCostsRequirements($event, game)"
              [customIconSize]="'big'"
              [customClassStock]="'stock-san-c increased-size'"
            ></m-ui-costs>

            <!-- STATS -->
            <!--          <div-->
            <!--            *ngIf="game.stats as stats"-->
            <!--            class="stats"-->
            <!--            matTooltip="Skrzynie zdobyte przez graczy w tym miesiącu / Moje zdobyte skrzynie w tym miesiącu"-->
            <!--            matTooltipShowDelay="300"-->
            <!--          >-->
            <!--            <img [src]="'chest.png' | asset:'products/basic'">-->
            <!--            {{ stats.chests_total_this_month }}/{{ stats.my_chests_this_month }}-->
            <!--          </div>-->

            <button
              class="c-base c-primary play-button"
              (click)="openGame(game)"
              [disabled]="!game.costs.canPlay"
            >
              Graj
            </button>

            <button
              class="terms"
              (click)="openAlertDialog(game)"
            >
              zasady gry
            </button>

            <!--          <button-->
            <!--            *ngIf="game.payment === GameModes.PREMIUM"-->
            <!--            class="rank"-->
            <!--            (click)="openMinigameRankListComponent(game)"-->
            <!--          >-->
            <!--            <i class="fas fa-chart-line"></i>-->
            <!--          </button>-->
          </div>
        </div>
      </swiper-slider>

    </swiper-container>

    <div class="resources-container">
      <ng-container *ngFor="let product of resources.products">
        <m-ui-product
          [item]="product"
          [stockView]="STOCK_VIEW.CUSTOM"
          [lack]="false"
          [customIconSize]="'big'"
          [customClassStock]="'stock-layout-e'"
          [showBalance]="true"
          [observable]="true"
        ></m-ui-product>
      </ng-container>
    </div>
  </div>
</div>
