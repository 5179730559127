import { Component, Input } from '@angular/core';
import { Player } from '../../../../../../player/interfaces/player';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { PreviewPlayerService } from '../../../../../game-gui/providers/preview-player.service';
import { WORLD_BOOT_SCENE } from '../../../../../game-engine/scenes-world/world.constants';
import { PlayerService } from '../../../../../../player/providers/player.service';
import { BASIC_BOOT_SCENE } from '../../../../../game-engine/scenes-basic/basic.constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../store/state';
import { DialogService } from '../../../../../../shared/providers/dialog.service';
import { GameService } from '../../../../../services/game.service';
import { GuiService } from '../../../../../services/gui.service';
import { ChangeSceneService } from '../../../../../../shared/services/custom/change-scene.service';

@Component({
  selector: 'app-hud-player-preview',
  templateUrl: './hud-player-preview.component.html',
})
export class HudPlayerPreviewComponent extends AbstractInjectBaseComponent {
  @OwInject(PreviewPlayerService) previewPlayerService: PreviewPlayerService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(GameService) gameService: GameService;
  @OwInject(GuiService) guiService: GuiService;
  @OwInject(ChangeSceneService) changeSceneService: ChangeSceneService;
  @Input() player: Player;

  WORLD_BOOT_SCENE = WORLD_BOOT_SCENE;
  BASIC_BOOT_SCENE = BASIC_BOOT_SCENE;
}
