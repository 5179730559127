import GameConfig = Phaser.Types.Core.GameConfig;
import { GameService } from "../../../game/services/game.service";
import { Match3Service } from "../match3.service";

export interface Match3GameConfig extends GameConfig {
  gameService: GameService;
  match3GameService: Match3Service;
  gameOptions: M3GameOptions;
  gameDefinitions: M3GameDefinitions;
}

export enum M3Direction {
  HORIZONTAL = 1,
  VERTICAL = 2,
}

export interface M3GameOptions {
  cols: number;
  rows: number;
  gemColors: number;
  gemSize: {
    width: number;
    height: number;
  };
  swapSpeed: number;
  fallSpeed: number;
  destroySpeed: number;
  showMoves?: boolean;
}

export interface M3GameDefinitions {
  levels: Level[];
}

export interface M3Color {
  color: string;
  bonus: boolean;
  points: number;
}

export interface Level {
  level: number;
  completed_points_bonus: number;
  moves: number;
  missions: LevelTarget[];
  bonus: LevelTarget;
  gemsNumber: number;
  rows: number;
  cols: number;
  colors: M3Color[];
}

export interface LevelTarget {
  progress?: number;
  color: string;
  target: number;
}

export const M3_GAME_DEF_MOCK: M3GameDefinitions = {
  levels: [
    {
      level: 1,
      moves: 20,
      completed_points_bonus: 100,
      missions: [
        {
          color: "2",
          target: 10,
        },
        {
          color: "4",
          target: 15,
        },
      ],
      colors: [
        {
          color: "1",
          bonus: false,
          points: 10,
        },
        {
          color: "2",
          bonus: false,
          points: 10,
        },
        {
          color: "3",
          bonus: false,
          points: 10,
        },
        {
          color: "4",
          bonus: false,
          points: 10,
        },
        {
          color: "5",
          bonus: false,
          points: 10,
        },
      ],
      bonus: {
        color: "4",
        target: 20,
      },
      gemsNumber: 6,
      rows: 5,
      cols: 5,
    },
    {
      level: 2,
      moves: 10,
      completed_points_bonus: 1000,
      missions: [
        {
          color: "1",
          target: 10,
        },
      ],
      bonus: {
        color: "3",
        target: 10,
      },
      gemsNumber: 8,
      rows: 5,
      cols: 3,
      colors: [
        {
          color: "1",
          bonus: false,
          points: 10,
        },
        {
          color: "2",
          bonus: false,
          points: 10,
        },
      ],
    },
  ],
};
