import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractInjectBaseComponent} from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import {OwInject} from '../../../../../../../../core/decorators/ow-inject.decorator';
import {DialogService} from '../../../../../../../shared/providers/dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BoardTileState} from '../../../../../../../../store/game/interfaces/board-tile.state';
import {BuildingsService} from '../../../../../../services/buildings.service';
import {unsubscribeObject} from '../../../../../../../../core/utility/unsubscribe-array';
import {Store} from '@ngrx/store';
import {selectPlayer} from '../../../../../../../../store/player/selectors';
import {WfPieceDetailsComponent} from '../wf-piece-details/wf-piece-details.component';
import {ProductDetails} from '../../../../../../../player/interfaces/product.interface';
import {selectPrimary} from '../../../../../../../../store/primary/selectors';
import {take} from 'rxjs/operators';

@Component({
  selector: "wf-piece-pairing",
  templateUrl: "./wf-piece-pairing.component.html"
})
export class WfPiecePairingComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data: { details: BoardTileState, playerId: number }
  @OwInject(BuildingsService) buildingService: BuildingsService;
  @OwInject(Store) store: Store;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<WfPiecePairingComponent>;
  isLoading = false;
  buildingDetails = null;
  upgradeBuildingDetails = null;
  products = [];
  isValid = false;
  pieceWithDetails: Product = null;

  subs = {
    products: null
  }

  ngOnInit() {
    this.combineBuildDetails();

    this.subs.products = this.store.select(selectPlayer).subscribe(res => {
      this.products = res.product_balances;
      this.validatePairingRequirements();
    })
  }

  combineBuildDetails() {
    this.isLoading = true;

    this.buildingService.getBuildingDetails(this.data.details.player_building.upgrade_building_id).subscribe(res => {
      this.upgradeBuildingDetails = res;
      this.validatePairingRequirements();
      this.getPieceDetails();
    })
  }

  pairBuilding() {
    this.isLoading = true;
    this.buildingService.upgrade(this.data.details.player_building.player_building_id, false).subscribe(res => {
      this.isLoading = false;
      this.closeActive(true);

    }, err => {
      this.isLoading = false;
    })
  }

  validatePairingRequirements() {
    if (this.upgradeBuildingDetails == null || !this.products.length) {
      this.isValid = false;
      return;
    }

    let isValid = true;
    for (let product of this.upgradeBuildingDetails.required_products) {
      const productBalance = this.products.find(x => x.product_id === product.product_id);

      if (!productBalance || productBalance.balance < product.amount) {
        isValid = false;
      }
    }

    this.isValid = isValid;
  }

  // gets the piece information so it can be opened in another window
  getPieceDetails() {
    this.store.select(selectPrimary).pipe(take(1)).subscribe(res => {
      this.pieceWithDetails = res.products.find(x => x.product_id === this.upgradeBuildingDetails.required_products[0]?.product_id);
    })
  }

  openPieceDetails() {
    if (!this.pieceWithDetails) {
      return;
    }

    this.dialogService.open(WfPieceDetailsComponent, {
      data: {
        product: this.pieceWithDetails,
        currency: null,
      }
    })
  }

  closeActive(isPaired: boolean) {
    this.matDialogRef.close({isPaired})
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
