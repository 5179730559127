import { Component, Input } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../../../core/decorators/ow-inject.decorator";
import { ApiBusinessLeagueService } from "../../../../../../api/services/api-business-league.service";
import { LeagueAchievementDetails } from "../../../../../../interfaces/custom/league-achievement.interface";

@Component({
  selector: "app-micro-league-achievement-details",
  templateUrl: "./micro-league-achievement-details.component.html",
})
export class MicroLeagueAchievementDetailsComponent extends AbstractInjectBaseComponent {
  @OwInject(ApiBusinessLeagueService) apiBusinessLeagueService: ApiBusinessLeagueService;
  @Input() achievement: LeagueAchievementDetails;
  @Input() isLoading: boolean;
}
