<div class="global-dialog ow-dialog c-window-short user-details-container">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>
  <m-ui-back-button
    *ngIf="activeTemplate === TEMPLATE.PLAYER_DETAILS_EDIT"
    [preventDefault]="true"
    (clicked)="changeTemplate(TEMPLATE.PLAYER_DETAILS_INFO)"
  ></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Mój profil</span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="player && playerSettings" [ngSwitch]="activeTemplate">
    <ng-container
      *ngSwitchCase="TEMPLATE.PLAYER_DETAILS_INFO"
      [ngTemplateOutlet]="playerDetailsInfo"
    ></ng-container>

    <ng-container
      *ngSwitchCase="TEMPLATE.PLAYER_DETAILS_EDIT"
      [ngTemplateOutlet]="playerDetailsEdit"
    ></ng-container>
  </ng-container>

  <ng-template #playerDetailsInfo>
    <div class="info-wrapper loading-opacity" *ngIf="player">
      <div class="first-details-section">
        <div class="avatar-wrapper">
          <div class="avatar-default avatar">
            <i class="fas fa-user-circle"></i>
          </div>
          <div
            class="avatar-custom avatar"
            [style.background-image]="'url('+player.profile_image_url+')'"
          ></div>
          <input
            class="avatar-change"
            accept="image/x-png, image/gif, image/jpeg"
            type="file"
            (change)="changeImage($event)"
          >
        </div>

        <div class="name-wrapper">
          {{ player.first_name }} {{ player.last_name }}
        </div>
        <div class="position-wrapper">
          <span class="position-name">
            {{ player.title_id.name }}
          </span>
          <span class="position-location">
            {{ playerService.notDorOrDorNotActive ? player.branch_name : player['region_name'] }}
          </span>
        </div>

        <div class="role-wrapper">
          <div class="role-title">
            Gram jako:
          </div>
          <div class="role-select">
            <ng-select
              [items]="[{value: player.title_id.name}]"
              [bindLabel]="'value'"
              [(ngModel)]="playerName"
              [disabled]="true"
              [clearable]="false"
              [searchable]="false"
            ></ng-select>
          </div>
        </div>
      </div>

      <div class="second-details-section">
        <div class="frame frame-white">
          <button class="edit" (click)="changeTemplate(TEMPLATE.PLAYER_DETAILS_EDIT)">
            <i class="fas fa-edit"></i>
          </button>
          <div class="person-data">
            <div class="item" *ngIf="!isCC">
              <div class="name">
                Email Facebook:
              </div>
              <div class="value">
                {{ playerSettings.facebook_email || '-' }}
              </div>
            </div>
            <div class="item" *ngIf="!isCC">
              <div class="name">
                Nazwa profilu na Facebook:
              </div>
              <div class="value">
                {{ playerSettings.facebook_username || '-' }}
              </div>
            </div>
            <div class="item">
              <div class="name">
                Email służbowy:
              </div>
              <div class="value">
                {{ player.email || '-' }}
              </div>
            </div>
            <div class="item">
              <div class="name">
                Email prywatny:
              </div>
              <div class="value">
                {{ playerSettings.private_email || '-' }}
              </div>
            </div>
            <div class="item">
              <div class="name">
                Telefon komórkowy:
              </div>
              <div class="value">
                {{ playerSettings.business_phone || '-' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #playerDetailsEdit>
    <div class="edit-wrapper loading-opacity">
      <div class="form-wrapper">
        <form [formGroup]="formChangeSettings">
          <div class="frame">
            <div class="form-title">
              Informacje kontaktowe
            </div>
            <div class="control" *ngIf="!isCC">
              <div class="default-field">
                <input
                  class="control-input no-ow-input-directive"
                  formControlName="facebookEmail"
                  type="email"
                  placeholder="Email Facebbok"
                  id="facebookEmail"
                >
                <label for="facebookEmail">Email Facebook</label>
              </div>
            </div>
            <div class="control" *ngIf="!isCC">
              <div class="default-field">
                <input
                  class="control-input no-ow-input-directive"
                  formControlName="facebookUsername"
                  type="text"
                  placeholder="Nazwa profilu na Facebook"
                  id="facebookUsername"
                >
                <label for="facebookUsername">Nazwa profilu na Facebook</label>
              </div>
            </div>
            <div class="control">
              <div class="default-field">
                <input
                  class="control-input no-ow-input-directive"
                  formControlName="privateEmail"
                  type="text"
                  placeholder="Email prywatny"
                  id="privateEmail"
                >
                <label for="privateEmail">Email prywatny</label>
              </div>
            </div>
            <div class="control">
              <div class="default-field">
                <input
                  class="control-input no-ow-input-directive"
                  formControlName="businessPhone"
                  type="text"
                  placeholder="Telefon kontaktowy"
                  id="businessPhone"
                >
                <label for="businessPhone">Telefon kontaktowy</label>
              </div>
            </div>
          </div>
          <div class="buttons flex-center">
            <button
              type="submit"
              class="c-base c-primary full-width"
              (click)="postSettings()"
            >
              Zapisz
            </button>
          </div>
        </form>
      </div>
      <div class="form-wrapper">
        <form [formGroup]="formChangePassword">
          <div class="frame">
            <div class="form-title">
              Zmień hasło
            </div>
            <div class="control">
              <div
                class="default-field"
                [class.error]="formChangePassword.get('oldPassword').invalid && formChangePassword.get('oldPassword').dirty && formChangePassword.get('oldPassword').errors"
              >
                <input
                  class="control-input no-ow-input-directive"
                  formControlName="oldPassword"
                  type="password"
                  placeholder="Aktualne hasło"
                  id="oldPassword"
                >
                <label for="oldPassword">Aktualne hasło</label>
              </div>
            </div>
            <div class="control">
              <div
                class="default-field"
                [class.error]="formChangePassword.get('password').invalid && formChangePassword.get('password').dirty && formChangePassword.get('password').errors"
              >
                <input
                  class="control-input no-ow-input-directive"
                  formControlName="password"
                  type="password"
                  placeholder="Nowe hasło"
                  id="password"
                >
                <label for="password">Nowe hasło</label>
              </div>
            </div>
            <div class="control">
              <div
                class="default-field"
                [class.error]="(formChangePassword.get('passwordRepeat').invalid && formChangePassword.get('passwordRepeat').dirty && formChangePassword.get('passwordRepeat').errors) || (formChangePassword.get('password').value && formChangePassword.get('passwordRepeat').value && formChangePassword.hasError('notSame'))"
              >
                <input
                  class="control-input no-ow-input-directive"
                  formControlName="passwordRepeat"
                  type="password"
                  placeholder="Powtórz nowe hasło"
                  id="passwordRepeat"
                >
                <label for="passwordRepeat">
                  <ng-container
                    *ngIf="formChangePassword.get('password').value && formChangePassword.get('passwordRepeat').value && formChangePassword.hasError('notSame'); else defaultLabelTpl">
                    Podane hasła różnią się
                  </ng-container>
                  <ng-template #defaultLabelTpl>
                    Powtórz nowe hasło
                  </ng-template>
                </label>
              </div>
            </div>
          </div>
          <div class="buttons flex-center">
            <button
              type="submit"
              class="c-base c-primary"
              (click)="postPassword()"
              [disabled]="formChangePassword.invalid"
            >
              Zapisz
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

  <div class="logout" (click)="logout()">
    Wyloguj
  </div>
</div>


