import { Component } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";
import * as R from "ramda";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { GAME_CONFIG } from "../../../../../../../core/config/custom/_parsed-game.config";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { getToken } from "../../../../../../../core/utility/token";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { UserService } from "../../../../../../user/providers/user.service";
import { Branch } from "../../../../../interfaces/branch.interface";
import { STOCK_VIEW } from "../../../../shared-ui/mobile/consts/stock-view.const";
import { ApiBusinessCalendarService } from "../../../api/services/api-business-calendar.service";
import {
  BusinessCalendarItem,
  BusinessCalendarResponse,
  CalendarDate,
  DecoratedBusinessCalendarItem,
} from "../../../interfaces/custom/business-calendar.interface";
import { BusinessCalendarCloseComponent } from "./close/business-calendar-close.component";
import { BusinessCalendarDetailsComponent } from "./details/business-calendar-details.component";
@Component({
  selector: "app-business-calendar",
  templateUrl: "./business-calendar.component.html",
  styleUrls: ["./business-calendar.component.scss"],
})
export class BusinessCalendarComponent extends AbstractInjectBaseComponent {
  @OwInject(ApiBusinessCalendarService) apiBusinessCalendarService: ApiBusinessCalendarService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<BusinessCalendarComponent>;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(UserService) userService: UserService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) dialogData: {
    branch?: Branch;
    branchName?: string;
  };

  minRangeDate = new Date("2024-03-01");
  maxRangeDate = new Date("2025-01-31");
  allowPrev: boolean = true;
  allowNext: boolean = true;
  branchName?: string;
  managerPlayerId?: number;
  isManagerPlayerIdInEnabledPlayers?: boolean;
  isPlayerIdInEnabledPlayers?: boolean;
  isCC = GAME_CONFIG.IS_CC;
  isImpersonate: boolean = getToken().is_impersonate;

  alreadySentSeenIds: number[] = [];
  notSeenEventsOnNextPage: boolean = false;

  currentDate = new Date();
  datesToShow: CalendarDate[] = [];
  types: { label: string; className: BusinessCalendarItem["type"]; visible?: boolean; hidden?: boolean }[] = [
    { label: "Mikroliga", className: "microleague" },
    { label: "Sprzedaż kontraktowa", className: "contract" },
    { label: "Eventy", className: "event" },
    { label: "Inne", className: "other" },
  ];

  filteredEvents: { [key: string]: DecoratedBusinessCalendarItem[] };
  data: BusinessCalendarResponse;

  ngOnInit() {
    this.branchName = this.dialogData?.branchName;
    this.managerPlayerId = this.dialogData?.branch?.manager_player_id;
    this.isManagerPlayerIdInEnabledPlayers = this.userService.me.enabled_players.some(player => player.id === this?.managerPlayerId);
    this.isPlayerIdInEnabledPlayers = this.userService.me.enabled_players.some(
      player => player.id === this.playerService.getActivePlayerId()
    );
    this.getData(false, undefined, true);
    this.datesToShow = this.generateDateArray(this.currentDate);
  }

  isDuplicate(obj1, obj2) {
    return obj1.id === obj2.id && obj1.type === obj2.type;
  }

  getData(load?: boolean, date?: Date, init?: boolean) {
    this.apiBusinessCalendarService
      .businessCalendar(
        date ? date.getFullYear() : this.currentDate.getFullYear(),
        date ? date.getMonth() + 1 : this.currentDate.getMonth() + 1,
        this?.managerPlayerId
      )
      .subscribe(resp => {
        if (load) {
          if (this.data && this.data.data && resp && resp.data) {
            this.data.data = [...(this.data.data || []), ...(resp.data || [])];
            this.data.data = R.uniqWith(this.isDuplicate, this.data.data);
          }
        } else {
          this.data = resp;

          if (init) {
            this.getData(true, moment(this.currentDate).add(1, "month").toDate());
            this.getData(true, moment(this.currentDate).subtract(1, "month").toDate());
          }
        }
        this.filteredEvents = this.filterDataByCurrentDate(this?.data?.data || []);
      });
  }

  generateDateArray(startDate: Date): CalendarDate[] {
    const dateArray: CalendarDate[] = [];
    const weekdays = ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"];

    for (let i = -2; i <= 4; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);
      const dayName = weekdays[currentDate.getDay()];
      const isCurrentDate = moment(currentDate).isSame(new Date(), "day");
      dateArray.push({ date: currentDate, name: dayName, isCurrentDate });
    }

    return dateArray;
  }

  filterDataByCurrentDate(data: BusinessCalendarItem[]): { [key: string]: DecoratedBusinessCalendarItem[] } {
    const minDate = this.datesToShow[0].date;
    const maxDate = this.datesToShow[this.datesToShow.length - 1].date;
    const filteredData: { [key: string]: DecoratedBusinessCalendarItem[] } = {};

    this.notSeenEventsOnNextPage = this?.data?.data.some(event => moment(event.date_start).isAfter(moment(maxDate)) && !event.seen);

    this.types.forEach(i => {
      i.visible = false;
    });
    data.forEach(item => {
      const startDate = new Date(item.date_start);
      const endDate = new Date(item.date_end);
      const eventStartedEarlier = moment(startDate).isBefore(minDate, "day");
      const eventEndsLater = moment(endDate).isAfter(maxDate, "day");

      let startIndex = 1;
      let endIndex = 7;
      for (let i = 0; i < this.datesToShow.length; i++) {
        const date = new Date(this.datesToShow[i].date);
        if (moment(startDate).isSame(this.datesToShow[i].date, "day")) {
          startIndex = i + 1;
        }
        if (moment(endDate).isSame(this.datesToShow[i].date, "day")) {
          endIndex = i + 1;
        }
      }

      const startDateInRange = moment(minDate).isBetween(startDate, endDate, "day", "[]");
      const endDateInRange = moment(maxDate).isBetween(startDate, endDate, "day", "[]");
      const shortEventStartDateInRange = moment(startDate).isBetween(minDate, maxDate, "day", "[]");
      const shortEventEndDateInRange = moment(endDate).isBetween(minDate, maxDate, "day", "[]");
      const eventComplete = moment(endDate).isBefore(new Date(), "day");

      if (
        startDateInRange ||
        endDateInRange ||
        shortEventStartDateInRange ||
        shortEventEndDateInRange ||
        moment(maxDate).isSame(startDate, "day") ||
        moment(minDate).isSame(endDate, "day")
      ) {
        if (this.isCC && item.type === "contract") {
          return;
        }

        // A temporary solution until the microleague in cc is completed.
        if (this.isCC && item.type === "microleague") {
          return;
        }

        if (!filteredData[item.type]) {
          filteredData[item.type] = [];
        }

        filteredData[item.type].push({
          ...item,
          eventStartedEarlier,
          eventEndsLater,
          startIndex,
          endIndex,
          eventComplete,
        });
      }
    });

    const flattenFilteredData: DecoratedBusinessCalendarItem[] = Object.values(filteredData).flatMap(events => events);
    const unseenIds: number[] = flattenFilteredData.filter(event => !event.seen).map(event => event.player_assigned_event_id);
    const uniqUnseenIds: number[] = unseenIds.filter(item => !this?.alreadySentSeenIds?.includes(item));

    if (uniqUnseenIds.length) {
      if (((!this?.managerPlayerId && this.isPlayerIdInEnabledPlayers) || this.isManagerPlayerIdInEnabledPlayers) && !this.isImpersonate) {
        this.apiBusinessCalendarService.businessCalendarEventsSeen(uniqUnseenIds, this?.managerPlayerId).subscribe(resp => {
          this.alreadySentSeenIds = [...new Set([...this.alreadySentSeenIds, ...uniqUnseenIds])];
        });
      }
    }

    const sortedData: { [key: string]: DecoratedBusinessCalendarItem[] } = {};
    const sortOrder = ["microleague", "contract", "event", "other", "qa"];
    let count = 0;
    sortOrder.forEach(type => {
      if (filteredData?.[type]) {
        if (count === 0) {
          filteredData[type][0].firstItem = true;
        }
        if (type !== "qa") {
          sortedData[type] = filteredData[type];
        } else {
          if (filteredData["qa"] && Symbol.iterator in Object(filteredData["qa"])) {
            sortedData["other"] = sortedData["other"] || [];
            sortedData["other"] = [...sortedData["other"], ...filteredData["qa"]];
          }
        }
        count++;
      }
    });
    return sortedData;
  }

  prev() {
    if (this.allowPrev) {
      this.currentDate.setDate(this.currentDate.getDate() - 7);
      const newDate = moment(this.currentDate);
      this.datesToShow = this.generateDateArray(this.currentDate);

      const fetchDate = new Date(this.currentDate);
      fetchDate.setDate(fetchDate.getDate() - 8);

      if (!newDate.isSame(moment(fetchDate), "month")) {
        this.getData(true, fetchDate);
      } else {
        this.filteredEvents = this.filterDataByCurrentDate(this.data.data);
      }
      this.checkSwitchDateAllowed();
    }
  }

  next() {
    if (this.allowNext) {
      this.currentDate.setDate(this.currentDate.getDate() + 7);
      const newDate = moment(this.currentDate);
      this.datesToShow = this.generateDateArray(this.currentDate);

      const fetchDate = new Date(this.currentDate);
      fetchDate.setDate(fetchDate.getDate() + 8);

      if (!newDate.isSame(moment(fetchDate), "month")) {
        this.getData(true, fetchDate);
      } else {
        this.filteredEvents = this.filterDataByCurrentDate(this.data.data);
      }
      this.checkSwitchDateAllowed();
    }
  }

  checkSwitchDateAllowed() {
    this.allowNext = !moment(this.currentDate).add(4, "day").isSameOrAfter(moment(this.maxRangeDate), "day");
    this.allowPrev = !moment(this.currentDate).subtract(2, "day").isSameOrBefore(moment(this.minRangeDate), "day");
  }

  openDetails(item: BusinessCalendarItem) {
    if (item.has_details) {
      this.apiBusinessCalendarService.businessCalendarDetails(item.type, item.id).subscribe(resp => {
        this.dialogService.open(BusinessCalendarDetailsComponent, {
          data: {
            name: resp.name,
            description: resp.description,
            currency_prizes: resp.currency_prizes,
            product_prizes: resp.product_prizes,
            file: resp.file,
          },
        });
      });
    }
  }

  openCloseConfirm() {
    const unseenIds: number[] = this.data.data.filter(event => !event.seen).map(event => event.player_assigned_event_id);
    const hasUnSeenEvents = unseenIds.some(item => !this?.alreadySentSeenIds?.includes(item));
    if (
      hasUnSeenEvents &&
      ((!this?.managerPlayerId && this.isPlayerIdInEnabledPlayers) || this.isManagerPlayerIdInEnabledPlayers) &&
      !this.isImpersonate
    ) {
      this.dialogService.open(BusinessCalendarCloseComponent, { data: { ref: this.matDialogRef, managerPlayerId: this.managerPlayerId } });
    } else {
      this.matDialogRef.close();
    }
  }

  protected readonly STOCK_VIEW = STOCK_VIEW;
}
