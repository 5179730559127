import { EventDialog } from "../../../interfaces/event-dialog.interface";
import {EVENT_DIALOGS_NAMES_CORE} from '../../core/event-dialogs/event-names.const';
import {AlertComponent} from '../../../basic/custom/dialogs/alert/alert.component';
import {AlertConfirmComponent} from '../../../basic/custom/dialogs/alert-confirm/alert-confirm.component';
import {AlertImageComponent} from '../../../basic/custom/dialogs/alert-image/alert-image.component';

export const EVENT_DIALOGS_CORE_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_CORE.ALERT]: AlertComponent,
  [EVENT_DIALOGS_NAMES_CORE.ALERT_CONFIRM]: AlertConfirmComponent,
  [EVENT_DIALOGS_NAMES_CORE.ALERT_IMAGE]: AlertImageComponent,
};
