import { Injectable } from '@angular/core';
import * as R from 'ramda';
import { LeagueColorObject } from '../../interfaces/custom/league-color-object.interface';

@Injectable()
export class DictionaryService {
  /**
   * DATE
   */
  private _langMonthRoman = {
    1: 'I', 2: 'II', 3: 'III', 4: 'IV', 5: 'V', 6: 'VI', 7: 'VII', 8: 'VIII', 9: 'IX', 10: 'X', 11: 'XI', 12: 'XII',
  };

  private _langMonthText = {
    1: 'Styczeń', 2: 'Luty', 3: 'Marzec', 4: 'Kwiecień',
    5: 'Maj', 6: 'Czerwiec', 7: 'Lipiec', 8: 'Sierpień',
    9: 'Wrzesień', 10: 'Październik', 11: 'Listopad', 12: 'Grudzień',
  };

  private _quarter = {
    1: 'Q1',
    2: 'Q2',
    3: 'Q3',
    4: 'Q4',
  };

  private _half = {
    1: 'H1',
    2: 'H2',
  };

  getMonth(month) {
    if (month >= 1 && month <= 12) {
      return this._langMonthRoman[month];
    }
    return 0;
  }

  getMonthText(month) {
    if (month >= 1 && month <= 12) {
      return this._langMonthText[month];
    }
    return 0;
  }

  getQuarterName(quarter) {
    if (quarter >= 1 && quarter <= 4) {
      return this._quarter[quarter];
    }
    return 0;
  }

  getHalfName(half) {
    if (half >= 1 && half <= 2) {
      return this._half[half];
    }
    return 0;
  }

  /**
   * LEAGUE COLORS
   */
  private _leaguesObject: LeagueColorObject[] = [
    {name: 'Brązowa', icon: 'tierra', colorString: '#825731', color: 1},
    {name: 'Srebrna', icon: 'league_silver', colorString: '#9E9E9E', color: 2},
    {name: 'Złota', icon: 'league_gold', colorString: '#FDA936', color: 3},
    {name: 'Diamentowa', icon: 'league_diamond', colorString: '#0085BF', color: 4},
    {name: 'Brylantowa', icon: 'league_brilliance', colorString: '#CE3B43', color: 0},
  ];

  getLeagueObject(color): LeagueColorObject {
    color = +color;

    const colorObjectDefault: LeagueColorObject = {name: 'undefined', icon: null, colorString: null, color: null};
    const colorObjectResult = this._leaguesObject.find((item) => item.color === color);

    return R.clone(colorObjectResult || colorObjectDefault);
  }
}
