import { EventDialog } from "../../../../../../../core/interfaces/event-dialog.interface";
import { TrackingBeginComponent } from "../../../base/custom/dialogs/tracking-begin/tracking-begin.component";
import { TrackingBoardComponent } from "../../../base/custom/dialogs/tracking-board/tracking-board.component";
import { TrackingContinueComponent } from "../../../base/custom/dialogs/tracking-continue/tracking-continue.component";
import { TrackingStartComponent } from "../../../base/custom/dialogs/tracking-start/tracking-start.component";
import { EVENT_DIALOGS_NAMES_TRACKING_CUSTOM } from "./event-names.const";

export const EVENT_DIALOGS_TRACKING_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_TRACKING_CUSTOM.TRACKING_BEGIN]: TrackingBeginComponent,
  [EVENT_DIALOGS_NAMES_TRACKING_CUSTOM.TRACKING_START]: TrackingStartComponent,
  [EVENT_DIALOGS_NAMES_TRACKING_CUSTOM.TRACKING_CONTINUE]: TrackingContinueComponent,
  [EVENT_DIALOGS_NAMES_TRACKING_CUSTOM.TRACKING_BOARD]: TrackingBoardComponent,
};
