<!-- CURRENCIES OR PRODUCTS -->
<m-ui-separator [title]="'Koszt wykonania ruchu'"></m-ui-separator>

<div class="flex-center margin-20">
  <ng-container *ngIf="currencies || products">
    <m-ui-costs
      (eventEmitter)="checkCompleted($event)"
      [currencies]="currencies"
      [customClassStock]="'stock-san-a'"
      [products]="products"
      [stockView]="STOCK_VIEW.CUSTOM"
    ></m-ui-costs>
  </ng-container>
</div>

<!-- BUILDING -->
<ng-container *ngIf="requiredBuilding">
  <m-ui-separator [title]="'Wymagania'"></m-ui-separator>

  <div class="flex-center">
    <div class="req-building">
      <img
        [src]="requiredBuilding.icon + '_small.png' | asset:'buildings'"
        container="body"
        ngbTooltip="{{ requiredBuilding['is_disaster'] ? '' : requiredBuilding.name + ' - poziom ' + requiredBuilding.level }}"
        placement="bottom"
        tooltipClass="delayed"
      />

      <i *ngIf="!requiredBuilding.exists || requiredBuilding['is_disaster']" class="fas fa-exclamation icon-lack"></i>
    </div>
  </div>
</ng-container>

<!-- Cost increased -->
<ng-container *ngIf="enableWarning">
  <p class="hint-title">Uwaga!</p>
  <p class="hint-description">Dzisiaj wykorzystano już ruchy w standardowej cenie, teraz każdy kolejny ruch będzie droższy.<br>
    Jeśli wrócisz jutro, cena za dwa pierwsze ruchy wróci do pierwotnej ceny.</p>
</ng-container>

