<div class="container-bg"></div>

<div class="auth-container">
  <div class="inner">
    <div class="logo-wrapper"></div>

    <div class="auth-form-wrapper">
      <loading></loading>
      <app-auth-info></app-auth-info>

      <div class="loading-opacity content">
        <form [formGroup]="form" autocomplete="off">
          <div class="main-content">
            <!--TITLE-->
            <p class="form-title">
              Logowanie
            </p>
            <!-- LOGIN FORM -->
            <div class="control">
              <div
                class="default-field"
                [class.error]="form.get('san_log').invalid && form.get('san_log').dirty && form.get('san_log').errors"
              >
                <input
                  class="control-input no-ow-input-directive"
                  formControlName="san_log"
                  type="text"
                  placeholder="login"
                  id="san_log"
                  autocomplete="off"
                >
                <label for="san_log">Login</label>
                <div class="input-icon" [ngbTooltip]="tooltipTpl" tooltipClass="delayed">
                  <i class="fas fa-question"></i>
                </div>
                <!-- TOOLTIP -->
                <ng-template #tooltipTpl>
                  <div class="padding">
                    <b>Spróbuj prostego logowania</b><br/>
                    Wpisz pierwszą część służbowego adresu e-mail<br/>
                    ('imię.nazwisko' bez polskich znaków) oraz hasło.
                  </div>
                </ng-template>
              </div>
            </div>

            <div class="control">
              <div
                class="default-field"
                [class.error]="form.get('san_pass').invalid && form.get('san_pass').dirty && form.get('san_pass').errors"
              >
                <input
                  class="control-input no-ow-input-directive"
                  formControlName="san_pass"
                  type="password"
                  placeholder="hasło"
                  id="san_pass"
                  autocomplete="off"
                >
                <label for="san_pass">Hasło</label>
              </div>
            </div>

            <div class="buttons flex-center">
              <button
                class="c-base c-primary full-width"
                (click)="login()"
                [disabled]="form.invalid"
              >
                Zaloguj
              </button>
            </div>
          </div>

          <div class="bottom-links">
            <!-- LOST PASSWORD -->
            <a
              class="description text-center default-form-link"
              [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.FORGOT_PASSWORD"
            >
              <p>Nie pamiętasz hasła?</p>
            </a>

            <!-- REGISTER -->
            <a
              class="description text-center default-form-link"
              [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.REGISTRATION"
            >
              <p>Rejestracja</p>
            </a>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>

<!-- APP HELP DESK INFO -->
<app-helpdesk-info></app-helpdesk-info>

