<div class="global-dialog ow-dialog c-window-short">

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Wiadomości</span>
      </div>
    </div>
  </div>

  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="closeOrCloseAll()"
  ></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button
    [preventDefault]="true"
    (clicked)="close()"
    *ngIf="messageOpenDetailsData.fromList"
  ></m-ui-back-button>

  <div class="hidden-reward-container" *ngIf="message">

    <!-- REWARD IMAGE -->
    <div class="hidden-reward-image">
      <img [src]="(icon + '.png' | asset: path)"/>
    </div>

    <!-- TEASER -->
    <div class="teaser" [innerHTML]="message.teaser | safe:'html'"></div>

    <!-- DESCRIPTION -->
    <div class="inner-html-description" [innerHTML]="message.description | safe:'html'"></div>

    <!-- PRIZES -->
    <ng-container *ngIf="message.parameters.currencies.length || message.parameters.prizes.length">

      <div class="parameters">
        <!-- CURRENCIES -->
        <m-ui-currency
          *ngFor="let currency of message.parameters.currencies"
          [item]="currency"
          [stockView]="STOCK_VIEW.CUSTOM"
          [customIconSize]="'big'"
          [customClassStock]="'stock-san-a'"
          [lack]="false"
        ></m-ui-currency>

        <!-- PRODUCTS -->
        <m-ui-product
          *ngFor="let product of message.parameters.prizes"
          [item]="product"
          [other]="{value: '+ '}"
          [stockView]="STOCK_VIEW.CUSTOM"
          [customIconSize]="'big'"
          [customClassStock]="'stock-san-a'"
          [lack]="false"
        ></m-ui-product>
      </div>
    </ng-container>
  </div>

  <!-- MESSAGE TRASH -->
  <button class="c-square small trash" *ngIf="message && !message.deleted" matTooltip="Przenieś do archiwum">
    <i
      class="fas fa-archive trash-icon"
      (click)="moveToTrash()"
    ></i>
  </button>
</div>
