import {Injectable} from '@angular/core';
import {ApiService} from '../../../../../../core/providers/api.service';
import {Observable} from 'rxjs';
import {WfEntry} from '../../interfaces/wf.interface';
import {ProductDetails} from '../../../../../player/interfaces/product.interface';

@Injectable({
  providedIn: "root"
})
export class WfService {
  constructor(private api: ApiService) {
  }

  getWfSettings(): Observable<WfEntry[]> {
    return this.api.get('wf/entry');
  }

  getCategoryProducts(playerId: number, categoryId: number): Observable<ProductDetails[]> {
    return this.api.get(`products/player/${playerId}?category=${categoryId}`);
  }

  markWFEpisodeAsSeen(entryId: number) {
    return this.api.post(`wf/entry/${entryId}/seen-new-items`, {});
  }
}
