import { Injectable } from "@angular/core";
import { ApiService } from "../../../../core/providers/api.service";
import { PlayerService } from "../../../player/providers/player.service";
import { UserService } from "../../../user/providers/user.service";

@Injectable({ providedIn: "root" })
export class NotificationsService {
  constructor(
    private apiService: ApiService,
    private playerService: PlayerService,
    private userService: UserService
  ) {}

  setNotificationRead(type, playerId?: number) {
    if (playerId) {
      if (
        this.playerService.getActivePlayerId() !== playerId &&
        !this.userService.me.enabled_players.some(player => player.id === playerId)
      ) {
        return;
      }
    }

    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.patch(`/player/${playerId}/notifications/type/${type} `, {});
  }
}
