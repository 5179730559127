<script src="ranking-details-game.component.ts"></script>
<div class="global-dialog ow-dialog" [ngClass]="data.isWfRank ? 'c-window-long-wf' : 'c-window-long'">
  <ng-container *ngIf="data.isWfRank">
    <div class="decoration decoration-1"></div>
    <div class="decoration decoration-2"></div>
  </ng-container>
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks" *ngIf="rank?.name">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Ranking - {{ rank.name }}</span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="rank">
    <div class="navigate-info">
      <ng-container *ngIf="owDate">
        {{ owDate.month_text_normal }}
      </ng-container>
    </div>

    <div class="content loading-opacity">
      <!-- TOP -->
      <div class="top">
        <!-- LEFT -->
        <div class="left">

          <!-- SUB-TITLE -->
          <p
            class="sub-title"
            [innerText]="rank.name"
            owEllipsis
          ></p>

          <!-- DESCRIPTION -->
          <p
            class="description"
            [innerText]="rank.description"
            owEllipsis
          ></p>
        </div>

        <!-- RIGHT -->
        <div class="right">
          <!-- FORM -->
          <div class="default-field">
            <input
              type="text"
              id="search"
              placeholder="Szukaj..."
              (keyup)="changeSearchValue($event)"
            />
            <label for="search">Szukaj...</label>
          </div>
        </div>
      </div>

      <!-- FRAME -->
      <div class="frame" [class.sparkle-frame]="rank.parameters?.is_sparkle">
        <ng-container
          [ngTemplateOutlet]="innerRankTpl"
        ></ng-container>
      </div>

      <!-- SPARKLE BAR -->
      <div class="sparkle-bar" *ngIf="rank.parameters?.is_sparkle">
        <ng-container *ngIf="sparkle">
            <span>
              Razem zebraliśmy {{ sparkle?.sparkle_sum | NumberWithSpaces }}
              Iskierek = {{ sparkle?.cash_sum | NumberWithSpaces }} zł
            </span>

          <div class="sparkle-ribbon">
            <img [src]="'icon-iskierki.png' | asset:'ui'"/>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-container
  *ngIf="data.innerRank"
  [ngTemplateOutlet]="innerRankTpl"
></ng-container>

<!-- INNER RANK TPL -->
<ng-template #innerRankTpl>
  <div class="box">
    <!-- FILTERS -->
    <div class="filters" *ngIf="!data.hideFilters">
      <ng-container *ngFor="let level of levels">
        <div
          class="ow-radio-container"
          *ngIf="level.show"
        >
          <label class="choose-wrapper">
                  <span class="checkbox-wrapper">
                    <input
                      type="radio"
                      name="levelRankGame"
                      (click)="changeLevel(level)"
                      [checked]="level === activeLevel"
                    >
                    <span class="checkmark"></span>
                  </span>
            <span class="text-wrapper">{{ level.label }}</span>
          </label>
        </div>
      </ng-container>
    </div>

    <!-- SHOW MY POSITION -->
    <div
      class="my-position-link"
      showMyPositionRank
      *ngIf="myPositionIndex >= 0 && !searchValue && playerService.isActiveMe"
      [perfectScrollbarRef]="perfectScrollbar"
      [myPosition]="myPositionIndex"
    >
      <a>Pokaż moją pozycję</a>
    </div>
  </div>

  <!-- OTHER POSITIONS -->
  <ow-perfect-scrollbar #perfectScrollbar [scrollGradient]="rankResults">
    <ranking-game-table
      [rankResults]="rankResults"
      [rank]="rank"
      [columns]="RANK_CONFIG.columns"
      [myPositionIndex]="myPositionIndex"
      [inputSearchValue]="searchValue"
      [group]="data.group"
      [isWfRank]="data.isWfRank"
    ></ranking-game-table>
  </ow-perfect-scrollbar>
</ng-template>
