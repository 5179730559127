<div
  (click)="openDashboard()"
  class="dashboard"
>
  <img [src]="'business.png' | asset: 'ui'" alt="" class="icon-business"/>

  <button class="dashboard-button">
    Centrum danych
  </button>

  <div class="notification-box">
    <div
      [matTooltip]="'Zaktualizowaliśmy dane biznesowe.'"
      [matTooltipShowDelay]="400"
    >
      <div
        class="app-notification dot-blue"
        *ngIf="(playerService.player.title_id.playable && playerService.player['gui_notifications']?.['import_calculate_basic_actions'] > 0)
    || playerService.player['gui_notifications']?.['import_calculate_ranks'] > 0
    || playerService.player['gui_notifications']?.['import_calculate_tasks'] > 0"
      ></div>
    </div>
  </div>
</div>

<div
  (click)="openWF()"
  class="wf"
  [matTooltipShowDelay]="400"
  [matTooltip]="'SAN CHI - rozgrywka finałowa'"
  *ngIf="playerService.player.title_id.playable && playerService.player.gui_unlocks['wf_entry']"
>
  <img [src]="'wf_cc_button.png' | asset: 'ui'" alt="" class="icon-business"/>
</div>
