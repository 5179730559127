<div class="global-dialog ow-dialog c-window-long">
  <loading></loading>

  <m-ui-close-button></m-ui-close-button>

  <m-ui-back-button></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Osiągnięcia ligowe</span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="achievement">
    <div class="icon-league">
      <img alt="" [src]="achievement.icon_active + '.png' | asset: 'ui'"/>
    </div>

    <div class="title" [innerHTML]="achievement.name"></div>

    <div class="desc" *ngIf="achievement.complete_date">
      Osiągnięcie zdobyte dnia: {{ achievement.complete_date | date: 'dd.MM.yy' }}
    </div>

    <div class="desc middle"></div>

    <div class="desc">
      Liczba oddziałów, które zdobyły osiągnięcie: {{ achievement.list.length }}
    </div>

    <div class="list" *ngIf="achievement?.list.length > 0">
      <ow-perfect-scrollbar [scrollGradient]="achievement.list">
        <table>
          <tbody>
          <tr *ngFor="let item of achievement.list">
            <td>
              <div
                *ngIf="item.league_group_id"
                class="icon-league group small"
                [ngClass]="'icon-' + item.league_group_id"
              ></div>
            </td>
            <td>
              {{ item.branch_name }}
            </td>
            <td>
              {{ item.complete_date | date: 'dd.MM.yy' }}
            </td>
          </tr>
          </tbody>
        </table>
      </ow-perfect-scrollbar>
    </div>
  </ng-container>
</div>
