import { Component } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { QualityTaskScope } from '../../../../interfaces/custom/quality-tasks.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'business-quality-task-info',
  templateUrl: './quality-task-info.component.html',
})
export class QualityTaskInfoComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<QualityTaskInfoComponent>;
  @OwInject(MAT_DIALOG_DATA) public data: {
    taskScopes: QualityTaskScope[];
    quarter?: boolean;
  };

  close() {
    this.matDialogRef.close();
  }
}
