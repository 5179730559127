import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../../core/decorators/ow-inject.decorator";

@Component({
  selector: "app-confirm-contract-alert",
  templateUrl: "./confirm-alert.component.html",
  styleUrls: ["./confirm-alert.component.scss"],
})
export class ConfirmAlertComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<ConfirmAlertComponent>;

  close(confirm?: boolean) {
    this.matDialogRef.close(confirm);
  }
}
