<div class="global-dialog ow-dialog {{boardConfig.boardName}}">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button (clicked)="closeTrackingBoard()" [preventDefault]="true"></m-ui-close-button>

  <div class="top-hud">
    <ng-container>
      <div class="board-pill"
           matTooltip="{{boardConfig.hudMenuTooltipText.steps}}"
           matTooltipPosition="right"
           [matTooltipShowDelay]="300">
        <img [src]="'fitness.png' | asset : 'products/small'" />
        <p>{{ loaded ? parameters.steps : '&nbsp;'}}</p>
      </div>
      <div class="board-pill"
           matTooltip="{{boardConfig.hudMenuTooltipText.trackerMove}}"
           matTooltipPosition="right"
           [matTooltipShowDelay]="300">
        <img [src]="'tracker_move.png' | asset : 'parameters/small'" />
        <p>{{ loaded ? parameters.max_turns : '&nbsp;' }}</p>
      </div>
      <div class="board-pill"
           matTooltip="{{boardConfig.seeking === soughtCreatureEnum.objects ? boardConfig.hudMenuTooltipText.soughtAnimal : boardConfig.hudMenuTooltipText.soughtPoacher}}"
           matTooltipPosition="right"
           [matTooltipShowDelay]="300">
        <img [src]="'search.png' | asset : 'ui'" />
        <p>{{ loaded ? parameters.creaturesOnTheMap - parameters.creaturesFound : '&nbsp;'}}</p>
      </div>
    </ng-container>
  </div>

  <div class="frame is-loading">
    <div class="board-wrapper">
      <div *ngFor="let row of boardTiles; let y = index"
           class="row">
        <div (click)="discoverTheField(cell)"
             *ngFor="let cell of row; let x = index; trackBy: identifyObj"
             [ngClass]="{
               'bush': cell.showBushes,
               'blocked': boardConfig.blocked || !cell.clickable && data.isSpecialMode,
               'clickable': !data.isSpecialMode || cell.clickable && data.isSpecialMode,
               'shrink': data.isSpecialMode
             }"
             [ngStyle]="{
              'z-index': y+x
             }"
             class="cell {{cell.background}} {{boardConfig.boardName}}">
          <div *ngFor="let footprint of cell.footprint"
               title="{{footprint.tooltip}}"
               class="footprint {{footprint.class}}"></div>
          <div *ngIf="cell.creature?.name"
               title="{{cell.creature?.text}}"
               class="creature {{cell.creature.name}}{{cell.creature.variant || ''}}"></div>
        </div>
      </div>
    </div>
  </div>
</div>
