import { Component, OnInit } from "@angular/core";

import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { AbstractMessageDetailsChestSuccessComponent } from "../../../../abstract/core/abstract-message-details-chest-success.component";

@Component({
  selector: "app-message-details-chest-success",
  templateUrl: "./message-details-chest-success.component.html",
})
export class MessageDetailsChestSuccessComponent extends AbstractMessageDetailsChestSuccessComponent implements OnInit {
  STOCK_VIEW = STOCK_VIEW;

  ngOnInit() {
    this.getProducts();
    this.afterInit();
  }
}
