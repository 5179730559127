import { RANK_GROUP } from "../../../../../const/custom/rank-group.const";
import { RANK_NAME } from "../../../../../const/custom/rank-name.const";
import { RankFilter } from "../interfaces/rank-filter.interface";

export const FILTERS_CC: RankFilter[] = [
  {
    valueApi: RANK_GROUP.EMPLOYEE,
    label: "Konsultanci",
    customLabels: { [RANK_NAME.GAME]: "Indywidualny" },
    show: true,
  },
  {
    valueApi: RANK_GROUP.BRANCH,
    label: "Koordynatorzy",
    show: true,
  },
];
