import { Component, OnInit } from '@angular/core';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../../shared/providers/dialog.service';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { EdenredCardHistoryComponent } from '../edenred-card-history/edenred-card-history.component';
import { ApiEdenredCardService } from '../../../api/custom/services/api-edenred-card.service';

@Component({
  selector: 'app-edenred-card-list',
  templateUrl: './edenred-card-list.component.html',
})
export class EdenredCardListComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiEdenredCardService) apiEdenredCardService: ApiEdenredCardService;

  edenredCards: EdenredCard[];

  ngOnInit() {
    this.getEdenredCardList();
  }

  getEdenredCardList() {
    this.apiEdenredCardService.getEdenredCardList()
      .subscribe((resp) => {
        this.edenredCards = resp;
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }

  openEdenredCardHistory(card) {
    this.dialogService.open(EdenredCardHistoryComponent, {
      data: {
        card: card
      }
    });
  }
}

interface EdenredCard {
  blocked_at: string;
  bought_at: string;
  card_id: number;
  number: string;
  processing_at: string;
  received_at: string;
  sent_at: string;
  status: number;
  status_text: string;
  valid_till: string;
}
