import { Component, Input } from "@angular/core";

import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";

@Component({
  selector: "app-unlocked-productions",
  templateUrl: "./unlocked-productions.component.html",
})
export class UnlockedProductionsComponent {
  @Input() production: any[];
  @Input() hideExtraValue: boolean;
  STOCK_VIEW = STOCK_VIEW;
}
