export const OrientationManager = (function () {
  "use strict";

  let supportsOrientationChange = window.hasOwnProperty("onorientationchange"),
    orientationEvent = supportsOrientationChange ? "orientationchange" : "resize",
    currentOrientation = window.orientation || 0,
    currentWidth = window.innerWidth;

  function orientationChanged() {
    const newWidth = window.innerWidth,
      newOrientation = window.orientation || 0;

    // only change orientation and call provided callback if necessary
    if (newOrientation !== currentOrientation || newWidth !== currentWidth) {
      currentOrientation = newOrientation;
      currentWidth = newWidth;
      return true;
    }
    return false;
  }

  // exports
  return {
    supportsOrientationChange: supportsOrientationChange,
    orientation: currentOrientation,
    orientationEvent: orientationEvent,
    orientationChanged: orientationChanged,
  };
})();
