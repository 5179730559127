import { Component, OnInit } from '@angular/core';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import {
  AbstractMissionDetailsComponent
} from '../../../../mission/abstract/core/abstract-mission-details.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-message-event',
  templateUrl: './message-event.component.html',
})
export class MessageEventComponent extends AbstractMissionDetailsComponent implements OnInit {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<MessageEventComponent>;

  ngOnInit() {
    this.getMissionDetails(this.data.mission.player_mission_id);
  }

  close() {
    this.matDialogRef.close();
  }
}
