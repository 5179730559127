<ng-container *ngIf="player">
  <div class="user-container">
    <button
      class="main-button"
      *ngIf="showBtn"
      [class.active]="true"
      [class.anim]="button.group_name"
      [matTooltip]="button.tooltip"
      [matTooltipPosition]="'below'"
      [matTooltipShowDelay]="300"
      (click)="openPlayerChoose()"
    >
      <img [src]="button.icon | asset: 'ui/menu'">
    </button>

    <div class="experience-wrapper"
         [ngbTooltip]="'Twój profil'"
         [placement]="'bottom'"
         [tooltipClass]="'hud delayed'"
         [class.dor]="!player.title_id.playable"
    >
      <div class="avatar-wrapper">
        <div class="avatar-default" (click)="playerService.isActiveMe && openPlayerDetails()">
          <i class="fa fa-user-circle"></i>
        </div>
        <div
          class="avatar-custom"
          [class.dor]="!player.title_id.playable"
          [style.background-image]="'url('+player.profile_image_url+')'"
        ></div>
      </div>

      <!-- LEVEL STAR -->
      <div
        [matTooltip]="'hud-player.level-tooltip' | myTranslate:[player.points_balance | NumberWithSpaces, player.next_level_points | NumberWithSpaces]"
        matTooltipPosition="right"
        matTooltipShowDelay="300"
        class="level-star flex-center flex-center"
        *ngIf="player.title_id.playable"
      >
        {{ player.level }}
      </div>
    </div>
    <svg height="69" id="circle" viewBox="0 0 200 200" width="68">
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="25%" y2="100%">
          <stop offset="0%" class="stop-0" />
          <stop offset="100%" class="stop-100" />
        </linearGradient>
      </defs>
      <circle
        [style.strokeDashoffset]="strokeDashOffset"
        cx="100"
        cy="100"
        fill="transparent"
        id="bar"
        r="84"
        stroke-dasharray="528"
        transform="rotate(90 100,100)"
        stroke="url(#grad1)"
      ></circle>
    </svg>
  </div>
</ng-container>
