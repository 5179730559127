import { Component } from '@angular/core';
import { AbstractRankDetailsGameComponent } from '../../../../../abstract/custom/abstract-rank-game-details.component';

@Component({
  selector: 'app-ranking-details-game',
  templateUrl: './ranking-details-game.component.html',
})
export class RankingDetailsGame extends AbstractRankDetailsGameComponent {

}
