import { Injectable } from '@angular/core';
import { PlayerService } from '../../../../../player/providers/player.service';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class BusinessDateService {
  currentBusinessDate = null;

  constructor(
    private playerService: PlayerService,
  ) {
    const businessDate = this.playerService.player['business_last_calculation_date'];

    if (businessDate) {
      const date = new Date(businessDate);
      const dateFormat = moment(date).format('DD/MM/yyyy');
      const [day, month, year] = dateFormat.split('/');
      this.currentBusinessDate = {
        year: +year,
        month: +month,
        day: +day
      };
    }
  }
}
