import { Component, Input } from '@angular/core';
import { AbstractBuildingThumbnailComponent } from '../../../../abstract/core/abstract-building-thumbnail.component';

@Component({
  selector: 'app-building-thumbnail',
  templateUrl: './building-thumbnail.component.html',
})
export class BuildingThumbnailComponent extends AbstractBuildingThumbnailComponent {
  @Input() showTitle: boolean;
}
