<div
  class="global-dialog ow-dialog c-window-long"
  [ngClass]="activeTab?.class"
>
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON  -->
  <m-ui-back-button
    (clicked)="back()"
    [preventDefault]="true"
  ></m-ui-back-button>

  <!-- TABS -->
  <div class="tabs">
    <ng-container *ngFor="let tab of tabs; let i = index">
      <div
        *ngIf="tab.visible"
        [class.active]="tab == activeTab"
        [class]="tab.color"
        [style.z-index]="-i"
        class="tab"
      >
        <div class="bookmarks">
          <div
            (click)="playerService.player.gui_unlocks[tab.gui_unlocks] && changeTab({tab: tab})"
            [class]="tab.color"
            [matTooltip]="tab.disabledTooltip"
            [matTooltipPosition]="'below'"
            [matTooltipDisabled]="playerService.player.gui_unlocks[tab.gui_unlocks]"
            class="bookmark"
            [class.cursor]="!playerService.player.gui_unlocks[tab.gui_unlocks]"
          >
            <span>{{ tab.label }}</span>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="additional-data-tabs-wrapper">
      <div *ngIf="microLeague.isDetailsViewActive && this.microLeague?.details?.available_prizes?.length > 0 && !microLeague.isCleared" (click)="openMaxPrizesDialog()" class="trophy-data-date-tab"><div class="trophy"></div></div>
      <div *ngIf="microLeague.detailsSourceData || (achievement.activeTemplate === ACHIEVEMENT.ACHIEVEMENTS && achievement.listSourceData)" class="source-data-date-tab">
        Dane aktualne na: {{ (microLeague.detailsSourceData || achievement.listSourceData) | date:'dd-MM-y' }}
      </div>
    </div>

  </div>

  <ng-container [ngSwitch]="activeTemplate">
    <ng-container
      *ngSwitchCase="TEMPLATE.MICROLEAGUE"
      [ngTemplateOutlet]="microleagueTpl"
    ></ng-container>

    <ng-container
      *ngSwitchCase="TEMPLATE.ACHIEVEMENTS"
      [ngTemplateOutlet]="achievementsTpl"
    ></ng-container>
  </ng-container>

  <div
    *ngIf="microLeague.isCleared"
    class="cleared"
  ></div>
  <div *ngIf="branchName" class="branch-name">{{ branchName }}</div>
</div>

<ng-template #microleagueTpl>
  <app-micro-league
    (closed)="showMicroLeaguesClosed(managerPlayerId)"
    (showLeagueDetails)="showLeagueDetails($event, managerPlayerId)"
    *ngIf="!microLeague.isDetailsViewActive"
    [isClosedActive]="microLeague.isClosedViewActive"
    [isLoading]="isLoading"
    [microleagues]="microLeague.list"
    [closedBtn]="microLeague.closedDisableBtn"
    [managerPlayerId]="managerPlayerId"
  ></app-micro-league>

  <app-micro-league-details
    *ngIf="microLeague.isDetailsViewActive"
    [isCleared]="microLeague.isCleared"
    [isLoading]="isLoading"
    [microLeagueDetails]="microLeague.details"
  ></app-micro-league-details>
</ng-template>

<ng-template #achievementsTpl>
  <div
    class="visibility"
    *ngIf="achievement.activeTemplate === ACHIEVEMENT.CHOOSE_VIEW"
  >
    <div class="item individual" (click)="chooseAchievement(1, managerPlayerId)">
      <span>Osiągnięcia <br> indywidualne</span>
    </div>

    <div class="separator"></div>

    <div class="item branch" (click)="chooseAchievement(2, managerPlayerId)">
      <span>Osiągnięcia <br> oddziałowe</span>
    </div>
  </div>

  <app-micro-league-achievements
    *ngIf="achievement.activeTemplate === ACHIEVEMENT.ACHIEVEMENTS"
    [achievements]="achievement.list"
    [isLoading]="isLoading"
    (showAchievementsDetails)="showAchievementDetails($event, managerPlayerId)"
  ></app-micro-league-achievements>

  <app-micro-league-achievement-details
    *ngIf="achievement.activeTemplate === ACHIEVEMENT.DETAILS"
    [achievement]="achievement.details"
    [isLoading]="isLoading"
  ></app-micro-league-achievement-details>
</ng-template>
