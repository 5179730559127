import { Component, EventEmitter, Input, Output } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../../../../shared-ui/mobile/consts/stock-view.const";
import { LeagueAchievement } from "../../../../../interfaces/custom/league-achievement.interface";
import { PrizesAlertComponent } from "../../contract-sale/prizes-alert/prizes-alert.component";

@Component({
  selector: "app-micro-league-achievements",
  templateUrl: "./micro-league-achievements.component.html",
})
export class MicroLeagueAchievementsComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @Output() showAchievementsDetails: EventEmitter<number> = new EventEmitter<number>();
  @Input() achievements: LeagueAchievement[];
  @Input() isLoading: boolean;

  STOCK_VIEW = STOCK_VIEW;

  openAchievementDetails(achievementId: number) {
    this.showAchievementsDetails.emit(achievementId);
  }

  openPrize(item?) {
    let title;

    const prizes = {
      currency_prizes: [],
      product_prizes: [],
    };

    if (!item.prizes) {
      title = "Brak nagród do zdobycia";
    } else {
      if (item.prizes.currency?.length) {
        prizes.currency_prizes.push(...item.prizes.currency);
      }
      if (item.prizes.product?.length) {
        prizes.product_prizes.push(...item.prizes.product);
      }
      title = "Nagrody do zdobycia";
    }

    this.dialogService.open(PrizesAlertComponent, {
      data: {
        prizes: prizes,
        title: title,
      },
    });
  }
}
