<div class="global-dialog ow-dialog c-window-long">
  <loading [showAlways]="isLoading"></loading>

  <!-- EXIT BUTTON -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button
  [preventDefault]="true"
  (click)="back()"
  ></m-ui-back-button>

  <ng-container *ngIf="!isLoading && auction && (auction.status.value === STATUS.FINISHED || (auction.status.value === STATUS.ACTIVE && ((!auction.isTimeIntSet && auction.timer) || (auction.isTimeIntSet && auction.timer) || (!auction.isTimeIntSet && !auction.timer))))">
    <div class="content">
      <div class="img">
        <img [src]="auction.photo" alt="" />
      </div>

      <div class="right">
        <div class="top">
          <p class="title" [innerHTML]="auction.title"></p>
          <p class="desc" owEllipsis [innerHTML]="auction.description"></p>

          <div class="bottom">
            <div class="item alert" *ngIf="auction.status.value === STATUS.FINISHED">Aukcja zakończona</div>
            <div class="item">Data zakończenia: {{ auction.ends_at | date: "dd.MM.yyyy, HH:mm:ss" }}</div>
            <div class="item" *ngIf="((!auction.isTimeIntSet && auction.timer) || (auction.isTimeIntSet && auction.isTimeIntSet && auction.timer)) && auction.status.value === STATUS.ACTIVE">
              Do końca aukcji pozostało:
              <span class="timer" [class.alert]="auction.timerSoonEnd">
                <ng-container *ngIf="auction.isTimeIntSet">{{ auction.timer | toHour }}</ng-container>
                <ng-container *ngIf="!auction.isTimeIntSet">{{ auction.timer }}</ng-container>
              </span>
            </div>
            <div class="item">
              {{ auction.status.value === STATUS.FINISHED ? 'Licytowało: ' : 'Aktualnie licytuje: ' }}
              {{ auction.number_of_bidders + (auction.number_of_bidders === 1 ? ' osoba'
              : auction.number_of_bidders >= 2 && auction.number_of_bidders <= 4 ? ' osoby' : ' osób') }}
            </div>
          </div>
        </div>

        <div class="box">
          <div class="txt lost" *ngIf="auction.status.value === STATUS.ACTIVE && auction.my_bid?.is_highest">
            Twoja oferta jest w tej chwili najwyższa! Gratulujemy!
          </div>

          <div class="bottom" *ngIf="auction.status.value === STATUS.ACTIVE">
            <div class="item">
              Aktualna cena:
              <m-ui-currency
                [item]="{ currency_id: auction.currency_id, amount: (!auction.highest_bid || (auction.highest_bid.amount < auction.min_bid) ? auction.min_bid : auction.highest_bid?.amount) }"
                [stockView]="STOCK_VIEW.CUSTOM"
                [customIconSize]="'big'"
                [customClassStock]="'stock-san-a'"
                [lack]="false"
              ></m-ui-currency>
            </div>
            <div class="item no-border offer">
              Twoja oferta:
              <input
                class="control-input"
                type="number"
                [(ngModel)]="playerBid"
                [class.alert]="playerBid < auction.highest_bid?.amount + 1 || playerBid < auction.min_bid"
              />
              <m-ui-currency
                [item]="{ currency_id: auction.currency_id, amount: 0 }"
                [stockView]="STOCK_VIEW.CUSTOM"
                [customIconSize]="'big'"
                [customClassStock]="'stock-san-a'"
                [lack]="false"
                [hideValue]="true"
              ></m-ui-currency>

              <div class="alert error" *ngIf="playerBid < auction.highest_bid?.amount + 1 || playerBid < auction.min_bid">
                Minimalna oferta musi wynosić: {{ !auction.highest_bid || (auction.highest_bid.amount < auction.min_bid) ? auction.min_bid : auction.highest_bid?.amount + 1 }}
              </div>
            </div>
            <div class="item no-border">
              <button
                class="c-base c-secondary"
                (click)="openAuctionBid()"
                [disabled]="playerBid < auction.min_bid || playerBid < auction.highest_bid?.amount + 1 || (data.currencyId === currencyAmount.cash?.currency_id && playerBid > currencyAmount.cash?.balance)"
              >
                Licytuj
              </button>
            </div>
          </div>
        </div>

        <div class="bottom v2" *ngIf="auction.status.value === STATUS.FINISHED">
          <div class="txt won" *ngIf="auction.my_bid?.has_won">
            GRATULACJE! AUKCJA WYGRANA. TWOJA OFERTA BYŁA NAJWYŻSZA.
          </div>

          <div class="txt lost" *ngIf="auction.my_bid && !auction.my_bid.has_won">
            Niestety nie udało Ci się wygrać tej aukcji.
          </div>

          <div
            class="choose-currency"
            *ngIf="auction.can_choose_currency && auction.my_bid?.has_won"
          >
            <div class="allegro-currency-choose">!</div>
            <ng-container *ngIf="auction.my_bid.currency_id === null">
              <span>Wybierz walutę, którą chcesz zapłacić za tą aukcję. Prosimy o podjęcie decyzji nie później niż do 10 dni roboczych od zakończenia aukcji. Po tym czasie pozostanie tylko możliwość płatności w złotówkach.</span>
            </ng-container>

            <ng-container *ngIf="auction.my_bid.currency_id">
              <span class="fz-18">Wybrano płatność w {{ findBalanceByCurrencyId(auction.my_bid.currency_id)?.key === 'pln' ? 'złotówkach.' : 'Złotych Talarach.' }}</span>
            </ng-container>
          </div>

          <div class="item" *ngIf="!auction.can_choose_currency">
            Końcowa cena:
            <m-ui-currency
              [item]="{ currency_id: auction.currency_id, amount: auction.my_bid?.has_won ? auction.my_bid?.amount : (auction.highest_bid?.amount || auction.min_bid) }"
              [stockView]="STOCK_VIEW.CUSTOM"
              [customIconSize]="'big'"
              [customClassStock]="'stock-san-a'"
              [lack]="auction.my_bid?.has_won ? !auction.my_bid?.currency_id && auction.my_bid?.amount > findBalanceByCurrencyId(auction.currency_id)?.balance : false"
            ></m-ui-currency>
          </div>

          <div class="item" *ngIf="auction.can_choose_currency">
            Końcowa cena:
            <m-ui-currency
              [item]="{ currency_id: currencyAmount.pln.currency_id, amount: auction.my_bid?.has_won ? auction.my_bid?.amount : (auction.highest_bid?.amount || auction.min_bid) }"
              [stockView]="STOCK_VIEW.CUSTOM"
              [customIconSize]="'big'"
              [customClassStock]="'stock-san-a'"
              [lack]="auction.my_bid?.has_won ? !auction.my_bid?.currency_id && auction.my_bid?.amount > currencyAmount.pln?.balance : false"
            ></m-ui-currency>

            <button *ngIf="auction.my_bid?.has_won && auction.my_bid.currency_id === null"
                    [disabled]="auction.my_bid?.amount > currencyAmount.pln?.balance"
                    class="c-base c-secondary chose-btn pln"
                    (click)="chooseCurrency(currencyAmount.pln.currency_id)">
              Płacę w PLN
            </button>

            <div class="separator"></div>

<!--            <div [class.warn]="talarsBalance < (auction.my_bid?.amount * auction.pln_multiplier)">-->
              <m-ui-currency
                [item]="{ currency_id: currencyAmount.cash.currency_id, amount: auction.my_bid?.has_won ? (auction.my_bid?.amount * auction.pln_multiplier) : ((auction.highest_bid?.amount || auction.min_bid) * auction.pln_multiplier) }"
                [stockView]="STOCK_VIEW.CUSTOM"
                [customIconSize]="'big'"
                [customClassStock]="'stock-san-a'"
                [lack]="auction.my_bid?.has_won ? !auction.my_bid?.currency_id && (auction.my_bid?.amount * auction.pln_multiplier) > currencyAmount.cash?.balance : false"
              ></m-ui-currency>
<!--            </div>-->

            <button *ngIf="auction.my_bid?.has_won && auction.my_bid.currency_id === null"
                    class="c-base c-secondary chose-btn zt"
                    [disabled]="(auction.my_bid?.amount * auction.pln_multiplier) > currencyAmount.cash?.balance"
                    (click)="chooseCurrency(currencyAmount.cash.currency_id)">
              Płacę w Talarach
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="auction-id">id aukcji: {{ auction.id }}</div>
    <div class="auction-price" *ngIf="findBalanceByCurrencyId(auction.currency_id)?.key === 'cash'">
      <m-ui-currency
        [item]="{ currency_id: auction.currency_id, amount: currencyAmount.cash?.balance }"
        [stockView]="STOCK_VIEW.CUSTOM"
        [customIconSize]="'big'"
        [customClassStock]="'stock-san-a'"
        [lack]="false"
      ></m-ui-currency>
    </div>
  </ng-container>
</div>
