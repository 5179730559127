<div
  *ngIf="returnCurrencies || returnProducts"
  class="building-return-container"
>
  <div class="sub-title" *ngIf="!hideSubTitle">{{ title || 'Nagroda' }}:</div>

  <!-- RETURN CURRENCY -->
  <m-ui-currency
    *ngFor="let currency of returnCurrencies"
    [item]="currency"
    [other]="{value: '+'}"
    [stockView]="STOCK_VIEW.CUSTOM"
    [customIconSize]="'big'"
    [customClassStock]="'stock-san-a'"
    [lack]="false"
  ></m-ui-currency>

  <!-- RETURN PRODUCTS -->
  <m-ui-product
    *ngFor="let product of returnProducts"
    [item]="product"
    [other]="{value: '+'}"
    [stockView]="STOCK_VIEW.CUSTOM"
    [customIconSize]="'big'"
    [customClassStock]="'stock-san-a'"
    [lack]="false"
  ></m-ui-product>
</div>
