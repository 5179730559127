<div
  *ngIf="parameters.length"
  class="parameters-container"
>
  <div class="sub-title" *ngIf="!hideSubTitle">Parametry:</div>

  <m-ui-parameter
    *ngFor="let parameter of parameters"
    [item]="parameter"
    [other]="{value: (!hideExtraValue && groupTypeBuilding !== 'special' && parameter.income > 0 ? '+' : ''), amount: parameter.income}"
    [stockView]="STOCK_VIEW.CUSTOM"
    [customIconSize]="'big'"
    [customClassStock]="'stock-san-a'"
    [lack]="false"
    [ngClass]="parameter.income > 0 ? 'green' : 'red'"
  ></m-ui-parameter>
</div>
