import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { OwDate } from "../../../../../../../core/utility/ow-date.class";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../../shared-ui/mobile/consts/stock-view.const";
import { CARD_TEMPLATE } from "../../../consts/custom/card-template.const";
import { Task } from "../../../interfaces/custom/task.interface";

@Component({
  selector: "app-card-task-details",
  templateUrl: "./card-task-details.component.html",
})
export class CardTaskDetailsComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;

  STOCK_VIEW = STOCK_VIEW;
  CARD_TEMPLATE = CARD_TEMPLATE;

  @OwInject(MAT_DIALOG_DATA) data: {
    task: Task;
    cardTasks: Task[];
    allTasks: Task[];
    isChooseableView?: boolean;
    currentIndex: number;
    owDate: OwDate;
  };

  currentTask: Task;
  currentIndex = 0;
  pagination = {
    allowNext: false,
    allowPrev: false,
  };
  prizeMinInfo = PRIZE_MIN_INFO;

  indicatorName = "Wskaźnik techniczny 100%";

  ngOnInit() {
    this.currentTask = this.data.task;
    const indexTask: number = this.data.cardTasks.indexOf(this.currentTask);

    if (this.data.isChooseableView) {
      this.currentIndex = this.data.currentIndex;
    } else {
      this.currentIndex = indexTask;
    }

    this.changeTask(this.data.cardTasks[indexTask]);
  }

  checkPagination() {
    this.pagination.allowNext = this.currentIndex < this.data.cardTasks.length - 1;
    this.pagination.allowPrev = this.currentIndex > 0;
  }

  next() {
    if (this.pagination.allowNext) {
      this.currentIndex++;
      this.changeTask(this.data.cardTasks[this.currentIndex]);
    }
  }

  prev() {
    if (this.pagination.allowPrev) {
      this.currentIndex--;
      this.changeTask(this.data.cardTasks[this.currentIndex]);
    }
  }

  changeTask(task: Task) {
    this.currentTask = task;
    this.checkPagination();
  }

  openParentId() {
    this.changeTask(this.currentTask.nextTask);
  }
}

const PRIZE_MIN_INFO = `Za spełnienie wyznaczonego minimum otrzymasz nagrodę <br> w wysokości ustalonej dla Twojej grupy ligowej.`;
