<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <!-- NAVIGATION CENTER -->
  <m-ui-navigation-center
    *ngIf="!data.isChooseableView"
    (nextClicked)="next()"
    (prevClicked)="prev()"
    [hideNext]="!pagination.allowNext"
    [hidePrev]="!pagination.allowPrev"
  ></m-ui-navigation-center>

  <ng-container *ngIf="currentTask; else noTaskTpl">

    <div class="dialog-decoration">
      <span class="right"></span>
      <span class="bottom"></span>
    </div>

    <div class="mark-window-left-container">
      <!-- QUALITY -->
      <div class="mark-window-left" *ngIf="currentTask?.is_quality_multiplier">
        <img [src]="'icon_quality.png' | asset:'ui'">
      </div>

      <!-- CUSTOM BY CLASS NAME -->
      <div
        class="mark-window-left custom"
        [ngClass]="currentTask?.className"
      >
        <div class="image"></div>
      </div>
    </div>

    <!-- TITLE -->
    <div class="title">
      {{ currentTask.name }}
    </div>

    <!-- DESCRIPTION -->
    <div
      class="description"
      [innerText]="currentTask.description"
      owEllipsis
    ></div>

    <div class="content loading-opacity">
      <ow-perfect-scrollbar scrollGradient>

        <!-- BRANCH NAME -->
        <div class="branch-name">
          {{ playerService.notDorOrDorNotActive ? currentTask.branch_name : currentTask['region_name'] }}
          <ng-container *ngIf="currentTask.nextTask">
            <i (click)="openParentId()" class="fas fa-arrow-circle-right"></i>
          </ng-container>
        </div>

        <!-- PRIZES -->
        <div class="prizes">
          <div class="prize-container" *ngFor="let item of currentTask.scopes | keyvalue">
            <span class="percent">
              {{ currentTask['scope' + item.key] }}%
            </span>
            <div class="frame-white">
              <div class="prize">
                <ng-container *ngFor="let prize of currentTask.scopes[item.key].prizes">
                  <m-ui-currency
                    *ngIf="prize['type'] === 'currency'"
                    [stockView]="STOCK_VIEW.CUSTOM"
                    [item]="prize"
                    [lack]="false"
                    [customClassStock]="'stock-card-details'"
                  ></m-ui-currency>

                  <m-ui-product
                    *ngIf="prize['type'] === 'product'"
                    [stockView]="STOCK_VIEW.CUSTOM"
                    [item]="prize"
                    [lack]="false"
                    [customClassStock]="'stock-card-details'"
                  ></m-ui-product>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <!-- PROGRESS BARS -->
        <!-- PROGRESSBAR PERFORMANCE -->
        <div class="progressbar-container" *ngIf="currentTask.performance_percent !== null">
          <div class="box">
            <div class="progressbar-title">
              {{ currentTask.is_task_type_branch ? 'Stopień realizacji zadania w oddziale' : 'Stopień realizacji zadania' }}
            </div>
            <div class="progressbar-value">
              {{ currentTask.performance_percent }}%
            </div>
          </div>
          <div class="progress-container-after">
            <div class="progressbar">
              <div
                class="fill fill-color-1"
                [class.ten]="currentTask.performance_percent >= 100 && (!currentTask.is_task_type_branch ? currentTask.min_valid : true)"
                [style.width]="currentTask.performance_percent / 1.163 + '%'"
              ></div>
            </div>
          </div>
        </div>

        <!-- PROGRESSBAR SHARE -->
        <div class="progressbar-container" *ngIf="currentTask.share_percent !== null">
          <div class="box">
            <div class="progressbar-title">
              Twój udział w nagrodzie
              <ng-container *ngIf="currentTask.is_task_type_branch_weighted">
                <br>
                <span class="bracket-task-weighted">
                  (udział jest ważony liczbą unikatowych klientów z nową umową depozytową w danym miesiącu w oddziale, z wyłączeniem produkcji kierownika)
                </span>
              </ng-container>
            </div>
            <div class="progressbar-value">
              {{ currentTask.share_percent }}%
            </div>
          </div>
          <div class="progress-container-after">
            <div class="progressbar">
              <div
                class="fill fill-color-2"
                [style.width]="currentTask.share_percent / 1.163 + '%'"
              ></div>
            </div>
          </div>
        </div>

        <!-- PROGRESSBAR FORECAST -->
        <div class="progressbar-container" *ngIf="currentTask.forecast !== null">
          <div class="box">
            <div class="progressbar-title">
              Prognoza Twojego wyniku
            </div>
            <div class="progressbar-value">
              {{ currentTask.forecast }}%
            </div>
          </div>
          <div class="progress-container-after">
            <div class="progressbar">
              <div
                class="fill fill-color-3"
                [style.width]="currentTask.forecast / 1.163 + '%'"
              ></div>
            </div>
          </div>
        </div>

        <!-- INFO -->
        <div class="info" *ngIf="currentTask.is_task_type_branch">
          Na wysokość Twojej nagrody za udział w wykonaniu zadania składają się z 2 części.<br>
          1. Jeśli zostało przez Ciebie spełnione minimum, to otrzymasz część stałą w wysokości ustalonej dla grupy
          ligowej Twojego oddziału.<br>
          2. Niezależnie od spełnienia przez Ciebie minimum, otrzymasz także bonus za udział w realizacji zadania.
          Bonus jest wyliczany proporcjonalnie do Twojego udziału w wykonaniu zadania. W wyliczeniach nie uwzględnia się
          produkcji Kierownika.<br>
          Nagroda za udział w wykonaniu zadania w Oddziale, do którego było oddelegowanie, wyliczana jest
          na tych samych zasadach (według danych i grupy ligowej oddziału, do którego było oddelegowanie).
        </div>

        <!-- TABLES -->
        <div class="table">
          <table>
            <tbody>
            <tr>
              <td class="title left">WYKONANIE ZADANIA</td>
            </tr>

            <!-- ADDITIONAL DESCRIPTON -->
            <tr *ngIf="currentTask.additional_description">
              <td>
                {{ currentTask.additional_description }}
              </td>
            </tr>

            <!-- TARGET -->
            <tr
              *ngIf="currentTask.target_name !== indicatorName"
              class="border-bottom"
            >
              <td>
                {{ currentTask.target_name }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.target }}
              </td>
            </tr>

            <!-- NUMERATOR -->
            <tr class="border-bottom">
              <td>
                {{ currentTask.numerator_name }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.is_task_type_branch ? currentTask.sum_branch : currentTask.numerator }}
              </td>
            </tr>

            <!-- DENOMINATOR -->
            <tr
              class="border-bottom"
              *ngIf="currentTask.denominator_name !== indicatorName"
            >
              <td>
                {{ currentTask.denominator_name }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.denominator }}
              </td>
            </tr>

            <!-- SUMMARY -->
            <tr class="summary">
              <td>
                {{ currentTask.is_task_type_branch ? 'Wynik oddziału:' : 'Twój wynik:' }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.performance_percent_formatted }}
              </td>
            </tr>
            </tbody>
          </table>

          <table *ngIf="currentTask.is_task_type_branch">
            <tbody>
            <tr>
              <td class="title">UDZIAŁ W NAGRODZIE</td>
            </tr>
            <tr class="border-bottom">
              <td>
                Twoja sprzedaż:
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.is_task_type_branch_weighted ? currentTask.numerator_weighted : currentTask.numerator }}
              </td>
            </tr>
            <tr>
              <td>
                Sprzedaż wszystkich doradców oraz kasjerów w oddziale:
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.is_task_type_branch_weighted ? currentTask.sum_branch_weighted : currentTask.sum_branch_without_leader }}
              </td>
            </tr>
            </tbody>
          </table>

          <!-- TABLE ONLY SHOW MIN -->
          <table *ngIf="currentTask.is_showing_mins">
            <tbody>
            <tr>
              <td class="title left prize-min">
                <span>
                  Próg minimalny
                  <i
                    *ngIf="currentTask.rewards.minimums.length > 0"
                    class="far fa-info-circle"
                    [ngbTooltip]="prizeMinTooltipTpl"
                    placement="right"
                  ></i>
                </span>

                <div class="min-prizes" *ngIf="currentTask.rewards.minimums.length > 0">
                  <ng-container *ngFor="let prize of currentTask.rewards.minimums">
                    <m-ui-currency
                      *ngIf="prize['type'] === 'currency'"
                      [stockView]="STOCK_VIEW.CUSTOM"
                      [item]="prize"
                      [lack]="false"
                      [customClassStock]="'stock-card-details'"
                    ></m-ui-currency>

                    <m-ui-product
                      *ngIf="prize['type'] === 'product'"
                      [stockView]="STOCK_VIEW.CUSTOM"
                      [item]="prize"
                      [lack]="false"
                      [customClassStock]="'stock-card-details'"
                    ></m-ui-product>
                  </ng-container>
                </div>
              </td>
            </tr>
            <!-- MIN 1 -->
            <tr *ngIf="currentTask.min1_name" class="border-bottom">
              <td>
                {{ currentTask.min1_name }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.min1_value_formatted }}
              </td>
            </tr>

            <!-- MIN 2 -->
            <tr *ngIf="currentTask.min2_name" class="border-bottom">
              <td>
                {{ currentTask.min2_name }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.min2_value_formatted }}
              </td>
            </tr>

            <!-- MIN 3 -->
            <tr *ngIf="currentTask.min3_name" class="border-bottom">
              <td>
                {{ currentTask.min3_name }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.min3_value_formatted }}
              </td>
            </tr>

            <!-- MAX 1 -->
            <tr *ngIf="currentTask.max1_name" class="border-bottom">
              <td>
                {{ currentTask.max1_name }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.max1_value_formatted }}
              </td>
            </tr>

            <!-- MIN VALID -->
            <tr>
              <td>
                {{ currentTask.min2_name ? 'Minima spełnione' : 'Minimum spełnione:' }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.min_valid ? 'Tak' : 'Nie' }}
              </td>
            </tr>
            </tbody>
          </table>

          <!-- ADDITIONAL INFO -->
          <table *ngIf="currentTask.additional_info_description">
            <tbody>
            <tr>
              <td class="title">DODATKOWE INFORMACJE</td>
            </tr>
            <tr>
              <td>
                {{ currentTask.additional_info_description }}
              </td>
              <td class="text-right no-wrap">
                {{ currentTask.additional_info_value_formatted }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </ow-perfect-scrollbar>
    </div>
  </ng-container>

  <!-- CLEARED -->
  <div class="cleared" *ngIf="currentTask?.is_cleared"></div>

  <!-- WF STICKER -->
  <div class="wf-sticker" *ngIf="currentTask?.card_template_id === CARD_TEMPLATE.WF"></div>

  <ng-template #noTaskTpl>
    <div class="sanjungle-logo"></div>
  </ng-template>
</div>

<ng-template #prizeMinTooltipTpl>
  <div [innerHTML]="prizeMinInfo"></div>
</ng-template>
