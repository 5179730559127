<div *ngIf="!isLoading && achievements.length" class="container">
  <ow-perfect-scrollbar [scrollGradient]="achievements">
    <div
      *ngFor="let item of achievements"
      [class.blocked]="!item.is_available"
    >
      <div
        [class.flipped]="item.flipped"
        class="achievement"
      >
        <figure class="front">
          <div
            (click)="item.flipped = !item.flipped"
            [class.blocked]="!item.is_available"
            class="flip-card"
          >
            <i
              [class.rotate]="!item.flipped"
              class="fas fa-share"
            ></i>
          </div>

          <img [src]="item.icon_active + '.png' | asset: 'ui'" class="l-icon"/>

          <div class="title">{{ item.name }}</div>

          <div class="progress-container-after">
            <div class="progressbar">
              <div *ngIf="item.polygons?.length > 0" class="polygons">
                <div
                  *ngFor="let threshold of item.polygons; let i = index"
                  [style.left]="((i + 1) * 100) / item.threshold.limit + '%'"
                ></div>
              </div>

              <div
                [class.fill-color-4]="item.isProgressGreen"
                [class.fill-color-5]="!item.isProgressGreen"
                [style.width]="(item.threshold.offset * 100) / item.threshold.limit + '%'"
                class="fill"
              ></div>
            </div>
          </div>

          <div class="value">
            zdobyte: {{ item.threshold.offset }} z {{ item.threshold.limit }}
          </div>

          <m-ui-separator></m-ui-separator>

          <div class="prizes-box">
            <div class="target margin-v2">
              Nagroda do zdobycia:

              <img (click)="openPrize(item)" [src]="'trophy.png' | asset: 'ui'" />
            </div>

            <div
              [matTooltipDisabled]="item.is_achievable"
              [matTooltip]="'Niespełniony warunek'"
              class="target"
              matTooltipPosition="above"
            >
              Twoja nagroda:

              <div class="squares">
                <i
                  [ngClass]="item.sumPrizes?.amount > 0 ? 'far fa-check-square' : 'far fa-minus-square'"
                  class="contour"
                ></i>
                <i
                  [ngClass]="item.sumPrizes?.amount > 0 ? 'fas fa-check-square green' : 'fas fa-minus-square red'"
                  class="color"
                ></i>
              </div>
            </div>
          </div>

          <m-ui-separator></m-ui-separator>

          <div class="target available">
            <div>
              Pozostało dostępnych: {{ item.availability.available }} z {{ item.availability.limit }}
            </div>

            <div (click)="openAchievementDetails(item.achievement_id)" class="details">
              <i class="far fa-chevron-circle-right"></i>
            </div>
          </div>
        </figure>

        <!-- BACK -->
        <figure class="back">
          <div
            (click)="item.flipped = !item.flipped"
            [class.visibility-hidden]="!item.flipped && !item.is_available"
            class="flip-card"
          >
            <i
              [class.rotate]="!item.flipped"
              class="fas fa-share"
            ></i>
          </div>

          <img [src]="item.icon_active + '.png' | asset: 'ui'" class="l-icon"/>

          <div class="title">{{ item.name }}</div>

          <div class="desc margin-v1">{{ item.description }}</div>

          <ng-container *ngIf="item.completions.length > 0">
            <div class="desc">
              Zdobyte:
              <br>
              <ng-container *ngFor="let b of item.completions">
                {{ b?.completion_date | date: 'dd.MM.yy' }}
              </ng-container>
            </div>
          </ng-container>
        </figure>
      </div>
    </div>
  </ow-perfect-scrollbar>
</div>

<ng-container *ngIf="!isLoading && achievements.length === 0">
  <div class="no-item">Brak aktywnych osiągnięć mikroligowych</div>
</ng-container>
