import { TabTop } from '../interfaces/tab-top.interface';
import { RANK_NAME } from '../../../../../const/custom/rank-name.const';
import { PAGINATION_TYPES } from '../../../../../const/custom/pagination-types.const';

const disabledTooltip = 'Rankingi dostępne wkrótce';

export const TABS: TabTop[] = [
  {
    label: 'Zadania sprzedażowe',
    data: 'business',
    index: RANK_NAME.BUSINESS,
    pagination: PAGINATION_TYPES.MONTH,
    visible: true,
    color: 'green',
    gui_unlocks: 'rank_tab_business',
  },
  {
    label: 'Liga miesięczna',
    data: 'leagues',
    index: RANK_NAME.LEAGUE_MONTH,
    pagination: PAGINATION_TYPES.MONTH,
    visible: true,
    color: 'yellow',
    gui_unlocks: 'rank_tab_league_month',
  },
  {
    label: 'Liga kwartalna',
    data: 'leagues',
    index: RANK_NAME.LEAGUE_QUARTER,
    pagination: PAGINATION_TYPES.QUARTER,
    visible: true,
    color: 'blue-light',
    gui_unlocks: 'rank_tab_league_quarter',
  },
  {
    label: 'Liga półroczna',
    data: 'leagues',
    index: RANK_NAME.LEAGUE_HALF,
    pagination: PAGINATION_TYPES.HALF,
    visible: true,
    color: 'red',
    gui_unlocks: 'rank_tab_league_half',
  },
  {
    label: 'Liga roczna',
    data: 'leagues',
    index: RANK_NAME.LEAGUE_YEAR,
    pagination: PAGINATION_TYPES.YEAR,
    visible: true,
    color: 'purple',
    gui_unlocks: 'rank_tab_league_year',
  },
  {
    label: 'Świat gry',
    data: 'game-ranks',
    index: RANK_NAME.GAME,
    pagination: PAGINATION_TYPES.MONTH,
    visible: true,
    color: 'orange',
    gui_unlocks: 'rank_tab_game',
  },
];
