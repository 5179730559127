import { Injectable } from "@angular/core";
import { PlayerService } from "../../../../../player/providers/player.service";
import { NotificationsService } from "../../../../services/custom/notifications.service";

@Injectable({
  providedIn: "root",
})
export class ClearNotificationService {
  constructor(
    private playerService: PlayerService,
    private notificationsService: NotificationsService
  ) {}

  clearNotification(type: string[], playerId?: number, list?: boolean) {
    if (!playerId || this.playerService.getActivePlayerId() === playerId) {
      type.map(item => {
        if (this.playerService.player[`gui_notifications${list ? "_list" : ""}`]?.[item] > 0) {
          this.notificationsService.setNotificationRead(item, playerId).subscribe();
        }
      });
    } else {
      this.playerService.getPlayer(playerId).subscribe(player => {
        type.map(item => {
          if (player[`gui_notifications${list ? "_list" : ""}`]?.[item] > 0) {
            this.notificationsService.setNotificationRead(item, playerId).subscribe();
          }
        });
      });
    }
  }
}
