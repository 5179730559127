<div class="ow-dialog">
  <!-- DIALOG EXIT -->

  <div class="description"><strong>WYBÓR KONTRAKTU</strong><br /><br />
    Czy na pewno chcesz wybrać ten kontrakt? Decyzja jest nieodwracalna.
  </div>

  <div class="buttons-wrapper">
    <button class="c-base c-primary" (click)="close(true)">
      Potwierdź
    </button>
    <button class="c-base c-secondary" (click)="close()">
      Anuluj
    </button>
  </div>

</div>
