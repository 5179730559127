import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../../core/providers/api.service";
import { PlayerService } from "../../../../../player/providers/player.service";
import { Observable } from "rxjs";
import { GetBusinessLeagueCardRequest } from "../interfaces/request/get-business-league-card-request.interface";
import { GetBusinessLeagueQuarterlyRequest } from "../interfaces/request/get-business-league-quarterly-request.interface";
import { GetBusinessLeagueMonthlyRequest } from "../interfaces/request/get-business-league-monthly-request.interface";
import { GetBusinessLeagueCardResponse } from "../interfaces/response/get-business-league-card-response.interface";
import { GetBusinessLeagueEstimateRequest } from "../interfaces/request/get-business-league-estimate-request.interface";
import { Branch } from "../../../../interfaces/branch.interface";

@Injectable({
  providedIn: "root",
})
export class ApiBusinessLeagueService {
  constructor(
    public apiService: ApiService,
    private playerService: PlayerService
  ) {}

  card(data: GetBusinessLeagueCardRequest, playerId?: number, branch_id?: number): Observable<GetBusinessLeagueCardResponse> {
    if (branch_id) {
      return this.apiService.get(`branch/${branch_id}/leagues/card/${data.year}/${data.month}`);
    }
    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.get(`player/${playerId}/leagues/card/${data.year}/${data.month}`);
  }

  monthly(data: GetBusinessLeagueMonthlyRequest, branch?: Branch): Observable<any> {
    const { year, month } = data;

    if (branch && !branch.manager_player_id) {
      return this.apiService.get(`branch/${branch.branch_id}/leagues/monthly/${year}/${month}`);
    }

    let playerId = branch?.manager_player_id;
    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.get(`player/${playerId}/leagues/monthly/${year}/${month}`);
  }

  quarterly(data: GetBusinessLeagueQuarterlyRequest, branch?: Branch): Observable<any> {
    const { year, quarter } = data;

    if (branch && !branch.manager_player_id) {
      return this.apiService.get(`branch/${branch.branch_id}/leagues/quarterly/${year}/${quarter}`);
    }

    let playerId = branch?.manager_player_id;
    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.get(`player/${playerId}/leagues/quarterly/${year}/${quarter}`);
  }

  estimate({ task_id, forecast }: GetBusinessLeagueEstimateRequest, playerId?: number) {
    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.get(`player/${playerId}/leagues/estimate/${task_id}/${forecast}`);
  }

  deleteEstimations(playerId?: number) {
    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.delete(`player/${playerId}/leagues/estimations`);
  }

  achievements(playerId?: number) {
    playerId = playerId || this.playerService.getActivePlayerId();

    return this.apiService.get(`achievement/player/${playerId}`);
  }

  achievementDetails(achievement_id: number, playerId?: number) {
    playerId = playerId || this.playerService.getActivePlayerId();

    return this.apiService.get(`achievement/${achievement_id}/player/${playerId}`);
  }
}
