import { AfterContentInit, Component, Input, OnChanges, OnInit } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../../../core/decorators/ow-inject.decorator";
import { PlayerService } from "../../../../../../../../../player/providers/player.service";
import { STOCK_VIEW } from "../../../../../../../shared-ui/mobile/consts/stock-view.const";
import { PlayerMicroLeagueDetails } from "../../../../../../interfaces/custom/business-challenges.interface";
import * as moment from "moment";

@Component({
  selector: "app-micro-league-details",
  templateUrl: "./micro-league-details.component.html",
})
export class MicroLeagueDetailsComponent extends AbstractInjectBaseComponent {
  @OwInject(PlayerService) playerService: PlayerService;

  @Input() isLoading: boolean;
  @Input() isCleared: boolean;
  private _microLeagueDetails: PlayerMicroLeagueDetails;
  groupPrizes: boolean = false;
  tempMyPosition?: number;
  // @Input() microLeagueDetails: PlayerMicroLeagueDetails;
  @Input()
  set microLeagueDetails(value: PlayerMicroLeagueDetails) {
    this._microLeagueDetails = value;
    if (this._microLeagueDetails?.items) {
      this._microLeagueDetails.items[this._microLeagueDetails.my_position_index].isMyPosition = true;
      this._microLeagueDetails?.items.sort(this.sortLeagueDetailsByPosition);
      this.tempMyPosition = this._microLeagueDetails?.items.findIndex(item => item.isMyPosition);
    }
  }
  get microLeagueDetails(): PlayerMicroLeagueDetails {
    return this._microLeagueDetails;
  }
  protected readonly STOCK_VIEW = STOCK_VIEW;

  toggleGroupPrizes() {
    this.groupPrizes = !this.groupPrizes;
  }

  checkDateIsAfter(date: string): boolean {
    return moment(date).isSameOrAfter(new Date());
  }

  //put all members with position === 0 at the end of list
  sortLeagueDetailsByPosition(first, second) {
    if (second.position === 0) {
      return -1;
    } else {
      return 0;
    }
  }
}
