import { Routes } from "@angular/router";
import {RegistrationComponent} from '../../base/custom/components/registration/registration.component';
import {RegistrationConfirmComponent} from '../../base/custom/components/registration-confirm/registration-confirm.component';
import {ForgotPasswordComponent} from '../../base/custom/components/forgot-password/forgot-password.component';
import {ChangePasswordComponent} from '../../base/custom/components/change-password/change-password.component';
import {ResetPasswordComponent} from '../../base/custom/components/reset-password/reset-password.component';
import {LoginComponent} from '../../base/custom/components/login/login.component';

export const CUSTOM_ROUTING_AUTH_CHILDREN: Routes = [
  {
    path: 'registration',
    component: RegistrationComponent
  },
  {
    path: 'invitation/:token',
    component: RegistrationConfirmComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
  },
  {
    path: 'password/reset/:token',
    component: ResetPasswordComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    component: LoginComponent,
  },
];
