<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button *ngIf="!isMissionContract"></m-ui-back-button>

  <ng-container *ngIf="mission">
    <div class="mark-window-left">
      <img [src]="mission.icon + '.png' | asset:'missions/big'"/>
    </div>

    <!-- BOOKMARKS -->
    <div class="bookmarks">
      <div class="bookmark green">
        <span>{{ isMissionContract ? 'Misje kontraktowe' : 'Misje' }}</span>
      </div>
    </div>

    <!-- TIMER -->
    <div class="timer" *ngIf="mission.expires_at">
      <i class="fas fa-clock"></i> {{ timer | toHour }}
    </div>

    <!-- MISSION DESCRIPTION -->
    <div class="mission-title padding-right loading-opacity" [innerHTML]="mission.title"></div>
    <div
      class="inner-html-description padding-right loading-opacity"
      [innerHTML]="mission.description | safe:'html'"
    ></div>

    <!-- MISSION PRIZES -->
    <div class="mission-prizes padding-right loading-opacity">

      <!-- POINTS EXPERIENCE -->
      <ng-container *ngIf="mission.points_prize">
        <m-ui-stock-other
          [item]="{icon: 'exp', amount: mission.points_prize}"
          [stockView]="STOCK_VIEW.CUSTOM"
          [customIconSize]="'big'"
          [customClassStock]="'stock-san-a'"
          [other]="{value: '+ '}"
          [lack]="false"
        ></m-ui-stock-other>
      </ng-container>

      <!-- CURRENCY PRIZE -->
      <ng-container *ngFor="let currency of mission.currency_prizes">
        <m-ui-currency
          [item]="currency"
          [stockView]="STOCK_VIEW.CUSTOM"
          [customIconSize]="'big'"
          [customClassStock]="'stock-san-a'"
          [other]="{value: '+ '}"
          [lack]="false"
        ></m-ui-currency>
      </ng-container>

      <!-- PRODUCTS PRIZE -->
      <ng-container *ngFor="let product of mission.product_prizes">
        <m-ui-product
          [item]="product"
          [other]="{value: '+ '}"
          [stockView]="STOCK_VIEW.CUSTOM"
          [customIconSize]="'big'"
          [customClassStock]="'stock-san-a'"
          [lack]="false"
        ></m-ui-product>
      </ng-container>
    </div>

    <!-- MISSION SEPARATOR-->
    <m-ui-separator title="Warunki zakończenia misji"></m-ui-separator>

    <!-- MISSION CONDITIONS -->
    <div class="frame loading-opacity">
      <ow-perfect-scrollbar class="loading-opacity" scrollGradient>
        <table class="conditions">
          <tbody>
          <tr *ngFor="let condition of mission.conditions" [class.completed]="condition.completed_at">

            <!-- CONDITION CHECKBOX -->
            <td class="td-check">
              <i [ngClass]="condition.completed_at ? 'fas fa-check-square' : 'far fa-minus-square'"></i>
            </td>

            <!-- CONDITION NAME -->
            <td class="td-name">
              {{ condition.name }}
            </td>

            <!-- CONDITION BUTTON HAND OVER -->
            <td class="td-transfer">
              <button
                class="hand-over"
                *ngIf="condition.is_hand_over"
                [disabled]="condition.completed_at"
                (click)="openMissionTransfer(condition)"
              >
                Przekaż
              </button>
            </td>

            <!-- CONDITION TARGET -->
            <td class="td-condition">
              <ng-container [ngSwitch]="condition.target_type">
                <!-- INTEGER -->
                <ng-container *ngSwitchCase="MISSION_CONDITION_VALUE_TYPE.INTEGER">
                  {{ condition.value }}/{{ condition.target }}
                </ng-container>

                <!-- PERCENTAGE -->
                <ng-container *ngSwitchCase="MISSION_CONDITION_VALUE_TYPE.PERCENTAGE">
                  {{ condition.value | percent:'1.2-2'}}/{{ condition.target | percent:'1.2-2' }}
                </ng-container>
              </ng-container>
            </td>
          </tr>
          </tbody>
        </table>
      </ow-perfect-scrollbar>
    </div>

    <!-- BOTTOM BAR -->
    <div class="bottom-buttons loading-opacity">
      <!-- REJECT -->
      <div class="bar-button base" *ngIf="mission.rejectable && noConditionsComplete">
        <button
          class="c-base c-primary"
          (click)="reject()"
        >
          Odrzuć
        </button>
      </div>

      <!-- COLLECT -->
      <div class="bar-button base"
           [matTooltip]="buttonConfig.tooltip">
        <button
          class="c-base c-secondary"
          [disabled]="missionExpired || noConditionsComplete || buttonConfig.disabled"
          (click)="collect()"
        >
          Zakończ
        </button>
      </div>

      <!-- SHOW ON MAP -->
      <div class="bar-button base" *ngIf="mission.center_map_on_tile_id">
        <button
          class="c-base c-primary"
          (click)="showOnMap(mission.center_map)"
        >
          {{ 'missions.show-on-map' | myTranslate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
