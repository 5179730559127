<div class="global-dialog ow-dialog c-window-d c-quaternary no-transparent loading-opacity" *ngIf="buildingDetails">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <div class="details">
    <img
      class="tracking-picture"
      [src]="buildingDetails | buildingIcon:false"
    >

    <h2>{{ buildingDetails.name }}</h2>

    <button
      class="c-base c-primary"
      (click)="trackingContinue()"
    >
      Kontynuuj
    </button>
  </div>
</div>
