import { Component, OnDestroy, OnInit } from "@angular/core";

import { translate } from "../../../../../../../../core/helpers/translate.helper";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { BuildingConfig } from "../../../../../../game-engine/interfaces/building-config";
import { AbstractBuildingsListComponent } from "../../../../abstract/core/abstract-buildings-list.component";
import { BuildingBuildData } from "../../../../interfaces/core/dialogs/building-build-data.interface";
import { BuildingBuildComponent } from "../building-build/building-build.component";

@Component({
  selector: "app-building-list",
  templateUrl: "./building-list.component.html",
})
export class BuildingListComponent extends AbstractBuildingsListComponent implements OnInit, OnDestroy {
  buildingBuildComponent = BuildingBuildComponent;
  isLoading: boolean;
  swiperConfig = {
    slidesPerView: 3,
    initialSlide: 1,
    centeredSlides: true,
  };

  ngOnInit() {
    this.isLoading = true;
    this.subscribePlayer();
    this.subscribeBoardTile();
  }

  openBuildingDetails(buildingId: number) {
    const buildingBuildData: BuildingBuildData = {
      buildingId,
      playerTileId: this.data.playerTileId,
    };

    this.dialogService.open(this.buildingBuildComponent, {
      data: buildingBuildData,
    });
  }

  getBuildingsByPlayerTileId() {
    this.buildingsService.getBuildingsByPlayerTileId(this.data.playerTileId).subscribe(
      (buildings: BuildingConfig[]) => {
        if (buildings.length === 0) {
          this.matDialogRef.close();

          this.dialogService.openAlert({
            description: this.tile.empty_buildings_list_message || translate("building-list.empty-buildings-list-message"),
          });
        } else {
          this.buildings = buildings.map(building => {
            building = this.setOrderGroup(building);
            building = this.getCurrenciesBalance(building);
            building = this.setBuildPricesDisplay(building);
            return building;
          });

          this.setOrders();
          this.checkEmpty();
          this.setSwiper();
          this.isLoading = false;
        }
      },
      () => (this.isLoading = false)
    );
  }

  ngOnDestroy(): void {
    unsubscribeObject(this.subs);
  }
}
