import { Component, OnDestroy, OnInit } from "@angular/core";
import { select } from "@ngrx/store";
import { filter } from "rxjs/operators";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { ParametersService } from "../../../../../../../../core/providers/parameters.service";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { selectGameBoardTile } from "../../../../../../../../store/game/selectors";
import { BUILDING_GROUP_TYPE_DAILY_PRODUCTION } from "../../../../../../constants";
import { BuildingProductionData } from "../../../../../../interfaces/production.interfaces";
import { ProductionService } from "../../../../../../services/production.service";
import { AbstractBuildingInfoComponent } from "../../../../abstract/core/abstract-building-info.component";
import { getAutomaticProductHelper } from "../../../../helpers/custom/get-automatic-product.helper";
import { getStorageValueHelper } from "../../../../helpers/custom/get-storage-value.helper";
import { getNotOutcomeParameters, getOutcomeParameters } from "../../../../helpers/custom/outcome-parameters.helper";
import { removeDuplicateMax } from "../../../../helpers/custom/remove-duplicate-max.helper";

@Component({
  selector: "app-san-building-info",
  templateUrl: "./building-info.component.html",
})
export class BuildingInfoComponent extends AbstractBuildingInfoComponent implements OnInit, OnDestroy {
  @OwInject(ProductionService) productionService: ProductionService;
  @OwInject(ParametersService) parametersService: ParametersService;
  BUILDING_GROUP_TYPE_DAILY_PRODUCTION = BUILDING_GROUP_TYPE_DAILY_PRODUCTION;
  production: BuildingProductionData[];

  automaticCurrency: {
    currency_id: number;
    amount: number;
  };

  automaticProduct: {
    product_id: number;
    amount: number;
  };

  storageValue: number;

  notOutcomeParameters: any[];
  outcomeParameters: any[];

  ngOnInit() {
    if (this.data["player_building_id"]) {
      this.getPlayerBuildingDetails(this.data["player_building_id"]);
    } else {
      this.subscribeBoardTile();
    }
  }

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameBoardTile, { playerTileId: this.data.playerTileId }),
        filter(state => !!state)
      )
      .subscribe(tile => {
        this.getPlayerBuildingDetails(tile.player_building.player_building_id);
      });
  }

  afterBuildingDetails() {
    this.getBuildingProduction();
    this.setAutoProduction();
    this.setStorageSize();
    this.setParameters();
  }

  getBuildingProduction() {
    this.productionService.getBuildingProduction(this.buildingDetails.building_id).subscribe(
      (production: any[]) => {
        this.production = removeDuplicateMax(production);
      },
      error => {
        if (error.status === 404) {
          error.defaultHandler.unsubscribe();
        }
      }
    );
  }

  setParameters() {
    this.notOutcomeParameters = getNotOutcomeParameters(this.buildingDetails.parameters);
    this.outcomeParameters = getOutcomeParameters(this.buildingDetails.parameters);
  }

  setAutoProduction() {
    const automaticProduction = getAutomaticProductHelper(this.buildingDetails);

    this.automaticCurrency = automaticProduction.automaticCurrency;
    this.automaticProduct = automaticProduction.automaticProduct;
  }

  setStorageSize() {
    this.storageValue = getStorageValueHelper(this.buildingDetails);
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
