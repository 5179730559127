export enum MiniGameEventsEnum {
  LOAD_PROGRESS = "LOAD_PROGRESS",
  LOAD_COMPLETE = "LOAD_COMPLETE",
  GAME_START = "GAME_START",
  GAME_PAUSE = "GAME_PAUSE",
  GAME_RESUME = "GAME_RESUME",
  PROGRESS = "PROGRESS",
  GAME_OVER = "GAME_OVER",
  DESTROY = "DESTROY",
}
