import { Component, OnDestroy, OnInit } from "@angular/core";
import { select } from "@ngrx/store";
import { filter, take } from "rxjs/operators";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { GlobalService } from "../../../../../../../../core/providers/global.service";
import { ParametersService } from "../../../../../../../../core/providers/parameters.service";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { BoardTileState } from "../../../../../../../../store/game/interfaces/board-tile.state";
import { selectGameBoardTile } from "../../../../../../../../store/game/selectors";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { GAME_EVENTS } from "../../../../../../constants";
import { PlayerBuilding } from "../../../../../../game-engine/interfaces/player-building.config";
import { BASIC_BOOT_SCENE } from "../../../../../../game-engine/scenes-basic/basic.constants";
import { AbstractBuildingSpecialComponent } from "../../../../abstract/core/abstract-building-special.component";
import { getAutomaticProductHelper } from "../../../../helpers/custom/get-automatic-product.helper";
import { getStorageValueHelper } from "../../../../helpers/custom/get-storage-value.helper";
import { getNotOutcomeParameters, getOutcomeParameters } from "../../../../helpers/custom/outcome-parameters.helper";
import { upgradeDifferenceParameters } from '../../../../helpers/core/upgrade.helpers';
import { forkJoin, of } from 'rxjs';

@Component({
  selector: "app-custom-building-special",
  templateUrl: "./building-special.component.html",
})
export class BuildingSpecialComponent extends AbstractBuildingSpecialComponent implements OnInit, OnDestroy {
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ParametersService) parametersService: ParametersService;
  boardTile: BoardTileState;
  currentBuildingFullDetails: PlayerBuilding;
  notOutcomeParameters: any[];
  outcomeParameters: any[];
  storageValue;
  automaticCurrency;
  automaticProduct;

  // used for comparing parameters between current_lvl and next one; for instance - if lvl 1 requires 40 param and lvl 2 requires 60 param, then it should show 20 param
  notOutcomeParametersUpgrade;
  outcomeParametersUpgrade;

  notOutcomeParametersDiffParameters;

  outcomeParametersDiffParameters;
  outcomeParametersRequirements;

  ngOnInit() {
    if (this.data["boardTile"]) {
      this.afterBoard(this.data["boardTile"]);
    } else {
      this.subscribeBoardTile();
    }
  }

  goToPlace() {
    this.buildingDetails.upgrade_button_name = "Przejdź do wioski";
  }

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameBoardTile, { playerTileId: this.data.playerTileId }),
        filter(state => !!state),
        take(1)
      )
      .subscribe(tile => {
        this.afterBoard(tile);
      });
  }

  afterBoard(tile: BoardTileState) {
    this.boardTile = tile;
    this.buildingDetails = tile.player_building;


    forkJoin([
      this.getBuildingDetails(this.buildingDetails.upgrade_building_id ?? null),
      this.buildingsService.getPlayerBuildingDetails(this.buildingDetails.player_building_id)
    ]).subscribe(([upgradeBuildingDetails, buildingDetails]) => {
      this.currentBuildingFullDetails = buildingDetails as any;

      if (upgradeBuildingDetails) {
        this.upgradeBuildingDetails = upgradeBuildingDetails;
        this.setDifferentGroup();
        this.getUnlockedBuildings();
        this.getUpgradeDifferenceParameters();
        this.setSwiper();
      }
    })
  }

  getBuildingDetails(buildingId: number) {
    if (!buildingId) {
      return of(null)
    }

    return this.buildingsService.getBuildingDetails(buildingId);
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }

  goToCityScene() {
    if (this.boardTile.open_player_island_id) {
      this.playerService.playerRegions.pipe(take(1)).subscribe(regionsData => {
        let targetIsland;
        regionsData.regions.find(
          regionData => (targetIsland = regionData.islands.find(island => island.player_island_id === this.boardTile.open_player_island_id))
        );
        this.globalService.globalEvents.emit({
          name: GAME_EVENTS.START_SCENE,
          value: BASIC_BOOT_SCENE,
          data: {
            sceneConfigKey: targetIsland ? targetIsland.config_name : this.buildingDetails.icon,
            playerIslandId: this.boardTile.open_player_island_id,
          },
        });
      });
      this.matDialogRef.close();
    }
  }

  setDifferentGroup() {
    super.setDifferentGroup();
    this.getUpgradeDifferenceParameters();
    this.setStorageSize();
    this.setAutoProduction();
  }


  getUpgradeDifferenceParameters() {
    this.setParameters();

    // compare parameters difference(what's the cost of current building compared to the next level)
    this.notOutcomeParametersDiffParameters = upgradeDifferenceParameters(
      this.notOutcomeParameters,
      this.notOutcomeParametersUpgrade,
      true
    );

    this.outcomeParametersDiffParameters = upgradeDifferenceParameters(this.outcomeParameters, this.outcomeParametersUpgrade);

    // we're filtering stuff that didn't change
    this.outcomeParametersDiffParameters = this.outcomeParametersDiffParameters.filter(x => x.upgrade !== null);
    this.notOutcomeParametersDiffParameters = this.notOutcomeParametersDiffParameters.filter(x => x.upgrade !== null && x.parameters.income_diff !== 0);

    this.outcomeParametersRequirements = this.outcomeParametersDiffParameters.map(parameter => {
      return {
        ...parameter.upgrade,
        outcome: parameter.parameters.outcome_diff === 0 ? 0 : parameter.parameters.outcome_diff || parameter.upgrade.outcome,
      };
    });
  }

  setParameters() {
    // current building requirements
    this.notOutcomeParameters = getNotOutcomeParameters(this.currentBuildingFullDetails.parameters, true);
    this.outcomeParameters = getOutcomeParameters(this.currentBuildingFullDetails.parameters);

    // requirements of building after lvl up - if exist
    if (this.upgradeBuildingDetails) {
      this.notOutcomeParametersUpgrade = getNotOutcomeParameters(this.upgradeBuildingDetails.parameters, true);
      this.outcomeParametersUpgrade = getOutcomeParameters(this.upgradeBuildingDetails.parameters);
    }
  }

  setAutoProduction() {
    const automaticProduction = getAutomaticProductHelper(this.upgradeBuildingDetails);

    this.automaticCurrency = automaticProduction.automaticCurrency;
    this.automaticProduct = automaticProduction.automaticProduct;
  }

  setStorageSize() {
    this.storageValue = getStorageValueHelper(this.upgradeBuildingDetails);
  }

  close() {
    this.matDialogRef.close();
  }
}
