<div class="global-dialog ow-dialog c-window-long">
  <loading></loading>

  <m-ui-close-button [preventDefault]="true"  (clicked)="openCloseConfirm()"></m-ui-close-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Kalendarz Biznesowy</span>
      </div>
    </div>
  </div>
  <button class="previous" (click)="prev()" [disabled] = "!allowPrev">
    <i class="far fa-chevron-square-left nav-arrow"></i>
  </button>
  <button class="next" (click)="next()" [disabled] = "!allowNext">
    <i class="far fa-chevron-square-right nav-arrow"></i>
  </button>
  <div *ngIf="notSeenEventsOnNextPage" class="arrow-new"><span>NOWE</span></div>

  <div class="calendar-wrapper">
    <div class="box header label"></div>
    <div *ngFor="let date of datesToShow; let i = index; let grid" class="box header" [ngClass]="{
                    'current': date.isCurrentDate,
                }" [class]="'grid-column-' + (i+2)">
      <div class="date">{{ date.date | date: 'dd.MM' }}</div>
      <div class="name">{{ date.name }}</div>
    </div>
  </div>
  <div class="main-container">
    <ow-perfect-scrollbar>
      <div class="calendar-wrapper">
        <ng-container *ngFor="let type of types">
          <div class="box label {{type.className}}" [ngClass]="{'visible': true, 'hidden': (type.className === 'contract' && isCC) || (type.className === 'microleague' && isCC)}"><div class="type-name">{{type.label}}</div></div>
          <div *ngFor="let date of datesToShow; let i = index" class="box {{type.className}} {{'grid-column-' + (i+2)}}" [ngClass]="{
        'current': date.isCurrentDate,
        'visible': true,
        'hidden': (type.className === 'contract' && isCC) || (type.className === 'microleague' && isCC)
    }">
          </div>
        </ng-container>

        <ng-container *ngFor="let type of types">
          <div class="events {{type.className}}">
            <div *ngFor="let item of filteredEvents?.[type.className]" class="item from-{{item.startIndex}} to-{{item.endIndex}}" [ngClass]="{
        'event-start-visible': !item.eventStartedEarlier,
        'event-end-visible': !item.eventEndsLater,
        'tooltip-on-bottom': item.firstItem,
        'event-complete': item.eventComplete
    }">
              <div class="label">
                {{item.name}}
              </div>
              <div class="info">
                <button (click)="openDetails(item)" *ngIf="item.has_details">
                  <i class="fas fa-info"></i>
                </button>
              </div>
              <div *ngIf="!item.seen" class="new-event">NOWE</div>
              <div class="info-tooltip to-{{item.endIndex}}">{{item.name}} <m-ui-currency [hideValue]="true" *ngFor="let currency of item.currency_prizes" [stockView]="STOCK_VIEW.J" [item]="{currency_id: currency.id}" [lack]="false"></m-ui-currency> <m-ui-product [hideValue]="true" *ngFor="let prize of item.product_prizes" [stockView]="STOCK_VIEW.J" [item]="{product_id: prize.id}" [lack]="false"></m-ui-product></div>
            </div>
          </div>
        </ng-container>
      </div>
    </ow-perfect-scrollbar>
  </div>
  <div *ngIf="branchName" class="branch-name">{{ branchName }}</div>
</div>
