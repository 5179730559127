import { EventDialog } from "../../../../../../../core/interfaces/event-dialog.interface";
import { AuctionBidComponent } from "../../../base/custom/dialogs/auction-bid/auction-bid.component";
import { AuctionDetailsComponent } from "../../../base/custom/dialogs/auction-details/auction-details.component";
import { AuctionsComponent } from "../../../base/custom/dialogs/auctions/auctions.component";
import { EVENT_DIALOGS_NAMES_AUCTIONS_CUSTOM } from "./event-names.const";

export const EVENT_DIALOGS_AUCTIONS_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_AUCTIONS_CUSTOM.AUCTIONS]: AuctionsComponent,
  [EVENT_DIALOGS_NAMES_AUCTIONS_CUSTOM.DETAILS]: AuctionDetailsComponent,
  [EVENT_DIALOGS_NAMES_AUCTIONS_CUSTOM.BID]: AuctionBidComponent,
};
