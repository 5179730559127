<div class="global-dialog ow-dialog c-window-short dialog-decoration">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks center">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Wiadomość</span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="mission">
    <div class="icon-window-right">
      <img [src]="mission.icon + '.png' | asset:'missions/big'"/>
    </div>

    <div class="title loading-opacity" [innerHTML]="mission.title"></div>

    <div class="description loading-opacity" [innerHTML]="mission.description"></div>

    <button
      *ngIf="mission['is_permanent_local_event'] === null"
      class="c-base c-secondary loading-opacity"
      (click)="collect()"
    >
      Zamknij
    </button>
  </ng-container>
</div>
