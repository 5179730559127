import { Directive, Injectable, Input } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import * as R from "ramda";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { GlobalEvent } from "../../../../../../core/interfaces/shared.interfaces";
import { AssetsService } from "../../../../../../core/providers/assets.service";
import { GlobalService } from "../../../../../../core/providers/global.service";
import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { AppState } from "../../../../../../store/state";
import { UtilitySelectors } from "../../../../../../store/utility";
import { Player } from "../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../player/providers/player.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { PreviewPlayerService } from "../../../../game-gui/providers/preview-player.service";
import { BoardService } from "../../../../services/board.service";
import { GameService } from "../../../../services/game.service";
import { EVENT_DIALOGS_NAMES_MESSAGE } from "../../../message/consts/core/event-dialogs/event-names.const";
import { EVENT_DIALOGS_NAMES_MISSION } from "../../../mission/consts/core/event-dialogs/event-names";
import { EVENT_DIALOGS_NAMES_WAREHOUSE } from "../../../warehouse/consts/core/event-dialogs/event-names.const";
import { HudMenuMoreComponent } from "../../base/core/dialogs/hud-menu-more/hud-menu-more.component";
import { MENU_BUTTONS_ORDER_DISPLAY } from "../../hud-menu-buttons/const/core/menu-buttons-order-display.const";
import { MappedMenuButtonConfig, MenuButtonConfig } from "../../hud-menu-buttons/interfaces/core/menu-buttonts-config.interface";
import { MenuButtonsConfigService } from "../../hud-menu-buttons/services/core/menu-buttons-config.service";
import { ChangeMapCustomService } from "../../hud-menu-buttons/services/custom/change-map-custom.service";

@Directive()
@Injectable()
export abstract class AbstractHudMenuComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(PreviewPlayerService) previewPlayerService: PreviewPlayerService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(AssetsService) assetsService: AssetsService;
  @OwInject(MenuButtonsConfigService) menuButtonsConfigService: MenuButtonsConfigService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(GameService) gameService: GameService;
  @OwInject(BoardService) boardService: BoardService;
  @OwInject(Router) router: Router;
  @OwInject(ChangeMapCustomService) changeMapCustomService: ChangeMapCustomService;

  @Input() isDialog: boolean;
  @Input() data: {
    player: Player;
  };

  hasMissionsToCollect: Observable<boolean>;
  newProductsInStorage: Observable<any[]>;
  hasNewMessagesToRead: Observable<boolean>;
  storageId: number;

  menuButtons: MappedMenuButtonConfig[] = [];
  snapShotButtons: MappedMenuButtonConfig[] = [];
  MENU_BUTTONS_ORDER_DISPLAY = MENU_BUTTONS_ORDER_DISPLAY;
  otherButtonsIsActive = false;
  groups = [];

  subs: { scene: any; player: any; globalEmitter: any } = {
    scene: null,
    player: null,
    globalEmitter: null,
  };

  sceneConfig: { gui_unlocks_button: string; configKeyName: string; other: any };

  afterConstructor() {
    this.hasMissionsToCollect = this.store.pipe(select(UtilitySelectors.selectHasMissionsToCollect));
    this.newProductsInStorage = this.store.pipe(select(UtilitySelectors.selectNewProductInStorage));
    this.hasNewMessagesToRead = this.store.pipe(select(UtilitySelectors.selectHasNewMessagesToRead));
  }

  async handleGameEvents(event: GlobalEvent) {
    switch (event.name) {
      case "SCENE_CONFIG_CHANGE":
        this.sceneConfig = {
          gui_unlocks_button: event.value.gui_unlocks_button,
          configKeyName: event.value.configKeyName,
          other: event.value.other,
        };
        this.setMenu();
        break;
    }
  }

  setNotification() {
    this.menuButtons.forEach(button => {
      switch (button.name) {
        case "missions":
          button["customNotification"] = {
            type: "async",
            observable: this.hasMissionsToCollect,
          };
          break;

        case "messages":
          button["customNotification"] = {
            type: "async",
            observable: this.hasNewMessagesToRead,
          };
          break;

        case "warehouse":
          button["customNotification"] = {
            type: "async",
            observable: this.newProductsInStorage.pipe(map(arr => arr.length)),
          };
          break;
      }
    });
  }

  setMenu() {
    let buttons = this.menuButtonsConfigService.getButtonsMenuConfig({
      gui_unlocks: this.playerService.player.gui_unlocks,
      place: "menu-left",
      isDialog: this.isDialog,
      sceneConfig: this.sceneConfig,
    });

    buttons = buttons.filter(button => {
      return button.onlyIsActiveMe ? this.playerService.isActiveMe : true;
    });

    if (R.equals(this.snapShotButtons, buttons)) {
      return;
    }
    this.snapShotButtons = buttons;
    this.menuButtons = R.clone(this.snapShotButtons);
    this.setNotification();
    this.otherButtonsIsActive = this.menuButtons.length > 5 && !this.isDialog;
  }

  // core override - reproduce behaviour from the core 1.5
  handleMenuButtonEvent(button: MenuButtonConfig, event?) {
    if (button.type === "group") {
      this.toggleGroupButtons(button);
    } else if (button.type === "button") {
      switch (button.click_event) {
        case "city":
          this.changeMapCustomService.goToCity();
          break;

        case "messages":
          this.openMessages();
          break;

        case "missions":
          this.openMissions();
          break;

        case "warehouse":
          this.openWarehouse();
          break;
      }
    }
  }

  toggleGroupButtons(button: MappedMenuButtonConfig) {
    button.group_buttons.forEach(groupBtn => (groupBtn.isVisible = !groupBtn.isVisible));
  }
  // core override end

  openHudMenuMore() {
    this.dialogService.open(HudMenuMoreComponent);
  }

  openMissions() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MISSION.MISSIONS,
    });
  }

  openMessages() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGES,
    });
  }

  openWarehouse() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE,
      config: {
        data: {
          storageId: this.storageId,
        },
      },
    });
  }
}
