import { Injectable } from "@angular/core";
import * as R from "ramda";

import { GAME_CONFIG } from "../../../../core/config/custom/_parsed-game.config";
import { CUSTOM_MENU_BUTTONS_CONFIG } from "../../constants/custom/menu-buttons.const";
import { ButtonMenuConfigFilter, MappedMenuButtonConfig, MenuButtonConfig } from "../../interfaces/custom/menu-buttons.interface";

@Injectable({
  providedIn: "root",
})
export class MenuButtonsConfigService {
  buttons: MenuButtonConfig[] = [];
  isCC = GAME_CONFIG.IS_CC;

  constructor() {
    this.setButtons(CUSTOM_MENU_BUTTONS_CONFIG);
  }

  setButtons(x: MappedMenuButtonConfig[]) {
    this.buttons = x;
  }

  getButtonsMenuConfig(config: ButtonMenuConfigFilter): MappedMenuButtonConfig[] {
    let buttons = R.clone(this.buttons)
      .filter(button => button.place === config.place)
      .filter(
        button =>
          button.gui_unlocks_button === null ||
          config.gui_unlocks[button.gui_unlocks_button] ||
          (!this.isCC && button.gui_unlocks_blocked_tooltip)
      )
      .map(button => ({ ...button, isVisible: true }));
    buttons = R.sortBy(R.prop("order"))(buttons);
    buttons = buttons.reduce((result, button) => {
      if (button.type === "group") {
        if (config.isDialog) {
          return result;
        }
        const groupButtons = buttons.filter(btn => btn.group_name === button.name);

        switch (button.group_variant) {
          case 2:
            if (groupButtons.length === 1) {
              result.push({ ...groupButtons[0], isVisible: true });
            } else if (groupButtons.length > 1) {
              result.push({ ...button, group_buttons: groupButtons });
            }
            break;
          case 3:
            if (groupButtons.length > 1) {
              result.push({ ...button, group_buttons: groupButtons });
            }
            break;
          default:
            if (groupButtons.length > 0) {
              result.push({ ...button, group_buttons: groupButtons });
            }
            break;
        }
      } else if (!button.group_name || config.isDialog) {
        result.push(button);
      }
      return result;
    }, []);
    return buttons;
  }
}
