<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <ng-container *ngIf="buildingDetails && upgradeBuildingDetails">
    <div class="top-info">
      <div
        class="experience"
        *ngIf="upgradeBuildingDetails.exp_points > 0"
        [matTooltip]="'Punkty doświadczenia'"
        [matTooltipShowDelay]="400"
        [matTooltipPosition]="'above'"
      >
        <div class="inner-wrapper">
          <p>+{{ upgradeBuildingDetails.exp_points }}</p>
        </div>
        <img [src]="'exp.png' | asset : 'ui'" />
      </div>

      <!-- GROUP BUILDING -->
      <div
        class="group-count"
        [matTooltip]="'Maksymalna ilość budynków'"
        [matTooltipShowDelay]="400"
        [matTooltipPosition]="'above'"
      >
        <m-ui-group-count [building]="buildingDetails" #groupCount></m-ui-group-count>
      </div>
    </div>

    <div class="bookmarks">
      <div class="overlap active green">
        <div class="bookmark green">
          <span>{{ buildingDetails.name }}</span>
        </div>
      </div>

      <div class="levels-container">
        <div class="lvl-box">
          <span>Poziom</span>
          <div class="level">
            {{ buildingDetails.level }}
          </div>
        </div>

        <div class="level next">
          {{ upgradeBuildingDetails.level }}
        </div>
      </div>
    </div>

    <div class="top loading-opacity">
      <div class="icon-container">
        <!-- BUILDING ICON -->
        <img [src]="buildingDetails | buildingIcon:false">
      </div>

      <div class="right-column">
        <div
          class="description"
          [innerHTML]="buildingDetails.group_description | safe: 'html'"
        ></div>

        <div class="elements" [class.not-show]="hideSubTitle">
          <div class="min-title" *ngIf="hideSubTitle && notOutcomeParametersDiffParameters?.length">Otrzymasz:</div>

          <app-building-return
            *ngIf="upgradeBuildingDetails?.build_currency_prizes?.length || upgradeBuildingDetails?.build_product_prizes?.length"
            [returnCurrencies]="upgradeBuildingDetails.build_currency_prizes"
            [returnProducts]="upgradeBuildingDetails.build_product_prizes"
            [hideSubTitle]="hideSubTitle"
            [title]="'Produkty'"
          ></app-building-return>

          <app-unlocked-parameters-upgrade
            *ngIf="notOutcomeParametersDiffParameters?.length"
            [parameters]="notOutcomeParametersDiffParameters"
            [hideSubTitle]="hideSubTitle"
          ></app-unlocked-parameters-upgrade>

          <app-unlocked-productions-upgrade
            *ngIf="diffProductions?.length"
            [production]="diffProductions"
          ></app-unlocked-productions-upgrade>

          <app-building-upgrade-diff-automatic-production
            *ngIf="automaticCurrency || automaticProduct || upgradeAutomaticCurrency || upgradeAutomaticProduct"
            [groupType]="upgradeBuildingDetails.group_type"
            [automaticCurrency]="automaticCurrency"
            [automaticProduct]="automaticProduct"
            [upgradeAutomaticCurrency]="upgradeAutomaticCurrency"
            [upgradeAutomaticProduct]="upgradeAutomaticProduct"
          ></app-building-upgrade-diff-automatic-production>

          <app-building-upgrade-diff-storage-value
            *ngIf="storageValue || upgradeStorageValue"
            [storageValue]="storageValue"
            [upgradeStorageValue]="upgradeStorageValue"
          ></app-building-upgrade-diff-storage-value>
        </div>
      </div>
    </div>

    <div class="bottom loading-opacity">
      <m-ui-separator title="Wymagania"></m-ui-separator>

      <!-- REQUIREMENTS -->
      <app-building-requirements
        *ngIf="upgradeBuildingDetails && outcomeParametersRequirements"
        [building]="upgradeBuildingDetails"
        [products]="upgradeBuildingDetails.build_product_prices"
        [currencies]="upgradeBuildingDetails.build_currency_prices"
        [playerLevel]="upgradeBuildingDetails.required_level > 1 ? upgradeBuildingDetails.required_level : null"
        [requiredBuilding]="upgradeBuildingDetails.required_building"
        [parametersOutcome]="outcomeParametersRequirements"
        (requirementsStatus)="setRequirementsStatus($event)"
      ></app-building-requirements>
    </div>

    <div
      class="buttons"
      *ngIf="buildingDetails && requirementsStatus"
    >
      <!-- BUILD -->
      <div
        [ngbTooltip]="requirementsStatus['textErrors'].length && tooltipRequirementsTpl"
        placement="bottom"
        container="body"
      >
        <button
          class="c-base c-secondary"
          [disabled]="!requirementsStatus.valid"
          (click)="upgrade()"
        >
            <span class="text-normal">
              Rozbuduj <app-core-building-time [building]="upgradeBuildingDetails"></app-core-building-time>
            </span>
        </button>
      </div>

      <!-- FAST ACTION -->
      <div
        [ngbTooltip]="requirementsStatus['textErrors'].length && tooltipRequirementsTpl"
        placement="bottom"
        container="body"
      >
        <button
          *ngIf="upgradeBuildingDetails.fast_build_currency_prices.length || upgradeBuildingDetails.fast_build_product_prices.length"
          [matTooltip]="'Akcja błyskawiczna'"
          class="c-base c-primary"
          (click)="openFastUpgradeConfirm()"
          [disabled]="!requirementsStatus.valid"
        >
          Błyskawiczna akcja
        </button>
      </div>

      <!-- TOOLTIP REQUIREMENTS TPL -->
      <ng-template #tooltipRequirementsTpl>
        <div *ngFor="let textError of requirementsStatus['textErrors']">
          {{ textError }}
        </div>
      </ng-template>
    </div>
  </ng-container>
</div>
