import { Component, Input, OnInit } from '@angular/core';
import { STOCK_VIEW } from '../../../../../shared-ui/mobile/consts/stock-view.const';
import { BUILDING_GROUP_TYPE_DAILY_PRODUCTION } from '../../../../../../constants';

@Component({
  selector: 'app-building-upgrade-diff-automatic-production',
  templateUrl: './building-upgrade-diff-automatic-production.component.html',
})
export class BuildingUpgradeDiffAutomaticProductionComponent implements OnInit {
  @Input() groupType;
  @Input() automaticCurrency;
  @Input() automaticProduct;
  @Input() upgradeAutomaticCurrency;
  @Input() upgradeAutomaticProduct;

  STOCK_VIEW = STOCK_VIEW;
  BUILDING_GROUP_TYPE_DAILY_PRODUCTION = BUILDING_GROUP_TYPE_DAILY_PRODUCTION;
  diffAutomaticProduction;

  ngOnInit() {
    this.setDiffAutomaticProduction();
  }

  setDiffAutomaticProduction() {
    if (this.upgradeAutomaticCurrency || this.upgradeAutomaticProduct) {
      this.diffAutomaticProduction = {
        normal: this.automaticCurrency?.amount || this.automaticProduct?.amount || 0,
        upgrade: this.upgradeAutomaticCurrency?.amount || this.upgradeAutomaticProduct?.amount,
      };

      this.diffAutomaticProduction.parameters = {
        diff: this.diffAutomaticProduction.upgrade - this.diffAutomaticProduction.normal,
      };
    }
  }
}
