<!-- QUIZ -->
<ng-container *ngIf="qa.type === QA_TYPE.QUIZ">
  <!-- SEPARATOR -->
  <m-ui-separator
    [title]="separatorTitle"
    *ngIf="!IS_QA_PRIZES_DIVIDE && isShowSeparator"
  ></m-ui-separator>

  <div class="container" [class.qa-prizes-divide]="IS_QA_PRIZES_DIVIDE">
    <!-- SCORE AND TIME -->
    <div class="score">
      <m-ui-separator
        title="Twój wynik"
        *ngIf="IS_QA_PRIZES_DIVIDE"
      ></m-ui-separator>

      <div class="prizes">
        <m-ui-stock-other
          *ngIf="answeringTime"
          [customClassStock]="'stock-layout-a'"
          [customIconSize]="'big'"
          [item]="{icon: 'time'}"
          [lack]="false"
          [other]="{amount: answeringTime}"
          [stockView]="STOCK_VIEW.CUSTOM"
        ></m-ui-stock-other>

        <m-ui-stock-other
          *ngIf="scoreText"
          [customClassStock]="'stock-layout-a'"
          [customIconSize]="'big'"
          [item]="{icon: 'check'}"
          [lack]="false"
          [other]="{amount: scoreText}"
          [stockView]="STOCK_VIEW.CUSTOM"
        ></m-ui-stock-other>
      </div>
    </div>

    <!-- PRIZES FOR QA -->
    <div
      class="qa-prizes"
      *ngIf="(!qa.required_threshold && (qa.currency_prizes.length > 0 || qa.product_prizes.length > 0)) || (qa.required_threshold && (qa.last_attempt?.currency_prizes_for_finish.length > 0 || qa.last_attempt?.product_prizes_for_finish.length > 0))"
      [class.border-left]="IS_QA_PRIZES_DIVIDE"
    >
      <m-ui-separator
        title="Nagrody za wypełnienie quizu"
        *ngIf="IS_QA_PRIZES_DIVIDE"
      ></m-ui-separator>

      <div class="prizes">
        <!-- PRIZES FOR QA -->
        <ng-container *ngIf="!qa.required_threshold || (qa.required_threshold && !qa.last_attempt?.finished_at)">
          <m-ui-currency
            *ngFor="let prize of qa.currency_prizes"
            [customClassStock]="'stock-layout-a'"
            [customIconSize]="'big'"
            [item]="prize"
            [lack]="false"
            [stockView]="STOCK_VIEW.CUSTOM"
          ></m-ui-currency>

          <ng-container *ngFor="let prize of qa.product_prizes">
            <m-ui-product
              *ngIf="prize['product_id'] !== HIDE_PRODUCT_ID_1 && prize['product_id'] !== HIDE_PRODUCT_ID_2"
              [customClassStock]="'stock-layout-a'"
              [customIconSize]="'big'"
              [item]="prize"
              [lack]="false"
              [stockView]="STOCK_VIEW.CUSTOM"
            ></m-ui-product>
          </ng-container>
        </ng-container>

        <!-- PRIZES FOR QA WITH MIN THRESHOLD -->
        <ng-container *ngIf="qa.required_threshold && qa.last_attempt?.finished_at">
          <m-ui-currency
            *ngFor="let prize of qa.last_attempt.currency_prizes_for_finish"
            [customClassStock]="'stock-layout-a'"
            [customIconSize]="'big'"
            [item]="prize"
            [lack]="false"
            [stockView]="STOCK_VIEW.CUSTOM"
          ></m-ui-currency>

          <ng-container *ngFor="let prize of qa.last_attempt.product_prizes_for_finish">
            <m-ui-product
              *ngIf="prize['product_id'] !== HIDE_PRODUCT_ID_1 && prize['product_id'] !== HIDE_PRODUCT_ID_2"
              [customClassStock]="'stock-layout-a'"
              [customIconSize]="'big'"
              [item]="prize"
              [lack]="false"
              [stockView]="STOCK_VIEW.CUSTOM"
            ></m-ui-product>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- PRIZES FOR THE RIGHT ANSWER -->
    <div
      class="right-answer-prizes"
      *ngIf="qa.last_attempt?.currency_prizes_for_answers.length > 0 || qa.last_attempt?.product_prizes_for_answers.length > 0"
    >
      <m-ui-separator
        title="Nagrody za poprawne odpowiedzi"
        *ngIf="IS_QA_PRIZES_DIVIDE"
      ></m-ui-separator>

      <div class="prizes">
        <ng-container *ngIf="qa.last_attempt">
          <ng-container *ngFor="let prize of qa.last_attempt.currency_prizes_for_answers">
            <m-ui-currency
              [customClassStock]="'stock-layout-a'"
              [customIconSize]="'big'"
              [item]="prize"
              [lack]="false"
              [stockView]="STOCK_VIEW.CUSTOM"
            ></m-ui-currency>
          </ng-container>

          <ng-container *ngFor="let prize of qa.last_attempt.product_prizes_for_answers">
            <m-ui-product
              *ngIf="prize['product_id'] !== HIDE_PRODUCT_ID_1 && prize['product_id'] !== HIDE_PRODUCT_ID_2"
              [customClassStock]="'stock-layout-a'"
              [customIconSize]="'big'"
              [item]="prize"
              [lack]="false"
              [stockView]="STOCK_VIEW.CUSTOM"
            ></m-ui-product>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
