import { Pipe, PipeTransform } from '@angular/core';
import { BusinessLeagueColor } from '../helpers/business-league-color.helper';
import { LeagueColorObject } from '../../interfaces/custom/league-color-object.interface';

@Pipe({
  name: 'businessLeagueColor'
})
export class BusinessLeagueColorPipe implements PipeTransform {
  transform(color): LeagueColorObject {
    return new BusinessLeagueColor().getLeagueObject(color);
  }
}
