<div class="global-dialog ow-dialog c-window-short">

  <div class="bookmarks">
    <div class="bookmark green">
      <span>Zadania dodatkowe</span>
    </div>
  </div>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- DIALOG BACK -->
  <m-ui-back-button></m-ui-back-button>

  <!-- DESCRIPTION -->
  <div
    class="description"
    [innerHTML]="'Do 20 dnia miesiąca możesz zmienić zadania dodatkowe przypisane do Twojego konta zachowując pełną wartość nagrody. Po 20 dniu miesiąca zmiany zadań nie są możliwe.'"
    owEllipsis
  ></div>

  <!-- TASKS -->
  <div class="frame">
    <ow-perfect-scrollbar scrollGradient>
      <table>
        <ng-container *ngFor="let task of data.tasks">
          <tr *ngIf="!task.hideOnList">
            <td class="task-title">
              {{ task.name }}
            </td>

            <ng-container *ngIf="task.scopes && task.scopes[task.keyPercentage] as scope; else emptyFourTdTpl">
              <ng-container *ngFor="let i of [0,1,2,3]">
                <ng-container *ngIf="scope.prizes[i] as prize; else emptyTdTpl">
                  <ng-container *ngIf="prize['type'] === 'currency'">
                    <td class="currency">
                      <m-ui-currency
                        [item]="prize"
                        [lack]="false"
                        [stockView]="STOCK_VIEW.CUSTOM"
                        [customClassStock]="'stock-card-details'"
                      ></m-ui-currency>
                    </td>
                  </ng-container>

                  <ng-container *ngIf="prize['type'] === 'product'">
                    <td class="currency">
                      <m-ui-product
                        [item]="prize"
                        [lack]="false"
                        [stockView]="STOCK_VIEW.CUSTOM"
                        [customClassStock]="'stock-card-details'"
                        [lockOpenStorage]="true"
                      ></m-ui-product>
                    </td>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-template #emptyTdTpl>
              <td class="emptyTd"></td>
            </ng-template>

            <ng-template #emptyFourTdTpl>
              <ng-container [ngTemplateOutlet]="emptyTdTpl" *ngFor="let i of [0,1,2,3]"></ng-container>
            </ng-template>

            <td class="button">
              <button class="c-square x-small c-primary" (click)="openCardTaskDetails(task)">
                <i class="fas fa-angle-right"></i>
              </button>
            </td>
          </tr>
        </ng-container>
      </table>
    </ow-perfect-scrollbar>
  </div>
</div>

