import { EventDialog } from '../../../../../../../core/interfaces/event-dialog.interface';
import { EVENT_DIALOGS_NAMES_WF_CUSTOM } from './event-names.const';
import { WfMainComponent } from '../../../base/custom/dialogs/wf-main/wf-main.component';
import {WfPieceDetailsComponent} from '../../../base/custom/dialogs/wf-piece-details/wf-piece-details.component';
import {WfPiecePairingComponent} from '../../../base/custom/dialogs/wf-piece-pairing/wf-piece-pairing.component';

export const EVENT_DIALOGS_WF_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_WF_CUSTOM.MAIN]: WfMainComponent,
  [EVENT_DIALOGS_NAMES_WF_CUSTOM.DETAILS]: WfPieceDetailsComponent,
  [EVENT_DIALOGS_NAMES_WF_CUSTOM.PAIRING]: WfPiecePairingComponent
};
