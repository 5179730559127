import {EventShopModule} from '../../../event-shop/module/custom/event-shop.module';
import {AuctionsModule} from '../../../auctions/module/auctions/auctions.module';
import {SanBusinessModule} from '../../../san-business/module/custom/san-business.module';
import {SanRanksModule} from '../../../san-ranks/module/custom/san-ranks/san-ranks.module';
import {OwPerfectScrollbarModule} from '@oskarwegner/ow-perfect-scrollbar';
import {TrackingModule} from '../../../tracking/module/custom/tracking.module';
import {AntyminerModule} from '../../../antyminer/module/custom/antyminer.module';

export const CUSTOM_IMPORTS = [
  SanBusinessModule,
  SanRanksModule,
  EventShopModule,
  // FinalChallengesModule,
  // EmployeesModule,
  // AnimalGalleryModule,
  TrackingModule,
  AuctionsModule,
  // WfModule,
  OwPerfectScrollbarModule,
  AntyminerModule
];
