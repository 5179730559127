import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ShopConfirmationsService } from '../../../../../../../providers/shop-confirmations.service';
import { GAME_CONFIG } from '../../../../../../../core/config/custom/_parsed-game.config';
import { SynchronizeTimeService } from '../../../../../../../core/providers/synchronize-time.service';
import { DialogService } from '../../../../../../shared/providers/dialog.service';
import { UserService } from '../../../../../../user/providers/user.service';
import { GuiService } from '../../../../../services/gui.service';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';

@Component({
  selector: 'confirmation-delivery',
  templateUrl: './confirmation-delivery.component.html',
})
export class ConfirmationDeliveryComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(Router) router: Router;
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;
  @OwInject(ElementRef) elementRef: ElementRef;
  @OwInject(ShopConfirmationsService) shopConfirmationsService: ShopConfirmationsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(UserService) userService: UserService;
  @OwInject(GuiService) guiService: GuiService;

  isConfirmed: boolean;
  deliveryTime: Date;
  error: string;
  confirmation: ConfirmationDelivery;
  loading: boolean;
  isCC = GAME_CONFIG.IS_CC;

  ngOnInit() {
    this.guiService.isSplashShow.next(false);

    if (this.shopConfirmationsService.confirmations === null) {
      this.loading = true;

      this.shopConfirmationsService.shopConfirmations()
        .subscribe(() => {
          this.loading = false;
          this.setConfirmations();
        }, (errResp) => {
          this.dialogService.openAlertErrorApi({errResp});
          this.loading = false;
        });
    } else {
      this.setConfirmations();
    }
  }

  clear() {
    this.isConfirmed = false;
    this.deliveryTime = null;
    this.error = null;
  }

  setConfirmations() {
    this.setCurrentConfirmation();
  }

  setCurrentConfirmation() {
    if (this.shopConfirmationsService.confirmations.length) {
      this.clear();
      this.setDeliveryTimeToday();
      this.confirmation = this.shopConfirmationsService.confirmations.pop();
    } else {
      this.guiService.isSplashShow.next(true);
      this.router.navigate(['/auth/game']);
    }
  }

  setDeliveryTimeToday() {
    this.deliveryTimeChange(moment(this.synchronizeTimeService.getActualLocalTime()).format('YYYY-MM-DD'));
  }

  toggleConfirmed() {
    const confirmationContainer = this.elementRef.nativeElement.querySelector('ow-perfect-scrollbar > div:first-of-type');

    this.isConfirmed = !this.isConfirmed;
    if (this.isConfirmed) {
      setTimeout(() => {
        confirmationContainer.scrollTop = 1000;
      }, 100);

    } else {
      confirmationContainer.scrollTop = 0;
    }
  }

  deliveryTimeChange(event) {
    if (event) {
      const futureTime = moment(event).diff(moment(this.synchronizeTimeService.getActualLocalTime()));
      if (futureTime < 0) {
        this.deliveryTime = event;
        this.error = '';
      } else {
        this.error = 'Data odbioru nie może być w dniu, który jeszcze nie nastąpił.';
      }
    } else {
      this.error = 'Wprowadź poprawną datę.';
    }
  }

  shopConfirmationsDelay() {
    this.loading = true;

    this.shopConfirmationsService.shopConfirmationsDelay(this.confirmation.confirmation_id)
      .subscribe(() => {
        this.setCurrentConfirmation();
        this.loading = false;
      }, errResp => {
        if (errResp.status !== 409) {
          this.dialogService.openAlertErrorApi({errResp});
        }
        this.loading = false;
        this.setCurrentConfirmation();
      });
  }

  delayConfirmationAlert() {
    this.dialogService.openConfirm({
      title: 'Uwaga',
      description: 'Właśnie potwierdzasz, że nie otrzymałaś(-eś) jeszcze swojego zamówienia. Komunikat wyświetli się ponownie jutro.'
    }, (confirm) => {
      if (confirm) {
        this.shopConfirmationsDelay();
      }
    });
  }

  shopConfirmationsConfirm() {
    this.loading = true;

    this.shopConfirmationsService.shopConfirmationsConfirm(this.confirmation.confirmation_id, this.deliveryTime)
      .subscribe(() => {
        this.loading = false;
        this.dialogService.openAlert({
          title: 'Dziękujemy',
          description: `Potwierdzenie ${this.confirmation.number} przyjęte.`
        });
        this.setCurrentConfirmation();
      }, errResp => {
        if (errResp.status !== 409) {
          this.dialogService.openAlertErrorApi({errResp});
        }
        this.loading = false;
      });
  }
}

export interface ConfirmationDelivery {
  address: string;
  sent_date: string;
  prizes: [{
    name: string;
    amount: number;
    price: string;
  }];
  confirmation_id: number;
  number: string;
  card_number?: string;
}
