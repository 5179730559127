import { Injectable } from "@angular/core";
import { of } from "rxjs";

import { ApiService } from "../../../../../../core/providers/api.service";
import { PlayerService } from "../../../../../player/providers/player.service";
import { Contract } from "../../custom/dialogs/business-challenges/contract-sale/contract-sale.component";

@Injectable({
  providedIn: "root",
})
export class ApiMicroLeagueService {
  constructor(
    public apiService: ApiService,
    private playerService: PlayerService
  ) {}

  getPlayerMicroLeague(player_id?: number) {
    player_id = player_id || this.playerService.getActivePlayerId();
    return this.apiService.get(`/microleague/player/${player_id}`);
  }

  getMicroLeagueDetails(microleague_id: number, player_id?: number) {
    player_id = player_id || this.playerService.getActivePlayerId();
    return this.apiService.get(`/microleague/microleague_id/${microleague_id}/player/${player_id}`);
  }

  getMicroLeagueAchievements(player_id?: number) {
    player_id = player_id || this.playerService.getActivePlayerId();
    return this.apiService.get(`/microleague-achievement/player/${player_id}`);
  }

  getMicroLeagueAchievementsVisibility(player_id?: number) {
    player_id = player_id || this.playerService.getActivePlayerId();
    return this.apiService.get(`/microleague-achievement/player/${player_id}/visibility`);
  }

  getPlayerMicroLeagueAchievement(achievement_id: number, player_id?: number) {
    player_id = player_id || this.playerService.getActivePlayerId();
    return this.apiService.get(`/microleague-achievement/achievement_id/${achievement_id}/player/${player_id}`);
  }

  getPlayerContract(player_id?: number) {
    player_id = player_id || this.playerService.getActivePlayerId();
    return this.apiService.get(`/microleague/contract/player/${player_id}`);
  }

  confirmPlayerMicroLeagueRules(microleague_id: number, player_id?: number) {
    player_id = player_id || this.playerService.getActivePlayerId();
    return this.apiService.patch(`/microleague/microleague_id/${microleague_id}/player/${player_id}/rules`);
  }

  confirmPlayerMicroLeagueChest(microleague_id: number, player_id?: number) {
    player_id = player_id || this.playerService.getActivePlayerId();
    return this.apiService.post(`/microleague/microleague_id/${microleague_id}/player/${player_id}/chest`);
  }
}
