<div class="container">
  <ng-container *ngIf="!isLoading && microLeagueDetails">
    <div class="title">{{ microLeagueDetails.name }}</div>
    <div class="description" owEllipsis [innerHTML]="microLeagueDetails.description | safe:'html'"></div>

    <!-- MY POSITION -->
    <div
      class="my-position-link"
      showMyPositionRank
      *ngIf="microLeagueDetails.my_position_index !== null && playerService.isActiveMe && microLeagueDetails?.items?.length >= 20"
      [perfectScrollbarRef]="perfectScrollbar"
      [myPosition]="tempMyPosition"
    >
      <a>Pokaż moją pozycję</a>
    </div>
      <div class="table-header">
        <table class="custom">
          <thead>
          <th class="position"></th>
          <th class="name"></th>
          <th class="value1">
            Wynik
          </th>
          <th class="prize">
            {{!groupPrizes ? isCleared ? 'Przyznane nagrody' : 'Maksymalne nagrody za premiowane miejsca' : 'Nagrody, o które walczyła grupa'}}
          </th>
          </thead>
        </table>
      </div>
    <div class="table-body">
      <ow-perfect-scrollbar #perfectScrollbar scrollGradient>
        <table class="custom">
          <tbody>
          <tr
            *ngFor="let item of microLeagueDetails?.items; let i = index"
            [class.my-position]="item?.isMyPosition"
            [attr.data-id]="i"
          >
            <td class="position">
              {{ item.position === 0 ? '-' : item.position }}
            </td>
            <td class="name">{{ item.name }}</td>
            <td
              class="value1"
              [matTooltip]="item.tooltip"
              [matTooltipPosition]="'above'"
              [matTooltipShowDelay]="300"
              [matTooltipDisabled]="!item.tooltip"
            >
              {{ item.value1 }}
            </td>
            <td class="prize">
              <div class="my-prizes">
                <ng-container *ngIf="(!microLeagueDetails.is_closed || groupPrizes && (item.prizes?.currency || item.prizes?.product))">

                  <!-- CURRENCY -->
                  <ng-container *ngIf="item?.prizes?.currency?.length">
                    <m-ui-currency
                      *ngFor="let currency of item?.prizes?.currency"
                      [item]="currency"
                      [lack]="false"
                      [stockView]="STOCK_VIEW.G"
                    ></m-ui-currency>
                  </ng-container>

                  <!-- PRODUCT -->
                  <ng-container *ngIf="item?.prizes?.product?.length">
                    <m-ui-product
                      *ngFor="let product of item?.prizes?.product"
                      [item]="product"
                      [lack]="false"
                      [stockView]="STOCK_VIEW.G"
                    ></m-ui-product>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="microLeagueDetails.is_closed && !groupPrizes && (item.final_prizes?.currency || item.final_prizes?.product)">

                  <!-- CURRENCY -->
                  <ng-container *ngIf="item?.final_prizes.currency?.length">
                    <m-ui-currency
                      *ngFor="let currency of item?.final_prizes?.currency"
                      [item]="currency"
                      [lack]="false"
                      [stockView]="STOCK_VIEW.G"
                    ></m-ui-currency>
                  </ng-container>

                  <!-- PRODUCT -->
                  <ng-container *ngIf="item?.final_prizes.product?.length">
                    <m-ui-product
                      *ngFor="let product of item?.final_prizes?.product"
                      [item]="product"
                      [lack]="false"
                      [stockView]="STOCK_VIEW.G"
                    ></m-ui-product>
                  </ng-container>
                </ng-container>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </ow-perfect-scrollbar>
    </div>
  </ng-container>

  <div *ngIf="isCleared && microLeagueDetails.is_confirmed !== null" class="toggle-group" (click)="toggleGroupPrizes()"><span>{{!groupPrizes ? 'Zobacz nagrody, o które walczyła grupa' : 'Zobacz przyznane nagrody'}}</span><i class="far fa-chevron-square-right nav-arrow"></i></div>

  <ng-container *ngIf="!isLoading && !microLeagueDetails">
    <div class="no-item">Brak mikroligi</div>
  </ng-container>
</div>
