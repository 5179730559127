import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../../core/providers/api.service";
import { ApiOptions } from "../../../../../../core/interfaces/api-options";
import { GetBasicActionsCurrentRequest } from "../interfaces/request/get-basic-actions-current-request.interface";
import { GetBasicActionsDetailsRequest } from "../interfaces/request/get-basic-actions-details-request.interface";
import { PlayerService } from "../../../../../player/providers/player.service";

@Injectable({
  providedIn: "root",
})
export class ApiBasicActionsService {
  constructor(
    public apiService: ApiService,
    private playerService: PlayerService
  ) {}

  getBasicActionsCurrent(branch_id?: GetBasicActionsCurrentRequest | number) {
    if (branch_id) {
      return this.apiService.get(`/branch/${branch_id}/manager/basic-actions/current`);
    }
    const player_id = this.playerService.getActivePlayerId();
    return this.apiService.get(`/player/${player_id}/basic-actions/current`);
  }

  getBasicActionsDetails({ player_id, date }: GetBasicActionsDetailsRequest) {
    const options: ApiOptions = {
      params: {
        date,
      },
    };

    player_id = player_id || this.playerService.getActivePlayerId();
    return this.apiService.get(`player/${player_id}/basic-actions/details`, options);
  }

  getBasicActionsDetailsDashboard({ player_id, date, task_id }: GetBasicActionsDetailsRequest) {
    const options: ApiOptions = {
      params: {
        date,
      },
    };

    player_id = player_id || this.playerService.getActivePlayerId();
    return this.apiService.get(`player/${player_id}/basic-actions/details/dashboard/task_id/${task_id}`, options);
  }
}
