import { Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractMissionTransferComponent } from "../../../../abstract/core/abstract-mission-transfer.component";
import { PatchMissionHandOver } from "../../../../api/core/interfaces";

@Component({
  selector: "app-custom-mission-transfer",
  templateUrl: "./mission-transfer.component.html",
})
export class MissionTransferComponent extends AbstractMissionTransferComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.condition = this.data.condition;

    this.clearForm();
    this.subscribePlayer();
  }

  changePlayer(playerCurrencyBalances) {
    if (this.data.condition.currency) {
      this.productOrCurrency = this.currencyService.getCurrencyBalance(this.data.condition.currency, playerCurrencyBalances);
    }

    if (this.data.condition.product) {
      this.productOrCurrency = this.productPlayerService.getProduct(this.data.condition.product);
    }

    this.setRequiredAmount();
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105));
  }

  missionHandOver() {
    const amount = this.form.value.amount;
    this.loading = true;

    const patchMissionHandOver: PatchMissionHandOver = {
      player_mission_condition_id: this.condition.player_mission_condition_id,
      amount: +amount,
    };

    this.apiMissionService.missionHandOver(patchMissionHandOver).subscribe({
      next: () => {
        this.loading = false;
        this.reload = true;
        this.close();
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
