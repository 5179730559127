import { Injectable } from "@angular/core";

import { ApiService } from "../../../../core/providers/api.service";

@Injectable({
  providedIn: "root",
})
export class ApiMinigameRanksService {
  constructor(public apiService: ApiService) {}

  ranks() {
    return this.apiService.get("minigame/ranks");
  }

  rankDetails({ game, year, month }: { game: number; year: number; month: number }) {
    return this.apiService.get(`minigame/${game}/rank/${year}/${month}`);
  }
}
