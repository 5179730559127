import { Component, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";
import { Moment } from "moment";

import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { OwDate } from "../../../../../../../core/utility/ow-date.class";
import { AbstractBasicActionsComponent } from "../../../abstract/custom/abstract-basic-actions.component";
import { BasicActionDetails } from "../../../interfaces/custom/basic-actions.interface";
import { Branch } from "../../../../../interfaces/branch.interface";
import { ClearNotificationService } from "../../utils/clear-notification.service";

@Component({
  selector: "app-basic-actions",
  templateUrl: "./basic-actions.component.html",
})
export class BasicActionsComponent extends AbstractBasicActionsComponent implements OnInit, OnDestroy {
  @OwInject(ClearNotificationService) clearNotificationService: ClearNotificationService;
  @OwInject(MAT_DIALOG_DATA) public data: {
    date: string;
    taskId?: number;
    playerId?: number;
    branch?: Branch;
    branchName?: string;
  };

  momentDate: Moment;
  desc = DESCRIPTION;
  WF_CURRENCY = 13;
  branchName?: string;

  ngOnInit() {
    this.momentDate = moment(this.data.date) || new OwDate().momentDate;
    this.getBasicActionDetails(this.momentDate.format("YYYY-MM-DD"));
    this.branchName = this.data?.branchName;
  }

  getBasicActionDetails(date) {
    const api = this.data?.taskId ? "getBasicActionsDetailsDashboard" : "getBasicActionsDetails";
    this.apiBasicActionsService[api]({ player_id: this.data?.playerId, date, task_id: this.data?.taskId }).subscribe(
      (resp: BasicActionDetails) => {
        this.basicActionDetails = resp;
        if (this.basicActionDetails.items.length === 0 && this.basicActionDetails.date_prev) {
          this.getBasicActionDetails(this.basicActionDetails.date_prev);
        }
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  clearNotification() {
    this.clearNotificationService.clearNotification(["basic_action", "import_calculate_basic_actions"], this.data?.playerId);
  }

  ngOnDestroy() {
    this.clearNotification();
  }
}

const DESCRIPTION =
  "Oto lista zadań, za które codziennie zdobywasz nagrody. Są one naliczane w cyklach dziennych i dotyczą <br> " +
  "Twojego wykonania zadań w poprzednim dniu roboczym. Na przykład w dniu 23 marca otrzymasz nagrody za zadania zrealizowane 22 marca.";
