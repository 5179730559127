<div class="upgrade-container">
  <div class="upgrade-label">
    <ng-container *ngIf="normal || upgrade as parameter">
      <!-- PARAMETER NORMAL -->
      <m-ui-parameter
        *ngIf="parameter"
        [item]="parameter"
        [stockView]="STOCK_VIEW.CUSTOM"
        [customIconSize]="'big'"
        [customClassStock]="'stock-san-a'"
        [lack]="false"
        [hideValue]="true"
      ></m-ui-parameter>
    </ng-container>

    <span  class="value">
      {{ normal ? normal[determineValue] : 0}}
    </span>

    <div
      class="upgrade-value-container"
      [class.has-normal]="normal"
    >
      <div
        class="upgrade-arrow"
      ></div>

      <span
        *ngIf="upgrade"
        class="upgrade"
        [class.quantity-diff]="parameters && ((parameters.income_diff > 0) || (parameters.outcome_diff > 0) || parameters.new)"
        [class.quantity-diff-minus]="parameters && ((parameters.income_diff < 0) || (parameters.outcome_diff < 0))"
      >
        {{ upgrade ? upgrade[determineValue] : 0 }}
      </span>
    </div>
  </div>
</div>
