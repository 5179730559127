<div class="global-dialog ow-dialog c-window-short c-secondary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button *ngIf="isImpersonate"></m-ui-close-button>

  <ng-container *ngIf="titleAcquired">
    <div class="title">
      {{ titleAcquired }}
    </div>

    <div class="message-image loading-opacity"
         [ngClass]="{
         'default': !chestType || chestType !== 3,
         'chest-blue': chestType === 3
         }"></div>

    <div
      class="description"
      [innerHTML]="descriptionAcquired | safe: 'html'">
    </div>

    <button class="c-base c-primary accept" (click)="openAndClaim()">
      {{ buttonOpen }}
    </button>
  </ng-container>
</div>
