import {MessagesComponent} from '../../base/custom/dialogs/messages/messages.component';
import {MessageDetailsNormalComponent} from '../../base/custom/dialogs/message-details-normal/message-details-normal.component';
import {MessageDetailsLevelUpComponent} from '../../base/custom/dialogs/message-details-level-up/message-details-level-up.component';
import {MessageDetailsComicsComponent} from '../../base/custom/dialogs/message-details-comics/message-details-comics.component';
import {
  MessageDetailsHiddenRewardComponent
} from '../../base/custom/dialogs/message-details-hidden-reward/message-details-hidden-reward.component';
import {
  MessageDetailsChestSuccessComponent
} from '../../base/custom/dialogs/message-details-chest-success/message-details-chest-success.component';
import {
  MessageDetailsChestFailedComponent
} from '../../base/custom/dialogs/message-details-chest-failed/message-details-chest-failed.component';
import {
  MessageDetailsProbabilityCompensationComponent
} from '../../base/custom/dialogs/message-details-probability-compensation/message-details-probability-compensation.component';
import {MessageDetailsInfoProblemComponent} from '../../base/custom/dialogs/message-details-info-problem/message-details-info-problem.component';

export const CUSTOM_IMPORTS = [];

export const CUSTOM_DECLARATIONS = [
  MessagesComponent,
  MessageDetailsNormalComponent,
  MessageDetailsLevelUpComponent,
  MessageDetailsComicsComponent,
  MessageDetailsHiddenRewardComponent,
  MessageDetailsChestSuccessComponent,
  MessageDetailsChestFailedComponent,
  MessageDetailsProbabilityCompensationComponent,
  MessageDetailsInfoProblemComponent
];

export const CUSTOM_ENTRY_COMPONENTS = [];

export const CUSTOM_EVENT_DIALOGS = {};
