import { Component, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { map, take } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { PLAYER_TITLES } from "../../../../../../../core/consts/core/player-titles";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { SynchronizeTimeService } from "../../../../../../../core/providers/synchronize-time.service";
import { OwDate } from "../../../../../../../core/utility/ow-date.class";
import { OwPaginationDate } from "../../../../../../../core/utility/ow-pagination.class";
import { unsubscribeObject } from "../../../../../../../core/utility/unsubscribe-array";
import { PrimarySelectors } from "../../../../../../../store/primary";
import { AppState } from "../../../../../../../store/state";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { UserService } from "../../../../../../user/providers/user.service";
import { RANKS_NAME } from "../../../../../constants/custom/rank.const";
import { Branch } from "../../../../../interfaces/branch.interface";
import { NotificationsService } from "../../../../../services/custom/notifications.service";
import { ApiPlayerService } from "../../../../hud/api/custom/services/api-player.service";
import { RanksComponent } from "../../../../san-ranks/basic/custom/dialogs/ranks/ranks.component";
import { STOCK_VIEW } from "../../../../shared-ui/mobile/consts/stock-view.const";
import { BusinessDateService } from "../../../../shared-ui/services/custom/business-date.service";
import { ApiBusinessLeagueService } from "../../../api/services/api-business-league.service";
import { LEAGUE_TYPE } from "../../../consts/custom/league-type.const";
import { League, LeagueTask } from "../../../interfaces/custom/league.interface";
import { DashboardRoleChangeService } from "../../services/dashboard-role-change.service";
import { ClearNotificationService } from "../../utils/clear-notification.service";
import { QualityTasksComponent } from "../quality-tasks/quality-tasks.component";

@Component({
  selector: "app-league",
  templateUrl: "./league.component.html",
})
export class LeagueComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiBusinessLeagueService) apiBusinessLeagueService: ApiBusinessLeagueService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ApiPlayerService) apiPlayerService: ApiPlayerService;
  @OwInject(UserService) userService: UserService;
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;
  @OwInject(BusinessDateService) businessDateService: BusinessDateService;
  @OwInject(DashboardRoleChangeService) dashboardRoleChangeService: DashboardRoleChangeService;
  @OwInject(ClearNotificationService) clearNotificationService: ClearNotificationService;
  @OwInject(MAT_DIALOG_DATA) data: {
    dashboardDate?: OwDate;
    isOpenFromQuarter?: boolean;
    playerId?: number;
    branchName?: string;
    branch: Branch;
    leagueGroup?: number;
  };
  @OwInject(NotificationsService) notificationsService: NotificationsService;
  @OwInject(Store) store: Store<AppState>;

  currentDate: OwDate = new OwDate();
  owPaginationDate: OwPaginationDate;
  owDate: OwDate;
  allowEstimation: boolean;
  LEAGUE_TYPE = LEAGUE_TYPE;
  STOCK_VIEW = STOCK_VIEW;
  PLAYER_TITLES = PLAYER_TITLES;
  isLoading: boolean;
  prevResults: boolean;
  prevResultsHref: string;
  activeTabMonthOwDate;

  /**
   * DIALOGS TOOLTIP
   */
  tooltipDialogs = {
    prediction:
      "Prognoza pokazuje jaki wynik w Lidze osiągniesz, jeśli do końca okresu obliczeniowego Twoja realizacja zadań będzie " +
      "postępować w obecnym tempie.",
    estimation:
      "Moduł symulacji umożliwia Ci sprawdzenie jak zmiana realizacji każdego zadania wpływa na Twój wynik i miejsce w rankingu " +
      "Ligi Sprzedaży.",
    estimationInfo:
      "Wartości wyświetlane w Prognozie i Symulacji Ligi (tj. miejsce, punkty, kolor ligi, nagrody) mają charakter poglądowy i " +
      "nie są wartościami gwarantowanymi.<br/>Dopiero wynik uzyskany po oficjalnym podsumowaniu Ligi, wyświetlany jako „Ranking " +
      "rozliczony”, zawiera wartości ostateczne.",
    notAvailable: "Symulacja niedostępna",
  };

  league: League;
  leagueGroup;
  isDor: boolean;
  branchName?: string;
  branch?: Branch;
  managerPlayerId?: number;
  private branchSubscription: Subscription;
  private branchNameSubscription: Subscription;

  businessQuarter: { year: number; quarter: number };
  subs = {
    leagueDate: null,
    primary: null,
  };

  /**
   * TABS LEFT
   */
  activeTabLeft: TabLeft;
  tabsLeft: TabLeft[] = [
    { label: "Miesięczna", data: "monthly", index: LEAGUE_TYPE.MONTH, visible: true },
    { label: "Kwartalna", data: "quarterly", index: LEAGUE_TYPE.QUARTER, visible: true },
  ];

  ngOnInit() {
    this.branch = this.data?.branch;
    const initSub = { branch: true, name: true };
    this.branchSubscription = this.dashboardRoleChangeService.branch$.subscribe(branch => {
      if (initSub.branch === true) {
        this.branch = branch || this.data?.branch;
        setTimeout(() => {
          initSub.branch = false;
        }, 1000);
      } else {
        this.branch = branch;
      }
    });
    this.branchNameSubscription = this.dashboardRoleChangeService.branchName$.subscribe(branchName => {
      if (initSub.name === true) {
        this.branchName = branchName || this.data?.branchName;
        setTimeout(() => {
          initSub.name = false;
        }, 1000);
      } else {
        this.branchName = branchName;
      }
    });
    this.subscribePrimary();
    this.isDor = this.playerService.player.title_id.id >= PLAYER_TITLES.DOR.id;
    this.owDate = this.data.dashboardDate || new OwDate();
    this.clearActiveTabs();
    this.getLeague();
    this.leagueGroup = this.data?.leagueGroup || this.playerService.player["league_group_id"];
    this.branchName = this.data?.branchName;
    this.managerPlayerId = this?.branch?.manager_player_id;
  }

  subscribePrimary() {
    this.subs.primary = this.store
      .pipe(
        select(PrimarySelectors.selectPrimary),
        map(primary => {
          return primary["business_quarter"];
        }),
        take(1)
      )
      .subscribe(business_quarter => {
        this.businessQuarter = business_quarter;
      });
  }

  openRanks() {
    const rankTab = this.activeTabLeft.index == LEAGUE_TYPE.MONTH ? RANKS_NAME.LEAGUE_MONTH : RANKS_NAME.LEAGUE_QUARTER;

    this.dialogService.open(RanksComponent, {
      data: {
        tabIndex: rankTab,
        owDate: new OwDate(this.owDate),
        fromLeague: !!this.branchName,
        branchName: this.branchName,
        branch: this?.branch,
      },
    });
  }

  openQuality() {
    this.dialogService.open(QualityTasksComponent, {
      data: {
        owDate: new OwDate(this.owDate),
        tabData: this.activeTabLeft.data,
        branchName: this?.branchName,
        playerId: this.data?.playerId,
        branch: this?.branch,
      },
    });
  }

  setPagination() {
    this.owPaginationDate = new OwPaginationDate({
      current: this.owDate,
      start: new OwDate({ year: 2023, month: 3, day: 1 }),
      end: this.data.dashboardDate ? new OwDate(this.businessDateService.currentBusinessDate) : null,
    });

    if (this.owDate.quarter === 4 || this.getBlockQuarterNext()) {
      this.owPaginationDate.allowNext.quarter = false;
    }
  }

  clearActiveTabs() {
    this.activeTabLeft = this.tabsLeft[0];
  }

  changeTabLeft(tab: TabLeft) {
    this.activeTabLeft = tab;
    this.owDate = new OwDate(this.activeTabMonthOwDate);
    this.getLeague();
  }

  checkRoleChanges() {
    this.dashboardRoleChangeService.owDate = this.owDate;
    this.dashboardRoleChangeService.checkRoleChanges();
  }

  getLeague() {
    this.isLoading = true;
    this.prevResults = false;
    let api;

    if (this?.branch?.role_changed || !this?.branch) {
      this.checkRoleChanges();
    }

    switch (this.activeTabLeft.index) {
      case LEAGUE_TYPE.MONTH:
        this.activeTabMonthOwDate = this.owDate;

        api = this.apiBusinessLeagueService.monthly(
          {
            year: this.owDate.year,
            month: this.owDate.month,
          },
          this?.branch
        );

        if (this.owDate.year === 2022 && (this.owDate.month === 1 || this.owDate.month === 2)) {
          this.league = null;
          this.setPagination();
          this.isLoading = false;
          this.prevResults = true;

          switch (this.owDate.month) {
            case 1:
              this.prevResultsHref = RESULTS_JANUARY;
              break;
            case 2:
              this.prevResultsHref = RESULTS_FEBRUARY;
              break;
          }
          return;
        }
        break;

      case LEAGUE_TYPE.QUARTER:
        if (
          (this.owDate.quarter === (this.businessQuarter.quarter === 4 ? 1 : this.businessQuarter.quarter + 1) &&
            this.owDate.year === (this.businessQuarter.quarter === 4 ? this.businessQuarter.year + 1 : this.businessQuarter.year)) ||
          this.getBlockQuarter()
        ) {
          this.owDate.subtract({ quarter: 1 });
          this.getLeague();
          return;
        } else {
          api = this.apiBusinessLeagueService.quarterly(
            {
              year: this.owDate.year,
              quarter: this.owDate.quarter,
            },
            this?.branch
          );
        }
        break;
    }

    this.setPagination();

    if (api) {
      api.subscribe(
        resp => {
          this.league = resp;
          this.setAllowEstimation();
          this.convertProgressbarValue();
          this.isLoading = false;
        },
        errResp => {
          this.league = null;
          this.isLoading = false;
          if (errResp.status === 404) {
            errResp.defaultHandler.unsubscribe();
          }
        }
      );
    } else {
      this.isLoading = false;
    }
  }

  getBlockQuarter(date = this.owDate.momentDate) {
    const currentDate = this.synchronizeTimeService.getActualLocalTime();
    const currentMomentDate = moment(currentDate);
    const quarterLastMonth = moment(date).endOf("quarter").month();

    // MANUAL LOCK #11848
    if (
      this.owDate.year === (this.businessQuarter.quarter === 4 ? this.businessQuarter.year + 1 : this.businessQuarter.year) &&
      this.owDate.quarter === (this.businessQuarter.quarter === 4 ? 1 : this.businessQuarter.quarter + 1)
    ) {
      return true;
    }

    return this.owDate.year >= currentMomentDate.year() && currentMomentDate.month() < quarterLastMonth;
  }

  getBlockQuarterNext() {
    // MANUAL LOCK #11848
    if (this.owDate.year === this.businessQuarter.year && this.owDate.quarter === this.businessQuarter.quarter) {
      return true;
    }

    return this.getBlockQuarter(moment(this.owDate.momentDate).add({ quarter: 1 }));
  }

  convertProgressbarValue() {
    this.league.tasks.forEach(task => {
      task.performance = task.performance.replace(",", ".");
      task.forecast = task.forecast.replace(",", ".");
    });
  }

  setAllowEstimation() {
    this.allowEstimation = false;

    switch (this.activeTabLeft.index) {
      case LEAGUE_TYPE.MONTH:
        this.allowEstimation = this.owDate.month === this.currentDate.month;
        break;

      case LEAGUE_TYPE.QUARTER:
        this.allowEstimation = this.owDate.quarter === this.currentDate.quarter;
        break;
    }

    if (this.owDate.year < this.currentDate.year) {
      this.allowEstimation = false;
    }

    if (this.branchName && !this.userService.me.enabled_players.some(player => player.id === this.data.playerId)) {
      this.allowEstimation = false;
    }
  }

  deleteEstimations() {
    this.apiBusinessLeagueService.deleteEstimations(this?.managerPlayerId).subscribe(() => {
      this.getLeague();
    });
  }

  getLeagueWithBranchInfo() {
    this.dashboardRoleChangeService.owDate = this.owDate;
    this.dashboardRoleChangeService.getDataWithBranchInfo(() => {
      this.getLeague();
    }, this.branch);
  }

  prev() {
    switch (this.activeTabLeft.index) {
      case LEAGUE_TYPE.MONTH:
        this.owDate.subtract({ month: 1 });
        break;

      case LEAGUE_TYPE.QUARTER:
        this.owDate.subtract({ quarter: 1 });
        break;
    }

    if (this?.branch && !this?.branch?.role_changed) {
      this.getLeagueWithBranchInfo();
    } else {
      this.getLeague();
    }
  }

  next() {
    switch (this.activeTabLeft.index) {
      case LEAGUE_TYPE.MONTH:
        this.owDate.add({ month: 1 });
        break;

      case LEAGUE_TYPE.QUARTER:
        this.owDate.add({ quarter: 1 });
        break;
    }

    if (this?.branch && !this?.branch?.role_changed) {
      this.getLeagueWithBranchInfo();
    } else {
      this.getLeague();
    }
  }

  openAlert(description: string) {
    this.dialogService.openAlert({ description });
  }

  slideEvent(task: LeagueTask, event) {
    task.forecast_estimation = +event.target.value;
  }

  onChange(task: LeagueTask, event) {
    if (event.target.value.length === 0) {
      event.target.value = 0;
    }

    if (event.target.value < task.forecast_min) {
      event.target.value = +task.forecast_min;
    }

    if (event.target.value > task.forecast_max) {
      event.target.value = +task.forecast_max;
    }

    if (event.target.value !== task.forecast_estimation || event.source) {
      task.forecast_estimation = +event.target.value;

      this.apiBusinessLeagueService
        .estimate(
          {
            task_id: task.id,
            forecast: task.forecast_estimation,
          },
          this?.managerPlayerId
        )
        .subscribe(resp => {
          this.league.estimation = resp;
        });
    } else {
      // Other value
      task.forecast_estimation = 0.01;
      setTimeout(() => {
        task.forecast_estimation = +event.target.value;
      });
    }
  }

  clearNotification() {
    this.clearNotificationService.clearNotification(["import_calculate_leagues"], this?.managerPlayerId);
  }

  ngOnDestroy() {
    this.clearNotification();
    unsubscribeObject(this.subs);
    this.branchSubscription.unsubscribe();
    this.branchNameSubscription.unsubscribe();
  }
}

interface TabLeft {
  label: string;
  data: string;
  index: number;
  visible: boolean;
}

const RESULTS_JANUARY =
  "https://sanwonderland.santanderconsumer.pl/api/files/download/7a3b1d96-fa24-4d40-a078-0025c9ad0e64/STYCZE%C5%83_grupy_ranking.pdf";
const RESULTS_FEBRUARY =
  "https://sanwonderland.santanderconsumer.pl/api/files/download/1992e42f-c833-4a70-a811-49f77291879d/aLUTY_grupy_ranking.pdf";
