import { Injectable } from "@angular/core";

import { AbstractEventDialogsService } from "../../../../../../core/abstracts/abstract-event-dialogs.service";
import { EVENT_DIALOGS_ANTYMINER_CUSTOM } from '../../consts/custom/event-dialogs/event-dialogs.const';

@Injectable({
  providedIn: "root",
})
export class AntyminerEventDialogsService extends AbstractEventDialogsService {
  getEventDialogs() {
    return {
      customEventDialogs: EVENT_DIALOGS_ANTYMINER_CUSTOM
    };
  }
}
