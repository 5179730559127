<div class="global-dialog ow-dialog c-window-long">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON  -->
  <m-ui-back-button></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Wyzwania biznesowe</span>
      </div>
    </div>
  </div>

  <div class="container">
    <div
      class="box"
      [class.blocked]="!playerService.player.gui_unlocks['contract_sale']"
      matTooltip="Dostępne wkrótce!"
      matTooltipPosition="below"
      [matTooltipDisabled]="playerService.player.gui_unlocks['contract_sale']"
    >
      <div class="icon contract-sale" (click)="openChallenge(CHALLENGE.CONTRACT)"></div>
      <span>Kontrakt</span>

      <a href="https://santao.santanderconsumer.pl/api/files/download/e27fc8f3-fede-4068-be71-ff1a12b1e05e/KONTRAKTY_San_Tao.pdf" target="_blank"><i [matTooltip]="'Zapoznaj się ze szczegółami.'" class="fas fa-info-square"></i></a>

      <div
        class="app-notification dot-blue"
        *ngIf="playerService.player['gui_notifications']?.['import_calculate_microleague_contract'] > 0"
      ></div>
    </div>

    <div class="separator"></div>

    <div
      class="box"
      [class.blocked]="!playerService.player.gui_unlocks['micro_league']"
      matTooltip="Dostępne wkrótce!"
      matTooltipPosition="below"
      [matTooltipDisabled]="playerService.player.gui_unlocks['micro_league']"
    >
      <div class="icon micro-league" (click)="openChallenge(CHALLENGE.MICROLEAGUE)"></div>
      <span>Mikroliga</span>

      <a href="https://santao.santanderconsumer.pl/api/files/download/52192d81-22a2-4e4a-9789-9c63e66baacd/MIKROLIGA%20SanTao.pdf" target="_blank"><i [matTooltip]="'Zapoznaj się ze szczegółami.'" class="fas fa-info-square"></i></a>

      <div
        class="app-notification dot-blue"
        *ngIf="playerService.player['gui_notifications']?.['import_calculate_microleague'] > 0"
      ></div>
    </div>
  </div>
  <div *ngIf="branchName" class="branch-name">{{ branchName }}</div>
</div>
