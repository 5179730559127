import * as moment from "moment";

import { TileTooltipType } from "../../../../constants";
import { TILE_MENU_ATLAS } from "../../../scenes-main/main.constants";
import { getTileTooltipPositionOffset } from "../../../utils/board.helper";
import { isAssetLoadedToPhaserCache } from "../../../utils/game.helper";
import { addTextShadow, isoToScreen } from "../../../utils/utils";
import { MyScene } from "../../core/MyScene";
import { TILE_TOOLTIP_OFFSET_BY_ICON } from "../../tile-menu/custom-tile-menu.config";
import { TileTooltipCore } from "../core/TileTooltipCore.class";

export class TileTooltip extends TileTooltipCore {
  handleTooltipExtensions() {
    switch (this.tooltipType) {
      case TileTooltipType.UPGRADE: {
        const time = this.boardTile.tileData.player_building?.built_at;

        this.counter = {
          percent: 0,
          intervalRef: null,
          stringTime: "",
          startTime: this.gameService.synchronizeTimeService.getActualLocalTime().getTime(),
          finishTime: moment(time),
        };

        const buildTimeTextObject = this.scene.add.text(0, 10, "", this.config.textStyles);
        addTextShadow(buildTimeTextObject);
        buildTimeTextObject.setOrigin(0.5, -1);
        this.setTimer(buildTimeTextObject);
        this.add(buildTimeTextObject);
        break;
      }

      case TileTooltipType.PRODUCTION: {
        this.counter = {
          percent: 0,
          intervalRef: null,
          stringTime: "",
          startTime: new Date(this.boardTile.tileData.production.created_at).getTime(),
          finishTime: moment(this.boardTile.tileData.production.finishes_at),
        };
        this.backgroundImage.destroy();
        const container = this.scene.add.container(-65, 60);
        const productionTimeTextObject = this.scene.add.text(0, 25, "", this.config.textStyles);
        addTextShadow(productionTimeTextObject);
        productionTimeTextObject.setOrigin(0, 0.5);
        this.tooltipImage.setPosition(85, -25);
        this.add(container);
        this.setTimer(productionTimeTextObject);

        const icon = this.getProductionIcon();
        const spinnerIcon = this.scene.add.image(35, -25, TILE_MENU_ATLAS, "production-spinner.png");
        this.setTooltipTexture(icon);
        this.setRotationAnim(spinnerIcon, -1);
        container.add([productionTimeTextObject, this.tooltipImage, spinnerIcon]);

        break;
      }

      case TileTooltipType.PRODUCTION_FINISHED:
      case TileTooltipType.COLLECT: {
        const value = this.boardTile.playerBuildingData.auto_production_amount || this.productionFinishValue;
        if (value) {
          const collectValue = this.scene.add.text(
            0,
            -160,
            `${this.boardTile.playerBuildingData.auto_production_amount || this.productionFinishValue}`,
            this.config.textStyles
          );
          addTextShadow(collectValue);
          collectValue.setOrigin(0.5, -1);
          this.add(collectValue);
          this.collectValueText = collectValue;
        }
        break;
      }
    }
  }

  afterCreate() {
    super.afterCreate();
    if (this.boardTile.hasBuilding) {
      const config = TILE_TOOLTIP_OFFSET_BY_ICON[this.boardTile.playerBuildingData.icon];
      if (config) {
        if (config.x) {
          this.x -= config.x;
        }
        if (config.y) {
          this.y -= config.y;
        }
      }
    }
  }

  setTooltipTexture(tileImage: string, iconFrame?: string) {
    const atlas = this.scene.cache.json.get("icons-atlas");
    if (atlas && iconFrame && atlas.frames.hasOwnProperty(`${iconFrame}.png`)) {
      this.tooltipImage.setTexture("icons-atlas", `${iconFrame}.png`);
    } else if (isAssetLoadedToPhaserCache(tileImage, this.scene.game.textures.getTextureKeys())) {
      if (!this.destroying) {
        this.tooltipImage.setTexture(tileImage);
      }
    } else {
      this.gameService.loadGameImages(this.scene as MyScene, [tileImage]).subscribe(() => {
        if (!this.destroying) {
          this.tooltipImage.setTexture(tileImage);
        }
      });
    }
  }

  setType(type: TileTooltipType) {
    this.tooltipType = type;
    if (
      (!this.boardTile.isBuildInProgress() && (this.isProductionInProgress() || this.isProductionFinished() || this.isAutoProduction())) ||
      this.boardTile.hasMission()
    ) {
      this.backgroundImage = this.scene.add.image(0, 0, TILE_MENU_ATLAS, "take.png");
      this.backgroundImage.setOrigin(0.5);
      this.backgroundImage.setScale(this.config.scaleFactor);
      this.backgroundImage.setAlpha(0);
      this.add(this.backgroundImage);
      this.tooltipImage = this.scene.add.sprite(0, 0, TILE_MENU_ATLAS, "production-spinner.png");
      this.tooltipImage.setScale(this.config.scaleFactor);
      this.tooltipImage.setOrigin(0.5, 0.5);

      let icon = null;
      if (this.boardTile.hasMission()) {
        icon = this.gameService.assetsService.getAsset(`missions/board-main/${this.boardTile.playerBuildingData.player_mission.icon}.png`);
        this.setJumpAnim([this.tooltipImage, this.backgroundImage]);
        if (icon) {
          this.setTooltipTexture(icon.path);
          this.tooltipImage.setOrigin(0.5, 0.58);
        }

        if (this.boardTile.tileData.player_building.player_mission.expires_at) {
          const missionTime = this.scene.add.text(0, -160, "00:00:00", this.config.textStyles);
          addTextShadow(missionTime);
          missionTime.setOrigin(0.5, -1);

          this.counter = {
            percent: 0,
            intervalRef: null,
            stringTime: "",
            startTime: this.gameService.synchronizeTimeService.getActualLocalTime().getTime(),
            finishTime: moment(this.boardTile.tileData.player_building.player_mission.expires_at),
          };
          this.setTimer(missionTime);
          this.add(missionTime);
        }
      } else if (this.isProductionFinished()) {
        this.backgroundImage.setAlpha(1);
        const currencyPrize = this.boardTile.tileData.production.currency_prizes[0];
        const productPrize = this.boardTile.tileData.production.product_prizes[0] as any;

        this.productionFinishValue = currencyPrize ? currencyPrize.amount : productPrize.amount;

        let iconKey;
        if (currencyPrize) {
          const currency = this.gameService.currencyService.getCurrencyDefinition(currencyPrize);
          iconKey = currency.key;
          icon = currency.iconUrlBig;
        } else if (productPrize) {
          iconKey = productPrize.icon + "-small";
          const product = this.gameService.productionService.productsService.getProduct(productPrize);
          icon = product.iconUrl;
        }

        this.setTooltipTexture(icon, iconKey);
        this.setJumpAnim([this.tooltipImage, this.backgroundImage]);
      } else if (this.isAutoProduction()) {
        this.backgroundImage.setAlpha(1);
        const currencyPrize = this.boardTile.tileData.player_building.automatic_currency;
        const productPrize = this.boardTile.tileData.player_building.automatic_product;
        let iconKey;
        if (currencyPrize) {
          const currency = this.gameService.currencyService.getCurrencyDefinition(currencyPrize);
          iconKey = currency.key;
          icon = currency.iconUrlBig;
        } else if (productPrize) {
          iconKey = productPrize.icon + "-small";
          const product = this.gameService.productionService.productsService.getProduct(productPrize);
          icon = product.iconUrl;
        }

        this.setTooltipTexture(icon, iconKey);
        this.setJumpAnim([this.tooltipImage, this.backgroundImage]);
      }
    } else {
      /**
       * Refactored to allow collect auto production while building build is in progress.
       * This change is made just after another change, that checked if tile should be update silent (without re-rendering),
       * so it's a little mess and redundant now. @todo: need to refactor/rewrite all Tooltip logic. Maybe allow multiple tooltips?
       */
      let icon = null;
      if (this.isAutoProduction() && this.boardTile.playerBuildingData.auto_production_amount > 0) {
        const bg = this.scene.add.image(0, 0, TILE_MENU_ATLAS, "take.png");
        bg.setOrigin(0.5);
        bg.setScale(this.config.scaleFactor);
        this.add(bg);
        this.tooltipImage = this.scene.add.sprite(0, 0, TILE_MENU_ATLAS, "production-spiner.png");
        this.tooltipImage.setScale(this.config.scaleFactor);
        this.tooltipImage.setOrigin(0.5, 0.5);

        const currencyPrize = this.boardTile.tileData.player_building.automatic_currency;
        const productPrize = this.boardTile.tileData.player_building.automatic_product;
        if (currencyPrize) {
          const currency = this.gameService.currencyService.getCurrencyDefinition(currencyPrize);
          icon = currency.iconUrlBig;
        } else if (productPrize) {
          const product = this.gameService.productionService.productsService.getProduct(productPrize);
          icon = product.iconUrl;
        }
        this.type = TileTooltipType.COLLECT;
        this.setTooltipTexture(icon);
        this.setJumpAnim([this.tooltipImage, bg]);
      } else {
        this.tooltipImage = this.scene.add.sprite(0, 0, TILE_MENU_ATLAS, `tooltip_${type}.png`);
        this.tooltipImage.setScale(this.config.scaleFactor);
        this.setLevitationAnim(this.tooltipImage);
      }
    }

    this.add(this.tooltipImage);
    const screenPos = isoToScreen(this.boardTile.isoX, this.boardTile.isoY);
    let tooltipOffset;

    tooltipOffset = getTileTooltipPositionOffset(this.boardTile.tileData.tile_type, this.boardTile.playerBuildingData.icon);

    this.x = this.boardTile.x + tooltipOffset.x;
    this.y = this.boardTile.y + tooltipOffset.y;

    this.handleTooltipExtensions();
    this.afterCreate();
  }
}
