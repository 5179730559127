<div class="global-dialog ow-dialog c-window-long">
  <loading></loading>

  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button
    *ngIf="!data?.isOpenFromQuarter"
  ></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Osiągnięcia ligowe</span>
      </div>
    </div>

    <div class="source-data-date-tab" *ngIf="sourceDate">
      Dane aktualne na: {{ sourceDate | date:'dd-MM-y' }}
    </div>
  </div>

  <div class="date">
    <a
      [href]="'https://santao.santanderconsumer.pl/api/files/download/23731948-d3e7-4947-9731-10928c64dd5c/Osi%C4%85gni%C4%99cia_ligowe.pdf'"
      target="_blank"
    >
      <i class="fas fa-info-square"></i>
    </a>
  </div>

  <div class="container" *ngIf="achievements">
    <ow-perfect-scrollbar [scrollGradient]="achievements">
      <div
        *ngFor="let item of achievements"
        [class.blocked]="!item.is_available"
      >
        <div
          class="achievement"
          [class.flipped]="item.flipped"
        >
          <figure class="front">
            <div
              class="flip-card"
              (click)="item.flipped = !item.flipped"
              [class.blocked]="!item.is_available"
            >
              <i
                class="fas fa-share"
                [class.rotate]="!item.flipped"
              ></i>
            </div>

            <img class="l-icon" [src]="item.icon_active + '.png' | asset: 'ui'"/>

            <div class="title">{{ item.name }}</div>

            <div
              class="app-notification"
              *ngIf="item.hasNotification"
            ></div>

            <div class="progress-container-after">
              <div class="progressbar">
                <div class="polygons" *ngIf="item.polygons?.length > 0">
                  <div
                    *ngFor="let threshold of item.polygons; let i = index"
                    [style.left]="((i + 1) * 100) / item.threshold.limit + '%'"
                  ></div>
                </div>

                <div
                  class="fill"
                  [style.width]="(item.threshold.offset * 100) / item.threshold.limit + '%'"
                  [class.fill-color-4]="item.isProgressGreen"
                  [class.fill-color-5]="!item.isProgressGreen"
                ></div>
              </div>
            </div>

            <div class="value">
              zdobyte: {{ item.threshold.offset }} z {{ item.threshold.limit }}
            </div>

            <m-ui-separator></m-ui-separator>

            <div class="target margin-v2">
              Nagroda do zdobycia:

              <m-ui-currency
                *ngIf="item.prizes?.currency.length > 0"
                [item]="item.prizes?.currency[0]"
                [stockView]="STOCK_VIEW.CUSTOM"
                [customClassStock]="'stock-card-details'"
                [lack]="false"
              ></m-ui-currency>
            </div>

            <div
              class="target"
              [matTooltip]="'Niespełniony warunek'"
              [matTooltipDisabled]="item.is_achievable"
              matTooltipPosition="above"
            >
              Twoja nagroda:

              <m-ui-currency
                [item]="item.sumPrizes"
                [stockView]="STOCK_VIEW.CUSTOM"
                [customClassStock]="'stock-card-details'"
                [lack]="false"
              ></m-ui-currency>
            </div>

            <m-ui-separator></m-ui-separator>

            <div class="target available">
              <div
                [matTooltip]="item.availability.is_league_group_limit ? 'w grupie ligowej' : 'w kraju'"
                matTooltipPosition="above"
              >
                Pozostało dostępnych: {{ item.availability.available }} z {{ item.availability.limit }}
              </div>

              <div class="details" (click)="openAchievementDetails(item.achievement_id)">
                <i class="far fa-chevron-circle-right"></i>
              </div>
            </div>
          </figure>

          <!-- BACK -->
          <figure class="back">
            <div
              class="flip-card"
              (click)="item.flipped = !item.flipped"
              [class.visibility-hidden]="!item.flipped && !item.is_available"
            >
              <i
                class="fas fa-share"
                [class.rotate]="!item.flipped"
              ></i>
            </div>

            <img class="l-icon" [src]="item.icon_active + '.png' | asset: 'ui'"/>

            <div class="title">{{ item.name }}</div>

            <div class="desc margin-v1">{{ item.description }}</div>

            <ng-container *ngIf="item.completions.length > 0">
              <div class="desc">
                Zdobyte:
                <br>
                <ng-container *ngFor="let b of item.completions">
                  {{ b?.completion_date | date: 'dd.MM.yy' }} w oddziale {{ b?.branch_name }}
                </ng-container>
              </div>
            </ng-container>
          </figure>
        </div>
      </div>
    </ow-perfect-scrollbar>
  </div>
  <div *ngIf="branchName" class="branch-name">{{ branchName }}</div>
</div>
