import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { forkJoin } from "rxjs";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { BuildingsService } from "../../../../../../services/buildings.service";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";

@Component({
  selector: "app-tracking-start",
  templateUrl: "./tracking-start.component.html",
})
export class TrackingStartComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(MAT_DIALOG_DATA) data: { playerBuildingId: number; upgradeBuildingId: number };
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<TrackingStartComponent>;
  @OwInject(BuildingsService) buildingsService: BuildingsService;

  STOCK_VIEW = STOCK_VIEW;
  isLoading;
  buildingDetails;
  upgradeBuildingDetails;
  requirementsStatus: { valid: boolean; requirementsList: any[] };

  ngOnInit() {
    this.combineBuildDetails();
  }

  combineBuildDetails() {
    this.isLoading = true;
    forkJoin([
      this.buildingsService.getPlayerBuildingDetails(this.data.playerBuildingId),
      this.buildingsService.getBuildingDetails(this.data.upgradeBuildingId),
    ]).subscribe(([buildingDetails, upgradeBuildingDetails]) => {
      this.buildingDetails = buildingDetails;
      this.upgradeBuildingDetails = upgradeBuildingDetails;
      this.isLoading = false;
    });
  }

  setRequirementsStatus(status) {
    this.requirementsStatus = status;
  }

  track() {
    this.buildingsService.upgrade(this.data.playerBuildingId).subscribe(() => {
      this.matDialogRef.close({ trackDown: true });
    });
  }

  close() {
    this.matDialogRef.close({ trackDown: false });
  }
}
