<div class="ow-dialog">
  <!-- DIALOG EXIT -->

  <div class="description">Masz nowe wydarzenie w dalszej części kalendarza. <br />
    Czy na pewno chcesz zamknąć okno?</div>

  <div class="buttons-wrapper">
    <button class="c-base c-primary" (click)="closeCalendar()">
      Tak, chcę zamknąć kalendarz
    </button>
    <button class="c-base c-secondary" (click)="close()">
      Nie, chcę wrócić do kalendarza
    </button>

  </div>


</div>
