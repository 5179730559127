import { CatchBananasPlayScene } from "../../scenes/CatchBananasPlay.scene";

export class AbstractArcadeSprite extends Phaser.Physics.Arcade.Sprite {
  scene: CatchBananasPlayScene;
  colliders: Phaser.Physics.Arcade.Collider[] = [];
  constructor(scene: CatchBananasPlayScene, x: number, y: number, texture: string, frame: string) {
    super(scene, x, y, texture, frame);
    scene.add.existing(this);

    this.scene.physics.world.enable(this);
    this.createAnimations();
    this.handldeStartAnimation();
    this.handleUpdateAnimation();
    this.handleCompleteAnimation();
    this.spriteConfiguration();
    this.addColiders();
  }

  createAnimations() {}

  handldeStartAnimation() {}

  handleUpdateAnimation() {}

  handleCompleteAnimation() {}

  spriteConfiguration() {}

  addColiders() {
    this.colliders.push(this.scene.physics.add.collider(this, this.scene.catchBananasState.platformBanana, this.destroyObject, null, this));
    this.colliders.push(this.scene.physics.add.overlap(this, this.scene.catchBananasState.annie, this.collectObject, null, this));
  }

  collectObject() {
    this.scene.catchBananasState.annie.anims.play("ania_triumph");
    this.scene.saveCurrentState();
    this.destroy();
  }

  destroyObject() {
    this.colliders.forEach(collider => collider.destroy());
    this.scene.physics.world.disable(this);
    this.scene.saveCurrentState();
  }
}
