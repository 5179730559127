import * as moment from "moment";
import * as R from "ramda";
import { Subscription, timer } from "rxjs";

import { GameEvent } from "../../../game/interfaces/game.interfaces";
import { GameService } from "../../../game/services/game.service";
import { GameModes } from "../../enums/game-modes.enum";
import { GameParams } from "../../interfaces/game-params";
import { MiniGameEventsEnum } from "../../interfaces/mini-game-events.enum";
import {
  CB_ATLAS_KEY,
  CB_EDITABLE,
  CB_GAME_HEIGHT,
  CB_GAME_WIDTH,
  CB_PAUSE_USED_LS_KEY,
  CB_PLAY_SCENE_KEY,
  CB_SPRITE_POSITIONS,
  CB_UI_KEY,
} from "../catch-bananas.constans";
import { provideSceneConfig } from "../catch-bananas.helpers";
import { CatchBananas } from "../classes/catch-bananas";
import { CatchBananasGame } from "../classes/CatchBananasGame";
import { AnnieSprite, MonkeySprite, TreeSprite } from "../classes/sprites";
import { BoxGuiSprite } from "../classes/sprites/box-gui.sprite";
import { CbGameState } from "../interfaces/cb-game-state.interface";
import { CatchBananasService } from "../services/catch-bananas.service";

const fontStyles = {
  color: "#FFF",
  fontSize: "22px",
  fontFamily: "MikadoPhaser",
  align: "center",
  fontStyle: "normal",
};

export class CatchBananasPlayScene extends Phaser.Scene {
  // game
  editMode: boolean;
  isPlaying = false;
  sceneConfig: any;
  gameService: GameService;
  myGame: CatchBananasGame;
  catchBananasState = new CatchBananas();
  catchBananasService: CatchBananasService;
  gameParams: GameParams;
  treesOccupied = [];
  lastThrowTimeStamp = moment();
  resumeTimeCounter$: Subscription;

  // Subs
  cbEvents$: Subscription;

  keyLeft: Phaser.Input.Keyboard.Key;
  keyRight: Phaser.Input.Keyboard.Key;
  uiContainer: Phaser.GameObjects.Container;

  soundIcon: Phaser.GameObjects.Sprite;
  music: Phaser.Sound.BaseSound;
  collectSound: Phaser.Sound.BaseSound;
  errorSound: Phaser.Sound.BaseSound;
  clockSound: Phaser.Sound.BaseSound;
  collectWoodSound: Phaser.Sound.BaseSound;
  boardButtonsContainer: Phaser.GameObjects.Container; // volume, reset buttons; right bottom
  isSoundOn: boolean;

  constructor() {
    super({ key: CB_PLAY_SCENE_KEY });
  }

  init(data: any) {
    this.myGame = this.game as CatchBananasGame;
    this.gameParams = this.myGame.gameConfig.gameParams;
    if (this.gameParams.state) {
      this.mergeConfigs();
    } else {
      this.sceneConfig = provideSceneConfig();
      this.generateRandomPositions();
      this.catchBananasState.timeLeft = this.isPremiumGame() ? CB_EDITABLE.CB_PREMIUM_GAME_TIME : CB_EDITABLE.CB_NORMAL_GAME_TIME;
    }
  }

  preload() {
    this.editMode = (this.game as CatchBananasGame).editMode;
    const preloadText = this.add.text(this.cameras.main.centerX, this.cameras.main.centerY, "");
    this.gameService = (this.game as CatchBananasGame).gameService;
    this.catchBananasService = (this.game as CatchBananasGame).catchBananasService;
    this.loadAssets();
    this.loadSounds();

    this.load.on("progress", progress => {
      const progressRound = Math.round(100 * progress);
      preloadText.setText(progressRound + "%");
      this.emitCBEvent(MiniGameEventsEnum.LOAD_PROGRESS, progressRound);
    });

    this.load.on("complete", progress => {
      preloadText.destroy();
    });
  }

  create() {
    this.keyboardKeysInit();
    this.subscribeCBEvents();
    this.emitCBEvent(MiniGameEventsEnum.LOAD_COMPLETE);
    this.initializeSound();
    this.createUI();
  }

  update(time: number, delta: number) {}

  generateRandomPositions() {
    this.sceneConfig.monkeys.forEach(monkey => {
      monkey.treeId = this.generateOccupiedTreeIndex();
    });
    this.generateOccupiedTree();
  }

  generateOccupiedTree() {
    this.sceneConfig.trees.forEach(tree => {
      const monkey = this.sceneConfig.monkeys.find(monkey => monkey.treeId === tree.id);
      monkey ? (tree.isOccupied = true) : (tree.isOccupied = false);
    });
  }

  isPremiumGame() {
    return this.gameParams.gameMode === GameModes.PREMIUM;
  }

  startGame(instant?: boolean) {
    this.emitCBEvent(MiniGameEventsEnum.PROGRESS, this.getCurrentState());
    this.music.play();
    this.music["loop"] = true;
    if (instant) {
      return;
    }
    const counter = this.add.text(this.cameras.main.centerX, this.cameras.main.centerY, `3`, fontStyles);
    counter.setFontSize(60);
    let timeToStart = 3;
    const startTimes = this.time.addEvent({
      delay: 1000,
      callback: () => {
        timeToStart--;
        counter.setText(`${timeToStart}`);
        if (timeToStart <= 0) {
          startTimes.remove();
          counter.destroy();
          this.isPlaying = true;
          this.createTimeLeftTimer();
          document.body.onkeyup = e => {
            if (this.scene && e.keyCode === 32) {
              if (this.wasPauseUsed()) {
                return;
              }

              // this.scene.isPaused() ? this.scene.resume() : this.scene.pause();
              if (!this.scene.isPaused()) {
                this.input.keyboard.resetKeys();
                localStorage.setItem(CB_PAUSE_USED_LS_KEY, "true");
                this.scene.pause();
                this.emitCBEvent(MiniGameEventsEnum.GAME_PAUSE);
              }
            }
          };
        }
      },
      callbackScope: this,
      loop: true,
    });
  }

  wasPauseUsed() {
    return Boolean(localStorage.getItem(CB_PAUSE_USED_LS_KEY));
  }

  subscribeCBEvents() {
    this.cbEvents$ = this.myGame.gameConfig.catchBananasService.cbEvents.subscribe((event: GameEvent) => {
      if (!this.cameras.main) {
        this.cbEvents$.unsubscribe();
        return;
      }
      switch (event.name) {
        case MiniGameEventsEnum.GAME_START:
          this.createSprites();
          this.startGame();
          break;
        case MiniGameEventsEnum.GAME_PAUSE:
          this.scene.pause();
          break;
        case MiniGameEventsEnum.GAME_RESUME:
          const counter = this.add.text(this.cameras.main.centerX, this.cameras.main.centerY, `3`, fontStyles);
          counter.setFontSize(60);
          let timeToStart = 3;
          this.resumeTimeCounter$ = timer(1000, 1000).subscribe(i => {
            timeToStart--;
            counter.text = `${timeToStart}`;
            if (timeToStart === 0) {
              this.scene.resume();
              this.resumeTimeCounter$.unsubscribe();
              counter.destroy();
            }
          });
          break;
        case MiniGameEventsEnum.DESTROY:
          if (this.resumeTimeCounter$) {
            this.resumeTimeCounter$.unsubscribe();
          }
          break;
      }
    });
  }

  loadAssets() {
    this.load.atlas(
      CB_ATLAS_KEY,
      this.gameService.assetsService.getAsset("minigames/catch-bananas/atlas/cb-atlas.png").path,
      this.gameService.assetsService.getAsset("minigames/catch-bananas/atlas/cb-atlas.json").path
    );

    this.load.atlas(
      CB_UI_KEY,
      this.gameService.assetsService.getAsset("minigames/catch-bananas/atlas/cb-ui.png").path,
      this.gameService.assetsService.getAsset("minigames/catch-bananas/atlas/cb-ui.json").path
    );

    this.load.image("background", this.gameService.assetsService.getAsset("minigames/catch-bananas/background.png").path);
  }

  loadSounds() {
    this.load.audio("collect", this.gameService.assetsService.getAsset("minigames/catch-bananas/sfx/coin.mp3").path);
    this.load.audio("collect-wood", this.gameService.assetsService.getAsset("minigames/catch-bananas/sfx/collect-wood.mp3").path);
    this.load.audio("music", this.gameService.assetsService.getAsset("minigames/catch-bananas/sfx/music.mp3").path);
    this.load.audio("error", this.gameService.assetsService.getAsset("minigames/catch-bananas/sfx/error.mp3").path);
    this.load.audio("clock", this.gameService.assetsService.getAsset("minigames/catch-bananas/sfx/clock.mp3").path);
  }

  initializeSound() {
    this.collectSound = this.sound.add("collect");
    this.collectSound["volume"] = 0.3;
    this.collectWoodSound = this.sound.add("collect-wood");
    this.collectWoodSound["volume"] = 0.3;
    this.clockSound = this.sound.add("clock");
    this.clockSound["volume"] = 1;
    this.errorSound = this.sound.add("error");
    this.errorSound["volume"] = 0.3;
    this.music = this.sound.add("music");
    this.music["volume"] = 0.1;
  }

  createSprites() {
    //Background
    const background = this.add.image(this.sceneConfig.background.x, this.sceneConfig.background.y, "background");
    if (this.sceneConfig.background.origin) {
      background.setOrigin(this.sceneConfig.background.origin.x, this.sceneConfig.background.origin.y);
    }

    // Trees
    this.sceneConfig.trees.forEach((tree, i) => {
      const sprite = new TreeSprite(this, this.setPosition(tree.id), CB_SPRITE_POSITIONS.y.palms, CB_ATLAS_KEY, tree.icon, {
        ...tree,
      });
      if (!this.gameParams.state) {
        this.catchBananasState.trees.push(sprite);
      }
      this.catchBananasState.trees[i] = sprite;
    });

    // Monkeys
    this.sceneConfig.monkeys.forEach((monkey, i) => {
      const sprite = new MonkeySprite(this, this.setPosition(monkey.treeId), CB_SPRITE_POSITIONS.y.monkeys, {
        ...monkey,
      });
      if (!this.gameParams.state) {
        this.catchBananasState.monkeys.push(sprite);
      } else {
        this.catchBananasState.monkeys[i] = sprite;
      }
    });

    // Platforms
    this.add
      .graphics({})
      .fillRect(0, CB_SPRITE_POSITIONS.y.annie - 10, CB_GAME_WIDTH, 0)
      .generateTexture("platform");

    this.catchBananasState.platformBanana = this.physics.add
      .staticSprite(0, CB_SPRITE_POSITIONS.y.annie, "platform")
      .setOrigin(0)
      .refreshBody();

    this.catchBananasState.platformAnnie = this.physics.add
      .staticSprite(0, CB_SPRITE_POSITIONS.y.annie - 100, "platform")
      .setOrigin(0)
      .refreshBody();

    // AnnieSprite
    this.catchBananasState.annie = new AnnieSprite(
      this,
      CB_SPRITE_POSITIONS.x[this.sceneConfig.annie.position],
      CB_SPRITE_POSITIONS.y.annie
    );

    // menu on the right bottom
    this.boardButtonsContainer = this.add.container(this.cameras.main.width - 30, this.cameras.main.height - 30);

    // music
    this.soundIcon = this.add.sprite(0, 0, CB_UI_KEY, "sound_on.png");
    this.boardButtonsContainer.add(this.soundIcon);
    this.soundIcon.setInteractive({
      cursor: "pointer",
    });
    this.soundIcon.on("pointerdown", () => {
      this.isSoundOn = !this.isSoundOn;
      this.setVolume();
    });
    this.soundIcon.on("pointerover", () => {
      this.soundIcon.setAlpha(0.8);
    });
    this.soundIcon.on("pointerout", () => {
      this.soundIcon.setAlpha(1);
    });
  }

  createUI() {
    const { width: cameraWidth, height: cameraHeight, centerX } = this.cameras.main;
    this.uiContainer = this.add.container(centerX, cameraHeight - 30);
    this.uiContainer.setDepth(9999);
    const bar = this.add.image(0, 0, CB_UI_KEY, "bar.png").setScale(1.4, 1);
    bar.setOrigin(0.5);
    this.uiContainer.add(bar);

    const timeImage = this.add.image(-135, 0, CB_UI_KEY, "clock.png");
    this.uiContainer.add(timeImage);
    this.catchBananasState.timeLeftText = this.add.text(timeImage.x + 30, 0, `${this.catchBananasState.timeLeft} s`, fontStyles);
    this.catchBananasState.timeLeftText.setOrigin(0, 0.5);
    this.uiContainer.add(this.catchBananasState.timeLeftText);

    const banana = this.add.image(-50, 0, CB_UI_KEY, "banana.png").setOrigin(0, 0.5);
    this.uiContainer.add(banana);

    this.catchBananasState.bananasText = this.add
      .text(banana.x + banana.width + 4, 0, `${this.catchBananasState.score1}`, fontStyles)
      .setOrigin(0, 0.5);
    this.uiContainer.add(this.catchBananasState.bananasText);

    const rottenBanana = this.add.image(45, 0, CB_UI_KEY, "banana_rotten.png").setOrigin(0, 0.5);
    this.uiContainer.add(rottenBanana);

    this.catchBananasState.rottenBananasText = this.add
      .text(rottenBanana.x + rottenBanana.width + 4, 0, `${this.catchBananasState.score2}`, fontStyles)
      .setOrigin(0, 0.5);
    this.uiContainer.add(this.catchBananasState.rottenBananasText);

    if (this.isPremiumGame()) {
      const boxX = CB_GAME_WIDTH / 2 - 15;
      const boxY = -CB_GAME_HEIGHT / 2;
      const barBox = this.add.image(CB_GAME_WIDTH / 2 - 15, -CB_GAME_HEIGHT / 2, CB_UI_KEY, "bar_box.png").setOrigin(1, 1.5);
      this.uiContainer.add(barBox);

      let boxMargin = 0;
      if (this.isPremiumGame()) {
        const boxesTimeRanges = [
          { min: 0, max: 40 },
          { min: 50, max: 80 },
          { min: 90, max: 110 },
        ];
        this.sceneConfig.boxes.forEach((box, i) => {
          if (!this.gameParams.state) {
            const randTimeRange = {
              min: boxesTimeRanges[i].min,
              max: boxesTimeRanges[i].max,
            };
            box.throwTime = Math.floor(Math.random() * (randTimeRange.max - randTimeRange.min) + randTimeRange.min);
          }
          const boxSprite = new BoxGuiSprite(this, boxX - 27, boxY - 235, {
            id: box.id,
            throwTime: box.throwTime,
            icon: box.icon,
          });
          boxMargin += boxSprite.height + 6;
          boxSprite.setY(boxSprite.y + boxMargin);

          if (!this.gameParams.state) {
            this.catchBananasState.boxes.push(boxSprite);
          } else {
            this.catchBananasState.boxes[i] = boxSprite;
          }
          this.uiContainer.add(boxSprite);
        });
      }
    }
  }

  setPosition(position: number) {
    return CB_SPRITE_POSITIONS.x[position];
  }

  getCurrentState(): CbGameState {
    return {
      chestsCatched: this.catchBananasState.chestsCatched,
      annie: {
        position: this.catchBananasState.annie.position,
      },
      velocity: this.catchBananasState.velocity,
      timeLeft: this.catchBananasState.timeLeft,
      time: this.catchBananasState.time,
      score1: this.catchBananasState.score1,
      score2: this.catchBananasState.score2,
      score3: this.catchBananasState.score3,
      monkeys: this.catchBananasState.monkeys.map(monkey => {
        return {
          id: monkey.id,
          throwIntervalTime: monkey.throwIntervalTime,
          jumpIntervalTime: monkey.jumpIntervalTime,
          treeId: monkey.treeId,
          fallenBox: monkey.fallenBox
            ? {
                throwTime: monkey.fallenBox.throwTime,
                icon: monkey.fallenBox.icon,
                id: monkey.fallenBox.id,
              }
            : null,
        };
      }),
      trees: this.catchBananasState.trees.map(tree => {
        return {
          id: tree.id,
          isOccupied: tree.isOccupied,
          icon: tree.icon,
        };
      }),
      boxes: this.isPremiumGame()
        ? this.catchBananasState.boxes.map(box => {
            return {
              id: box.id,
              icon: box.icon,
              throwTime: box.throwTime,
            };
          })
        : null,
    };
  }

  emitCBEvent(name: MiniGameEventsEnum, value?: any) {
    this.myGame.gameConfig.catchBananasService.cbEvents.emit({
      name,
      value,
    });
  }

  keyboardKeysInit() {
    this.input.enabled = true;
    this.keyLeft = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.LEFT);
    this.keyRight = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.RIGHT);
  }

  createTimeLeftTimer() {
    this.time.addEvent({
      delay: 1000,
      callback: () => {
        this.catchBananasState.time++;
        if (this.isPremiumGame()) {
          const box = this.catchBananasState.boxes.find(box => box.throwTime === this.catchBananasState.time);
          if (box) {
            this.catchBananasState.monkeys[box.id - 1].fallenBox = box;
          }
        }
        if (this.catchBananasState.time % CB_EDITABLE.CB_TIME_DIFFICULITY === CB_EDITABLE.CB_TIME_DIFFICULITY - 1) {
          this.catchBananasState.velocity += CB_EDITABLE.CB_BANANA_VELOCITY_ACCELERATION;
        }
        this.catchBananasState.timeLeft--;
        this.updateTimeText();
        if (this.catchBananasState.timeLeft <= 0) {
          this.gameOver();
        }
      },
      callbackScope: this,
      loop: true,
    });
  }

  updateTimeText(timeValue?: number) {
    if (timeValue) {
      this.catchBananasState.timeLeft += timeValue;
    }
    if (this.catchBananasState.timeLeft <= 0) {
      this.catchBananasState.timeLeft = 0;
      this.catchBananasState.timeLeftText.setText(`${this.catchBananasState.timeLeft.toString()} s`);
      this.gameOver();
    }
    this.catchBananasState.timeLeftText.setText(`${this.catchBananasState.timeLeft.toString()} s`);
    if (timeValue) {
      this.catchBananasState.timeLeftText.setScale(2);
      this.catchBananasState.timeLeftText.setTint(0xff0000);
      this.add.tween({
        targets: this.catchBananasState.timeLeftText,
        scale: 1,
        duration: 300,
      });

      this.tweens.addCounter({
        from: 0,
        to: 255,
        duration: 300,
        onUpdate: tween => {
          const value = Math.floor(tween.getValue());
          this.catchBananasState.timeLeftText.setTint(
            Phaser.Display.Color.GetColor(timeValue < 0 ? 255 : value, timeValue > 0 ? 255 : value, value)
          );
        },
      });
    }
  }

  gameOver() {
    this.emitCBEvent(MiniGameEventsEnum.GAME_OVER, this.getCurrentState());
    localStorage.removeItem(CB_PAUSE_USED_LS_KEY);
    this.scene.pause();
  }

  generateOccupiedTreeIndex() {
    const index = this.getRandomIntInclusive(1, 4);

    if (this.treesOccupied.indexOf(index) > -1) {
      return this.generateOccupiedTreeIndex();
    }
    this.treesOccupied.push(index);
    return index;
  }

  getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  saveCurrentState() {
    if (!this.isPlaying) {
      return;
    }
    this.emitCBEvent(MiniGameEventsEnum.PROGRESS, this.getCurrentState());
  }

  setVolume() {
    if (this.isSoundOn) {
      this.music["volume"] = 0;
      this.collectSound["volume"] = 0;
      this.clockSound["volume"] = 0;
      this.errorSound["volume"] = 0;
      this.collectWoodSound["volume"] = 0;
      this.soundIcon.setFrame("sound_off.png");
    } else {
      this.music["volume"] = 0.3;
      this.clockSound["volume"] = 1;
      this.collectSound["volume"] = 0.3;
      this.errorSound["volume"] = 0.3;
      this.collectWoodSound["volume"] = 0.3;
      this.soundIcon.setFrame("sound_on.png");
    }
  }

  private mergeConfigs() {
    this.sceneConfig = R.mergeDeepRight(provideSceneConfig(), this.gameParams.state);
    this.catchBananasState = R.mergeDeepRight(this.catchBananasState, this.gameParams.state);
    const monkeyThrowingBox = this.catchBananasState.monkeys.find(monkey => monkey.fallenBox);
    if (monkeyThrowingBox) {
      this.catchBananasState.boxes.map(box => {
        if (monkeyThrowingBox) {
          box.throwTime += 1;
        }
        return box;
      });
      this.sceneConfig.boxes.map(box => {
        if (monkeyThrowingBox) {
          box.throwTime += 2;
        }
        return box;
      });
    }
  }
}
