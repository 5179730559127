import { Component, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as R from "ramda";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { PLAYER_TITLES } from "../../../../../../../../core/consts/core/player-titles";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { NotificationsService } from "../../../../../../services/custom/notifications.service";
import { ApiMicroLeagueService } from "../../../../api/services/api-micro-league.service";
import { CHALLENGE_NAME, MICRO_LEAGUE_TABS } from "../../../../consts/custom/business-challenges.const";
import {
  Achievement,
  ChangeTabMicroLeague,
  MicroLeague,
  PlayerMicroLeagueDetails,
  TabTopMicroLeague,
} from "../../../../interfaces/custom/business-challenges.interface";
import { MicroLeagueComponent } from "./micro-league/micro-league.component";
import { ClearNotificationService } from "../../../utils/clear-notification.service";
import { MicroLeagueMaxPrizesComponent } from "./micro-league-max-prizes/micro-league-max-prizes.component";

@Component({
  selector: "app-micro-league-tabs",
  templateUrl: "./micro-league-tabs.component.html",
})
export class MicroLeagueTabsComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(ApiMicroLeagueService) apiMicroLeagueService: ApiMicroLeagueService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<MicroLeagueComponent>;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(NotificationsService) notificationsService: NotificationsService;
  @OwInject(ClearNotificationService) clearNotificationService: ClearNotificationService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data: { playerId?: number; branchName?: string };

  tabs: TabTopMicroLeague[] = MICRO_LEAGUE_TABS;

  TEMPLATE = {
    MICROLEAGUE: CHALLENGE_NAME.MICROLEAGUE,
    ACHIEVEMENTS: CHALLENGE_NAME.ACHIEVEMENTS,
  };

  microLeague: MicroLeague = {
    list: [],
    details: null,
    isClosedViewActive: false,
    detailsSourceData: null,
    isCleared: null,
    isDetailsViewActive: false,
    closedDisableBtn: false,
  };

  achievement: Achievement = {
    details: null,
    list: [],
    listSourceData: null,
    activeTemplate: null,
    visibility: null,
    isChooseViewEnable: false,
  };

  ACHIEVEMENT = {
    CHOOSE_VIEW: 1,
    ACHIEVEMENTS: 2,
    DETAILS: 3,
  };

  activeTab: TabTopMicroLeague;
  activeTemplate: number;
  isLoading = false;
  isDor: boolean;
  branchName?: string;
  managerPlayerId?: number;

  ngOnInit() {
    this.managerPlayerId = this.data?.playerId;
    this.setVisible();
    this.branchName = this.data.branchName;
  }

  setVisible() {
    this.isDor = this.playerService.player.title_id.id >= PLAYER_TITLES.DOR.id;
    if (this.isDor) {
      const tab = this.tabs.find(t => t.index === CHALLENGE_NAME.ACHIEVEMENTS);
      tab.visible = false;
    }
    this.changeTab({ tab: this.tabs[0] });
  }

  changeTab(dataTab: ChangeTabMicroLeague) {
    this.activeTab = dataTab.tab;
    this.activeTemplate = dataTab.tab.index;
    this.isLoading = true;

    switch (this.activeTab.index) {
      case CHALLENGE_NAME.MICROLEAGUE:
        this.clearData(this.achievement);
        this.getLeague(this.managerPlayerId);
        break;

      case CHALLENGE_NAME.ACHIEVEMENTS:
        this.clearData(this.microLeague);
        this.getVisibility(this.managerPlayerId);
        break;
    }
  }

  getVisibility(playerId?: number) {
    this.apiMicroLeagueService.getMicroLeagueAchievementsVisibility(playerId).subscribe(resp => {
      this.achievement.visibility = resp;
      this.achievement.isChooseViewEnable = this.achievement.visibility.is_individual && this.achievement.visibility.is_branched;

      if (this.achievement.isChooseViewEnable) {
        this.achievement.activeTemplate = this.ACHIEVEMENT.CHOOSE_VIEW;
      } else {
        this.achievement.activeTemplate = this.ACHIEVEMENT.ACHIEVEMENTS;
        this.getAchievements(undefined, playerId);
      }
    });
  }

  getLeague(playerId?: number) {
    this.isLoading = true;

    this.apiMicroLeagueService.getPlayerMicroLeague(playerId).subscribe(resp => {
      const closed = resp.filter(m => m.is_closed);
      this.microLeague.closedDisableBtn = closed.length == 0;
      this.microLeague.list.forEach(m => {
        if (m.prizes?.product?.length) {
          m.prizes.product.sort((a, b) => a.product_id - b.product_id);
        }
        if (m.prizes?.currency?.length) {
          m.prizes.currency.sort((a, b) => a.currency_id - b.currency_id);
        }
        if (m.final_prizes?.product?.length) {
          m.final_prizes.product.sort((a, b) => a.product_id - b.product_id);
        }
        if (m.final_prizes?.currency?.length) {
          m.final_prizes.currency.sort((a, b) => a.currency_id - b.currency_id);
        }
      });
      this.microLeague.list = resp.filter(m => m.is_closed === this.microLeague.isClosedViewActive);
      this.isLoading = false;
    });
  }

  showLeagueDetails(event, playerId?: number) {
    this.isLoading = true;
    this.microLeague.isDetailsViewActive = true;

    this.apiMicroLeagueService.getMicroLeagueDetails(event, playerId).subscribe((resp: PlayerMicroLeagueDetails) => {
      const details = R.clone(resp);
      this.microLeague.details = this.setValues(details);
      this.microLeague.details.items.forEach(m => {
        if (m.prizes?.product?.length) {
          m.prizes.product.sort((a, b) => a.product_id - b.product_id);
        }
        if (m.prizes?.currency?.length) {
          m.prizes.currency.sort((a, b) => a.currency_id - b.currency_id);
        }
        if (m.final_prizes?.product?.length) {
          m.final_prizes.product.sort((a, b) => a.product_id - b.product_id);
        }
        if (m.final_prizes?.currency?.length) {
          m.final_prizes.currency.sort((a, b) => a.currency_id - b.currency_id);
        }
      });
      this.microLeague.detailsSourceData = this.microLeague.details.last_calculated_at;
      this.microLeague.isCleared = this.microLeague.details.is_closed;
      this.isLoading = false;
    });
  }

  openMaxPrizesDialog() {
    this.dialogService.open(MicroLeagueMaxPrizesComponent, {
      data: this.microLeague?.details?.available_prizes,
    });
  }

  setValues(details) {
    details.items.forEach(i => {
      i.value1 = i.values[0];
      i.values.shift();
      switch (i.values.length) {
        case 1:
          i.tooltip = i.values[0];
          break;
        case 2:
          i.tooltip = i.values[0] + " / " + i.values[1];
          break;
        default:
          i.tooltip = null;
          break;
      }
    });

    return details;
  }

  chooseAchievement(type: number, playerId?: number) {
    this.achievement.activeTemplate = this.ACHIEVEMENT.ACHIEVEMENTS;
    this.getAchievements(type, playerId);
  }

  getAchievements(type?: number, playerId?: number) {
    this.isLoading = true;

    this.apiMicroLeagueService.getMicroLeagueAchievements(playerId).subscribe(resp => {
      this.achievement.listSourceData = resp.source_data_date;
      this.achievement.list = resp.achievements;
      if (type) {
        this.achievement.list.filter(a => a.level_type === type);
      }
      this.achievement.list.forEach(a => {
        a.flipped = false;
        a.sumPrizes = this.sumPrizes(a);
        a.polygons = this.getThresholds(a);
        a.isProgressGreen = a.completions.some(c => c.is_player_completed);
      });

      this.isLoading = false;
    });
  }

  getThresholds(a) {
    return Array.from(Array(a.threshold?.limit).keys());
  }

  sumPrizes(achievement) {
    let sum = 0;

    if (achievement.is_achievable) {
      achievement.completions?.forEach(c => {
        if (c.is_player_completed) {
          c.prizes.currency?.forEach(p => {
            sum += p?.amount;
          });
        }
      });
    }

    return {
      currency_id: 9,
      amount: sum,
    };
  }

  showAchievementDetails(achievementId: number, playerId?: number) {
    this.isLoading = true;

    this.apiMicroLeagueService.getPlayerMicroLeagueAchievement(achievementId, playerId).subscribe(resp => {
      this.achievement.details = resp;
      this.achievement.activeTemplate = this.ACHIEVEMENT.DETAILS;
      this.isLoading = false;
    });
  }

  showMicroLeaguesClosed(playerId?: number) {
    this.microLeague.isClosedViewActive = true;
    this.getLeague(playerId);
  }

  back() {
    switch (this.activeTab.index) {
      case CHALLENGE_NAME.MICROLEAGUE:
        if (this.microLeague.isClosedViewActive && !this.microLeague.isDetailsViewActive) {
          this.clearData(this.microLeague);
          this.getLeague(this.managerPlayerId);
        } else if (this.microLeague.isDetailsViewActive) {
          this.clearData(this.microLeague, true);
        } else {
          this.matDialogRef.close();
        }
        break;

      case CHALLENGE_NAME.ACHIEVEMENTS:
        switch (this.achievement.activeTemplate) {
          case this.ACHIEVEMENT.DETAILS:
            this.clearData(this.achievement, true);
            this.achievement.activeTemplate = this.ACHIEVEMENT.ACHIEVEMENTS;
            break;

          case this.ACHIEVEMENT.ACHIEVEMENTS:
            this.clearData(this.achievement);

            if (this.achievement.isChooseViewEnable) {
              this.achievement.activeTemplate = this.ACHIEVEMENT.CHOOSE_VIEW;
            } else {
              this.matDialogRef.close();
            }
            break;

          case this.ACHIEVEMENT.CHOOSE_VIEW:
            this.matDialogRef.close();
            break;
        }
        break;

      default:
        this.matDialogRef.close();
        break;
    }
  }

  clearData(obj, closeDetails?: boolean) {
    Object.keys(obj).forEach(key => {
      if (
        key == "visibility" ||
        key == "activeTemplate" ||
        key == "isChooseViewEnable" ||
        (closeDetails && (key == "list" || key == "closedDisableBtn" || key == "isClosedViewActive"))
      ) {
        return;
      }
      if (typeof obj[key] == "boolean") {
        obj[key] = false;
      } else if (Array.isArray(obj[key])) {
        obj[key] = [];
      } else {
        obj[key] = null;
      }
    });
  }

  clearNotification() {
    this.clearNotificationService.clearNotification(["import_calculate_microleague"], this?.managerPlayerId);
  }

  ngOnDestroy() {
    this.clearNotification();
  }
}
