<div class="building-thumbnail-container" *ngIf="building">
  <img
    [src]="building.icon + '_small.png' | asset:'buildings'"
    ngbTooltip="{{ building.name + ' - poziom ' + building.level }}"
    placement="bottom"
    tooltipClass="delayed"
    container="body"
  />

  <div
    class="title"
    *ngIf="showTitle"
  >
    {{ building.name + ' (' + building.level + ')' }}
  </div>
</div>
