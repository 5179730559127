import { Component, Input, OnInit } from "@angular/core";
import * as R from "ramda";

import { AbstractInjectBaseComponent } from "../../../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../../../core/decorators/ow-inject.decorator";
import { OwDate } from "../../../../../../../../../../core/utility/ow-date.class";
import { PlayerService } from "../../../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../../../shared/providers/dialog.service";
import { MyIdObject, RankLevel } from "../../../../../../../../interfaces/custom/rank.interface";
import { RankBusinessDetails } from "../../../../../../../../interfaces/custom/rank-details.interface";
import { STOCK_VIEW } from "../../../../../../../shared-ui/mobile/consts/stock-view.const";
import { ApiBusinessRankService } from "../../../../../../api/service/api-business-rank.service";
import { LEVEL_FILTER_KEY } from "../../../../../../const/custom/level-filter-key.const";
import { RANK_VALUE_TYPE } from "../../../../../../const/custom/rank-value-type.const";
import { RankTask } from "../../../../../../interfaces/custom/rank-task.interface";
import { SchemaFilterTitleDetails } from "../../../../../../interfaces/custom/rank-visibility-schema.interface";
import { checkSchemaButtons, getSchemaByGroupAndTitleId } from "../../../../helpers/rank-schema.helper";

@Component({
  selector: "app-rank-business-base-cc",
  templateUrl: "./rank-business-base-cc.component.html",
})
export class RankBusinessBaseCcComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiBusinessRankService) apiBusinessRankService: ApiBusinessRankService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @Input() rank: RankTask;
  @Input() owDate: OwDate;
  @Input() levels: RankLevel[];
  @Input() group: string;
  @Input() myIdObject: MyIdObject;
  @Input() filterKey: string;
  STOCK_VIEW = STOCK_VIEW;
  activeLevel: RankLevel;
  loading: boolean;
  rankDetails;
  schema: SchemaFilterTitleDetails;
  tooltip: string;
  RANK_VALUE_TYPE = RANK_VALUE_TYPE;
  otherPrizeKey: string;
  options: any[];
  activeOption: {
    rankId: number;
    label: string;
    groupApi: number | null;
  } | null;
  isLoading;
  availablePrizesList = []; // used to segregate prizes in columns

  ngOnInit() {
    this.isLoading = true;

    this.setSchema();

    if (this.filterKey) {
      this.activeLevel = this.levels.find(l => l.filterKey == this.filterKey);
    } else {
      this.setDefaultActiveLevel();
    }

    this.getRank();
  }

  setSchema() {
    this.schema = getSchemaByGroupAndTitleId(this.group, this.myIdObject);
  }

  setDefaultActiveLevel() {
    this.activeLevel = this.levels.find(level => level.filterKey === LEVEL_FILTER_KEY.COUNTRY);
  }

  setLevels(filterKey: string[]) {
    this.levels.forEach(level => {
      if (level.filterKey === LEVEL_FILTER_KEY.COUNTRY) {
        level.show = true;
      } else {
        level.show = R.includes(level.filterKey, filterKey);
      }
    });
  }

  changeLevel(level: RankLevel) {
    this.activeLevel = level;
    this.getRank();
  }

  getRank() {
    this.otherPrizeKey = null;

    const requestData = {
      id: this.rank.id,
      level: this.activeLevel.filterKey,
      group: null,
    };

    if (this.activeOption) {
      requestData.id = this.activeOption.rankId;
      requestData.group = this.activeOption.groupApi;
    }

    this.apiBusinessRankService.rankCc(requestData).subscribe(
      resp => {
        this.rankDetails = resp;
        this.owDate = new OwDate({
          year: resp.year,
          month: resp.month,
        });

        for (let item of this.rankDetails.items) {
          for (let award of item.prizes) {
            const el = this.availablePrizesList.find(x => x.currency_id === award.currency_id);
            if (!el) {
              this.availablePrizesList.push({...award, amount: 0});
            }
          }
        }

        this.rankDetails.items = this.rankDetails.items.map(rank => {
          this.tooltipFormated(rank);

          // sort awards per column
          const currPrizeClone = JSON.parse(JSON.stringify(rank.prizes));
          rank.prizes = [];
          // 1 is first rank, 0 is curr user
          for (let prize of this.availablePrizesList) {
            const col = currPrizeClone.find(x => x.currency_id === prize.currency_id);

            if (col) {
              rank.prizes.push({...col, disabled: false});
            } else {
              rank.prizes.push({disabled: true})
            }
          }

          return rank;
        });

        this.setLevels(this.rankDetails.available_filters);
        this.updateBySchema();
      },
      errResp => {
        this.isLoading = false;
        this.rankDetails = null;
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  tooltipFormated(rank: RankBusinessDetails) {
    if (rank.mins.tooltip_text !== null) {
      rank.mins.tooltip_text = rank.mins.tooltip_text.replace(/<br[\/]*>/g, "\n");
    }
  }

  updateBySchema() {
    checkSchemaButtons(this.schema, this.activeLevel, this.myIdObject, this.rankDetails.items);

    this.isLoading = false;
  }
}
