import { EventDialog } from '../../../../../../../core/interfaces/event-dialog.interface';
import { EVENT_DIALOGS_NAMES_EVENT_SHOP } from './event-names.const';
import { EventShopList } from '../../../basic/custom/dialogs/event-shop-list/event-shop-list';
import { EventShopDetails } from '../../../basic/custom/dialogs/event-shop-details/event-shop-details';
import { EventShopProductDetails } from '../../../basic/custom/dialogs/event-shop-product-details/event-shop-product-details';
import { EventShopAlertConfirm } from '../../../basic/custom/dialogs/event-shop-alert-confirm/event-shop-alert-confirm.component';

export const EVENT_DIALOGS_EVENT_SHOP_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_EVENT_SHOP.LIST]: EventShopList,
  [EVENT_DIALOGS_NAMES_EVENT_SHOP.DETAILS]: EventShopDetails,
  [EVENT_DIALOGS_NAMES_EVENT_SHOP.PRODUCT_DETAILS]: EventShopProductDetails,
  [EVENT_DIALOGS_NAMES_EVENT_SHOP.ALERT_CONFIRM]: EventShopAlertConfirm
};
