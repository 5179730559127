<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <ng-container *ngIf="buildingDetails">
    <div class="top-info">
      <div
        class="experience"
        *ngIf="buildingDetails.exp_points > 0"
        [matTooltip]="'Punkty doświadczenia'"
        [matTooltipShowDelay]="400"
        [matTooltipPosition]="'above'"
      >
        <div class="inner-wrapper">
          <p>+{{ buildingDetails.exp_points }}</p>
        </div>
        <img [src]="'exp.png' | asset : 'ui'" />
      </div>

      <!-- GROUP BUILDING -->
      <div
        *ngIf="buildingDetails.building_id < 10001 || buildingDetails.building_id > 10060"
        class="group-count"
        [matTooltip]="'Maksymalna ilość budynków'"
        [matTooltipShowDelay]="400"
        [matTooltipPosition]="'above'"
      >
        <m-ui-group-count [building]="buildingDetails" #groupCount></m-ui-group-count>
      </div>
    </div>

    <div class="bookmarks">
      <div class="overlap active green">
        <div class="bookmark green">
          <span>{{ buildingDetails.name }}</span>
        </div>
      </div>
    </div>

    <div class="top loading-opacity">
      <div class="icon-container">
        <!-- BUILDING ICON -->
        <img [src]="buildingDetails | buildingIcon:false">
      </div>

      <div class="right-column">
        <div
          class="description"
          [innerHTML]="buildingDetails.group_description"
        ></div>

        <div class="elements prizes-build" [class.not-show]="hideSubTitle">
          <div class="min-title" *ngIf="hideSubTitle && notOutcomeParameters?.length">Otrzymasz:</div>

          <div class="box" *ngIf="buildingDetails.build_currency_prizes.length || buildingDetails.build_product_prizes.length || notOutcomeParameters?.length">
            <app-building-return
              *ngIf="buildingDetails.build_currency_prizes.length || buildingDetails.build_product_prizes.length"
              [returnCurrencies]="buildingDetails.build_currency_prizes"
              [returnProducts]="buildingDetails.build_product_prizes"
              [hideSubTitle]="hideSubTitle"
              [title]="'Produkty'"
            ></app-building-return>

            <app-unlocked-parameters
              *ngIf="notOutcomeParameters?.length"
              [parameters]="notOutcomeParameters"
              [hideSubTitle]="hideSubTitle"
            ></app-unlocked-parameters>
          </div>

          <app-unlocked-productions
            *ngIf="production"
            [production]="production"
          ></app-unlocked-productions>

          <app-unlocked-automatic-production
            *ngIf="automaticCurrency || automaticProduct"
            [groupType]="buildingDetails.group_type"
            [automaticCurrency]="automaticCurrency"
            [automaticProduct]="automaticProduct"
          ></app-unlocked-automatic-production>

          <app-unlocked-storage-value
            *ngIf="storageValue"
            [storageValue]="storageValue"
          ></app-unlocked-storage-value>
        </div>
      </div>
    </div>
    <div class="bottom loading-opacity">
      <m-ui-separator title="Wymagania"></m-ui-separator>

      <!-- REQUIREMENTS -->
      <app-building-requirements
        *ngIf="buildingDetails && outcomeParameters"
        [building]="buildingDetails"
        [products]="buildingDetails.build_product_prices"
        [currencies]="buildingDetails.build_currency_prices"
        [playerLevel]="buildingDetails.required_level > 1 ? buildingDetails.required_level : null"
        [requiredBuilding]="buildingDetails.required_building"
        [groupLimit]="groupCount"
        [parametersOutcome]="outcomeParameters"
        (requirementsStatus)="setRequirementsStatus($event)"
      ></app-building-requirements>
    </div>

    <div
      class="buttons"
      *ngIf="buildingDetails && requirementsStatus"
    >
      <!-- BUILD -->
      <div
        [ngbTooltip]="requirementsStatus['textErrors'].length && tooltipRequirementsTpl"
        placement="bottom"
        container="body"
      >
        <button
          class="c-base c-secondary"
          [disabled]="!requirementsStatus.valid"
          (click)="build()"
        >
            <span class="text-normal">
          {{ buildingDetails.upgrade_button_name || 'Buduj' }}&nbsp;<app-core-building-time [building]="buildingDetails"></app-core-building-time>
            </span>
        </button>
      </div>

      <!-- FAST ACTION -->
      <div
        [ngbTooltip]="requirementsStatus['textErrors'].length && tooltipRequirementsTpl"
        placement="bottom"
        container="body"
      >
        <button
          *ngIf="buildingDetails.fast_build_currency_prices.length || buildingDetails.fast_build_product_prices.length"
          [matTooltip]="'Akcja błyskawiczna'"
          class="c-base c-primary"
          (click)="openFastBuildConfirm()"
          [disabled]="!requirementsStatus.valid"
        >
          Błyskawiczna akcja
        </button>
      </div>

      <!-- TOOLTIP REQUIREMENTS TPL -->
      <ng-template #tooltipRequirementsTpl>
        <div *ngFor="let textError of requirementsStatus['textErrors']">
          {{ textError }}
        </div>
      </ng-template>
    </div>
  </ng-container>
</div>
