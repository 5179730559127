import { CB_EDITABLE } from "../catch-bananas.constans";
import { AnnieSprite, MonkeySprite, TreeSprite } from "./sprites";
import { BoxGuiSprite } from "./sprites/box-gui.sprite";

export class CatchBananas {
  monkeys: MonkeySprite[] = [];
  trees: TreeSprite[] = [];
  boxes: BoxGuiSprite[] = [];
  time = 0;
  annie: AnnieSprite = null;
  score1 = 0;
  score2 = 0;
  score3 = 0;
  score = 0;
  chestsCatched = 0;
  velocity = CB_EDITABLE.CB_BANANA_VELOCITY;
  timeLeft = 150;
  timeLeftText: Phaser.GameObjects.Text = null;
  bananasText: Phaser.GameObjects.Text;
  rottenBananasText: Phaser.GameObjects.Text;
  platformBanana: Phaser.Physics.Arcade.Sprite;
  platformAnnie: Phaser.Physics.Arcade.Sprite;
}
