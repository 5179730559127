<div class="dor-background" *ngIf="!player.title_id.playable">
  <div class="logo"></div>
</div>

<app-san-hud-player [player]="player"></app-san-hud-player>

<div class="hud-menu-positioner" [class.pointer-events-active]="!playerService.isActiveMe">
  <app-custom-hud-menu [data]="{player: player}"></app-custom-hud-menu>
  <app-hud-player-preview [player]="player"></app-hud-player-preview>
</div>

<app-san-hud-resources
  *ngIf="
    isCC
    ? true
    : (
        player.title_id.id < TITLES.PARTNER
        && (
          playerService.isActiveMe
          || playerService.firstTimeMePlayerSnapshot.title_id.id >= TITLES.DOR
          || playerService.firstTimeMePlayerSnapshot.branch_id === player.branch_id
        )
        && (
          playerService.firstTimeMePlayerSnapshot.region_id
          ? playerService.firstTimeMePlayerSnapshot.region_id === player.region_id
          : true
        )
        && (
          playerService.firstTimeMePlayerSnapshot['partner_id']
          ? playerService.firstTimeMePlayerSnapshot['partner_id'] === player['partner_id']
          : true
        )
      )
  "
></app-san-hud-resources>

<app-san-hud-parameters></app-san-hud-parameters>

<app-san-business
  *ngIf="
  !isCC
  && (
     (
      playerService.isActiveMe
      || playerService.firstTimeMePlayerSnapshot.title_id.id >= TITLES.DOR
      || playerService.firstTimeMePlayerSnapshot.branch_id === player.branch_id
    )
    && (
      playerService.firstTimeMePlayerSnapshot.region_id
      ? playerService.firstTimeMePlayerSnapshot.region_id === player.region_id
      : true
    )
    && (
      playerService.firstTimeMePlayerSnapshot['partner_id']
      ? playerService.firstTimeMePlayerSnapshot['partner_id'] === player['partner_id']
      : true
    )
  )"
></app-san-business>
<app-san-business-cc *ngIf="isCC"></app-san-business-cc>

<!-- HUD OFFSET TIME -->
<app-hud-offset-time [player]="player"></app-hud-offset-time>
