<div class="global-dialog ow-dialog wf-piece-details-container">

  <m-ui-close-button [preventDefault]="true" (clicked)="closeActive()"></m-ui-close-button>

  <div class="content">
    <ng-container *ngIf="data.currency != null">
      <img [src]="data.currency.key + '.png' | asset: 'products/big'"/>
      <p [innerHTML]="data.currency.description"></p>
    </ng-container>
    <ng-container *ngIf="data.product != null">
      <img [src]="data.product.icon + '.png' | asset: 'products/big'" />
      <p [innerHTML]="data.product.description"></p>
    </ng-container>

  </div>
</div>
