import { Directive, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[showMyPositionRank]",
})
export class ShowMyPositionRankDirective {
  /**
   * Perfect scrollbar reference
   */
  @Input() private perfectScrollbarRef: any;

  /**
   * My position from ranks api (index 0-xxx)
   */
  @Input() private myPosition: number;

  constructor() {}

  @HostListener("click", ["$event"]) onClick() {
    if (this.perfectScrollbarRef && this.perfectScrollbarRef.directiveRef.elementRef && this.myPosition !== null) {
      const tableRanks = this.perfectScrollbarRef.directiveRef.elementRef.nativeElement;
      const myPositionTr = tableRanks.querySelector(`[data-id="${this.myPosition}"]`);

      if (myPositionTr) {
        this.scrollTo(null, myPositionTr["offsetTop"]);
      }
    }
  }

  scrollTo(x, y, time = 300) {
    this.perfectScrollbarRef.directiveRef.scrollTo(x, y, time);
  }
}
