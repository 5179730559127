<ng-container *ngIf="!isLoading && achievement">
  <div class="container">
    <div class="icon-league">
      <img [src]="achievement.icon_active + '.png' | asset: 'ui'" alt=""/>
    </div>

    <div [innerHTML]="achievement.name" class="title"></div>

    <m-ui-separator [title]="'Osiągnięcie zdobyte przez:'"></m-ui-separator>

    <div *ngIf="achievement?.list.length > 0" class="list">
      <ow-perfect-scrollbar [scrollGradient]="achievement.list">
        <table>
          <tbody>
          <tr *ngFor="let item of achievement.list">
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.complete_date | date: 'dd.MM.yyyy' }}
            </td>
          </tr>
          </tbody>
        </table>
      </ow-perfect-scrollbar>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isLoading && !achievement">
  <div class="no-item">Brak szczegółów osiągnięcia</div>
</ng-container>
