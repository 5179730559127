import { LEVEL_FILTER_KEY } from '../../const/custom/level-filter-key.const';

export const SCHEMA_FILTER_DEFINITIONS: SchemaFilter = {
  employee: {
    '1-2': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY, LEVEL_FILTER_KEY.REGION, LEVEL_FILTER_KEY.BRANCH],
      country: {
        buttonMyPosition: 'player_id',
        buttonMyWorkers: 'branch_id',
      },
      region: {
        buttonMyPosition: 'player_id',
        buttonMyWorkers: 'branch_id',
      },
      branch: {
        buttonMyPosition: 'player_id',
      },
      league_group: {
        buttonMyPosition: 'player_id',
        buttonMyWorkers: 'branch_id',
      },
      league_group_region: {
        buttonMyPosition: 'player_id',
        buttonMyWorkers: 'branch_id',
      },
    },
    '3': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY, LEVEL_FILTER_KEY.REGION, LEVEL_FILTER_KEY.BRANCH],
      country: {
        buttonMyWorkers: 'branch_id',
      },
      region: {
        buttonMyWorkers: 'branch_id',
      },
      branch: {},
      league_group: {
        buttonMyWorkers: 'branch_id',
      },
      league_group_region: {
        buttonMyWorkers: 'branch_id',
      },
    },
    '4-5': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY, LEVEL_FILTER_KEY.REGION],
      country: {},
      region: {},
    },
    '6': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY],
      country: {},
    },
    '18-21': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY, LEVEL_FILTER_KEY.REGION, LEVEL_FILTER_KEY.BRANCH],
      country: {
        buttonMyPosition: 'player_id',
      },
      region: {
        buttonMyPosition: 'player_id',
      },
      branch: {
        buttonMyPosition: 'player_id',
      }
    },
  },
  managers: {
    '1-2': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY, LEVEL_FILTER_KEY.REGION],
      country: {},
      region: {},
    },
    '3': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY, LEVEL_FILTER_KEY.REGION],
      country: {
        buttonMyPosition: 'player_id',
      },
      region: {
        buttonMyPosition: 'player_id',
      },
      league_group: {
        buttonMyPosition: 'player_id',
      },
      league_group_region: {
        buttonMyPosition: 'player_id',
      },
    },
    '4-5': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY, LEVEL_FILTER_KEY.REGION],
      country: {},
      region: {},
    },
    '6': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY],
      country: {},
    },
    '18-21': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY, LEVEL_FILTER_KEY.REGION],
      country: {},
      region: {},
    },
  },
  branch: {
    '1-2': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY, LEVEL_FILTER_KEY.REGION],
      country: {
        buttonMyPosition: 'branch_id',
      },
      region: {
        buttonMyPosition: 'branch_id',
      },
      league_group: {
        buttonMyPosition: 'branch_id',
      },
      league_group_region: {
        buttonMyPosition: 'branch_id',
      },
    },
    '3': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY, LEVEL_FILTER_KEY.REGION],
      country: {
        buttonMyPosition: 'branch_id',
      },
      region: {
        buttonMyPosition: 'branch_id',
      },
      league_group: {
        buttonMyPosition: 'branch_id',
      },
      league_group_region: {
        buttonMyPosition: 'branch_id',
      },
    },
    '4-5': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY, LEVEL_FILTER_KEY.REGION],
      country: {},
      region: {},
    },
    '6': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY],
      country: {},
    },
    '18-21': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY, LEVEL_FILTER_KEY.REGION],
      country: {
        buttonMyPosition: 'branch_id',
      },
      region: {
        buttonMyPosition: 'branch_id',
      },
    },
  },
  region: {
    '1-2': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY],
      country: {
        buttonMyPosition: 'region_id',
      },
      league_group: {
        buttonMyPosition: 'region_id',
      },
    },
    '3': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY],
      country: {
        buttonMyPosition: 'region_id',
      },
      league_group: {
        buttonMyPosition: 'region_id',
      },
    },
    '4-5': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY],
      country: {
        buttonMyPosition: 'region_id',
      },
      league_group: {
        buttonMyPosition: 'region_id',
      },
    },
    '6': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY],
      country: {},
    },
    '18-21': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY],
      country: {
        buttonMyPosition: 'region_id',
      },
    },
  },
  'branch_managers': {
    '1-2': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY, LEVEL_FILTER_KEY.REGION],
      country: {
        buttonMyPosition: 'branch_id',
      },
      region: {
        buttonMyPosition: 'branch_id',
      },
    },
    '3': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY, LEVEL_FILTER_KEY.REGION],
      country: {
        buttonMyPosition: 'branch_id',
      },
      region: {
        buttonMyPosition: 'branch_id',
      },
    },
    '4-5': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY, LEVEL_FILTER_KEY.REGION],
      country: {},
      region: {},
    },
    '6': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY],
      country: {},
    },
    '18-21': {
      tabs: [LEVEL_FILTER_KEY.COUNTRY, LEVEL_FILTER_KEY.REGION],
      country: {
        buttonMyPosition: 'branch_id',
      },
      region: {
        buttonMyPosition: 'branch_id',
      },
    },
  },
};

export interface SchemaFilter {
  employee: SchemaFilterTitle;
  branch: SchemaFilterTitle;
  region: SchemaFilterTitle;
  managers: SchemaFilterTitle;
  'branch_managers': SchemaFilterTitle;
}

interface SchemaFilterTitle {
  '1-2': SchemaFilterTitleDetails;
  3: SchemaFilterTitleDetails;
  '4-5': SchemaFilterTitleDetails;
  6: SchemaFilterTitleDetails;
  '18-21': SchemaFilterTitleDetails;
}

export interface SchemaFilterTitleDetails {
  tabs: string[];
  country?: SchemaFilterOptions;
  region?: SchemaFilterOptions;
  branch?: SchemaFilterOptions;
  league_group?: SchemaFilterOptions;
  league_group_region?: SchemaFilterOptions;

  currentButtonMyWorkers?: {
    id: number;
    filterKey: string;
    isActive: boolean;
  };
  currentMyPositionId?: number;
}

interface SchemaFilterOptions {
  buttonMyPosition?: string;
  buttonMyWorkers?: string;
}
