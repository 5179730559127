import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SharedModule } from "../../../../../shared/shared.module";
import { BuildingsModule } from "../../../buildings/module/core/buildings.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { TrackStartRequirementsComponent } from "../../base/custom/components/track-start-requirements/track-start-requirements.component";
import { TrackingBeginComponent } from "../../base/custom/dialogs/tracking-begin/tracking-begin.component";
import { TrackingBoardComponent } from "../../base/custom/dialogs/tracking-board/tracking-board.component";
import { TrackingContinueComponent } from "../../base/custom/dialogs/tracking-continue/tracking-continue.component";
import { TrackingStartComponent } from "../../base/custom/dialogs/tracking-start/tracking-start.component";

@NgModule({
  declarations: [
    TrackingBeginComponent,
    TrackingContinueComponent,
    TrackingStartComponent,
    TrackingBoardComponent,
    TrackStartRequirementsComponent,
  ],
  imports: [CommonModule, SharedModule, SharedUiMobileModule, BuildingsModule],
})
export class TrackingModule {}
