<div class="ow-dialog">
  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="close()"
  ></m-ui-close-button>

  <!-- TITLE -->
  <div class="title">
    Maksymalne nagrody do zdobycia za premiowane miejsca
  </div>
  <div class="prizes-wrapper">
    <ng-container *ngFor="let item of data; let i = index" >
      <div *ngIf="item?.prizes?.product || item?.prizes?.currency" class="prizes">
        <span>{{i+1}} miejsce</span>
        <m-ui-currency  *ngFor="let currencyItem of item?.prizes?.currency" [stockView]="STOCK_VIEW.J" [item]="currencyItem" [lack]="false"></m-ui-currency>
        <m-ui-product *ngFor="let prizeItem of item?.prizes?.product" [stockView]="STOCK_VIEW.J" [item]="prizeItem" [lack]="false"></m-ui-product>
      </div>
    </ng-container>
  </div>

</div>
