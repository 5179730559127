import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { OwDate } from "../../../../../../../core/utility/ow-date.class";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { CARD_TEMPLATE } from "../../../../san-business/consts/custom/card-template.const";
import { Task } from "../../../../san-business/interfaces/custom/task.interface";
import { STOCK_VIEW } from "../../../../shared-ui/mobile/consts/stock-view.const";

@Component({
  selector: "app-card-task-details-cc",
  templateUrl: "./card-task-details-cc.component.html",
})
export class CardTaskDetailsCCComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  STOCK_VIEW = STOCK_VIEW;
  temporaryParentTask: Task;
  CARD_TEMPLATE = CARD_TEMPLATE;

  @OwInject(MAT_DIALOG_DATA) data: {
    task: Task;
    tasks: Task[];
    isChooseableView?: boolean;
    currentIndex: number;
    owDate: OwDate;
    position: boolean;
  };

  currentTask: Task;
  currentIndex = 0;
  pagination = {
    allowNext: false,
    allowPrev: false,
  };

  indicatorName = "Wskaźnik techniczny 100%";

  ngOnInit() {
    this.currentTask = this.data.task;
    const indexTask: number = this.data.tasks.indexOf(this.currentTask);

    if (this.data.isChooseableView) {
      this.currentIndex = this.data.currentIndex;
    } else {
      this.currentIndex = indexTask;
    }

    this.changeTask(this.data.tasks[indexTask]);
  }

  checkPagination() {
    this.pagination.allowNext = this.currentIndex < this.data.tasks.length - 1;
    this.pagination.allowPrev = this.currentIndex > 0;
  }

  next() {
    if (this.pagination.allowNext) {
      this.currentIndex++;
      this.changeTask(this.data.tasks[this.currentIndex]);
    }
  }

  prev() {
    if (this.pagination.allowPrev) {
      this.currentIndex--;
      this.changeTask(this.data.tasks[this.currentIndex]);
    }
  }

  changeTask(task: Task) {
    this.currentTask = task;
    this.checkPagination();
  }

  openParentId() {
    this.changeTask(this.currentTask.nextTask);
  }

  changeChildTask(task: Task) {
    this.temporaryParentTask = this.currentTask;
    this.currentTask = task;
  }

  revertChildTask() {
    this.currentTask = this.temporaryParentTask;
    this.temporaryParentTask = null;
  }
}
