import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractQaDetailsComponent } from '../../../../abstract/core/abstract-qa-details.component';

@Component({
  selector: 'app-custom-qa-details',
  templateUrl: './qa-details.component.html',
})
export class QaDetailsComponent extends AbstractQaDetailsComponent implements OnInit, OnDestroy {

  ngOnInit() {
    if (this.data.qa) {
      this.setCurrentQa();
    }
  }

  ngOnDestroy() {
    this.clearInterval();
  }
}
