import { Component, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { forkJoin } from "rxjs";
import { filter, take } from "rxjs/operators";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { ParametersService } from "../../../../../../../../core/providers/parameters.service";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { selectGameBoardTile } from "../../../../../../../../store/game/selectors";
import { BuildingDetailsConfig } from "../../../../../../game-engine/interfaces/building-details-config";
import { PlayerBuilding } from "../../../../../../game-engine/interfaces/player-building.config";
import { AbstractBuildingUpgradeComponent } from "../../../../../buildings/abstract/core/abstract-building-upgrade.component";
import { upgradeDifferenceParameters } from "../../../../../buildings/helpers/core/upgrade.helpers";
import { getNotOutcomeParameters, getOutcomeParameters } from "../../../../../buildings/helpers/custom/outcome-parameters.helper";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { EVENT_DIALOGS_NAMES_ANTYMINER_CUSTOM } from '../../../../consts/custom/event-dialogs/event-names.const';
import { AppState } from "../../../../../../../../store/state";
import { selectParameters } from "../../../../../../../../store/primary/selectors";
import {AntyminerItem, BuildingDataAntyminer, PlayerAntyminerBuildingDetailsExtra} from '../../../../interfaces/antyminer.interface';

@Component({
  selector: "app-antyminer-begin",
  templateUrl: "./antyminer-begin.component.html",
})
export class AntyminerBeginComponent extends AbstractBuildingUpgradeComponent implements OnInit, OnDestroy {
  @OwInject(ParametersService) parametersService: ParametersService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(Store) store: Store<AppState>;

  STOCK_VIEW = STOCK_VIEW;

  notOutcomeParameters: any[];
  outcomeParameters: any[];

  notOutcomeParametersUpgrade;
  outcomeParametersUpgrade;

  notOutcomeParametersDiffParameters;

  outcomeParametersDiffParameters;
  outcomeParametersRequirements;

  boardSize;

  hideSubTitle: boolean;
  playerBuilding: PlayerBuilding;
  antyminerItems: AntyminerItem[] = [];
  buildingDetails: PlayerAntyminerBuildingDetailsExtra;

  ngOnInit() {
    this.subscribeBoardTile();
  }

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameBoardTile, {playerTileId: this.data.playerTileId}),
        filter(state => !!state),
        take(1)
      )
      .subscribe(tile => {
        this.playerBuilding = tile.player_building;
        this.combineBuildDetails();
      });
  }

  combineBuildDetails() {
    if (this.playerBuilding.upgrade_building_id) {
      forkJoin([
        this.buildingsService.getPlayerBuildingDetails(this.playerBuilding.player_building_id),
        this.buildingsService.getBuildingDetails(this.playerBuilding.upgrade_building_id),
        this.store.select(selectParameters).pipe(take(1)),
      ]).subscribe(([buildingDetails, upgradeBuildingDetails, parameters]) => {
        this.buildingDetails = buildingDetails;
        this.upgradeBuildingDetails = upgradeBuildingDetails;
        this.setTrackingDetails();
        this.getUpgradeDifferenceParameters();
      });
    } else {
      this.buildingsService.getPlayerBuildingDetails(this.playerBuilding.player_building_id).subscribe({
        next: buildingDetails => {
          this.buildingDetails = buildingDetails;
          this.upgradeBuildingDetails = null;
          this.setTrackingDetails();
          this.setParameters();
        },
      });
    }
  }

  setTrackingDetails() {
    this.boardSize = this.buildingDetails.antiminer_definition.board.x + "x" + this.buildingDetails.antiminer_definition.board.y;

    this.antyminerItems = this.buildingDetails.antiminer_details.objects;
  }

  getUpgradeDifferenceParameters() {
    this.setParameters();

    this.notOutcomeParametersDiffParameters = upgradeDifferenceParameters(
      this.notOutcomeParameters,
      this.notOutcomeParametersUpgrade,
      true
    );

    this.outcomeParametersDiffParameters = upgradeDifferenceParameters(this.outcomeParameters, this.outcomeParametersUpgrade);
    this.notOutcomeParametersDiffParameters = this.notOutcomeParametersDiffParameters.filter(p => p.parameters.income_diff !== 0);

    this.outcomeParametersRequirements = this.outcomeParametersDiffParameters.map(parameter => {
      return {
        ...parameter.upgrade,
        outcome: parameter.parameters.outcome_diff === 0 ? 0 : parameter.parameters.outcome_diff || parameter.upgrade.outcome,
      };
    });
  }

  setParameters() {
    this.notOutcomeParameters = getNotOutcomeParameters(this.buildingDetails.parameters, true);
    this.outcomeParameters = getOutcomeParameters(this.buildingDetails.parameters);

    if (this.upgradeBuildingDetails) {
      this.notOutcomeParametersUpgrade = getNotOutcomeParameters(this.upgradeBuildingDetails.parameters, true);
      this.outcomeParametersUpgrade = getOutcomeParameters(this.upgradeBuildingDetails.parameters);
    }
  }

  upgrade({fastUpgrade}: { fastUpgrade?: boolean } = {}) {
    this.buildingsService.upgrade(this.playerBuilding.player_building_id, fastUpgrade).subscribe(res => {
      this.dialogService.closeAll();
      this.openTrackingBoard(res);
    });
  }

  openTrackingBoard(upgradeResponse) {
    const buildingAntyminerData: BuildingDataAntyminer = {
      playerTileId: this.data.playerTileId,
      playerBuildingId: upgradeResponse.player_building_id,
      playerBuildingLevel: this.playerBuilding.level,
      boardDetails: {
        board: {
          x: this.buildingDetails.antiminer_definition.board.x,
          y: this.buildingDetails.antiminer_definition.board.y,
        },
        buildingDetails: {
          ...upgradeResponse,
          antiminer_progress: {
            max_turns: this.buildingDetails.antiminer_details.max_turns,
            moves: 0
          }
        },
        background: this.buildingDetails.antiminer_definition.background,
      },
    };

    setTimeout(() => {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_ANTYMINER_CUSTOM.ANTYMINER_BOARD,
        config: {
          data: buildingAntyminerData,
        },
      });
    });
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
