<div
  class="global-dialog ow-dialog c-window-short"
  [style.min-width]="300"
  [style.max-height]="400"
  [style.min-height]="200"
>
  <!-- TITLE -->
  <p class="title">
    Podaj numer telefonu
  </p>

  <!-- DESCRIPTION -->
  <p class="description">
    Zakup tego produktu wymaga weryfikacji numerem telefonu.<br/>
    Podaj numer telefonu, na który może zadzwonić kurier w celu umówienia terminu odbioru paczki lub pracownik
    backoffice.
    <br>
    Podanie numeru jest równoznaczne z wyrażeniem zgody na kontakt telefoniczny.
  </p>

  <form [formGroup]="form" (submit)="submit()">
    <div class="control">
      <div class="default-field">
        <input
          placeholder="Numer telefonu"
          class="control-input no-ow-input-directive"
          formControlName="phoneNumber"
          type="text"
        />
      </div>
    </div>

    <!-- CONFIRM -->
    <div class="buttons">
      <!-- CANCEL -->
      <button
        class="c-base c-secondary"
        type="button"
        (click)="matDialogRef.close()"
      >
        {{ 'alert.close' | myTranslate }}
      </button>

      <!-- CONFIRM -->
      <button
        class="c-base c-primary"
        type="submit"
        [disabled]="form.invalid"
      >
        {{ 'alert.ok' | myTranslate }}
      </button>
    </div>
  </form>
</div>
