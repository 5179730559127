<div class="global-dialog ow-dialog c-window-long">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON  -->
  <m-ui-back-button></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Kontrakt</span>
      </div>
    </div>

    <div *ngIf="playerService.player['microleague_contract_last_calculated_at']" class="source-data-date-tab">
      Dane aktualne na: {{ playerService.player['microleague_contract_last_calculated_at'] | date:'dd-MM-y' }}
    </div>
  </div>

  <ng-container *ngIf="!isLoading && contracts.length">
    <div class="contract-list-wrapper">
      <div class="header">
        <span class="contract-label date">Data</span>
        <span class="contract-label contract">Sprzedaż kontraktowa</span>
        <span class="contract-label expected-value">Wartość oczekiwana</span>
        <span class="contract-label realization">Wykonanie</span>
      </div>
      <div class="body">
        <ow-perfect-scrollbar #perfectScrollbar scrollGradient [isRefreshScrollTop]="false">
          <div class="contract-wrapper">
            <ng-container *ngFor="let contractItems of contracts">
              <div class="contract-item" [class.package-border]="contractItems.package_id" [class.item-centered]="contractItems.type === 'legacy'">
                <div *ngIf="contractItems.package_id" class="contract-package-description"><span>{{!contractItems?.package_resolved ? 'WYBÓR KONTRAKTÓW' : 'WYBRANY KONTRAKT'}}</span><i class="fas fa-info-square" (click)="openContractPackageInfo(contractItems?.package_resolved)"></i></div>
                <div *ngFor="let contractItem of contractItems.contracts" [class.is-chosen]="contractItem?.is_chosen" [class.package]="contractItems?.type === 'package'" [class.is-met]="(contractItem?.is_closed || (contractItem?.is_done && !contractItem?.is_closed)) || (contractItems.package_resolved && !contractItem?.is_chosen)" class="contract-content-wrapper">
                  <div class="info-wrapper">
                    <div class="info">
                      <i class="fas fa-info-square" (click)="openInfo(contractItem.description)"></i>
                    </div>
                    <div class="checkbox">
                      <div class="squares">
                        <i
                          [ngClass]="contractItem.is_done ? 'far fa-check-square' : 'far fa-minus-square'"
                          class="contour"
                        ></i>
                        <i
                          [ngClass]="contractItem.is_done ? 'fas fa-check-square green' : 'fas fa-minus-square red'"
                          class="color"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div class="date">{{ contractItem.date_from | date: 'dd.MM.yyyy' }} - {{ contractItem.date_to | date: 'dd.MM.yyyy' }}</div>
                  <div class="name">
                    <div class="name-wrapper" *ngIf="!(contractItem?.is_combo)">{{ contractItem.name }}</div>
                    <ng-container *ngIf="contractItem?.is_combo">
                      <div class="condition-wrapper" *ngFor="let conditions of contractItem.conditions">
                        <div class="condition" [class.done]="conditions.is_done"></div><span> {{conditions.name}} </span>
                      </div>
                    </ng-container>
                    <div
                      class="accept-rules"
                      *ngIf="contractItem?.is_confirmed !== null && (contractItems?.type !== 'package' || (contractItems?.type === 'package' && contractItems?.package_resolved && contractItem.is_chosen) || contractItems?.type === 'package' && !contractItems?.package_resolved)"
                      [class]="{'box-active': contractItem?.is_confirmed, 'cursor-default': checkDateIsBeforeNow(contractItem?.rules_confirmation_deadline) || contractItem?.is_confirmed || contractItem?.is_chosen}"
                      (click)="confirmRules(contractItem?.definition_id)"
                    >
                      <div *ngIf="checkDateIsAfterNow(contractItem?.rules_confirmation_deadline) || contractItem?.is_confirmed"
                           class="box"
                      ></div>

                      {{checkDateIsAfterNow(
                        contractItem?.rules_confirmation_deadline) ||
                        contractItem?.is_confirmed ? contractItems.package_id ?
                          'Wybieram ten kontrakt. Wiem na czym polega i co mogę zdobyć.' :
                          'Wiem na czym polega ten kontrakt i co mogę zdobyć.' :
                          contractItem?.is_confirmed === false ? contractItems.package_id ?
                            'Brak decyzji w terminie - przypisany kontrakt domyślny. Teraz walczysz tylko o część nagrody.' :
                            'Brak potwierdzenia w terminie, walczysz tylko o część nagrody.' : '' }}
                    </div>
                  </div>
                  <div class="expected-value">
                    <div *ngIf="!(contractItem?.is_combo)">{{ contractItem?.values?.[0] }}</div>
                    <ng-container *ngIf="contractItem?.is_combo">
                      <div *ngFor="let conditions of contractItem.conditions">
                        <span> {{conditions.values?.[0]}} </span>
                      </div>
                    </ng-container>
                  </div>
                  <div class="realization">
                    <div *ngIf="!(contractItem?.is_combo)">{{ contractItem?.values?.[1] }}</div>
                    <ng-container *ngIf="contractItem?.is_combo">
                      <div *ngFor="let conditions of contractItem.conditions">
                        <span> {{conditions.values?.[1]}} </span>
                      </div>
                    </ng-container>
                  </div>
                  <div class="prize-wrapper">
                    <div class="trophy">
                      <img (click)="openPrize(contractItem)" [src]="'trophy.png' | asset: 'ui'" />
                    </div>
                    <div *ngIf="contractItem?.is_chest_received === false && checkDateIsAfterNow(contractItem?.chest_confirmation_deadline)" class="chest" [class.cursor-default]="!(contractItem?.is_chest_received === false && checkDateIsAfterNow(contractItem?.chest_confirmation_deadline))">
                      <img (click)="markChestOpened(contractItem.definition_id)" [src]="'box.png' | asset: 'ui'" />
                    </div>
                    <div *ngIf="contractItem?.time_prizes && contractItem?.time_prizes?.length > 0" [class.inactive]="!(contractItem?.is_time_prize_available) || (contractItem?.is_closed || (contractItem?.is_done && !contractItem?.is_closed))" class="clock">
                      <img [matTooltip]="(contractItem?.is_definition_closed) ? 'Kontrakt zakończony, spróbuj następnym razem!' : !(contractItem?.is_time_prize_available) ? 'Wszystkie nagrody dodatkowe zostały wypłacone!' : 'Nagrody za czas wykonania kontraktu.'"  (click)="openClockPrize(contractItem)" [src]="'clock.png' | asset: 'ui'" />
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ow-perfect-scrollbar>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isLoading && contracts.length === 0">
    <div class="no-item">Brak aktywnych kontraktów</div>
  </ng-container>

  <div *ngIf="branchName" class="branch-name">{{ branchName }}</div>
</div>
