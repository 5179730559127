<div class="ow-dialog">
  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="close()"
  ></m-ui-close-button>

  <!-- TITLE -->
  <p class="title" *ngIf="data.title">
    {{ data.title }}
  </p>

  <pinch-zoom>
    <img [src]="data.image">
  </pinch-zoom>
</div>
