import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { Primary } from '../../../../../../../../core/interfaces/primary';
import { ParametersService } from '../../../../../../../../core/providers/parameters.service';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';
import { BuildingConfig } from '../../../../../../game-engine/interfaces/building-config';
import { AbstractRequirementsComponent } from '../../../../../shared-ui/abstract/abstract-requirements.component';
import { STOCK_VIEW } from '../../../../../shared-ui/mobile/consts/stock-view.const';
import { RequiredBuildingConfig } from "../../../../../../game-engine/interfaces/required-building-config";

@Component({
  selector: "app-track-start-requirements",
  templateUrl: "./track-start-requirements.component.html",
})
export class TrackStartRequirementsComponent extends AbstractRequirementsComponent implements OnInit, OnDestroy {
  @OwInject(ParametersService) parametersService: ParametersService;
  @Input() building: BuildingConfig;
  STOCK_VIEW = STOCK_VIEW;
  primary: Primary;
  enableWarning = false;

  ngOnInit() {
    this.subscribePlayer();

    if (this.building.build_currency_prices && this.building['original_build_currency_prices']) {
      if (this.building.build_currency_prices[0]?.currency_id === this.building['original_build_currency_prices'][0]?.currency_id &&
        this.building.build_currency_prices[0]?.amount !== this.building['original_build_currency_prices'][0]?.amount) {
        this.enableWarning = true;
      }
    }
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
