import { Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractWarehouseDetailsComponent } from "../../../../abstract/core/abstract-warehouse-details.component";

@Component({
  selector: "app-core-warehouse-details",
  templateUrl: "./warehouse-details.component.html",
})
export class CoreWarehouseDetailsComponent extends AbstractWarehouseDetailsComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribeDefaultTradingCurrencyId();
    this.parseProduct();
    this.getProductDetails();
    this.subscribePlayer();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
