import {Component, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import {Severity} from '@sentry/angular-ivy';

import {AbstractInjectBaseComponent} from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import {EVENTS} from '../../../../../../../../core/consts/core/events';
import {OwInject} from '../../../../../../../../core/decorators/ow-inject.decorator';
import {GlobalService} from '../../../../../../../../core/providers/global.service';
import {
  checkMissionWithoutStars,
  filterStarMissionByTypeAndSubGroupingHelper,
} from '../../../../../../../../helpers/filterStarMissionByType.helper';
import {AppState} from '../../../../../../../../store/state';
import {UtilityActions} from '../../../../../../../../store/utility';
import {DialogService} from '../../../../../../../shared/providers/dialog.service';
import {CenterMap} from '../../../../../../game-engine/interfaces/shared';
import {STOCK_VIEW} from '../../../../../shared-ui/mobile/consts/stock-view.const';
import {ApiMissionService} from '../../../../api/core/services/api-mission.service';
import {ApiMissionGroupService} from '../../../../api/core/services/api-mission-group.service';
import {MISSION_CONDITION_VALUE_TYPE} from '../../../../consts/core';
import {CUSTOM_MISSION_STAR_SUB_GROUPS} from '../../../../consts/custom/mission-star-sub-groups.const';
import {
  GroupMissionDetailsInterface,
  GroupMissionDetailsMissionInterface,
  GroupMissionInterface,
  MissionDetails,
} from '../../../../interfaces/core';

@Component({
  selector: "app-star-missions-details",
  templateUrl: "./star-missions-details.component.html",
  styleUrls: ["./star-missions-details.component.scss"],
})
export class StarMissionsDetailsComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiMissionGroupService) apiMissionGroupService: ApiMissionGroupService;
  @OwInject(ApiMissionService) apiMissionService: ApiMissionService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) public data: {
    mission: GroupMissionInterface;
    afterCloseOpenList: boolean;
    isOpenBySocket?: boolean;
  };
  MISSION_CONDITION_VALUE_TYPE = MISSION_CONDITION_VALUE_TYPE;

  SUB_GROUPS = CUSTOM_MISSION_STAR_SUB_GROUPS;
  STOCK_VIEW = STOCK_VIEW;

  groupMissions: GroupMissionInterface[] = [];
  activeGroupMission: GroupMissionInterface;
  activeGroupMissionDetails: GroupMissionDetailsInterface;
  activeMission: GroupMissionDetailsMissionInterface;
  activeMissionDetails: MissionDetails;
  areConditionsComplete: boolean;

  isMissionWithoutStars: boolean;

  noMissionsForActiveGroup: boolean;
  allStarsCollected: boolean;

  icon;

  ngOnInit() {
    this.getGroupMissions();
  }

  getGroupMissions() {
    this.apiMissionGroupService.getMissionGroups().subscribe(groupMissions => {
      if (checkMissionWithoutStars(this.data.mission)) {
        this.isMissionWithoutStars = true;
        const mission = groupMissions.find(g => g.mission_group_id === this.data.mission.mission_group_id);
        this.activeGroupMission = mission.is_reward_collected ? null : mission;
        this.allStarsCollected = mission.is_reward_collected;
      } else {
        const starsGroupMissions = filterStarMissionByTypeAndSubGroupingHelper(groupMissions);
        this.groupMissions = starsGroupMissions.filter(m => m.sub_group_id === this.data.mission.sub_group_id);
        this.allStarsCollected = this.groupMissions.every(m => m.is_reward_collected);
        this.activeGroupMission = this.groupMissions.find(groupMission => !groupMission.is_reward_collected);
        if (this.allStarsCollected) {
          this.icon = this.groupMissions[this.groupMissions.length - 1].icon;
        }
      }

      if (this.activeGroupMission && !this.activeGroupMission.is_reward_collected && this.activeGroupMission?.total_missions === 0) {
        this.noMissionsForActiveGroup = true;
        return;
      }

      if (
        this.activeGroupMission &&
        this.activeGroupMission?.completed_missions === this.activeGroupMission?.total_missions &&
        !this.activeGroupMission.is_reward_collected
      ) {
        this.apiMissionGroupService.getMissionGroupReward(this.activeGroupMission.mission_group_id).subscribe(() => {
          this.clearData();
          this.getGroupMissions();
        });
      } else if (this.activeGroupMission) {
        this.checkActiveGroupMission();
      }
    });
  }

  clearData() {
    this.groupMissions = [];
    this.activeGroupMission = null;
    this.activeGroupMissionDetails = null;
    this.activeMission = null;
    this.activeMissionDetails = null;
    this.areConditionsComplete = false;
    this.isMissionWithoutStars = false;
    this.noMissionsForActiveGroup = false;
    this.allStarsCollected = false;
  }

  checkActiveGroupMission() {
    this.apiMissionGroupService.getMissionGroupsDetails(this.activeGroupMission.mission_group_id).subscribe(groupMissionDetails => {
      this.activeGroupMissionDetails = groupMissionDetails;
      const activeMission = this.activeGroupMissionDetails.missions.find(mission => !mission.is_collected);

      if (!activeMission) {
        const missionJSON = JSON.stringify({
          messageTitle: "Star mission not found",
          missionGroupId: this.activeGroupMission.mission_group_id
        });
        Sentry.captureMessage(missionJSON, "fatal");
        return;
      }
      this.activeMission = activeMission;
      this.apiMissionService.getMissionDetails(this.activeMission.player_mission_id).subscribe(mission => {
        this.activeMissionDetails = mission;
        this.areConditionsComplete = this.activeMissionDetails.conditions.every(condition => condition.completed_at);
        this.dispatchRemoveMissionToOpen();
      });
    });
  }

  dispatchRemoveMissionToOpen() {
    this.store.dispatch(
      new UtilityActions.RemoveMissionToOpen({
        player_mission_id: this.activeMissionDetails.player_mission_id,
      })
    );
  }

  changeMission(mission: GroupMissionDetailsMissionInterface) {
    this.activeMission = mission;
  }

  completeStage() {
    this.apiMissionService.missionCollect(this.activeMissionDetails.player_mission_id).subscribe(() => {
      this.getGroupMissions();
    });
  }

  showOnMap(centerMap: CenterMap) {
    this.globalService.globalEvents.emit({
      name: EVENTS.GAME.CENTER_TILE,
      value: centerMap,
    });

    this.dialogService.closeAll();
  }
}
