<div class="global-dialog ow-dialog c-window-short">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button [preventDefault]="true" (clicked)="closeOrCloseAll()"></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button [preventDefault]="true" (clicked)="close()" *ngIf="messageOpenDetailsData.fromList"></m-ui-back-button>

  <div class="chest-success-container" *ngIf="message">
    <!-- TEASER -->
    <div class="teaser" [innerHTML]="message.teaser | safe: 'html'"></div>

    <!-- REWARD IMAGE -->
    <div class="message-image"
         [ngClass]="{
          'default': message.parameters.chest_product_icon !== 'chest_blue',
          'chest-blue': message.parameters.chest_product_icon === 'chest_blue'
         }"></div>

    <!-- DESCRIPTION -->
    <div class="inner-html-description" [innerHTML]="message.description | safe: 'html'"></div>

    <!-- PRIZES -->
    <ng-container *ngIf="message.parameters.currency_rewards.length || message.parameters.product_rewards.length">
      <div class="parameters">
        <!-- CURRENCIES -->
        <m-ui-currency
          *ngFor="let currency of message.parameters.currency_rewards"
          [item]="currency"
          [stockView]="STOCK_VIEW.CUSTOM"
          [customClassStock]="'stock-san-a'"
          [lack]="false"></m-ui-currency>

        <!-- PRODUCTS -->
        <m-ui-product
          *ngFor="let product of message.parameters.product_rewards"
          [item]="product"
          [stockView]="STOCK_VIEW.CUSTOM"
          [customClassStock]="'stock-san-a'"
          [lack]="false"></m-ui-product>
      </div>
    </ng-container>
  </div>

  <!-- MESSAGE TRASH -->
  <button class="c-square small" *ngIf="message && !message.deleted" matTooltip="Przenieś do archiwum">
    <i class="fas fa-archive trash-icon" (click)="moveToTrash()"></i>
  </button>
</div>
