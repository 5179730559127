import {Component, OnInit} from '@angular/core';
import { Router } from "@angular/router";
import * as R from "ramda";

import { GAME_CONFIG } from "../../../../../../../core/config/custom/_parsed-game.config";
import { PLAYER_TITLES } from "../../../../../../../core/consts/core/player-titles";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE } from "../../../../currency-exchange/consts/core/event-dialogs/event-names.const";
import { AbstractHudResourcesComponent } from "../../../abstract/core/abstract-hud-resources.component";

@Component({
  selector: "app-san-hud-resources",
  templateUrl: "./hud-resources.component.html",
})
export class HudResourcesComponent extends AbstractHudResourcesComponent implements OnInit {
  @OwInject(Router) router: Router;

  LOCK_NOT_ACTIVE_ME_CURRENCY_ID = [3];
  LOCK_NOT_ACTIVE_ME_PRODUCT_ID = [];

  DOR_CURRENCY_ID = [3];
  DOR_PRODUCT_ID = [];

  PARTNER_CURRENCY_ID = [];
  PARTNER_PRODUCT_ID = [];

  CC_MANAGER_CURRENCY_ID = [3];
  CC_MANAGER_PRODUCT_ID = [];

  isCC = GAME_CONFIG.IS_CC;

  ngOnInit() {
    this.subscribeUser();
    this.setParameters();
  }

  openShop() {
    this.router.navigate(["auth/shop"]);
  }

  openExchangeCurrency() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE.CURRENCY_EXCHANGE,
    });
  }

  orderGuiItems() {
    super.orderGuiItems();
    this.filterRole();
    this.filterIsNotActiveMe();
  }

  filterRole() {
    if (GAME_CONFIG.IS_CC) {
      if (!this.playerService.player.title_id.playable) {
        this.filterGuiItems({
          filteredCurrencyId: this.CC_MANAGER_CURRENCY_ID,
          filteredProductId: this.CC_MANAGER_PRODUCT_ID,
        });
      }
    } else {
      if (this.playerService.player.title_id.id >= PLAYER_TITLES.DOR.id) {
        let filteredCurrencyId, filteredProductId;

        switch (this.playerService.player.title_id.id) {
          case PLAYER_TITLES.PARTNER.id:
            filteredCurrencyId = this.PARTNER_CURRENCY_ID;
            filteredProductId = this.PARTNER_PRODUCT_ID;
            break;

          case PLAYER_TITLES.DOR.id:
          case PLAYER_TITLES.DPP.id:
            filteredCurrencyId = this.DOR_CURRENCY_ID;
            filteredProductId = this.DOR_PRODUCT_ID;
            break;
        }

        this.filterGuiItems({
          filteredCurrencyId,
          filteredProductId,
        });
      }
    }
  }

  filterGuiItems({ filteredCurrencyId, filteredProductId }) {
    this.guiItems = this.guiItems.filter(guiItem => {
      if (guiItem.hasOwnProperty("currency_id")) {
        return R.includes(guiItem.currency_id, filteredCurrencyId);
      }

      if (guiItem.hasOwnProperty("product_id")) {
        return R.includes(guiItem.product_id, filteredProductId);
      }
    });
  }

  filterIsNotActiveMe() {
    if (!this.playerService.isActiveMe) {
      this.guiItems = this.guiItems.filter(guiItem => {
        if (guiItem.hasOwnProperty("currency_id")) {
          return !R.includes(guiItem.currency_id, this.LOCK_NOT_ACTIVE_ME_CURRENCY_ID);
        }

        if (guiItem.hasOwnProperty("product_id")) {
          return !R.includes(guiItem.product_id, this.LOCK_NOT_ACTIVE_ME_PRODUCT_ID);
        }
      });
    }
  }
}
