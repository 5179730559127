<div class="global-dialog ow-dialog c-window-long" [ngClass]="activeTab.status">
  <loading></loading>

  <!-- EXIT BUTTON -->
  <m-ui-close-button></m-ui-close-button>

  <!-- TABS -->
  <div class="tabs" *ngIf="tabs && activeTab">
    <ng-container *ngFor="let tab of tabs; let i = index">
      <div
        class="tab"
        [class.active]="tab === activeTab"
        [class]="tab.color"
        [style.z-index]="-i"
      >
        <div class="bookmarks">
          <div
            class="bookmark"
            [class]="tab.color"
            (click)="!tab.disabledTooltip && changeTab(tab)"
            [ngbTooltip]="tab.disabledTooltip"
            placement="bottom"
          >
            <span>{{ tab.label }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container [ngSwitch]="activeTab.id">
    <ng-container
      *ngSwitchCase="TAB_ID.AVAILABLE"
      [ngTemplateOutlet]="availableTpl"
    ></ng-container>

    <ng-container
      *ngSwitchCase="TAB_ID.ENDED"
      [ngTemplateOutlet]="endedTpl"
    ></ng-container>

    <ng-container
      *ngSwitchCase="TAB_ID.PLAYER"
      [ngTemplateOutlet]="playerTpl"
    ></ng-container>

    <ng-container
      *ngSwitchCase="TAB_ID.INFO"
      [ngTemplateOutlet]="infoTpl"
    ></ng-container>
  </ng-container>
</div>

<ng-template #availableTpl>
  <app-auction-card
    *ngIf="auctions.items.length"
    [auctions]="auctions.items"
    [typeAuction]="TAB_ID.AVAILABLE"
    [isLoading]="isLoading"
    (openDetails)="openAuctionDetails($event)"
  ></app-auction-card>

  <ng-container *ngIf="!isLoading && !auctions.items.length">
    <div class="no-item">Brak aktualnie dostępnych aukcji</div>
  </ng-container>
</ng-template>

<ng-template #endedTpl>
  <app-auction-card
    *ngIf="auctions.items.length"
    [auctions]="auctions.items"
    [typeAuction]="TAB_ID.ENDED"
    [isLoading]="isLoading"
    (openDetails)="openAuctionDetails($event)"
  ></app-auction-card>

  <ng-container *ngIf="!isLoading && !auctions.items.length">
    <div class="no-item">Brak zakończonych aukcji</div>
  </ng-container>
</ng-template>

<ng-template #playerTpl>
  <div class="filter" *ngIf="auctions.objectFilters.length && auctions.activeFilter">
      <div class="role-select">
        <ng-select
          [items]="auctions.objectFilters"
          [bindLabel]="'label'"
          [(ngModel)]="auctions.activeFilter"
          [clearable]="false"
          [searchable]="false"
          (change)="getAuctions($event)"
        ></ng-select>
      </div>
  </div>

  <app-auction-card
    *ngIf="auctions.items.length"
    [auctions]="auctions.items"
    [typeAuction]="TAB_ID.PLAYER"
    [isLoading]="isLoading"
    (openDetails)="openAuctionDetails($event)"
  ></app-auction-card>

  <ng-container *ngIf="!isLoading && !auctions.items.length">
    <div class="no-item">Brak aukcji z Twoim udziałem</div>
  </ng-container>
</ng-template>

<ng-template #infoTpl>
  <app-auction-info
  [currentFilterId]="media.currentFilterId"
  [currentPage]="media.currentPage"
  [maxPages]="media.maxPages"
  [items]="media.items"
  (getMediaList)="getMediaList()"
  (nextPage)="nextPage()"
  (previousPage)="previousPage()"
  (changeFilter)="changeFilter()"
  [isLoading]="isLoading"
  ></app-auction-info>
</ng-template>
