import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  standalone: true,
  selector: "ow-slider",
  templateUrl: "./ow-slider.component.html",
  // styleUrls: ['./ow-slider.component.scss']
})
export class OwSliderComponent {
  @Input() min = 0;
  @Input() max = 0;
  @Input() value = 0;
  @Input() step = 0.1;
  @Input() disabled = false;
  @Output() onChange = new EventEmitter();
  @Output() onInput = new EventEmitter();

  emitChange(event) {
    this.onChange.emit(event);
  }

  emitInput(event) {
    this.onInput.emit(event);
  }
}
