import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { PlayerService } from '../../../../../player/providers/player.service';
import { UserService } from '../../../../../user/providers/user.service';
import { SynchronizeTimeService } from '../../../../../../core/providers/synchronize-time.service';

@Injectable({
  providedIn: 'root'
})
export class EventsShopService {
  eventShopNotification: boolean;
  eventShopLSKey = `${this.userService.me.id}-event-shop-notification`;

  constructor(
    private playerService: PlayerService,
    private userService: UserService,
    private synchronizeTimeService: SynchronizeTimeService,
  ) {
  }

  clearEventShopNotification() {
    this.eventShopNotification = false;

    const date = this.playerService.player['event_shop_important_date'];
    if (date) {
      localStorage.setItem(this.eventShopLSKey, date);
    }
  }

  checkEventShopNotification() {
    const date = this.playerService.player['event_shop_important_date'];
    if (!date) {
      this.eventShopNotification = false;
      return;
    }

    const markAsImportantOnDate = moment(date).startOf('day');
    const currentDate = moment(this.synchronizeTimeService.getActualLocalTime()).startOf('day');

    const dateFromLS = localStorage.getItem(this.eventShopLSKey);

    if (dateFromLS && moment(dateFromLS).startOf('day').isSame(markAsImportantOnDate)) {
      this.eventShopNotification = false;
    } else {
      this.eventShopNotification = markAsImportantOnDate.isSame(currentDate);
    }
  }
}
