import { Injectable } from "@angular/core";
import {AuctionsEventDialogsService} from '../../../modules/game/game-ui/auctions/services/custom/event-dialogs.service';
import {EventShopEventDialogsService} from '../../../modules/game/game-ui/event-shop/services/custom/event-dialogs.service';
import {TrackingEventDialogsService} from '../../../modules/game/game-ui/tracking/services/custom/event-dialogs.service';
import {AntyminerEventDialogsService} from '../../../modules/game/game-ui/antyminer/services/custom/event-dialogs.service';

@Injectable({
  providedIn: "root",
})
export class RegisterCustomEventDialogsService {
  constructor(
    private auctionsEventDialogsService: AuctionsEventDialogsService,
    private eventShopEventDialogsService: EventShopEventDialogsService,
    private trackingService: TrackingEventDialogsService,
    private antyminerService: AntyminerEventDialogsService
  ) {}
}
