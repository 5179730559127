<div class="global-dialog ow-dialog c-window-long">
  <loading></loading>

  <m-ui-close-button></m-ui-close-button>

  <m-ui-back-button
    *ngIf="data.backButton"
  ></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Oddziały</span>
      </div>
    </div>
  </div>

  <!-- TOP MENU -->
  <div class="top-menu">
    <input
      type="text"
      class="search-bar"
      placeholder="Szukaj..."
      (keyup)="changeSearchValue($event)"
    />
  </div>

  <!-- CONTENT -->
  <div class="main loading-opacity">
    <ng-container *ngIf="filteredBranches?.length > 0 ; else noItemTpl">
      <ow-perfect-scrollbar
        [scrollGradient]="filteredBranches"
        [isRefreshScrollTop]="false"
        [class.loading-opacity]="isLoading"
      >
        <div *ngFor="let branch of filteredBranches" class="branch">
          <span
            [matTooltip]="'kierownik niezarejestrowany'"
            [matTooltipPosition]="'right'"
            [matTooltipShowDelay]="300"
            [matTooltipDisabled]="!!branch.manager_player_id"
            [class]="{'italic': !branch.manager_player_id}"> {{ branch.name }} </span>
          <span class="cta" (click)="goToBranch(branch)"> Wybierz </span>
        </div>
      </ow-perfect-scrollbar>
    </ng-container>

    <ng-template #noItemTpl>
      <div *ngIf="!isLoading" class="no-item">
        Brak danych
      </div>
    </ng-template>
  </div>
</div>
