import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";

import { GameService } from "../../../../game/services/game.service";
import { RiverRideGame } from "../../classes/RiverRideGame.class";
import { RRGameParams } from "../../river-ride.interfaces";
import { RREditorScene } from "../../scenes/RiverRideEditor.scene";
import { RiverRidePlayScene } from "../../scenes/RiverRidePlay.scene";
import { RiverRideService } from "../../services/river-ride.service";
import ScaleModes = Phaser.Scale.ScaleModes;

@Component({
  selector: "app-minigame-river-ride",
  templateUrl: "./minigame-river-ride.component.html",
})
export class MinigameRiverRideComponent implements AfterViewInit, OnDestroy {
  game: RiverRideGame;

  constructor(private gameService: GameService, private riverRideService: RiverRideService, private cd: ChangeDetectorRef) {}

  @Input() parameters: RRGameParams;
  @Output() fromGameEvent = new EventEmitter<any>();
  editMode = Boolean(location.search.includes("edit-mode"));

  ngAfterViewInit() {
    this.gameService.pauseGame();

    this.game = new RiverRideGame({
      parent: "minigame-river-ride",
      scene: [this.editMode ? RREditorScene : RiverRidePlayScene],
      gameService: this.gameService,
      riverRideService: this.riverRideService,
      width: 1000,
      height: 570,
      loader: {
        // @ts-ignore
        imageLoadType: "HTMLImageElement",
        crossOrigin: "anonymous"
      },
      images: {
        default: 'assets/phaser/default.png',
        missing: 'assets/phaser/missing.png',
        white: 'assets/phaser/white.png'
      },
      editMode: this.editMode,
      gameParams: this.parameters,
      scale: {
        mode: ScaleModes.FIT,
      },
    });
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.game.destroy(true);
    this.gameService.resumeGame();
  }
}
