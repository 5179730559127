import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import { AbstractInjectBaseComponent } from "../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../core/decorators/ow-inject.decorator";
import { STOCK_VIEW } from "../../../../game/game-ui/shared-ui/mobile/consts/stock-view.const";
import { GameEvent } from "../../../../game/interfaces/game.interfaces";
import { DialogService } from "../../../../shared/providers/dialog.service";
import { GameModes } from "../../../enums/game-modes.enum";
import { MiniGameEventsEnum } from "../../../interfaces/mini-game-events.enum";
import { CB_EDITABLE } from "../../catch-bananas.constans";
import { CBStatus } from "../../interfaces/status.interface";
import { CatchBananasService } from "../../services/catch-bananas.service";

@Component({
  selector: "app-minigame-catch-bananas-gui",
  templateUrl: "./minigame-catch-bananas-gui.component.html",
})
export class MinigameCatchBananasGuiComponent extends AbstractInjectBaseComponent implements AfterViewInit, OnInit, OnDestroy {
  @OwInject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef;
  @OwInject(MAT_DIALOG_DATA) data: {
    game: number;
    payment: number;
    unfinishedStatus: CBStatus;
  };
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<MinigameCatchBananasGuiComponent>;
  @OwInject(CatchBananasService) catchBananasService: CatchBananasService;
  @OwInject(DialogService) dialogService: DialogService;
  @ViewChild("catchBananasGuiResult", { static: false }) catchBananasGuiResultTplRef: TemplateRef<any>;
  @ViewChild("catchBananasGuiPause", { static: false }) catchBananasGuiPauseTplRef: TemplateRef<any>;
  @ViewChild("catchBananasGuiLoad", { static: false }) catchBananasGuiLoadTplRef: TemplateRef<any>;
  CBGameModes = GameModes;
  STOCK_VIEW = STOCK_VIEW;
  currentGame: CBStatus;
  currentTemplate: TemplateRef<any>;
  catchBananasParams: any;
  gameState = {
    progress: 0,
    isLoaded: false,
  };
  editor = CB_EDITABLE;
  isEditable: boolean;
  summary: {
    mini_game_id: number;
    game: number;
    payment: number;
    token: string;
    score1: number;
    score2: number;
    score3: number;
    points1: number;
    points2: number;
    points3: number;
    multiplier1: number;
    multiplier2: number;
    multiplier3: number;
    total_food: number;
    started_at: string;
    finished_at: string;
    chests_received: boolean;
  };

  private cbEvents$: Subscription;

  ngAfterViewInit() {
    this.showLoad();
  }

  setTemplate(template: TemplateRef<any>) {
    this.currentTemplate = template;
    this.changeDetectorRef.detectChanges();
  }

  showLoad() {
    this.setTemplate(this.catchBananasGuiLoadTplRef);
  }

  initCatchBananas() {
    this.catchBananasParams = {
      gameMode: this.data.payment,
      state: this.data.unfinishedStatus && this.data.unfinishedStatus.progress,
    };
  }

  showResult(score) {
    this.summary = score;
    this.setTemplate(this.catchBananasGuiResultTplRef);
  }

  close() {
    this.matDialogRef.close();
  }

  ngOnInit(): void {
    this.checkUnfinished();
    this.initCatchBananas();
    this.subscribeEvents();
  }

  subscribeEvents() {
    this.cbEvents$ = this.catchBananasService.cbEvents.subscribe((event: GameEvent | { name: number; value: any }) => {
      switch (event.name) {
        case MiniGameEventsEnum.LOAD_PROGRESS:
          this.gameState.progress = event.value;
          break;
        case MiniGameEventsEnum.LOAD_COMPLETE:
          this.gameState.isLoaded = true;
          break;
        case MiniGameEventsEnum.GAME_PAUSE:
          this.setPause();
          break;
        case MiniGameEventsEnum.PROGRESS:
          this.progress(event.value);
          break;
        case MiniGameEventsEnum.GAME_OVER:
          this.finish(event.value);
          break;
      }
    });
  }

  playGame() {
    this.emitCBEvent(MiniGameEventsEnum.GAME_START);
    this.setTemplate(null);
  }

  setPause() {
    this.setTemplate(this.catchBananasGuiPauseTplRef);
  }

  resumeGame() {
    this.setTemplate(null);
    this.emitCBEvent(MiniGameEventsEnum.GAME_RESUME);
  }

  emitCBEvent(name: MiniGameEventsEnum, value?: any) {
    this.catchBananasService.cbEvents.emit({
      name,
      value,
    });
  }

  checkUnfinished() {
    if (this.data.unfinishedStatus) {
      this.currentGame = this.data.unfinishedStatus;
    }
  }

  start() {
    this.catchBananasService
      .start({
        game: this.data.game,
        payment: this.data.payment,
      })
      .subscribe({
        next: resp => {
          this.currentGame = resp;
          this.playGame();
        },
        error: errResp => {
          this.dialogService.openAlertErrorApi({ errResp });
        },
      });
  }

  continueUnfinished() {
    this.playGame();
  }

  finish(progress) {
    progress = this.addBuildVersionGame(progress);
    progress = this.encodeData(progress);

    this.catchBananasService
      .finish({
        mini_game_id: this.currentGame.mini_game_id,
        progress,
      })
      .subscribe(resp => {
        this.showResult(resp);
      });
  }

  progress(progress) {
    progress = this.addBuildVersionGame(progress);
    progress = this.encodeData(progress);

    this.catchBananasService
      .progress({
        mini_game_id: this.currentGame.mini_game_id,
        progress,
      })
      .subscribe();
  }

  encodeData(data) {
    const base = btoa(JSON.stringify(data));
    return encodeURIComponent(`${this.currentGame.token}${base}`);
  }

  addBuildVersionGame(progress) {
    return {
      ...progress,
      buildVersionGame: document["GAME_VERSION_TIMESTAMP"],
    };
  }

  ngOnDestroy(): void {
    this.catchBananasService.cbEvents.emit({ name: MiniGameEventsEnum.DESTROY });
    this.cbEvents$.unsubscribe();
  }
}
