<div
  class="ow-dialog c-window-d c-quaternary no-transparent"
  [style.min-width]="data.style?.minWidth"
  [style.max-height]="data.style?.maxHeight"
  [style.min-height]="data.style?.minHeight"
>
  <!-- TITLE -->
  <p class="title" *ngIf="data">
    {{ data.title ? data.title : ('alert.alert' | myTranslate) }}
  </p>

  <!-- DESCRIPTION -->
  <p
    class="description"
    *ngIf="data.description"
    [innerHTML]="data.description"
  ></p>

  <ng-container *ngIf="data.costs?.currencies.length || data.costs?.products.length">
    <!-- SEPARATOR TITLE -->
    <p class="separator-title" *ngIf="data.costs.separatorTitle">
      {{ data.costs.separatorTitle }}
    </p>

    <!-- COSTS -->
    <m-ui-costs
      [currencies]="data.costs.currencies"
      [products]="data.costs.products"
      [stockView]="STOCK_VIEW.CUSTOM"
      [observable]="true"
      [lack]="data.costs.lack"
      (eventEmitter)="checkCostsRequirements($event)"
      [customIconSize]="'big'"
      [customClassStock]="'stock-layout-a'"
    ></m-ui-costs>
  </ng-container>

  <!-- CONFIRM -->
  <div class="buttons">
    <!-- CANCEL -->
    <button
      class="c-base"
      [ngClass]="data.buttonClose?.colorSchema ? data.buttonClose.colorSchema : 'c-secondary'"
      (click)="close(false)"
    >
      {{ data.buttonClose?.label || ('alert.close' | myTranslate) }}
    </button>

    <!-- CONFIRM -->
    <button
      class="c-base"
      [ngClass]="data.buttonSuccess?.colorSchema ? data.buttonSuccess.colorSchema : 'c-primary'"
      (click)="close(true)"
      [disabled]="data.buttonSuccess?.disabled || (costsRequirement && !costsRequirement.valid)"
    >
      {{ data.buttonSuccess?.label || ('alert.ok' | myTranslate) }}
    </button>
  </div>
</div>
