import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../core/abstracts/abstract-inject-base.component";
import { GAME_CONFIG } from "../../../../core/config/custom/_parsed-game.config";
import { OwInject } from "../../../../core/decorators/ow-inject.decorator";
import { STOCK_VIEW } from "../../../game/game-ui/shared-ui/mobile/consts/stock-view.const";
import { DialogService } from "../../../shared/providers/dialog.service";
import { ApiMinigameService } from "../../api/services/api-minigame.service";
import { ApiMinigameRanksService } from "../../api/services/api-minigame-rank.service";
import { CatchBananaConfig } from "../../config/catch-banana.config";
import { Match3Config } from "../../config/match3.config";
import { RiverRideConfig } from "../../config/river-ride.config";
import { GameIdentifierEnum } from "../../enums/game-identifier.enum";
import { GameModes } from "../../enums/game-modes.enum";
import { GameConfigInterface } from "../../interfaces/game-config.interface";
import { MinigameStats } from "../../riverride/interfaces/stats.interface";
import { Match3ConfigPremium } from "../../config/match3-premium.config";
import { Match3Service } from "../../match3/match3.service";
import {swiperInjectionStyles} from '../../../../../styles/swiper/swiper-injection-styles';
import {Swiper} from 'swiper/types';

@Component({
  selector: "app-minigames",
  templateUrl: "./minigames.component.html",
})
export class MinigamesComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef;
  @OwInject(ApiMinigameService) apiMinigameService: ApiMinigameService;
  @OwInject(Match3Service) m3service: Match3Service;
  @OwInject(ApiMinigameRanksService) apiMinigameRanksService: ApiMinigameRanksService;
  @ViewChild("swiperRef", { static: false })
  swiper: Swiper = null;
  currentSlideIndex = 0;
  STOCK_VIEW = STOCK_VIEW;
  GameModes = GameModes;
  loaded = false;
  isCC = GAME_CONFIG.IS_CC;
  disableCC = false;

  currentTemplate: TemplateRef<any>;
  swiperConfig = {
    slidesPerView: 4,
    initialSlide: 0,
    centeredSlides: false,
    pagination: {
      dynamicBullets: true,
      clickable: true,
    },
    injectStyles: [swiperInjectionStyles]
  };
  sliderActiveIndex = 0;

  rawGamesConfig = [RiverRideConfig, CatchBananaConfig, Match3Config, Match3ConfigPremium];
  games: GameConfigInterface[] = []; // parsed game configs

  recoverGameProgressAutomatically = true;
  stats: MinigameStats;

  resources = {
    products: [],
  };

  ngOnInit() {
    this.apiMinigameService.getMiniGames().subscribe(res => {
      // deep clone config and set proper values from DEF
      for (const settings of res) {
        const gameConfig = this.rawGamesConfig.find(x => x.key === settings.key);

        if (!gameConfig || !settings.visible) {
          continue;
        }

        gameConfig.visible = settings.visible;
        gameConfig.game = settings.id;
        gameConfig.costs.products = [{ product_id: settings.required_product_id, amount: settings.required_price }];

        this.games.push(gameConfig);

        // add new resource to the hud menu below the cards if missing
        const resource = this.resources.products.find(x => x.product_id === settings.required_product_id);
        if (!resource) {
          this.resources.products.push({ product_id: settings.required_product_id, visible: true, disabledCC: this.disableCC });
        }
      }

      this.filterResources();
      this.checkAndOverrideConfigSwiper();

      this.loaded = true;

      if (this.recoverGameProgressAutomatically) {
        this.recoverLastGameSession();
      }
    });
  }

  // find and show products below the cards
  filterResources() {
    this.resources.products = this.resources.products.filter(resource => resource.visible === true).filter(this.isCCondition.bind(this));
  }

  isCCondition(item) {
    return !this.isCC || (this.isCC && !item.disabledCC);
  }

  checkAndOverrideConfigSwiper() {
    if (this.games.length < this.swiperConfig.slidesPerView) {
      this.swiperConfig.slidesPerView = this.games.length;
    }
  }

  openAlertDialog(gameConfig: GameConfigInterface) {
    let dialogObject = null;

    switch (gameConfig.game) {
      case GameIdentifierEnum.CatchBanana: {
        dialogObject = {
          description: gameConfig.rules,
          isScrollbarOn: true,
          style: {
            fixedPerfectScrollbarHeight: "330px",
          },
        };
        break;
      }
      case GameIdentifierEnum.RiverRide: {
        dialogObject = {
          description: gameConfig.rules,
          isScrollbarOn: true,
          style: {
            fixedPerfectScrollbarHeight: "424px",
          },
        };
        break;
      }
      case GameIdentifierEnum.Match3: {
        dialogObject = {
          description: gameConfig.rules,
          isScrollbarOn: true,
          style: {
            maxWidth: "1000px",
            fixedPerfectScrollbarHeight: "650px",
          },
        };
        break;
      }
      case GameIdentifierEnum.Match3Premium: {
        dialogObject = {
          description: gameConfig.rules,
          isScrollbarOn: true,
          style: {
            maxWidth: "1000px",
            fixedPerfectScrollbarHeight: "650px",
          },
        };
        break;
      }
      default: {
        dialogObject = {
          description: gameConfig.rules,
          isScrollbarOn: true,
          style: {
            maxWidth: "800px",
          },
        };
        break;
      }
    }

    this.dialogService.openAlert(dialogObject);
  }

  openGame(game, currentProgress = null) {
    this.dialogService.closeActive();
    this.dialogService.open(game.component, {
      data: {
        game: game.game,
        payment: game.payment,
        unfinishedStatus: currentProgress,
      },
    });
  }

  prevSlide() {
    this.swiper.slidePrev();
    this.currentSlideIndex = this.swiper.activeIndex;
  }

  nextSlide() {
    this.swiper.slideNext();
    this.currentSlideIndex = this.swiper.activeIndex;
  }

  close() {
    this.dialogService.closeAll();
  }

  checkCostsRequirements(event, game) {
    // TODO - rewrite the way m-ui-costs is updating the values(violating "value changed after it was checked")
    // save values after refresh tick
    setTimeout(() => {
      if (game.costs.canPlay !== event.value.valid) {
        game.costs.canPlay = event.value.valid;
      }
    }, 10);
  }

  recoverLastGameSession() {
    this.apiMinigameService.getCurrentUnfinishedGame().subscribe(gameProgress => {
      if (gameProgress) {
        switch (gameProgress.game.id) {
          case GameIdentifierEnum.RiverRide: {
            this.openGame(RiverRideConfig, gameProgress);
            break;
          }
          case GameIdentifierEnum.Match3Premium:
          case GameIdentifierEnum.Match3: {
            // this.openGame(Match3Config, gameProgress);
            const encodedProgress = this.apiMinigameService.encodeData(
              {
                ...gameProgress.progress,
                forcedFinish: true,
                score1: gameProgress?.progress?.score1
                  ? this.m3service.calculateTheScore(gameProgress.progress.score1, 0, 0, 0, gameProgress.progress.boxAmount)
                  : 0,
              },
              gameProgress.token
            );
            this.apiMinigameService.finishGame(gameProgress.mini_game_id, { body: { progress: encodedProgress } }).subscribe(res => {
              this.recoverLastGameSession();
            });
            break;
          }
          case GameIdentifierEnum.CatchBanana: {
            this.openGame(CatchBananaConfig, gameProgress);
            break;
          }
          default:
            break;
        }
      }
    });
  }

  // findGame({ game, payment }: { game: number; payment: number }) {
  //   return this.games.find(item => item.game === game && item.payment === payment);
  // }
  //
  // getStats() {
  //   this.riverRideService.stats().subscribe(stats => {
  //     this.stats = stats;
  //
  //     this.games
  //       .filter(game => game.payment === GameModes.PREMIUM)
  //       .forEach(game => {
  //         const eachStats = stats.find(stat => stat.game === game.game);
  //
  //         if (eachStats) {
  //           game.stats = eachStats;
  //         }
  //       });
  //
  //     this.changeDetectorRef.detectChanges();
  //   });
  // }
  //
  // openMinigameRankListComponent(game) {
  //   if (!game.ranks) {
  //     switch (game.game) {
  //       case 1:
  //       case 2:
  //         this.dialogService.openAlert({
  //           description: RANK_MINIGAME_DESCRIPTIONS[game.game],
  //         });
  //         break;
  //     }
  //
  //     return;
  //   }
  //
  //   this.dialogService.open(MinigameRankDetailsComponent, {
  //     data: {
  //       ranks: game.ranks,
  //     },
  //   });
  // }
  //
  // getRankList() {
  //   this.apiMinigameRanksService.ranks().subscribe(
  //     resp => {
  //       this.games
  //         .filter(game => game.payment === GameModes.PREMIUM)
  //         .forEach(game => {
  //           const ranks = resp
  //             .filter(rank => rank.game === game.game)
  //             .map(rank => {
  //               const name = MINIGAME_NAMES[rank.game];
  //
  //               rank.description = RANK_MINIGAME_DESCRIPTIONS[rank.game];
  //               rank.month_text = new OwDate({ year: rank.year, month: rank.month }).month_text_normal;
  //               rank.full_name = `${name} - ${rank.month_text} ${rank.year}`;
  //               return rank;
  //             });
  //
  //           if (ranks.length > 0) {
  //             game.ranks = ranks;
  //           }
  //         });
  //
  //       this.changeDetectorRef.detectChanges();
  //     },
  //     errResp => {
  //       this.dialogService.openAlertErrorApi({ errResp });
  //     }
  //   );
  // }
}
