<loading></loading>

<!-- NAVIGATE INFO -->
<div class="navigate-info" *ngIf="!isLoading && owDate">
    <!-- IF RANKS MONTH -->
    <ng-container *ngIf="pagination == PAGINATION_TYPES.MONTH">
      {{ owDate.month_text_normal }}
    </ng-container>

    <!-- IF RANKS QUARTER -->
    <ng-container *ngIf="pagination == PAGINATION_TYPES.QUARTER">
      {{ owDate.quarter_text_normal }}
    </ng-container>

    <!-- IF RANKS HALF -->
    <ng-container *ngIf="pagination == PAGINATION_TYPES.HALF">
      {{ owDate.half_text_normal }}
    </ng-container>

    <ng-container *ngIf="rankDetails?.source_data_date">
      | Dane aktualne na: {{ rankDetails.source_data_date | date:'dd-MM-y' }}
    </ng-container>

    <ng-container *ngIf="!rankDetails?.source_data_date">
      {{ owDate.year }}
    </ng-container>
</div>

<div class="content">

  <!-- TOP -->
  <div class="top">

    <!-- LEFT -->
    <div class="left">
      <ng-container *ngIf="regions.length && groups.length && group === RANK_GROUP.BRANCH">
        <!-- SITES -->
        <div class="role-select">
          <ng-select
            [items]="sites"
            bindLabel="value"
            bindValue="id"
            [clearable]="false"
            placeholder="Wybierz sieć"
            [searchable]="false"
            [(ngModel)]="activeSiteId"
            (change)="getRank()"
          ></ng-select>
        </div>

        <!-- REGIONS -->
        <div class="role-select">
          <ng-select
            [items]="regions"
            bindLabel="name"
            bindValue="region_id"
            placeholder="Wybierz region"
            [clearable]="false"
            [searchable]="false"
            [(ngModel)]="activeRegionId"
            (change)="getRank()"
          ></ng-select>
        </div>

        <!-- GROUPS -->
        <div class="role-select">
          <ng-select
            [items]="groups"
            bindLabel="league_group_name"
            bindValue="league_group_id"
            placeholder="Wybierz grupę"
            [clearable]="false"
            [searchable]="false"
            [(ngModel)]="activeGroupId"
            (change)="getRank()"
          ></ng-select>
        </div>
      </ng-container>
    </div>

    <!-- RIGHT -->
    <div class="right">

      <!-- FORM -->
      <div class="default-field">
        <input
          type="text"
          placeholder="Szukaj..."
          id="search"
          #inputSearch
          (keyup)="$event"
        >
        <label for="search">Szukaj...</label>
      </div>
    </div>
  </div>

  <div class="frame">
    <ng-container *ngIf="rankDetails">
      <!-- MY POSITION -->
      <div
        class="my-position-link"
        showMyPositionRank
        *ngIf="rankDetails.my_position !== null && playerService.isActiveMe && group != RANK_GROUP.REGION"
        [perfectScrollbarRef]="perfectScrollbar"
        [myPosition]="rankDetails.my_position"
      >
        <a>{{branch && isDor ? "Pokaż pozycję oddziału" : "Pokaż moją pozycję"}}</a>
      </div>

      <ow-perfect-scrollbar #perfectScrollbar scrollGradient>
        <table>
          <tbody>
          <ng-container *ngFor="let item of rankDetails.items | filterObject:inputSearch.value; let i = index">
            <ng-container
              [ngTemplateOutlet]="templateTr"
              [ngTemplateOutletContext]="{item: item, i: i, inputSearch: inputSearch}"
            ></ng-container>
          </ng-container>
          </tbody>
        </table>
      </ow-perfect-scrollbar>
    </ng-container>
  </div>
</div>

<!-- TEMPLATE -->
<ng-template #templateTr let-item="item" let-i="i" let-inputSearch="inputSearch">
  <tr
    [class.my-position]="(i == schema.currentMyPositionId) && !(inputSearch && inputSearch.value) && playerService.isActiveMe"
    [attr.data-id]="i">
    <!-- POSITION -->
    <td class="td-position">
      {{
      activeRegionId && activeGroupId
        ? item.pos_league_group_region
        : activeGroupId
          ? item.league_group_pos
          : item.pos
      }}
    </td>

    <!-- BADGE -->
    <td class="td-badge">
      <div
        class="icon-league group small"
        [ngClass]="'icon-' + item.league_group_id"
      ></div>
    </td>

    <!-- NAME -->
    <td class="td-name">
      {{ item.name }}
    </td>
    <!-- REGION NAME -->
    <td class="td-region-name">
      {{ item.region_name }}
    </td>
    <!-- LEAGUE NAME -->
    <td class="td-league-name">
      <div class="flex-center">
        <div
          class="icon-league"
          [matTooltip]="item.colorObject.name"
          [ngClass]="'league-' + item.color"
        ></div>
      </div>
    </td>
    <!-- VALUE -->
    <td class="td-value">
      {{ item.result | number:'1.3'}}
    </td>
    <!-- PRIZES -->
    <td class="td-prize-array">
      <ng-container *ngFor="let currency of item.prizes">
        <m-ui-currency
          [item]="currency"
          [lack]="false"
          [stockView]="STOCK_VIEW.G"
          [hideValue]="true"
        ></m-ui-currency>
        {{ currency?.amount }}
      </ng-container>
    </td>
  </tr>
</ng-template>
