import * as R from 'ramda';
import { LeagueGroupObject } from '../../interfaces/custom/league-group-object.interface';

export class BusinessLeagueGroup {
  leaguesObject: LeagueGroupObject[] = [
    {name: 'Nieokreślona', league_group_id: 0},
    {name: 'AGUA', league_group_id: 1},
    {name: 'FUEGO', league_group_id: 2},
    {name: 'AIRE', league_group_id: 3},
    {name: 'TIERRA', league_group_id: 4},
  ];

  getLeagueGroup(id): LeagueGroupObject {
    id = +id;

    const groupObjectDefault: LeagueGroupObject = {name: 'undefined', league_group_id: null};
    const groupObjectResult = this.leaguesObject.find((item) => item.league_group_id === id);

    return R.clone(groupObjectResult || groupObjectDefault);
  }
}
