import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { AppState } from "../../../../../../../../store/state";
import { BusinessDateService } from "../../../../../shared-ui/services/custom/business-date.service";
import { ApiPlayerService } from "../../../../../hud/api/custom/services/api-player.service";
import { Branch } from "../../../../../../interfaces/branch.interface";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BusinessPreviewDashboardComponent } from "../business-preview-dashboard.component";
import { OwDate } from "../../../../../../../../core/utility/ow-date.class";
import { OwPaginationDate } from "../../../../../../../../core/utility/ow-pagination.class";
import { PlayerService } from "../../../../../../../player/providers/player.service";

@Component({
  selector: "app-business-preview-branches",
  templateUrl: "./business-preview-branches.component.html",
  styleUrls: ["./business-preview-branches.component.scss"],
})
export class BusinessPreviewBranchesComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(BusinessDateService) businessDateService: BusinessDateService;
  @OwInject(ApiPlayerService) apiPlayerService: ApiPlayerService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MAT_DIALOG_DATA) data: { backButton?: boolean; owDate?: OwDate };

  branches: Branch[];
  filteredBranches: Branch[];
  searchValue: string;
  isLoading: boolean = false;
  _owDate: OwDate;

  set owDate(owDate: OwDate) {
    this._owDate = owDate;
  }

  get owDate(): OwDate {
    return this._owDate;
  }

  ngOnInit() {
    if (this.data.owDate) {
      this.owDate = this.data.owDate;
    } else {
      this.owDate = new OwDate(this.businessDateService.currentBusinessDate);
    }
    this.getBranches();
  }

  getBranches(page: number = 1) {
    this.isLoading = true;
    this.apiPlayerService
      .branches({
        page: page,
        limit: 100,
        year: this.owDate.year,
        month: this.owDate.month,
        player_id: !this.playerService.isActiveMe ? this.playerService.player.id : undefined,
      })
      .subscribe((resp: any) => {
        this.branches = resp.body.sort((a, b) => a.name.localeCompare(b.name));
        this.filteredBranches = this.branches;
        this.isLoading = false;
      });
  }

  goToBranch(branch: Branch) {
    this.dialogService.closeAll();
    this.dialogService.open(BusinessPreviewDashboardComponent, {
      data: { branch: branch, branches: this.branches, owDate: this.data?.owDate },
    });
  }

  changeSearchValue(event) {
    this.searchValue = event.target.value.toLowerCase();
    this.filteredBranches = this.branches.filter(branch => branch.name.toLowerCase().includes(this.searchValue));
  }
}
