import { Component, OnInit } from "@angular/core";
import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import {WfService} from '../../../../module/custom/wf.service';
import {WfEntry} from '../../../../interfaces/wf.interface';
import * as moment from 'moment';
import {GAME_CONFIG} from '../../../../../../../../core/config/custom/_parsed-game.config';

@Component({
  selector: "wf-main",
  templateUrl: "./wf-main.component.html",
})
export class WfMainComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MAT_DIALOG_DATA) data: { tab?: string };
  @OwInject(WfService) wfService: WfService;
  isCC = GAME_CONFIG.IS_CC;

  activeTab: TabTop;
  wfSettings: WfEntry[] = null;

  TAB = {
    RULES: "rules",
    LIBRARY: "library",
    RANKS: "ranks",
    EPISODE_1: 'episode_1',
    EPISODE_2: 'episode_2',
    EPISODE_3: 'episode_3'
  };

  tabs: TabTop[] = [
    { label: "Zasady", name: this.TAB.RULES, color: "green", show: true, tooltip: '', enable: true },
    { label: "Biblioteka finałowa", name: this.TAB.LIBRARY, color: "green", show: true, enable: false },
    { label: "Rankingi finałowe", name: this.TAB.RANKS, color: "green", show: true, enable: false },
    { label: "Duety - Etap I", name: this.TAB.EPISODE_1, color: "green", show: true, enable: false},
    { label: "Duety - Etap 2", name: this.TAB.EPISODE_2, color: "green", show: true, enable: false},
    { label: "Duety - Etap 3", name: this.TAB.EPISODE_3, color: "green", show: true, enable: false}
  ];

  ngOnInit() {
    this.initializeData();
  }

  initializeData(updateValues: boolean = false) {
    if (this.isCC) {
      this.setTab();
      return;
    }

    this.wfService.getWfSettings().subscribe(res => {
      this.wfSettings = res;
      if (!updateValues) {
        this.setTab();
      }
    })
  }

  setTab() {
    if (this.isCC) {
      this.tabs = this.tabs.slice(0, 3);
    }

    this.tabs.map(t => {
      switch (t.name) {
        case this.TAB.LIBRARY:
          t.enable = this.playerService.player.gui_unlocks["wf_media"];
          t.tooltip = t.enable ? '' : 'Dostępne wkrótce';
          break;
        case this.TAB.RANKS:
          t.enable = this.playerService.player.gui_unlocks["wf_ranks"];
          t.tooltip = t.enable ? '' : 'Dostępne wkrótce';
          break;
        case this.TAB.EPISODE_1:
        case this.TAB.EPISODE_2:
        case this.TAB.EPISODE_3: {
          const wfSetting = this.wfSettings[parseInt(t.name.charAt(t.name.length - 1)) - 1];
          const dateFrom = moment(wfSetting.date_from, 'YYYY-MM-DD');
          const dateTo = moment(wfSetting.date_to, 'YYYY-MM-DD');
          const currDate = moment();

          t.enable = currDate.isSameOrAfter(dateFrom, 'days');
          t.label = wfSetting.title;
          t.tooltip = t.enable ? wfSetting.description : 'Dostępne wkrótce';
          wfSetting.isFinished = moment().isAfter(dateTo, 'days');

         break;
        }
        default:
          break;
      }
    });
    this.changeTabByName(this.data.tab || this.TAB.RULES);
  }

  changeTabByName(name: string) {
    let tab = this.tabs.find(tab => tab.name === name);

    if (!tab || (tab && !tab.show)) {
      tab = this.tabs.find(tab => tab.show === true);
    }

    this.changeTab(tab);
  }

  changeTab(tab: TabTop) {
    this.activeTab = tab;
  }
}

interface TabTop {
  label: string;
  name: string;
  show: boolean;
  color: string;
  enable: boolean;
  dialogName?: string;
  tooltip?: string;
}
