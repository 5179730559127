import { Component } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { PLAYER_TITLES } from "../../../../../../../core/consts/core/player-titles";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { AssetsService } from "../../../../../../../core/providers/assets.service";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { DashboardCCComponent } from "../../dialogs/dashboard-cc/dashboard-cc.component";
import { EventEmitterDialogsService } from "../../../../../../../core/services/core/event-emitter-dialogs.service";
import {EVENT_DIALOGS_NAMES_WF_CUSTOM} from '../../../../wf/consts/custom/event-dialogs/event-names.const';
import { NotificationsService } from "../../../../../services/custom/notifications.service";

@Component({
  selector: "app-san-business-cc",
  templateUrl: "./san-business-cc.component.html",
})
export class SanBusinessCCComponent extends AbstractInjectBaseComponent {
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(AssetsService) assetsService: AssetsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(NotificationsService) notificationsService: NotificationsService;
  PLAYER_TITLES = PLAYER_TITLES;

  openDashboard() {
    if (this.playerService.player.gui_unlocks["business_dashboard"]) {
      this.dialogService.open(DashboardCCComponent);
    } else {
      this.dialogService.openAlert({
        description: "Centrum danych (dostępne wkrótce)",
      });
    }
    if (this.playerService.player["gui_notifications"]?.["import_dashboard_prize"] > 0) {
      this.notificationsService.setNotificationRead("import_dashboard_prize").subscribe();
    }
  }

  openWF() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_WF_CUSTOM.MAIN
    });
  }
}
