import { select } from "@ngrx/store";
import * as R from "ramda";
import { distinctUntilChanged, map } from "rxjs/operators";

import { PlayerSelectors } from "../../../../../store/player";
import { NOTIFICATIONS_NAMES } from "../../../constants/custom/building-notifications.const";
import { BoardCore } from "../core/BoardCore.class";

export class Board extends BoardCore {
  subs;

  afterCreate() {
    super.afterCreate();
    this.subs = {
      player: null,
      guiNotification: null,
    };

    this.subs.guiNotification = this.gameService.store
      .pipe(
        select(PlayerSelectors.selectPlayer),
        map(player => player["gui_notifications"]),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.boardTiles.forEach(boardTile => {
          if (
            boardTile.hasBuilding &&
            R.includes(boardTile.playerBuildingData.group, [
              NOTIFICATIONS_NAMES.QA,
              NOTIFICATIONS_NAMES.SURVEY,
              NOTIFICATIONS_NAMES.PHOTO_BOOTH,
            ])
          ) {
            if (boardTile.tooltip) {
              boardTile.destroyTooltip();
            }
            boardTile.handlePlayerBuilding();
          }
        });
      });
  }
}
