import { Component, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../../../shared/providers/dialog.service';
import { ClRankDetailsComponent } from '../cl-rank-details/cl-rank-details.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventShopRank } from '../../../../../event-shop/api/custom/interfaces/response/get-event-shops-ranks.interface';
import * as R from 'ramda';

@Component({
  selector: 'app-cl-rank-list',
  templateUrl: './cl-rank-list.component.html',
})
export class ClRankListComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data: {
    event_shop_id: number,
    ranks: EventShopRank[],
    event_business_id?: number
  };

  activeFilter: any;
  filters = [
    {
      type: 1,
      label: 'Indywidualny',
      show: true,
    },
    {
      type: 2,
      label: 'Oddziały',
      show: true,
    },
  ];

  dates: RankDate[] = [];
  currentIndex: number;
  currentDate: RankDate;

  ngOnInit() {
    this.setDates();
    this.changeFilter(this.filters[0]);
    this.changeDate(this.dates.length - 1);
  }

  openClRankDetails(rank) {
    this.dialogService.open(ClRankDetailsComponent, {
      data: {
        event_shop_id: this.data.event_shop_id,
        rank,
        event_business_id: this.data.event_business_id
      }
    });
  }

  changeFilter(filter): void {
    this.activeFilter = R.clone(filter);
  }

  setDates() {
    this.dates = [];

    this.data.ranks.forEach((rank) => {
      if (!this.dates.some((date) => date.date_start === rank.date_start && date.date_end === rank.date_end)) {
        this.dates.push({
          date_start: rank.date_start,
          date_end: rank.date_end,
        });
      }
    });
  }

  changeDate(index) {
    this.currentIndex = index;
    this.currentDate = this.dates[this.currentIndex];
  }
}

interface RankDate {
  date_start: string;
  date_end: string;
}
