import { Component } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { translate } from "../../../../../../../core/helpers/translate.helper";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { ContractSaleComponent } from "./contract-sale/contract-sale.component";
import { MicroLeagueTabsComponent } from "./micro-league-tabs/micro-league-tabs.component";

@Component({
  selector: "app-business-challenges",
  templateUrl: "./business-challenges.component.html",
})
export class BusinessChallengesComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MAT_DIALOG_DATA) data: { playerId?: number; branchName?: string };

  CHALLENGE = {
    CONTRACT: 1,
    MICROLEAGUE: 2,
  };

  blocked = false;
  branchName?: string;

  ngOnInit() {
    this.branchName = this.data.branchName;
  }

  openChallenge(challenge: number) {
    switch (challenge) {
      case this.CHALLENGE.CONTRACT:
        if (this.playerService.player.gui_unlocks["contract_sale"]) {
          this.dialogService.open(ContractSaleComponent, { data: { playerId: this.data?.playerId, branchName: this.data?.branchName } });
        }
        break;

      case this.CHALLENGE.MICROLEAGUE:
        if (this.playerService.player.gui_unlocks["micro_league"]) {
          this.dialogService.open(MicroLeagueTabsComponent, { data: { playerId: this.data?.playerId, branchName: this.data?.branchName } });
        }
        break;
    }
  }
}
