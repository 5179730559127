export const CHALLENGE_NAME = {
  MICROLEAGUE: 1,
  ACHIEVEMENTS: 2,
};

export const MICRO_LEAGUE_TABS = [
  {
    label: "Twoje mikroligi",
    index: CHALLENGE_NAME.MICROLEAGUE,
    visible: true,
    color: "green",
    gui_unlocks: "micro_league",
    class: "micro-league",
  },
  {
    label: "Osiągnięcia",
    index: CHALLENGE_NAME.ACHIEVEMENTS,
    visible: false, // https://redmine.oskarwegner.pl/issues/26597
    color: "yellow",
    gui_unlocks: "micro_league_achievements",
    class: "achievements",
    disabledTooltip: "Dostępne wkrótce!",
  },
];
