<div
  *ngIf="production"
  class="building-productions"
>
  <div class="sub-title">{{production[0]?.product_prizes[0]?.name.toLowerCase().includes('certyf') ? 'Certyfikaty' : 'Produkcja/ zaopatrzenie'}}:</div>

  <m-ui-product
    *ngFor="let product of production"
    [item]="product.product_prizes[0]"
    [stockView]="STOCK_VIEW.CUSTOM"
    [other]="{value: hideExtraValue ? '' : '+'}"
    [customIconSize]="'big'"
    [customClassStock]="'stock-san-a'"
    [lack]="false"
  ></m-ui-product>
</div>
