<div class="global-dialog ow-dialog c-window-short edenred-list-container">

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <div class="title history">
    Historia doładowań Twojej karty
  </div>

  <loading></loading>

  <div class="frame loading-opacity">
    <ow-perfect-scrollbar
      *ngIf="cardTopUps"
      [scrollGradient]="cardTopUps"
    >
      <div class="table-wrapper">
        <table>
          <thead>
          <tr>
            <th class="topup-amount">Kwota doładowania</th>
            <th class="topup-date">Data przyznania</th>
            <th class="topup-status">Tytuł doładowania</th>
            <th class="topup-status">Status doładowania</th>
            <th class="topup-date">Data doładowania</th>

          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let card of cardTopUps">
            <td class="topup-amount">
              {{ card.amount }}zł
            </td>

            <td class="topup-date">
              {{ card.created_at | date:'dd.MM.yyyy' }}
            </td>

            <td class="topup-status">
              {{ card.description }}
            </td>

            <td class="topup-status">
              {{ card.status_text }}
            </td>

            <td class="topup-date">
              {{ card.top_up_at | date:'dd.MM.yyyy' }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ow-perfect-scrollbar>
  </div>
</div>
