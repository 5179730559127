import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import { AbstractInjectBaseComponent } from "../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../core/decorators/ow-inject.decorator";
import { STOCK_VIEW } from "../../../../game/game-ui/shared-ui/mobile/consts/stock-view.const";
import { DialogService } from "../../../../shared/providers/dialog.service";
import { RRStatus } from "../../interfaces/status.interface";
import { RREvent, RREvents, RRGameModes } from "../../river-ride.interfaces";
import { RiverRideService } from "../../services/river-ride.service";

@Component({
  selector: "app-minigame-river-ride-gui",
  templateUrl: "./minigame-river-ride-gui.component.html",
})
export class MinigameRiverRideGuiComponent extends AbstractInjectBaseComponent implements AfterViewInit, OnInit, OnDestroy {
  @OwInject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef;
  @OwInject(MAT_DIALOG_DATA) data: {
    game: number;
    payment: number;
    unfinishedStatus: RRStatus;
  };
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<MinigameRiverRideGuiComponent>;
  @OwInject(RiverRideService) riverRideService: RiverRideService;
  @OwInject(DialogService) dialogService: DialogService;
  @ViewChild("riverRideGuiResult", { static: false }) riverRideGuiResultTplRef: TemplateRef<any>;
  @ViewChild("riverRideGuiPause", { static: false }) riverRideGuiPauseTplRef: TemplateRef<any>;
  @ViewChild("riverRideGuiLoad", { static: false }) riverRideGuiLoadTplRef: TemplateRef<any>;
  RRGameModes = RRGameModes;
  STOCK_VIEW = STOCK_VIEW;

  currentGame: RRStatus;
  currentTemplate: TemplateRef<any>;
  riverRideParams: any;
  gameState = {
    progress: 0,
    isLoaded: false,
  };

  summary: {
    mini_game_id: number;
    game: number;
    payment: number;
    token: string;
    score1: number;
    score2: number;
    score3: number;
    points1: number;
    points2: number;
    points3: number;
    multiplier1: number;
    multiplier2: number;
    multiplier3: number;
    total_food: number;
    chest_points: number;
    chest_multiplier: number;
    started_at: string;
    finished_at: string;
    chests_received: boolean;
  };

  private rrEvents$: Subscription;

  ngAfterViewInit() {
    this.showLoad();
  }

  setTemplate(template: TemplateRef<any>) {
    this.currentTemplate = template;
    this.changeDetectorRef.detectChanges();
  }

  showLoad() {
    this.setTemplate(this.riverRideGuiLoadTplRef);
  }

  initRiverRide() {
    this.riverRideParams = {
      gameMode: this.data.payment,
      state: this.data.unfinishedStatus && this.data.unfinishedStatus.progress,
    };
  }

  showResult(score) {
    this.summary = score;
    this.setTemplate(this.riverRideGuiResultTplRef);
  }

  close() {
    this.matDialogRef.close();
  }

  ngOnInit(): void {
    this.checkUnfinished();
    this.initRiverRide();
    this.subscribeEvents();
  }

  subscribeEvents() {
    this.rrEvents$ = this.riverRideService.rrEvents.subscribe((event: RREvent) => {
      switch (event.name) {
        case RREvents.LOAD_PROGRESS:
          this.gameState.progress = event.value;
          break;
        case RREvents.LOAD_COMPLETE:
          this.gameState.isLoaded = true;
          break;
        case RREvents.GAME_PAUSE:
          this.setPause();
          break;
        case RREvents.PROGRESS:
          this.progress(event.value);
          break;
        case RREvents.GAME_OVER:
          this.finish(event.value);
          break;
      }
    });
  }

  playGame() {
    this.emitRREvent(RREvents.GAME_START);
    this.setTemplate(null);
  }

  setPause() {
    this.setTemplate(this.riverRideGuiPauseTplRef);
  }

  resumeGame() {
    this.setTemplate(null);
    this.emitRREvent(RREvents.GAME_RESUME);
  }

  emitRREvent(name: RREvents, value?: any) {
    this.riverRideService.rrEvents.emit({
      name,
      value,
    });
  }

  checkUnfinished() {
    if (this.data.unfinishedStatus) {
      this.currentGame = this.data.unfinishedStatus;
    }
  }

  start() {
    this.riverRideService
      .start({
        game: this.data.game,
        payment: this.data.payment,
      })
      .subscribe(
        resp => {
          this.currentGame = resp;
          this.playGame();
        },
        errResp => {
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
  }

  continueUnfinished() {
    this.playGame();
  }

  finish(progress) {
    progress = this.addBuildVersionGame(progress);
    progress = this.encodeData(progress);

    this.riverRideService
      .finish({
        mini_game_id: this.currentGame.mini_game_id,
        progress,
      })
      .subscribe(resp => {
        this.showResult(resp);
      });
  }

  progress(progress) {
    progress = this.addBuildVersionGame(progress);
    progress = this.encodeData(progress);

    this.riverRideService
      .progress({
        mini_game_id: this.currentGame.mini_game_id,
        progress,
      })
      .subscribe();
  }

  encodeData(data) {
    const base = btoa(JSON.stringify(data));
    return encodeURIComponent(`${this.currentGame.token}${base}`);
  }

  addBuildVersionGame(progress) {
    return {
      ...progress,
      buildVersionGame: document["GAME_VERSION_TIMESTAMP"],
    };
  }

  ngOnDestroy(): void {
    this.rrEvents$.unsubscribe();
  }
}
