import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { MediaCategory } from "../../../../../hud/api/custom/interfaces/response/get-media-category-response.interface";
import { Media } from "../../../../../hud/api/custom/interfaces/response/get-media-list-response.interface";

@Component({
  selector: "app-auction-info",
  templateUrl: "./auction-info.component.html",
})
export class AuctionInfoComponent {
  @Input() filters: MediaCategory[];
  @Input() currentFilterId: number;
  @Input() currentPage: number;
  @Input() maxPages: number;
  @Input() items: Media[];
  @Input() isLoading?: boolean;

  @Output() getMediaList = new EventEmitter();
  @Output() nextPage = new EventEmitter();
  @Output() previousPage = new EventEmitter();
  @Output() changeFilter = new EventEmitter();

  next() {
    this.nextPage.emit();
  }

  previous() {
    this.previousPage.emit();
  }

  change() {
    this.changeFilter.emit();
  }
}
