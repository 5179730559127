import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { GAME_CONFIG } from "../../../../../../../core/config/custom/_parsed-game.config";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { GlobalEvent } from "../../../../../../../core/interfaces/shared.interfaces";
import { BoardTileState } from "../../../../../../../store/game/interfaces/board-tile.state";
import { UtilityActions } from "../../../../../../../store/utility";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { BUILDING_GROUP_TYPE_META_PRODUCTION, BUILDING_GROUP_TYPE_SPECIAL_INCREASE, GAME_EVENTS } from "../../../../../constants";
import { ADDITIONAL_BOARD_DATA } from "../../../../../game-engine/config/additional-board-data.config";
import { NotificationsService } from "../../../../../services/custom/notifications.service";
// import { BuildingEfficiencyComponent } from "../../../../../buildings/base/custom/dialogs/building-efficiency/building-efficiency.component";
import { EVENT_DIALOGS_NAMES_BUILDINGS_CUSTOM } from "../../../../buildings/consts/custom/event-dialogs/event-names.const";
import { BuildingData } from "../../../../buildings/interfaces/core/dialogs/building-data.interface";
// import { EVENT_DIALOGS_NAMES_EMPLOYEES_CUSTOM } from "../../../../../employees/consts/custom/event-dialogs/event-names.const";
import { MISSION_TYPE } from "../../../../mission/consts/core";
import { EVENT_DIALOGS_NAMES_MISSION } from "../../../../mission/consts/core/event-dialogs/event-names";
// import { EVENT_DIALOGS_NAMES_TRACKING_CUSTOM } from "../../../../../tracking/consts/custom/event-dialogs/event-names.const";
import { AbstractHudComponent } from "../../../abstract/core/abstract-hud.component";
import { TITLES } from "../../../const/custom/titles.const";
import { ChangeMapService } from "../../services/change-map.service";
import { MessageEventComponent } from "../../dialogs/message-event/message-event.component";
import { EVENT_DIALOGS_NAMES_TRACKING_CUSTOM } from "../../../../tracking/consts/custom/event-dialogs/event-names.const";
import { EVENT_DIALOGS_NAMES_MESSAGE_CUSTOM } from "../../../../message/consts/custom/event-dialogs/event-names.const";
import { MinigamesComponent } from '../../../../../../minigames/components/minigames/minigames.component';
import { EVENT_DIALOGS_NAMES_ANTYMINER_CUSTOM } from '../../../../antyminer/consts/custom/event-dialogs/event-names.const';

@Component({
  selector: "app-san-hud",
  templateUrl: "./hud.component.html",
})
export class HudComponent extends AbstractHudComponent implements OnInit {
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ChangeMapService) changeMapService: ChangeMapService;
  @OwInject(Router) router: Router;
  @OwInject(NotificationsService) notificationsService: NotificationsService;

  isCC = GAME_CONFIG.IS_CC;
  TITLES = TITLES;

  ngOnInit() {
    this.subscribePlayer();
    this.subscribeUser();
    this.subs.globalEmitter = this.globalService.globalEvents.subscribe(this.handleGameEvents.bind(this));
  }

  async handleGameEvents(event: GlobalEvent) {
    await super.handleGameEvents(event);
    switch (event.name) {
      case GAME_EVENTS.TRACKING_BEGIN:
        this.openTrackingBegin(event);
        break;

      case GAME_EVENTS.TRACKING_CONTINUE:
        this.openTrackingContinue(event);
        break;

      case GAME_EVENTS.BUILDING_INFO_PROBLEM:
        this.openInfoProblem(event);
        break;

      case GAME_EVENTS.ANTYMINER_BEGIN:
        this.openAntyminerBegin(event);
        break;

      case GAME_EVENTS.ANTYMINER_CONTINUE:
        this.openAntyminerContinue(event);
        break;
    }
  }

  openFunctional(event: GlobalEvent) {
    super.openFunctional(event);
    this.openMinigames(event);
    // this.openEmployment(event);
  }

  openTrackingBegin(event: GlobalEvent) {
    const buildingTrackingBeginData: BuildingData = {
      playerTileId: event.value,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_TRACKING_CUSTOM.TRACKING_BEGIN,
      config: {
        data: buildingTrackingBeginData,
      },
    });
  }

  openTrackingContinue(event: GlobalEvent) {
    const buildingTrackingBeginData: BuildingData = {
      playerTileId: event.value,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_TRACKING_CUSTOM.TRACKING_CONTINUE,
      config: {
        data: buildingTrackingBeginData,
      },
    });
  }

  openAntyminerBegin(event: GlobalEvent) {
    const buildingTrackingBeginData: BuildingData = {
      playerTileId: event.value,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_ANTYMINER_CUSTOM.ANTYMINER_BEGIN,
      config: {
        data: buildingTrackingBeginData,
      },
    });
  }

  openAntyminerContinue(event: GlobalEvent) {
    const buildingTrackingBeginData: BuildingData = {
      playerTileId: event.value,
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_ANTYMINER_CUSTOM.ANTYMINER_CONTINUE,
      config: {
        data: buildingTrackingBeginData,
      },
    });
  }

  openInfoProblem(event: GlobalEvent) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MESSAGE_CUSTOM.MESSAGE_DETAILS_ANIMAL,
      config: {
        data: event,
      },
    });
  }

  // openEmployment(event: GlobalEvent) {
  //   if (this.isBuildingMetaProduction(event.data) && event.data.open_player_island_id) {
  //     const data: BuildingData = {
  //       playerTileId: event.value,
  //     };
  //
  //     this.eventEmitterDialogsService.emitter.emit({
  //       name: EVENT_DIALOGS_NAMES_EMPLOYEES_CUSTOM.EMPLOYEE_LIST,
  //       config: {
  //         data,
  //       },
  //     });
  //   }
  // }

  openPlayerMission(event: GlobalEvent) {
    const missionDetailsData = {
      mission: {
        player_mission_id: event.value.player_mission_id,
      },
    };

    if (event.value.slot?.type === 999) {
      this.dialogService.open(MessageEventComponent, {
        data: missionDetailsData,
      });
    } else {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_MISSION.MISSION_DETAILS,
        config: {
          data: missionDetailsData,
          disableClose: true,
        },
      });
    }
  }

  // openPhotoBooth(event) {
  //   if (event.data.player_building.group === ADDITIONAL_BOARD_DATA.OPEN_PHOTO_BOOTH) {
  //     if (this.playerService.player.gui_unlocks["photobooth"]) {
  //       this.clearGalleryNotification();
  //       this.router.navigate(["auth/gallery"]);
  //     } else {
  //       this.alertTemporarilyUnavailable("Fotobudka");
  //     }
  //   }
  // }

  openMinigames(event) {
    if (event.data.player_building.group === ADDITIONAL_BOARD_DATA.OPEN_MINIGAMES) {
      if (this.playerService.player.gui_unlocks["minigames"]) {
        this.dialogService.open(MinigamesComponent);
      } else {
        this.alertTemporarilyUnavailable("Minigry");
      }
    }
  }

  // openEfficiency(event) {
  //   this.dialogService.open(BuildingEfficiencyComponent, {
  //     data: {
  //       playerTileId: event.value,
  //     },
  //   });
  // }

  alertTemporarilyUnavailable(title: string) {
    this.dialogService.openAlert({
      title,
      description: "Tymczasowo niedostępne",
    });
  }

  missionCompleted(event: GlobalEvent) {
    if (event.value.mission_type === MISSION_TYPE.ONE) {
      this.store.dispatch(new UtilityActions.SetHasMissionsToCollect(true));
    }

    if (event.value.mission_type === MISSION_TYPE.ONE) {
      this.store.dispatch(
        new UtilityActions.AddMissionToOpen({
          player_mission_id: event.value.player_mission_id,
          slot: {
            mission_slot_id: event.value.mission_slot_id,
            type: event.value.mission_type,
          },
        })
      );
    }
  }

  clearGalleryNotification() {
    if (this.playerService.player["gui_notifications"]?.["gallery"] > 0) {
      this.notificationsService.setNotificationRead("gallery").subscribe();
    }
  }

  isBuildingMetaProduction(boardTileState: BoardTileState) {
    return boardTileState.player_building.group_type === BUILDING_GROUP_TYPE_META_PRODUCTION && boardTileState.open_player_island_id;
  }

  isBuildingSpecialIncrease(boardTileState: BoardTileState) {
    return boardTileState.player_building.group_type === BUILDING_GROUP_TYPE_SPECIAL_INCREASE && boardTileState.open_player_island_id;
  }
}
