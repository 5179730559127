import { moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Input, OnInit, ViewChild } from "@angular/core";

import { RiverRideGame } from "../../classes/RiverRideGame.class";
import { RRGameObject } from "../../classes/RRGameObject.class";
import { GameObjectIds, riverRideGameplayConfig, RR_GAME_OBJECT_DEFS } from "../../river-ride.config";
import { RR_PLAY_SCENE_KEY } from "../../river-ride.constants";
import { generateSegmentGameObjectConfig, provideSceneConfig } from "../../river-ride.helpers";
import { RiverRideGameplayConfig, RRRiverSegment, RRRiverSegmentGameObjectDef } from "../../river-ride.interfaces";
import { RREditorScene } from "../../scenes/RiverRideEditor.scene";

export const LS_CONFIG_KEY = "rr-config-v2";
export const LS_SEGMENT_ORDER_KEY = "rr-segment-order-v2";

@Component({
  selector: "app-rrconfig-editor",
  templateUrl: "./rrconfig-editor.component.html",
})
export class RRConfigEditorComponent implements OnInit {
  _gameRef: RiverRideGame;

  riverRideGameplayConfig: RiverRideGameplayConfig = JSON.parse(JSON.stringify(riverRideGameplayConfig));
  selectedObject: RRGameObject;

  gameObjectDefs = RR_GAME_OBJECT_DEFS;

  startSegment: RRRiverSegment = riverRideGameplayConfig.riverSegments[0];

  @ViewChild("codeTextarea", { static: false }) textarea;
  @Input()
  set gameRef(gameRef: RiverRideGame) {
    if (!gameRef) {
      return;
    }
    this._gameRef = gameRef;
    this.sceneRef = gameRef.scene.getAt(0) as RREditorScene;
  }

  sceneRef: RREditorScene;
  objectToAdd: RRRiverSegmentGameObjectDef;
  segmentToAddObject: RRRiverSegment;

  constructor() {
    this.riverRideGameplayConfig = provideSceneConfig();
  }

  pauseGame() {
    if (this.sceneRef.scene.isPaused(RR_PLAY_SCENE_KEY)) {
      this.sceneRef.scene.resume(RR_PLAY_SCENE_KEY);
    } else {
      this.sceneRef.scene.pause(RR_PLAY_SCENE_KEY);
    }
  }

  saveConfig() {
    localStorage.setItem(LS_CONFIG_KEY, JSON.stringify(this.riverRideGameplayConfig));
    this.sceneRef.saveRiverMapConfig();
  }

  restartScene() {
    this.sceneRef.scene.start(RR_PLAY_SCENE_KEY, JSON.parse(JSON.stringify(this.sceneRef.riverMap)));
  }

  resetConfig() {
    if (confirm("Przywrócić domyślny konfig?")) {
      localStorage.removeItem(LS_CONFIG_KEY);
      localStorage.removeItem(LS_SEGMENT_ORDER_KEY);
      location.reload();
    }
  }

  ngOnInit() {
    this.sceneRef.selectedObject$.subscribe(selectedObject => {
      this.selectedObject = selectedObject;
      if (selectedObject) {
        const segmentInstanceParams = this.riverRideGameplayConfig.riverSegments.find(
          segmentData => segmentData.id === selectedObject.segmentInstance.segmentData.id
        );
        segmentInstanceParams.objects[selectedObject.index] = selectedObject.params;
      }
    });
  }

  stopTween() {
    this.selectedObject.stopTween();
  }

  playTweens() {
    if (!this.selectedObject.tweenRef.isPlaying()) {
      this.selectedObject.createTween();
    }
  }

  saveAndRestart() {
    this.saveConfig();
    this.restartScene();
  }

  saveAndReload() {
    this.saveConfig();
    location.reload();
  }

  drop($event: any) {
    moveItemInArray(this.sceneRef.riverMap, $event.previousIndex, $event.currentIndex);
  }

  copyToClipboard() {
    const copyText = document.querySelector("textarea");
    copyText.select();
    document.execCommand("copy");
  }

  addObject() {
    if (this.segmentToAddObject && this.objectToAdd) {
      this.segmentToAddObject.objects.push(
        generateSegmentGameObjectConfig(
          300,
          2800,
          this.objectToAdd.objectId,
          this.gameObjectDefs,
          this.objectToAdd.movable ? 3 : null,
          this.objectToAdd.movable ? 700 : null
        )
      );
      this.saveAndReload();
    }
  }

  updateObjectParams(objectId: GameObjectIds) {
    const objectDef = this.gameObjectDefs.find(object => object.objectId === objectId);
    const params = this.selectedObject.params;
    params.animationFrames = objectDef.animationFrames;
    if (objectDef.movable) {
      params.targetX = 800;
      params.targetY = null;
      params.tweenDuration = 2;
    }
    if (!objectDef.movable) {
      params.targetX = null;
      params.targetY = null;
      params.tweenDuration = 0;
    }
  }

  removeObject() {
    const selectedObjectIndex = this.selectedObject.index;
    const selectedObjectSegmentImage = this.selectedObject.segmentInstance.segmentData.imagePath;
    const targetSegment = this.riverRideGameplayConfig.riverSegments.find(segment => segment.imagePath === selectedObjectSegmentImage);
    targetSegment.objects.splice(selectedObjectIndex, 1);
    this.selectedObject.destroyObject();
  }
}
