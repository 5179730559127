import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { CUSTOM_ROUTING_APP_CHILDREN } from "./consts/custom/routing-app-children.const";
import { APP_ROUTING_PATH } from "./consts/routing-app.const";
import { APP_ROUTING_ABSOLUTE_PATH } from "./consts/routing-app-absolute.const";
import { AuthGuard } from "./core/guards/auth.guard";
import { InitGuard } from "./core/guards/init.guard";
import { PrimaryGuard } from "./core/guards/primary.guard";
import { SocketGuard } from "./core/guards/socket.guard";
import { SyncGuard } from "./core/guards/sync.guard";
import { ConfirmationDeliveryGuard } from "./guards/confirmation-delivery.guard";
import { ShopRestrictedGuard } from "./guards/shop-restricted.guard";
import { PlayerChooseComponent } from "./modules/auth/base/core/components/player-choose/player-choose.component";
import { ReauthorizeGuard } from "./modules/auth/guards/core/reauthorize.guard";
import { MockDialogListComponent } from "./modules/mock-dialogs/components/mock-dialog-list/mock-dialog-list.component";
import {NewEditionGuard} from './guards/new-edition.guard';

const appRoutes: Routes = [
  {
    path: APP_ROUTING_PATH.AUTH,
    canActivate: [SyncGuard],
    data: {
      syncGuards: [InitGuard, AuthGuard, ReauthorizeGuard],
    },
    children: [
      ...CUSTOM_ROUTING_APP_CHILDREN,
      {
        path: APP_ROUTING_PATH.PLAYER_CHOOSE,
        component: PlayerChooseComponent,
      },
      {
        path: APP_ROUTING_PATH.GAME,
        canActivate: [SyncGuard],
        data: {
          syncGuards: [PrimaryGuard, SocketGuard, ConfirmationDeliveryGuard, NewEditionGuard, ShopRestrictedGuard],
        },
        loadChildren: () => import("./modules/new-game/module/core/game.module").then(m => m.GameModule),
      },
    ],
  },
  {
    path: "dialog-list",
    canActivate: [],
    component: MockDialogListComponent,
  },
  {
    path: "**",
    redirectTo: APP_ROUTING_ABSOLUTE_PATH.PLAYER_CHOOSE,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      paramsInheritanceStrategy: "always",
      enableTracing: false,
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
